import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { FiscalCodeService } from "@shared/services/fiscal-code.service";
import moment from 'moment-mini';

@Component({
    selector: 'fiscal-code',
    templateUrl: './fiscal-code.component.html'
})
export class FiscalCodeComponent implements OnInit {

    @Input('group')
    formGroup: UntypedFormGroup;

    @Input()
    readonly = false;

    // eslint-disable-next-line no-useless-constructor
    constructor(private fiscalCodeService: FiscalCodeService) {}

    ngOnInit(): void {
        const fiscalCode = <UntypedFormControl>this.formGroup.get("fiscalCode");

        fiscalCode.statusChanges.subscribe((value) => {
            // Should this check for "pending" status?
            this.onChange(value);
        });
    }

    private onChange(status: string) {
        if (status === "VALID") {
            const fiscalCode = this.formGroup.get("fiscalCode").value;
            const placeOfBirth = this.fiscalCodeService.retrieveBirthPlace(fiscalCode);
            const dob = FiscalCodeService.retrieveBirthDate(fiscalCode);
            if (placeOfBirth) {
                this.formGroup.patchValue({
                    "dob": moment(dob).format('DD/MM/YYYY'),
                    "birthPlace": placeOfBirth.town,
                    "birthState": placeOfBirth.state,
                    "gender": FiscalCodeService.refetrieveSex(fiscalCode)
                });
            } else {
                this.formGroup.patchValue({
                    "dob": moment(dob).format('DD/MM/YYYY'),
                    "birthPlace": '',
                    "birthState": '',
                    "gender": FiscalCodeService.refetrieveSex(fiscalCode)
                });
            }
        }
        this.formGroup.get('lastName').markAsTouched();
        this.formGroup.get('firstName').markAsTouched();
    }
}
