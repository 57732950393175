
import { take } from 'rxjs/operators';
import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { TranslateService } from '@ngx-translate/core';

import { APP_CONFIG, AppConfig } from '@src/app/app-config.module';
import { NGXLogger } from "ngx-logger";

import { SoldoCookieService } from "./soldo-cookie.service";


export interface ICountryMetadata {
    alpha_2: string;
    alpha_3: string;
    numeric: string;
    icc: string;
    name?: string;
    alt?: string;
    active_market?: boolean;
    blacklist?: boolean;
    hl?: boolean;
    lang?: string;
}

export interface ICountryTranslation {
    alpha_3: string;
    name: string;
    alt: string;
}

export const MAIN_MARKETS_COUNTRY_ISO: Array<string> = ["FRA", "DEU", "ITA", "NLD", "ESP", "GBR"];


@Injectable()
export class CountryService  {
    private initPromise: Promise<void> = null;
    private countriesMeta: ICountryMetadata[] = null;
    private countries$: Subject<ICountryMetadata[]> = new BehaviorSubject<ICountryMetadata[]>([]);

    public locationPromise: Promise<string> = null;

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private soldoCookieService: SoldoCookieService,
        private logger: NGXLogger
    ) {
        this.initPromise = new Promise((resolve) => {
            this._loadMetadata().then(() => {
                this._loadTranslation();
                this.translate.onLangChange.subscribe(() => {
                    this._loadTranslation();
                });
                resolve();
            });
        });

        let conf = this.soldoCookieService.getStoredConfig();
        if (conf.geolocation) {
            this.logger.debug("Country Service, location retrieved from conf cookie ", conf.geolocation);
            this.locationPromise = Promise.resolve(conf.geolocation);
        } else {
            this.locationPromise = this.geolocate().pipe(take(1)).toPromise().then(data => {
                const alpha_2 = data.country_code;
                return this.initPromise.then(() => {
                    const alpha_3 = this.countriesMeta.find(c => c.alpha_2 == alpha_2).alpha_3;
                    conf = this.soldoCookieService.getStoredConfig();
                    conf.geolocation = alpha_3;
                    this.soldoCookieService.storeConfig(conf);
                    return alpha_3;
                });
            });
        }
    }

    public geolocate(): Observable<any> {
        const geolocationService = new URL("https://utils.soldo.com/checkip");
        const observable = this.http.get(geolocationService.toString());
        return observable;
    }

    public resolve(): Promise<void> {
        return this.initPromise;
    }

    private _loadMetadata(): Promise<ICountryMetadata[]> {
        const initPromise: Promise<{ default: ICountryMetadata[] }> = import('../data/world_countries_metadata.json');
        return initPromise.then(result => {
            this.countriesMeta = result.default;
            return result.default;
        });
    }

    private _loadTranslation(): void {
        import(`../data/world_countries.${this.translate.currentLang}.json`).then(result => {
            const translation = result.default;
            this.countries$.next(this.countriesMeta.map(meta => {
                const translated = translation.find(t => t.alpha_3 === meta.alpha_3);
                meta.name = translated.name;
                meta.alt = translated.alt;
                return meta;
            }));
        });
    }

    sortFunction = function (a: ICountryMetadata, b: ICountryMetadata): number {
        if (a.name > b.name) {
            return 1;
        } else if (a.name < b.name) {
            return -1;
        } else {
            return 0;
        }
    };

    getMainMarkets(): Array<string> {
        return MAIN_MARKETS_COUNTRY_ISO;
    }
    get countries(): Observable<ICountryMetadata[]> {
        return new Observable(fn => this.countries$.subscribe(fn));
    }

    getMetadata(alpha_3: string): ICountryMetadata {
        return this.countriesMeta.find(m => m.alpha_3 === alpha_3);
    }

}
