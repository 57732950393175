import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'typeface-icon',
    templateUrl: './typeface-icon.component.html',
    styleUrls: ['./typeface-icon.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TypefaceIconComponent {
}