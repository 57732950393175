
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse, HttpUploadProgressEvent } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComplianceProofDocumentType, ComplianceProofType, ComplianceStatus, DocUploadFile, DocUploadMetadata, KYCDocRequest, UploadPresignedURLResponse } from '@shared/models/registration.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface DocRequestDefinition {
    docType: ComplianceProofDocumentType;
    attachments: 1 | 2;
}
export interface DocRequestByCountry {
    [key: string]: {
        IDENTITY: DocRequestDefinition[];
        ADDRESS: DocRequestDefinition[];
    };
}

@Component({
    selector: 'app-doc-upload',
    templateUrl: './doc-upload.component.html',
    styleUrls: ['./doc-upload.component.scss']
})
export class DocUploadComponent implements OnInit {

    status: ComplianceStatus;
    people: KYCDocRequest[];
    nameList: string[];
    loading: boolean = false;
    docCountry: string;

    docTypesByCountry: DocRequestByCountry = {
        "IRL": {
            IDENTITY: [
                { docType: "PASSPORT", attachments: 1 },
                { docType: "ID_CARD", attachments: 2 },
                { docType: "DRIVING_LICENSE", attachments: 2 }],
            ADDRESS: [
                { docType: "BANK_STATEMENT", attachments: 1 },
                { docType: "UTILITY", attachments: 1 },
                { docType: "OTHER", attachments: 1 }
            ]
        }, // IRL is the fallback, add others to override
        "ITA": {
            IDENTITY: [
                { docType: "PASSPORT", attachments: 1 },
                { docType: "ID_CARD", attachments: 2 },
                { docType: "DRIVING_LICENSE", attachments: 2 }],
            ADDRESS: [
                { docType: "BANK_STATEMENT", attachments: 1 },
                { docType: "UTILITY", attachments: 1 },
                { docType: "OTHER", attachments: 1 }
            ]
        }
    };

    constructor(
        protected logger: NGXLogger,
        private registstrationDataService: RegistrationDataService,
        private httpClient: HttpClient,
        private gtmService: GoogleTagManagerService,
        private translate: TranslateService,
        private amplitudeService: AmplitudeService) { }

    _docsCountry() {
        const c = this.registstrationDataService.getCompanyCountry();
        if (["IRL", "GBR", "ITA", "FRA", "ESP", "DEU", "NLD"].findIndex(i => i == c) > -1) {
            return c;
        } else {
            return "IRL";
        }
    }

    _docTypesByCountry(country: string) {
        return this.docTypesByCountry[country] || this.docTypesByCountry["IRL"];
    }

    ngOnInit() {
        this.docCountry = this._docsCountry();
        this.status = this.registstrationDataService.getComplianceStatus();
        const kycDocMetadata = this.registstrationDataService.getKYCDocUploadData();

        this.people = kycDocMetadata;
        let firstOpen = true;
        for (const p of this.people) {
            p.completion = 0;
            for (const d of p.requiredDocuments) {
                d.completion = 0;
                d.open = firstOpen;
                if (firstOpen) {
                    firstOpen = false;
                }
                d.filesRequired = 1;
                d.docType = this._docTypesByCountry(this.docCountry)[d.proofType][0].docType;
                d.uploadStatus = "NEW";
                for (const f of d.fileUploads) {
                    f.httpUploadStatus = {
                        percentageUploaded: 0,
                        status: null
                    };
                    f.fileExtension = "";
                    f.contentType = null;
                }
            }
        }

        this.nameList = this.people.map(p => p.name).join("|, |").split("|");
    }

    docTypeLabel(type: ComplianceProofDocumentType): string {
        const docTypeTKey = "REGISTRATION.DOCUPLOAD.DOC_NAME." + this._docsCountry() + "_" + type;
        let docTypeTranslated = this.translate.instant(docTypeTKey);
        if (docTypeTranslated == docTypeTKey) {
            docTypeTranslated = this.translate.instant("REGISTRATION.DOCUPLOAD.DOC_NAME." + type);
        }
        return docTypeTranslated;
    }
    docTypeHelp(proofType: ComplianceProofType, docType: ComplianceProofDocumentType): string {
        const docTypeHelpTKey = 'REGISTRATION.DOCUPLOAD.HELP.' + proofType + '.' + this._docsCountry() + "_" + docType;
        let docTypeHelpTranslated = this.translate.instant(docTypeHelpTKey);
        if (docTypeHelpTranslated == docTypeHelpTKey) {
            docTypeHelpTranslated = this.translate.instant('REGISTRATION.DOCUPLOAD.HELP.' + proofType + '.' + docType);
        }
        return docTypeHelpTranslated;
    }

    open(e: MouseEvent, document: DocUploadMetadata) {
        document.open = true;
    }

    toggle(e: MouseEvent, document: DocUploadMetadata) {
        e.preventDefault();
        e.stopPropagation();
        document.open = !document.open;
    }

    onDocTypeChange(document: DocUploadMetadata, selected: DocRequestDefinition) {
        document.filesRequired = selected.attachments;
    }

    onDrop(event: DragEvent, person: KYCDocRequest, document: DocUploadMetadata, docFile: DocUploadFile) {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer && event.dataTransfer.files.length) {
            this.onFileSelected(person, document, event.dataTransfer.files, docFile);
        }
    }
    onDragOver(event: DragEvent, person: KYCDocRequest, document: DocUploadMetadata, docFile: DocUploadFile) {
        event.preventDefault();
        event.stopPropagation();
    }
    onDragLeave(event: DragEvent, person: KYCDocRequest, document: DocUploadMetadata, docFile: DocUploadFile) {
        event.preventDefault();
        event.stopPropagation();
    }

    canConfirmDocument(document: DocUploadMetadata) {
        for (const upload of document.fileUploads) {
            if (upload.httpUploadStatus.status === 200) {
                return true;
            }
        }
        return false;
    }

    confirmDocument(person: KYCDocRequest, document: DocUploadMetadata) {
        document.uploadStatus = "CONFIRMING";

        this.registstrationDataService.completeProofUpload(document).then((response) => {
            let allConfirmed = true;
            let nFiles = 0;
            for (const attachmentResponse of response.attachmentMetadataList) {
                const docFile = document.fileUploads.find(f => f.fileName === attachmentResponse.filename);
                if (attachmentResponse.uploadStatus !== "COMPLETED") {
                    allConfirmed = false;
                } else {
                    nFiles++;
                    this.removeFile(document, docFile);
                }
            }
            document.uploadStatus = allConfirmed ? "CONFIRMED" : "ERROR";
            document.open = !allConfirmed;

            if (allConfirmed) {
                this.gtmService.trackDocumentUploadConfirmed(document.proofType, document.docType);
                this.amplitudeService.trackDocumentUploaded(document.proofType, document.docType, nFiles);
                this._openNextDocumentSection();
            }
        });
    }

    _openNextDocumentSection(): void {
        const remainingPeople = this.people.filter(p => p.completion < 100);
        for (var person of remainingPeople) {
            const remainingDocuments = person.requiredDocuments.filter(d => d.uploadStatus != "CONFIRMED");
            for (var doc of remainingDocuments) {
                doc.open = true;
                return;
            }
        }
    }

    getFileUploadStatus(docFile: DocUploadFile): string {
        if (docFile.httpUploadStatus.status == null) {
            return "new";
        }
        if (docFile.httpUploadStatus.status === 200) {
            return "uploaded";
        }
        if (docFile.httpUploadStatus.status === 0) {
            return "uploading";
        }
        return "error";
    }

    refreshPeopleAndDocStatus = () => {
        for (const p of this.people) {
            p.completion = 0;
            let remainingDocs = p.requiredDocuments.length;
            for (const d of p.requiredDocuments) {
                if (d.uploadStatus === "CONFIRMED") {
                    remainingDocs--;
                } else {
                    d.uploadStatus = "NEW";
                    let remainingFiles: number = this._docTypesByCountry(this.docCountry)[d.proofType].find(dt => dt.docType === d.docType).attachments;

                    for (const f of d.fileUploads) {
                        if (f.httpUploadStatus.status === 200) {
                            d.uploadStatus = "UPLOADING";
                            remainingFiles--;
                        }
                    }
                    if (remainingFiles === 0) {
                        remainingDocs--;
                        d.uploadStatus = "UPLOADED";
                    }
                }
            }
            if (remainingDocs === 0) {
                p.completion = 100;
            }
        }
    }

    promptFileSelection(event: MouseEvent, inputElement: HTMLElement): void {
        event.stopPropagation();
        event.preventDefault();
        this.logger.debug(event, inputElement);

        inputElement.focus();
        inputElement.click();
    }

    onFileSelected(person: KYCDocRequest, document: DocUploadMetadata, fileList: FileList, docFile: DocUploadFile): void {
        if (fileList.length) {

            const refreshCallback = this.refreshPeopleAndDocStatus;
            const fileToUpload = fileList[0];

            if (fileToUpload.size > 5242880) {
                docFile.httpUploadStatus.error = "too_large";
                return;
            }
            if (!fileToUpload.type.match(/^(image\/.*|application\/pdf)/)) {
                docFile.httpUploadStatus.error = "wrong_type";
                return;
            }

            docFile.httpUploadStatus.error = null;

            docFile.httpUploadStatus.status = 0;
            docFile.httpUploadStatus.percentageUploaded = 0;
            docFile.fileName = fileToUpload.name;
            docFile.fileExtension = "";
            docFile.contentType = fileToUpload.type;

            document.uploadStatus = "UPLOADING";

            this.uploadFile(document, docFile, fileToUpload).subscribe((a: HttpEvent<any>) => {

                var completion = null;

                if (a.type == HttpEventType.UploadProgress) {

                    const update = a as HttpUploadProgressEvent;
                    completion = Math.floor(update.loaded * 95 / update.total);
                    this.logger.debug("uploading... ", update, completion, "%");

                } else if (a.type == HttpEventType.Response) {

                    const response = a as HttpResponse<any>;

                    if (response.status == 200) {
                        this.logger.debug("upload complete", response);
                        completion = 100;
                        docFile.httpUploadStatus.status = response.status;
                        document.uploadStatus = "UPLOADING";
                        refreshCallback();
                    } else {
                        this.logger.debug("upload error", a);
                        completion = -1;
                        docFile.httpUploadStatus.status = null;
                        document.uploadStatus = "ERROR";
                    }
                }

                if (typeof completion == "number") {
                    docFile.httpUploadStatus.percentageUploaded = completion;
                }

            });

        }
    }

    removeFile(document: DocUploadMetadata, docFile: DocUploadFile) {
        if (!document.uploadStatus.startsWith("CONFIRM")) {
            docFile.fileExtension = "";
            docFile.httpUploadStatus.percentageUploaded = 0;
            docFile.httpUploadStatus.status = null;
            this.refreshPeopleAndDocStatus();
        }
    }

    private uploadFile(document: DocUploadMetadata, fileRequest: DocUploadFile, file: File): Observable<HttpEvent<any>> {
        return this.registstrationDataService.requestAWSS3PresignedUploadURL(document, fileRequest).pipe(switchMap((response: UploadPresignedURLResponse) => {

            const presignedURL: string = response.presignedUploadUrl;
            return this._uploadfileAWSS3(presignedURL, file);

        }));
    }

    private _uploadfileAWSS3(fileuploadurl, file: File): Observable<HttpEvent<any>> {
        const headers = new HttpHeaders({ "Content-Type": file.type });
        this.logger.debug("sending headers ", headers);

        const req = new HttpRequest(
            "PUT",
            fileuploadurl,
            file,
            {
                headers: headers,
                reportProgress: true, // This is required for track upload process
            });
        return this.httpClient.request(req);
    }

    endUploadPhaseButtonDisabled(): boolean {
        for (const p of this.people) {
            for (const d of p.requiredDocuments) {
                if (d.uploadStatus !== "CONFIRMED") {
                    return true;
                }
            }
        }
        return false;
    }

    endUploadPhase(): void {
        this.loading = true;
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }
}
