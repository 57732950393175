import { Component, OnInit } from '@angular/core';
import { RegistrationDataService } from '@shared/services/registrationData.service';

@Component({
    selector: 'save-and-continue',
    templateUrl: './save-and-continue.component.html',
    styleUrls: ['./save-and-continue.component.scss']
})

export class SaveAndContinueComponent implements OnInit {
    emailRecipient: string = null;

    constructor(
        private readonly registrationDataService: RegistrationDataService
    ){}
    ngOnInit(): void {
        this.emailRecipient = this.registrationDataService.getContactEmail();
    }
}