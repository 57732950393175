import { EventEmitter } from "@angular/core";
import { DefaultOutboundActions, OutboundAction, SubStepComponentType } from "@shared/models/app-structure.model";
import { LeadStartData } from "@shared/services/lead-data-mediator.service";

export enum SignupCustomOutboundActions {
    SWITCH_TO_MANUAL_COMPANY_EDIT = "edit_company",
    SWITCH_TO_COMPANY_SEARCH = "search_company"
}
export interface SignupSubStepOutboundAction extends OutboundAction<DefaultOutboundActions | SignupCustomOutboundActions, LeadStartData> {
}
export abstract class SignupSubStepType extends SubStepComponentType<DefaultOutboundActions | SignupCustomOutboundActions, LeadStartData> {
    controlActionEventEmitter: EventEmitter<SignupSubStepOutboundAction>;
}
