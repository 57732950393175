<h3 class="title">{{'REGISTRATION.COMPANY_STEP.IDENTIFIERS.TITLE' | translate}}</h3>

<form [formGroup]="form" novalidate autocomplete="off">

  <!-- Generic Registration Number -->
  <soldo-field-layout *ngIf="market !== 'ITA' && !skipRegistrationNumber"
    [control]="form.controls['registrationNumber']"
    fieldName="registrationNumber"
    [required]="registrationNumberMandatory"
    cypressSelector="Company_TextInput_RegistrationNumber"
    label="REGISTRATION.COMPANY_STEP.IDENTIFIERS.REGISTRATION_NUMBER_LABEL">

    <input input
      name="registrationNumber"
      formControlName="registrationNumber"
      type="text" class="form-control"
      placeholder="{{ 'REGISTRATION.COMPANY_STEP.IDENTIFIERS.REGISTRATION_NUMBER_PLACEHOLDER' + (market === 'IRL' ? '_IRL' : '') | translate }}">

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value: form.get('registrationNumber').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      <soldo-field-error errorName="pattern">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <!-- Italian REA Number -->
  <soldo-field-layout *ngIf="market === 'ITA' && !skipRegistrationNumber"
    [control]="form.controls['registrationNumber']"
    fieldName="registrationNumber"
    [required]="registrationNumberMandatory"
    cypressSelector="Company_TextInput_RegistrationNumber"
    label="REGISTRATION.COMPANY_STEP.IDENTIFIERS.REA_LABEL">

    <input input #inputRea
      name="registrationNumber"
      formControlName="registrationNumber"
      type="text" class="form-control"
      placeholder="{{ 'REGISTRATION.COMPANY_STEP.IDENTIFIERS.REA_PLACEHOLDER' | translate }}"
      (input)="inputRea.value=$event.target.value.toUpperCase()"
      mask="SS-000000000000"
      [validation]="false"
      [specialCharacters]="[ '-' ]"
      [dropSpecialCharacters]="false">

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value: form.get('registrationNumber').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      <soldo-field-error errorName="pattern">{{ 'WRONG_FORMAT_REA_ITA' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <!-- Italian Charities and Associations select the preferred identifier type -->
  <soldo-field-layout *ngIf="isItaCharityOrAsd"
    [control]="form.controls['useCodiceFiscale']"
    fieldName="useCodiceFiscale"
    [required]="false"
    cypressSelector="Company_RadioButton_PartitaIvaOrCodiceFiscale"
    label=""
    class="codice-fiscale-or-vat">

    <div input class="fiscal-code-checkbox">
      <div>
        <input id="vat-number" type="radio" [value]=false formControlName="useCodiceFiscale" name="useCodiceFiscale"
          data-cy="Company_RadioButton_PartitaIvaOrCodiceFiscale_PARTITAIVA">
        <label for="vat-number">{{ 'REGISTRATION.COMPANY_STEP.IDENTIFIERS.PIVA_LABEL' | translate }}</label>
      </div>
      <div>
        <input id="fiscal-code" type="radio" [value]=true formControlName="useCodiceFiscale" name="useCodiceFiscale"
          data-cy="Company_RadioButton_PartitaIvaOrCodiceFiscale_CODICEFISCALE">
        <label for="fiscal-code">{{ 'REGISTRATION.COMPANY_STEP.IDENTIFIERS.CODICE_FISCALE_LABEL' | translate }}</label>
      </div>
    </div>

  </soldo-field-layout>


  <!-- VAT Number -->
  <soldo-field-layout *ngIf="form.get('vatNumber')"
    [control]="form.controls['vatNumber']"
    fieldName="vatNumber"
    [required]="market === 'ITA' && !isItaCharityOrAsd"
    cypressSelector="Company_TextInput_VatNumber"
    label="{{ isItaCharityOrAsd ? '' : 'REGISTRATION.COMPANY_STEP.IDENTIFIERS.' + ((market == 'ITA') ? 'PIVA' : 'VAT_NUMBER') + '_LABEL'}}"
    [ngClass]="{'isItaChairtyOrAsd': isItaCharityOrAsd}">

    <input input
      type="text"
      class="form-control"
      placeholder="{{ 'REGISTRATION.COMPANY_STEP.IDENTIFIERS.' + ((market == 'ITA') ? 'PIVA' : 'VAT_NUMBER') + '_PLACEHOLDER' + (market === 'IRL' ? '_IRL' : '') | translate }}"
      name="vatNumber"
      formControlName="vatNumber" >

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value: form.get('vatNumber').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      <soldo-field-error errorName="pattern">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <!-- Codice Fiscale -->
  <soldo-field-layout *ngIf="form.get('codiceFiscale')"
    [control]="form.controls['codiceFiscale']"
    fieldName="codiceFiscale"
    [required]="market === 'ITA' && !isItaCharityOrAsd"
    cypressSelector="Company_TextInput_CodiceFiscale"
    label="{{ isItaCharityOrAsd ? '' : 'REGISTRATION.COMPANY_STEP.IDENTIFIERS.CODICE_FISCALE_LABEL'}}">

    <input input type="text" class="form-control"
      placeholder="{{ 'REGISTRATION.COMPANY_STEP.IDENTIFIERS.CODICE_FISCALE_PLACEHOLDER' | translate }}"
      name="codiceFiscale"
      formControlName="codiceFiscale">

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value: form.get('codiceFiscale').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      <soldo-field-error errorName="pattern">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <!-- Business Name -->
  <soldo-field-layout
    [control]="form.controls['businessName']"
    fieldName="businessName"
    [required]="true"
    cypressSelector="Company_TextInput_BusinessName"
    label="REGISTRATION.COMPANY_STEP.IDENTIFIERS.BUSINESS_NAME_LABEL">

    <input input type="text" class="form-control"
      placeholder="{{ 'REGISTRATION.COMPANY_STEP.IDENTIFIERS.BUSINESS_NAME_PLACEHOLDER' | translate }}"
      name="businessName"
      formControlName="businessName">

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value: form.get('businessName').errors?.maxlength?.requiredLength} }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <!-- Trading Name -->
  <soldo-field-layout *ngIf="form.get('tradingName')"
    [control]="form.controls['tradingName']"
    fieldName="tradingName"
    [required]="false"
    cypressSelector="Company_TextInput_TradingName"
    label="REGISTRATION.COMPANY_STEP.IDENTIFIERS.TRADING_NAME_LABEL">

    <input input type="text" class="form-control"
        placeholder="{{ 'REGISTRATION.COMPANY_STEP.IDENTIFIERS.TRADING_NAME_PLACEHOLDER' | translate }}"
        name="tradingName"
        formControlName="tradingName">

    <soldo-field-info infoMessage>{{ 'REGISTRATION.COMPANY_STEP.IDENTIFIERS.TRADING_NAME_INFO' | translate }}</soldo-field-info>

    <ng-container errorMessage>
      <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value: form.get('tradingName').errors?.maxlength?.requiredLength} }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

</form>

<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.COMPANY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.COMPANY_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
