<main>
  <app-header></app-header>
  <message-alerts></message-alerts>
  <div class="content">
    <modal-dialog></modal-dialog>
    <resume-overlay *ngIf="!isStaticRoute"></resume-overlay>
    <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <button *ngIf="startOverButtonEnabled" class="link" (click)="startOver()">{{ "START_OVER" | translate }}</button>
  </div>
</main>

<div class="version caption" [ngClass]="{ 'shown': showVersion}">v. {{ appVersion }}</div>
