import { ModuleWithProviders, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot } from '@angular/router';
import { CountryService } from '@shared/services/country.service';
import { FiscalCodeService, TownCodesFile } from '@shared/services/fiscal-code.service';
import { StatusService } from '@shared/services/status.service';
import { CompanyStepComponent } from './company-step/company-step.component';
import { DirectorComponent } from './director/director.component';
import { RegistrationComponent } from './registration.component';
import { SignupStepComponent } from './signup-step/signup-step.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { ExpectedActivityStepComponent } from './expected-activity-step/expected-activity-step.component';
import { BeneficialOwnerChoiceComponent } from './beneficial-owner-choice/beneficial-owner-choice.component';
import { BeneficialOwnersComponent } from './beneficial-owners/beneficial-owners.component';
import { AdminComponent } from './admin/admin.component';
import { ApplicantPreIdvPageComponent } from './applicant-pre-idv-page/applicant-pre-idv-page.component';

export const registrationRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
    {
        path: 'registration',
        component: RegistrationComponent,
        canActivateChild: [
            (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(StatusService).canActivateChild(childRoute, state)
        ],
        resolve: {
            countriesNamesTranslations: (): Promise<void> => inject(CountryService).resolve()
        },
        children: [
            {
                path: 'welcome',
                data: {
                    pageName: "Welcome"
                },
                component: SignupStepComponent
            },
            {
                path: 'company',
                data: {
                    pageName: "Company Info"
                },
                component: CompanyStepComponent
            },
            {
                path: 'director',
                data: {
                    pageName: "Director Details"
                },
                component: DirectorComponent,
                resolve: {
                    formData: (): Promise<TownCodesFile> => inject(FiscalCodeService).resolve(),
                    itTowns: (): Promise<void> => inject(CountryService).resolve()
                }
            },
            {
                path: 'account',
                data: {
                    pageName: "Account Admin"
                },
                component: AdminComponent,
                resolve: {
                    formData: (): Promise<TownCodesFile> => inject(FiscalCodeService).resolve(),
                    itTowns: (): Promise<void> => inject(CountryService).resolve()
                }
            },
            {
                path: 'beneficial-owners',
                data: {
                    pageName: "Beneficial Owners"
                },
                component: BeneficialOwnerChoiceComponent
            },
            {
                path: 'beneficial-owners-details',
                data: {
                    pageName: "Beneficial Owners Details"
                },
                component: BeneficialOwnersComponent
            },
            {
                path: 'expected-activity',
                data: {
                    pageName: "Expected Activity"
                },
                component: ExpectedActivityStepComponent
            },
            {
                path: 'terms-conditions',
                data: {
                    pageName: "Terms and Conditions"
                },
                component: TermsConditionComponent
            },
            {
                path: 'verify-your-identity',
                data: {
                    pageName: "Identity Verification",
                    canAccessAfterTerms: true
                },
                component: ApplicantPreIdvPageComponent
            }
        ]
    }
]);
