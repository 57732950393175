<h3 class="title">{{'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.TITLE' | translate}}</h3>
<p class="subtitle">{{'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.SUB_TITLE' | translate}}</p>

<form [formGroup]="form" novalidate autocomplete="off">

  <soldo-field-layout *ngIf="form.get('companySize')"
    [control]="form.controls['companySize']"
    fieldName="companySize"
    [required]="true"
    cypressSelector="Singup_RadioButton_EntitySize"
    label="REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.NUM_EMPLOYEES_LABEL">

    <div input class="radio-main">
      <div class="radio-list radio-list-top">

        <div class="radio-item fewer-than-fifty-radio"
          [attr.data-cy]="'Singup_RadioButton_EntitySize_'+companySizeOptions[0].value"
          [class.active]="fewerThan50Mode"
          [class.hidden]="!fewerThan50Mode">

          <input type="radio" formControlName="companySize" [id]="'OPT0'" [value]="companySizeOptions[0].value">
          <label class="fewer-than-50-label" [for]="'OPT0'">{{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.FEWER_THAN_50' | translate }}</label>

          <soldo-field-layout  *ngIf="form.get('companySize')"
            [control]="form.controls['companySizeFewerThan50']"
            fieldName="companySize"
            [required]="true"
            cypressSelector="Signup_TextInput_EntitySize">

            <input input class="form-control mt-3" *ngIf="fewerThan50Mode" type="text" inputmode="tel"
                    formControlName="companySizeFewerThan50" [placeholder]="" (focus)="$event.target.select()">

            <ng-container errorMessage>
              <soldo-field-error error errorName="required">{{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.REQUIRED_LESS_THAN_50' | translate }}</soldo-field-error>
              <soldo-field-error error errorName="min">{{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.ENTER_VALID_NUMBER' | translate  }}</soldo-field-error>
              <soldo-field-error error errorName="max">{{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.ENTER_VALID_NUMBER' | translate  }}</soldo-field-error>
              <soldo-field-error error errorName="pattern">{{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.ENTER_VALID_NUMBER' | translate  }}</soldo-field-error>
            </ng-container>
          </soldo-field-layout>

        </div>

        <div *ngFor="let i of [1,2,3,4,5]" class="radio-item"
          [attr.data-cy]="'Singup_RadioButton_EntitySize_'+companySizeOptions[i].value">
          <input type="radio" formControlName="companySize" [id]="'OPT'+i" [value]="companySizeOptions[i].value">
          <label [for]="'OPT'+i">{{ companySizeOptions[i].label }}</label>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!fewerThan50Mode" errorMessage>
      <soldo-field-error error errorName="required">{{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.RANGE_REQUIRED' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>
</form>

<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.SIGNUP_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.SIGNUP_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
