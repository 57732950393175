<h2>{{'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.TITLE' | translate}}</h2>
<h5>{{'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.SUB_TITLE' | translate}}</h5>

<div class="layout-section">
  <form [formGroup]="form" novalidate autocomplete="off">
    <div class="form-input" [attr.data-cy]="'Singup_RadioButton_EntitySize'"
      [ngClass]="{ 'ng-touched': !fewerThan50Mode && form?.controls.companySize?.touched }">
      <label for="companySize">{{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.NUM_EMPLOYEES_LABEL' | translate }}</label>

      <fieldset class="chips-radio">
        <div class="radio-item" [attr.data-cy]="'Singup_RadioButton_EntitySize_'+companySizeOptions[0].value">

          <input type="radio" formControlName="companySize" id="OPT0" [value]="companySizeOptions[0].value">
          <label for="OPT0">{{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.FEWER_THAN_50' | translate }}</label>

          <div class="conditional-subfield-container"  *ngIf="fewerThan50Mode">
            <soldo-field-layout
              [control]="form.controls['companySizeFewerThan50']"
              fieldName="companySize"
              cypressSelector="Signup_TextInput_EntitySize">

              <input input type="text" inputmode="tel"
                      formControlName="companySizeFewerThan50" (focus)="$event.target.select()">

              <ng-container errorMessage>
                <soldo-field-error error errorName="required">{{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.NUMBER_REQUIRED' | translate }}</soldo-field-error>
                <soldo-field-error error errorName="min">{{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.ENTER_VALID_NUMBER' | translate  }}</soldo-field-error>
                <soldo-field-error error errorName="max">{{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.ENTER_VALID_NUMBER' | translate  }}</soldo-field-error>
                <soldo-field-error error errorName="pattern">{{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.ENTER_VALID_NUMBER' | translate  }}</soldo-field-error>
              </ng-container>
            </soldo-field-layout>
          </div>
        </div>

        <div *ngFor="let i of [1,2,3,4,5]" class="radio-item"
          [attr.data-cy]="'Singup_RadioButton_EntitySize_'+companySizeOptions[i].value">
          <input type="radio" formControlName="companySize" [id]="'OPT'+i" [value]="companySizeOptions[i].value">
          <label [for]="'OPT'+i">{{ companySizeOptions[i].label }}</label>
        </div>
      </fieldset>
      <div class="validation-errors-container">
        <div class="caption field-validation-error" *ngIf="form?.controls.companySize?.touched && form.controls.companySize.errors?.required">
          {{ 'REGISTRATION.SIGNUP_STEP.NUM_EMPLOYEES.RANGE_REQUIRED' | translate }}
        </div>
      </div>
    </div>

    <!-- Sub-Step Navigation -->
    <div class="form-page-CTA-row">
      <button type="button" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
        {{ 'REGISTRATION.SIGNUP_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
      </button>
      <button type="submit" class="primary" (click)="nextStep()" [disabled]="loading" [ngClass]="{ 'loading': loading}"
        data-cy="Signup_Button_NextStepButton">
        {{ 'REGISTRATION.SIGNUP_STEP.NEXT_SUB_STEP_BUTTON' | translate }}
      </button>
    </div>
  </form>
</div>
