import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPagesRoutingModule } from './static-pages-routing.module';
import { SharedModule } from '../shared/shared.module';
import { StaticPreIdvPageComponent } from './static-pre-idv-page/static-pre-idv-page.component';
import { EmbeddedIdvWithMitekComponent } from './embedded-idv-with-mitek/embedded-idv-with-mitek.component';
import { EmbeddedIdvWithOnfidoComponent } from './embedded-idv-with-onfido/embedded-idv-with-onfido.component';
import { IdentityVerifiedComponent } from './identity-verified/identity-verified.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectSalesThankYouPageComponent } from './direct-sales-thank-you-page/direct-sales-thank-you-page.component';
import { InternalUserIdvComponent } from './internal-user-idv/internal-user-idv.component';
import { InternalUserIdentityVerifiedComponent } from './internal-user-identity-verified/internal-user-identity-verified.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';


@NgModule({
    declarations: [
        StaticPreIdvPageComponent,
        EmbeddedIdvWithMitekComponent,
        EmbeddedIdvWithOnfidoComponent,
        IdentityVerifiedComponent,
        DirectSalesThankYouPageComponent,
        InternalUserIdvComponent,
        InternalUserIdentityVerifiedComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        StaticPagesRoutingModule,
        TranslateModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgxMaskDirective
    ]
})
export class StaticPagesModule { }
