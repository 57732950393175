import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { CompanyStepComponent } from './company-step.component';
import { CompanyDescriptionComponent as CompanyDescriptionSubStepComponent } from './substeps/company-description/company-description.component';
import { CompanyIdentifiersComponent as CompanyIdentifiersSubStepComponent } from './substeps/company-identifiers/company-identifiers.component';
import { CompanyRegisteredAddressComponent as CompanyRegisteredAddressSubStepComponent }
    from './substeps/company-registered-address/company-registered-address.component';
import { CompanyTradingAddressComponent as CompanyTradingAddressSubStepComponent } from './substeps/company-trading-address/company-trading-address.component';
import { CompanySalesChannelComponent as CompanySalesChannelSubStepComponent } from './substeps/company-sales-channel/company-sales-channel.component';
import { CompanyInternationalComponent as CompanyInternationalSubStepComponent } from './substeps/company-international/company-international.component';
import { NgxMaskDirective } from 'ngx-mask';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        TranslateModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgxMaskDirective
    ],
    declarations: [
        CompanyStepComponent,
        CompanyIdentifiersSubStepComponent,
        CompanyDescriptionSubStepComponent,
        CompanyRegisteredAddressSubStepComponent,
        CompanyTradingAddressSubStepComponent,
        CompanySalesChannelSubStepComponent,
        CompanyInternationalSubStepComponent
    ]
})
export class CompanyStepModule { }
