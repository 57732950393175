
import { Component, EventEmitter, Injectable, Type } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class ModalDialogService {

    modalDialogEventEmitter: EventEmitter<Type<Component>> = new EventEmitter();

    showModalDialog(contentComponentType:Type<Component>): void {
        this.modalDialogEventEmitter.emit(contentComponentType);
    }
    closeModalDialog(): void {
        this.modalDialogEventEmitter.emit(null);
    }
}
