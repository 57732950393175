<header>
  <h3 class="title">{{ "REGISTRATION.ACCOUNT.TITLE" | translate }}</h3>
  <p>{{ "REGISTRATION.ACCOUNT.DESCRIPTION" | translate }}</p>
</header>

<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
  <div [formGroup]="form">
    <h5 class="mt-5">{{ "REGISTRATION.ACCOUNT.ACCOUNT_ADMIN" | translate }}</h5>
    <div class="row my-4 select-contacts">
      <div class="col-md-7 contacts" data-cy="Admin_Checkbox_DirectorIsAdminArray">
        <div formGroupName="director">
          <div data-cy="Admin_Checkbox_DirectorIsAdmin">
            <input id="contact-director" type="checkbox" formControlName="isAdmin" #checkDirector (change)="directorChecked(checkDirector.checked)">
              <label for="contact-director" class="single-line">{{ form.get('director').get('firstName').value }} {{
                form.get('director').get('lastName').value }}</label>
          </div>
        </div>
        <div formArrayName="contacts">
          <div *ngFor="let contact of getContactsFormArray().controls; let i=index" [formGroupName]="i">
            <div *ngIf="getContactsFormArray().at(i).get('contactExternalId').value" data-cy="Admin_Checkbox_DirectorIsAdmin">
              <input id="contact-{{i}}" type="checkbox" formControlName="isAdmin" #checkbox (change)="onCheck(checkbox.checked, i)">
              <label for="contact-{{i}}" class="single-line">{{
                  getContactsFormArray().at(i).get('firstName').value }} {{
                  getContactsFormArray().at(i).get('lastName').value }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="form.touched && (admins == 0)" class="text-danger relative one-admin">
      {{ "ONE_ADMIN" | translate }}
    </div>

    <div *ngIf="admins > 0" data-cy="Admin_Contact_DirectorAdminGroupArray">
      <div formGroupName="director" class="contacts-badge">
        <div *ngIf="form.get('director').get('isAdmin').value">
          <h5 class="my-4">
            {{ form.get('director').get('firstName').value }} {{
              form.get('director').get('lastName').value }}
          </h5>
          <person-form-group [group]="form.get('director')" [readonly]="true"></person-form-group>
        </div>
      </div>

      <div formArrayName="contacts" class="contacts-badge">
        <div *ngFor="let contact of getContactsFormArray().controls; let i=index" [formGroupName]="i" data-cy="Admin_Contact_DirectorAdminGroup">
          <div *ngIf="!getContactsFormArray().at(i).get('isNew').value && getContactsFormArray().at(i).get('isAdmin').value">
            <h5 class="my-4">
              {{ getContactsFormArray().at(i).get('firstName').value }} {{
                getContactsFormArray().at(i).get('lastName').value }}
            </h5>
            <person-form-group [group]="getContactsFormArray().at(i)"></person-form-group>

          </div>
        </div>
      </div>
    </div>
    <div formArrayName="contacts" data-cy="Admin_Contact_NewAdminGroupArray">
      <div *ngFor="let contact of getContactsFormArray().controls; let i = index" [formGroupName]="i">
        <div *ngIf="getContactsFormArray().at(i).get('isNew').value" data-cy="Admin_Contact_NewAdminGroup">

          <div class="admin-form-header">
            <h5 class="my-4">
              {{ "REGISTRATION.ACCOUNT.ADDITIONAL_ADMIN" | translate }} {{ i+1 }}
            </h5>
            <button class="btn gray-text" data-cy="Admin_Contact_RemoveNewAdmin"
              (click)="removeContact(i)">
              <span>{{ 'REMOVE' | translate }}</span>
            </button>
          </div>

          <person-form-group [group]="getContactsFormArray().at(i)" data-cy="Admin_Contact_NewAdmin"></person-form-group>
        </div>
      </div>
    </div>

    <button class="btn orange-on-white" type="button" (click)="addContact()" *ngIf="canCreateMoreAdmins()" data-cy="Admin_Button_AddAdmin">
      <span>{{ "REGISTRATION.ACCOUNT.ADD" | translate }}</span><i class="md-icon">add_circle</i>
    </button>
  </div>

  <div class="row mt-5">
      <div class="col-6">
          <button type="button" class="btn gray-text go-back" (click)="goToPrevious()"
            data-cy="Admin_Button_StepBackButton"><i class="md-icon">arrow_right_alt</i>
            <span>{{ 'PREVIOUS' | translate }}</span>
          </button>
      </div>
      <div class="col-6 text-right">
          <button type="submit" class="btn btn-primary" [disabled]="loading" [ngClass]="{ 'in-progress': loading}"
            data-cy="Admin_Button_StepSubmitButton">{{ 'NEXT' | translate }}</button>
      </div>
  </div>
</form>
<save-and-continue-later stepName="Account Admin"></save-and-continue-later>
