import { Injectable, Inject } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import * as moment from 'moment-mini';
import * as constants from "./fiscal-code.costants";
import { HttpClient } from "@angular/common/http";
import { APP_CONFIG, AppConfig } from "@src/app/app-config.module";


interface TownCode {
    name: string;
    province: string;

}
export interface TownCodesFile {
    default: {
        [key: string]: TownCode
    }
}

@Injectable()
export class FiscalCodeService  {

    // trick to get a static inizialization
    private static town_codes: { [key: string]: TownCode } = null;

    private readyPromise: Promise<TownCodesFile>;

    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
        this.readyPromise = import("../data/town_codes.json");
        this.readyPromise.then(result => {
            FiscalCodeService.town_codes = result.default;
        });
    }

    static retrieveBirthDate(fiscalCode: string): { year: number, month: number, day: number } {
        if (!fiscalCode) {
            return null;
        }

        const str_day = fiscalCode.substring(9, 11);
        const str_month = fiscalCode.substring(8, 9).toUpperCase();
        const month_number = constants.MONTH_CODES.indexOf(str_month);
        let str_year = fiscalCode.substring(6, 8);
        let int_day = parseInt(str_day);
        if (int_day > 40) {
            int_day -= 40;
        }
        const int_year = parseInt(str_year);
        const currentFullYear = (new Date()).getFullYear().toString();
        const last_2_digit_currentFullYear = currentFullYear.substring(2);
        if (int_year <= parseInt(last_2_digit_currentFullYear)) {
            str_year = currentFullYear.substring(0, 2) + str_year;
        } else {
            str_year = String(parseInt(currentFullYear.substring(0, 2)) - 1) + str_year;
        }

        const dob = moment([parseInt(str_year), month_number, int_day]);

        return {
            year: dob.year(),
            month: dob.month(),
            day: dob.date()
        };
    }

    static refetrieveSex(fiscalCode: string): string {
        const str_day = fiscalCode.substring(9, 11);
        const int_day = parseInt(str_day);
        if (int_day > 40) {
            return 'F';
        }
        return 'M';
    }

    static stringReplaceAt(string, index, character): string {
        return [string.substr(0, index), character, string.substr(character.length + index)].join('');
    }

    public retrieveBirthPlace(fiscalCode: string): { state: string, town: string } {
        if (!fiscalCode) {
            return null;
        }

        let town_code = fiscalCode.substring(11, 15).toUpperCase();
        for (let i = 1; i <= 3; i++) {
            const c = town_code.toUpperCase().charAt(i);
            if (constants.OMOCODIA_TABLE.indexOf(c) !== -1) {
                //                    str_codice_catasto = str_codice_catasto.replaceAt(i, this.omocodia_table_code.indexOf(c).toString());
                town_code = FiscalCodeService.stringReplaceAt(town_code, i, constants.OMOCODIA_TABLE.indexOf(c).toString());
            }
        }

        const townCodes = FiscalCodeService.town_codes;
        if (townCodes && townCodes.hasOwnProperty(town_code)) {

            const town = townCodes[town_code];

            return {
                state: town.province,
                town: town.name
            };

        } else {
            return null;
        }
    }

    resolve(): Promise<TownCodesFile> {
        return this.readyPromise;
    }

    validator = (control: AbstractControl): ValidationErrors | null => {
        const regex: RegExp = new RegExp("^[a-zA-Z]{6}[l-vL-V0-9]{2}[a-zA-Z][l-vL-V0-9]{2}[a-zA-Z][l-vL-V0-9]{3}[a-zA-Z]$");
        const cf = control.value;
        if (cf === null || cf === "") {
            return null;
        }
        return (cf
            && regex.test(cf)
            && this.validateCheckDigit(cf)
            && moment(FiscalCodeService.retrieveBirthDate(cf)).isValid()
            && (cf && (this.retrieveBirthPlace(cf) || cf.charAt(11).toUpperCase() === "Z"))
        ) ? null : { 'wrongFormat': { value: cf } };
    };

    calculateCheckDigit(codicefiscale: string): string {
        let i;
        let val = 0;
        const codicefiscale_to_upper = codicefiscale.toUpperCase();
        for (i = 0; i < 15; i++) {
            const c = codicefiscale_to_upper.charAt(i);
            if (i % 2) {
                val += constants.EVEN_CHECK_DIGITS[c];
            } else {
                val += constants.ODD_CHECK_DIGITS[c];
            }
        }
        val = val % 26;
        return constants.CHECK_DIGITS_MATRIX.charAt(val);
    }

    validateCheckDigit(codicefiscale: string): boolean {
        return codicefiscale.substring(15).toUpperCase() == this.calculateCheckDigit(codicefiscale);
    }

    validateAgainstLastName(codicefiscale: string, lastName: string): boolean {
        return codicefiscale.toUpperCase().substring(0, 3) == this._buildLastNamePart(lastName);
    }

    validateAgainstFirstName(codicefiscale: string, firstName: string): boolean {
        return codicefiscale.toUpperCase().substring(3, 6) == this._buildFirstNamePart(firstName);
    }

    _buildFirstNamePart(firstName: string): string {
        const normalizedFirstName = this._normalizeString(firstName);
        const consonants: string[] = [];
        const vowels: string[] = [];
        let namePart = ["X", "X", "X"];
        for (let i = 0; i < normalizedFirstName.length; i++) {
            const char = normalizedFirstName.charAt(i);
            if (constants.VOWELS.indexOf(char) > -1) {
                vowels.push(char);
            } else if (constants.CONSONANTS.indexOf(char) > -1) {
                consonants.push(char);
            }
        }
        if (consonants.length > 3) {
            namePart = [consonants[0], consonants[2], consonants[3]];
        } else {
            namePart[0] = consonants.shift() || vowels.shift() || "X";
            namePart[1] = consonants.shift() || vowels.shift() || "X";
            namePart[2] = consonants.shift() || vowels.shift() || "X";
        }
        return namePart.join('');
    }
    _buildLastNamePart(lastName: string): string {
        const normalizedLastName = this._normalizeString(lastName);
        const consonants = [];
        const vowels = [];
        const namePart = ["X", "X", "X"];
        for (let i = 0; i < normalizedLastName.length; i++) {
            const char = normalizedLastName.charAt(i);
            if (constants.VOWELS.indexOf(char) > -1) {
                vowels.push(char);
            } else if (constants.CONSONANTS.indexOf(char) > -1) {
                consonants.push(char);
            }
        }
        namePart[0] = consonants.shift() || vowels.shift() || "X";
        namePart[1] = consonants.shift() || vowels.shift() || "X";
        namePart[2] = consonants.shift() || vowels.shift() || "X";
        return namePart.join('');
    }

    _normalizeString(original: string): string {
        return original.toUpperCase().replace(/\s/, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
}
