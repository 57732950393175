<div class="form-input" [attr.data-cy]="cypressSelector"
  [ngClass]="{ 'ng-touched': control.touched, 'ng-invalid': control.invalid }">

  <label *ngIf="label" [for]="fieldName">
    {{ label | translate }} <soldo-tooltip *ngIf="hint && useTooltip" [position]="tooltipPosition" [text]="hint"></soldo-tooltip>
  </label>

  <div class="hint-text caption" [ngClass]="{'mobile-only': useTooltip}">
    {{ hint | translate }}
  </div>

  <div class="input-container">
    <ng-content select="[input]"></ng-content>
  </div>

  <div class="validation-errors-container">
    <ng-content select="[errorMessage]"></ng-content>
  </div>

</div>
