<h2>{{'REGISTRATION.SIGNUP_STEP.ENTITY_NAME.TITLE' | translate}}</h2>

<div class="layout-section">
  <form [formGroup]="form" novalidate autocomplete="off">

    <soldo-field-layout
      [control]="form.controls['companyName']"
      fieldName="companyName"
      cypressSelector="Signup_TextInput_EntityName"
      [label]="'REGISTRATION.SIGNUP_STEP.ENTITY_NAME.' + (soleTrader ? 'TRADING_NAME' : 'COMPANY_NAME') + '_LABEL'">

      <input input
        name="companyName"
        type="text"
        formControlName="companyName"
        placeholder="{{ 'REGISTRATION.SIGNUP_STEP.ENTITY_NAME.' + (soleTrader ? 'TRADING_NAME' : 'COMPANY_NAME') + '_PLACEHOLDER' | translate }}">

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REGISTRATION.SIGNUP_STEP.ENTITY_NAME.COMPANY_NAME_REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="maxlength">
          {{ 'MAX_LENGHT' | translate : { value: form.get('companyName').errors?.maxlength?.requiredLength} }}
        </soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <button class="link no-padding"
            *ngIf="isCompanySearchAvailable()"
            data-cy="Signup_Button_Switch_To_Company_Search" type="button"
            (click)="switchToCompanySearch()">
      {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SWITCH_COMPANY_SEARCH_LINK' | translate}}
    </button>

    <!-- Sub-Step Navigation -->
    <div class="form-page-CTA-row">

        <button type="button" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
          {{ 'REGISTRATION.SIGNUP_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
        </button>

        <button type="submit" class="primary" (click)="nextStep()" [disabled]="loading"
          [ngClass]="{ 'loading': loading}" data-cy="Signup_Button_NextStepButton">
          {{ 'REGISTRATION.SIGNUP_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
        </button>

    </div>
  </form>
</div>
