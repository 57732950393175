import { Component, EventEmitter } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { LeadStartData } from '@shared/services/lead-data-mediator.service';
import { SignupCustomOutboundActions, SignupSubStepOutboundAction, SignupSubStepType } from '../../signup-step.model';
import { NGXLogger } from 'ngx-logger';
import { CompanySearchProfileStatus, CompanySearchResult } from '@shared/models/company-search.model';
import { CompanySearchService } from '@shared/services/company-search.service';
import { regexValidators } from '@shared/customValidators';
@Component({
    selector: 'substep-company-search',
    templateUrl: './company-search.component.html',
    styleUrls: ['./company-search.component.scss']
})
export class CompanySearchSubStepComponent extends SignupSubStepType {
    subStepName: string = "Company Search";

    form: UntypedFormGroup;
    searchResultControl: FormControl<string>;
    soleTrader: boolean = false;
    country: string;
    searchTerm: string = "";
    termSearched: string = "";
    searchBy: "NAME" | "REGISTRATION_NUMBER" | "VAT_CODE" = "NAME";
    registrationNumberRegExp: RegExp = /^\w{2}\d{3,}$/;

    searchResult: CompanySearchResult = null;
    tooManyResults: number = 20;

    kybProviderId: string;
    noResultFound: boolean;
    companyName: string;
    companyNumber: string;
    selectedCompanyIndex: number = -1;

    searchLoading = false;

    constructor(
        private logger: NGXLogger,
        private fb: UntypedFormBuilder,
        private companySearchService: CompanySearchService,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService) {

        super();
        this.controlActionEventEmitter = new EventEmitter<SignupSubStepOutboundAction>();
    }

    isValid(): boolean {
        this.form.get("companySearch").markAsTouched();
        return this.form.valid;
    }

    init(d: Partial<LeadStartData>): void {
        this.country = d.companyCountry;
        const validators = [Validators.required, Validators.maxLength(64)];
        if (this.country === "ITA") {
            validators.push(Validators.pattern(regexValidators.piva));
        }
        this.form = this.fb.group({
            "companySearch": ["", validators]
        });
        this.searchResultControl = new FormControl<string>(d.kybProviderId);
        this.loading = false;
    }

    getData(): Partial<LeadStartData> {
        return {};
    }

    getInputValue($event) {
        this.form.get("companySearch").markAsTouched();
        this.searchTerm = $event.target.value;
    }

    onSearchButtonClicked(): void {
        this.form.get("companySearch").markAsTouched();
        this.searchResult = null;
        this.kybProviderId = null;
        this.selectedCompanyIndex = -1;

        if (this.form.valid) {
            this.searchLoading = true;
            this.termSearched = this.searchTerm;
            this.companySearchService.search(this.termSearched, this.country).then((result) => {
                this.searchLoading = false;
                this.searchResult = result;
                this.searchResult.companySearchResults = result.companySearchResults || [];
                this.amplitudeService.trackCompanySearchPerformed(this.searchTerm, result.totalResults, this.registrationNumberRegExp.test(this.searchTerm));
                if (this.country === "ITA" && this.searchResult.companySearchResults.length > 0) {
                    this.searchResultControl.setValue(this.searchResult.companySearchResults[0].kybProviderId);
                    this.nextStep();
                }
            }, (err) => this.logger.trace("FATAL: ", err));
        }
        if (this.country === "ITA") {
            this.searchBy = "VAT_CODE";
        } else if (this.registrationNumberRegExp.test(this.termSearched)) {
            this.searchBy = "REGISTRATION_NUMBER";
        } else {
            this.searchBy = "NAME";
        }

    }

    statusToBeTagged(status: CompanySearchProfileStatus): string {
        let statusToBeTagged = null;
        if (status && !(status == CompanySearchProfileStatus.ACTIVE || status == CompanySearchProfileStatus.OPEN)) {
            statusToBeTagged = status.split("-").map(s => s.slice(0, 1).toUpperCase() + s.slice(1)).join(" ");
        }
        return statusToBeTagged;
    }

    onResultClicked(kybProviderId: string): void {
        this.searchResultControl.setValue(kybProviderId);
        this.nextStep();
    }

    onSubmit(event): void {
        event.preventDefault();
        this.nextStep();
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.searchResultControl.value) {
            this.loading = true;
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: {
                    kybProviderId: this.searchResultControl.value
                }
            });
        }
    }

    enterManually(): void {
        this.loading = true;
        this.controlActionEventEmitter.emit({
            action: SignupCustomOutboundActions.SWITCH_TO_MANUAL_COMPANY_EDIT,
            data: {
                kybProviderId: null
            }
        });
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

    doNothing(): null {
        // Sonar quality gate bypass
        return null;
    }

}
