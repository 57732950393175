import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment-mini';

const piva = /^(it|IT)?[0-9]{11}$/;
const eircode = /^([AC-FHKNPRTV-Y][0-9]{2}|D6W)(\s)?[0-9AC-FHKNPRTV-Y]{4}$/i;
const codicePostale = /^[0-9]{5}$/;
const ukPostCode = /^[A-z]([0-9]|[0-9][A-z0-9]|[A-z][0-9]|[A-z][0-9][A-z0-9])\s?[0-9][A-z]{2}$/i;
const netherlandsPostcode = /^[0-9]{4}\s?([A-RT-Z][A-Z]|S[B-CE-RT-Z])$/i;
const germanPostcode = /^[0-9]{5}$/;
const frenchPostcode = /^[0-9]{5}/; // allowing chars after the 5 digits on purpose, see CEDEX
const spanishPostcode = /^[0-9]{5}$/;
const emailLocalPartWhitelist = /^([a-zA-Z\d\.\-\_\+\&\*])*$/;
const emailSubdomainsWhitelist = /^([a-zA-Z\d\-\.])*$/;
const emailTopLevelDomainWhitelist = /^([a-zA-Z])*$/;
const singleAtInTheMiddle = /^[^\@]+\@[^\@]+$/;

export const regexValidators = {
    phone: '[0-9 ]{6,13}$',
    alphaNum: '[0-9A-Za-z]*',
    piva: piva,
    rea: '[A-Z]{2}\-[0-9]+',
    postcodesByCountry: {
        IRL: eircode,
        ITA: codicePostale,
        GBR: ukPostCode,
        NLD: netherlandsPostcode,
        DEU: germanPostcode,
        FRA: frenchPostcode,
        ESP: spanishPostcode
    }
};

export class CustomValidators {

    public static fieldsMatch(firstControlName, secondControlName): ValidationErrors | null {
        return (AC: AbstractControl) => {
            const firstControlValue = AC.get(firstControlName); // to get value in input tag
            const secondControlValue = AC.get(secondControlName); // to get value in input tag
            if (firstControlValue.value !== secondControlValue.value) {
                return {
                    "MatchFields": true
                };
            } else {
                return null;
            }
        };
    }

    public static validPhone(prefixControlName, numberControlName): ValidatorFn {
        return (AC: AbstractControl) => {
            const prefixControl = AC.get(prefixControlName);
            const numberControl = AC.get(numberControlName);
            const phoneRegex = new RegExp(regexValidators.phone);
            if (numberControl.value) {

                //console.log("Validating phone number ", ['"',prefixControl.value,'"'].join(''), ['"',numberControl.value,'"'].join(''))

                if (prefixControl.value === '+39' || prefixControl.value === '+44') {
                    if (prefixControl.value === '+39' && (!numberControl.value.startsWith('3') ||
                        (numberControl.value.length < 9 || numberControl.value.length > 10))) {
                        return {
                            'notValidNumber': true
                        };
                    }

                    if (prefixControl.value === '+44' && (!numberControl.value.startsWith('7') ||
                        numberControl.value.length !== 10)) {
                        return {
                            'notValidNumber': true
                        };
                    }
                } else {

                    if (numberControl.value.length < 7 || numberControl.value.length > 12) {
                        return {
                            'notValidNumber': true
                        };
                    }

                }

                if (!phoneRegex.test(numberControl.value)) {
                    return {
                        'notValidNumber': true
                    };
                }
            }

            return null;
        };
    }

    public static validDate(minAge?: number, maxAge?: number): Function {
        const now = moment();

        return (control: AbstractControl): ValidationErrors | null => {
            const date = moment(control.value, 'DD/MM/YYYY', true);
            if (!date.isValid()) {
                return {
                    "invalidDateFormat": true
                };
            } else if ((minAge && now.diff(date, 'years') < minAge) || (maxAge && now.diff(date, 'years') > maxAge)) {
                return {
                    "ageNotAllowed": true
                };
            }
            return null;
        };
    }

    public static cannotUseSoldoPecAddress(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            if (control.value === "fatture@pec.soldo.com") {
                return { "notYourEmail": true };
            }

            return null;
        };
    }

    public static validEmail(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const email: string = control.value;
            if (email) {
                const invalidEmailError = { invalidEmailFormat: true };
                if (!email.match(singleAtInTheMiddle)|| email.includes('..')) {
                    return invalidEmailError;
                }
                const { local, subdomains, tld } = CustomValidators.splitEmail(email);
                if (local.length === 0) {
                    return invalidEmailError;
                }
                if (!local.match(emailLocalPartWhitelist)) {
                    return invalidEmailError;
                }
                if (subdomains.length === 0) {
                    return invalidEmailError;
                }
                if (!subdomains.match(emailSubdomainsWhitelist)) {
                    return invalidEmailError;
                }
                if (CustomValidators.noDotAtStartOrEnd(local) || CustomValidators.noDotAtStartOrEnd(subdomains)) {
                    return invalidEmailError;
                }
                if (tld.length < 2) {
                    return invalidEmailError;
                }
                if (!tld.match(emailTopLevelDomainWhitelist)) {
                    return invalidEmailError;
                }
            }
            return null;
        }
    }

    private static noDotAtStartOrEnd(part: string) {
        return part.charAt(0) === '.' || part.charAt(part.length - 1) === '.';
    }

    private static splitEmail(email: string): { local: string, subdomains: string, tld: string } {
        const parts = email.split('@');
        const subdomains = parts[1].split('.');
        const tld = subdomains.pop();
        return {
            local: parts[0],
            subdomains: subdomains.join('.'),
            tld
        };
    }

}

