import { Component, OnInit } from '@angular/core';
import { IAlert, AlertService } from "../../services/alert.service";


@Component({
  selector: 'message-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

    public alerts: IAlert[] = [];

    constructor(private alertService: AlertService) {}

    closeAlert(alert: IAlert) {
        this.alertService.remove(alert.id);
    }

    ngOnInit() {
        this.alertService.alertsChanged.subscribe(
            (alerts) => {
                this.alerts = alerts;
            }
        );
    }
}
