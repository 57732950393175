import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss']
})
export class InputLabelComponent implements OnInit {

  @Input()
  forControl: string;
  constructor() { }

  @Input()
  required: boolean = false;

  ngOnInit() {
  }

}
