import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DocUploadComponent } from './doc-upload/doc-upload.component';
import { HooYuLinkPageComponent } from './hoo-yu-link-page/hoo-yu-link-page.component';
import { HooYuVerificationEmbeddedComponent } from './hoo-yu-verification-embedded/hoo-yu-verification-embedded.component';
import { HooYuVerificationLandingComponent } from './hoo-yu-verification-landing/hoo-yu-verification-landing.component';
import { IdentityVerificationCompleteComponent } from './identity-verification-complete/identity-verification-complete.component';
import { IdentityVerificationLandingComponent } from './identity-verification-landing/identity-verification-landing.component';
import { IdvLinkPageComponent } from './idv-link-page/idv-link-page.component';
import { OnfidoComponent } from './onfido/onfido.component';
import { SuccessComponent } from "./success.component";
import { ButtonsTestComponent } from '@shared/components/test-new-style/buttons-test/buttons-test.component';
import { TextfieldTestComponent } from '@shared/components/test-new-style/textfield-test/textfield-test.component';

export const successRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
    {
        path: 'success',
        data: {
            pageName: "Thank You"
        },
        component: SuccessComponent
    },
    {
        path: 'docupload',
        data: {
            pageName: "Document Upload"
        },
        component: DocUploadComponent
    },
    {
        path: 'mitek',
        data: {
            pageName: "HooYu Verification Needed"
        },
        component: HooYuVerificationLandingComponent
    },
    {
        path: 'onfido',
        data: {
            pageName: "Onfido Verification Needed"
        },
        component: IdentityVerificationLandingComponent
    },
    {
        path: 'verify-your-id',
        data: {
            pageName: "HooYu Verification Link"
        },
        component: HooYuLinkPageComponent
    },
    {
        path: 'static/mitek',
        data: {
            pageName: "HooYu Identity Verification"
        },
        component: HooYuVerificationEmbeddedComponent
    },
    {
        path: 'static/identity-verified',
        data: {
            pageName: "Identity Verification Completed"
        },
        component: IdentityVerificationCompleteComponent
    },
    {
        path: 'static/verify-your-id',
        data: {
            pageName: "Ofido Verification Link"
        },
        component: IdvLinkPageComponent
    },
    {
        path: 'static/onfido',
        data: {
            pageName: "Onfido Identity Verification"
        },
        component: OnfidoComponent
    },
    {
        path: 'static/demo-buttons',
        data: {
            pageName: "BUTTONS DEMO"
        },
        component: ButtonsTestComponent
    },
    {
        path: 'static/demo-form',
        data: {
            pageName: "FORM DEMO"
        },
        component: TextfieldTestComponent
    }
]);
