import { InjectionToken, NgModule } from '@angular/core';
import { environment } from '@src/environments/environment';
import { NgxLoggerLevel } from 'ngx-logger';

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');


export class AppConfig {
    env: string;
    logLevel: NgxLoggerLevel;
    localUrlEndpoint: string;
    aggregatorUrlEndpoint: string;
    googleTagManagerID: string;
    enableDynatraceTracking: boolean;
    amplitudeProjectId: string;
    intercomTestMode: boolean;
    intercomAppId: string;
    invalidPlanOfferRedirectUrl: {
        ITA: string,
        GBR: string,
        IRL: string,
        NLD: string,
        FRA: string,
        fallback: string
    };
    termsAndConditionsLink: {
        EU: string,
        GBR: string,
        ITA: string,
        FRA: string,
        NLD: string
    };
    privacyPolicyLink: {
        it: string,
        en: string,
        nl: string,
        fr: string
    };
    hooyuArticleURLs: {
        [key: string]: string;
    };
    embedHooYuVerificationSession: boolean;
    websiteHomePage: {
        [key: string]: string;
    };
    signupWithXeroLoginURLRoot: string;
    signupWithXeroLoginRedirectURI: string;
    signupWithXeroLoginAuthorizationScopes: string;
    signupWithXeroLeadSource: string;
}

export const APP_DI_CONFIG: AppConfig = {
    env: environment.env,
    logLevel: environment.logLevel,
    localUrlEndpoint: environment.localUrlEndpoint,
    aggregatorUrlEndpoint: environment.aggregatorUrlEndpoint,
    googleTagManagerID: environment.googleTagManagerID,
    enableDynatraceTracking: environment.enableDynatraceTracking,
    amplitudeProjectId: environment.amplitudeProjectId,
    intercomAppId: environment.intercomAppId,
    intercomTestMode: environment.intercomTestMode,
    invalidPlanOfferRedirectUrl: environment.invalidPlanOfferRedirectUrl,
    termsAndConditionsLink: environment.termsAndConditionsLink,
    privacyPolicyLink: environment.privacyPolicyLink,
    hooyuArticleURLs: environment.hooyuArticleURLs,
    embedHooYuVerificationSession: environment.embedHooYuVerificationSession,
    websiteHomePage: environment.websiteHomePage,
    signupWithXeroLoginURLRoot: environment.signupWithXeroLoginURLRoot,
    signupWithXeroLoginRedirectURI: environment.signupWithXeroLoginRedirectURI,
    signupWithXeroLoginAuthorizationScopes: environment.signupWithXeroLoginAuthorizationScopes,
    signupWithXeroLeadSource: environment.signupWithXeroLeadSource
};

@NgModule({
    providers: [{
        provide: APP_CONFIG,
        useValue: APP_DI_CONFIG
    }]
})
export class AppConfigModule { }
