import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CountryService, ICountryMetadata, MAIN_MARKETS_COUNTRY_ISO } from '../../shared/services/country.service';
import { RegistrationDataService } from '../../shared/services/registrationData.service';
import { PersonFormGroup, PersonFormHelperService } from './person-form-helper.service';
import { NGXLogger } from 'ngx-logger';
import { Contact } from '../../shared/models/registration.model';

@Component({
    selector: 'person-form',
    templateUrl: './person-form.component.html',
    styleUrl: './person-form.component.scss'
})
export class PersonFormComponent implements OnChanges {
    @Input()
    personFormGroup: FormGroup<PersonFormGroup> = null;

    @Input()
    readonly: boolean = false;

    @Input()
    emailIsReadonly: boolean = false;

    @Input()
    applyUniquenessChecks: boolean = false;

    @Input()
    checkInArray: Contact[];

    @ViewChild('mobileNumberInputElement')
    mobileNumberInputElement: ElementRef<HTMLInputElement>;

    countryOfIncorporation: string;
    completeCountryList: ICountryMetadata[];

    constructor(
        private readonly countryService: CountryService,
        private readonly registrationDataService: RegistrationDataService,
        private readonly personFormHelperService: PersonFormHelperService,
        private readonly logger: NGXLogger) {

        this.countryOfIncorporation = this.registrationDataService.getCompanyCountry();
        this.countryService.countries.subscribe(allCountries => {

            const preferredCountries = allCountries.filter(c => MAIN_MARKETS_COUNTRY_ISO.includes(c.alpha_3));
            preferredCountries.sort(this.countryService.sortFunction);

            const otherCountries = allCountries.filter(c => c.icc && !MAIN_MARKETS_COUNTRY_ISO.includes(c.alpha_3));
            otherCountries.sort(this.countryService.sortFunction);

            this.completeCountryList = preferredCountries.concat(otherCountries);
        });
    }

    getNationalityLabel(): string {
        const nationality = this.personFormGroup.value.nationality;
        return this.completeCountryList?.find(c => c.alpha_3 === nationality)?.name;
    }

    getCountryOfResidenceLabel(): string {
        const countryOfResidence = this.personFormGroup.value.address?.country;
        return this.completeCountryList?.find(c => c.alpha_3 === countryOfResidence)?.name;
    }

    getMobileNumberLabel(): string {
        return `${this.personFormGroup.controls.mobile.controls.prefix.value}${this.personFormGroup.controls.mobile.controls.number.value}`;
    }

    ngOnChanges(): void {
        this.setDefaultValidatorsAndBehaviors();
    }

    setDefaultValidatorsAndBehaviors(): void {
        const personControls = this.personFormGroup.controls;

        if (this.readonly) {
            for (const control of Object.values(personControls)) {
                control.setValidators([]);
            }
        } else {

            this.personFormHelperService.applyDefaultValidators(this.personFormGroup);
            if (this.applyUniquenessChecks) {
                this.personFormHelperService.applyUniquenessValidators(this.personFormGroup, this.checkInArray);
            }

            this.setupMobileNumberTypingAid();

            personControls.address.controls.country.statusChanges.subscribe(() => {
                this.logger.debug("COUNTRY CHANGED: -> recalculating date of birth, codice fiscale, and fatca question");
                this.personFormHelperService.addOrRemoveDateOfBirthAndCodiceFiscale(this.personFormGroup, this.countryOfIncorporation);
                this.personFormHelperService.addOrRemoveFatcaSubjectQuestion(this.personFormGroup);
                this.logger.debug("COUNTRY CHANGED: -> adding default validators");
                this.personFormHelperService.applyDefaultValidators(this.personFormGroup);
                if (this.applyUniquenessChecks) {
                    this.personFormHelperService.applyUniquenessValidators(this.personFormGroup, this.checkInArray);
                }
            });
        }
    }

    setupMobileNumberTypingAid(): void {
        const numberField = this.personFormGroup.controls.mobile.controls.number;
        const prefixField = this.personFormGroup.controls.mobile.controls.prefix;

        if(!this.personFormGroup.value.mobile.prefix) {
            const suggestedPrefix = this.completeCountryList.find(c => c.alpha_3 === this.countryOfIncorporation)?.icc;
            prefixField.setValue(suggestedPrefix);
        }

        numberField.valueChanges.subscribe((value: string) => {

            switch (prefixField.value) {
                case "+44":
                case "+33":
                    if (value?.startsWith("0")) {
                        const cursorPosition = this.mobileNumberInputElement.nativeElement.selectionStart;
                        numberField.setValue(value.substring(1), {
                            emitEvent: false
                        });
                        this.mobileNumberInputElement.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
                    }
                    break;
            }

        });

    }
}
