<div id="overlay" *ngIf="isResume">
  <div class="overlay-content">
    <div class="main">
      <header>
        <h2 class="v2">{{ "RESUME.TITLE" | translate }}</h2>
        <p class="subtitle">{{ "RESUME.DESCRIPTION" | translate }}</p>
      </header>

      <div class="info-container">
        <h3 class="v2">{{ 'RESUME.INFO' | translate }}</h3>
        <div class="info">
          <div class="row">
            <div class="col-6">
              <label>{{ "RESUME.COMPANY_NAME" | translate}}</label>
              <p [innerHTML]="resumeInfo.companyName"></p>
            </div>
            <div class="col-6">
              <label>{{ "RESUME.CONTACT_EMAIL" | translate}}</label>
              <p class="single-line" [innerHTML]="resumeInfo.contactEmail"></p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6" *ngIf="resumeInfo.planName">
              <label>{{ "RESUME.PLAN" | translate}}</label>
              <p [innerHTML]="resumeInfo.planName | translate"></p>
            </div>
        </div>
        </div>

        <div class="row footer">
          <div class="col-6">
            <button class="btn gray-text go-back" (click)="reset()">
              <i class="md-icon">arrow_right_alt</i>
              <span>{{ "RESUME.RESET" | translate }}</span>
            </button>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-primary" (click)="dismiss()">{{ "RESUME.PROCEED" | translate }}</button>
          </div>
        </div>
      </div>
      </div>
  </div>
</div>
