<h2>{{ 'REGISTRATION.TERMS_CONDITIONS.TITLE' | translate }}</h2>
<p>{{ 'REGISTRATION.TERMS_CONDITIONS.PARAGRAPH_1' | translate }}</p>
<p *ngIf="!isDirectSales">{{ 'REGISTRATION.TERMS_CONDITIONS.PARAGRAPH_2' | translate }}</p>
<p *ngIf="isDirectSales">{{ 'REGISTRATION.TERMS_CONDITIONS.PARAGRAPH_2_DS' | translate }}</p>

<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
  <div class="layout-section">
    <soldo-field-layout *ngIf="this.form.get('cloudTerms')"
      [control]="form.controls['cloudTerms']"
      fieldName="cloudTerms"
      cypressSelector="TermsConditions_Checkbox_AcceptCloudTerms"
      >
    <ng-container input>
      <input id="ACCEPT_CLOUD_TERMS" type="checkbox" (value)="true" formControlName="cloudTerms">
      <label for="ACCEPT_CLOUD_TERMS">
        <span innerHTML="{{ 'REGISTRATION.TERMS_CONDITIONS.ACCEPT_CLOUD_TERMS' | translate: {value: termsLink} }}">{{ 'DEFAULT_EMPTY_STRING' | translate }}</span>
      </label>
    </ng-container>
      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'TERMS_AGREEMENT' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout
      [control]="form.controls['financialTerms']"
      fieldName="financialTerms"
      cypressSelector="TermsConditions_Checkbox_AcceptFinancialTerms"
      >
      <ng-container input>
        <input id="ACCEPT_FINANCIAL_TERMS" type="checkbox" (value)="true" formControlName="financialTerms">
        <label for="ACCEPT_FINANCIAL_TERMS">
          <span innerHTML="{{ 'REGISTRATION.TERMS_CONDITIONS.ACCEPT_FINANCIAL_TERMS' | translate: {value: termsLink} }}">{{ 'DEFAULT_EMPTY_STRING' | translate }}</span>
        </label>
      </ng-container>
      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'TERMS_AGREEMENT' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout *ngIf="isEligibleForStartupOffer"
      [control]="form.controls['startupConfirm']"
      fieldName="startupConfirm"
      cypressSelector="TermsConditions_Checkbox_ConfirmProStartupPlan"
      >
      <ng-container input>
        <input id="CONFIRM_PROSTARTUP" type="checkbox" (value)="true" formControlName="startupConfirm">
        <label for="CONFIRM_PROSTARTUP">
          <span innerHTML="{{ 'REGISTRATION.TERMS_CONDITIONS.PROSTARTUP' | translate }}">{{ 'DEFAULT_EMPTY_STRING' | translate }}</span>
        </label>
      </ng-container>
      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'OTHER_AGREEMENT' | translate : { value: insightsLink } }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>
  </div>

  <button type="button" class="link no-padding" (click)="$event.preventDefault(); onShowCode()" data-cy="TermsConditions_Button_ShowDiscountCode">
    {{ 'REGISTRATION.TERMS_CONDITIONS.DISCOUNT_CODE_LABEL' | translate }}
  </button>
  <div class="conditional-subfield-container" *ngIf="showCode">
    <soldo-field-layout
      [control]="form.controls['discountCode']"
      fieldName="discountCode"
      cypressSelector="TermsConditions_TextInput_DiscountCode"
      >
      <input input
        type="text"
        formControlName="discountCode"
        placeholder="{{ 'REGISTRATION.TERMS_CONDITIONS.DISCOUNT_CODE' | translate }}"
        (input)="form.get('discountCode').setValue($event.target.value.toUpperCase())">

      <ng-container errorMessage>
        <soldo-field-error error errorName="maxlength">
          {{ 'MAX_LENGHT' | translate: { value: form.get('discountCode').errors?.maxlength.requiredLength } }}
        </soldo-field-error>
      </ng-container>
    </soldo-field-layout>
  </div>

  <div class="form-page-CTA-row">
    <button type="button" (click)="goToPrevious()" data-cy="TermsConditions_Button_StepBackButton">
      {{ 'PREVIOUS' | translate }}
    </button>
    <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}"
      data-cy="TermsConditions_Button_StepSubmitButton">{{ 'REGISTRATION.TERMS_CONDITIONS.SUBMIT' | translate }}</button>
  </div>

</form>
<save-and-continue-later stepName="Terms and Conditions"></save-and-continue-later>
