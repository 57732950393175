<h3 class="title">{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.TITLE' | translate}}</h3>
<p class="subtitle">{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.SUB_TITLE_'+country | translate}}</p>

<div *ngIf="!isCompleteDataset" class="error-message d-flex align-items-center">
  <i class="md-icon error-ico">error_outline</i>
  <div>
    <p>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.ERROR_MESSAGE_FIRST_' + country | translate}}</p>
    <p class="m-0">{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.ERROR_MESSAGE_SECOND' | translate}}
      <span class="btn btn-link enter-manually p-0" (click)="nextStep()">
        {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.PLEASE_ENTER_MANUALLY' | translate}}
      </span>.
    </p>
  </div>
</div>
<div class="company-search-result-details" *ngIf="searchResult">

  <h2 class="v2 company-name">{{searchResult.name}}</h2>

  <p class="detail-header">{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.REGISTERED_ADDRESS' | translate}}</p>
  <div *ngIf="!isCompleteDataset" class="not-found">
    {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.NOT_FOUND' | translate}}
  </div>
  <div *ngIf="isCompleteDataset" class="company-registered-address">
    <span class="line-1" *ngIf="searchResult.registeredAddressLine1">{{searchResult.registeredAddressLine1}}</span>
    <span class="line-2" *ngIf="searchResult.registeredAddressLine2">{{searchResult.registeredAddressLine2}}</span>
    <span class="city" *ngIf="searchResult.city">{{searchResult.city}}</span>
    <span class="country" *ngIf="searchResult.country">{{searchResult.country}}</span>
    <span class="postcode" *ngIf="searchResult.postcode">{{searchResult.postcode}}</span>
  </div>

  <ng-container *ngIf="searchResult.status">
    <p class="detail-header" > {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.COMPANY_STATUS' | translate}}</p>
      <div class="registration-status">
        {{searchResult.status}}
      </div>
  </ng-container>

  <p class="detail-header" *ngIf="searchResult.directorProfileList?.length">{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.DIRECTORS' | translate}}</p>
  <div class="company-directors">
    <div class="director" *ngFor="let director of searchResult.directorProfileList">
      <span class="last-name">{{director.lastName}}</span>
      <span class="first-name">{{director.firstName}}</span>
      <span *ngIf="director.middleName" class="middle-name">{{director.middleName}}</span>
    </div>
  </div>

  <ng-container *ngIf="searchResult.dateOfIncorporation">
    <p class="detail-header">{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.INCORPORATION_DATE' | translate}}</p>
    <div class="incorporation-date">
      {{searchResult.dateOfIncorporation}}
    </div>
  </ng-container>

  <ng-container *ngIf="searchResult.registrationNumber">
    <p class="detail-header"> {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.COMPANY_NUMBER' | translate}}</p>
    <div>
      <span class="registration-number">
        {{searchResult.registrationNumber}}
      </span>
    </div>
  </ng-container>

</div>

<ng-container *ngIf="isCompleteDataset">
  <p class="mismatch-prompt">
    <span>
      {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.DATA_MISMATCH_PROMPT' | translate}}
      <soldo-field-info>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.DATA_MISMATCH_HELP_' + country | translate}}</soldo-field-info>
    </span>
  </p>

  <p class="enter-manually">
    <button data-cy="Signup_Button_EnterCompanyManually" type="button" class="btn btn-link enter-manually" (click)="enterManually()">
      {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.ENTER_MANUALLY' | translate}}
    </button>
  </p>
</ng-container>


<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.DISCARD' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.ACCEPT' | translate}}
    </button>
  </div>

</div>
