import { Component, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { Account } from '@shared/models/registration.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { ExpectedActivitySubStepOutboundAction, ExpectedActivitySubStepType } from '../../expected-activity-step.model';

@Component({
    selector: 'app-cash-withdrawal',
    templateUrl: './cash-withdrawal.component.html',
    styleUrls: ['./cash-withdrawal.component.scss']
})
export class CashWithdrawalComponent extends ExpectedActivitySubStepType {
    subStepName: string = "Expected Cash Withdrawal";

    cashWithdrawalOptions = [
        { label: '0-15%', value: 8 },
        { label: '15-25%', value: 20 },
        { label: '25-50%', value: 38 },
        { label: 'REGISTRATION.EXPECTED_ACTIVITY_STEP.CASH.OVER_50_PERCENT', value: 51 }
    ];
    form: UntypedFormGroup;
    showDescription = false;
    readonly descriptionThreshold = 25;

    constructor(
        private fb: UntypedFormBuilder,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService
    ) {
        super();
        this.controlActionEventEmitter = new EventEmitter<ExpectedActivitySubStepOutboundAction>();
    }

    init(d: Partial<Account>): void {
        this.form = this.fb.group({
            cashWithdrawal: [d.ExpectedCashWithdraw__c, Validators.required]
        });
        this.toggleDescription(d.ExpectedCashWithdraw__c >= this.descriptionThreshold, d.ExpectedCashWithdrawDescription__c);
    }

    getData(): Partial<Account> {
        return {
            ExpectedCashWithdraw__c: this.form.get('cashWithdrawal').value,
            ExpectedCashWithdrawDescription__c: this.form.controls['description'] ? this.form.get('description').value : ''
        };
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        } else {
            this.form.markAllAsTouched();
        }
    }

    isValid(): boolean {
        return this.form.valid;
    }

    toggleDescription(showDescription: boolean, description: string = null) {
        this.showDescription = showDescription;
        if (this.showDescription && !this.form.controls.description) {
            this.form.addControl('description', this.fb.control(description, Validators.required));
        } else if (!this.showDescription && this.form.controls.description) {
            this.form.removeControl('description');
        }
    }

}
