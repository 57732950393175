import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { AcceptedLanguage, I18nService } from '../../services/i18n.service';

interface LanguageData {
    label: string;
    value: AcceptedLanguage;
    flag: string;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    languageSelectorEnabled: boolean = true;
    availableLanguages: LanguageData[];
    form: UntypedFormGroup;

    constructor(
        private readonly i18nService: I18nService
    ) {
        this.availableLanguages = i18nService.getValidLanguages().map(lang => ({
            label: lang.toUpperCase(),
            value: lang as AcceptedLanguage,
            flag: lang === 'en' ? 'gb' : lang
        }));
    }


    ngOnInit() {
        this.i18nService.languageSelectionControlEmitter.subscribe(controlEvent => {
            this.languageSelectorEnabled = controlEvent.selectionAllowed;
        });
        this.form = new UntypedFormGroup({
            language: new FormControl<LanguageData>(this.availableLanguages.find(l => l.value === this.i18nService.lang))
        });
        this.form.valueChanges.subscribe(({ language }) => {
            this.i18nService.switchTo(language.value);
        });
    }

}
