import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AcceptedLanguage, I18nService } from '../../services/i18n.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    lang: string;
    availableLanguages: string[];
    dropDownOpen: boolean = false;

    windowClickUnlinsten: () => void;

    constructor(
        private i18nService: I18nService,
        private renderer: Renderer2
    ) {
        this.availableLanguages = i18nService.getValidLanguages();
    }

    onLangButtonClicked(e:MouseEvent): void {
        this.dropDownOpen = !this.dropDownOpen;
        e.stopPropagation();
    }

    ngOnInit() {
        this.lang = this.i18nService.lang;
        this.windowClickUnlinsten = this.renderer.listen("window", "click", () => {
            this.dropDownOpen = false;
        });
    }

    ngOnDestroy(): void {
        this.windowClickUnlinsten();
    }

    switchLang(lang: AcceptedLanguage) {
        this.i18nService.switchTo(lang);
        this.lang = lang;
    }
}
