import { Component, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { LeadStartData } from '@shared/services/lead-data-mediator.service';
import { SignupSubStepOutboundAction, SignupSubStepType } from '../../signup-step.model';

interface CompanySizeOptionLabel {
    label: string;
    value: number;
}
@Component({
    selector: 'substep-number-of-employees',
    templateUrl: './number-of-employees.component.html',
    styleUrls: ['./number-of-employees.component.scss']
})
export class NumberOfEmployeesSubStepComponent extends SignupSubStepType {
    subStepName: string = "Number of Employees";

    loading: boolean = true;

    form: UntypedFormGroup;
    fewerThan50Mode: boolean = null;

    companySizeOptions: Array<CompanySizeOptionLabel> = [
        { label: "Fewer than 50", value: 49 },
        { label: "50-99", value: 99 },
        { label: "100-249", value: 249 },
        { label: "250-999", value: 999 },
        { label: "1000-4999", value: 4999 },
        { label: "5000+", value: 5001 }
    ];

    constructor(
        private fb: UntypedFormBuilder,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService) {

        super();
        this.controlActionEventEmitter = new EventEmitter<SignupSubStepOutboundAction>();
    }

    init(d: Partial<LeadStartData>): void {
        let companySizeValue = null;
        let companySizeFewerThan50Value = null;
        if (d.companySize > 0 && d.companySize < 50) {
            companySizeValue = 49;
            companySizeFewerThan50Value = d.companySize;
            this.fewerThan50Mode = true;
        }else{
            companySizeValue = d.companySize;
            this.fewerThan50Mode = false;
        }

        const patternValidator = Validators.pattern(/^[0-9]*$/);
        const minValidator = Validators.min(1);
        const maxValidator = Validators.max(49);

        this.form = this.fb.group({
            "companySize": [companySizeValue, [Validators.required]],
            "companySizeFewerThan50": [companySizeFewerThan50Value, [this.fewerThan50Validator, (control: AbstractControl): ValidationErrors => {
                return patternValidator(control) || minValidator(control) || maxValidator(control) || null;
            }]]
        });

        this.form.get('companySize').valueChanges.subscribe((value) => {
            this.fewerThan50Mode = value == 49;
            if (value > 49){
                this.form.get('companySizeFewerThan50').setValue(null);
            }
            this.form.get('companySizeFewerThan50').updateValueAndValidity();
        });

        this.loading = false;
    }

    fewerThan50Validator = (control: AbstractControl): ValidationErrors => {
        if (this.fewerThan50Mode == true) {
            return Validators.required(control);
        }
    };

    isValid(): boolean {
        this.form.get("companySize").markAsTouched();
        this.form.get("companySizeFewerThan50").markAsTouched();

        return this.form.valid;
    }

    getData(): Partial<LeadStartData> {
        const formValues = this.form.value;
        const NumberOfEmployees = this.fewerThan50Mode ? formValues["companySizeFewerThan50"] : formValues["companySize"];
        return {
            companySize: Number.parseInt(NumberOfEmployees)
        };
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if(this.isValid()) {
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        }
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

}
