import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[interactive]',
    host: {
        '(click)': '$any(this)._activated($event)',
        '(keydown.enter)': '$any(this)._activated($event)',
        '(keydown.space)': '$any(this)._activated($event)'
    }
})
export class InteractiveElementDirective implements OnInit {

    @Input()
    interactive: number;

    @Output()
    interact: EventEmitter<MouseEvent | KeyboardEvent> = new EventEmitter<MouseEvent | KeyboardEvent>();

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        this.el.nativeElement.setAttribute("tabindex", this.interactive || 0);
    }

    _activated(event: MouseEvent | KeyboardEvent) {
        this.interact.emit(event);
    }

}
