<div class="overlay" *ngIf="isResume">
  <div class="resume-box">
    <div class="resume-content">
      <header>
        <h3>{{ "RESUME.TITLE" | translate }}</h3>
        <p>{{ "RESUME.DESCRIPTION" | translate }}</p>
      </header>

      <div class="layout-section">
        <p><strong>{{ 'RESUME.INFO' | translate }}</strong></p>

        <div class="resume-info-box">
          <div class="info-row">
            <div>
              <div><strong>{{ "RESUME.COMPANY_NAME" | translate}}</strong></div>
              <div [innerHTML]="resumeInfo.companyName"></div>
            </div>

            <div>
              <div><strong>{{ "RESUME.CONTACT_EMAIL" | translate}}</strong></div>
              <div [innerHTML]="resumeInfo.contactEmail"></div>
            </div>
          </div>

          <div class="info-row">
            <div *ngIf="resumeInfo.planName">
              <div><strong>{{ "RESUME.PLAN" | translate}}</strong></div>
              <div [innerHTML]="resumeInfo.planName | translate"></div>
            </div>
          </div>
        </div>

        <div class="form-page-CTA-row">
          <button type="button" (click)="reset()" data-cy="Resume_Button_RestartButton">
            {{ 'RESUME.RESET' | translate }}
          </button>
          <button type="button" class="primary" [disabled]="loading" (click)="dismiss()" data-cy="Resume_Button_ContinueButton">
            {{ 'RESUME.PROCEED' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
