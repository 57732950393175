import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CompanyLookupResult, CompanySearchResult } from '../models/company-search.model';
import { SharedModule } from '../shared.module';
import { RequestAggregatorService } from './requestAggregator.service';

@Injectable({
    providedIn: SharedModule
})
export class CompanySearchService {
    private static SERVICE_NAME = "soldoSalesforceRestService";
    private static SEARCH_METHOD = "searchCompany";
    private static LOOKUP_METHOD = "getCompanyLookup";
    private static ENABLED_COUNTRIES = ["GBR", "ITA"];

    // eslint-disable-next-line no-useless-constructor
    constructor(
        private aggregator: RequestAggregatorService,
        private logger: NGXLogger
    ) {}

    search(term: string, countryIso3: string): Promise<CompanySearchResult> {
        if (!term) {
            this.logger.debug("Search term is missing");
            return null;
        }
        if (CompanySearchService.ENABLED_COUNTRIES.indexOf(countryIso3) < 0) {
            this.logger.debug("Country '", countryIso3, "' is not allowed");
            return null;
        }
        return this.aggregator.sendSingleRequest<CompanySearchResult>({
            serviceName: CompanySearchService.SERVICE_NAME,
            serviceMethod: CompanySearchService.SEARCH_METHOD,
            args: [term, countryIso3]
        });
    }

    lookup(kybProviderId: string, countryIso3: string) {
        if (!kybProviderId) {
            this.logger.debug("kybProviderId is missing");
            return null;
        }
        if (CompanySearchService.ENABLED_COUNTRIES.indexOf(countryIso3) < 0) {
            this.logger.debug("Country '", countryIso3, "' is not allowed");
            return null;
        }
        return this.aggregator.sendSingleRequest<CompanyLookupResult>({
            serviceName: CompanySearchService.SERVICE_NAME,
            serviceMethod: CompanySearchService.LOOKUP_METHOD,
            args: [kybProviderId, countryIso3]
        });
    }
}
