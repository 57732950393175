import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'test-new-style.component',
    templateUrl: './test-new-style.component.html',
    styleUrls: ['./test-new-style.component.scss']
})

export class TestNewStyleComponent{

    constructor(
        private translateService: TranslateService){
    }

    currentLanguage(): string {
        return this.translateService.currentLang;
    }
}
