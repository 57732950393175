import { Component, EventEmitter, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidators } from '@shared/customValidators';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { CheckCodeStatusDTO, CheckVerificationCodeRequestDTO, RequestEmailVerificationCodeResponseDTO } from '@shared/models/registration.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { LeadStartData } from '@shared/services/lead-data-mediator.service';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { SoldoCookieService } from '@shared/services/soldo-cookie.service';
import { AppConfig, APP_CONFIG } from '@src/app/app-config.module';
import { SignupSubStepOutboundAction, SignupSubStepType } from '../../signup-step.model';
@Component({
    selector: 'substep-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationSubStepComponent extends SignupSubStepType {
    subStepName: string = "Email Verification";

    loading: boolean = true;

    _data: Partial<LeadStartData>;

    form: UntypedFormGroup;
    codeLength: number = 4;

    codeRequested: boolean = false;
    resendCodeEnabled: boolean = true;

    verificationCodeMismatch: boolean = false;
    codeDurationMinutes: number = 30;

    private static FS_PROVIDER_UK = "Soldo Financial Services Ltd";
    private static FS_PROVIDER_EU = "Soldo Financial Services Ireland DAC";
    privacyLink: string = "-- link --";
    soldoFsProvider: string = EmailVerificationSubStepComponent.FS_PROVIDER_EU;

    isDirectSales: boolean = false;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private fb: UntypedFormBuilder,
        private translateService: TranslateService,
        private amplitudeService: AmplitudeService,
        private registrationDataService: RegistrationDataService,
        private soldoCookieService: SoldoCookieService,
        private gtmService: GoogleTagManagerService) {

        super();
        this.controlActionEventEmitter = new EventEmitter<SignupSubStepOutboundAction>();
    }

    init(d: Partial<LeadStartData>): void {
        this._data = d;
        this.form = this.fb.group({
            "email": [d.email, [
                Validators.required,
                CustomValidators.validEmail(),
                this.leadMaxlengthValidator("Email"),
                CustomValidators.cannotUseSoldoPecAddress()]]
        });

        this.setPrivacyLink(this.translateService.currentLang);
        this.translateService.onLangChange.subscribe(e => {
            this.setPrivacyLink(e.lang);
        });

        this.isDirectSales = this.registrationDataService.isDirectSalesSegment();

        this.loading = false;
    }

    setPrivacyLink(lang: string): void {
        if(this.config.privacyPolicyLink[lang]) {
            this.privacyLink = this.config.privacyPolicyLink[lang];
        } else {
            this.privacyLink = this.config.privacyPolicyLink["fallback"];
        }
    }

    requestCode() {

        if (this.resendCodeEnabled) {
            const d = this.getData();
            this.resendCodeEnabled = false;
            this.registrationDataService.requestEmailVerificationCode(Object.assign({}, this._data, d, { language: this.currentLanguage() }))
                .then((response: RequestEmailVerificationCodeResponseDTO) => {

                    if (response.success) {
                        this.showCodeField();
                        this.codeDurationMinutes = response.codeDurationMinutesLeft;
                        setTimeout(() => {
                            this.resendCodeEnabled = true;
                        }, 30 * 1000);
                    } else {
                        this.resendCodeEnabled = true;
                        if (response.error === "BLACKLISTED_DOMAIN") {
                            this.form.get("email").setErrors({
                                blacklistedDomain: true
                            });
                            this.trackFreeEmailDomainErrorTriggered(this.form.get("email").value);
                        }
                    }


                });
            this.trackCodeRequested();
        }
    }

    showCodeField() {
        this.form.addControl("code", new UntypedFormControl(null, [
            Validators.required,
            Validators.pattern(/^[0-9]{4}$/),
            (): ValidationErrors | null => {
                if (this.verificationCodeMismatch) {
                    return {
                        "codeMismatch": true
                    };
                } else {
                    return null;
                }
            }
        ]));
    }

    removeCodeField(): void {
        this.form.removeControl("code");
    }

    setEmailVerified(email: string, token: string, tokenExpiry: number): void {
        this.soldoCookieService.storeEmailVerificationToken(token, email, tokenExpiry);
    }

    isEmailVerified(email: string): boolean {
        return this.soldoCookieService.readEmailVerificationToken(email) !== null;
    }

    isValid(): boolean {
        this.form.get("email").markAsTouched();

        if (this.form.get("code")) {
            this.form.get("code").markAsTouched();
        }
        return this.form.valid;
    }

    getData(): Partial<LeadStartData> {
        const formValues = this.form.value;
        return {
            email: formValues["email"]
        };
    }

    getVerificationRequestObject(): CheckVerificationCodeRequestDTO {
        const formValues = this.form.value;
        return {
            email: formValues["email"],
            code: formValues["code"]
        };
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            const d = this.getData();

            if (this.isEmailVerified(d.email)) {
                this.controlActionEventEmitter.emit({
                    action: DefaultOutboundActions.NEXT,
                    data: d
                });
            } else if (this.form.get('code')) {

                this.loading = true;

                // send code to server for verification
                this.registrationDataService.checkEmailVerificationCode(this.getVerificationRequestObject()).then((response) => {
                    if (response.checkCode == CheckCodeStatusDTO.CODE_VERIFIED) {
                        this.setEmailVerified(d.email, response.token, response.tokenExpiry);
                        d.emailVerificationToken = response.token;
                        this.loading = false;
                        this.controlActionEventEmitter.emit({
                            action: DefaultOutboundActions.NEXT,
                            data: d
                        });
                    } else {
                        this.loading = false;
                        this.verificationCodeMismatch = true;
                        this.form.get("code").updateValueAndValidity();
                    }
                });

            } else {
                this.requestCode();
            }

        }
    }

    onEmailChange(): void {
        this.resendCodeEnabled = true;
        this.removeCodeField();
    }

    onCodeInput() {
        this.verificationCodeMismatch = false;
    }

    currentLanguage(): string {
        return this.translateService.currentLang;
    }

    trackCodeRequested(): void {
        this.amplitudeService.trackEmailVerificationCodeRequested();
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

    trackFreeEmailDomainErrorTriggered(email: string): void {
        let domain: string = "unknown";
        if (email && email !== "" && email.indexOf("@") > -1) {
            domain = email.substring(email.lastIndexOf("@") + 1);
        }
        this.amplitudeService.trackFreeEmailDomainErrorTriggered(domain);
    }

    onSubmit(): boolean {

        return false;
    }

}
