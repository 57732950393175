import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AddressFormControlMetadata } from '../address2.component';
import { Subscription } from 'rxjs';
import { AbstractAddressFieldsComponent } from '../abstract-address-fields.component';
import { Router } from '@angular/router';
import { HereApiAddressSearchResult } from '../../../services/here-apiaddress-lookup.service';
import { AmplitudeService } from '../../../services/amplitude.service';
import { regexValidators } from '../../../customValidators';

@Component({
    selector: 'address-fields-generic',
    templateUrl: './address-fields-generic.component.html',
    styleUrls: ['./address-fields-generic.component.scss']
})
export class AddressFieldsGenericComponent extends AbstractAddressFieldsComponent implements OnInit {

    @Input("detectManualEdits")
    public detectManualEdits: boolean = true;

    @Input("manualChangeEmitter")
    public manualChangeEmitter: EventEmitter<string>;

    @Input('formGroup')
    public addressForm: UntypedFormGroup;

    @Input('simplified')
    public simplified: boolean = false;

    @Input('fieldMetadata')
    public fieldMetadata: AddressFormControlMetadata;

    @Input('searchResultEmitter')
    public searchResultEmitter: EventEmitter<HereApiAddressSearchResult>;

    @Input()
    readonly = false;

    private localSubscriptions: Subscription[] = [];

    constructor(
        protected amplitudeService: AmplitudeService,
        protected router: Router) {

        super(amplitudeService, router);
        this.disableManualEditDetection();
    }

    ngOnInit() {
        this.localSubscriptions.push(this.searchResultEmitter.subscribe((selectedAddress: HereApiAddressSearchResult) => {
            this.addressForm.patchValue({
                idCheckAddressLookupAddressCode: selectedAddress.id,
                postcode: selectedAddress.address.postalCode,
                street: selectedAddress.address.street,
                buildingNumber: selectedAddress.address.houseNumber,
                state: selectedAddress.address.county,
                city: selectedAddress.address.city,
                line1: [selectedAddress.address.street, selectedAddress.address.houseNumber].join(" "),
                line2: "",
                line3: ""
            }, { emitEvent: false });
            this.trackSearchResultSelected();
        }));

        setTimeout(() => {
            const country = this.addressForm.get("country").value;
            const postcodeRegexPattern = regexValidators.postcodesByCountry[country] || /.*/;

            if (this.simplified) {
                this.localSubscriptions.push(this.configField("line1", "RegisteredAddressLine1__c", true, false, true, []));
                this.localSubscriptions.push(this.configField("line2", "RegisteredAddressLine2__c", false, false, true, []));
            } else {
                this.localSubscriptions.push(this.configField("street", "RegisteredAddressStreet__c", true, true, true, []));
                this.localSubscriptions.push(this.configField("buildingNumber", "RegisteredAddressHouseNumber__c", true, true, true, []));
            }
            this.localSubscriptions.push(this.configField("city", "RegisteredAddressCity__c", true, true, true, []));
            this.localSubscriptions.push(this.configField("postcode", "RegisteredAddressPostalCode__c", true, true, true, [
                Validators.pattern(postcodeRegexPattern), Validators.maxLength(8)
            ]));

            this.enableManualEditDetection();
        }, 0);
    }

    ngOnDestroy() {
        for (const s of this.localSubscriptions) {
            s.unsubscribe();
        }
    }

    invalidateSearchResult(fieldName: string) {
        const formValue = this.addressForm.value;
        if (formValue.idCheckAddressLookupAddressCode) {
            this.addressForm.patchValue({
                idCheckAddressLookupAddressCode: ""
            });
            this.trackSeachInvalidated(fieldName);
        }
    }

}
