import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '@src/app/app-config.module';
import { EventEmitter } from 'events';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
export interface SoldoTrackingCookie {
    CID: string;
    PID: string;
    LID: string;
    OID: string;
    SID: string;
    sld_aff: string;
    sld_aff_t: number;
    gclid: string;
    gclid_timestamp: number;
    email: string;
    phone: string;
    AFF_META: { [key: string]: any };
    META: { [key: string]: any };
    experimentId: string;
    experimentVariantId: string;
}

export interface SoldoMarketingParameters {
    CID?: string;
    PID?: string;
    LID?: string;
    OID?: string;
    SID?: string;
    utm_source: string;
    utm_campaign: string;
    utm_content: string;
    utm_term: string;
    utm_medium: string;
    driverid: string;
}

export interface SoldoAffiliateData {
    affiliateId: string;
    affiliateClickTime: number;
    affiliateMetadata: string;
}

export interface GCLIDData {
    gclid: string;
    gclidTimestamp: number;
}

export interface SoldoRegistrationConfigData {
    discountCode: string;
    parentDomain: string;
    geolocation: string;
    soleTraderSelectionEnabled: boolean;
    hooYuLink: string;
    idvSessionId: string;
    idvProvider: string;
}

export interface SoldoExperimentData {
    experimentId: string;
    experimentVariantId: string;
}
interface EmailVerificationTokenCookie {
    token: string;
    hash: number;
}

export interface SignupWithXeroConfigurationCookie {
    plan: string;
    offer: string;
}

const AFFILIATE_ID_PARAM = "sld_aff";
const AFFILIATE_CLICK_TIME_PARAM = "sld_aff_t";
const AFFILIATE_METADATA_PARAM = "AFF_META";

const GCLID_PARAM = "gclid";
const GCLID_TIMESTAMP_PARAM = "gclid_timestamp";


const MINUTES_MS = 1000 * 60;
const DAYS_MS = 24 * 60 * MINUTES_MS;

const SOLDO_REGISTRATION_ID_COOKIE_NAME = "sld_rid";
const SOLDO_REGISTRATION_ID_COOKIE_DURATION = 30 * DAYS_MS;

const SOLDO_REGISTRATION_CONFIG_COOKIE_NAME = "soldo-registration-config";

const SOLDO_EMAIL_VERIFICATION_TOKEN_COOKIE_NAME = "sld_evtk";
const SOLDO_EMAIL_VERIFICATION_TOKEN_COOKIE_DURATION = 7 * DAYS_MS;

const SOLDO_SIGNUP_WITH_XERO_CONF_COOKIE_NAME = "sld_swxc";
const SOLDO_SIGNUP_WITH_XERO_CONF_COOKIE_DURATION = 7 * DAYS_MS;

const CONFIG_COOKIE_DOMAIN = ".soldo.com";
const CONFIG_COOKIE_DURATION = 30 * MINUTES_MS;

const AFFILIATE_META_PARAM_EXCLUSION = [
    AFFILIATE_ID_PARAM,
    "registrationId",
    "OfferName",
    "PlanName",
    "discountCode",
    "parentDomain"
];

const DEDICATED_UTM_COOKIE_NAME = "sld_utm";
const DEDICATED_GCLID_COOKIE_NAME = "sld_gclid";
const DEDICATED_AFFILIATE_COOKIE_NAME = "sld_aff";

const EXPERIMENT_DATA_COOKIE_NAME = "sld_exp";

interface AffiliateTrackingCookie {
    id: string,
    timestamp: number,
    [key: string]: any
}

@Injectable()
export class SoldoCookieService {
    public registrationIdEventEmitter: EventEmitter = new EventEmitter();
    emailVerificationTokenCookie: EmailVerificationTokenCookie;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private cookieService: CookieService,
        private logger: NGXLogger
    ) { }

    getStoredConfig(): Partial<SoldoRegistrationConfigData> {
        const conf: Partial<SoldoRegistrationConfigData> = {
            discountCode: null,
            parentDomain: null,
            geolocation: null,
            soleTraderSelectionEnabled: false,
            hooYuLink: null,
            idvSessionId: null,
            idvProvider: null
        };
        const encoded = this.cookieService.get(SOLDO_REGISTRATION_CONFIG_COOKIE_NAME);
        if (encoded) {
            try {

                const serialized = atob(encoded);
                const jsonConf = JSON.parse(serialized);

                conf.discountCode = jsonConf.discountCode;
                conf.parentDomain = jsonConf.parentDomain;
                conf.geolocation = jsonConf.geolocation || null;
                conf.soleTraderSelectionEnabled = jsonConf.soleTraderSelectionEnabled == true;
                conf.hooYuLink = jsonConf.hooYuLink || null;
                conf.idvSessionId = jsonConf.idvSessionId || null;
                conf.idvProvider = jsonConf.idvProvider || null;

            } catch (e) {
                console.debug("Can't eval stored config cookie", e);
            }
        }

        return conf;
    }

    storeConfig(conf: Partial<SoldoRegistrationConfigData>): void {
        const serialized = JSON.stringify(conf);
        const encoded = btoa(serialized);
        this.cookieService.set(
            SOLDO_REGISTRATION_CONFIG_COOKIE_NAME,
            encoded,
            new Date(Date.now() + CONFIG_COOKIE_DURATION),
            "/", CONFIG_COOKIE_DOMAIN);
    }

    updateAffiliateData(params: URLSearchParams): SoldoAffiliateData {
        if (params.get(AFFILIATE_ID_PARAM)) {
            const affiliateData: AffiliateTrackingCookie = {
                id: params.get(AFFILIATE_ID_PARAM),
                timestamp: Date.now()
            };
            for (const [key, value] of params) {
                if (AFFILIATE_META_PARAM_EXCLUSION.indexOf(key) < 0) {
                    affiliateData[key] = value;
                }
            }
            this.updateDedicatedAffiliateTrackingCookie(affiliateData);
        }
        return this.readDedicatedAffiliateTrackingCookie();
    }

    updateRegistrationIdCookie(registrationId: string): void {
        this.cookieService.set(SOLDO_REGISTRATION_ID_COOKIE_NAME,
            registrationId,
            new Date(Date.now() + SOLDO_REGISTRATION_ID_COOKIE_DURATION),
            "/", CONFIG_COOKIE_DOMAIN);

        this.registrationIdEventEmitter.emit("registration-id-replaced", registrationId);
    }

    deleteRegistrationIdCookie(): void {
        this.cookieService.delete(SOLDO_REGISTRATION_ID_COOKIE_NAME, '/', CONFIG_COOKIE_DOMAIN);
    }

    readRegistrationIdCookie(): string {
        return this.cookieService.get(SOLDO_REGISTRATION_ID_COOKIE_NAME);
    }

    readDedicatedUTMCookie(): SoldoMarketingParameters {
        const serializedCookieValue = this.cookieService.get(DEDICATED_UTM_COOKIE_NAME);
        if (serializedCookieValue) {
            const utmValuesArray = serializedCookieValue.split(";");
            return {
                utm_source: utmValuesArray[0],
                utm_medium: utmValuesArray[1],
                utm_campaign: utmValuesArray[2],
                utm_content: utmValuesArray[3],
                utm_term: utmValuesArray[4],
                driverid: utmValuesArray[5]
            };
        }
        return null;
    }

    readDedicatedGoogleClickIDCookie(): GCLIDData {
        const serializedCookieValue = this.cookieService.get(DEDICATED_GCLID_COOKIE_NAME);
        if (serializedCookieValue) {
            const gclidValuesArray = serializedCookieValue.split(":");
            return {
                gclid: gclidValuesArray[0],
                gclidTimestamp: parseInt(gclidValuesArray[1])
            };
        }
        return null;
    }

    updateDedicatedAffiliateTrackingCookie(data: AffiliateTrackingCookie): void {
        if (typeof data == 'object') {
            this.cookieService.set(DEDICATED_AFFILIATE_COOKIE_NAME,
                btoa(JSON.stringify(data)),
                new Date(Date.now() + (30*DAYS_MS)),
                "/",
                CONFIG_COOKIE_DOMAIN);
        }
    }

    readDedicatedAffiliateTrackingCookie(): SoldoAffiliateData {
        const serializedCookieValue = this.cookieService.get(DEDICATED_AFFILIATE_COOKIE_NAME);
        if (serializedCookieValue) {
            try {
                const affiliateCookieObject: AffiliateTrackingCookie = JSON.parse(atob(serializedCookieValue));
                this.logger.info("sld_aff cookie", affiliateCookieObject);

                const affiliateDataObject: SoldoAffiliateData = {
                    affiliateId: affiliateCookieObject.id,
                    affiliateClickTime: affiliateCookieObject.timestamp,
                    affiliateMetadata: ""
                };

                const metaDataKeys = Object.keys(affiliateCookieObject);
                const metaDataObject = {};
                metaDataKeys.forEach((key) => {
                    if (["id", "timestamp"].indexOf(key) == -1) {
                        metaDataObject[key] = affiliateCookieObject[key];
                    }
                });

                affiliateDataObject.affiliateMetadata = JSON.stringify(metaDataObject);

                return affiliateDataObject;
            } catch (e) {
                this.logger.error("Could not parse affiliate tracking cookie", serializedCookieValue, e);
            }
        }
        return null;
    }

    readCookieHubPreferences(): { [key: string]: boolean } {
        const serializedCookiehubPreferences = this.cookieService.get("cookiehub");
        let preferences = {};

        if (serializedCookiehubPreferences && serializedCookiehubPreferences.length) {
            try {
                preferences = JSON.parse(atob(serializedCookiehubPreferences));

                if (preferences["answered"] && preferences["categories"]) {
                    preferences["categories"].forEach(category => {
                        preferences[category["id"]] = category["value"];
                    });
                }

            } catch (e) {
                this.logger.error("Could not parse cookiehub preferences cookie", serializedCookiehubPreferences, e);
            }
        }

        return preferences;
    }

    readExperimentDataCookie(): object {
        const serializedExperimentData = this.cookieService.get(EXPERIMENT_DATA_COOKIE_NAME);
        let experimentData = {};
        if (serializedExperimentData && serializedExperimentData.length) {
            try {
                experimentData = JSON.parse(atob(serializedExperimentData));
            } catch (e) {
                this.logger.error("Could not parse experiment data cookie", serializedExperimentData, e);
            }
        }

        return experimentData;
    }

    storeEmailVerificationToken(token: string, email: string, expiresAt: number): void {

        const data: EmailVerificationTokenCookie = { token: token, hash: this.emailToHash(email) };
        const serialized = JSON.stringify(data);
        const encoded = btoa(serialized);

        this.cookieService.set(SOLDO_EMAIL_VERIFICATION_TOKEN_COOKIE_NAME,
            encoded,
            new Date(Date.now() + SOLDO_EMAIL_VERIFICATION_TOKEN_COOKIE_DURATION),
            "/", CONFIG_COOKIE_DOMAIN);
        // Saves a cookie with a value like:
        // { token: "<token>", hash: "<email_hash>" }
    }

    readEmailVerificationToken(email: string): string {
        const serializedemailVerificationToken = this.cookieService.get(SOLDO_EMAIL_VERIFICATION_TOKEN_COOKIE_NAME);
        let tokenValue = null;
        // Reads the cookie if present
        if (serializedemailVerificationToken) {
            try {
                this.emailVerificationTokenCookie = JSON.parse(atob(serializedemailVerificationToken));
                if (this.emailToHash(email) === this.emailVerificationTokenCookie.hash) {
                    // Returns the token value only if the email has the same hash
                    tokenValue = this.emailVerificationTokenCookie.token;
                }
            } catch (e) {
                this.logger.error("Could not parse token cookie", this.emailVerificationTokenCookie, e);
            }
        }

        return tokenValue;
    }

    deleteEmailVerificationToken(): void {
        this.cookieService.delete(SOLDO_EMAIL_VERIFICATION_TOKEN_COOKIE_NAME, "/", CONFIG_COOKIE_DOMAIN);
    }

    writeSignupWithXeroCookie(plan: string, offer: string) {
        if (plan && offer) {
            const serializedCookieValue = [plan, offer].join(":");
            this.cookieService.set(SOLDO_SIGNUP_WITH_XERO_CONF_COOKIE_NAME,
                serializedCookieValue,
                new Date(Date.now() + SOLDO_SIGNUP_WITH_XERO_CONF_COOKIE_DURATION),
                "/", CONFIG_COOKIE_DOMAIN);
        }
    }

    readSignupWithXeroCookie(): SignupWithXeroConfigurationCookie {
        const serializedCookieValue = this.cookieService.get(SOLDO_SIGNUP_WITH_XERO_CONF_COOKIE_NAME);
        let cookieValue: SignupWithXeroConfigurationCookie = null;
        if (serializedCookieValue && serializedCookieValue.indexOf(":") > -1) {
            const splitValue = serializedCookieValue.split(":");
            cookieValue = {
                plan: splitValue[0],
                offer: splitValue[1]
            };
        }
        return cookieValue;
    }

    emailToHash(email: string): number {
        return this._simpleHash(email.toLowerCase());
    }

    _simpleHash(str: string): number {
        let hash = 0;
        let i;
        let chr;
        if (str.length === 0) {
            return hash;
        }
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    // "analytics" "marketing" "preferences" "uncategorized"
    isCookieCategoryAccepted(category: string): boolean {
        return this.readCookieHubPreferences()[category] !== false;
    }
}
