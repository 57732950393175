<h2>{{ "REGISTRATION.BENEFICIAL_OWNER_CHOICE.TITLE" | translate }}</h2>
<h5>{{ "REGISTRATION.BENEFICIAL_OWNER_CHOICE.DESCRIPTION_1" | translate }}</h5>
<h5>{{ "REGISTRATION.BENEFICIAL_OWNER_CHOICE.DESCRIPTION_2" | translate }}</h5>


<button data-cy="UBO_Button_WhatIsUBO" type="button" (click)="openUBOHelpDialog()" class="link no-padding">
  {{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.WHAT_IS_UBO' | translate}}
</button>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off" [ngClass]="{ 'ng-touched': form.touched }">

    <soldo-field-layout
      [control]="form.controls.uboMode"
      fieldName="uboMode"
      cypressSelector="UBO_RadioButton_Mode">

      <fieldset input>

        <input type="radio" formControlName="uboMode" name="uboMode"
          id="EnterNowMode" value="ONLINE" #enterNowModeRadio>
        <div class="card" data-cy="UBO_RadioButton_EnterNowMode"
          interactive (interact)="enterNowModeRadio.click()">

          <h3>{{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_ENTER_NOW.TITLE' | translate}}</h3>
          <p>{{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_ENTER_NOW.DESCRIPTION' | translate}}</p>

        </div>

        <input type="radio" formControlName="uboMode" name="uboMode"
          id="EmailMode" value="EMAIL" #emailModeRadio>
        <div class="card" data-cy="UBO_RadioButton_EmailMode"
          interactive (interact)="emailModeRadio.click()">

          <h3>{{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_EMAIL.TITLE' | translate}}</h3>
          <p *ngIf="countryOfIncorporation !== 'ITA'">
            {{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_EMAIL.DESCRIPTION' | translate}}
          </p>
          <p *ngIf="countryOfIncorporation === 'ITA'">
            {{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_EMAIL.DESCRIPTION_ITA' | translate}}
          </p>

        </div>

        <input type="radio" formControlName="uboMode" name="uboMode"
          id="SkipMode" value="NONE" #skipModeRadio>
        <div class="card" data-cy="UBO_RadioButton_SkipMode"
          interactive (interact)="skipModeRadio.click()">

          <h3>{{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_SKIP.TITLE' | translate}}</h3>
          <p>{{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_SKIP.DESCRIPTION' | translate}}</p>

        </div>

      </fieldset>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>

    </soldo-field-layout>

    <div class="form-page-CTA-row">

      <button type="button" (click)="goToPrevious()" data-cy="UBO_Button_StepBackButton">
        {{ 'PREVIOUS' | translate }}
      </button>

      <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}"
        data-cy="UBO_Button_StepSubmitButton">
        {{ 'NEXT' | translate}}
      </button>

    </div>
  </form>

<save-and-continue-later stepName="Ultimate Beneficial Owner"></save-and-continue-later>
