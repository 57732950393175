import { Component, Input, EventEmitter, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { auditTime, catchError, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AddressEntry, UkAddress } from '../../services/uk-address-lookup.service';
import { CountryService, ICountryMetadata } from '../../services/country.service';
import { Account } from '../../models/registration.model';
import { HereAddressSearchConfiguration, HereApiaddressLookupService, HereApiAddressSearchResult } from '../../services/here-apiaddress-lookup.service';
import { AmplitudeService } from '../../services/amplitude.service';
import { Subject, of } from 'rxjs';
import { CustomValidators } from '../../customValidators';


export interface AddressFormControlMetadata {
    [key: string]: {
        modelFieldName: keyof Account,
        mandatory: boolean
    }
}

@Component({
    selector: 'address2-form-group',
    templateUrl: './address2.component.html',
    styleUrls: ['./address2.component.scss']
})
export class Address2Component implements OnInit, OnChanges {

    @Input('group')
    public addressForm: UntypedFormGroup;

    @Input('simplified')
    public simplified: boolean = false;

    @Input('activeMarketsOnly')
    public activeMarketsOnly: boolean = false;

    @Input()
    readonly = false;

    public countries: ICountryMetadata[];

    public fieldMetadata: AddressFormControlMetadata = {
        "idCheckAddressLookupAddressCode": {
            modelFieldName: "RegisteredAddressServiceRefCode__c",
            mandatory: false
        },
        "line1": {
            modelFieldName: "RegisteredAddressLine1__c",
            mandatory: false
        },
        "line2": {
            modelFieldName: "RegisteredAddressLine2__c",
            mandatory: false
        },
        "line3": {
            modelFieldName: "RegisteredAddressLine3__c",
            mandatory: false
        },
        "city": {
            modelFieldName: "RegisteredAddressCity__c",
            mandatory: false
        },
        "buildingName": {
            modelFieldName: "RegisteredAddressHouseName__c",
            mandatory: false
        },
        "buildingNumber": {
            modelFieldName: "RegisteredAddressHouseNumber__c",
            mandatory: false
        },
        "poBoxNumber": {
            modelFieldName: "RegisteredAddressPoBox__c",
            mandatory: false
        },
        "street": {
            modelFieldName: "RegisteredAddressStreet__c",
            mandatory: false
        },
        "secondaryStreet": {
            modelFieldName: "RegisteredAddressSecondaryStreet__c",
            mandatory: false
        },
        "subBuilding": {
            modelFieldName: "RegisteredAddressSubBuilding__c",
            mandatory: false
        },
        "postcode": {
            modelFieldName: "RegisteredAddressPostalCode__c",
            mandatory: false
        },
        "state": {
            modelFieldName: "RegisteredAddressState__c",
            mandatory: false
        }
    };

    public hereSearchConfiguration: HereAddressSearchConfiguration = new HereAddressSearchConfiguration();
    public hereApiAddressSearchResults: HereApiAddressSearchResult[] = [];
    public searchHereApi = new Subject<string>();

    public hereApiSelectionEmitter: EventEmitter<HereApiAddressSearchResult> = new EventEmitter();

    public hereApiSearchLoading: boolean = false;

    public w2UkLookupSelectionEmitter: EventEmitter<UkAddress> = new EventEmitter();

    public addressFieldsLayout = null;

    mainMarketCountryNames: string[] = ["FRA", "DEU", "ITA", "NLD", "ESP", "GBR"];
    constructor(
        private readonly countryService: CountryService,
        private readonly hereLookupService: HereApiaddressLookupService,
        private readonly router: Router,
        protected logger: NGXLogger,
        private readonly amplitudeService: AmplitudeService,
        private readonly changeDetectorRef: ChangeDetectorRef) {

        this.countryService.countries.subscribe(allCountries => {
            this.countries = allCountries.filter((country) => {
                return this.mainMarketCountryNames.indexOf(country.alpha_3) != -1;
            }).sort(this.countryService.sortFunction)
                .concat(allCountries.filter((country) =>
                    ((!this.activeMarketsOnly || country.active_market) && !country.blacklist) && this.mainMarketCountryNames.indexOf(country.alpha_3) == -1
                ).sort(this.countryService.sortFunction));
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['addressForm']) {
            this.addressForm.addControl("hereAPIAddressSearch", new UntypedFormControl());
            this.addressForm.addControl("ukAddressSearch", new UntypedFormControl());

            this.addressForm.get("country").valueChanges.subscribe((value) => {
                this.hereSearchConfiguration.setSearchInCountry(value, this.countryLang);
                this.handleCountryChange(value, true);
            });
            this.handleCountryChange(this.country, false);

            this.addressForm.get("hereAPIAddressSearch").valueChanges.subscribe(addressId => {
                this.hereLookupService.lookupAddress(this.hereSearchConfiguration, addressId).subscribe(selectedAddress => {
                    this.hereApiSearchLoading = false;
                    selectedAddress.id = addressId;
                    this.hereApiSelectionEmitter.emit(selectedAddress);
                    this.addressForm.get('hereAPIAddressSearch').setValue('', { emitEvent: false });
                });
            });

            this.hereSearchConfiguration.setSearchInCountry(this.country, this.countryLang);
            this.hereSearchConfiguration.setSearchAtPosition(0.118092, 51.509865);
        }
    }

    ngOnInit(): void {
        // Init Here API lookup
        this.searchHereApi.pipe(
            auditTime(500),
            distinctUntilChanged(),
            tap(() => {
                this.hereApiAddressSearchResults = [];
            }),
            filter(term => term?.length > 2),
            tap(() => {
                this.hereApiSearchLoading = true;
            }),
            switchMap(term =>
                this.hereLookupService.searchAddress(this.hereSearchConfiguration, term).pipe(
                    catchError(() => of([])), // empty list on error
                    tap(results => {
                        this.hereApiSearchLoading = false;
                        this.amplitudeService.trackAddressSearchPerformed(this.router.url, this._getFormId(), this.country, results.length);
                    })
                )
            )
        ).subscribe(results => {
            this.hereApiAddressSearchResults = results;
        });
    }

    private _getFormId(): string {
        let formId: string = "none";
        if (this.addressForm.parent?.get("personFormId")) {
            formId = this.addressForm.parent.get("personFormId").value;
        }
        return formId;
    }

    private handleCountryChange(country: string, clearValues: boolean) {

        if (clearValues) {
            if (this.addressForm.get("idCheckAddressLookupAddressCode").value) {
                let formId: string = "none";
                if (this.addressForm.parent.get("personFormId")) {
                    formId = this.addressForm.parent.get("personFormId").value;
                }
                this.amplitudeService.trackAddressSearchResultDiscarded(this.router.url, formId, this.country, "country");
            }

            this.clearValues();
        }
        this.clearValidators();
        this.addressFieldsLayout = null;


        switch (country) {
            case "GBR":
            case "IRL":
            case "ITA":
                this.addressFieldsLayout = country;
                break;
            default:
                this.addressFieldsLayout = "generic";
        }
        this.changeDetectorRef.detectChanges();
    }

    private clearValues() {
        for (const formFieldName in this.fieldMetadata) {
            const control = this.addressForm.get(formFieldName);
            control.patchValue("", { emitEvent: false });
        }
    }

    private clearValidators() {
        for (const formFieldName in this.fieldMetadata) {
            const fieldData = this.fieldMetadata[formFieldName];
            const control = this.addressForm.get(formFieldName);
            control.setValidators(CustomValidators.getAccountMaxlengthValidator(fieldData.modelFieldName));
            fieldData.mandatory = false;
        }
    }

    ukSearchResultSelected(event: UkAddress) {
        this.w2UkLookupSelectionEmitter.emit(event);
    }

    ukSearchQueryResults(results: AddressEntry[]) {
        let formId: string = "none";
        if (this.addressForm.parent.get("personFormId")) {
            formId = this.addressForm.parent.get("personFormId").value;
        }
        this.amplitudeService.trackAddressSearchPerformed(this.router.url, formId, this.country, results ? results.length : 0);
    }

    get country(): string {
        return this.addressForm.controls['country'].value;
    }

    get countryLang(): string {
        const countryMetadata = this.countries.find(c => c.alpha_3 === this.country);
        return countryMetadata ? countryMetadata.lang : null;
    }

    getCountryLabel(): string {
        return this.countries.find(c => c.alpha_3 === this.country).name;
    }

}
