import { Component, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { LeadStartData } from '@shared/services/lead-data-mediator.service';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import * as companyTypeByCountry from "@shared/data/company_types.json";
import { filter } from 'rxjs/operators';
import { SignupSubStepOutboundAction, SignupSubStepType } from '../../signup-step.model';

type BusinessCategory = 'REGISTERED_BUSINESS' | 'OTHER_ENTITIES' | 'SOLETRADER';

interface CompanyType {
    label: string;
    value: string;
    category: BusinessCategory;
}

@Component({
    selector: 'substep-company-type',
    templateUrl: './company-type.component.html',
    styleUrls: ['./company-type.component.scss']
})
export class CompanyTypeSubStepComponent extends SignupSubStepType {
    subStepName: string = "Company Type";

    companyTypesGroupedByCategory: Array<Array<CompanyType>> = [];
    form: UntypedFormGroup;
    private soleTraderEventSent = false;

    constructor(
        private fb: UntypedFormBuilder,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService,
        private registrationDataService: RegistrationDataService) {

        super();

        this.controlActionEventEmitter = new EventEmitter<SignupSubStepOutboundAction>();
    }

    init(d: Partial<LeadStartData>): void {

        if (d.companyCountry in companyTypeByCountry) {

            const companyTypesSortedByCategory: Array<CompanyType> = (companyTypeByCountry[d.companyCountry] as Array<CompanyType>)
                .sort(this.sortByCompanyCategory());

            companyTypesSortedByCategory.forEach(type => {
                const lastCategoryGroup = this.companyTypesGroupedByCategory[this.companyTypesGroupedByCategory.length - 1];
                if (lastCategoryGroup && type.category == lastCategoryGroup[0].category) {
                    lastCategoryGroup.push(type);
                } else {
                    this.companyTypesGroupedByCategory.push([type]);
                }
            });

            const isPreviousTypeValid: boolean = companyTypesSortedByCategory.find(type => type.value === d.companyType) !== undefined;

            if (d.companyType && isPreviousTypeValid) {
                this._scrollCompanyTypeIntoView(d.companyType);
            }
            const isSoleTraderSelectionEnabled = this.registrationDataService.isSoleTraderSelectionEnabled();
            this.form = this.fb.group({
                "companyType": [isPreviousTypeValid ? d.companyType : null, [Validators.required, (companyTypeControl: AbstractControl): ValidationErrors => {
                    const isSoleTraderCategory = this.isSoleTraderType(companyTypeControl.value, d.companyCountry);

                    if (companyTypeControl.value && isSoleTraderCategory && !isSoleTraderSelectionEnabled) {
                        return {
                            "selectionNotAllowed": true
                        };
                    } else {
                        return null;
                    }

                }]]
            });
            if (!isSoleTraderSelectionEnabled) {
                this.form.get('companyType').valueChanges
                    .pipe(
                        filter(value => this.isSoleTraderType(value, d.companyCountry))
                    ).subscribe(value => {
                        if (!this.soleTraderEventSent) {
                            this.amplitudeService.trackSoleTraderCategoryErrorVisualized(value);
                            this.soleTraderEventSent = true;
                        }
                    });
            }

        } else {
            this.form = this.fb.group({
                "companyTypeOther": [d.companyTypeOther, Validators.required]
            });
        }
    }

    private sortByCompanyCategory(): (a: CompanyType, b: CompanyType) => number {
        const companyCategoryIndexes: BusinessCategory[] = [
            'REGISTERED_BUSINESS',
            'OTHER_ENTITIES',
            'SOLETRADER'
        ];
        return (a, b) => {
            return companyCategoryIndexes.indexOf(a.category) - companyCategoryIndexes.indexOf(b.category);
        };
    }

    private isSoleTraderType(value: string, companyCountry: string) {
        const companyTypes = companyTypeByCountry[companyCountry] as Array<CompanyType>;
        return companyTypes.some(type => type.value === value && type.category == 'SOLETRADER');
    }

    private _scrollCompanyTypeIntoView(companyType: string) {
        setTimeout(() => {
            const companyTypeSelected = document.querySelector(`label[for='${  companyType  }']`);
            companyTypeSelected.scrollIntoView({ behavior: "smooth", block: "end" });
        }, 0);
    }

    isValid(): boolean {
        if(this.form.get("companyType")) {
            this.form.get("companyType").markAsTouched();
        } else {
            this.form.get("companyTypeOther").markAsTouched();
        }
        return this.form.valid;
    }

    getData(): Partial<LeadStartData> {

        const formValues = this.form.value;
        return {
            companyType: formValues["companyType"],
            companyTypeOther: formValues["companyTypeOther"]
        };
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        }
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

}
