import { Component, Input, Type, ViewEncapsulation } from '@angular/core';
import { RegistrationDataService } from '../../services/registrationData.service';
import { SaveAndContinueLaterService } from '../../services/save-and-continue-later.service';
import { SoldoCookieService } from '../../services/soldo-cookie.service';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '../../services/amplitude.service';
import { Account, Lead } from '../../models/registration.model';
import { ModalDialogService } from '@shared/services/modal-dialog.service';
import { SaveAndContinueComponent } from '@shared/dialog-contents/save-and-continue/save-and-continue.component';

@Component({
    selector: 'save-and-continue-later',
    templateUrl: './save-and-continue-later.component.html',
    styleUrls: ['./save-and-continue-later.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SaveAndContinueLaterComponent {

    overlayVisible: boolean = false;
    emailRecipient: string = "";

    @Input()
    stepName: string;
    @Input()
    substepName: string;

    constructor(
        private readonly registrationDataService: RegistrationDataService,
        private readonly cookieService: SoldoCookieService,
        private readonly translateService: TranslateService,
        private readonly saveAndContinueService: SaveAndContinueLaterService,
        private readonly amplitudeService: AmplitudeService,
        private readonly modalDialogService: ModalDialogService) { }


    saveAndContinueLater(): void {
        this.modalDialogService.showModalDialog(SaveAndContinueComponent as Type<Component>);
        const leadOrAccount: Lead | Account = this.registrationDataService.getLead() || this.registrationDataService.getAccount();
        const registrationId = this.cookieService.readRegistrationIdCookie() || leadOrAccount?.SoldoRegistrationExternalID__c;
        this.saveAndContinueService.sendSaveAndContinueLaterEmail(
            registrationId,
            this.translateService.currentLang)
            .then(() => {
                this.amplitudeService.trackSaveAndContinueClicked(this.stepName, this.substepName);
            });
    }
}
