<h2>{{ "REGISTRATION.DIRECTOR.TITLE" + (soleTrader ? "_SOLETRADER" : "") | translate }}</h2>

<div class="callout informative">
  <div class="callout-content">
    {{ "REGISTRATION.DIRECTOR.DESCRIPTION" + (soleTrader ? "_SOLETRADER" : "") | translate: {regulatoryInstitution: regulatoryInstitution} }}
  </div>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">

  <div class="layout-section" *ngIf="!soleTrader" data-cy="Director_RadioButton_IsLeadDirector">
    <h4><strong>{{ 'REGISTRATION.DIRECTOR.IS_DIRECTOR' | translate }}</strong></h4>
    <p>{{ "REGISTRATION.DIRECTOR.LEGAL_REPRESENTATIVE_DEFINITION"  | translate }}</p>
    <fieldset>
      <div data-cy="Director_RadioButton_IsLeadDirector_TRUE">
        <input id="YES-lead" type="radio"  formControlName="isPrimaryLead" (change)="setPrimaryLeadAsDirector()" [value]="true">
        <label for="YES-lead">{{ "REGISTRATION.DIRECTOR.USER_IS_LEGAL_REP" | translate }}</label>
      </div>
      <div data-cy="Director_RadioButton_IsLeadDirector_FALSE">
        <input id="NO-lead" type="radio"  formControlName="isPrimaryLead" (change)="setNewDirector()" [value]="false">
        <label for="NO-lead">{{ "REGISTRATION.DIRECTOR.USER_IS_NOT_LEGAL_REP" | translate }}</label>
      </div>
    </fieldset>
  </div>

  <ng-container *ngIf="primaryLeadIsDirector !== null">
    <h5 *ngIf="!soleTrader"><strong>{{ "REGISTRATION.DIRECTOR.SUBTITLE" | translate }}</strong></h5>
    <person-form-group [group]="form.get('director')" formGroupName="director"></person-form-group>
  </ng-container>

  <div class="form-page-CTA-row">
    <button type="button" (click)="goToPrevious()" data-cy="Director_Button_StepBackButton">
      {{ 'PREVIOUS' | translate}}
    </button>

    <button type="submit" class="primary" [disabled]="loading || primaryLeadIsDirector === null" [ngClass]="{ 'loading': loading}"
      data-cy="Director_Button_StepSubmitButton">
      {{ 'NEXT' | translate}}
    </button>
  </div>

</form>

<save-and-continue-later stepName="Director Details"></save-and-continue-later>
