import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComplianceStatusContext } from '@shared/models/registration.model';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { APP_CONFIG, AppConfig } from '@src/app/app-config.module';

@Component({
    selector: 'app-identity-verification-landing',
    templateUrl: './identity-verification-landing.component.html',
    styleUrls: ['./identity-verification-landing.component.scss']
})
export class IdentityVerificationLandingComponent {

    translationVariant: "MULTIPLE" | "SINGLE" = "SINGLE";
    complianceContext: ComplianceStatusContext;
    peopleDialogVisible: boolean = false;
    maxdays: number;

    constructor(
        @Inject(APP_CONFIG) private readonly config: AppConfig,
        private readonly translateService: TranslateService,
        private readonly registstrationDataService: RegistrationDataService
    ) {
        this.complianceContext = this.registstrationDataService.getComplianceContext();
        this.maxdays = this.complianceContext.daysUntilExpiration || 7;
        const identityVerificationMetadataList = this.complianceContext.identityVerificationMetadataList;
        this.translationVariant = identityVerificationMetadataList?.length == 1 ? "SINGLE" : "MULTIPLE";
    }

    showPeopleDialog() {
        this.peopleDialogVisible = true;
    }

    hidePeopleDialog() {
        this.peopleDialogVisible = false;
    }

    callToActionOnClick() {
        const homeURL = this.config.websiteHomePage[this.translateService.currentLang] || this.config.websiteHomePage["default"];
        window.location.replace(homeURL);
    }
}
