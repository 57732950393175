<div class="loading-background" *ngIf="loading">
  <div class="loading-screen"></div>
</div>

<div class="hooyu-landing">
  <header>
    <h2 class="v2">{{ "REGISTRATION.HOOYU_LINK_PAGE.TITLE" | translate }}</h2>

    <div class="icon">
      <img src="../../../assets/images/doc_upload.png">
    </div>

  </header>

  <p [innerHTML]="'REGISTRATION.HOOYU_LINK_PAGE.PARAGRAPH_1' | translate : {name: contactFirstName}"></p>
  <p [innerHTML]="'REGISTRATION.HOOYU_LINK_PAGE.PARAGRAPH_2' | translate : {maxdays: 7}"></p>
  <p *ngIf="poaRequired" [innerHTML]="'REGISTRATION.HOOYU_LINK_PAGE.NECESSARY_STEPS_POA_REQUIRED' | translate"></p>
  <p *ngIf="!poaRequired" [innerHTML]="'REGISTRATION.HOOYU_LINK_PAGE.NECESSARY_STEPS' | translate"></p>

  <div class="cta-container">
    <button class="btn btn-primary" (click)="callToActionOnClick()">
      {{ "REGISTRATION.HOOYU_LINK_PAGE.CTA" | translate }}</button>
  </div>

  <p [innerHTML]="'REGISTRATION.HOOYU_LINK_PAGE.PARAGRAPH_3' | translate"></p>
  <p class="hooyu-logo">
    <svg class="mitek-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1500 441.5" overflow="visible" xml:space="preserve">
      <path class="no-adjust" fill="#EE2C46" d="M580.1,95.2c23,0,41.6-18,41.6-41s-18.6-41-41.6-41s-41.6,18-41.6,41S557.2,95.2,580.1,95.2"></path>
      <path fill="#0B1B2B" d="M580.1,144.8c-18.6,0-33.5,14.9-33.5,33.5v161.9c0,19.9-15.7,35.4-34.3,35.4s-34.1-15.5-34.1-35.4V115.7H478
      C478,58,432.1,13.3,375.6,13.3S273.2,58,273.2,115.7v224.5c0,19.9-15.5,35.4-34.1,35.4S205,360,205,340.2V115.7
      C205,58,159.1,13.3,102.6,13.3S0.2,58,0.2,115.7v291.5c0,18.6,15.5,34.1,34.1,34.1s34.1-15.5,34.1-34.1V114.4
      c0-19.8,15.5-35.4,34.1-35.4s34.1,15.5,34.1,35.4V339c0,57.7,45.9,102.4,102.4,102.4S341.5,396.7,341.5,339V114.4
      c0-19.8,15.5-35.4,34.1-35.4s34.1,15.5,34.1,35.4v173.8c0,0.8,0.1,1.5,0.1,2.3v48.5c0,57.7,45.9,102.4,102.4,102.4
      s101.3-44.7,101.3-102.4v-33.1c0-0.7,0.1-1.4,0.1-2V178.3C613.7,159.7,598.8,144.8,580.1,144.8"></path>
      <path fill="#0E202E" d="M681,112.6V46.8c0-18.6,14.9-33.5,33.5-33.5S748,28.2,748,46.8v65.8h86.2c18,0,32.9,14.9,32.9,32.9
      s-14.9,32.9-32.9,32.9H748V326c0,27.9,21.1,49.6,49.6,49.6h36.6c18,0,32.9,14.9,32.9,32.9s-14.9,32.9-32.9,32.9H797
      c-63.9,0-116-52.1-116-116L681,112.6L681,112.6z"></path>
      <path fill="#0E202E" d="M883.8,276.7c0-92,70.2-164.7,164.8-164.7c48.5,0,86.4,19.3,107.6,38.5c7.5,6.8,12.4,16.2,12.4,26.1
      c0,8.7-2.5,16.2-9.9,24.9l-95.7,113.8c-6.8,8.1-14.3,11.2-23.6,11.2c-18.7,0-32.3-13.1-32.3-31.1c0-7.5,1.9-14.9,7.5-20.5l75.2-88.3
      c-10.6-7.5-27.3-11.2-41-11.2c-53.5,0-95.8,44.8-95.8,101.3S994.5,378,1048.6,378c29.2,0,51-12.4,65.9-28
      c9.9-8.1,16.8-15.5,29.8-15.5c19.3,0,33.6,13.7,33.6,33c0,6.8-3.7,15.5-9.9,22.4c-23.6,28.6-66.5,51.6-119.4,51.6
      C954.7,441.4,883.8,368.7,883.8,276.7"></path>
      <path fill="#0E202E" d="M1491.7,383.2l-95-112.4l90-107.4c5.6-6.2,7.4-13,7.4-20.5c0-18-14.3-31-32.3-31c-9.9,0-16.8,3.7-23.6,11.2
      L1337.8,243h-44V34.1c0-19.2-14.9-34.1-34.1-34.1s-34.1,14.9-34.1,34.1v372.5c0,19.2,14.9,34.8,34.1,34.8s34.1-15.5,34.1-34.8v-98
      h45.7l100.2,118.6c6.2,8.1,16.1,12.4,25.4,12.4c18,0,34.8-14.9,34.8-34.1C1499.8,398.1,1496.7,389.4,1491.7,383.2"></path>
    </svg>
  </p>
  <p class="learn-more">
    <a [href]="hooyuArticleLink" target="_blank">{{ "REGISTRATION.HOOYU_LINK_PAGE.HOOYU_ARTICLE_LINK" | translate }}</a>
  </p>
</div>
