import { AfterViewInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { SoldoFieldErrorComponent } from '../soldo-field-error/soldo-field-error.component';

@Component({
    selector: 'soldo-field-layout',
    templateUrl: './soldo-field-layout.component.html',
    styleUrls: ['./soldo-field-layout.component.scss']
})
export class SoldoFieldLayoutComponent implements AfterViewInit {
    @ContentChildren(SoldoFieldErrorComponent) errorMessageComponents: QueryList<SoldoFieldErrorComponent>;

    @Input() cypressSelector: string;
    @Input() fieldName: string;
    @Input() label: string;
    @Input() control: AbstractControl;
    @Input('required') isMandatory: boolean = false;

    ngAfterViewInit(): void {
        this.control.statusChanges.subscribe(this._evaluateErrorVisibility);
        setTimeout(this._evaluateErrorVisibility, 0);
    }
    private _evaluateErrorVisibility = () => {
        // Should this check for "pending" status?
        const errors = this.control.errors || {};
        this.errorMessageComponents.forEach(emc => {

            if (this.control instanceof UntypedFormGroup) {
                const formGroup = <UntypedFormGroup>this.control;
                Object.keys(this.control.controls).forEach(fgControlKey => {
                    if (formGroup.controls[fgControlKey].errors !== null) {
                        Object.keys(formGroup.controls[fgControlKey].errors).forEach(errorKey => {
                            errors[`${fgControlKey}-${errorKey}`] = true;
                        });
                    }
                });
            }
            emc.active = Boolean(errors[emc.errorName]);
        });
    };
}
