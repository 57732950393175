import { Component, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { Account } from '@shared/models/registration.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { ExpectedActivitySubStepOutboundAction, ExpectedActivitySubStepType } from '../../expected-activity-step.model';

enum UseCase {
    pettyCash = 'Petty Cash',
    travel = 'Travel and entertainment',
    team = 'Team Spending',
    company = 'Company Expenses',
    advertising = 'Online Advertising',
    other = 'Other'
}

interface UseCaseData {
    value: UseCase;
    active: boolean;
    showDescription: boolean;
    label: string;
}

@Component({
    selector: 'app-use-case',
    templateUrl: './use-case.component.html',
    styleUrls: ['./use-case.component.scss']
})
export class UseCaseComponent extends ExpectedActivitySubStepType {
    subStepName: string = 'Expected Use Case';

    form: UntypedFormGroup;
    useCases: UseCaseData[] = [
        { value: UseCase.pettyCash, active: false, showDescription: false, label: 'PETTY_CASH' },
        { value: UseCase.travel, active: false, showDescription: false, label: 'TRAVEL' },
        { value: UseCase.team, active: false, showDescription: false, label: 'TEAM' },
        { value: UseCase.company, active: false, showDescription: false, label: 'COMPANY' },
        { value: UseCase.advertising, active: false, showDescription: false, label: 'ADVERTISING' },
        { value: UseCase.other, active: false, showDescription: true, label: 'OTHER' }
    ];

    constructor(
        private fb: UntypedFormBuilder,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService
    ) {
        super();
        this.controlActionEventEmitter = new EventEmitter<ExpectedActivitySubStepOutboundAction>();
    }

    init(d: Partial<Account>): void {
        const activeUseCases = d.ExpectedUseCase__c?.split(';') ?? [];
        this.form = this.fb.group({});
        for (const useCase of this.useCases) {
            useCase.active = activeUseCases.indexOf(useCase.value) > -1;
            this.form.addControl(useCase.label, this.fb.control(useCase.active));
        }
        this.toggleDescription(this.useCases.find(useCase => useCase.showDescription).active, d.ExpectedUseCaseDescription__c);
    }

    getData(): Partial<Account> {
        const data: Partial<Account> = {
            ExpectedUseCaseDescription__c: null,
            ExpectedUseCase__c: ''
        };
        const useCases: UseCase[] = [];
        for (const useCase of this.useCases) {
            if (this.form.get(useCase.label).value) {
                useCases.push(useCase.value);
                if (useCase.showDescription) {
                    data.ExpectedUseCaseDescription__c = this.form.get('description').value;
                }
            } else if (useCase.showDescription) {
                data.ExpectedUseCaseDescription__c = '';
            }
        }
        data.ExpectedUseCase__c = useCases.join(';');
        return data;
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            if (this.form.controls['description']) {
                this.amplitudeService.trackFreeTextSurveyOptionSubmitted(this.subStepName);
            }
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        } else {
            this.form.markAllAsTouched();
        }
    }

    updateCheck(useCase: UseCaseData, checked: boolean) {
        this.form.markAllAsTouched();
        if (useCase.showDescription) {
            this.toggleDescription(checked);
        }
    }

    isValid(): boolean {
        return this.form.valid && this.atLeastOneSelected();
    }

    atLeastOneSelected(): boolean {
        return Object.keys(this.form.controls).some(key => {
            if (key === 'description') {
                return false;
            }
            return this.form.get(key).value;
        });
    }

    private toggleDescription(showDescription: boolean, description: string = null) {
        if (showDescription && !this.form.controls['description']) {
            this.amplitudeService.trackFreeTextSurveyOptionSelected(this.subStepName);
            this.form.addControl('description', this.fb.control(description, [Validators.required, Validators.maxLength(255)]));
        } else if (!showDescription && this.form.controls['description']) {
            this.form.removeControl('description');
        }
    }

}

