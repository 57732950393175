<h3 class="title">{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.TITLE' | translate}}</h3>

<p class="subtitle">{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.FIRST_SUBTITLE_' + country | translate}}</p>
<p class="subtitle">{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SECOND_SUBTITLE' | translate}}</p>

<form [formGroup]="form" novalidate autocomplete="off">
  <div class="search-field input-group">
    <soldo-field-layout [control]="form.controls['companySearch']" fieldName="companySearch" [required]="true"
      cypressSelector="Signup_Button_CompanySearch_SearchTerm" label="">

      <div input class="search-field input-group">
        <input type="text" name="companySearch" formControlName="companySearch" class="form-control"
          (input)="getInputValue($event)"
          placeholder="{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.PLACEHOLDER_' + country  | translate }}" />

        <button class="btn md-text" type="button" (click)="onSearchButtonClicked()"
          value="{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SEARCH_BUTTON' | translate }}"
          data-cy="Signup_Button_CompanySearch_Search">
          {{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SEARCH_BUTTON' | translate }}
        </button>
      </div>

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.REQUIRED_ERROR_' + country  | translate }}</soldo-field-error>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value: form.get('companySearch').errors?.maxlength?.requiredLength} }}</soldo-field-error>
        <soldo-field-error errorName="pattern">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

  </div>
</form>

<ng-container *ngIf="searchResult">

  <div *ngIf="searchResult.totalResults >= tooManyResults" class="too-many-results">
    {{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.TOO_MANY_RESULTS' | translate }}
  </div>

  <div *ngFor="let company of searchResult.companySearchResults; let i = index" class="companyCard"
    [ngClass]="{'companySelected': i == selectedCompanyIndex, 'in-progress': loading}"
    (click)="selectCompanyFromList(i)" [disabled]="loading" data-cy="Signup_Interactive_CompanySearch_Result_{{i}}">
    <h2 class="v2 companyName">{{ company.name }}</h2>
    <div class="companyAddress">{{ company.addressSummary }}</div>
    <div *ngIf="statusToBeTagged(company.status)" class="companyStatusLabel">{{ statusToBeTagged(company.status) }}</div>
  </div>

  <div *ngIf="searchResult.companySearchResults.length == 0">
    <p class="searchResultNull">{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SEARCH_RESULT_NULL.'+searchBy | translate: {searchTerm:
      termSearched} }}</p>
    <div class="d-flex question-tooltip">
      <p class="serach-question">{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.CANNOT_FIND' | translate}}</p>
      <soldo-field-info infoMessage>{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.INFO_' + country | translate }}</soldo-field-info>
    </div>
  </div>

</ng-container>

<button data-cy="Signup_Button_CompanySearch_EnterManually" type="button" (click)="enterManually()"
  class="btn btn-link enter-manually-button">
  {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SWITCH_MANUALLY_LINK' | translate}}
</button>


<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading"
      data-cy="Signup_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.SIGNUP_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <!-- <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()"
      [disabled]="loading || selectedCompanyIndex == -1" [ngClass]="{ 'in-progress': loading}"
      data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.SIGNUP_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div> -->

</div>
