import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppConfig, APP_CONFIG } from '@src/app/app-config.module';
import { TranslateService } from '@ngx-translate/core';
import * as amplitude from 'amplitude-js';
import { NGXLogger } from 'ngx-logger';
import { Application, ApplicationSegment, ComplianceProofDocumentType, ComplianceProofType, ComplianceStatus, UBOProvisioningChoiceType } from '../models/registration.model';
import { RegistrationDataService } from './registrationData.service';
import { SoldoAffiliateData, SoldoCookieService, SoldoMarketingParameters } from './soldo-cookie.service';

export type AmplitudeEventCategory = "Navigation" | "Milestone" | "Interaction" | "Integration" | "Error";

export interface AmplitudeEventProperties {
    app?: "Registration Webapp";
    category: AmplitudeEventCategory,
    subcategory?: string,
    onPage?: string,
    browserLanguage?: string,
    appLanguage?: string,
    selectedPlan?: string,
    selectedOffer?: string,
    [key: string]: any
}

@Injectable()
export class AmplitudeService {

    private amplitude: amplitude.AmplitudeClient;

    private nextFormId: number = 0;

    private uniquenessHelper: object = {};

    private affiliateParams: SoldoAffiliateData;
    private utmParams: SoldoMarketingParameters;

    private readonly CATEGORY_DOCUPLOAD = "Document Upload";
    private readonly CATEGORY_ADDRESS_FORM = "Address Form";
    private readonly CATEGORY_COMPANY_SEARCH = "Company Search";

    constructor(
        private cookieSerivice: SoldoCookieService,
        private dataService: RegistrationDataService,
        private translate: TranslateService,
        private logger: NGXLogger,
        @Inject(APP_CONFIG) private appConfig: AppConfig) {

        this.amplitude = amplitude.getInstance();
        window["amplitude"] = amplitude;

        try {
            this.logger.debug("Initializing Amplitude with", this.appConfig.amplitudeProjectId);
            const userId = cookieSerivice.readRegistrationIdCookie();
            this.amplitude.init(this.appConfig.amplitudeProjectId, userId, {}, client => this._init(client));

        } catch (e) {
            this.logger.debug("Couldn't initialize Amplitude", e);
        }

    }


    private _init(client: amplitude.AmplitudeClient) {
        this.cookieSerivice.registrationIdEventEmitter.on("registration-id-replaced", newId => {
            client.setUserId(newId);
        });

        this.dataService.eKYCResultEventEmitter.subscribe((application: Application) => {
            if (application.complianceStatus
                && application.complianceStatus.status == ComplianceStatus.DOC_REQUIRED
                && (application.complianceStatus.docUploadMetadataList || []).length) {
                this.trackEKYCFail(application.complianceStatus.docUploadMetadataList.length);
            } else {
                this.trackEKYCPass();
            }
        });

        this.dataService.statusAdvancedEventEmitter.subscribe((status: string) => {
            switch (status) {
                case "Terms":
                    this.trackApplicationCompleted(this.dataService.getComplianceStatus() == ComplianceStatus.COMPANY_PROVISIONED);
                    break;
            }
        });

        this.affiliateParams = this.cookieSerivice.readDedicatedAffiliateTrackingCookie();
        this.utmParams = this.cookieSerivice.readDedicatedUTMCookie();
    }

    getNextFormId(): number {
        return this.nextFormId++;
    }

    public setUserMetrics(metrics: { [key: string]: any }): void {
        const identity = new this.amplitude.Identify();
        for (const k in metrics) {
            identity.set(k, metrics[k]);
        }
        this.amplitude.identify(identity);
    }

    public trackEvent(eventName: string, data: { [key: string]: any }, callback: amplitude.Callback) {
        this.amplitude.logEvent(eventName, data, callback);
    }

    private eventProperties(customProperties: AmplitudeEventProperties): AmplitudeEventProperties {
        const defaultProperties: AmplitudeEventProperties = {
            app: "Registration Webapp",
            category: "Navigation",
            selectedPlan: this.dataService.getPlanName(),
            selectedOffer: this.dataService.getOfferName(),
            browserLanguage: this.translate.getBrowserCultureLang(),
            appLanguage: this.translate.currentLang,
            previousPages: document.referrer
        };
        if (this.utmParams) {
            Object.keys(this.utmParams).forEach(param => {
                defaultProperties[param] = this.utmParams[param];
            });
        }
        if (this.affiliateParams) {
            defaultProperties.affiliateId = this.affiliateParams.affiliateId;
        }
        return Object.assign({}, defaultProperties, customProperties);
    }

    public trackPageView(routeSnapshot: ActivatedRouteSnapshot, status: string): void {
        const title = routeSnapshot.data['pageName'] || location.hash.replace('#', '');

        const path = location.hash.replace('#', '');

        if (path != "/") {
            this.amplitude.logEvent(`Viewed ${title}`, this.eventProperties({
                category: "Navigation",
                registrationStatus: status
            }));
        }
    }

    public trackSignupStarted(): void {
        this.setUserMetrics({
            segment: "Self Serve"
        });
        this.amplitude.logEvent("Signup Started", this.eventProperties({
            category: "Milestone"
        }));
    }

    public trackSignupCountryAccepted(countryOfIncorporation: string) {
        const eventName = "Selected Country of Incorporation";
        if (!this.uniquenessHelper[eventName]) {
            this.setUserMetrics({
                companyCountry: countryOfIncorporation
            });
            this.amplitude.logEvent(eventName, this.eventProperties({
                category: "Navigation"
            }));
            this.uniquenessHelper[eventName] = true;
        }
    }

    public trackSignupPrivacyAccepted() {
        const eventName = "Accepted Privacy Policy";
        if (!this.uniquenessHelper[eventName]) {
            this.amplitude.logEvent(eventName, this.eventProperties({
                category: "Navigation"
            }));
            this.uniquenessHelper[eventName] = true;
        }
    }

    public trackSignupCompleted(numberOfEmployees: number, companyCountry: string): void {

        this.setUserMetrics({
            companyCountry: companyCountry,
            soleTrader: this.dataService.isSoleTrader(),
            numberOfEmployees: this.dataService.getCompanySize(),
            companyType: this.dataService.getCompanyType()
        });

        this.amplitude.logEvent("Signup Completed", this.eventProperties({
            category: "Milestone",
            numberOfEmployees: numberOfEmployees,
            market: companyCountry
        }));
    }

    public trackApplicationResumed(status: string, segment: ApplicationSegment = "Self Serve"): void {
        this.setUserMetrics({
            segment: segment
        });
        this.amplitude.logEvent("Application Resumed", this.eventProperties({
            category: "Milestone",
            registrationStatus: status
        }));
    }

    public trackApplicationCompleted(eKycPass: boolean): void {
        this.amplitude.logEvent("Application Completed", this.eventProperties({
            category: "Milestone",
            eKYCPass: eKycPass
        }));
    }

    public trackEKYCPass(): void {
        this.amplitude.logEvent("eKYC Check Passed", this.eventProperties({
            category: "Milestone",
            subcategory: "eKYC"
        }));
    }

    public trackEKYCFail(numberOfRequiredDocuments: number): void {
        this.amplitude.logEvent("eKYC Check Failed", this.eventProperties({
            category: "Milestone",
            subcategory: "eKYC",
            numberOfRequiredDocuments: numberOfRequiredDocuments
        }));
    }

    public trackDocumentUploaded(proofType: ComplianceProofType, documentType: ComplianceProofDocumentType, numFiles: number): void {
        this.amplitude.logEvent("Document Uploaded", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_DOCUPLOAD,
            documentType: documentType,
            proofType: proofType,
            filesUploaded: numFiles
        }));
    }

    public trackSubStepViewed(subStepName: string) {
        this.amplitude.logEvent("SubStep Viewed", this.eventProperties({
            category: "Navigation",
            subStepName: subStepName
        }));
    }

    public trackSubStepCompleted(subStepName: string) {
        this.amplitude.logEvent("SubStep Completed", this.eventProperties({
            category: "Navigation",
            subStepName: subStepName
        }));
    }

    public trackEmailVerificationCodeRequested() {
        this.amplitude.logEvent("Email Verification Code Requested", this.eventProperties({
            category: "Interaction"
        }));
    }

    public trackFreeEmailDomainErrorTriggered(emailProvider: string) {
        this.amplitude.logEvent("Free Email Domain Error Triggered", this.eventProperties({
            category: "Interaction",
            domain: emailProvider
        }));
    }

    public trackExperimentActivation(experimentName: string, page: string): void {
        this.amplitude.logEvent(`${experimentName}ExperimentActivate`, this.eventProperties({
            category: "Integration",
            onPage: page
        }));
    }

    public trackExperimentVariantAssigned(experimentName: string, page: string, variant: number): void {
        this.amplitude.logEvent(`${experimentName}ExperimentVariantAssigned`, this.eventProperties({
            category: "Integration",
            onPage: page,
            variant: variant
        }));
    }

    public trackAddressSearchPerformed(page: string, formId: string, country: string, numResults: number): void {
        this.amplitude.logEvent("Address Search Query", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_ADDRESS_FORM,
            onPage: page,
            formId: formId,
            addressCountry: country,
            numResults: numResults
        }));
    }

    public trackAddressSearchResultSelected(page: string, formId: string, country: string, isFormValid: boolean): void {
        this.amplitude.logEvent("Address Search Result Selected", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_ADDRESS_FORM,
            onPage: page,
            formId: formId,
            addressCountry: country,
            isFormValid: isFormValid
        }));
    }

    public trackAddressFieldEdited(page: string, formId: string, country: string, field: string): void {
        this.amplitude.logEvent("Address Field Manually Edited", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_ADDRESS_FORM,
            onPage: page,
            formId: formId,
            addressCountry: country,
            field: field
        }));
    }

    public trackAddressSearchResultDiscarded(page: string, formId: string, country: string, field: string): void {
        this.amplitude.logEvent("Address Search Result Discarded", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_ADDRESS_FORM,
            onPage: page,
            formId: formId,
            addressCountry: country,
            field: field
        }));
    }

    public trackAddressSearchResultSubmitted(page: string, formId: string, country: string): void {
        this.amplitude.logEvent("Address Search Result Submitted", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_ADDRESS_FORM,
            onPage: page,
            formId: formId,
            addressCountry: country
        }));
    }

    public updateCompanySearchUsageMetric(companySearchResultSubmitted: boolean) {
        this.setUserMetrics({
            usedCompanySearch: companySearchResultSubmitted
        });
    }

    public trackCompanySearchPerformed(searchTerm: string, numResults: number, searchByNumber: boolean = false): void {
        this.amplitude.logEvent("Company Search Query", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_COMPANY_SEARCH,
            searchTerm: searchTerm,
            numResults: numResults,
            searchByNumber: searchByNumber,
            searchTermLenth: searchTerm ? searchTerm.trim().length : 0
        }));
    }

    public trackSoleTraderCategoryErrorVisualized(selectedCompanyType: string): void {
        this.amplitude.logEvent("Sole Trader Disabled Message Visualized", this.eventProperties({
            category: "Interaction",
            selectedType: selectedCompanyType
        }));
    }

    public trackSignupWithXeroAuthorized(): void {
        this.amplitude.logEvent("Signup With Xero Authorized", this.eventProperties({
            category: "Integration",
            subcategory: "Xero"
        }));
    }

    public trackIDVLinkClicked(provider: string, callback: amplitude.Callback): void {
        this.amplitude.logEvent(`Clicked on ${provider} Verification Link`, this.eventProperties({
            category: "Interaction"
        }), callback);
    }

    public trackBeneficialOwnerInputModeChoice(mode: UBOProvisioningChoiceType): void {
        this.amplitude.logEvent("Beneficial Owner Input Mode Selected", this.eventProperties({
            category: "Interaction",
            subcategory: "Beneficial Owner",
            chosenInputMode: mode
        }));
    }

    public trackBeneficialOwnersCreated(total: number, isDirector: boolean, totAdmin: number, totAdditional: number): void {
        this.amplitude.logEvent("Beneficial Owners Saved", this.eventProperties({
            category: "Interaction",
            subcategory: "Beneficial Owner",
            totBeneficialOwners: total,
            totDirectorBO: isDirector ? 1 : 0,
            totAdminBO: totAdmin,
            totAdditional: totAdditional
        }));
    }

    public trackHooYuLivenessCheckStarted(): void {
        this.amplitude.logEvent("HooYu Liveness Check Started", this.eventProperties({
            category: "Interaction",
            subcategory: "HooYu"
        }));
    }

    public trackHooYuLivenessCheckEnded(): void {
        this.amplitude.logEvent("HooYu Liveness Check Ended", this.eventProperties({
            category: "Interaction",
            subcategory: "HooYu"
        }));
    }

    public trackHooYuModalOpened(): void {
        this.amplitude.logEvent("HooYu Modal Opened", this.eventProperties({
            category: "Interaction",
            subcategory: "HooYu"
        }));
    }

    public trackHooYuModalClosed(): void {
        this.amplitude.logEvent("HooYu Modal Closed", this.eventProperties({
            category: "Interaction",
            subcategory: "HooYu"
        }));
    }

    public trackApplicantIsLegalRepresentativeChoice(isLegalRepresentative: boolean): void {
        this.amplitude.logEvent("Legal Representative Choice", this.eventProperties({
            category: "Interaction",
            isLegalRepresentative: isLegalRepresentative
        }));

        this.setUserMetrics({ applicantIsLegalRepresentative: isLegalRepresentative });
    }

    public trackFreeTextSurveyOptionSubmitted(subStepName: string): void {
        this.amplitude.logEvent("Free Text Survey Option Submitted", this.eventProperties({
            category: "Interaction",
            subStepName: subStepName
        }));
    }

    public trackFreeTextSurveyOptionSelected(subStepName: string): void {
        this.amplitude.logEvent("Free Text Survey Option Selected", this.eventProperties({
            category: "Interaction",
            subStepName: subStepName
        }));
    }

    public trackSaveAndContinueClicked(stepName: string, substepName: string): void {
        this.amplitude.logEvent("Clicked Save and Continue", this.eventProperties({
            category: "Interaction",
            step: stepName,
            substep: substepName
        }));
    }

    public trackOnfidoWebSdkTraversal(idvStepName: string, isCrossDevice: boolean): void {
        this.amplitude.logEvent("Onfido Web IDV Traversal", this.eventProperties({
            category: "Interaction",
            subcategory: "Identity Verification",
            idvStepName: idvStepName,
            isCrossDevice: isCrossDevice
        }));
    }

    public trackOnfidoWebSdkError(errorType: string): void {
        this.amplitude.logEvent("Onfido Web IDV Error", this.eventProperties({
            category: "Error",
            subcategory: "Identity Verification",
            errorType: errorType
        }));
    }

    public trackOnfidoWebSdkIdvUserExit(): void {
        this.amplitude.logEvent("Onfido Web IDV User Exit", this.eventProperties({
            category: "Interaction",
            subcategory: "Identity Verification"
        }));
    }

    public trackOnfidoWebSdkIdvComplete(): void {
        this.amplitude.logEvent("Onfido Web IDV Completed", this.eventProperties({
            category: "Interaction",
            subcategory: "Identity Verification"
        }));
    }

}
