import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StaticPreIdvPageComponent } from './static-pre-idv-page/static-pre-idv-page.component';
import { EmbeddedIdvWithMitekComponent } from './embedded-idv-with-mitek/embedded-idv-with-mitek.component';
import { EmbeddedIdvWithOnfidoComponent } from './embedded-idv-with-onfido/embedded-idv-with-onfido.component';
import { IdentityVerifiedComponent } from './identity-verified/identity-verified.component';
import { DirectSalesThankYouPageComponent } from './direct-sales-thank-you-page/direct-sales-thank-you-page.component';

const routes: Routes = [
    {
        path: "static/verify-your-identity",
        data: {
            pageName: "Identity Verification"
        },
        component: StaticPreIdvPageComponent
    },
    {
        path: "static/verify-with-mitek",
        data: {
            pageName: "Mitek Identity Verification"
        },
        component: EmbeddedIdvWithMitekComponent
    },
    {
        path: "static/verify-with-onfido",
        data: {
            pageName: "Onfido Identity Verification"
        },
        component: EmbeddedIdvWithOnfidoComponent
    },
    {
        path: "static/identity-verified",
        data: {
            pageName: "Identity Verified"
        },
        component: IdentityVerifiedComponent
    },
    {
        path: "static/thank-you",
        data: {
            pageName: "Thank You"
        },
        component: DirectSalesThankYouPageComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class StaticPagesRoutingModule { }
