import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { APP_CONFIG, AppConfig } from '@src/app/app-config.module';

@Component({
    selector: 'direct-sales-thank-you-page',
    templateUrl: './direct-sales-thank-you-page.component.html',
    styleUrl: './direct-sales-thank-you-page.component.scss'
})

export class DirectSalesThankYouPageComponent implements OnInit {
  postRegistrationInfoUrl: string;
  companyName: string = null;
  email: string = null;

  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private readonly translateService: TranslateService,
    private readonly registrationDataService: RegistrationDataService) {
  }

  ngOnInit() {
      this.email = this.registrationDataService.getContactEmail();
      this.companyName = this.registrationDataService.getCompanyName();

      this.translateService.onLangChange.subscribe(e => {
          this.handleLanguageChange(e.lang);
      });
      this.handleLanguageChange(this.translateService.currentLang);
  }

  handleLanguageChange(lang) {
      this.postRegistrationInfoUrl = this.config.postRegistrationInfoURLs[lang] || this.config.postRegistrationInfoURLs.fallback;
  }

  onClickCTA() {
      window.location.assign(this.postRegistrationInfoUrl);
  }
}
