import { Injectable, EventEmitter } from "@angular/core";

export interface IAlert {
    id?: number;
    type: string;
    message: string;
    timeout?: number;
}

@Injectable()
export class AlertService {

    alertsChanged: EventEmitter<IAlert[]> = new EventEmitter();
    private alerts: IAlert[] = [];

    constructor() {}

    public add(alert: IAlert) {

        if (alert.id) {
            this.remove(alert.id);
        } else {
            alert.id = this.alerts.length ? this.alerts[this.alerts.length - 1].id + 1 : 0;
        }
        this.alerts.push(alert);
        this.alertsChanged.emit(this.alerts);
        setTimeout(() => this.remove(alert.id), alert.timeout || 20000);

    }

    public remove(id: number) {
        this.alerts = this.alerts.filter(item => item.id !== id);
        this.alertsChanged.emit(this.alerts);
    }
}
