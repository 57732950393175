import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { UkAddressLookupService, AddressEntry, UkAddress } from '../../services/uk-address-lookup.service';
import { NGXLogger } from 'ngx-logger';
import { auditTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { regexValidators } from '../../customValidators';

@Component({
    selector: 'soldo-uk-address-search',
    templateUrl: './soldo-uk-address-search.component.html',
    styleUrls: ['./soldo-uk-address-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SoldoUkAddressSearch implements OnInit {

    @Input()
    public controlName: string = "postcode";

    @Input('group')
    public addressForm: UntypedFormGroup;

    @Output()
    queryResults: EventEmitter<AddressEntry[]> = new EventEmitter<AddressEntry[]>();

    @Output()
    resultSelected: EventEmitter<UkAddress> = new EventEmitter<UkAddress>();

    searchControl: UntypedFormControl;

    addressEntries: Array<AddressEntry> = [];

    showDropdown: boolean = false;

    searchTerm: string = null;
    searchTypeahead = new Subject<string>();
    searchLoading: boolean = false;

    private _value = null;

    constructor(
        private readonly logger: NGXLogger,
        private readonly ukAddressLookupService: UkAddressLookupService) { }

    get value(): UkAddress {
        return this._value;
    }

    set value(selectedValue: UkAddress) {
        this._value = selectedValue;
    }

    ngOnInit() {
        this.searchControl = <UntypedFormControl>this.addressForm.get(this.controlName);

        this.searchTypeahead.pipe(
            tap(() => {
                this.addressEntries = [];
            }),
            auditTime(250),
            distinctUntilChanged(),
            tap(term => {
                this.searchTerm = term;
            }),
            filter(term => typeof term === 'string' && regexValidators.postcodesByCountry.GBR.test(term)))
            .subscribe(postcode => {
                this.searchLoading = true;
                this.ukAddressLookupService.search(postcode).then((result) => {
                    if (result.result === "RESULT_FOUND") {
                        this.addressEntries = result.addressEntries;
                        this.queryResults.emit(this.addressEntries);
                    } else {
                        this.queryResults.emit(null);
                    }
                    this.searchLoading = false;
                    this.showDropdown = true;
                }, () => {
                    this.queryResults.emit(null);
                    this.searchLoading = false;
                });
            });

        this.searchControl.valueChanges.pipe(
            filter(addressId => addressId?.length > 0)
        ).subscribe(addressId => {
            this.ukAddressLookupService.getDetails(addressId)
                .then((response) => {
                    this.addressEntries = [];
                    this.searchControl.patchValue("");
                    this.value = response;
                    this.value.idCheckAddressLookupAddressCode = addressId;
                    this.resultSelected.emit(this.value);
                });
        });
    }
}
