import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AppConfig, APP_CONFIG } from '@src/app/app-config.module';
import { AbstractFormComponent } from '@shared/components/abstract-form.component';
import { MentionMeService } from '@shared/components/mention-me/mention-me.service';
import { Contact } from '@shared/models/registration.model';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { StatusService } from '@shared/services/status.service';
import { StepService } from '@shared/services/steps.service';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'terms-condition',
    templateUrl: './terms-condition.component.html',
    styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent extends AbstractFormComponent implements OnInit {
    form: UntypedFormGroup;
    loading = false;
    termsLink: string;
    showCode: boolean = false;
    isMentiomeEnabled: boolean = false;
    registrationId: string;
    primaryLead: Contact;
    companyName: string;
    isEligibleForStartupOffer: boolean;
    isDirectSales: boolean;

    constructor(
        protected logger: NGXLogger,
        @Inject(APP_CONFIG) private config: AppConfig,
        private router: Router,
        private fb: UntypedFormBuilder,
        private registrationDataService: RegistrationDataService,
        private stepService: StepService,
        private statusService: StatusService,
        private mentiomeService: MentionMeService
    ) {
        super();
    }

    ngOnInit() {
        this.isEligibleForStartupOffer = this.registrationDataService.isEligibleForStartupOffer();
        const accountData = this.registrationDataService.getAccount();
        const productMarket = this.registrationDataService.getProductMarket();
        this.registrationId = accountData.SoldoRegistrationExternalID__c;
        this.primaryLead = this.registrationDataService.getContacts().find((c) => c.ContactIsPrimaryLead__c);
        this.companyName = accountData.Name;

        this.isDirectSales = this.registrationDataService.isDirectSalesSegment();

        this.form = this.fb.group({
            financialTerms: [accountData.SoldoTermsAndConditions__c, Validators.requiredTrue],
            discountCode: [accountData.DiscountCode__c, Validators.maxLength(50)]
        });

        if(!this.isDirectSales) {
            this.form.addControl("cloudTerms", new UntypedFormControl(false, Validators.requiredTrue));
        }

        if (this.isEligibleForStartupOffer) {
            this.form.addControl("startupConfirm", new UntypedFormControl(false, Validators.requiredTrue));
        }

        this.showCode = Boolean(accountData.DiscountCode__c);

        this.isMentiomeEnabled = this.mentiomeService.isEnabled;

        if (this.config.termsAndConditionsLink[productMarket]) {
            this.termsLink = this.config.termsAndConditionsLink[productMarket];
        } else {
            this.termsLink = this.config.termsAndConditionsLink["EU"];
        }
    }

    _saveForm() {
        const formValue = this.form.value;

        this.registrationDataService.saveAccount({
            SoldoTermsAndConditions__c: true,
            DiscountCode__c: formValue.discountCode
        });
    }

    onSubmit(): boolean {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.loading = true;
            this._saveForm();

            const updatedFsSubmissionStatus = this.statusService.getFsSubmissionStatusValidIncrement("Terms");

            this.registrationDataService.saveAccount({
                FsApplicationSubmissionStatus__c: updatedFsSubmissionStatus
            });

            this.registrationDataService.completeApplication().then(() => {

                this.statusService.setStatusIncrementAfterSubmission(updatedFsSubmissionStatus);
                this.router.navigate([this.statusService.getFsSubmissionStatus().resumeFromState()]);

            }, (err) => this.logger.trace("FATAL: ", err));
            return true;
        } else {
            this.loading = false;
            this.logger.error("form not valid: ", this.form.value);
            return false;
        }
    }

    onShowCode() {
        this.showCode = true;
    }

    goToPrevious() {
        // Navigate to the personal page
        //this.router.navigate([this.stepService.getPreviousStep().linkRoute()]);
        this.stepService.navigateToPreviousStep();
    }
}
