<div id="overlay" *ngIf="currentContentComponent != null" data-cy="ModalDialog_Overlay"
  interactive="-1" (interact)="closeFromOutsideDialog($event)">

  <div class="overlay-content">
    <div class="main">
      <button class="x-button" data-cy="ModalDialog_Button_X" (click)="dismiss()">
        <i class="md-icon">close</i>
      </button>
      <div class="info-container">
        <ng-container *ngComponentOutlet="currentContentComponent"></ng-container>
        <div class="row footer">
            <button class="btn btn-primary" data-cy="ModalDialog_Button_Close" (click)="dismiss()">
              {{ 'REGISTRATION.SAVE_AND_CONTINUE.DISMISS' | translate }}
            </button>
        </div>
      </div>
    </div>
  </div>

</div>
