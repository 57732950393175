import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {
    Account, Contact, Lead,
    ACCOUNT_DEFAULT_FIELD_LENGTH,
    CONTACT_DEFAULT_FIELD_LENGTH,
    DEFAULT_STRING_MAX_LENGTH,
    LEAD_DEFAULT_FIELD_LENGTH
} from "./models/registration.model";
import * as moment from 'moment-mini';

const piva = /^(it|IT)?\d{11}$/;
const eircode = /^([AC-FHKNPRTV-Y]\d{2}|D6W)(\s)?[0-9AC-FHKNPRTV-Y]{4}$/i;
const codicePostale = /^\d{5}$/;
const ukPostCode = /^[A-z](\d|\d[A-z0-9]|[A-z]\d|[A-z]\d[A-z0-9])\s?\d[A-z]{2}$/i;
const netherlandsPostcode = /^\d{4}\s?([A-RT-Z][A-Z]|S[B-CE-RT-Z])$/i;
const germanPostcode = /^\d{5}$/;
const frenchPostcode = /^\d{5}/; // allowing chars after the 5 digits on purpose, see CEDEX
const spanishPostcode = /^\d{5}$/;
const emailLocalPartWhitelist = /^([a-zA-Z\d.\-_+&*])*$/;
const emailSubdomainsWhitelist = /^([a-zA-Z\d\-.])*$/;
const emailTopLevelDomainWhitelist = /^([a-zA-Z])*$/;
const singleAtInTheMiddle = /^[^@]+@[^@]+$/;

export const regexValidators = {
    phone: /\d{6,13}$/,
    alphaNum: /[0-9A-Za-z]*/,
    piva: piva,
    rea: /[A-Z]{2}-\d+/,
    postcodesByCountry: {
        IRL: eircode,
        ITA: codicePostale,
        GBR: ukPostCode,
        NLD: netherlandsPostcode,
        DEU: germanPostcode,
        FRA: frenchPostcode,
        ESP: spanishPostcode
    }
};

export class CustomValidators {

    private static italianMobileValidator(number): ValidationErrors | null {
        const error = { notValidNumber: true };
        if (!number.startsWith('3')) {
            return error;
        }
        if (number.length < 9 || number.length > 10) {
            return error;
        }
        return null;
    }

    private static britishMobileValidator(number): ValidationErrors | null {
        const error = { notValidNumber: true };
        if (!number.startsWith('7')) {
            return error;
        }
        if (number.length != 10) {
            return error;
        }
        return null;
    }

    public static validPhone(prefixControlName, numberControlName, required: boolean = true): ValidatorFn {
        return (AC: AbstractControl) => {
            const prefixControl = AC.get(prefixControlName);
            const numberControl = AC.get(numberControlName);
            const phoneRegex = new RegExp(regexValidators.phone);

            if (numberControl.value) {
                if (!phoneRegex.test(numberControl.value)) {
                    return {
                        'notValidNumber': true
                    };
                }

                switch (prefixControl.value) {
                    case "+39":
                        return this.italianMobileValidator(numberControl.value);
                    case "+44":
                        return this.britishMobileValidator(numberControl.value);
                    default:
                        if (numberControl.value.length < 7 || numberControl.value.length > 12) {
                            return {
                                'notValidNumber': true
                            };
                        }
                }
            }

            if (required && !(numberControl.value && prefixControl.value)) {
                return {
                    "required": true
                };
            }

            return null;
        };
    }

    public static validDate(minAge?: number, maxAge?: number): ValidatorFn {
        const now = moment();

        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }
            const date = moment(control.value, 'DD/MM/YYYY', true);
            if (!date.isValid()) {
                return {
                    "invalidDateFormat": true
                };
            } else if ((minAge && now.diff(date, 'years') < minAge) || (maxAge && now.diff(date, 'years') > maxAge)) {
                return {
                    "ageNotAllowed": true
                };
            }
            return null;
        };
    }

    public static cannotUseSoldoPecAddress(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            if (control.value === "fatture@pec.soldo.com") {
                return { "notYourEmail": true };
            }

            return null;
        };
    }

    public static validEmail(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const email: string = control.value;
            if (!email) {
                return null;
            }
            const invalidEmailError = { invalidEmailFormat: true };
            if (!singleAtInTheMiddle.exec(email) || email.includes('..')) {
                return invalidEmailError;
            }
            const { local, subdomains, tld } = CustomValidators.splitEmail(email);
            if (local.length === 0) {
                return invalidEmailError;
            }
            if (!emailLocalPartWhitelist.exec(local)) {
                return invalidEmailError;
            }
            if (subdomains.length === 0) {
                return invalidEmailError;
            }
            if (!emailSubdomainsWhitelist.exec(subdomains)) {
                return invalidEmailError;
            }
            if (CustomValidators.noDotAtStartOrEnd(local) || CustomValidators.noDotAtStartOrEnd(subdomains)) {
                return invalidEmailError;
            }
            if (tld.length < 2) {
                return invalidEmailError;
            }
            if (!emailTopLevelDomainWhitelist.exec(tld)) {
                return invalidEmailError;
            }

            return null;
        };
    }

    private static noDotAtStartOrEnd(part: string) {
        return part.startsWith('.') || part.endsWith('.');
    }

    private static splitEmail(email: string): { local: string, subdomains: string, tld: string } {
        const parts = email.split('@');
        const subdomains = parts[1].split('.');
        const tld = subdomains.pop();
        return {
            local: parts[0],
            subdomains: subdomains.join('.'),
            tld: tld
        };
    }

    public static getLeadMaxlengthValidator(field: keyof Lead): ValidatorFn {
        return Validators.maxLength(LEAD_DEFAULT_FIELD_LENGTH[field] || DEFAULT_STRING_MAX_LENGTH);
    }

    public static getAccountMaxlengthValidator(field: keyof Account): ValidatorFn {
        return Validators.maxLength(ACCOUNT_DEFAULT_FIELD_LENGTH[field] || DEFAULT_STRING_MAX_LENGTH);
    }

    public static getContactMaxlengthValidator(field: keyof Contact): ValidatorFn {
        return Validators.maxLength(CONTACT_DEFAULT_FIELD_LENGTH[field] || DEFAULT_STRING_MAX_LENGTH);
    }
}

