import { Component, OnInit } from '@angular/core';
import { StepService, RegistrationStep } from '@shared/services/steps.service';

@Component({
    selector: 'registration-nav',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    activeStep: RegistrationStep = null;
    activeStepIndex: number = 1;
    numberOfSteps: number;
    // 0 to 1 completion ration of current Step
    absoluteProgress: number = 1;
    maximumTotalSubsteps: number;

    constructor(
        private stepService: StepService) {
    }

    ngOnInit() {
        const steps = this.stepService.getRegistrationFlowSnapshot();
        this.handleStepChange(steps.find(s => s.active));

        this.stepService.stepChanged.subscribe(registrationStep => {
            this.handleStepChange(registrationStep);
        });
        this.stepService.stepProgressChanged.subscribe(currentSubstepActive => {
            this.handleStepChange(this.activeStep, currentSubstepActive);
        });
    }

    handleStepChange(registrationStep: RegistrationStep, completedSubStepsInCurrentStep: number = 0) {
        this.activeStep = registrationStep;
        const stepsInTheFlow = this.stepService.getRegistrationFlowSnapshot();
        this.numberOfSteps = stepsInTheFlow.length;
        this.maximumTotalSubsteps = stepsInTheFlow.map(s => s.maximumSubsteps).reduce((a, b) => {
            return a + b;
        });
        this.activeStepIndex = stepsInTheFlow.indexOf(this.activeStep);

        let substepsCompleted = 0;
        for (let i = 0; i < this.activeStepIndex; i++) {
            substepsCompleted += stepsInTheFlow[i].maximumSubsteps;
        }

        this.absoluteProgress = (substepsCompleted + completedSubStepsInCurrentStep) / this.maximumTotalSubsteps;
    }
}
