<div class="idv-loading-background" *ngIf="loading">
  <div class="loading-screen"></div>
</div>

<div class="idv-page-content" *ngIf="!loading">

  <header>
    <h2 *ngIf="applicantMustIdv">{{ "REGISTRATION.APPLICANT_PRE_IDV_PAGE.TITLE_MUST_IDV" | translate : {name: applicantFirstName } }}</h2>
    <h2 *ngIf="!applicantMustIdv">{{ "REGISTRATION.APPLICANT_PRE_IDV_PAGE.TITLE" | translate : {name: applicantFirstName } }}</h2>

    <div class="image docuploadimage"></div>

    <p><strong>{{ "REGISTRATION.APPLICANT_PRE_IDV_PAGE.SUBTITLE_1" | translate }}</strong></p>
  </header>

  <p [innerHTML]="'REGISTRATION.APPLICANT_PRE_IDV_PAGE.PARAGRAPH_1' | translate : {idvProvider: idvProvider}"></p>

  <div class="necessary-steps">
    <span [innerHTML]="'REGISTRATION.APPLICANT_PRE_IDV_PAGE.NECESSARY_STEPS.INTRO' | translate"></span>
    <ul>
      <li *ngFor="let step of necessaryIdvSteps" [innerHTML]="'REGISTRATION.APPLICANT_PRE_IDV_PAGE.NECESSARY_STEPS.' + step | translate"></li>
    </ul>
  </div>
  <ng-container *ngIf="applicantMustIdv">
    <p><strong>{{ "REGISTRATION.APPLICANT_PRE_IDV_PAGE.INTRO_CTA" | translate }}</strong></p>

    <div class="layout-section cta-section">
      <button
        data-cy="IDV_Button_VerifyYourIdentityButton"
        class="primary"
        (click)="proceedToIDV()">
        {{ "REGISTRATION.APPLICANT_PRE_IDV_PAGE.CTA" | translate }}
      </button>
    </div>
  </ng-container>


  <p [innerHTML]="'REGISTRATION.APPLICANT_PRE_IDV_PAGE.PARAGRAPH_2' | translate"></p>

  <p *ngIf="idvHelpPageUrl" [innerHTML]="'REGISTRATION.APPLICANT_PRE_IDV_PAGE.HELP_PARAGRAPH' | translate : {idvHelpPageUrl, privacyPolicyLink}"></p>

  <ng-container *ngIf="applicantMustIdv">
    <p>
      <strong>{{ "REGISTRATION.APPLICANT_PRE_IDV_PAGE.SUBTITLE_2" | translate }}</strong><br/>
      <span [innerHTML]="'REGISTRATION.APPLICANT_PRE_IDV_PAGE.PARAGRAPH_3_MUST_IDV' | translate"></span>
    </p>
  </ng-container>

  <ng-container *ngIf="!applicantMustIdv">
    <p>
      <strong>{{ "REGISTRATION.APPLICANT_PRE_IDV_PAGE.SUBTITLE_2" | translate }}</strong><br/>
      <span [innerHTML]="'REGISTRATION.APPLICANT_PRE_IDV_PAGE.PARAGRAPH_3' | translate"></span>
    </p>
    <p [innerHTML]="'REGISTRATION.APPLICANT_PRE_IDV_PAGE.PARAGRAPH_4' | translate"></p>

    <div class="layout-section cta-section">
      <button
        data-cy="IDV_Button_LearnMore"
        class="primary"
        (click)="onClickCTA()">
        {{ "REGISTRATION.APPLICANT_PRE_IDV_PAGE.CTA_LEARN_MORE" | translate }}
      </button>
    </div>
  </ng-container>

</div>
