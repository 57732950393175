<div class="idv-loading-background" *ngIf="loading">
  <div class="loading-screen"></div>
</div>

<div class="idv-page-content" *ngIf="!loading">
  <header>
    <h2>{{ "REGISTRATION.ONFIDO_LINK_PAGE.TITLE" | translate }}</h2>

    <img alt="Identity verification" src="../../../assets/images/doc_upload.png">

  </header>

  <p [innerHTML]="'REGISTRATION.ONFIDO_LINK_PAGE.PARAGRAPH_1' | translate : {name: contactFirstName ?? ''}"></p>

  <p [innerHTML]="'REGISTRATION.ONFIDO_LINK_PAGE.NECESSARY_STEPS_POA_REQUIRED' | translate"></p>

  <button data-cy="IDV_Button_VerifyYourIdentityButton" class="primary" (click)="callToActionOnClick()">
    {{ "REGISTRATION.ONFIDO_LINK_PAGE.CTA" | translate }}</button>

  <p [innerHTML]="'REGISTRATION.ONFIDO_LINK_PAGE.PARAGRAPH_3' | translate"></p>

  <div class="idv-logo">
    <img alt="Onfido" src="../../../assets/images/onfido-logo.svg">
  </div>

</div>
