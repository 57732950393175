<header>
  <h3 class="title">{{ 'REGISTRATION.TERMS_CONDITIONS.TITLE' | translate }}</h3>
  <p>{{ 'REGISTRATION.TERMS_CONDITIONS.PARAGRAPH_1' | translate }}</p>
  <p *ngIf="!isDirectSales">{{ 'REGISTRATION.TERMS_CONDITIONS.PARAGRAPH_2' | translate }}</p>
  <p *ngIf="isDirectSales">{{ 'REGISTRATION.TERMS_CONDITIONS.PARAGRAPH_2_DS' | translate }}</p>
</header>

<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
  <div data-cy="TermsConditions_Checkbox_AcceptCloudTerms" *ngIf="this.form.get('cloudTerms')">
    <input id="ACCEPT_CLOUD_TERMS" type="checkbox" (value)="true" formControlName="cloudTerms">
    <label for="ACCEPT_CLOUD_TERMS">
      <div [innerHTML]="'REGISTRATION.TERMS_CONDITIONS.ACCEPT_CLOUD_TERMS' | translate:{value: termsLink}">
      </div>
    </label>

    <div *ngIf="isErrorVisible(form.get('cloudTerms'))" class="text-danger relative">
      <div *ngIf="form.get('cloudTerms').errors?.required">
        {{ "TERMS_AGREEMENT" | translate }}
      </div>
    </div>
  </div>

  <div data-cy="TermsConditions_Checkbox_AcceptFinancialTerms">
    <input id="ACCEPT_FINANCIAL_TERMS" type="checkbox" (value)="true" formControlName="financialTerms">
    <label for="ACCEPT_FINANCIAL_TERMS">
      <div [innerHTML]="'REGISTRATION.TERMS_CONDITIONS.ACCEPT_FINANCIAL_TERMS' | translate:{value: termsLink}">
      </div>
    </label>

    <div *ngIf="isErrorVisible(form.get('financialTerms'))" class="text-danger relative">
      <div *ngIf="form.get('financialTerms').errors?.required">
        {{ "TERMS_AGREEMENT" | translate }}
      </div>
    </div>
  </div>

  <div *ngIf="isEligibleForStartupOffer" data-cy="TermsConditions_Checkbox_ConfirmProStartupPlan">
    <input id="CONFIRM_PROSTARTUP" type="checkbox" (value)="true" formControlName="startupConfirm">
    <label for="CONFIRM_PROSTARTUP">
      <div [innerHTML]="'REGISTRATION.TERMS_CONDITIONS.PROSTARTUP' | translate "></div>
    </label>

    <div *ngIf="isErrorVisible(form.get('startupConfirm'))" class="text-danger">
      <div *ngIf="form.get('startupConfirm').errors?.required">
        <div [innerHTML]="'OTHER_AGREEMENT' | translate:{value: insightsLink}"></div>
      </div>
    </div>
  </div>
  <mention-me-widget [registrationId]="registrationId" [companyName]="companyName" [primaryLead]="primaryLead"></mention-me-widget>

  <div class="code" data-cy="TermsConditions_TextInput_DiscountCode">
    <button class="btn gray-text" (click)="$event.preventDefault(); onShowCode()" data-cy="TermsConditions_Button_ShowDiscountCode">
      {{ 'REGISTRATION.TERMS_CONDITIONS.DISCOUNT_CODE_LABEL' | translate }}
    </button>
    <input *ngIf="showCode || isMentiomeEnabled" type="text" class="form-control" formControlName="discountCode"
      placeholder="{{ 'REGISTRATION.TERMS_CONDITIONS.DISCOUNT_CODE' | translate }}"
      (input)="form.get('discountCode').setValue($event.target.value.toUpperCase())">
    <div *ngIf="isErrorVisible(form.get('discountCode'))" class="text-danger">
      <div *ngIf="form.get('discountCode').errors?.maxlength ">
        {{ 'MAX_LENGHT' | translate:{value: form.get('discountCode').errors?.maxlength.requiredLength} }}
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-6">
      <button type="button" class="btn gray-text go-back" (click)="goToPrevious()"
        data-cy="TermsConditions_Button_StepBackButton"><i class="md-icon">arrow_right_alt</i>
        <span>{{ 'PREVIOUS' | translate }}</span>
      </button>
    </div>
    <div class="col-6 text-right">
      <button type="submit" class="btn btn-primary" [disabled]="loading"
        [ngClass]="{ 'in-progress': loading}" data-cy="TermsConditions_Button_StepSubmitButton">{{ 'REGISTRATION.TERMS_CONDITIONS.SUBMIT' | translate }}</button>
    </div>
  </div>
</form>
<save-and-continue-later stepName="Terms and Conditions"></save-and-continue-later>
