
import { Injectable } from '@angular/core';
import { RequestAggregatorService } from "./requestAggregator.service";


export interface UkAddress {
    addressType: string;
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    line5: string;
    postcode: string;
    street: string;
    houseNumber: string;
    houseName: string;
    secondaryStreet: string;
    subBuilding: string;
    city: string;
    adminAreaCode: string;
    adminAreaName: string;
    barcode: string;
    block: string;
    buildingName: string;
    buildingNumber: string;
    company: string;
    countryIso2: string;
    countryIso3: string;
    countryIsoNumber: string;
    dataLevel: string;
    department: string;
    district: string;
    idCheckAddressLookupAddressCode: string;
    label: string;
    neighbourhood: string;
    poBoxNumber: string;
    postalCode: string;
    province: string;
    provinceCode: string;
    provinceName: string;
    sortingNumber1: string;
    sortingNumber2: string;
    language: string;
    languageAlternatives: string;
    text: string;
    addressServiceRefCode: string;
}

interface UkAddressServiceResponse {
    bundleName: string;
    result: addressSearchResultType;
    sandbox: boolean;
    serviceCallReference: string;
}

export interface AddressResponse extends UkAddressServiceResponse {
    addressMatch: UkAddress
}

export interface AddressSearchResponse extends UkAddressServiceResponse {
    addressMatchList: Array<AddressEntry>;
}

export declare type addressSearchResultType = "RESULT_FOUND" | "NO_RESULT_FOUND" | "SERVICE_NOT_AVAILABLE" | "WRONG_PARAMETER";

export interface AddressEntry {
    idCheckAddressLookupAddressCode: string;
    text: string;
}

export interface AddressSearchResult {
    addressEntries: Array<AddressEntry>;
    result: addressSearchResultType;
}

@Injectable()
export class UkAddressLookupService {

    private static REST_SERVICE_NAME = "w2KycProviderRestService";
    private static SEARCH_METHOD = "addressLookup";
    private static ADDRESS_METHOD = "addressCheck";

    constructor(
        private requestAggregator: RequestAggregatorService) { }

    search(postCode: string): Promise<AddressSearchResult> {
        // insert a blank space in the postcode for good measure
        if(postCode.indexOf(" ") < 0) {
            postCode = postCode.substring(0, postCode.length - 3) + " " + postCode.substring(postCode.length - 3);
        }
        return this.requestAggregator.sendSingleRequest<AddressSearchResponse>({
            serviceName: UkAddressLookupService.REST_SERVICE_NAME,
            serviceMethod: UkAddressLookupService.SEARCH_METHOD,
            args: [postCode]
        }).then(response => {
            return {
                addressEntries: response.addressMatchList,
                result: response.result
            }
        });
    }

    getDetails(addressId: string): Promise<UkAddress> {
        return this.requestAggregator.sendSingleRequest<AddressResponse>({
            serviceName: UkAddressLookupService.REST_SERVICE_NAME,
            serviceMethod: UkAddressLookupService.ADDRESS_METHOD,
            args: [addressId]
        }).then(response => response.addressMatch);
    }

}
