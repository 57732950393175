import { Component, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { regexValidators } from '@shared/customValidators';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { Account, ACCOUNT_DEFAULT_FIELD_LENGTH } from '@shared/models/registration.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { startWith } from 'rxjs/operators';
import { CompanySubStepOutboundAction, CompanySubStepType } from '../../company-step.model';

@Component({
    selector: 'substep-company-identifiers',
    templateUrl: './company-identifiers.component.html',
    styleUrls: ['./company-identifiers.component.scss']
})
export class CompanyIdentifiersComponent extends CompanySubStepType {
    subStepName: string = "Company Identifiers";

    loading: boolean = true;

    form: UntypedFormGroup;

    market: string;
    skipRegistrationNumber: boolean = false;
    registrationNumberMandatory: boolean = false;
    isItaCharityOrAsd: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService) {

        super();
        this.controlActionEventEmitter = new EventEmitter<CompanySubStepOutboundAction>();
    }

    init(d: Partial<Account>): void {
        this.market = d.RegisteredAddressCountryISO__c;
        this.isItaCharityOrAsd = this.market === 'ITA' && ['AssociazioneSportiva', 'OnlusNoProfit'].indexOf(d.CompanyType__c) > -1;
        this.skipRegistrationNumber = d.SoleTrader__c && d.CompanyType__c !== 'DittaIndividuale';

        const registrationNumberValidators = [];

        if (d.SoleTrader__c) {
            registrationNumberValidators.push(Validators.nullValidator);
        } else {
            if (!this.isItaCharityOrAsd) {
                registrationNumberValidators.push(Validators.required);
                this.registrationNumberMandatory = true;
            }
            if (this.market === 'ITA') {
                registrationNumberValidators.push(Validators.pattern(regexValidators.rea));
            }
            registrationNumberValidators.push(this.accountMaxlengthValidator("CompanyRegistrationNumber__c"));
        }

        this.form = this.fb.group({
            registrationNumber: [d.CompanyRegistrationNumber__c, registrationNumberValidators],
            businessName: [d.Name, [Validators.required, this.accountMaxlengthValidator("Name")]],
            useCodiceFiscale: [Boolean(d.CompanyCodiceFiscale__c)]
        });

        if (!d.SoleTrader__c) {
            this.form.addControl("tradingName", new UntypedFormControl(d.CompanyTradingName__c, this.accountMaxlengthValidator("CompanyTradingName__c")));
        }

        const codiceFiscaleControl = new UntypedFormControl(d.CompanyCodiceFiscale__c, [Validators.required, Validators.pattern('[0-9]{11}')]);
        const vatNumberControl = new UntypedFormControl(d.CompanyVATNumber__c);

        switch (d.RegisteredAddressCountryISO__c) {
            case "ITA":
                vatNumberControl.setValidators([Validators.pattern(regexValidators.piva), Validators.required]);
                break;
            case "SWE":
                vatNumberControl.setValidators([Validators.maxLength(14), Validators.pattern(regexValidators.alphaNum)]);
                break;
            default:
                vatNumberControl.setValidators([Validators.maxLength(12), Validators.pattern(regexValidators.alphaNum)]);
        }

        this.form.get("useCodiceFiscale").valueChanges
            .pipe(startWith(Boolean(d.CompanyCodiceFiscale__c)))
            .subscribe(value => {
                if (value && this.isItaCharityOrAsd) {
                    this.form.addControl("codiceFiscale", codiceFiscaleControl);
                    if (vatNumberControl.touched) {
                        codiceFiscaleControl.markAsTouched();
                    }
                    this._removeFormControl("vatNumber");
                } else {
                    this.form.addControl("vatNumber", vatNumberControl);
                    if (codiceFiscaleControl.touched) {
                        vatNumberControl.markAsTouched();
                    }
                    this._removeFormControl("codiceFiscale");
                }
            });

        this.loading = false;
    }

    isValid(): boolean {
        this.form.markAllAsTouched();
        return this.form.valid;
    }

    getData(): Partial<Account> {
        const formValues = this.form.value;
        return {
            CompanyRegistrationNumber__c: formValues['registrationNumber'],
            CompanyCodiceFiscale__c: formValues['codiceFiscale'] || "",
            CompanyVATNumber__c: formValues['vatNumber'] || "",
            Name: formValues['businessName'],
            CompanyTradingName__c: formValues['tradingName']
        };
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        }
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

    accountMaxlengthValidator(field: keyof Account): ValidatorFn {
        return Validators.maxLength(ACCOUNT_DEFAULT_FIELD_LENGTH[field] || 255);
    }

    _removeFormControl(controlName: string) {
        const control = this.form.get(controlName);
        if (!control) {
            return;
        }
        control.setValue("");
        this.form.removeControl(controlName);
    }
}
