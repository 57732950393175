import { Component, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { Account, ACCOUNT_DEFAULT_FIELD_LENGTH, DEFAULT_STRING_MAX_LENGTH } from '@shared/models/registration.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { ExpectedActivitySubStepOutboundAction, ExpectedActivitySubStepType } from '../../expected-activity-step.model';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CustomValidators } from '@shared/customValidators';

@Component({
    selector: 'app-external-deposit',
    templateUrl: './external-deposit.component.html',
    styleUrls: ['./external-deposit.component.scss']
})
export class ExternalDepositComponent extends ExpectedActivitySubStepType {
    subStepName: string = 'Expected External Deposit';
    externalDepositDescriptionMaxlength: number;

    externalDepositOptions = [
        { label: '0-15%', value: 8 },
        { label: '15-25%', value: 20 },
        { label: '25-50%', value: 38, requireDescription: true },
        { label: 'REGISTRATION.EXPECTED_ACTIVITY_STEP.DEPOSIT.OVER_50_PERCENT', value: 51, requireDescription: true }
    ];
    form: UntypedFormGroup;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly amplitudeService: AmplitudeService,
        private readonly gtmService: GoogleTagManagerService
    ) {
        super();
        this.controlActionEventEmitter = new EventEmitter<ExpectedActivitySubStepOutboundAction>();
    }

    init(d: Partial<Account>): void {
        this.form = this.fb.group({
            externalDeposit: [null, Validators.required],
            description: [d.ThirdPartyLoadSourceDescription__c,
                [Validators.required, CustomValidators.getAccountMaxlengthValidator("ThirdPartyLoadSourceDescription__c")]
            ]
        });
        this.form.get('externalDeposit').valueChanges.pipe(
            map(value => this.externalDepositOptions.find(option => option.value === value)?.requireDescription),
            distinctUntilChanged()
        ).subscribe((requireDescription) => {
            if (requireDescription && !this.form.get('description')) {
                this.form.addControl('description', this.fb.control(null,
                    [Validators.required, CustomValidators.getAccountMaxlengthValidator("ThirdPartyLoadSourceDescription__c")])
                );
            } else if (!requireDescription && this.form.get('description')) {
                this.form.removeControl('description');
            }
        });
        this.externalDepositDescriptionMaxlength = ACCOUNT_DEFAULT_FIELD_LENGTH["ThirdPartyLoadSourceDescription__c"] || DEFAULT_STRING_MAX_LENGTH;

        this.form.get('externalDeposit').setValue(d.ThirdPartyLoadSource__c);
    }

    getData(): Partial<Account> {
        return {
            ThirdPartyLoadSource__c: this.form.get('externalDeposit').value,
            ThirdPartyLoadSourceDescription__c: this.form.controls['description'] ? this.form.get('description').value : ''
        };
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        } else {
            this.form.markAllAsTouched();
        }
    }

    isValid(): boolean {
        return this.form.valid;
    }

}
