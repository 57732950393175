import { UntypedFormArray, AbstractControl, ValidationErrors, UntypedFormGroup } from "@angular/forms";


export class ContactsCustomValidators {

    public static duplicateEmailValidator(contacts: UntypedFormArray) {

        contacts.controls.forEach((contact, index) => {
            if (!contact.get('isAdmin')?.value || contact.get('isDirector')?.value) {
                ContactsCustomValidators.clean_error(contact.get('email'), 'duplicated');
                return;
            }

            const duplicateFound = contacts.controls.filter((c, i) => {
                return i != index && (c.get('isAdmin')?.value || c.get('isDirector')?.value);
            }).some(c => {
                return c.get('email').value?.toLowerCase() === contact.get('email').value?.toLowerCase();
            });

            if (duplicateFound) {
                ContactsCustomValidators.set_error(contact.get('email'), 'duplicated');
            } else {
                ContactsCustomValidators.clean_error(contact.get('email'), 'duplicated');
            }
        });
    }

    public static duplicatePhoneValidator(contactsFormArray: UntypedFormArray, directorFormGroup: UntypedFormGroup) {
        const contacts = contactsFormArray.controls.concat(directorFormGroup);

        contacts.forEach((contact, index) => {
            const icc = contact.get('phone').get('prefix').value?.trim();
            const number = contact.get('phone').get('number').value?.trim();

            if (!contact.get('isAdmin')?.value || contact.get('isDirector')?.value) {
                ContactsCustomValidators.clean_error(contact.get('phone'), 'duplicated');
                return;
            }
            const duplicateFound = contacts.filter((c, i) => {
                return i != index && (c.get('isAdmin')?.value || c.get('isDirector')?.value);
            }).some(c => {
                const compareIcc = c.get('phone').get('prefix').value?.trim();
                const compareNumber = c.get('phone').get('number').value?.trim();
                return `${compareIcc}${compareNumber}` === `${icc}${number}`;
            });

            if (duplicateFound) {
                ContactsCustomValidators.set_error(contact.get('phone'), 'duplicated');
            } else {
                ContactsCustomValidators.clean_error(contact.get('phone'), 'duplicated');
            }
        });
    }

    public static duplicateCfValidator(contactsFormArray: UntypedFormArray, directorFormGroup: UntypedFormGroup) {
        const contacts = contactsFormArray.controls.concat(directorFormGroup);

        contacts.forEach((contact, index) => {
            if (!contact.get('isAdmin')?.value || !contact.get('fiscalCode')?.value || contact.get('isDirector')?.value) {
                ContactsCustomValidators.clean_error(contact.get('fiscalCode'), 'duplicated');
                return;
            }

            const duplicateFound = contacts.filter((c, i) => {
                return i != index && c.get('fiscalCode') && (c.get('isAdmin')?.value || c.get('isDirector')?.value);
            }).some(c => {
                return c.get('fiscalCode')?.value?.toLowerCase() === contact.get('fiscalCode')?.value?.toLowerCase();
            });

            if (duplicateFound) {
                ContactsCustomValidators.set_error(contact.get('fiscalCode'), 'duplicated');
            } else {
                ContactsCustomValidators.clean_error(contact.get('fiscalCode'), 'duplicated');
            }
        });
    }

    private static clean_error(control: AbstractControl, errorName: string) {
        if (!control) {
            return;
        }

        let errors = control.errors;
        if (errors && errorName in errors) {
            delete errors[errorName];
        }

        if (!errors || Object.keys(errors).length === 0) {
            errors = null;
        }

        control.setErrors(errors);
    }

    private static set_error(control: AbstractControl, errorName: string) {
        let errors = control.errors as ValidationErrors;
        if (!errors) {
            errors = {};
        }
        errors[errorName] = true;

        control.updateValueAndValidity({ emitEvent: false });
        control.setErrors(errors);
    }

}
