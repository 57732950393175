<div [formGroup]="formGroup">
  <input-label forControl="fiscalCode" required="true">
    {{ 'REGISTRATION.PERSON.FISCAL_CODE_LABEL' | translate }}
  </input-label>
  <input formControlName="fiscalCode" class="form-control"
    name="fiscalCode"
    [readOnly]="readonly"
    placeholder="{{ 'REGISTRATION.PERSON.FISCAL_CODE_PLACEHOLDER' | translate }}"
    type="text" #input (input)="input.value=$event.target.value.toUpperCase()">
  <input formControlName="dob" type="hidden" >
  <input type="hidden" formControlName="birthPlace" >
  <input type="hidden" formControlName="birthState" >
  <input type="hidden" formControlName="gender">
</div>
