import { Component, Input, Renderer2, ViewEncapsulation } from '@angular/core';
import { RegistrationDataService } from '../../services/registrationData.service';
import { SaveAndContinueLaterService } from '../../services/save-and-continue-later.service';
import { SoldoCookieService } from '../../services/soldo-cookie.service';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '../../services/amplitude.service';

@Component({
    selector: 'save-and-continue-later',
    templateUrl: './save-and-continue-later.component.html',
    styleUrls: ['./save-and-continue-later.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SaveAndContinueLaterComponent {

    overlayVisible: boolean = false;
    emailRecipient: string = "";

    @Input()
    stepName: string;
    @Input()
    substepName: string;


    // eslint-disable-next-line no-useless-constructor
    constructor(
        private renderer: Renderer2,
        private registrationDataService: RegistrationDataService,
        private cookieService: SoldoCookieService,
        private translateService: TranslateService,
        private saveAndContinueService: SaveAndContinueLaterService,
        private amplitudeService: AmplitudeService) { }

    saveAndContinueLater(): void {
        this.showDialog();
        this.saveAndContinueService.sendSaveAndContinueLaterEmail(
            this.cookieService.readRegistrationIdCookie(),
            this.translateService.currentLang)
            .then(() => {
                this.amplitudeService.trackSaveAndContinueClicked(this.stepName, this.substepName);
            });
    }

    showDialog(): void {
        this.emailRecipient = this.registrationDataService.getContactEmail();
        this.renderer.addClass(document.body, 'modal-is-open');
        this.overlayVisible = true;
    }

    hideDialog(): void {
        this.overlayVisible = false;
        this.renderer.removeClass(document.body, 'modal-is-open');
    }

}
