
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RegistrationDataService } from './registrationData.service';
import { Account, Contact, Lead } from '../models/registration.model';
import { RegistrationStatusMap, StatusService } from './status.service';
import { SoldoCookieService } from './soldo-cookie.service';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { AmplitudeService } from './amplitude.service';
import { NGXLogger } from 'ngx-logger';
import { CompanyLookupResult } from '../models/company-search.model';


export interface LeadStartData {
    leadConverted?: boolean;
    firstName: string;
    lastName: string;
    email: string;
    language: string;
    companyName: string;
    companyCountry: string;
    companyType: string;
    companyTypeOther: string;
    companySize: number;
    phone?: string;
    phoneICC?: string;
    privacyAccepted?: boolean;
    emailVerificationToken?: string;
    kybProviderId?: string;
}

export interface ReasonForUseData {
    reasonForUse: string;
}

export interface FreeTextUseCaseData {
    businessDescription: string;
    useCaseDescription: string;
}

@Injectable()
export class LeadDataMediatorService {

    // eslint-disable-next-line no-useless-constructor
    constructor(
        protected logger: NGXLogger,
        private registrationDataService: RegistrationDataService,
        private statusService: StatusService,
        private soldoCookieService: SoldoCookieService,
        private gtmService: GoogleTagManagerService,
        private amplitudeService: AmplitudeService) { }


    isConverted(): boolean {
        return this.registrationDataService.getLead() === null;
    }

    getLeadStartData(): LeadStartData {
        const data: LeadStartData = {
            leadConverted: false,
            firstName: null,
            lastName: null,
            email: null,
            language: null,
            companyCountry: null,
            companyName: null,
            companySize: null,
            companyType: null,
            companyTypeOther: null
        };

        const lead = this.registrationDataService.getLead();
        const account = this.registrationDataService.getAccount();
        const primaryLead = this.registrationDataService.getContacts().find(c => c.ContactIsPrimaryLead__c);
        const fsSubmisstionStatus = this.statusService.getFsSubmissionStatus();

        data.leadConverted = lead == null;
        const isStatusPastLeadStep = !["UndefinedStatus", "Started"].includes(fsSubmisstionStatus.key);

        if (isStatusPastLeadStep) {
            data.privacyAccepted = true;
            if (data.leadConverted) {
                if (primaryLead) {
                    data.firstName = primaryLead.FirstName;
                    data.lastName = primaryLead.LastName;
                    data.email = primaryLead.Email;
                    data.phone = primaryLead.MobilePhone;
                    data.phoneICC = primaryLead.ContactPhoneICC__c;
                }
                data.companyCountry = account.RegisteredAddressCountryISO__c;
                data.companyName = account.Name;
                data.companySize = account.NumberOfEmployees;
                data.companyType = account.CompanyType__c;
                data.companyTypeOther = account.CompanyTypeDescription__c;
                data.kybProviderId = account.kybProviderId;
            } else {
                data.firstName = lead.FirstName;
                data.lastName = lead.LastName;
                data.email = lead.Email;
                data.language = lead.LeadBrowserLanguage__c;
                data.companyCountry = lead.RegisteredAddressCountryISO__c;
                data.companyName = lead.Company;
                data.companySize = lead.NumberOfEmployees;
                data.companyType = lead.CompanyType__c;
                data.companyTypeOther = lead.CompanyTypeDescription__c;
                data.phone = lead.MobilePhone;
                data.phoneICC = lead.MobilePhoneICC__c;
                data.kybProviderId = lead.kybProviderId;
            }
        }

        return data;
    }

    submitEmailData(data: Partial<LeadStartData>): Promise<Contact[] | Lead> {
        const lead = this.registrationDataService.getLead();
        let primaryLead = this.registrationDataService.getContacts().find(c => c.ContactIsPrimaryLead__c);
        const newSubmissionStatus = this.statusService.getFsSubmissionStatusValidIncrement("Started");

        if (lead == null) {

            if(!primaryLead) {
                primaryLead = this.registrationDataService.getEmptyContact();
                primaryLead.LastName = "unknown";
            }

            const partialContact: Partial<Contact> = {
                Email: data.email,
                ContactIsPrimaryLead__c: true
            };

            this.registrationDataService.saveContacts([Object.assign({}, primaryLead, partialContact)]);
            return this.registrationDataService.submitContacts();

        } else {

            const partialLead: Partial<Lead> = {
                LastName: data.lastName,
                Email: data.email,
                LeadBrowserLanguage__c: data.language,
                Company: data.companyName,
                emailVerificationToken: data.emailVerificationToken,
                FsApplicationSubmissionStatus__c: newSubmissionStatus
            };

            this.registrationDataService.saveLead(partialLead);
            const submission = this.registrationDataService.submitLead();

            submission.then(() => {
                const registrationId = this.registrationDataService.getLead().SoldoRegistrationExternalID__c;
                this.soldoCookieService.updateRegistrationIdCookie(registrationId);
            });

            return submission;
        }

    }

    submitLeadStartData(data: LeadStartData, companySearchProfile?: CompanyLookupResult): Promise<any> {
        const lead = this.registrationDataService.getLead();
        const primaryLead = this.registrationDataService.getContacts().find(c => c.ContactIsPrimaryLead__c);
        const currentFsStubmissionStatus = this.statusService.getFsSubmissionStatus();
        const newSubmissionStatus = this.statusService.getFsSubmissionStatusValidIncrement("Details");
        const isFirstSignup = ["UndefinedStatus", "Started"].indexOf(currentFsStubmissionStatus.key) > -1 && newSubmissionStatus == "Details";

        if (lead == null) {

            const account: Partial<Account> = {
                RegisteredAddressCountryISO__c: data.companyCountry,
                Name: data.companyName,
                CompanyType__c: data.companyType,
                CompanyTypeDescription__c: data.companyTypeOther,
                NumberOfEmployees: data.companySize,
                FsApplicationSubmissionStatus__c: newSubmissionStatus,
                kybProviderId: data.kybProviderId || null
            };

            if (companySearchProfile) {
                account.RegisteredAddressLine1__c = companySearchProfile.registeredAddressLine1;
                account.RegisteredAddressLine2__c = companySearchProfile.registeredAddressLine2;
                account.RegisteredAddressCity__c = companySearchProfile.city;
                account.RegisteredAddressPostalCode__c = companySearchProfile.postcode;
                account.CompanyRegistrationNumber__c = companySearchProfile.registrationNumber;
                if (companySearchProfile.state) {
                    account.RegisteredAddressState__c = companySearchProfile.state;
                }
                if (companySearchProfile.vatNumber) {
                    account.CompanyVATNumber__c = companySearchProfile.vatNumber;
                }
            }

            this.registrationDataService.saveAccount(account);

            const partialContact: Partial<Contact> = {
                FirstName: data.firstName,
                LastName: data.lastName,
                Email: data.email,
                MobilePhone: data.phone,
                ContactPhoneICC__c: data.phoneICC,
                ContactIsPrimaryLead__c: true
            };

            this.registrationDataService.saveContacts([Object.assign({}, primaryLead, partialContact)]);

            return this.registrationDataService.submitContacts().then(() => {
                return this.registrationDataService.submitAccount().then(() => {
                    if (isFirstSignup) {
                        this.gtmService.trackSignedUp();
                        this.amplitudeService.trackSignupCompleted(data.companySize, data.companyCountry);
                    }
                    this.statusService.setStatusIncrementAfterSubmission(newSubmissionStatus);
                });
            });
        } else {

            const partialLead: Partial<Lead> = {
                FirstName: data.firstName,
                LastName: data.lastName,
                Email: data.email,
                LeadBrowserLanguage__c: data.language,
                Company: data.companyName,
                RegisteredAddressCountryISO__c: data.companyCountry,
                CompanyType__c: data.companyType,
                CompanyTypeDescription__c: data.companyTypeOther,
                NumberOfEmployees: data.companySize,
                MobilePhone: data.phone,
                MobilePhoneICC__c: data.phoneICC,
                FsApplicationSubmissionStatus__c: newSubmissionStatus,
                emailVerificationToken: data.emailVerificationToken,
                kybProviderId: data.kybProviderId || null
            };

            this.registrationDataService.saveLead(partialLead);
            const submission = this.registrationDataService.submitLead();
            submission.then(() => {
                const registrationId = this.registrationDataService.getLead().SoldoRegistrationExternalID__c;
                this.soldoCookieService.updateRegistrationIdCookie(registrationId);
                if (isFirstSignup) {
                    this.gtmService.trackSignedUp();
                    this.amplitudeService.trackSignupCompleted(data.companySize, data.companyCountry);
                }
            });

            return submission.then(r => {

                this.statusService.setStatusIncrementAfterSubmission(newSubmissionStatus);

                return r;
            });
        }
    }
}
