<main>
  <app-header></app-header>
  <message-alerts></message-alerts>
  <div class="layout">
    <modal-dialog></modal-dialog>
    <resume-overlay *ngIf="!isStaticRoute"></resume-overlay>
    <router-outlet></router-outlet>
  </div>
  <div class="start-over-button" *ngIf="startOverButtonEnabled">
      <a (click)="startOver($event)" href="#">{{ "START_OVER" | translate }}</a>
  </div>
</main>

<div class="version" [ngClass]="{ 'shown': showVersion}">v. {{ appVersion }}</div>
