<h2>{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.MONTHLY_SPEND.TITLE' | translate}}</h2>
<h5>{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.MONTHLY_SPEND.SUB_TITLE' | translate}}</h5>

<div class="layout-section">
  <form [formGroup]="form" novalidate>
    <h5>{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.MONTHLY_SPEND.LABEL' | translate }}</h5>
    <soldo-field-layout *ngIf="form.get('monthlySpend')"
      [control]="form.controls['monthlySpend']"
      fieldName="monthlySpend"
      cypressSelector="Expected_RadioButton_MonthlySpend"
      class="chips-radio"
      >

      <fieldset input>
        <div *ngFor="let option of monthlySpendOptions; let i = index" class="radio-item"
          [attr.data-cy]="'Expected_RadioButton_MonthlySpend_'+option.value">
          <input type="radio" formControlName="monthlySpend" [id]="'monthlySpendOption'+i" [value]="option.value">
          <label [for]="'monthlySpendOption'+i">{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.MONTHLY_SPEND.'+option.label | translate }}</label>
        </div>
      </fieldset>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.MONTHLY_SPEND.RANGE_REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <div class="form-page-CTA-row">
      <button type="button" (click)="previousStep()" data-cy="Expected_Button_PreviousStepButton">
        {{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
      </button>
      <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}"
      (click)="nextStep()" data-cy="Expected_Button_NextStepButton">{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.NEXT_SUB_STEP_BUTTON' | translate }}</button>
    </div>
  </form>
</div>
