<div [formGroup]="addressForm">

  <!--
    The form works in 2 modes: normal and simplified

    Simplified has no search service and only asks for City, Postcode (if applicable),
    and 2 lines of text.

    Normal mode has search (W2 for UK and Here for the others) and asks for the full
    complement of fields.
  -->


  <!-- COUNTRY ROW -->
  <div class="form-input-row parent-position">
    <soldo-field-layout
      cypressSelector="Address_NgSelect_Country"
      [control]="addressForm.controls['country']"
      fieldName="country"
      label="REGISTRATION.ADDRESS.COUNTRY_LABEL"
      [required]="!readonly"
      >
        <ng-select input
          *ngIf="!readonly"
          name="country"
          [items]="countries"
          [dropdownPosition]="'bottom'"
          notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
          type="search"
          [clearable]=false
          bindLabel="name"
          bindValue="alpha_3"
          placeholder="{{ 'REGISTRATION.ADDRESS.COUNTRY_PLACEHOLDER' | translate }}"
          formControlName="country">
        </ng-select>
        <div *ngIf="readonly" input>
          <input class="form-control" type="text" name="country_label" [value]="getCountryLabel()" readonly>
          <input type="hidden" formControlName="country" name="country">
        </div>
        <ng-container errorMessage>
          <div *ngIf="isErrorVisible(addressForm.get('country'))" class="text-danger">
            <div *ngIf="addressForm.get('country').errors?.required">{{ 'REQUIRED' | translate }}</div>
          </div>
        </ng-container>
    </soldo-field-layout>

  </div>

  <!-- HERE API SEARCH ROW -->
  <div class="form-input-row parent-position" *ngIf="country !== 'GBR' && !simplified && !readonly">

    <div class="form-input here-api-search child-position" (clickOutside)="searchResultVisible = false" data-cy="Address_HereAPIAddressSearch_SearchAddress">
      <input-label forControl="idCheckAddressLookupAddressCode">
        {{ 'REGISTRATION.ADDRESS.SEARCH' | translate }}
      </input-label>
      <input type="search" class="form-control"
        placeholder="{{ 'REGISTRATION.ADDRESS.SEARCH_PLACEHOLDER' | translate }}" formControlName="addressSearch"
        name="" (keydown)="keyDown($event)" data-cy="Address_HereAPIAddressSearch_SearchInput">
      <div *ngIf="searchResultVisible" class="address-search-results">
        <div class="address-search-result-line" *ngFor="let addressResult of hereApiAddressSearchResults; let i=index"
          (click)="selectHereApiSearchResult(addressResult)" [class.marked]="i == arrowkeyLocation"
          data-cy="Address_HereAPIAddressSearch_SearchResult">
          {{ addressResult.address.label }}
        </div>
        <div class="address-search-result-line" *ngIf="hereApiAddressSearchResults.length == 0" (click)="searchResultVisible = false"
          data-cy="Address_HereAPIAddressSearch_NoResult">
          {{ 'REGISTRATION.ADDRESS.SEARCH_NO_RESULT' | translate }}
        </div>
      </div>
    </div>

  </div>

  <!-- W2 UK SEARCH BY POSTCODE ROW -->
  <div class="form-input-row parent-position" *ngIf="country === 'GBR' && !readonly">

    <div class="form-input child-position">
      <soldo-uk-address-search
        (resultSelected)="ukSearchResultSelected($event)"
        (queryResults)="ukSearchQueryResults($event)"
        [group]="addressForm"
        [required]="false"
        controlName="ukAddressSearch">
      </soldo-uk-address-search>
      <div *ngIf="isErrorVisible(addressForm.get('ukAddressSearch'))" class="text-danger">
        <div *ngIf="addressForm.get('ukAddressSearch').errors?.pattern ">{{ 'WRONG_FORMAT' | translate }}</div>
      </div>
    </div>

  </div>

  <address-fields-gbr *ngIf="addressFieldsLayout === 'GBR'"
    [readonly]="readonly"
    [formGroup]="addressForm"
    [simplified]="simplified"
    [fieldMetadata]="fieldMetadata"
    [searchResultEmitter]="w2UkLookupSelectionEmitter"
    [manualChangeEmitter]="manualChangeEmitter"
    [detectManualEdits]="detectManualEdits">
  </address-fields-gbr>

  <address-fields-ita *ngIf="addressFieldsLayout === 'ITA'"
    [readonly]="readonly"
    [formGroup]="addressForm"
    [simplified]="simplified"
    [fieldMetadata]="fieldMetadata"
    [searchResultEmitter]="hereApiSelectionEmitter"
    [manualChangeEmitter]="manualChangeEmitter"
    [detectManualEdits]="detectManualEdits">
  </address-fields-ita>

  <address-fields-irl *ngIf="addressFieldsLayout === 'IRL'"
    [readonly]="readonly"
    [formGroup]="addressForm"
    [simplified]="simplified"
    [fieldMetadata]="fieldMetadata"
    [searchResultEmitter]="hereApiSelectionEmitter"
    [manualChangeEmitter]="manualChangeEmitter"
    [detectManualEdits]="detectManualEdits">
  </address-fields-irl>

  <address-fields-generic *ngIf="addressFieldsLayout === 'generic'"
    [readonly]="readonly"
    [formGroup]="addressForm"
    [simplified]="simplified"
    [fieldMetadata]="fieldMetadata"
    [searchResultEmitter]="hereApiSelectionEmitter"
    [manualChangeEmitter]="manualChangeEmitter"
    [detectManualEdits]="detectManualEdits"></address-fields-generic>

  <input type="hidden" formControlName="state" name="state">
  <input type="hidden" formControlName="line1" name="line1">
  <input type="hidden" formControlName="line2" name="line2">
  <input type="hidden" formControlName="line3" name="line3">
  <input type="hidden" formControlName="idCheckAddressLookupAddressCode" name="idCheckAddressLookupAddressCode">
  <input type="hidden" formControlName="poBoxNumber" name="poBoxNumber">
  <input type="hidden" formControlName="secondaryStreet" name="secondaryStreet">
</div>
