<div *ngIf="showFooter" class="footer">
  <div class="trademark">
    {{ 'REGISTRATION.WELCOME.TRADEMARK' | translate }}
  </div>
  <div class="footer-columns">
    <div class="sidebar-sx">
        {{ 'REGISTRATION.WELCOME.REGULATORY_DISCLOSURE_COL_1' | translate }}
    </div>
    <div class="sidebar-dx">
        {{ 'REGISTRATION.WELCOME.REGULATORY_DISCLOSURE_COL_2' | translate }}
    </div>
  </div>
</div>
