import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationDataService } from '../../shared/services/registrationData.service';
import { AmplitudeService } from '../../shared/services/amplitude.service';
import { NGXLogger } from 'ngx-logger';
import { AppConfig, APP_CONFIG } from '@src/app/app-config.module';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'static-pre-idv-page',
    templateUrl: './static-pre-idv-page.component.html',
    styleUrl: './static-pre-idv-page.component.scss'
})
export class StaticPreIdvPageComponent implements OnInit {
    loading: boolean = true;
    idvProvider: 'Onfido' | 'Mitek' = 'Onfido';
    idvHelpPageUrl: string = null;
    privacyPolicyLink: string = null;

    necessaryIdvSteps: string[] = [
        "STEP_OPEN_LINK",
        "STEP_UPLOAD_ID",
        "STEP_UPLOAD_POA",
        "STEP_RECORD_VIDEO"
    ];

    constructor(
        @Inject(APP_CONFIG) private readonly config: AppConfig,
        private readonly logger: NGXLogger,
        private readonly router: Router,
        private readonly translateService: TranslateService,
        private readonly registrationDataService: RegistrationDataService,
        private readonly amplitudeService: AmplitudeService) { }

    ngOnInit(): void {
        const idvData = this.registrationDataService.getIdvData();

        this.logger.debug("idvData", idvData);
        this.privacyPolicyLink = this.config.privacyPolicyLink[this.translateService.currentLang];

        this.translateService.onLangChange.subscribe(e => {
            this.handleLanguageChange(e.lang);
        });
        this.handleLanguageChange(this.translateService.currentLang);

        switch (idvData.provider) {
            case 'HOOYU':
                this.idvProvider = 'Mitek';
                break;
            case 'ONFIDO':
                this.idvProvider = 'Onfido';
                break;
        }
        this.loading = false;
    }

    handleLanguageChange(lang) {
        switch (lang) {
            case "en":
                this.privacyPolicyLink = this.config.privacyPolicyLink.en;
                this.idvHelpPageUrl = this.config.registrationProcessHelpURLs.fallback;
                break;
            case "it":
                this.privacyPolicyLink = this.config.privacyPolicyLink.it;
                this.idvHelpPageUrl = this.config.registrationProcessHelpURLs.ITA;
                break;
            default:
                this.privacyPolicyLink = this.config.privacyPolicyLink.fallback;
                this.idvHelpPageUrl = this.config.registrationProcessHelpURLs.fallback;
                break;
        }
    }

    proceedToIDV(): void {
        this.amplitudeService.trackIDVLinkClicked(this.idvProvider, () => {
            this.router.navigate([`static/verify-with-${this.idvProvider.toLowerCase()}`]);
        });
    }
}
