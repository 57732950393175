import { Injectable, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { APP_CONFIG, AppConfig } from '@src/app/app-config.module';
import { NGXLogger } from 'ngx-logger';

const MARKETO_COOKIE_NAME = "_mkto_trk";

@Injectable()
export class MarketoIntegrationService {

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private cookieService: CookieService,
        private logger: NGXLogger) {


    }

    public getMarketoCookie(): string {
        const marketoCookieValue: string = this.cookieService.get(MARKETO_COOKIE_NAME);
        this.logger.debug("Marketo cookie value retrieved", marketoCookieValue);
        return marketoCookieValue;
    }

}
