<h2>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.TITLE' | translate}}</h2>

<h5>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.FIRST_SUBTITLE_' + country | translate}}</h5>

<div class="layout-section">
  <form [formGroup]="form" novalidate autocomplete="off">
    <soldo-field-layout class="search-field-container" [control]="form.controls['companySearch']" fieldName="companySearch"
      cypressSelector="Signup_Button_CompanySearch_SearchTerm" label="">

      <div input class="search-field">
        <input type="text" name="companySearch" formControlName="companySearch" (input)="getInputValue($event)"
          placeholder="{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.PLACEHOLDER_' + country  | translate }}" />

        <button class="primary" type="submit" (click)="onSearchButtonClicked()"
        [disabled]="searchLoading" [ngClass]="{ 'loading': searchLoading }"
          value="{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SEARCH_BUTTON' | translate }}"
          data-cy="Signup_Button_CompanySearch_Search">
          {{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SEARCH_BUTTON' | translate }}
        </button>
      </div>

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.REQUIRED_ERROR_' + country |
          translate }}</soldo-field-error>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value:
          form.get('companySearch').errors?.maxlength?.requiredLength} }}</soldo-field-error>
        <soldo-field-error errorName="pattern">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

  </form>
</div>
<div class="layout-section">
  <ng-container *ngIf="searchResult">
    <h5 class="too-many-results-text" *ngIf="searchResult.totalResults >= tooManyResults">
      {{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.TOO_MANY_RESULTS' | translate }}
    </h5>

    <form (submit)="onSubmit($event)" *ngIf="searchResult.companySearchResults.length > 0">
      <fieldset>
        <div *ngFor="let company of searchResult.companySearchResults; let i = index"
          class="company-card" data-cy="Signup_Interactive_CompanySearch_Result_{{i}}"
          (click)="onResultClicked(company.kybProviderId)" (keyup)="doNothing()">
          <input type="radio" [value]="company.kybProviderId"
            [formControl]="searchResultControl" [disabled]="loading"
            (click)="$event.stopPropagation()" (keyup)="doNothing()">
          <h3>{{ company.name }}</h3>
          <h5>{{ company.addressSummary }}</h5>
          <p *ngIf="statusToBeTagged(company.status)" class="company-status-tag">{{ statusToBeTagged(company.status) }}</p>
        </div>
      </fieldset>
      <input type="submit">
    </form>

    <div class="empty-result-message-container" *ngIf="searchResult.companySearchResults.length == 0">
      <h5>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SEARCH_RESULT_NULL.' + searchBy | translate: {searchTerm: termSearched} }}</h5>
      <p>
        {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.CANNOT_FIND' | translate}}
        <br/>
        {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.INFO_' + country | translate}}
      </p>
    </div>
  </ng-container>
</div>


<div class="layout-section">
  <button data-cy="Signup_Button_CompanySearch_EnterManually" type="button" (click)="enterManually()"
    class="link no-padding">
    {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SWITCH_MANUALLY_LINK' | translate}}
  </button>
</div>

<!-- Sub-Step Navigation -->
<div class="form-page-CTA-row">
  <button type="submit" [ngClass]="{ 'loading': loading }" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
    {{ 'REGISTRATION.SIGNUP_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
  </button>
</div>
