
export const SELECT_INVALID_SOLDO_FIELD = 'soldo-field-layout:has(.ng-invalid)';

export function scrollToFirstResult(...cssQueries: string[]): void {
    const compoundQuery = cssQueries.join(', ');

    const scrollToElement = document.querySelector(compoundQuery);

    if (scrollToElement) {
        scrollToElement.scrollIntoView({ behavior: 'smooth' });
    }
}
