import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BeneficialOwnerFormComponent, BeneficialOwnerFormGroup } from './beneficial-owner-form/beneficial-owner-form.component';
import { RegistrationDataService } from '../../shared/services/registrationData.service';
import { Contact, ContactOperation } from '../../shared/models/registration.model';
import { ModalDialogService } from '../../shared/services/modal-dialog.service';
import { UboHelpComponent } from '../../shared/dialog-contents/ubo-help/ubo-help.component';
import { StepService } from '../../shared/services/steps.service';
import { NGXLogger } from 'ngx-logger';
import { StatusService } from '../../shared/services/status.service';
import { AmplitudeService } from '../../shared/services/amplitude.service';
import { SELECT_INVALID_SOLDO_FIELD, scrollToFirstResult } from '../../shared/formHelpers';


interface BeneficialOwnersFormGroup {
    readOnlyContacts: FormArray<FormGroup<BeneficialOwnerFormGroup>>;
    contacts: FormArray<FormGroup<BeneficialOwnerFormGroup>>;
}

@Component({
    selector: 'app-beneficial-owners',
    templateUrl: './beneficial-owners.component.html',
    styleUrls: ['./beneficial-owners.component.scss']
})
export class BeneficialOwnersComponent implements OnInit {

    beneficialOwnersForm: FormGroup<BeneficialOwnersFormGroup> = this.fb.group({
        readOnlyContacts: new FormArray<FormGroup<BeneficialOwnerFormGroup>>([]),
        contacts: new FormArray<FormGroup<BeneficialOwnerFormGroup>>([])
    });

    directorsAndAdmins: Contact[];

    loading: boolean = false;

    constructor(
        private logger: NGXLogger,
        private registrationDataService: RegistrationDataService,
        private fb: FormBuilder,
        private modalDialogService: ModalDialogService,
        private statusService: StatusService,
        private stepsService: StepService,
        private amplitudeService: AmplitudeService) { }

    ngOnInit(): void {
        const allContacts = this.registrationDataService.getContacts();
        this.directorsAndAdmins = allContacts.filter(c => c.ContactIsDirector__c || c.ContactIsAdmin__c);

        const beneficialOwners = allContacts.filter(c => c.ContactIsBeneficialOwner__c);

        for (const contact of beneficialOwners) {
            const boFormGroup = BeneficialOwnerFormComponent.createBeneficialOwnerFormGroupForContact(this.fb, contact);
            if(contact.ContactIsDirector__c || contact.ContactIsAdmin__c) {
                this.beneficialOwnersForm.controls.readOnlyContacts.push(boFormGroup);
            } else {
                this.beneficialOwnersForm.controls.contacts.push(boFormGroup);
            }
        }

    }

    get totalBeneficialOwners(): number {
        return this.beneficialOwnersForm.controls.readOnlyContacts.controls.length + this.beneficialOwnersForm.controls.contacts.controls.length;
    }

    onDirectorOrAdminSelected(contactId: string, selected: boolean): void {
        const contact = this.directorsAndAdmins.find(c => c.SoldoContactExternalID__c == contactId);
        contact.ContactIsBeneficialOwner__c = selected;
        if (selected) {
            const boFormFroup = BeneficialOwnerFormComponent.createBeneficialOwnerFormGroupForContact(this.fb, contact);
            this.beneficialOwnersForm.controls.readOnlyContacts.push(boFormFroup);
        } else {
            const position = this.beneficialOwnersForm.controls.readOnlyContacts.controls.findIndex(g => g.controls.contactId.value === contactId);
            this.beneficialOwnersForm.controls.readOnlyContacts.removeAt(position);
        }
    }

    canAddMore(): boolean {
        return this.totalBeneficialOwners < 3;
    }

    addBeneficialOwner(): void {
        const newBoFormGroup = BeneficialOwnerFormComponent.createEmptyBeneficialOwnerFormGroup(this.fb);
        this.beneficialOwnersForm.controls.contacts.push(newBoFormGroup);
    }

    removeBeneficialOwnerAt(index: number): void {
        this.beneficialOwnersForm.controls.contacts.removeAt(index);
    }

    openUBOHelpDialog(): void {
        this.modalDialogService.showModalDialog(UboHelpComponent);
    }

    goToPrevious() {
        this.stepsService.navigateToPreviousStep();
    }

    onSubmit(): boolean {
        this.beneficialOwnersForm.markAllAsTouched();

        if (this.totalBeneficialOwners == 0 || !this.beneficialOwnersForm.valid) {
            this.logger.debug("FORM NOT VALID OR EMPTY!")
            scrollToFirstResult(SELECT_INVALID_SOLDO_FIELD);
            return false;
        }

        this.logger.debug("VALID!", this.beneficialOwnersForm.value);

        const preexistingContactsOperations: ContactOperation[] = this.directorsAndAdmins.map(contact => {
            return {
                SoldoContactExternalID__c: contact.SoldoContactExternalID__c,
                ContactIsBeneficialOwner__c: contact.ContactIsBeneficialOwner__c
            };
        });

        const additionalBeneficialOwnersOperations: ContactOperation[] = this.beneficialOwnersForm.value.contacts.map(bo => {
            return {
                SoldoContactExternalID__c: bo.contactId,
                FirstName: bo.firstName,
                LastName: bo.lastName,
                ContactCitizenshipCountryISO__c: bo.nationality,
                ResidentialAddressCountryISO__c: bo.countryOfResidence,
                Email: bo.email,
                ContactIsBeneficialOwner__c: true
            };
        });

        const contactOperations = [...preexistingContactsOperations, ...additionalBeneficialOwnersOperations];

        this.logger.debug("Update these contacts", [...contactOperations]);

        const unsetBeneficialOwners = this.registrationDataService.getContacts()
            .filter(contact => contact.ContactIsBeneficialOwner__c
                && !contactOperations.find(co => co.SoldoContactExternalID__c === contact.SoldoContactExternalID__c));

        const unsetOrDeleteOperations: ContactOperation[] = unsetBeneficialOwners.map<ContactOperation>(contact => {
            return {
                SoldoContactExternalID__c: contact.SoldoContactExternalID__c,
                ContactIsBeneficialOwner__c: false,
                IsDeleted: !(contact.ContactIsAdmin__c || contact.ContactIsDirector__c)
            };
        });

        this.logger.debug("Unset or delete these Beneficial Owners", unsetOrDeleteOperations);

        contactOperations.push(...unsetOrDeleteOperations);

        this.logger.debug("Full list of operations", contactOperations);

        this.registrationDataService.saveContacts(contactOperations);

        const usageStats = {
            totalBeneficialOwners: contactOperations.filter(o => o.ContactIsBeneficialOwner__c).length,
            directorIsBeneficialOwner: this.directorsAndAdmins.some(c => c.ContactIsDirector__c && c.ContactIsBeneficialOwner__c),
            totalAdminBeneficialOwners: this.directorsAndAdmins.filter(c => c.ContactIsAdmin__c && c.ContactIsBeneficialOwner__c).length,
            totalExtraBeneficialOwners: additionalBeneficialOwnersOperations.filter(c => c.ContactIsBeneficialOwner__c).length
        };

        const postSubmitFsSubmissionStatus = this.statusService.getFsSubmissionStatusValidIncrement("BeneficialOwnerCreated");

        this.registrationDataService.saveAccount({
            FsApplicationSubmissionStatus__c: postSubmitFsSubmissionStatus
        });

        this.loading = true;
        this.registrationDataService.submitContacts().then(() => {
            this.registrationDataService.submitAccount().then(() => {
                this.statusService.setStatusIncrementAfterSubmission(postSubmitFsSubmissionStatus);
                this.amplitudeService.trackBeneficialOwnersCreated(
                    usageStats.totalBeneficialOwners,
                    usageStats.directorIsBeneficialOwner,
                    usageStats.totalAdminBeneficialOwners,
                    usageStats.totalExtraBeneficialOwners);
                this.stepsService.navigateToNextStep();
            });
        }, (err) => this.logger.trace("FATAL: ", err));

        return true;
    }
}
