import { Component, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { Account } from '@shared/models/registration.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { ExpectedActivitySubStepOutboundAction, ExpectedActivitySubStepType } from '../../expected-activity-step.model';

@Component({
    selector: 'app-monthly-spend',
    templateUrl: './monthly-spend.component.html',
    styleUrls: ['./monthly-spend.component.scss']
})
export class MonthlySpendComponent extends ExpectedActivitySubStepType {
    subStepName: string = 'Expected Monthly Spend';

    monthlySpendOptions = [
        { label: 'UNDER_10000_{currency}', value: 5000 },
        { label: '10000_50000_{currency}', value: 30000 },
        { label: '50000_150000_{currency}', value: 100000 },
        { label: 'OVER_150000_{currency}', value: 150001 }
    ];
    form: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService
    ) {
        super();
        this.controlActionEventEmitter = new EventEmitter<ExpectedActivitySubStepOutboundAction>();
    }

    init(d: Partial<Account>): void {
        let currency = 'EUR';
        if (d.RegisteredAddressCountryISO__c === 'GBR') {
            currency = 'GBP';
        }
        for (const opt of this.monthlySpendOptions) {
            opt.label = opt.label.replace('{currency}', currency);
        }
        this.form = this.fb.group({
            monthlySpend: [d.ExpectedMonthlySpend__c, Validators.required]
        });
    }

    getData(): Partial<Account> {
        return {
            ExpectedMonthlySpend__c: this.form.get('monthlySpend').value
        };
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        } else {
            this.form.markAllAsTouched();
        }
    }

    isValid(): boolean {
        return this.form.valid;
    }

}
