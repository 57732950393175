import { Injectable } from '@angular/core';

import { RegistrationDataService } from './registrationData.service';
import { Lead, Account, Contact } from "../models/registration.model";
import { NGXLogger } from 'ngx-logger';

import { IntercomService } from './intercom.service';
import { SoldoCookieService } from './soldo-cookie.service';
import { GoogleTagManagerService } from './google-tag-manager.service';

interface IntercomCompanyData {
    company_id: string;
    name: string;
    country: string;
    "Product": string;
    "Plan Name": string;
    "Offer Name": string;
}

interface IntercomUserData {
    email?: string;
    name?: string;
    "First name"?: string;
    Surname?: string;
    Plan?: string;
    Offer?: string;
    companies?: Array<IntercomCompanyData>;
    "Soldo Registration ID": string;
    "Google Client ID": string;
    "Google Click ID"?: string;
    "Google Click Timestamp"?: number;
    utm_source?: string;
    utm_medium?: string;
    utm_content?: string;
    utm_campaign?: string;
    utm_term?: string;
}

@Injectable()
export class IntercomIntegrationService {

    private _initialized = false;

    constructor(
        private logger: NGXLogger,
        private intercom: IntercomService,
        private registrationData: RegistrationDataService,
        private cookieService: SoldoCookieService,
        private gtmService: GoogleTagManagerService) { }

    init() {
        if (!this._initialized) {
            this.intercom.initializeIntercom();
            this._initialized = true;
        }
    }

    onNavigationEnd(status: string) {
        this.gtmService.requestGoogleClientID().then((clientId) => {
            if (this._initialized) {
                this.updateIntercomProfile(status, clientId);
            }
        });
    }

    setApplicantName(firstName: string, lastName: string) {
        const userData = {
            "First name": firstName,
            "Surname": lastName,
            "name": `${firstName} ${lastName}`
        };

        this.logger.debug("Updating Intercom with ", userData);

        this.intercom.logIn(userData);
    }

    updateIntercomProfile(status: string, clientId: string) {
        const lead: Lead = this.registrationData.getLead();
        const account: Account = this.registrationData.getAccount();
        const primaryLead: Contact = this.registrationData.getContacts().find((c) => c.ContactIsPrimaryLead__c);
        const adwordsData = this.cookieService.readDedicatedGoogleClickIDCookie();
        const marketingParams = this.cookieService.readDedicatedUTMCookie();

        const userData: IntercomUserData = {
            "Soldo Registration ID": this.cookieService.readRegistrationIdCookie(),
            "Google Client ID": clientId
        };

        if (adwordsData) {
            userData["Google Click ID"] = adwordsData.gclid;
            userData["Google Click Timestamp"] = adwordsData.gclidTimestamp;
        }
        if (marketingParams) {
            userData.utm_campaign = marketingParams.utm_campaign;
            userData.utm_content = marketingParams.utm_content;
            userData.utm_medium = marketingParams.utm_medium;
            userData.utm_source = marketingParams.utm_source;
            userData.utm_term = marketingParams.utm_term;
        }

        if (lead?.PlanName__c) {
            userData["Plan"] = lead.PlanName__c;
            userData["Offer"] = lead.OfferName__c;
            if (lead.FirstName) {
                userData.name = `${lead.FirstName} ${lead.LastName}`;
                userData["First name"] = lead.FirstName;
                userData["Surname"] = lead.LastName;
            }
        } else if (primaryLead?.FirstName) {
            userData.name = `${primaryLead.FirstName} ${primaryLead.LastName}`;
            userData["First name"] = primaryLead.FirstName;
            userData["Surname"] = primaryLead.LastName;
            userData["Plan"] = account.PlanName__c;
            userData["Offer"] = account.OfferName__c;
        }

        this.logger.debug("Logging in", status, userData);

        this.intercom.logIn(userData);
    }

}
