<h3 class="title">{{'REGISTRATION.SIGNUP_STEP.COUNTRY.TITLE' | translate}}</h3>

<form [formGroup]="form" novalidate autocomplete="off">

  <soldo-field-layout
    [control]="form.controls['searchTerm']"
    fieldName="searchTerm"
    cypressSelector="Signup_TextInput_SearchTerm"
    label="{{ 'REGISTRATION.SIGNUP_STEP.COUNTRY.COUNTRY_LABEL' | translate }}">

    <div input class="searchTerm-input">
      <input type="text" name="searchTerm" formControlName="searchTerm" class="form-control"
        placeholder="{{ 'REGISTRATION.SIGNUP_STEP.COUNTRY.COUNTRY_PLACEHOLDER' | translate }}" />
      <i class="md-icon search-icon">search</i>
    </div>
    <soldo-field-error errorMessage errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value:
      form.get('searchTerm').errors?.maxlength?.requiredLength} }}</soldo-field-error>
  </soldo-field-layout>

  <ng-container *ngIf="!searchMode">

    <ng-container *ngIf="showSelectedSeparately">
      <h6>{{ 'REGISTRATION.SIGNUP_STEP.COUNTRY.SELECTED_LIST_TITLE' | translate }}</h6>
      <div class="selectedCountry">
        <div class="country-flag-icon">
          <i class="{{'round-flag-icon round-flag-' + selectedCountry.alpha_2.toLowerCase()}}"
          [style]="'background: url(/assets/round-flag-icons/' + selectedCountry.alpha_2.toLowerCase() + '.svg);'"></i>
        </div>
        <div class="country-name">{{ selectedCountry.name | translate}}</div>
      </div>
    </ng-container>

    <h6>{{ 'REGISTRATION.SIGNUP_STEP.COUNTRY.POPULAR_LIST_TITLE' | translate }}</h6>
  </ng-container>

  <soldo-field-layout
    [control]="form.controls['country']"
    fieldName="country"
    [required]="true"
    cypressSelector="Signup_RadioItems_CountryOfIncorporation"
    label="">

    <div input class="radio-main">
      <div class="radio-list radio-list-top">
        <div *ngFor="let country of visibleCountries; let i = index" class="radio-item"
          [attr.data-cy]="'Signup_RadioItem_CountryOfIncorporation_'+country.alpha_3">
          <input type="radio" formControlName="country" [id]="country.alpha_3" [value]="country.alpha_3">
          <label [for]="country.alpha_3">
            <div class="country-flag-icon">
              <i class="{{'round-flag-icon round-flag-' + country.alpha_2.toLowerCase()}}"
                [style]="'background: url(/assets/round-flag-icons/' + country.alpha_2.toLowerCase() + '.svg);'"></i>
            </div>
            <div class="country-name">{{ country.name | translate}}</div>
          </label>
        </div>
      </div>
    </div>

    <soldo-field-error errorMessage errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>

  </soldo-field-layout>

</form>

<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading"
      data-cy="Signup_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.SIGNUP_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.SIGNUP_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
