<h2>{{ "REGISTRATION.ACCOUNT.TITLE" | translate }}</h2>
<h5>{{ "REGISTRATION.ACCOUNT.DESCRIPTION" | translate }}</h5>

<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
  <h4><strong>{{ "REGISTRATION.ACCOUNT.ACCOUNT_ADMIN" | translate }}</strong></h4>
  <div data-cy="Admin_Checkbox_DirectorIsAdminArray"  class="layout-section">
    <div formGroupName="director">
      <div data-cy="Admin_Checkbox_DirectorIsAdmin">
        <input id="contact-director" type="checkbox" formControlName="isAdmin" #checkDirector (change)="directorChecked(checkDirector.checked)">
          <label for="contact-director">{{ form.get('director').get('firstName').value }} {{
            form.get('director').get('lastName').value }}</label>
      </div>
    </div>
    <div formArrayName="contacts" class="form-input">
      <div *ngFor="let contact of getContactsFormArray().controls; let i=index" [formGroupName]="i">
        <div *ngIf="getContactsFormArray().at(i).get('contactExternalId').value" data-cy="Admin_Checkbox_DirectorIsAdmin">
          <input id="contact-{{i}}" type="checkbox" formControlName="isAdmin" #checkbox (change)="onCheck(checkbox.checked, i)">
          <label for="contact-{{i}}">{{
              getContactsFormArray().at(i).get('firstName').value }} {{
              getContactsFormArray().at(i).get('lastName').value }}</label>
        </div>
      </div>
      <div class="validation-errors-container">
        <div class="field-validation-error caption" *ngIf="form.touched && (admins == 0)">
          {{ 'ONE_ADMIN' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="admins > 0" data-cy="Admin_Contact_DirectorAdminGroupArray">
    <div formGroupName="director">
      <div *ngIf="form.get('director').get('isAdmin').value">
        <h5>{{ form.get('director').get('firstName').value }} {{ form.get('director').get('lastName').value }}</h5>
        <person-form-group [group]="form.get('director')" [readonly]="true"></person-form-group>
      </div>
    </div>

    <div formArrayName="contacts">
      <div *ngFor="let contact of getContactsFormArray().controls; let i=index" [formGroupName]="i" data-cy="Admin_Contact_DirectorAdminGroup">
        <div *ngIf="!getContactsFormArray().at(i).get('isNew').value && getContactsFormArray().at(i).get('isAdmin').value">
          <h5>{{ getContactsFormArray().at(i).get('firstName').value }} {{ getContactsFormArray().at(i).get('lastName').value }}</h5>
          <person-form-group [group]="getContactsFormArray().at(i)"></person-form-group>
        </div>
      </div>
    </div>
  </div>
  <div formArrayName="contacts" data-cy="Admin_Contact_NewAdminGroupArray">
    <div *ngFor="let contact of getContactsFormArray().controls; let i = index" [formGroupName]="i">
      <div
        *ngIf="getContactsFormArray().at(i).get('isNew').value"
        data-cy="Admin_Contact_NewAdminGroup"
        class="layout-section">

        <div class="contact-section-header">
          <h5><strong>{{ "REGISTRATION.ACCOUNT.ADDITIONAL_ADMIN" | translate }} {{ i+1 }}</strong></h5>
          <button type="button" class="link no-padding" data-cy="Admin_Contact_RemoveNewAdmin"
            (click)="removeContact(i)">
            <span>{{ 'REMOVE' | translate }}</span>
          </button>
        </div>

        <person-form-group [group]="getContactsFormArray().at(i)" data-cy="Admin_Contact_NewAdmin"></person-form-group>
      </div>
    </div>
  </div>

  <button type="button" (click)="addContact()" *ngIf="canCreateMoreAdmins()" data-cy="Admin_Button_AddAdmin">
    <span>{{ "REGISTRATION.ACCOUNT.ADD" | translate }}</span><typeface-icon>add_circle</typeface-icon>
  </button>

  <div class="form-page-CTA-row">
      <button type="button" (click)="goToPrevious()"
        data-cy="Director_Button_StepBackButton">
        {{ 'PREVIOUS' | translate }}
      </button>
      <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}"
        data-cy="Director_Button_StepSubmitButton">{{ 'NEXT' | translate }}</button>
  </div>
</form>
<save-and-continue-later stepName="Account Admin"></save-and-continue-later>
