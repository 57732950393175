import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { regexValidators } from '../../../customValidators';
import { AmplitudeService } from '../../../services/amplitude.service';
import { HereApiAddressSearchResult } from '../../../services/here-apiaddress-lookup.service';
import { ICounty, ITown, ItalianTownsService } from '../../../services/italian-towns.service';
import { AbstractAddressFieldsComponent } from '../abstract-address-fields.component';
import { AddressFormControlMetadata } from '../address2.component';

@Component({
    selector: 'address-fields-ita',
    templateUrl: './address-fields-ita.component.html',
    styleUrls: ['./address-fields-ita.component.scss']
})
export class AddressFieldsItaComponent extends AbstractAddressFieldsComponent implements OnInit {

    @Input("detectManualEdits")
    public detectManualEdits: boolean = true;

    @Input("manualChangeEmitter")
    public manualChangeEmitter: EventEmitter<string>;

    @Input('formGroup')
    public addressForm: UntypedFormGroup;

    @Input('simplified')
    public simplified: boolean = false;

    @Input('fieldMetadata')
    public fieldMetadata: AddressFormControlMetadata;

    @Input('searchResultEmitter')
    public searchResultEmitter: EventEmitter<HereApiAddressSearchResult>;

    @Input()
    readonly = false;

    italianCountiesTypeahead = new EventEmitter<string>();
    italianCountySelected = new EventEmitter<string>();
    italianTownsTypeahead = new EventEmitter<string>();

    public italianCounties: ICounty[];
    public italianCountiesFiltered: ICounty[];
    public italianTowns: ITown[];
    public italianTownsInCounty: ITown[] = [];
    public italianTownsFiltered: ITown[] = [];

    private localSubscriptions: Subscription[] = [];

    constructor(
        protected amplitudeService: AmplitudeService,
        protected router: Router,
        private italianTownService: ItalianTownsService
    ) {
        super(amplitudeService, router);

        this.disableManualEditDetection();

        this.italianTownService.data.then(data => {
            this.italianCounties = data.counties;
            this.italianCountiesFiltered = data.counties;
            this.italianTowns = data.towns;
            this.italianTownsInCounty = data.towns;
            this.italianTownsFiltered = data.towns;

            this.localSubscriptions.push(this.italianCountiesTypeahead.subscribe(term => {
                this.filterItalianCounties(term);
            }));
            this.localSubscriptions.push(this.italianTownsTypeahead.subscribe(term => {
                this.filterItalianTowns(term);
            }));
            this.localSubscriptions.push(this.italianCountySelected.subscribe(countyCode => {
                this.initItalianTownSelector(countyCode);
            }));

        });
    }

    ngOnInit() {
        this.localSubscriptions.push(this.searchResultEmitter.subscribe((selectedAddress: HereApiAddressSearchResult) => {
            // Do we have a matching county?
            const itCounty = this.italianCounties.find(c =>
                c.principal.toLowerCase().startsWith(selectedAddress.address.county.toLowerCase()));

            const countyValue = itCounty ? itCounty.code : "";
            let town = "";

            if (itCounty) {
                // Do we have a matching city?
                const itCity = this.italianTowns.find(t =>
                    t.town.toLowerCase().startsWith(selectedAddress.address.city.toLowerCase()));
                if (itCity) { // Complete address, show label
                    town = itCity.town;
                } else { // Go in manual mode!
                    this.logger.debug(`Can't match city: '${  selectedAddress.address.city  }'`);
                }
            } else { // Go in manual mode!
                this.logger.debug(`Can't match county: '${  selectedAddress.address.county  }'`);
            }

            this.addressForm.get("city").enable({ emitEvent: false });
            this.addressForm.patchValue({
                idCheckAddressLookupAddressCode: selectedAddress.id,
                postcode: selectedAddress.address.postalCode,
                street: selectedAddress.address.street,
                buildingNumber: selectedAddress.address.houseNumber,
                line1: [selectedAddress.address.street, selectedAddress.address.houseNumber].join(" "),
                line2: "",
                line3: "",
                state: countyValue,
                city: town
            }, { emitEvent: false });

            this.trackSearchResultSelected();
        }));

        setTimeout(() => {
            if (this.simplified) {
                this.localSubscriptions.push(this.configField("line1", "RegisteredAddressLine1__c", true, false, true, []));
                this.localSubscriptions.push(this.configField("line2", "RegisteredAddressLine2__c", false, false, true, []));
            } else {
                this.localSubscriptions.push(this.configField("street", "RegisteredAddressStreet__c", true, true, true, []));
                this.localSubscriptions.push(this.configField("buildingNumber", "RegisteredAddressHouseNumber__c", true, true, true, []));
            }
            this.localSubscriptions.push(this.configField("state", "RegisteredAddressState__c", true, true, true, []));
            this.localSubscriptions.push(this.configField("city", "RegisteredAddressCity__c", true, true, true, []));
            this.localSubscriptions.push(this.configField("postcode", "RegisteredAddressPostalCode__c", true, true, true, [
                Validators.pattern(regexValidators.postcodesByCountry.ITA)
            ]));

            // Init Italian variant
            const countyControl = this.addressForm.get("state");

            this.italianTownService.data.then(() => {
                this.localSubscriptions.push(countyControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
                    this.handleItalianCountyChange(value);
                }));
                this.handleItalianCountyChange(countyControl.value);
                this.enableManualEditDetection();
            });
        }, 0);
    }

    ngOnDestroy() {
        for (const s of this.localSubscriptions) {
            s.unsubscribe();
        }
    }

    invalidateSearchResult(fieldName: string) {
        const formValue = this.addressForm.value;
        if (formValue.idCheckAddressLookupAddressCode) {
            this.addressForm.patchValue({
                idCheckAddressLookupAddressCode: ""
            });

            this.trackSeachInvalidated(fieldName);
        }
    }

    private filterItalianCounties(term: string = "") {
        let searchTerm = term;
        if (searchTerm) {
            searchTerm = term.toUpperCase();
        }

        this.italianCountiesFiltered = this.italianCounties.filter(state => {
            return state.principal.toUpperCase().startsWith(searchTerm);
        }).concat(this.italianCounties.filter(state => {
            return state.principal.toUpperCase().search(searchTerm) > 0;
        }));
    }

    private initItalianTownSelector(countyCode: string) {
        if (!countyCode) {
            return;
        }
        const townControl = this.addressForm.get("city");
        const previousTown = townControl.value;
        let clearTownSelector = true;

        this.italianTownsInCounty = this.italianTowns.filter(town => {
            if (town.town === previousTown && town.county === countyCode) {
                clearTownSelector = false;
            }
            return town.county === countyCode;
        });
        if (clearTownSelector) {
            townControl.setValue(null, { onlySelf: true });
        }
        this.italianTownsFiltered = this.italianTownsInCounty;
    }

    private filterItalianTowns(term: string = "") {
        const searchTerm = term?.toUpperCase();
        this.italianTownsFiltered = this.italianTownsInCounty.filter(town => {
            return town.town.toUpperCase().startsWith(searchTerm);
        }).concat(this.italianTownsInCounty.filter(town => {
            return town.town.toUpperCase().search(searchTerm) > 0;
        }));
    }

    private handleItalianCountyChange(countyCode): void {
        this.disableManualEditDetection();
        if (countyCode) {
            this.addressForm.get("city").enable({ onlySelf: true, emitEvent: false });
            this.italianCountySelected.next(countyCode);
        } else {
            this.addressForm.get("city").disable({ onlySelf: true, emitEvent: false });
            this.italianCountySelected.next(null);
        }
        this.enableManualEditDetection();
    }

    getStateLabel(): string {
        return this.italianCountiesFiltered?.find(c => c.code === this.addressForm.get('state').value).principal;
    }
}
