import { Component, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { Account, ACCOUNT_DEFAULT_FIELD_LENGTH, DEFAULT_STRING_MAX_LENGTH } from '@shared/models/registration.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { CompanySubStepOutboundAction, CompanySubStepType } from '../../company-step.model';
import { CustomValidators } from '@shared/customValidators';

@Component({
    selector: 'substep-company-description',
    templateUrl: './company-description.component.html',
    styleUrls: ['./company-description.component.scss']
})
export class CompanyDescriptionComponent extends CompanySubStepType {
    subStepName: string = "Company Description";

    loading: boolean = true;

    form: UntypedFormGroup;
    isSoleTrader: boolean;
    countryOfIncorporation: string;
    isEligibleForStartupOffer: boolean;
    companyDescriptionMaxlength: number;

    constructor(
        private readonly registrationDataService: RegistrationDataService,
        private readonly fb: UntypedFormBuilder,
        private readonly amplitudeService: AmplitudeService,
        private readonly gtmService: GoogleTagManagerService) {

        super();
        this.controlActionEventEmitter = new EventEmitter<CompanySubStepOutboundAction>();
    }

    init(d: Partial<Account>): void {
        this.isEligibleForStartupOffer = this.registrationDataService.isEligibleForStartupOffer();
        this.isSoleTrader = d.SoleTrader__c;
        this.countryOfIncorporation = d.RegisteredAddressCountryISO__c;
        this.companyDescriptionMaxlength = ACCOUNT_DEFAULT_FIELD_LENGTH["CustomerBusinessDescription__c"] || DEFAULT_STRING_MAX_LENGTH;
        this.form = this.fb.group({
            description: [d.CustomerBusinessDescription__c, [
                Validators.required,
                Validators.minLength(50),
                CustomValidators.getAccountMaxlengthValidator("CustomerBusinessDescription__c")
            ]]
        });
        this.loading = false;
    }

    isValid(): boolean {
        this.form.markAllAsTouched();
        return this.form.valid;
    }

    getData(): Partial<Account> {
        const formValues = this.form.value;
        return {
            CustomerBusinessDescription__c: formValues.description
        };
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        }
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

}
