import { Component, OnInit } from '@angular/core';
import { RegistrationDataService, SoldoProductMarket } from '@shared/services/registrationData.service';
import { TranslateService } from '@ngx-translate/core';
import { MentionMeService } from '@shared/components/mention-me/mention-me.service';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
    market: SoldoProductMarket = "ROE";
    approved: "PENDING" | "APPROVED" = "PENDING";
    mentionmeOrderTagURL: string = "";

    constructor(
        private registrationDataService: RegistrationDataService,
        private translateService: TranslateService,
        private mentionMeService: MentionMeService) {
    }

    ngOnInit() {
        const productMarket = this.registrationDataService.getProductMarket();

        // @TODO This needs a refactoring this page can't work on the
        // cartesian product of product, market and language.
        this.market = "ROE";
        switch (productMarket) {
            case "ITA":
            case "GBR":
            case "IRL":
                this.market = productMarket;
        }

        this.approved = this.registrationDataService.approvalStatus();

        const primaryLead = this.registrationDataService.getContacts().find(c => c.ContactIsPrimaryLead__c);
        const account = this.registrationDataService.getAccount();

        var orderTagHttpParams = new HttpParams()
            .set("email", primaryLead.Email)
            .set("order_number", account.SoldoRegistrationExternalID__c)
            .set("order_total", "0.00")
            .set("order_currency", "EUR");

        this.mentionMeService.loadOrderTag(orderTagHttpParams);
    }

    getSlug(): string {
        return [this.market, this.approved].join(".");
    }

    onClickCTA(): void {
        window.location.assign(this.translateService.instant('SUCCESS.' + this.getSlug() + '.BUTTON_LINK'));
    }
}

