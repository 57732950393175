import { Component } from '@angular/core';

@Component({
    selector: 'buttons-test',
    templateUrl: './buttons-test.component.html',
    styleUrls: ['../test-new-style.component.scss','./buttons-test.component.scss']
})

export class ButtonsTestComponent {

    loading: boolean = true;

}
