import { Component, OnInit, Input, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AddressFormControlMetadata } from '../address2.component';
import { AbstractAddressFieldsComponent } from '../abstract-address-fields.component';
import { Router } from '@angular/router';
import { UkAddress } from '../../../services/uk-address-lookup.service';
import { AmplitudeService } from '../../../services/amplitude.service';
import { regexValidators } from '../../../customValidators';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'address-fields-gbr',
    templateUrl: './address-fields-gbr.component.html',
    styleUrls: ['./address-fields-gbr.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddressFieldsGbrComponent extends AbstractAddressFieldsComponent implements OnInit, OnDestroy {

    @Input('formGroup')
    public addressForm: UntypedFormGroup;

    @Input('simplified')
    public simplified: boolean = false;

    @Input('fieldMetadata')
    public fieldMetadata: AddressFormControlMetadata;

    @Input('searchResultEmitter')
    public searchResultEmitter: EventEmitter<UkAddress>;

    @Input()
    readonly = false;

    constructor(
        protected amplitudeService: AmplitudeService,
        protected router: Router,
        protected logger: NGXLogger) {

        super(amplitudeService, router, logger);
    }

    ngOnInit() {

        this.localSubscriptions.push(this.searchResultEmitter.subscribe((event: UkAddress) => {
            this.temporarilyDisableSearchInvalidationCheck();
            this.addressForm.patchValue({
                idCheckAddressLookupAddressCode: event.idCheckAddressLookupAddressCode,
                line1: event.line1,
                line2: event.line2,
                line3: event.line3,
                city: event.city,
                buildingName: event.buildingName,
                buildingNumber: event.buildingNumber,
                poBoxNumber: event.poBoxNumber,
                street: event.street,
                secondaryStreet: event.secondaryStreet,
                subBuilding: event.subBuilding,
                postcode: event.postalCode
            });
            this.trackSearchResultSelected();
        }));

        if (this.simplified) {
            this.configField("line1", "RegisteredAddressLine1__c", true, true, []);
            this.configField("line2", "RegisteredAddressLine2__c", false, true, []);
        } else {
            this.configField("street", "RegisteredAddressStreet__c", true, true, [], true);
            this.configField("buildingName", "RegisteredAddressHouseName__c", false, true, [], true);
            this.configField("buildingNumber", "RegisteredAddressHouseNumber__c", false, true, [], true);
            this.configField("subBuilding", "RegisteredAddressSubBuilding__c", false, true, [], true);
            this.addressForm.setValidators([this.nameOrNumberValidator]);
        }
        this.configField("city", "RegisteredAddressCity__c", true, true, [], true);
        this.configField("postcode", "RegisteredAddressPostalCode__c", true, true, [
            Validators.pattern(regexValidators.postcodesByCountry.GBR)
        ], true);
        this.initCompleted();
    }

    private readonly nameOrNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const addressFormGroup = control as UntypedFormGroup;
        if (addressFormGroup.get("buildingName").value || addressFormGroup.get("buildingNumber").value) {
            return null;
        }
        return {
            "nameornumber": true
        };
    };

    isDisplayingSearchResult(): boolean {
        return this.addressForm.get("idCheckAddressLookupAddressCode").value !== "";
    }

    invalidateSearchResult(fieldName: string) {
        const searchResultIdControl = this.addressForm.get("idCheckAddressLookupAddressCode");
        if (searchResultIdControl.value) {
            this.addressForm.patchValue({
                idCheckAddressLookupAddressCode: "",
                line1: "",
                line2: "",
                line3: "",
                poBoxNumber: "",
                secondaryStreet: ""
            }, { emitEvent: false });
            this.trackSeachInvalidated(fieldName);
        }
    }

    ngOnDestroy(): void {
        this.clearAllSubscriptions();
    }

}
