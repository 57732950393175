import { NgxLoggerLevel } from 'ngx-logger';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    env: 'dev',
    logLevel: NgxLoggerLevel.DEBUG,
    production: false,
    localUrlEndpoint: '/assets/data',
    aggregatorUrlEndpoint: 'https://dev.soldocloud.net/platform-fe/services/aggregate',
    googleTagManagerID: 'GTM-MK77NL8',
    enableDynatraceTracking: false,
    amplitudeProjectId: '1ee9250effb8aae3bf339c05439a4433',
    intercomAppId: 'b4179nby', // test app id
    intercomTestMode: true,
    invalidPlanOfferRedirectUrl: {
        ITA: 'https://stagtools.soldo.com/?lang=it',
        GBR: 'https://stagtools.soldo.com/?lang=gb',
        fallback: 'https://stagtools.soldo.com/?lang=eu'
    },
    embedHooYuVerificationSession: true,
    websiteHomePage: {
        it: 'https://www.soldo.com/it-it/',
        en: 'https://www.soldo.com/en-gb/',
        fallback: 'https://www.soldo.com/en-eu/'
    },
    experimental: {},
    signupWithXeroLoginURLRoot: "https://login.xero.com/identity/connect/authorize",
    signupWithXeroLoginRedirectURI: "https://registration-dev.soldo.com/",
    signupWithXeroLoginAuthorizationScopes: "openid profile email accounting.contacts accounting.settings",
    signupWithXeroLeadSource: "XeroAppStore",
    apiCallsMockConfig: {
        GLOBAL: false,
        forceMitekAsIdvProvider: false,
        forceApplicantOffIdv: false
    }
};
