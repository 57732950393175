import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplitudeService } from '../../shared/services/amplitude.service';
import { RegistrationDataService } from '../../shared/services/registrationData.service';

@Component({
    selector: 'app-idv-link-page',
    templateUrl: './idv-link-page.component.html',
    styleUrls: ['./idv-link-page.component.scss']
})
export class IdvLinkPageComponent implements OnInit {

    workflowRunId: string;
    loading: boolean = true;
    contactFirstName: string;

    constructor(
        private readonly router: Router,
        private readonly registrationDataService: RegistrationDataService,
        private readonly amplitudeService: AmplitudeService) { }

    ngOnInit(): void {
        const contacts = this.registrationDataService.getContacts();

        if (contacts.length == 0) {
            this.loading = false;
            this.workflowRunId = this.registrationDataService.getWorkflowRunId();
        } else {
            this.contactFirstName = contacts[0]?.FirstName;
            this.registrationDataService.loadIDVData()
                .then(idvData => {
                    this.workflowRunId = idvData.workflowRunId;
                    this.loading = false;
                }, () => {
                    this.router.navigate(["onfido"]); // back to the standard email flow
                });
        }
    }

    callToActionOnClick(): void {
        this.loading = true;
        this.amplitudeService.trackIDVLinkClicked("onfido", () => {
            this.registrationDataService.setWorkflowRunId(this.workflowRunId);
            this.router.navigate([`static/onfido`]);
        });
    }

}
