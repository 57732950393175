<div class="idv-page-content">
  <header>
    <h2>{{ "REGISTRATION.ONFIDOLANDING."+translationVariant+".TITLE" | translate }}</h2>
    <img alt="Identity verification" src="../../../assets/images/doc_upload.png">
  </header>

  <p [innerHTML]="'REGISTRATION.ONFIDOLANDING.'+translationVariant+'.PARAGRAPH_1' | translate"></p>

  <p [innerHTML]="'REGISTRATION.ONFIDOLANDING.'+translationVariant+'.PARAGRAPH_2' | translate : { maxdays: maxdays }"></p>

  <p *ngIf="translationVariant != 'SINGLE'" [innerHTML]="'REGISTRATION.ONFIDOLANDING.'+translationVariant+'.PARAGRAPH_3' | translate : { maxdays: maxdays }"></p>

  <p [innerHTML]="'REGISTRATION.ONFIDOLANDING.'+translationVariant+'.PARAGRAPH_4' | translate"></p>

  <p [innerHTML]="'REGISTRATION.ONFIDOLANDING.'+translationVariant+'.SUBTITLE' | translate "></p>

  <button data-cy="IDV_Button_VerifyYourIdentityButton" class="primary" (click)="callToActionOnClick()">{{ "REGISTRATION.ONFIDOLANDING."+translationVariant+".CTA" | translate }}</button>

  <div class="idv-logo">
    <img src="../../../assets/images/onfido-logo.svg" alt="Onfido logo">
  </div>
</div>
