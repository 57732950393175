<div class="container">

  <div class="main">
    <h2 class="v2">
      {{ 'REGISTRATION.IDV_COMPLETE.TITLE' | translate }}
    </h2>

    <div class="image">

      <img src="../../../assets/images/email-ok.png" alt="mail image"/>

    </div>

    <p>
      {{ 'REGISTRATION.IDV_COMPLETE.PARAGRAPH' | translate }}
    </p>

    <button class="btn btn-primary" (click)="continue()">{{ 'REGISTRATION.IDV_COMPLETE.CTA' | translate }}</button>
  </div>

</div>


