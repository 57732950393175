<div [formGroup]="addressForm">

  <!-- SIMPLIFIED LINE 1, 2 ADDRESS -->
  <ng-container *ngIf="simplified">

    <!-- LINE 1 -->
    <soldo-field-layout
      cypressSelector="Address_TextInput_Line1"
      [control]="addressForm.controls['line1']"
      fieldName="line1" label="REGISTRATION.ADDRESS.LINE_1_LABEL">

      <input input type="text"
        placeholder="{{ 'REGISTRATION.ADDRESS.LINE_1_PLACEHOLDER' | translate }}"
        formControlName="line1" name="line1"
        [readOnly]="readonly">

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.get('line1').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <!-- LINE 2 -->
    <soldo-field-layout
      cypressSelector="Address_TextInput_Line2"
      [control]="addressForm.controls['line2']"
      fieldName="line2" label="REGISTRATION.ADDRESS.LINE_2_LABEL">

      <input input type="text"
        placeholder="{{ 'REGISTRATION.ADDRESS.LINE_2_PLACEHOLDER' | translate }}"
        formControlName="line2" name="line2"
        [readOnly]="readonly">

      <ng-container errorMessage>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.get('line2').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

  </ng-container>

  <!-- NOT SIMPLIFIED STREET AND BUILDING NUMBER -->
  <ng-container class="form-input-row" *ngIf="!simplified">

    <soldo-field-layout
      cypressSelector="Address_TextInput_Street"
      [control]="addressForm.controls['street']"
      fieldName="street" label="REGISTRATION.ADDRESS.STREET_LABEL">

      <input input type="text"
        placeholder="{{ 'REGISTRATION.ADDRESS.STREET_PLACEHOLDER' | translate }}"
        formControlName="street" name="street"
        [readOnly]="readonly">

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.get('street').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout
      cypressSelector="Address_TextInput_BuildingNumber"
      [control]="addressForm.controls['buildingNumber']" fieldName="buildingNumber"
      label="REGISTRATION.ADDRESS.BUILDING_NUMBER_LABEL">

      <input input type="text"
        placeholder="{{ 'REGISTRATION.ADDRESS.BUILDING_NUMBER_PLACEHOLDER' | translate }}"
        formControlName="buildingNumber" name="buildingNumber"
        [readOnly]="readonly">

        <ng-container errorMessage>
          <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
          <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.get('buildingNumber').errors?.maxlength?.requiredLength} }}</soldo-field-error>
        </ng-container>
    </soldo-field-layout>

  </ng-container>

  <!-- ITALIAN PROVINCE SELECTOR -->
  <soldo-field-layout
    cypressSelector="Address_NgSelect_Province"
    [control]="addressForm.controls['state']"
    fieldName="state" label="REGISTRATION.ADDRESS.STATE_LABEL">

    <ng-select input *ngIf="!readonly" formControlName="state" name="state"
      type="search"
      dropdownPosition="bottom"
      [items]="italianCountiesFiltered"
      bindLabel="principal"
      bindValue="code"
      [typeahead]="italianCountiesTypeahead"
      [clearable]="false"
      notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
      placeholder="{{ 'REGISTRATION.ADDRESS.STATE_PLACEHOLDER' | translate }}">
    </ng-select>

    <div *ngIf="readonly" input>
      <input type="hidden" formControlName="state" name="state">
      <input  type="text" name="state_label" [value]="getStateLabel()" readonly>
    </div>

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <!-- ITALIAN TOWN SELECTOR -->
  <soldo-field-layout
    cypressSelector="Address_NgSelect_City"
    [control]="addressForm.controls['city']"
    fieldName="city" label="REGISTRATION.ADDRESS.CITY_LABEL">

    <ng-select input *ngIf="!readonly" formControlName="city" name="city"
      type="search"
      dropdownPosition="bottom"
      [items]="italianTownsFiltered"
      bindLabel="town"
      bindValue="town"
      [typeahead]="italianTownsTypeahead"
      [clearable]="false"
      notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
      placeholder="{{ 'REGISTRATION.ADDRESS.CITY_PLACEHOLDER' | translate }}">
    </ng-select>

    <ng-container *ngIf="readonly" input>
      <input type="hidden" formControlName="city" name="city" #city>
      <input  type="text" name="city_label" [value]="city.value" readonly>
    </ng-container>

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <!-- POSTCODE ROW -->
  <soldo-field-layout
    cypressSelector="Address_TextInput_Postcode"
    [control]="addressForm.controls['postcode']"
    fieldName="postcode" label="REGISTRATION.ADDRESS.POSTCODE_LABEL">

    <input input type="text"
      placeholder="{{ 'REGISTRATION.ADDRESS.POSTCODE_PLACEHOLDER' | translate }}"
      formControlName="postcode" name="postcode"
      [readOnly]="readonly">

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.get('postcode').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      <soldo-field-error errorName="pattern">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <input *ngIf="!simplified" type="hidden" formControlName="line1">
  <input *ngIf="!simplified" type="hidden" formControlName="line2">
  <input *ngIf="simplified" type="hidden" formControlName="street">
  <input type="hidden" formControlName="buildingName">
  <input *ngIf="simplified" type="hidden" formControlName="buildingNumber">
  <input type="hidden" formControlName="subBuilding">

</div>
