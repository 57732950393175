import { Component, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { Account } from '@shared/models/registration.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { CompanySubStepOutboundAction, CompanySubStepType } from '../../company-step.model';
import { SELECT_INVALID_SOLDO_FIELD, scrollToFirstResult } from '../../../../shared/formHelpers';

@Component({
    selector: 'substep-company-trading-address',
    templateUrl: './company-trading-address.component.html',
    styleUrls: ['./company-trading-address.component.scss']
})
export class CompanyTradingAddressComponent extends CompanySubStepType {
    subStepName: string = "Company Trading Address";

    loading: boolean = true;

    form: UntypedFormGroup;
    companyCountry: string;
    tradingAddressFormGroup: UntypedFormGroup;
    registeredCountry: string;
    registeredAddress: {
        line1: string;
        line2: string;
        city: string;
        postalCode: string;
        state: string;
    };
    tradingCountry: string = null;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly amplitudeService: AmplitudeService,
        private readonly gtmService: GoogleTagManagerService) {

        super();
        this.controlActionEventEmitter = new EventEmitter<CompanySubStepOutboundAction>();
    }

    init(d: Partial<Account>): void {
        this.registeredCountry = d.RegisteredAddressCountryISO__c;
        this.form = this.fb.group({
            tradingAddressDifferent: [Boolean(this._isTradingAddressDifferent(d))]
        });

        this.tradingCountry = d.TradingAddressCountryISO__c || d.RegisteredAddressCountryISO__c;

        this.tradingAddressFormGroup = this.fb.group({
            idCheckAddressLookupAddressCode: [d.TradingAddressServiceRefCode__c ? d.TradingAddressServiceRefCode__c : null],
            line1: [d.TradingAddressLine1__c],
            line2: [d.TradingAddressLine2__c],
            line3: [d.TradingAddressLine3__c ? d.TradingAddressLine3__c : ''],
            city: [d.TradingAddressCity__c],
            postcode: [d.TradingAddressPostalCode__c],
            country: [this.tradingCountry],
            state: [d.TradingAddressState__c],
            buildingName: [d.TradingAddressHouseName__c],
            buildingNumber: [d.TradingAddressHouseNumber__c],
            poBoxNumber: [d.TradingAddressPoBox__c],
            street: [d.TradingAddressStreet__c],
            secondaryStreet: [d.TradingAddressSecondaryStreet__c],
            subBuilding: [d.TradingAddressSubBuilding__c]
        });
        this.toggleAddress(this._isTradingAddressDifferent(d));

        this.form.get('tradingAddressDifferent').valueChanges.subscribe((value) => {
            this.toggleAddress(value);
        });

        this.tradingAddressFormGroup.get("country").valueChanges.subscribe((country) => {
            this.tradingCountry = country;
        });

        this.registeredAddress = {
            line1: d.RegisteredAddressLine1__c,
            line2: null,
            city: d.RegisteredAddressCity__c,
            postalCode: d.RegisteredAddressPostalCode__c,
            state: d.RegisteredAddressState__c
        };

        if (d.RegisteredAddressLine2__c){
            if (d.RegisteredAddressLine2__c.trim() !== ""){
                this.registeredAddress.line2 = d.RegisteredAddressLine2__c;
            }
        }

        this.loading = false;
    }

    toggleAddress(on: boolean) {
        if (on) {
            this.form.addControl("tradingAddress", this.tradingAddressFormGroup);
        } else {
            this.form.removeControl("tradingAddress");
        }
    }

    isValid(): boolean {

        if (this.form.value.tradingAddressDifferent) {
            this.form.markAllAsTouched();
        }

        return this.form.valid;

    }

    getData(): Partial<Account> {

        const formValues = this.form.value;
        const tradingAddress = formValues.tradingAddressDifferent ? formValues.tradingAddress : null;

        if (tradingAddress) {
            return {
                "TradingAddressLine1__c": tradingAddress.line1,
                "TradingAddressLine2__c": tradingAddress.line2,
                "TradingAddressLine3__c": tradingAddress.line3 ? tradingAddress.line3 : '',
                "TradingAddressCity__c": tradingAddress.city,
                "TradingAddressPostalCode__c": tradingAddress.postcode,
                "TradingAddressCountryISO__c": tradingAddress.country,
                "TradingAddressState__c": tradingAddress.state,
                "TradingAddressServiceRefCode__c": tradingAddress.idCheckAddressLookupAddressCode,
                "TradingAddressHouseName__c": tradingAddress.buildingName,
                "TradingAddressHouseNumber__c": tradingAddress.buildingNumber,
                "TradingAddressPoBox__c": tradingAddress.poBoxNumber,
                "TradingAddressStreet__c": tradingAddress.street,
                "TradingAddressSecondaryStreet__c": tradingAddress.secondaryStreet,
                "TradingAddressSubBuilding__c": tradingAddress.subBuilding
            };
        } else {
            return {
                "TradingAddressLine1__c": null,
                "TradingAddressLine2__c": null,
                "TradingAddressLine3__c": null,
                "TradingAddressCity__c": null,
                "TradingAddressPostalCode__c": null,
                "TradingAddressCountryISO__c": null,
                "TradingAddressState__c": null,
                "TradingAddressServiceRefCode__c": null,
                "TradingAddressHouseName__c": null,
                "TradingAddressHouseNumber__c": null,
                "TradingAddressPoBox__c": null,
                "TradingAddressStreet__c": null,
                "TradingAddressSecondaryStreet__c": null,
                "TradingAddressSubBuilding__c": null
            };
        }

    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        } else {
            scrollToFirstResult(SELECT_INVALID_SOLDO_FIELD);
        }
    }

    _isTradingAddressDifferent(data: Partial<Account>): boolean {

        if (data.TradingAddressCity__c && data.TradingAddressCity__c.length > 0 &&
            ((data.RegisteredAddressCountryISO__c != data.TradingAddressCountryISO__c) ||
                (data.RegisteredAddressCity__c != data.TradingAddressCity__c) ||
                (data.RegisteredAddressState__c != data.TradingAddressState__c) ||
                (data.RegisteredAddressLine1__c != data.TradingAddressLine1__c) ||
                (data.RegisteredAddressLine2__c != data.TradingAddressLine2__c) ||
                (data.RegisteredAddressPostalCode__c != data.TradingAddressPostalCode__c))
        ) {
            return true;
        }
        return false;
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

}

