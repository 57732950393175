<div class="idv-landing">
  <header>
    <h2 class="v2">{{ "REGISTRATION.ONFIDOLANDING."+translationVariant+".TITLE" | translate }}</h2>
    <div class="icon">
      <img src="../../../assets/images/doc_upload.png">
    </div>
  </header>

  <p [innerHTML]="'REGISTRATION.ONFIDOLANDING.'+translationVariant+'.PARAGRAPH_1' | translate"></p>

  <p [innerHTML]="'REGISTRATION.ONFIDOLANDING.'+translationVariant+'.PARAGRAPH_2' | translate : {maxdays: maxdays}"></p>

  <p *ngIf="translationVariant != 'SINGLE'" [innerHTML]="'REGISTRATION.ONFIDOLANDING.'+translationVariant+'.PARAGRAPH_3' | translate : {maxdays: maxdays }"></p>

  <p [innerHTML]="'REGISTRATION.ONFIDOLANDING.'+translationVariant+'.PARAGRAPH_4' | translate"></p>

  <p [innerHTML]="'REGISTRATION.ONFIDOLANDING.'+translationVariant+'.SUBTITLE' | translate "></p>
  <div class="close-button">
    <button class="btn btn-primary" (click)="callToActionOnClick()">{{ "REGISTRATION.ONFIDOLANDING."+translationVariant+".CTA" | translate }}</button>
  </div>

  <p class="onfido-logo">
    <img src="../../../assets/images/onfido-logo.svg">
  </p>
</div>
