import { Component, OnDestroy, OnInit } from '@angular/core';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { NGXLogger } from 'ngx-logger';
import { SdkHandle, SdkResponse, init as initOnfidoSDK } from 'onfido-sdk-ui';
import { OnfidoUIConfig } from './onfido.config';
import { Router } from '@angular/router';
import { AmplitudeService } from '../../shared/services/amplitude.service';


@Component({
    selector: 'stt-onfido',
    templateUrl: './onfido.component.html',
    styleUrls: ['./onfido.component.scss']
})
export class OnfidoComponent implements OnInit, OnDestroy {

    private onfidoSdkHandle: SdkHandle;
    private userAnalyticsEventListener: (event) => void;

    constructor(protected logger: NGXLogger,
        private registrationDataService: RegistrationDataService,
        private amplitudeService: AmplitudeService,
        private router: Router) {

            this.userAnalyticsEventListener = (event) => {
                this.logger.debug("This is an ONFIDO event", event);
                this.amplitudeService.trackOnfidoWebSdkTraversal(event.detail?.eventName, event.detail?.isCrossDevice);
            };
        }


    ngOnInit(): void {
        window.addEventListener('userAnalyticsEvent', this.userAnalyticsEventListener);

        const workflowRunId = this.registrationDataService.getWorkflowRunId();
        this.registrationDataService.generateOnfidoSdkToken(workflowRunId)
            .then((sdkToken:string) => {
                this.onfidoSdkHandle = this.initOnfido(sdkToken, workflowRunId);
            });
    }

    ngOnDestroy(): void {
        this.onfidoSdkHandle.safeTearDown();
        window.removeEventListener('userAnalyticsEvent', this.userAnalyticsEventListener);
    }

    private initOnfido(sdkToken: string, workflowRunId: string): SdkHandle {
        return initOnfidoSDK({
            token: sdkToken,
            workflowRunId: workflowRunId,
            containerId: "onfidoContainer",
            useModal: false,
            customUI: OnfidoUIConfig,
            onComplete: (data: SdkResponse) => {
                this.logger.debug("IDV process completed", data);
                this.amplitudeService.trackOnfidoWebSdkIdvComplete();
            },
            onUserExit: (data) => {
                this.logger.debug("User quit the flow", data);
                this.amplitudeService.trackOnfidoWebSdkIdvUserExit();
            },
            onModalRequestClose: () => {
                this.logger.debug("User closed the modal");
            },
            onError: (error) => {
                this.logger.debug("An error occurred", error);
                this.amplitudeService.trackOnfidoWebSdkError(error.type);
            }
        });
    }
}
