import { Component, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../app-config.module';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { RegistrationDataService } from '../../shared/services/registrationData.service';
import { AmplitudeService } from '../../shared/services/amplitude.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CountryService, ICountryMetadata, MAIN_MARKETS_COUNTRY_ISO } from '../../shared/services/country.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { FiscalCodeService } from '../../shared/services/fiscal-code.service';
import moment from 'moment-mini';
import { CustomValidators } from '../../shared/customValidators';
import { IDVSessionDetails, UserIDVDetails } from '../../shared/models/registration.model';

interface AddressFormGroup {
    country: FormControl<string | null>;
    line1: FormControl<string | null>;
    line2: FormControl<string | null>;
    line3: FormControl<string | null>;
    city: FormControl<string | null>;
    buildingName: FormControl<string | null>;
    buildingNumber: FormControl<string | null>;
    poBoxNumber: FormControl<string | null>;
    street: FormControl<string | null>;
    secondaryStreet: FormControl<string | null>;
    subBuilding: FormControl<string | null>;
    postcode: FormControl<string | null>;
    state: FormControl<string | null>;
    idCheckAddressLookupAddressCode: FormControl<string | null>;
}

interface InternalUserDetailsFields {
    firstName: FormControl<string>;
    middleName: FormControl<string>;
    lastName: FormControl<string>;
    nationality: FormControl<string | null>;
    address: FormGroup<AddressFormGroup>;
    dateOfBirth: FormControl<string | null>;
    codiceFiscaleChoice?: FormControl<boolean | null>;
    codiceFiscale?: FormControl<string | null>;
}

@Component({
    selector: 'internal-user-idv',
    templateUrl: './internal-user-idv.component.html',
    styleUrl: './internal-user-idv.component.scss'
})
export class InternalUserIdvComponent {
    loading: boolean = true;
    completeCountryList: ICountryMetadata[];
    tokenVerified: boolean = false;
    sessionData: IDVSessionDetails = null;
    idvUserForm: FormGroup<InternalUserDetailsFields> = null;
    skipForm: boolean = false;

    constructor(
        @Inject(APP_CONFIG) private readonly config: AppConfig,
        private readonly logger: NGXLogger,
        private readonly router: Router,
        private readonly fb: FormBuilder,
        private readonly registrationDataService: RegistrationDataService,
        private readonly countryService: CountryService,
        private readonly fiscalCodeService: FiscalCodeService,
        private readonly amplitudeService: AmplitudeService) { }

    ngOnInit(): void {
        this.countryService.countries.subscribe(allCountries => {
            const preferredCountries = allCountries.filter(c => MAIN_MARKETS_COUNTRY_ISO.includes(c.alpha_3));
            preferredCountries.sort(this.countryService.sortFunction);

            const otherCountries = allCountries.filter(c => c.icc && !MAIN_MARKETS_COUNTRY_ISO.includes(c.alpha_3));
            otherCountries.sort(this.countryService.sortFunction);

            this.completeCountryList = preferredCountries.concat(otherCountries);
        });

        const idvData = this.registrationDataService.getIdvData();
        this.logger.debug("idvData", idvData);

        // USE TOKEN TO GET STATUS AND DATA
        this.registrationDataService.getIdvSession(idvData.personExternalId).then((sessionDetails) => {
            this.sessionData = sessionDetails;

            switch(this.sessionData.identityVerificationStatus) {
                case "SENT":
                case "COMPLETED":
                case "ABANDONED":
                    this.skipForm = true;
                    break;
                default:
                    this.initDetailsForm();
            }

            if(this.sessionData.workflowRunId) {
                idvData.sessionId = this.sessionData.workflowRunId;
                this.registrationDataService.setIdvData(idvData);
            }

            this.tokenVerified = true;
            this.loading = false;
        }, (err) => {
            if(err.status == 401) {
                this.tokenVerified = false;
                this.loading = false;
            }
        });
    }

    initDetailsForm() {
        this.idvUserForm = this.fb.group({
            firstName: [this.sessionData?.firstName],
            middleName: [this.sessionData?.middleName],
            lastName: [this.sessionData?.lastName],
            dateOfBirth: [null as string, [Validators.required, CustomValidators.validDate(18, 120)]],
            nationality: [null as string, Validators.required],
            address: this.fb.group({
                country: [null as string, [Validators.required]],
                idCheckAddressLookupAddressCode: [null],
                line1: [null],
                line2: [null],
                line3: [null],
                city: [null],
                state: [null],
                postcode: [null],
                buildingName: [null],
                buildingNumber: [null],
                poBoxNumber: [null],
                street: [null],
                secondaryStreet: [null],
                subBuilding: [null]
            })
        });

        this.idvUserForm.controls.dateOfBirth.valueChanges.subscribe(() => {
            if(this.idvUserForm.controls.codiceFiscale) {
                this.idvUserForm.controls.codiceFiscale.updateValueAndValidity();
            }
        });

        this.idvUserForm.valueChanges.pipe(distinctUntilChanged()).subscribe((values) => {
            this.logger.debug("Form valueChanges", values);
            if ([values.nationality, values.address.country].includes("ITA")) {
                this.removeCodiceFiscaleChoiceField();
                this.addCodiceFiscaleField();
            } else if (values.codiceFiscaleChoice) {
                this.addCodiceFiscaleField();
            } else {
                this.removeCodiceFiscale();
                this.addCodiceFiscaleChoiceField();
            }
        });
    }

    addCodiceFiscaleField() {
        if (!this.idvUserForm.get("codiceFiscale")) {
            this.logger.debug("adding codiceFiscale");
            this.idvUserForm.addControl("codiceFiscale", new FormControl<string | null>(null, [
                Validators.required,
                this.fiscalCodeService.validator,
                this.codiceFiscaletNameValidatorFactory(`${this.sessionData.firstName} ${this.sessionData.middleName || ''}`, this.sessionData.lastName),
                this.codiceFiscaleDateValidatorFactory(this.idvUserForm.controls.dateOfBirth)
            ]), { emitEvent: false });
        }
    }

    removeCodiceFiscale() {
        if (this.idvUserForm.controls.codiceFiscale) {
            this.logger.debug("removing codiceFiscale");
            this.idvUserForm.removeControl("codiceFiscale", { emitEvent: false });
        }
    }

    addCodiceFiscaleChoiceField() {
        if (!this.idvUserForm.controls.codiceFiscaleChoice) {
            this.logger.debug("adding codiceFiscaleChoice");
            this.idvUserForm.addControl("codiceFiscaleChoice", new FormControl<boolean | null>(false, [Validators.required]), { emitEvent: false });
        }
    }

    removeCodiceFiscaleChoiceField() {
        if (this.idvUserForm.controls.codiceFiscaleChoice) {
            this.logger.debug("removing codiceFiscaleChoice");
            this.idvUserForm.removeControl("codiceFiscaleChoice", { emitEvent: false });
        }
    }

    codiceFiscaletNameValidatorFactory(firstName: string, lastName: string): ValidatorFn {
        return (codiceFiscaleControl: AbstractControl): ValidationErrors => {
            if (codiceFiscaleControl.value && codiceFiscaleControl.valid) {
                const checkAgainstFirstName = this.fiscalCodeService.validateAgainstFirstName(codiceFiscaleControl.value, firstName);
                const checkAgainstLastName = this.fiscalCodeService.validateAgainstLastName(codiceFiscaleControl.value, lastName);
                if (!checkAgainstFirstName || !checkAgainstLastName) {
                    return {
                        "nameCfMismatch": true
                    };
                }
            }
            return null;
        };
    }

    codiceFiscaleDateValidatorFactory(dateControl: FormControl<string>): ValidatorFn {
        return (codiceFiscaleControl: AbstractControl): ValidationErrors => {
            if (codiceFiscaleControl.value && codiceFiscaleControl.valid && dateControl.value && dateControl.valid) {
                const cfDate = moment(FiscalCodeService.retrieveBirthDate(codiceFiscaleControl.value)).format('DD/MM/YYYY');
                if (cfDate != dateControl.value) {
                    return {
                        "dateCfMismatch": true
                    };
                }
            }
            return null;
        };
    }

    submitDetailsForm() {
        this.idvUserForm.markAllAsTouched();
        this.idvUserForm.updateValueAndValidity();
        this.logger.debug("The form is ", this.idvUserForm.valid ? "VALID" : "INVALID");
        if (this.idvUserForm.valid) {
            const formValue = this.idvUserForm.value;

            let codiceFiscaleBirthPlace: { state: string, town: string } = null;
            let codiceFiscaleDateOfBirth: string = null;
            const codiceFiscale = formValue.codiceFiscale;
            if (codiceFiscale) {
                codiceFiscaleBirthPlace = this.fiscalCodeService.retrieveBirthPlace(codiceFiscale);
                codiceFiscaleDateOfBirth = moment(FiscalCodeService.retrieveBirthDate(codiceFiscale)).format('DD/MM/YYYY');
            }

            const idvData: UserIDVDetails = {
                nationality: formValue.nationality,
                fiscalCode: formValue.codiceFiscale,
                birthDate: moment(codiceFiscaleDateOfBirth || formValue.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                birthTown: codiceFiscaleBirthPlace?.town || null,
                birthProvince: codiceFiscaleBirthPlace?.state || null,
                address: {
                    countryISO: formValue.address.country,
                    line1: formValue.address.line1,
                    line2: formValue.address.line2,
                    line3: formValue.address.line3,
                    street: formValue.address.street,
                    secondaryStreet: formValue.address.secondaryStreet,
                    houseName: formValue.address.buildingName,
                    houseNumber: formValue.address.buildingNumber,
                    subBuilding: formValue.address.subBuilding,
                    province: formValue.address.state,
                    postalCode: formValue.address.postcode,
                    postalBox: formValue.address.poBoxNumber,
                    searchRefCode: formValue.address.idCheckAddressLookupAddressCode,
                    city: formValue.address.city
                }
            }
            this.loading = true;
            this.registrationDataService.postUserIdvData(this.sessionData.userKYCRequestId, idvData).then((workflowRunId) => {
                this.logger.debug("INT USER IDV RETURNS ", workflowRunId);
                if(workflowRunId) {
                    const idvData = this.registrationDataService.getIdvData();
                    this.registrationDataService.setIdvData({
                        ...idvData,
                        sessionId: workflowRunId
                    });
                    this.proceedToIDV();
                }
            });
        }
        return false;
    }

    proceedToIDV() {
        this.router.navigate(['static/verify-with-onfido']);
    }
}
