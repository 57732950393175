
<div class="save-and-continue-feature">
  <button class="btn gray-text" (click)="$event.preventDefault();saveAndContinueLater()">
    {{ "REGISTRATION.SAVE_AND_CONTINUE.SAVE_AND_CONTINUE_CTA" | translate }}
  </button>
</div>

<div *ngIf="overlayVisible" class="save-and-continue-overlay" (click)="hideDialog()">
  <div class="save-and-continue-dialog" (click)="$event.stopPropagation()">

    <h4>{{ "REGISTRATION.SAVE_AND_CONTINUE.TITLE" | translate }}</h4>

    <p>{{ "REGISTRATION.SAVE_AND_CONTINUE.PARAGRAPH_1" | translate : {recipient: emailRecipient} }}</p>

    <p>{{ "REGISTRATION.SAVE_AND_CONTINUE.PARAGRAPH_2" | translate }}</p>

    <div class="button-row">
      <button class="confirm" (click)="hideDialog()">{{ "REGISTRATION.SAVE_AND_CONTINUE.DISMISS" | translate }}</button>
      <button class="close" (click)="hideDialog()"><i class="md-icon">close</i></button>
    </div>
  </div>
</div>
