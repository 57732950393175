<h3 class="title">{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.CASH.TITLE' | translate}}</h3>

<form [formGroup]="form" novalidate>
  <soldo-field-layout *ngIf="form.get('cashWithdrawal')"
    [control]="form.controls['cashWithdrawal']"
    fieldName="cashWithdrawal"
    [required]="true"
    cypressSelector="Expected_RadioButton_CashWithdrawal"
    label="REGISTRATION.EXPECTED_ACTIVITY_STEP.CASH.LABEL">

    <div input class="radio-main">
      <div class="radio-list radio-list-top">
        <div *ngFor="let i of [0,1,2,3]" class="radio-item"
          [attr.data-cy]="'Expected_RadioButton_CashWithdrawal_'+cashWithdrawalOptions[i].value">
          <input type="radio" formControlName="cashWithdrawal" [id]="'OPT'+i" [value]="cashWithdrawalOptions[i].value"
            (change)="toggleDescription(cashWithdrawalOptions[i].value >= descriptionThreshold)">
          <label [for]="'OPT'+i">{{cashWithdrawalOptions[i].label | translate}}</label>
        </div>
      </div>
    </div>

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.CASH.RANGE_REQUIRED' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <div class="description_container" *ngIf="showDescription">
    <soldo-field-layout
    [required]="true"
    [control]="form.controls['description']"
    label="REGISTRATION.EXPECTED_ACTIVITY_STEP.CASH.DESCRIPTION_LABEL"
    cypressSelector="Expected_RadioButton_CashWithdrawal_Description"
    fieldName="description"
    >
    <textarea input
        name="description"
        formControlName="description"
        type="text" class="form-control"
        rows="5"
      ></textarea>
      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>
  </div>

</form>


<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading" data-cy="Expected_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Expected_Button_NextStepButton">
      {{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
