import { Component, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { Account, ACCOUNT_DEFAULT_FIELD_LENGTH, DEFAULT_COMPANY_NAME } from '@shared/models/registration.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { LeadStartData } from '@shared/services/lead-data-mediator.service';
import { SignupCustomOutboundActions, SignupSubStepOutboundAction, SignupSubStepType } from '../../signup-step.model';

@Component({
    selector: 'substep-company-name',
    templateUrl: './company-name.component.html',
    styleUrls: ['./company-name.component.scss']
})
export class CompanyNameSubStepComponent extends SignupSubStepType {
    subStepName: string = "Company Name";

    loading: boolean = true;

    form: UntypedFormGroup;
    soleTrader: boolean = false;
    country: string;

    constructor(
        private fb: UntypedFormBuilder,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService) {

        super();
        this.controlActionEventEmitter = new EventEmitter<SignupSubStepOutboundAction>();
    }

    isValid(): boolean {
        this.form.get("companyName").markAsTouched();
        return this.form.valid;
    }

    init(d: Partial<LeadStartData>): void {
        this.country = d.companyCountry;
        if (d.companyType === 'LiberoProfessionista'
            || d.companyType === 'DittaIndividuale'
            || d.companyType === 'SoleTrader') {

            this.soleTrader = true;
        } else {
            this.soleTrader = false;
        }

        this.form = this.fb.group({
            "companyName": [d.companyName == DEFAULT_COMPANY_NAME ? "" : d.companyName, [Validators.required, this.accountMaxlengthValidator("Name")]]
        });

        this.loading = false;
    }

    getData(): Partial<LeadStartData> {
        const formValues = this.form.value;
        return {
            companyName: formValues["companyName"]
        };
    }

    isCompanySearchAvailable() {
        return !this.soleTrader && ["GBR", "ITA"].indexOf(this.country) >= 0;
    }

    switchToCompanySearch(): void {
        this.loading = true;
        this.controlActionEventEmitter.emit({
            action: SignupCustomOutboundActions.SWITCH_TO_COMPANY_SEARCH,
            data: this.getData()
        });
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if(this.isValid()) {
            this.loading = true;
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        }
    }
    accountMaxlengthValidator(field: keyof Account): ValidatorFn {
        return Validators.maxLength(ACCOUNT_DEFAULT_FIELD_LENGTH[field] || 255);
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

}
