import { Component, OnInit } from '@angular/core';
import {
    AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { AbstractFormComponent } from "@shared/components/abstract-form.component";
import { CustomValidators } from "@shared/customValidators";
import { Account, Contact, ContactOperation } from "@shared/models/registration.model";
import { AmplitudeService } from '@shared/services/amplitude.service';
import { CountryService } from '@shared/services/country.service';
import { FiscalCodeService } from "@shared/services/fiscal-code.service";
import { RegistrationDataService } from "@shared/services/registrationData.service";
import { StatusService } from '@shared/services/status.service';
import { StepService } from "@shared/services/steps.service";
import moment from 'moment-mini';
import { NGXLogger } from 'ngx-logger';
import { ContactsCustomValidators } from '../helpers/contacts-custom-validators';
import { SELECT_INVALID_SOLDO_FIELD, scrollToFirstResult } from '../../shared/formHelpers';

interface ContactAddressForm {
    idCheckAddressLookupAddressCode: string,
    line1: string,
    line2: string,
    line3: string,
    city: string,
    state: string,
    postcode: string,
    country: string,
    buildingName: string,
    buildingNumber: string,
    poBoxNumber: string,
    street: string,
    secondaryStreet: string,
    subBuilding: string
}

interface ContactForm {
    personFormId: string,
    contactExternalId: string,
    firstName: string,
    lastName: string,
    fiscalCode: string,
    birthPlace: string,
    birthState: string,
    gender: string,
    citizenship: string,
    dob: string,
    phone: {
        prefix: string,
        number: string
    },
    email: string,
    address: ContactAddressForm,
    isDirector: boolean,
    isAdmin: boolean,
    isFirstCardHolder: boolean,
    isNew: boolean,
    isPrimaryLead: boolean,
    isFatcaSubject: boolean
}

@Component({
    selector: 'account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent extends AbstractFormComponent implements OnInit {

    private readonly maxExtraAdmins = 3;
    private directorContact: Contact;
    private contacts: Contact[];
    form: UntypedFormGroup = null;
    admins = 0;
    loading = false;
    extraAdmins = 0;

    constructor(
        protected logger: NGXLogger,
        private router: Router,
        private fb: UntypedFormBuilder,
        private registrationDataService: RegistrationDataService,
        private fiscalCodeService: FiscalCodeService,
        private stepService: StepService,
        private countryService: CountryService,
        private amplitudeService: AmplitudeService,
        private statusService: StatusService
    ) {
        super();
    }

    ngOnInit() {
        const allContacts = this.registrationDataService.getContacts();
        this.directorContact = allContacts.find(c => c.ContactIsDirector__c);

        if (this.directorContact.ContactIsAdmin__c) {
            this.admins++;
        }
        this.contacts = allContacts.filter(c => c.ContactIsAdmin__c && !c.ContactIsDirector__c);
        const contactsFormGroup: UntypedFormGroup[] = this.contacts.map((contact => {
            this.admins++;
            this.extraAdmins++;
            return this.initContactForm(contact);
        }));

        this.form = this.fb.group({
            director: this.initContactForm(this.directorContact),
            contacts: this.fb.array(contactsFormGroup)
        });
    }

    getContactsFormArray(): UntypedFormArray {
        return <UntypedFormArray>this.form.get('contacts');
    }

    canCreateMoreAdmins() {
        return this.extraAdmins < this.maxExtraAdmins;
    }

    directorChecked(checked: boolean) {
        this.directorContact.ContactIsAdmin__c = checked;
        this.form.setControl('director', this.initContactForm(this.directorContact));
        this.updateAdminCount(checked);
    }

    onCheck(checked: boolean, i: number) {
        const contacts = this.getContactsFormArray();
        const contact = this.contacts[i];
        contact.ContactIsAdmin__c = checked;
        contacts.setControl(i, this.initContactForm(contact));
        this.updateAdminCount(checked);
    }

    addContact() {
        this.getContactsFormArray().push(this.initContactForm());
        this.extraAdmins++;
        this.admins++;
    }

    removeContact(i: number) {
        this.getContactsFormArray().removeAt(i);
        this.extraAdmins--;
        this.admins--;
        this.getContactsFormArray().controls.forEach((c) => {
            c.get('email').updateValueAndValidity();
            c.get('phone').updateValueAndValidity();
            c.get('fiscalCode')?.updateValueAndValidity();
        });
    }

    onSubmit(): boolean {
        this.countAdmins();
        this.form.markAllAsTouched();
        const directorForm = <UntypedFormGroup>this.form.get('director');
        this.form.updateValueAndValidity();
        ContactsCustomValidators.duplicateEmailValidator(this.getContactsFormArray());
        ContactsCustomValidators.duplicatePhoneValidator(this.getContactsFormArray(), directorForm);
        ContactsCustomValidators.duplicateCfValidator(this.getContactsFormArray(), directorForm);
        if (this.form.valid && this.admins > 0) {
            this.loading = true;
            this.saveForm();

            const postSubmitFsSubmissionStatus = this.statusService.getFsSubmissionStatusValidIncrement("AdminCreated");

            this.registrationDataService.saveAccount({
                FsApplicationSubmissionStatus__c: postSubmitFsSubmissionStatus
            });

            this.registrationDataService.submitContacts().then(() => {
                this.registrationDataService.submitAccount().then(() => {
                    this.statusService.setStatusIncrementAfterSubmission(postSubmitFsSubmissionStatus);
                    this.stepService.navigateToNextStep();
                });
            }, (err) => this.logger.trace("FATAL: ", err));
            return true;
        } else {
            this.loading = false;
            this.logger.error("form not valid: ", this.form.value);
            this.printAllErrors();

            if (this.admins === 0) {
                scrollToFirstResult("form");
            }
            setTimeout(() => {
                scrollToFirstResult(SELECT_INVALID_SOLDO_FIELD, 'fiscal-code:has(input.ng-invalid)');
            }, 0);

            return false;
        }
    }

    goToPrevious() {
        this.stepService.navigateToPreviousStep();
    }

    private initContactForm(contact?: Contact): UntypedFormGroup {
        let contactFormGroup: UntypedFormGroup = null;
        const account: Account = this.registrationDataService.getAccount();
        const icc = this.countryService.getMetadata(account.RegisteredAddressCountryISO__c).icc;

        if (contact) { // existing contact
            let phoneWithoutIcc = contact.MobilePhone;
            if (phoneWithoutIcc && phoneWithoutIcc.indexOf(contact.ContactPhoneICC__c) > -1) {
                phoneWithoutIcc = phoneWithoutIcc.substring(contact.ContactPhoneICC__c.length);
            }

            contactFormGroup = this.fb.group({
                personFormId: [contact.SoldoContactExternalID__c, []],
                contactExternalId: [contact.SoldoContactExternalID__c],
                firstName: [contact.FirstName, [Validators.required, this.validateFirstNameAgainstCF, this.contactMaxlengthValidator("FirstName")]],
                lastName: [contact.LastName, [Validators.required, this.validateLastNameAgainstCF, this.contactMaxlengthValidator("LastName")]],
                citizenship: [contact.ContactCitizenshipCountryISO__c, Validators.required],
                dob: [contact.Birthdate ? moment(contact.Birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY') : contact.Birthdate, [Validators.required, CustomValidators.validDate(18, 120)]],
                phone: this.fb.group({
                    prefix: [contact.ContactPhoneICC__c ? contact.ContactPhoneICC__c : icc],
                    number: [phoneWithoutIcc]
                }),
                email: [contact.Email, []],
                address: this.fb.group({
                    idCheckAddressLookupAddressCode: [contact.ResidentialAddressServiceRefCode__c ? contact.ResidentialAddressServiceRefCode__c : null],
                    line1: [contact.ResidentialAddressLine1__c],
                    line2: [contact.ResidentialAddressLine2__c],
                    line3: [contact.ResidentialAddressLine3__c],
                    city: [contact.ResidentialAddressCity__c],
                    postcode: [contact.ResidentialAddressPostalCode__c],
                    country: [contact.ResidentialAddressCountryISO__c, Validators.required],
                    state: [contact.ResidentialAddressState__c],
                    buildingName: [contact.ResidentialAddressHouseName__c],
                    buildingNumber: [contact.ResidentialAddressHouseNumber__c],
                    poBoxNumber: [contact.ResidentialAddressPoBox__c],
                    street: [contact.ResidentialAddressStreet__c],
                    secondaryStreet: [contact.ResidentialAddressSecondaryStreet__c],
                    subBuilding: [contact.ResidentialAddressSubBuilding__c]
                }),
                isDirector: [contact.ContactIsDirector__c],
                isAdmin: [contact.ContactIsAdmin__c],
                isPrimaryLead: [contact.ContactIsPrimaryLead__c],
                isFirstCardHolder: [contact.ContactIsCardHolder__c],
                isNew: [false]
            });

            const usCitizenOrResident = [contact.ContactCitizenshipCountryISO__c, contact.ResidentialAddressCountryISO__c].includes("USA");
            if(!usCitizenOrResident) {
                let isFatcaSubjectValue = null;
                switch(contact.ContactIsFatcaSubject__c) {
                    case "Yes":
                        isFatcaSubjectValue = true;
                        break;
                    case "No":
                        isFatcaSubjectValue = false;
                        break;
                }
                contactFormGroup.addControl('isFatcaSubject', new UntypedFormControl(isFatcaSubjectValue, Validators.required));
            }

            if (contact.ContactIsAdmin__c) {
                const emailControl = contactFormGroup.get('email');
                emailControl.setValidators([
                    Validators.required,
                    CustomValidators.validEmail(),
                    CustomValidators.cannotUseSoldoPecAddress(),
                    this.preventReuseOfDirectorsEmailValidatorFactory()
                ]);
                const phoneControl = contactFormGroup.get('phone');
                phoneControl.setValidators([CustomValidators.validPhone('prefix', 'number')]);
                phoneControl.get('prefix').setValidators([Validators.required]);
                phoneControl.get('number').setValidators([Validators.required]);
            }

            if (contact.ContactFiscalCode__c) {
                const cfField = new UntypedFormControl(contact.ContactFiscalCode__c, [Validators.required, this.fiscalCodeService.validator]);
                cfField["id"] = Date.now();
                contactFormGroup.addControl("fiscalCode", cfField);
                contactFormGroup.addControl("gender", new UntypedFormControl(contact.ContactGender__c, Validators.required));
                contactFormGroup.addControl("birthPlace", new UntypedFormControl(contact.ContactBirthPlace__c));
                contactFormGroup.addControl("birthState", new UntypedFormControl(contact.ContactBirthState__c));
            }

        } else {
            const addressFormGroup: UntypedFormGroup = this.fb.group({
                idCheckAddressLookupAddressCode: [null],
                line1: [null],
                line2: [null],
                line3: [null],
                city: [null],
                state: [null],
                postcode: [null],
                country: [account.RegisteredAddressCountryISO__c, Validators.required],
                buildingName: [null],
                buildingNumber: [null],
                poBoxNumber: [null],
                street: [null],
                secondaryStreet: [null],
                subBuilding: [null]
            });

            const adminFormId: string = [
                this.registrationDataService.getAccount().SoldoRegistrationExternalID__c,
                "admin",
                this.amplitudeService.getNextFormId()].join("-");

            contactFormGroup = this.fb.group({
                personFormId: [adminFormId, []],
                contactExternalId: [null],
                firstName: [null, [Validators.required, this.validateFirstNameAgainstCF, this.contactMaxlengthValidator("FirstName")]],
                lastName: [null, [Validators.required, this.validateLastNameAgainstCF, this.contactMaxlengthValidator("LastName")]],
                citizenship: [null, Validators.required],
                dob: [null, [Validators.required, CustomValidators.validDate(18, 120)]],
                phone: this.fb.group({
                    prefix: [icc, [Validators.required]],
                    number: [null, [Validators.required]]
                }, { validators: [CustomValidators.validPhone('prefix', 'number')] }),
                email: [null, [
                    Validators.required,
                    CustomValidators.validEmail(),
                    this.contactMaxlengthValidator("Email"),
                    CustomValidators.cannotUseSoldoPecAddress(),
                    this.preventReuseOfDirectorsEmailValidatorFactory()
                ]],
                isFatcaSubject: [null, Validators.required],
                address: addressFormGroup,
                isDirector: [false],
                isAdmin: [true],
                isFirstCardHolder: [false],
                isNew: [true]
            });
        }

        contactFormGroup.valueChanges.subscribe(() => {
            this.onContactFormGroupValueChange(contactFormGroup, contact);
        });

        // enable disable validators on email and phone
        contactFormGroup.get('isAdmin').valueChanges.subscribe((value) => {

            const emailControl = contactFormGroup.get('email');
            const phoneControl = contactFormGroup.get('phone');
            if (value) {
                emailControl.setValidators([
                    Validators.required,
                    CustomValidators.validEmail(),
                    this.contactMaxlengthValidator("Email"),
                    CustomValidators.cannotUseSoldoPecAddress(),
                    this.preventReuseOfDirectorsEmailValidatorFactory()
                ]);
                phoneControl.setValidators([CustomValidators.validPhone('prefix', 'number')]);
                phoneControl.get('prefix').setValidators([Validators.required]);
                phoneControl.get('number').setValidators([Validators.required]);
            } else {
                emailControl.clearValidators();
                phoneControl.clearValidators();
                phoneControl.get('prefix').clearValidators();
                phoneControl.get('number').clearValidators();

            }

            emailControl.updateValueAndValidity();
            phoneControl.get('prefix').updateValueAndValidity();
            phoneControl.get('number').updateValueAndValidity();
        });
        return contactFormGroup;
    }

    private preventReuseOfDirectorsEmailValidatorFactory(): ValidatorFn {
        return (control: AbstractControl) => {

            if (this.form.get('director').get('email') === control) {
                return null;
            }

            if (this.form.get('director').get('email')?.value == control.value) {
                return {
                    noDirectorsEmail: true
                };
            }

            return null;
        };
    }

    private onContactFormGroupValueChange(contactFormGroup: UntypedFormGroup, contact: Contact) {
        const account: Account = this.registrationDataService.getAccount();
        let cfField = contactFormGroup.get("fiscalCode");
        const countryOfResidence = contactFormGroup.get("address")?.get("country")?.value;
        const countryOfCitizenship = contactFormGroup.get("citizenship")?.value;

        if (!cfField && (account.RegisteredAddressCountryISO__c === "ITA" || countryOfResidence === "ITA")) {
            cfField = new UntypedFormControl(contact ? contact.ContactFiscalCode__c : null, [Validators.required, this.fiscalCodeService.validator]);
            cfField["id"] = Date.now();
            contactFormGroup.addControl("fiscalCode", cfField);
            contactFormGroup.addControl("gender", new UntypedFormControl(contact ? contact.ContactGender__c : null, Validators.required));
            contactFormGroup.addControl("birthPlace", new UntypedFormControl(contact ? contact.ContactBirthPlace__c : null));
            contactFormGroup.addControl("birthState", new UntypedFormControl(contact ? contact.ContactBirthState__c : null));
        }

        if (cfField && (account.RegisteredAddressCountryISO__c !== "ITA" && countryOfResidence !== "ITA")) {
            contactFormGroup.removeControl("fiscalCode");
            contactFormGroup.removeControl("gender");
            contactFormGroup.removeControl("birthPlace");
            contactFormGroup.removeControl("birthState");
        } else if (cfField?.value && cfField?.valid) {
            contactFormGroup.get("firstName").updateValueAndValidity({
                onlySelf: true,
                emitEvent: true
            });
            contactFormGroup.get("lastName").updateValueAndValidity({
                onlySelf: true,
                emitEvent: true
            });
        }

        if ([countryOfCitizenship, countryOfResidence].includes("USA") && contactFormGroup.get("isFatcaSubject")) {
            contactFormGroup.removeControl("isFatcaSubject");
        } else if (![countryOfCitizenship, countryOfResidence].includes("USA") && !contactFormGroup.get("isFatcaSubject")) {
            contactFormGroup.addControl("isFatcaSubject", new UntypedFormControl(null, Validators.required));
        }
    }

    private validateFirstNameAgainstCF = (firstNameControl: AbstractControl): ValidationErrors => {
        const cfField = firstNameControl.parent ? firstNameControl.parent.get("fiscalCode") : false;
        if (cfField && firstNameControl.value && cfField.value && cfField.valid) {
            if (!this.fiscalCodeService.validateAgainstFirstName(cfField.value, firstNameControl.value)) {
                return {
                    "firstNameCfMismatch": true
                };
            }
        }
        return null;
    };

    private validateLastNameAgainstCF = (lastNameControl: AbstractControl): ValidationErrors => {
        const cfField = lastNameControl.parent ? lastNameControl.parent.get("fiscalCode") : false;
        if (cfField && lastNameControl.value && cfField.value && cfField.valid) {
            if (!this.fiscalCodeService.validateAgainstLastName(cfField.value, lastNameControl.value)) {
                return {
                    "lastNameCfMismatch": true
                };
            }
        }
        return null;
    };

    private saveForm() {
        const formValue = this.form.value;
        const contactOperations: Array<ContactOperation> = [];
        contactOperations.push(this.assignContactOperation(formValue.director));
        for (const c of formValue.contacts) {
            contactOperations.push(this.assignContactOperation(c));
        }
        this.logger.debug("Contact Operations: ", contactOperations);
        this.registrationDataService.saveContacts(contactOperations);
    }

    private assignContactOperation(contact: ContactForm):ContactOperation {
        this.logger.debug("Preparing Contact Operation: ", contact);

        const isUsCitizenOrResident = [contact.address.country, contact.citizenship].includes("USA");

        if (contact.isAdmin && contact.address.idCheckAddressLookupAddressCode) {
            this.amplitudeService.trackAddressSearchResultSubmitted(
                this.router.url,
                contact.personFormId || "none",
                contact.address.country);
        }

        const isBeneficialOwner = this.registrationDataService.getContacts()
            .some(c => c.SoldoContactExternalID__c == contact.contactExternalId && c.ContactIsBeneficialOwner__c);

        return {
            "SoldoContactExternalID__c": contact.contactExternalId,
            "FirstName": contact.firstName,
            "LastName": contact.lastName,
            "ContactFiscalCode__c": contact.fiscalCode,
            "ContactBirthPlace__c": contact.birthPlace,
            "ContactBirthState__c": contact.birthState,
            "ContactGender__c": contact.gender,
            "ContactCitizenshipCountryISO__c": contact.citizenship,
            "Birthdate": moment(contact.dob, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            "ContactPhoneICC__c": contact.phone.number ? contact.phone.prefix : null,
            "MobilePhone": contact.phone.number ? contact.phone.prefix + contact.phone.number : null,
            "Email": contact.email,
            "ResidentialAddressLine1__c": contact.address.line1,
            "ResidentialAddressLine2__c": contact.address.line2,
            "ResidentialAddressLine3__c": contact.address.line3,
            "ResidentialAddressCity__c": contact.address.city,
            "ResidentialAddressPostalCode__c": contact.address.postcode,
            "ResidentialAddressCountryISO__c": contact.address.country,
            "ResidentialAddressState__c": contact.address.state,
            "ResidentialAddressServiceRefCode__c": contact.address.idCheckAddressLookupAddressCode,
            "ResidentialAddressHouseName__c": contact.address.buildingName,
            "ResidentialAddressHouseNumber__c": contact.address.buildingNumber,
            "ResidentialAddressPoBox__c": contact.address.poBoxNumber,
            "ResidentialAddressStreet__c": contact.address.street,
            "ResidentialAddressSecondaryStreet__c": contact.address.secondaryStreet,
            "ResidentialAddressSubBuilding__c": contact.address.subBuilding,
            "ContactIsDirector__c": contact.isDirector,
            "ContactIsAdmin__c": contact.isAdmin,
            "ContactIsCardHolder__c": Boolean(contact.isFirstCardHolder),
            "ContactIsPrimaryLead__c": contact.isPrimaryLead,
            "ContactIsFatcaSubject__c": contact.isFatcaSubject || isUsCitizenOrResident ? "Yes" : "No",
            "IsDeleted": !(contact.isDirector || contact.isAdmin || contact.isPrimaryLead || isBeneficialOwner)
        };
    }

    private countAdmins() {
        let admins: number = 0;
        const formValue = this.form.value;
        admins += formValue.director.isAdmin ? 1 : 0;
        for (const c of formValue.contacts) {
            admins += c.isAdmin ? 1 : 0;
        }

        this.admins = admins;
    }

    private updateAdminCount(checked: boolean) {
        this.admins += checked ? 1 : -1;
    }

}

