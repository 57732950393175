import { SecurityContext } from "@angular/core";

export const DEFAULT_LAST_NAME = "-";
export const DEFAULT_COMPANY_NAME = "anonymous";

export type EMoneyLicenseIssuer = "SFS-UK" | "SFS-IRL";

export interface Lead {
    Id: string;
    SoldoRegistrationExternalID__c: string;
    SoldoRegistrationStatus__c: string;
    Email: string;
    MobilePhone: string;
    MobilePhoneICC__c: string;
    FirstName: string;
    LastName: string;
    Company: string;
    RegisteredAddressCountryISO__c: string;
    CompanyType__c: string;
    CompanyTypeDescription__c: string;
    CompanyReasonForUse__c: string;
    LeadBrowserLanguage__c: string;
    // remove in place of OfferName
    SoldoPromotionCode__c: string;
    OfferName__c?: string;
    PlanName__c: string;
    CID__c?: string;
    PID__c?: string;
    LID__c?: string;
    OID__c?: string;
    SID__c?: string;
    NumberOfEmployees: number;
    LeadSource: string;
    SoleTrader__c: boolean;
    CustomerBusinessDescription__c: string;
    CustomerUseCaseDescription__c: string;
    AffiliateId__c?: string;
    AffiliateClickTime__c?: number;
    AffiliateMetadata__c?: string;
    DiscountCode__c?: string;
    ParentDomain__c?: string;
    GCLID__c?: string;
    GCLIDTimestamp__c?: number;
    GACID__c?: string;
    ExperimentId__c?: string;
    ExperimentVariantId__c?: string;
    UtmSource__c?: string;
    UtmMedium__c?: string;
    UtmCampaign__c?: string;
    UtmTerm__c?: string;
    UtmContent__c?: string;
    SegmentAnonymousId__c?: string;
    SegmentUserId__c?: string;
    MarketoCookieId__c?: string;
    FsApplicationSubmissionStatus__c: string;
    ActiveWebExperimentData__c?: string;
    CookiePreferences__c?: string;
    emailVerificationToken?: string;
    kybProviderId?: string;
    DriverId__c?: string;
    DestinationId__c?: string;
}

export enum AccountSalesChannelOption {
    ONLINE = "Online",
    RETAIL = "Retail",
    OTHER = "Other"
}

export enum AccountBusinessOperationType {
    DOMESTIC = "Domestic",
    INTERNATIONAL = "International"
}

export enum UBOProvisioningChoiceType {
    ONLINE = "ONLINE",
    EMAIL = "EMAIL",
    NONE = "NONE"
}

export interface Account {
    SoldoRegistrationExternalID__c: string;
    Id: string;
    CompanyRegistrationNumber__c: string;
    Name: string;
    CompanyTradingName__c: string;
    CompanyVATNumber__c: string;
    CompanyCodiceFiscale__c: string;
    Industry: string;
    CompanyType__c: string;
    CompanyTypeDescription__c: string;
    RegisteredAddressLine1__c: string;
    RegisteredAddressLine2__c: string;
    RegisteredAddressLine3__c?: string;
    RegisteredAddressPostalCode__c: string;
    RegisteredAddressCity__c: string;
    RegisteredAddressCountryISO__c: string;
    RegisteredAddressState__c: string;
    RegisteredAddressServiceRefCode__c?: string;
    RegisteredAddressHouseName__c?: string;
    RegisteredAddressHouseNumber__c?: string;
    RegisteredAddressPoBox__c?: string;
    RegisteredAddressStreet__c?: string;
    RegisteredAddressSecondaryStreet__c?: string;
    RegisteredAddressSubBuilding__c?: string;
    TradingAddressLine1__c?: string;
    TradingAddressLine2__c?: string;
    TradingAddressLine3__c?: string;
    TradingAddressPostalCode__c?: string;
    TradingAddressCity__c?: string;
    TradingAddressCountryISO__c?: string;
    TradingAddressState__c?: string;
    TradingAddressServiceRefCode__c?: string;
    TradingAddressHouseName__c?: string;
    TradingAddressHouseNumber__c?: string;
    TradingAddressPoBox__c?: string;
    TradingAddressStreet__c?: string;
    TradingAddressSecondaryStreet__c?: string;
    TradingAddressSubBuilding__c?: string;
    SoldoTermsAndConditions__c: boolean;
    // SoldoOtherPrivacyPermissions__c?: boolean;
    CompanyReasonForUse__c: string;
    // remove in place of OfferName
    SoldoPromotionCode__c: string;
    OfferName__c?: string;
    PlanName__c: string;
    DiscountCode__c?: string;
    SoleTrader__c: boolean;
    AutomaticProvisioning__c?: boolean;
    CID__c?: string;
    PID__c?: string;
    LID__c?: string;
    OID__c?: string;
    SID__c?: string;
    NumberOfEmployees: number;
    IdentityVerificationRequested__c: boolean;
    CustomerBusinessDescription__c: string;
    CustomerUseCaseDescription__c: string;
    AffiliateId__c?: string;
    AffiliateClickTime__c?: number;
    AffiliateMetadata__c?: string;
    ParentDomain__c?: string;
    GCLID__c?: string;
    GCLIDTimestamp__c?: number;
    EMoneyLicenseIssuer__c: EMoneyLicenseIssuer;
    GACID__c?: string;
    ExperimentId__c?: string;
    ExperimentVariantId__c?: string;
    UtmSource__c?: string;
    UtmMedium__c?: string;
    UtmCampaign__c?: string;
    UtmTerm__c?: string;
    UtmContent__c?: string;
    SegmentAnonymousId__c?: string;
    SegmentUserId__c?: string;
    FsApplicationSubmissionStatus__c: string;
    ActiveWebExperimentData__c?: string;
    CookiePreferences__c?: string;
    kybProviderId?: string;
    SalesChannels__c?: string;
    SalesChannelsDescription__c?: string;
    BusinessOperationType__c?: AccountBusinessOperationType;
    PrimaryInternationalCountryISO__c?: string;
    ExpectedUseCase__c?: string;
    ExpectedUseCaseDescription__c?: string;
    ExpectedNumberOfCards__c?: number;
    ExpectedMonthlySpend__c?: number;
    ExpectedCashWithdraw__c?: number;
    ExpectedCashWithdrawDescription__c?: string;
    ThirdPartyLoadSource__c?: number;
    ThirdPartyLoadSourceDescription__c?: string;
    UBOProvisioningChoice__c?: UBOProvisioningChoiceType;
}

export interface Contact {
    SoldoContactExternalID__c: string;
    FirstName: string;
    LastName: string;
    ContactFiscalCode__c?: string;
    ContactBirthPlace__c?: string;
    ContactBirthState__c?: string;
    ContactGender__c?: string;
    ContactBirthCountryISO__c: string;
    ContactCitizenshipCountryISO__c: string;
    Birthdate: string;
    ContactPhoneICC__c: string;
    MobilePhone: string;
    Email: string;
    ResidentialAddressLine1__c: string;
    ResidentialAddressLine2__c: string;
    ResidentialAddressLine3__c?: string;
    ResidentialAddressPostalCode__c: string;
    ResidentialAddressCity__c: string;
    ResidentialAddressCountryISO__c: string;
    ResidentialAddressState__c: string;
    ResidentialAddressServiceRefCode__c?: string;
    ResidentialAddressHouseName__c?: string;
    ResidentialAddressHouseNumber__c?: string;
    ResidentialAddressPoBox__c?: string;
    ResidentialAddressStreet__c?: string;
    ResidentialAddressSecondaryStreet__c?: string;
    ResidentialAddressSubBuilding__c?: string;
    ContactIsAdmin__c: boolean;
    ContactIsDirector__c: boolean;
    ContactIsLegalOwner__c: boolean;
    ContactIsPrimaryLead__c: boolean;
    ContactIsCardHolder__c?: boolean;
    ContactIsBeneficialOwner__c?: boolean;
    ContactIsFatcaSubject__c?: "Yes" | "No";
}

export interface ContactOperation extends Partial<Contact> {
    SoldoContactExternalID__c: string;
    IsDeleted?: boolean;
}

export enum ComplianceStatus {
    IN_PROGRESS = "LOADING",
    KYC_REFERR = "PENDING",
    DOC_REQUIRED = "DOC_REQUIRED",
    DOC_UPLOADED = "PENDING",
    KYB_PENDING = "KYB_PENDING",
    KYB_FAILED = "PENDING",
    KYB_ACCEPTED = "PENDING",
    COMPANY_PROVISIONED = "ACCEPTED",
    IDV_REQUIRED = "IDV_REQUIRED",
    MANUAL_REVIEW_REQUIRED = "MANUAL_REVIEW_REQUIRED"
}

export type ComplianceProofType = "IDENTITY" | "ADDRESS";
export type ComplianceProofDocumentType = "PASSPORT" | "ID_CARD" | "DRIVING_LICENSE" | "UTILITY" | "BANK_STATEMENT" | "COUNCIL_TAX" | "OTHER";

export interface DocUploadFile {
    label: string;
    fileName: string;
    preSignedURL: string;
    httpUploadStatus?: {
        status: number,
        percentageUploaded: number,
        error?: "too_large" | "wrong_type"
    };
    fileExtension?: string;
    contentType?: string;
}

export type DocUploadStatus = "NEW" | "ERROR" | "UPLOADING" | "UPLOADED" | "CONFIRMING" | "CONFIRMED";

export interface DocUploadMetadata {
    applicationId: number;
    proofId: number;
    proofType: ComplianceProofType;
    fileUploads: DocUploadFile[];
    personId: number;
    completion?: number;
    uploadStatus?: DocUploadStatus;
    open?: boolean;
    docType?: ComplianceProofDocumentType;
    filesRequired?: number;
}

export interface KYCDocRequest {
    name: string;
    businessOnboardingPersonId: number;
    requiredDocuments: DocUploadMetadata[];
    completion?: number;
}

export interface UploadPresignedURLResponse {
    filename: string;
    contentType: string;
    applicationId: number;
    proofId: number;
    proofType: ComplianceProofType;
    presignedUploadUrl: string;
    uploadStatus: "COMPLETED" | "PENDING";
}

export interface EKYCRequiredDocumentsListElement {
    applicationId: number;
    personId: number;
    proofId: number;
    proofType: ComplianceProofType;
    firstName: string;
    lastName: string;
    presignedUrl: {
        [key: string]: string
    };
    uploadStatus?: "PENDING" | "COMPLETED";
}

export interface EKYCDocumentUploadedData {
    applicationId: number;
    personId: number;
    proofId: number;
    proofType: ComplianceProofType;
    fileName: string;
    contentType?: string;
}

export interface CompleteProofResponse {
    applicationId: number;
    proofId: number;
    personId: number;
    proofType: ComplianceProofType;
    proofDocumentType: ComplianceProofDocumentType;
    attachmentMetadataList: UploadPresignedURLResponse[];
}

export interface RequestEmailVerificationCodeRequestDTO {
    email: string;
    firstName: string;
    lastName: string;
    lang: string;
    countryIso3: string;
}

export type EmailVerificationError = "GENERIC_ERROR" | "BLACKLISTED_DOMAIN";

export interface RequestEmailVerificationCodeResponseDTO {
    success: boolean;
    emailSent: boolean;
    codeDurationMinutesLeft: number;
    error: EmailVerificationError;
}

export interface CheckVerificationCodeRequestDTO {
    email: string;
    code: string;
}

export enum CheckCodeStatusDTO {
    CODE_VERIFIED = "CODE_VERIFIED",
    CODE_NOT_VERIFIED = "CODE_NOT_VERIFIED"
}

export interface CheckVerificationCodeResponseDTO {
    checkCode: CheckCodeStatusDTO;
    token: string;
    tokenExpiry: number;
}

export interface IdentityVerificationMetadata {
    personId: number,
    name: string;
    surname: string;
    email: string;
    phone: string;
    superAdmin: boolean;
    legalOwner: boolean;
    kycPass: boolean;
    hooYuRequestLink: string;
    identityVerificationSteps: string;
    providerName: 'HOOYU' | 'ONFIDO';
    workflowRunId: string;
}

export interface ComplianceStatusContext {
    status: ComplianceStatus;
    docUploadMetadataList?: EKYCRequiredDocumentsListElement[];
    daysUntilExpiration: number; // used with HooYu
    identityVerificationMetadataList: IdentityVerificationMetadata[]
}

export type ApplicationSegment = "Self Serve" | "Direct";
export interface Application {
    status: string;
    lead: Lead;
    account: Account;
    contacts: Array<Contact>;
    complianceStatus?: ComplianceStatusContext;
    fsApplicationSubmissionStatus: string;
    handOver: boolean;
    companyProfile: any;
    segment?: ApplicationSegment;
}



/**
 * The following are default max lengths
 * taken directly from Salesforce reference documentation
 * and Salesforce Entity Manager
 *
 * 255 is the default when not specified
 */


export const DEFAULT_STRING_MAX_LENGTH = 255;

// Default is 255
export const LEAD_DEFAULT_FIELD_LENGTH: Partial<{ [K in keyof Lead]: number }> = {
    LeadBrowserLanguage__c: 2,
    Email: 80,
    FirstName: 40,
    LastName: 80,
    RegisteredAddressCountryISO__c: 80
};

// Default is 255
export const ACCOUNT_DEFAULT_FIELD_LENGTH: Partial<{ [K in keyof Account]: number }> = {
    RegisteredAddressCity__c: 40,
    RegisteredAddressHouseName__c: 80,
    RegisteredAddressHouseNumber__c: 80,
    RegisteredAddressPoBox__c: 80,
    RegisteredAddressPostalCode__c: 20,
    RegisteredAddressSecondaryStreet__c: 80,
    RegisteredAddressServiceRefCode__c: 80,
    RegisteredAddressState__c: 80,
    RegisteredAddressStreet__c: 80,
    RegisteredAddressSubBuilding__c: 80,
    TradingAddressCity__c: 40,
    TradingAddressHouseName__c: 80,
    TradingAddressHouseNumber__c: 80,
    TradingAddressPoBox__c: 80,
    TradingAddressPostalCode__c: 20,
    TradingAddressSecondaryStreet__c: 80,
    TradingAddressServiceRefCode__c: 80,
    TradingAddressState__c: 80,
    TradingAddressStreet__c: 80,
    TradingAddressSubBuilding__c: 80
};

// Default is 255
export const CONTACT_DEFAULT_FIELD_LENGTH: Partial<{ [K in keyof Contact]: number }> = {
    Email: 80,
    FirstName: 40,
    LastName: 80,
    ContactBirthPlace__c: 80,
    ContactBirthState__c: 80,
    ContactFiscalCode__c: 80,
    ContactPhoneICC__c: 80,
    ResidentialAddressCity__c: 40,
    ResidentialAddressHouseName__c: 80,
    ResidentialAddressHouseNumber__c: 80,
    ResidentialAddressPoBox__c: 80,
    ResidentialAddressPostalCode__c: 20,
    ResidentialAddressSecondaryStreet__c: 80,
    ResidentialAddressServiceRefCode__c: 80,
    ResidentialAddressState__c: 80,
    ResidentialAddressStreet__c: 80,
    ResidentialAddressSubBuilding__c: 80
};

/**
 * The following are fields we want to exclude from
 * sanitization against XSSI attacks
 */

export const LEAD_SANITIZE_OVERRIDE: Partial<{ [K in keyof Lead]: SecurityContext }> = {

};

export const CONTACT_SANITIZE_OVERRIDE: Partial<{ [K in keyof Contact]: SecurityContext }> = {

};

export const ACCOUNT_SANITIZE_OVERRIDE: Partial<{ [K in keyof Account]: SecurityContext }> = {

};
