<h3 class="title">{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.CARDS.TITLE' | translate}}</h3>
<p class="subtitle">{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.CARDS.SUB_TITLE' | translate}}</p>
<div class="tip">
  {{'REGISTRATION.EXPECTED_ACTIVITY_STEP.CARDS.TIP_TEXT' | translate}}
</div>
<form [formGroup]="form" novalidate>
  <soldo-field-layout *ngIf="form.get('numberOfCards')"
    [control]="form.controls['numberOfCards']"
    fieldName="numberOfCards"
    [required]="true"
    label="REGISTRATION.EXPECTED_ACTIVITY_STEP.CARDS.LABEL"
    cypressSelector="Expected_RadioButton_NumberOfCards">

    <div input class="radio-main">
      <div class="radio-list radio-list-top">
        <div *ngFor="let i of [0,1,2,3,4]" class="radio-item"
          [attr.data-cy]="'Expected_RadioButton_NumberOfCards_'+numberOfCardsOptions[i].value">
          <input type="radio" formControlName="numberOfCards" [id]="'OPT'+i" [value]="numberOfCardsOptions[i].value">
          <label [for]="'OPT'+i">{{ numberOfCardsOptions[i].label }}</label>
        </div>
      </div>
    </div>

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.CARDS.RANGE_REQUIRED' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>
</form>


<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading" data-cy="Expected_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Expected_Button_NextStepButton">
      {{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
