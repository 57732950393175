<h2>{{'REGISTRATION.SIGNUP_STEP.COUNTRY.TITLE' | translate}}</h2>
<div class="layout-section">
  <form [formGroup]="form" novalidate autocomplete="off">

    <soldo-field-layout class="autocomplete-field" [control]="form.controls['searchTerm']" fieldName="searchTerm"
      cypressSelector="Signup_TextInput_SearchTerm"
      label="{{ 'REGISTRATION.SIGNUP_STEP.COUNTRY.COUNTRY_LABEL' | translate }}">

      <ng-container input>
        <input type="text" name="searchTerm" formControlName="searchTerm"
          placeholder="{{ 'REGISTRATION.SIGNUP_STEP.COUNTRY.COUNTRY_PLACEHOLDER' | translate }}" />
        <typeface-icon>search</typeface-icon>
      </ng-container>

      <soldo-field-error errorMessage errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value:
        form.get('searchTerm').errors?.maxlength?.requiredLength} }}</soldo-field-error>
    </soldo-field-layout>

    <ng-container *ngIf="!searchMode">

      <div *ngIf="showSelectedSeparately" class="chips-radio selected-country">
        <h5>{{ 'REGISTRATION.SIGNUP_STEP.COUNTRY.SELECTED_LIST_TITLE' | translate }}</h5>
          <fieldset>
            <div class="radio-item">
              <input type="radio" [id]="selectedCountry.alpha_3" [value]="selectedCountry.alpha_3" checked>
              <label [for]="selectedCountry.alpha_3">
                <div class="{{'round-flag-icon round-flag-' + selectedCountry.alpha_2.toLowerCase()}}"
                  [style]="'background-image: url(/assets/round-flag-icons/' + selectedCountry.alpha_2.toLowerCase() + '.svg);'"></div>
                <div>{{ selectedCountry.name | translate}}</div>
              </label>
            </div>
          </fieldset>
        </div>

      <h5>{{ 'REGISTRATION.SIGNUP_STEP.COUNTRY.POPULAR_LIST_TITLE' | translate }}</h5>
    </ng-container>
    <soldo-field-layout class="chips-radio" [control]="form.controls['country']" fieldName="country"
      cypressSelector="Signup_RadioItems_CountryOfIncorporation" label="">

      <ng-container input>
        <fieldset>
          <div *ngFor="let country of visibleCountries; let i = index" class="radio-item"
            [attr.data-cy]="'Signup_RadioItem_CountryOfIncorporation_'+country.alpha_3">
            <input type="radio" formControlName="country" [id]="country.alpha_3" [value]="country.alpha_3">
            <label [for]="country.alpha_3">
              <div class="{{'round-flag-icon round-flag-' + country.alpha_2.toLowerCase()}}"
                [style]="'background-image: url(/assets/round-flag-icons/' + country.alpha_2.toLowerCase() + '.svg);'"></div>
              <div>{{ country.name | translate}}</div>
            </label>
          </div>
        </fieldset>
      </ng-container>

      <soldo-field-error errorMessage errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>

    </soldo-field-layout>

    <!-- Sub-Step Navigation -->
    <div class="form-page-CTA-row">
      <button type="button" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
        {{ 'REGISTRATION.SIGNUP_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
      </button>
      <button type="submit" class="primary" (click)="nextStep()" [disabled]="loading" [ngClass]="{ 'loading': loading}"
        data-cy="Signup_Button_NextStepButton">
        {{ 'REGISTRATION.SIGNUP_STEP.NEXT_SUB_STEP_BUTTON' | translate }}
      </button>
    </div>
  </form>
</div>
