import { Component } from '@angular/core';

@Component({
    selector: 'ubo-help',
    templateUrl: './ubo-help.component.html',
    styleUrls: ['./ubo-help.component.scss']
})

export class UboHelpComponent {
}
