<div [formGroup]="boFormGroup" class="layout-section">
  <soldo-field-layout
    [control]="boFormGroup.controls.firstName"
    fieldName="firstName"
    cypressSelector="BeneficialOwner_TextInput_FirstName"
    label="REGISTRATION.BENEFICIAL_OWNER_FORM.FIRST_NAME_LABEL">

    <input input
      formControlName="firstName"
      type="text"
      name="firstName"
      [readOnly]="readonly" />

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value:
        boFormGroup.controls.firstName.errors?.maxlength?.requiredLength} }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <soldo-field-layout
    [control]="boFormGroup.controls.lastName"
    fieldName="lastName"
    cypressSelector="BeneficialOwner_TextInput_LastName"
    label="REGISTRATION.BENEFICIAL_OWNER_FORM.LAST_NAME_LABEL">

    <input input
      formControlName="lastName"
      type="text" name="lastName"
      [readOnly]="readonly" />

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value:
        boFormGroup.controls.lastName.errors?.maxlength?.requiredLength} }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <soldo-field-layout
    [control]="boFormGroup.controls.nationality"
    fieldName="nationality"
    cypressSelector="BeneficialOwner_NgSelect_Nationality"
    label="REGISTRATION.BENEFICIAL_OWNER_FORM.NATIONALITY_LABEL">

    <ng-select input *ngIf="!readonly"
      formControlName="nationality"
      [items]="completeCountryList" dropdownPosition="bottom"
      notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
      [clearable]="false" bindLabel="name" bindValue="alpha_3"
      name="nationality">
    </ng-select>
    <ng-container *ngIf="readonly" input>
      <input type="text" name="nationality_label" [value]="getNationalityLabel()" readonly>
      <input type="hidden" formControlName="nationality" name="nationality">
    </ng-container>

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <soldo-field-layout
    [control]="boFormGroup.controls.countryOfResidence"
    fieldName="countryOfResidence"
    cypressSelector="BeneficialOwner_NgSelect_CountryOfResidence"
    label="REGISTRATION.BENEFICIAL_OWNER_FORM.COUNTRY_OF_RESIDENCE_LABEL">

    <ng-select input *ngIf="!readonly"
      formControlName="countryOfResidence"
      [items]="completeCountryList" dropdownPosition="bottom"
      notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
      [clearable]="false" bindLabel="name" bindValue="alpha_3"
      name="countryOfResidence">
    </ng-select>
    <ng-container *ngIf="readonly" input>
      <input type="text" name="countryOfResidence_label" [value]="getCountryOfResidenceLabel()" readonly>
      <input type="hidden" formControlName="countryOfResidence" name="countryOfResidence">
    </ng-container>

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <soldo-field-layout
    [control]="boFormGroup.controls.email"
    fieldName="email"
    cypressSelector="BeneficialOwner_TextInput_Email"
    label="REGISTRATION.BENEFICIAL_OWNER_FORM.EMAIL_LABEL">

    <input input
      trim="all"
      formControlName="email"
      type="text"
      name="email"
      [readOnly]="readonly"/>

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error error errorName="invalidEmailFormat">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
      <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value:
          boFormGroup.controls.email.errors?.maxlength?.requiredLength} }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>


</div>
