<h3 class="title">{{'REGISTRATION.SIGNUP_STEP.COMPANY_TYPE.TITLE' | translate}}</h3>

<form [formGroup]="form">
    <soldo-field-layout *ngIf="form.get('companyType')"
        [control]="form.controls['companyType']"
        fieldName="companyType"
        [required]="true"
        cypressSelector="Signup_RadioButton_CompanyType"
        label="">

          <div input class="scrolling-container">
              <div *ngFor="let companyTypeCategoryGroup of companyTypesGroupedByCategory">
                <h6>{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_TYPE.'+ companyTypeCategoryGroup[0].category + '_LABEL' | translate}}</h6>
                <div *ngFor="let companyType of companyTypeCategoryGroup" class="radio-item">
                    <input
                      type="radio"
                      formControlName= "companyType"
                      [id]="companyType.value"
                      [value]="companyType.value">
                    <label [for]="companyType.value">{{companyType.label}}</label>
                </div>
                <p class="text-danger" *ngIf="form.controls['companyType'].errors?.selectionNotAllowed && companyTypeCategoryGroup[0].category == 'SOLETRADER'">
                  {{ 'REGISTRATION.SIGNUP_STEP.COMPANY_TYPE.SOLETRADER_NOT_ACCEPTED_MESSAGE' | translate}}
                </p>
              </div>
            </div>

        <soldo-field-error errorMessage errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error errorMessage errorName="selectionNotAllowed">{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_TYPE.SELECTION_NOT_ALLOWED' | translate }}</soldo-field-error>
    </soldo-field-layout>

      <soldo-field-layout *ngIf="form.get('companyTypeOther')"
        [control]="form.controls['companyTypeOther']"
        fieldName="companyTypeOther"
        [required]="true"
        cypressSelector="Signup_RadioButton_CompanyTypeOther"
        label="{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_TYPE.LABEL' }}">

        <ng-container input>
            <input
                name="companyTypeOther"
                placeholder="{{ 'COMPANY.TYPE_PLACEHOLDER' | translate }}"
                type="text"
                class="form-control roe-country-type-field"
                formControlName="companyTypeOther">
        </ng-container>

        <soldo-field-error errorMessage errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
    </soldo-field-layout>
</form>

<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

    <div class="col-6">
      <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
        <i class="md-icon">arrow_right_alt</i>
        <span>{{ 'REGISTRATION.SIGNUP_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
      </button>
    </div>

    <div class="col text-right">
      <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
        [ngClass]="{ 'in-progress': loading }" data-cy="Signup_Button_NextStepButton">
        {{ 'REGISTRATION.SIGNUP_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
      </button>
    </div>

  </div>
