import { Component, OnInit } from '@angular/core';
import { REGISTRATION_STEP_ID, RegistrationStep, StepService } from '@shared/services/steps.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    showFooter: boolean = false;
    steps: RegistrationStep[] = [];

    constructor(private stepService: StepService) { }

    ngOnInit() {
        this.stepService.registrationSteps.subscribe(registrationSteps => {
            this.recalculateFooterVisibility();
        });
        this.recalculateFooterVisibility();
    }

    recalculateFooterVisibility() {
        this.showFooter = this.stepService.getActiveStep().id === REGISTRATION_STEP_ID.WELCOME;
    }
}
