import { Component, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { LeadStartData } from '@shared/services/lead-data-mediator.service';
import { SignupSubStepOutboundAction, SignupSubStepType } from '../../signup-step.model';

@Component({
    selector: 'substep-about-you',
    templateUrl: './about-you.component.html',
    styleUrls: ['./about-you.component.scss']
})
export class AboutYouSubStepComponent extends SignupSubStepType {
    subStepName: string = "About You";

    loading: boolean = true;

    form: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService) {
        super();
        this.controlActionEventEmitter = new EventEmitter<SignupSubStepOutboundAction>();
    }

    isValid(): boolean {
        this.form.get("firstName").markAsTouched();
        this.form.get("lastName").markAsTouched();
        return this.form.valid;
    }

    init(d: Partial<LeadStartData>): void {
        this.form = this.fb.group({
            "firstName": [d.firstName, [Validators.required, this.leadMaxlengthValidator("FirstName")]],
            "lastName": [d.lastName, [Validators.required, this.leadMaxlengthValidator("LastName")]]
        });

        this.loading = false;
    }

    getData(): Partial<LeadStartData> {
        const formValues = this.form.value;
        return {
            firstName: formValues["firstName"],
            lastName: formValues["lastName"]
        };
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if(this.isValid()) {
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        }
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

}
