import { UICustomizationOptions } from "onfido-sdk-ui";

export const OnfidoUIConfig: UICustomizationOptions = {
        fontFamilyTitle: "system-ui, sans-serif",
        fontFamilySubtitle: "system-ui, sans-serif",
        fontFamilyBody: "system-ui, sans-serif",

	    colorBackgroundSurfaceModal: "#FFFFFF",
        colorBorderSurfaceModal: "#D2D2D6",
        borderRadiusSurfaceModal: "8px",

        colorBackgroundIcon: "#FFF0D2",

        borderRadiusButton: "6px",

        colorContentButtonPrimaryText: "#FFFFFF",

        colorBackgroundButtonPrimary: "#FF634B",
        colorBorderButtonPrimary: "#FF634B",

        colorBackgroundButtonPrimaryHover: "#FF634B",
        colorBorderButtonPrimaryHover: "#FF634B",

        colorBackgroundButtonPrimaryActive: "#FF634B", // stands for active focus
        colorBorderButtonPrimaryActive: "#047CC3", // stands for active focus

        colorContentButtonSecondaryText: "#FF634B",

        colorBackgroundButtonSecondary: "#FFFFFF",
        colorBorderButtonSecondary: "#FF634B",

        colorBackgroundButtonSecondaryHover: "#FFFFFF",
        colorBorderButtonSecondaryHover: "#FF634B",

        colorBackgroundButtonSecondaryActive: "#FFFFFF", // stands for active focus
        colorBorderButtonSecondaryActive: "#047CC3", // stands for active focus

        colorInputOutline: "#047CC3",

        colorBorderDocTypeButtonHover: "#000000",
        colorBorderDocTypeButtonActive: "#047CC3",

        colorBorderLinkUnderline: "#2b2d33",
        colorContentLinkTextHover: "#2b2d33",
        colorBackgroundLinkHover: "transparent",
        colorBackgroundLinkActive: "transparent"
};
