import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig, APP_CONFIG } from '../../app-config.module';
import { AmplitudeService } from '../../shared/services/amplitude.service';
import { RegistrationDataService } from '../../shared/services/registrationData.service';

@Component({
    selector: 'app-hoo-yu-link-page',
    templateUrl: './hoo-yu-link-page.component.html',
    styleUrls: ['./hoo-yu-link-page.component.scss']
})
export class HooYuLinkPageComponent implements OnInit {

    loading: boolean = true;
    hooyuVerificationLink: string;
    hooyuArticleLink: string;
    contactFirstName: string;
    poaRequired: boolean = false;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private translateService: TranslateService,
        private router: Router,
        private registrationDataService: RegistrationDataService,
        private amplitudeService: AmplitudeService) { }

    ngOnInit(): void {
        this.registrationDataService.loadIDVData().then(idvData => {
            this.hooyuVerificationLink = idvData.link;
            const contacts = this.registrationDataService.getContacts();
            this.contactFirstName = contacts[0]?.FirstName;
            this.loading = false;
            const complianceContext = this.registrationDataService.getComplianceContext();
            this.poaRequired = complianceContext.identityVerificationMetadataList[0].identityVerificationSteps.split(',').includes('POA');
        }, () => {
            this.router.navigate(['mitek']); // back to the standard email flow
        });

        this.hooyuArticleLink = this.config.hooyuArticleURLs[this.translateService.currentLang] || this.config.hooyuArticleURLs["default"];
        this.translateService.onLangChange.subscribe(() => {
            this.hooyuArticleLink = this.config.hooyuArticleURLs[this.translateService.currentLang] || this.config.hooyuArticleURLs["default"];
        });
    }

    callToActionOnClick(): void {
        this.loading = true;
        this.amplitudeService.trackIDVLinkClicked('hooyu', () => {
            this.registrationDataService.setHooYuVerificationLink(this.hooyuVerificationLink);
            this.router.navigate(['static/mitek']);
        });
    }

}
