import { AddressFormControlMetadata } from "./address2.component";
import { UntypedFormGroup, Validators, ValidatorFn } from "@angular/forms";
import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { AbstractFormComponent } from "../abstract-form.component";
import { Account } from "../../models/registration.model";
import { AmplitudeService } from "../../services/amplitude.service";
import { Subscription } from "rxjs";



export abstract class AbstractAddressFieldsComponent extends AbstractFormComponent {

    public addressForm: UntypedFormGroup;
    public simplified: boolean = false;
    public fieldMetadata: AddressFormControlMetadata;

    // @Input("detectManualEdits")
    // Override in actual components adding the @Input
    public detectManualEdits: boolean = true;

    // @Input("manualChangeEmitter")
    // Override in actual components adding the @Input
    public manualChangeEmitter: EventEmitter<string>;

    constructor(
        protected amplitudeService: AmplitudeService,
        protected router: Router
    ) {
        super();
    }

    protected configField(controlName: string, modelFieldName: keyof Account, mandatory: boolean,
        invalidateSearch: boolean, setMaxLenght: boolean, extraValidators: ValidatorFn[]): Subscription {

        this.fieldMetadata[controlName].mandatory = mandatory;
        const control = this.addressForm.get(controlName);
        control.enable();
        const validators = [].concat(extraValidators);
        if (setMaxLenght) {
            validators.push(this.accountMaxlengthValidator(modelFieldName));
        }
        if (mandatory) {
            validators.push(Validators.required);
        }
        control.setValidators(validators);
        control.updateValueAndValidity();
        return control.valueChanges.subscribe(() => {
            if (this.detectManualEdits) {
                if (invalidateSearch) {
                    this.invalidateSearchResult(controlName);
                }
                this.manualChangeEmitter.emit(controlName);
            }
        });
    }

    protected disableManualEditDetection(): void {
        this.detectManualEdits = false;
    }
    protected enableManualEditDetection(): void {
        this.detectManualEdits = true;
    }

    protected getFormId(): string {
        let formId: string = "none";
        if (this.addressForm.parent && this.addressForm.parent.get("personFormId")) {
            formId = this.addressForm.parent.get("personFormId").value;
        }
        return formId;
    }

    protected trackSearchResultSelected(): void {
        this.amplitudeService
            .trackAddressSearchResultSelected(this.router.url, this.getFormId(), this.addressForm.get("country").value, this.addressForm.valid);
    }

    protected trackSeachInvalidated(fieldName: string): void {
        this.amplitudeService.trackAddressSearchResultDiscarded(this.router.url, this.getFormId(), this.addressForm.get("country").value, fieldName);
    }

    abstract invalidateSearchResult(fieldName: string): void
}
