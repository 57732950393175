
<div class="save-and-continue-CTA-container">
  <button class="link" (click)="$event.preventDefault();saveAndContinueLater()">
    {{ "REGISTRATION.SAVE_AND_CONTINUE.SAVE_AND_CONTINUE_CTA" | translate }}
  </button>
</div>

<div *ngIf="overlayVisible" class="save-and-continue-overlay" interactive="-1" (interact)="hideDialog()">
  <div class="save-and-continue-dialog" interactive="-1" (interact)="$event.stopPropagation()">

    <h3>{{ "REGISTRATION.SAVE_AND_CONTINUE.TITLE" | translate }}</h3>

    <p>{{ "REGISTRATION.SAVE_AND_CONTINUE.PARAGRAPH_1" | translate : {recipient: emailRecipient} }}</p>

    <p>{{ "REGISTRATION.SAVE_AND_CONTINUE.PARAGRAPH_2" | translate }}</p>

    <div class="button-row">
      <button class="primary" (click)="hideDialog()">{{ "REGISTRATION.SAVE_AND_CONTINUE.DISMISS" | translate }}</button>
    </div>

    <button class="link no-padding close" (click)="hideDialog()"><typeface-icon>close</typeface-icon></button>
  </div>
</div>
