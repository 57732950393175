import { NgxLoggerLevel } from "ngx-logger";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    env: 'dev',
    logLevel: NgxLoggerLevel.DEBUG,
    production: false,
    localUrlEndpoint: '/assets/data',
    aggregatorUrlEndpoint: 'https://dev.soldocloud.net/platform-fe/services/aggregate',
    googleTagManagerID: 'GTM-MK77NL8',
    enableDynatraceTracking: false,
    amplitudeProjectId: '1ee9250effb8aae3bf339c05439a4433',
    intercomAppId: "b4179nby", // test app id
    intercomTestMode: true,
    invalidPlanOfferRedirectUrl: {
        ITA: 'https://stagtools.soldo.com/?lang=it',
        GBR: 'https://stagtools.soldo.com/?lang=gb',
        FRA: 'https://stagtools.soldo.com/?lang=fr',
        NLD: 'https://stagtools.soldo.com/?lang=nl',
        IRL: 'https://stagtools.soldo.com/?lang=en',
        fallback: 'https://stagtools.soldo.com/?lang=eu'
    },
    mentionMeBaseUrl: "https://tag-demo.mention-me.com/api/v2/",
    mentionMeCustomerCode: "mm4fd5b592",
    termsAndConditionsLink: {
        EU: "https://www.soldo.com/en-eu/terms-conditions/",
        GBR: "https://www.soldo.com/en-gb/terms-conditions/",
        ITA: "https://www.soldo.com/it-it/condizioni-generali/",
        FRA: "https://www.soldo.com/fr-fr/conditions-generales/",
        NLD: "https://www.soldo.com/nl-nl/algemene-voorwaarden/"
    },
    privacyPolicyLink: {
        it: "https://www.soldo.com/it-it/informative-sulla-privacy/",
        en: "https://www.soldo.com/en-gb/privacy-policies/",
        fr: "https://www.soldo.com/fr-fr/politique-de-confidentialite/",
        nl: "https://www.soldo.com/nl-nl/privacybeleid/"
    },
    embedHooYuVerificationSession: true,
    hooyuArticleURLs: {
        default: 'https://www.soldo.com/en-gb/blog/smooth-and-safe-identity-verification-with-hooyu/',
        it: 'https://www.soldo.com/it-it/blog/verifica-dellidentita-facile-e-sicura-con-hooyu/'
    },
    websiteHomePage: {
        default: "https://www.soldo.com/",
        it: "https://www.soldo.com/it-it/",
        fr: "https://www.soldo.com/fr-fr/",
        nl: "https://www.soldo.com/nl-nl/"
    },
    experimental: {},
    signupWithXeroLoginURLRoot: "https://login.xero.com/identity/connect/authorize",
    signupWithXeroLoginRedirectURI: "https://registration-dev.soldo.com/",
    signupWithXeroLoginAuthorizationScopes: "openid profile email accounting.contacts accounting.settings",
    signupWithXeroLeadSource: "XeroAppStore"
};
