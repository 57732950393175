<div [formGroup]="personFormGroup">

  <div class="layout-section">
    <soldo-field-layout
      [control]="personFormGroup.controls.firstName"
      fieldName="firstName"
      cypressSelector="Contact_TextInput_FirstName"
      label="REGISTRATION.PERSON.FIRST_NAME_LABEL">

      <input input type="text" name="firstName"
        formControlName="firstName"
        [readOnly]="readonly" />

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="firstNameCfMismatch">{{ 'FIRST_NAME_CF_MISMATCH' | translate }}
        </soldo-field-error>
        <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{
            value: personFormGroup.controls.firstName.errors?.maxlength?.requiredLength
          } }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout
      [control]="personFormGroup.controls.lastName"
      fieldName="lastName"
      cypressSelector="Contact_TextInput_LastName"
      label="REGISTRATION.PERSON.LAST_NAME_LABEL">

      <input input type="text" name="lastName"
        formControlName="lastName"
        [readOnly]="readonly" />

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="lastNameCfMismatch">{{ 'LAST_NAME_CF_MISMATCH' | translate }}
        </soldo-field-error>
        <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{
            value: personFormGroup.get('lastName').errors?.maxlength?.requiredLength
          } }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout
      [control]="personFormGroup.controls.nationality"
      fieldName="nationality"
      cypressSelector="Contact_NgSelect_Citizenship"
      label="REGISTRATION.PERSON.CITIZENSHIP_LABEL">

      <ng-select *ngIf="!readonly" input name="nationality"
        formControlName="nationality"
        [items]="completeCountryList"
        bindLabel="name"
        bindValue="alpha_3"
        [clearable]="false"
        notFoundText="{{'NO_ITEMS_FOUND' | translate }}">
      </ng-select>

      <ng-container *ngIf="readonly" input>
        <input type="text" name="nationality_label" [value]="getNationalityLabel()" readonly>
        <input type="hidden" formControlName="nationality" name="nationality">
      </ng-container>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout *ngIf="personFormGroup.controls.dateOfBirth"
      [control]="personFormGroup.controls.dateOfBirth"
      fieldName="dateOfBirth"
      cypressSelector="Contact_TextInput_DateOfBirth"
      label="REGISTRATION.PERSON.DOB_LABEL">

      <input input type="text" name="dateOfBirth"
        formControlName="dateOfBirth"
        [readOnly]="readonly"
        mask="00/00/0000"
        [validation]="false"
        [specialCharacters]="[ '/' ]"
        [dropSpecialCharacters]="false"
        placeholder="{{ 'REGISTRATION.PERSON.DOB_PLACEHOLDER' | translate }}" />

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error errorName="invalidDateFormat">{{ 'DATE_FORMAT_ERROR' | translate }}</soldo-field-error>
        <soldo-field-error errorName="ageNotAllowed">{{ 'DATE_AGE_NOT_ALLOWED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <ng-container *ngIf="personFormGroup.controls.codiceFiscale">
      <soldo-field-layout
        [control]="personFormGroup.controls.codiceFiscale"
        fieldName="codiceFiscale"
        cypressSelector="Contact_TextInput_CodiceFiscale"
        label="REGISTRATION.PERSON.CODICE_FISCALE_ITA_LABEL">

          <input input #codiceFiscaleInputElement type="text" name="codiceFiscale"
            formControlName="codiceFiscale"
            [readOnly]="readonly"
            (input)="codiceFiscaleInputElement.value=$event.target.value.toUpperCase()">

          <ng-container errorMessage>
            <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
            <soldo-field-error error errorName="wrongFormat">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
            <soldo-field-error error errorName="duplicated">{{ 'DUPLICATED_CF' | translate }}</soldo-field-error>
            <soldo-field-error error errorName="ageNotAllowed">{{ 'DATE_AGE_NOT_ALLOWED' | translate }}</soldo-field-error>
          </ng-container>
      </soldo-field-layout>
    </ng-container>

    <soldo-field-layout
      [control]="personFormGroup.controls.email"
      fieldName="email"
      cypressSelector="Contact_TextInput_Email"
      label="REGISTRATION.PERSON.WORK_EMAIL_LABEL"
      [hint]="!readonly && countryOfIncorporation === 'ITA' ? 'REGISTRATION.PERSON.EMAIL_INFO' : ''"
      [useTooltip]="true">

      <input input type="text" name="email"
        formControlName="email"
        [readOnly]="readonly || emailIsReadonly"
        trim="all"/>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="invalidEmailFormat">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate : {
            value: personFormGroup.controls.email.errors?.maxlength?.requiredLength
          } }}</soldo-field-error>
        <soldo-field-error error errorName="notYourEmail">{{ 'NOT_YOUR_EMAIL' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="duplicated">{{ 'DUPLICATED_EMAIL' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="noDirectorsEmail">{{ 'NO_DIRECTOR_EMAIL_REUSE' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="noDuplicatedEmail">{{ 'DUPLICATED_EMAIL' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout
      [control]="personFormGroup.controls.mobile"
      fieldName="mobile"
      cypressSelector="Contact_TextInput_MobileNumber"
      label="REGISTRATION.PERSON.PHONE_LABEL"
      [hint]="!readonly ? 'REGISTRATION.PERSON.PHONE_INFO' : ''"
      [useTooltip]="true">

      <div input *ngIf="!readonly"
        [formGroup]="personFormGroup.controls.mobile"
        class="phone-input"
        [ngClass]="{'readonly': readonly}">

        <ng-select name="prefix"
          formControlName="prefix"
          [items]="completeCountryList"
          bindLabel="icc"
          bindValue="icc"
          [clearable]="false"
          notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
          placeholder="{{ 'REGISTRATION.PERSON.ICC_PLACEHOLDER' | translate }}"
          data-cy="MobileNumber_NgSelect_ICC">

          <ng-template ng-option-tmp let-item="item" let-index="index">
            <span class="ng-option-label">
              <span class="icc">{{item.icc}}</span><span class="border-left">{{item.name}}</span>
            </span>
          </ng-template>

        </ng-select>
        <input type="text" name="number" #mobileNumberInputElement
          formControlName="number"
          [readOnly]="readonly"
          data-cy="MobileNumber_TextInput_Number"/>

      </div>

      <ng-container input formGroupName="mobile" *ngIf="readonly">
        <input type="text" name="number_label"
          [value]="personFormGroup.value.mobile.prefix+personFormGroup.value.mobile.number"
          readOnly />
        <input type="hidden" name="prefix" formControlName="prefix">
        <input type="hidden" name="number" formControlName="number">
      </ng-container>

      <ng-container errorMessage >

        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="notValidNumber">{{ 'NOT_VALID_PHONE' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="duplicated">{{ 'DUPLICATED_PHONE' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>
  </div>

  <div class="layout-section">

    <h5><strong>{{ 'REGISTRATION.PERSON.ADDRESS' | translate }}</strong></h5>

    <address2-form-group [readonly]="readonly" [group]="personFormGroup.controls.address" data-cy="Contact_Address_ResidentialAddress">
    </address2-form-group>
  </div>

  <div class="layout-section">
    <soldo-field-layout *ngIf="personFormGroup.controls.isFatcaSubject"
      [control]="personFormGroup.controls.isFatcaSubject"
      fieldName="isFatcaSubject"
      cypressSelector="Contact_RadioButton_FatcaSubject"
      label="REGISTRATION.PERSON.FATCA_FIELD_QUESTION">

      <fieldset input id="isFatcaSubjectRadio">
        <legend>-- (hidden) need this to group the choices when multiple admin forms are on screen --</legend>
        <div *ngIf="!readonly || personFormGroup.value.isFatcaSubject == 'Yes'"
          data-cy="Contact_RadioButton_FatcaSubject_YES">
          <input id="isFatcaSubjectRadio-YES" type="radio" formControlName="isFatcaSubject" value="Yes">
          <label for="isFatcaSubjectRadio-YES">{{ 'REGISTRATION.PERSON.FATCA_YES_LABEL' | translate }}</label>
        </div>
        <div *ngIf="!readonly || personFormGroup.value.isFatcaSubject == 'No'"
          data-cy="Contact_RadioButton_FatcaSubject_NO">
          <input id="isFatcaSubjectRadio-NO" type="radio" formControlName="isFatcaSubject" value="No">
          <label for="isFatcaSubjectRadio-NO">{{ 'REGISTRATION.PERSON.FATCA_NO_LABEL' | translate }}</label>
        </div>
      </fieldset>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>
  </div>

