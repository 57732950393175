<h2>{{'REGISTRATION.COMPANY_STEP.REGISTERED_ADDRESS.TITLE' | translate}}</h2>
<h5 *ngIf="selectedCountry == 'GBR'">
  {{'REGISTRATION.COMPANY_STEP.REGISTERED_ADDRESS.GBR_SEARCH_HELP' | translate}}
</h5>
<div class="layout-section">

  <h5><strong>{{'REGISTRATION.COMPANY_STEP.REGISTERED_ADDRESS.TITLE_FORM' | translate}}</strong></h5>

  <form [formGroup]="form" novalidate autocomplete="off">

    <div data-cy="Company_Address_RegisteredAddress">

      <address2-form-group
        [group]="form"
        [activeMarketsOnly]="true"
        [simplified]="true"
        data-cy="Company_Address_RegisteredAddress">
      </address2-form-group>

    </div>

    <!-- Sub-Step Navigation -->
    <div class="form-page-CTA-row">

      <button type="button" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
        {{ 'REGISTRATION.COMPANY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
      </button>

      <button type="submit" class="primary" (click)="nextStep()" [disabled]="loading"
        [ngClass]="{ 'loading': loading}" data-cy="Signup_Button_NextStepButton">
        {{ 'REGISTRATION.COMPANY_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
      </button>

    </div>
  </form>
</div>
