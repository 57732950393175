import { Injectable } from '@angular/core';

@Injectable()
export class ResumeService {

    private _resumeState: boolean = false;

    constructor() { }

    getResumeState(): boolean {
        return this._resumeState;
    }

    setResumeState(resume: boolean) {
        this._resumeState = resume;
    }
}
