<h2>{{'REGISTRATION.COMPANY_STEP.SALES_CHANNEL.TITLE' | translate}}</h2>

<div class="layout-section">
  <form [formGroup]="form" novalidate autocomplete="off">
    <div class="form-input" [ngClass]="{ 'ng-touched': form.touched }">
      <div class="checkbox-group">
        <div [attr.data-cy]="'Company_Checkbox_SalesChannel_RETAIL'">
          <input id="retailChannel" type="checkbox" (value)="true" formControlName="retailChannel">
          <label for="retailChannel">{{ 'REGISTRATION.COMPANY_STEP.SALES_CHANNEL.OPTION_RETAIL_LABEL' | translate }}</label>
        </div>

        <div [attr.data-cy]="'Company_Checkbox_SalesChannel_ONLINE'">
          <input id="onlineChannel" type="checkbox" (value)="true" formControlName="onlineChannel">
          <label for="onlineChannel">{{ 'REGISTRATION.COMPANY_STEP.SALES_CHANNEL.OPTION_ONLINE_LABEL' | translate }}</label>
        </div>

        <div [attr.data-cy]="'Company_Checkbox_SalesChannel_OTHER'">
          <input id="otherChannelSelected" type="checkbox" (value)="true" formControlName="otherChannelSelected">
          <label for="otherChannelSelected">{{ 'REGISTRATION.COMPANY_STEP.SALES_CHANNEL.OPTION_OTHER_LABEL' | translate }}</label>
        </div>
      </div>

      <div *ngIf="form.controls['otherChannel']" class="conditional-subfield-container">
        <soldo-field-layout [control]="form.controls['otherChannel']" fieldName="otherChannel"
          label="REGISTRATION.COMPANY_STEP.SALES_CHANNEL.OTHER_CHANNEL_LABEL"
          cypressSelector="Company_TextInput_OtherSalesChannel"
          hint="REGISTRATION.COMPANY_STEP.SALES_CHANNEL.OTHER_CHANNEL_EXAMPLE">

          <ng-container input>
            <textarea name="otherChannel" formControlName="otherChannel" type="text"
              placeholder="">
          </textarea>
          </ng-container>

          <ng-container errorMessage>
            <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
            <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value:
              form.get('otherChannel').errors?.maxlength?.requiredLength} }}</soldo-field-error>
          </ng-container>
        </soldo-field-layout>

      </div>
      <div class="validation-errors-container">
        <div class="field-validation-error caption" *ngIf="form.errors?.selectOneOrMore">
          {{ 'ONE_OR_MORE_OPTIONS_REQUIRED' | translate }}
        </div>
      </div>
    </div>

    <div class="form-page-CTA-row">
      <button type="button" (click)="previousStep()" data-cy="Signup_Button_PreviousStepButton">
        {{ 'REGISTRATION.COMPANY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
      </button>
      <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}" (click)="nextStep()"
        data-cy="Signup_Button_NextStepButton">{{ 'REGISTRATION.COMPANY_STEP.NEXT_SUB_STEP_BUTTON' | translate }}</button>
    </div>
  </form>
</div>

