import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Account, Contact, ContactOperation } from "@shared/models/registration.model";
import { AmplitudeService } from '@shared/services/amplitude.service';
import { RegistrationDataService } from "@shared/services/registrationData.service";
import { StatusService } from '@shared/services/status.service';
import { StepService } from "@shared/services/steps.service";
import { NGXLogger } from 'ngx-logger';
import { SELECT_INVALID_SOLDO_FIELD, scrollToFirstResult } from '../../shared/formHelpers';
import { PersonFormGroup, PersonFormHelperService } from '../person-form/person-form-helper.service';
import { APP_CONFIG, AppConfig } from '../../app-config.module';

export enum LEGAL_REP_CHOICE {
    NO_CHOICE,
    APPLICANT,
    OTHER
}

interface LegalRepresentativeChoiceFormGroup {
    applicantIsLegalRep: FormControl<LEGAL_REP_CHOICE>;
    legalRep: FormGroup<PersonFormGroup>;
}

@Component({
    selector: 'director',
    templateUrl: './director.component.html',
    styleUrls: ['./director.component.scss']
})
export class DirectorComponent implements OnInit {

    form: FormGroup<LegalRepresentativeChoiceFormGroup>;
    soleTrader: boolean = false;
    loading: boolean = false;
    allContacts: Contact[] = [];
    countryOfIncorporation: string;
    regulatoryInstitution: string;
    registrationProcessURL: string;

    constructor(
        @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
        protected logger: NGXLogger,
        private readonly router: Router,
        private readonly fb: FormBuilder,
        private readonly personFormHelper: PersonFormHelperService,
        private readonly registrationDataService: RegistrationDataService,
        private readonly stepService: StepService,
        private readonly amplitudeService: AmplitudeService,
        private readonly statusService: StatusService
    ) {
        this.countryOfIncorporation = this.registrationDataService.getCompanyCountry();
        this.allContacts = this.registrationDataService.getContacts();
        this.soleTrader = this.registrationDataService.isSoleTrader();
        const account: Account = this.registrationDataService.getAccount();
        this.regulatoryInstitution = this.appConfig.regulatoryInstitutionShorthand[account.EMoneyLicenseIssuer__c];

        this.registrationProcessURL = this.appConfig.registrationProcessHelpURLs[this.countryOfIncorporation]
            || this.appConfig.registrationProcessHelpURLs.fallback;
    }

    public get applicantContact(): Contact {
        return this.allContacts.find(c => c.ContactIsPrimaryLead__c);
    }

    public get legalRepresentative(): Contact {
        return this.allContacts.find(c => c.ContactIsLegalOwner__c);
    }

    ngOnInit() {
        const fsApplicationSubmissionStatus = this.statusService.getFsSubmissionStatus();

        if (fsApplicationSubmissionStatus.key === "AccountCreated" && this.registrationDataService.isDirectSalesSegment()) {
            this.initAsDirectSalesHandOver();
        } else if (this.soleTrader) {
            this.initAsSoleTrader(this.applicantContact);
        } else if (this.legalRepresentative) {
            this.initAsExistingLegalRep(this.legalRepresentative);
        } else {
            this.initAsNew();
        }

        this.form.controls.applicantIsLegalRep.valueChanges.subscribe((choice) => {
            if (choice == LEGAL_REP_CHOICE.APPLICANT) {
                this.selectApplicant();
            } else if (choice === LEGAL_REP_CHOICE.OTHER) {
                this.selectNewContact();
            }
        });
    }

    private initAsNew() {
        this.form = this.fb.group({
            applicantIsLegalRep: [LEGAL_REP_CHOICE.NO_CHOICE, (control: AbstractControl) => {
                return (control.value === LEGAL_REP_CHOICE.NO_CHOICE) ? { required: true } : null;
            }],
            legalRep: this.personFormHelper.createEmptyPersonFormGroup(this.countryOfIncorporation)
        });
    }

    private initAsExistingLegalRep(legalRep: Contact) {
        this.form = this.fb.group({
            applicantIsLegalRep: [legalRep.ContactIsPrimaryLead__c ? LEGAL_REP_CHOICE.APPLICANT : LEGAL_REP_CHOICE.OTHER],
            legalRep: this.personFormHelper.createPersonFormGroupForContact(legalRep, this.countryOfIncorporation)
        });
    }

    private initAsSoleTrader(applicantContact: Contact) {
        const legalRepForm = this.personFormHelper.createPersonFormGroupForContact(applicantContact, this.countryOfIncorporation);
        legalRepForm.controls.address.controls.country.disable();
        this.form = this.fb.group({
            applicantIsLegalRep: [LEGAL_REP_CHOICE.APPLICANT],
            legalRep: legalRepForm
        });
    }

    private initAsDirectSalesHandOver() {
        this.allContacts = this.allContacts.map((c) => {
            if (c.ContactIsPrimaryLead__c) {
                return {
                    ...this.registrationDataService.getEmptyContact(),
                    SoldoContactExternalID__c: c.SoldoContactExternalID__c,
                    FirstName: c.FirstName,
                    LastName: c.LastName,
                    Email: c.Email,
                    ContactIsPrimaryLead__c: true
                };
            } else {
                return c;
            }
        });
        this.initAsNew();
    }

    selectApplicant(): void {
        this.allContacts.forEach(c => {
            c.ContactIsDirector__c = false;
            c.ContactIsLegalOwner__c = false;
        });

        this.applicantContact.ContactIsLegalOwner__c = true;
        this.applicantContact.ContactIsDirector__c = true;

        const legalRepFormGroup = this.personFormHelper.createPersonFormGroupForContact(this.applicantContact, this.countryOfIncorporation);

        this.form.setControl("legalRep", legalRepFormGroup);
    }

    selectNewContact(): void {
        this.allContacts.forEach(c => {
            c.ContactIsDirector__c = false;
            c.ContactIsLegalOwner__c = false;
        });

        const legalRepFormGroup = this.personFormHelper.createEmptyPersonFormGroup(this.countryOfIncorporation);

        this.form.setControl("legalRep", legalRepFormGroup);
    }

    private saveForm() {

        const legalRepValues = this.form.value.legalRep;
        this.logger.debug("Legal Representative Values: ", legalRepValues);

        const contactsOperations: Array<ContactOperation> = [];

        if (legalRepValues.address.idCheckAddressLookupAddressCode) {
            this.amplitudeService.trackAddressSearchResultSubmitted(
                this.router.url,
                "none",
                this.soleTrader ? this.countryOfIncorporation : legalRepValues.address.country
            );
        }

        const legalRepContact = this.personFormHelper.mapFormValuesToContact(this.form.controls.legalRep);

        if (this.soleTrader) {
            legalRepContact.ResidentialAddressCountryISO__c = this.countryOfIncorporation;
            legalRepContact.ContactIsAdmin__c = true;
        }

        contactsOperations.push({
            ...legalRepContact as ContactOperation,
            ContactIsDirector__c: true,
            ContactIsLegalOwner__c: true
        });

        const others = this.allContacts.filter(c => {
            return c.SoldoContactExternalID__c !== legalRepValues.contactExternalId;
        });

        others.forEach((c) => {
            contactsOperations.push({
                SoldoContactExternalID__c: c.SoldoContactExternalID__c,
                ContactIsLegalOwner__c: false,
                ContactIsDirector__c: false,
                IsDeleted: !this.personFormHelper.hasSomeRole(c)
            });
        });

        this.registrationDataService.saveContacts(contactsOperations);
    }

    onSubmit() {
        if (this.form.value.applicantIsLegalRep === LEGAL_REP_CHOICE.NO_CHOICE) {
            this.form.controls.applicantIsLegalRep.markAsTouched();
            scrollToFirstResult(SELECT_INVALID_SOLDO_FIELD);
            return;
        }

        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.loading = true;
            this.saveForm();

            const postSubmitFsSubmissionStatus = this.statusService.getFsSubmissionStatusValidIncrement(this.soleTrader ? "AdminCreated" : "DirectorCreated");

            this.registrationDataService.saveAccount({
                FsApplicationSubmissionStatus__c: postSubmitFsSubmissionStatus
            });

            this.registrationDataService.submitContacts().then(() => {
                this.registrationDataService.submitAccount().then(() => {
                    this.statusService.setStatusIncrementAfterSubmission(postSubmitFsSubmissionStatus);
                    this.amplitudeService.trackApplicantIsLegalRepresentativeChoice(this.applicantContact.ContactIsDirector__c);
                    this.stepService.navigateToNextStep();
                });
            }, (err) => this.logger.trace("FATAL: ", err));
        } else {
            this.loading = false;
            this.logger.warn("form not valid: ", this.form.value, this.form.errors);
            scrollToFirstResult(SELECT_INVALID_SOLDO_FIELD, 'fiscal-code:has(input.ng-invalid)');
        }
    }

    goToPrevious() {
        this.stepService.navigateToPreviousStep();
    }

    public get LEGAL_REP_CHOICE() {
        return LEGAL_REP_CHOICE;
    }
}
