<div class="idv-loading-background" *ngIf="loading">
  <div class="loading-screen"></div>
</div>

<div class="idv-page-content" *ngIf="!loading">

  <header>
    <h2>{{ "REGISTRATION.STATIC_PRE_IDV_PAGE.TITLE" | translate }}</h2>
    <div class="image docuploadimage"></div>
  </header>

  <p [innerHTML]="'REGISTRATION.STATIC_PRE_IDV_PAGE.PARAGRAPH_1' | translate"></p>

  <div class="necessary-steps">
    <span [innerHTML]="'REGISTRATION.STATIC_PRE_IDV_PAGE.NECESSARY_STEPS.INTRO' | translate"></span>
    <ul>
      <li *ngFor="let step of necessaryIdvSteps" [innerHTML]="'REGISTRATION.STATIC_PRE_IDV_PAGE.NECESSARY_STEPS.' + step | translate : {idvProvider: idvProvider}"></li>
    </ul>
  </div>

  <div class="layout-section cta-section">
    <button
      data-cy="IDV_Button_VerifyYourIdentityButton"
      class="primary"
      (click)="proceedToIDV()">
      {{ "REGISTRATION.STATIC_PRE_IDV_PAGE.CTA" | translate }}
    </button>
  </div>

  <p [innerHTML]="'REGISTRATION.STATIC_PRE_IDV_PAGE.HELP_PARAGRAPH' | translate : {idvHelpPageUrl : idvHelpPageUrl, privacyPolicyLink : privacyPolicyLink }"></p>

</div>
