export enum CompanySearchProfileStatus {
    ACTIVE = 'active',
    OPEN = 'open',
    DISSOLVED = 'dissolved',
    CLOSED = 'closed',
    CONVERTED_CLOSED = 'converted-closed',
    RECEIVERSHIP = 'receivership',
    LIQUIDATION = 'liquidation',
    ADMINISTRATION = 'administration',
    INSOLVENCY_PROCEEDINGS = 'insolvency-proceedings',
    VOLUNTARY_ARRANGEMENT = 'voluntary-arrangement'
  }

export interface CompanySearchResultItem {
    name: string,
    status: CompanySearchProfileStatus,
    kybProviderId: string,
    registrationNumber: string,
    addressSummary: string
}

export interface CompanySearchResult {
    companySearchResults: Array<CompanySearchResultItem>,
    totalResults: number
}

export interface CompanyLookupDirectorProfile {
    firstName: string,
    lastName: string,
    middleName: string,
    birthDate: string,
    citizenshipCountry: string,
    addressCountry: string,
    isDirector: true
}

export interface CompanyLookupResult {
    name: string,
    kybProviderId: string,
    registrationNumber: string,
    legalEntityType: string,
    registeredAddressLine1: string,
    registeredAddressLine2: string,
    city: string,
    state: string,
    vatNumber: string,
    postcode: string,
    country: string,
    employees: string,
    status: string,
    dateOfIncorporation: string,
    directorProfileList: Array<CompanyLookupDirectorProfile>
}
