
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { filter, map, mergeMap, take, timeout } from 'rxjs/operators';

import { NGXLogger } from 'ngx-logger';
import { CompanyLookupResult } from '../models/company-search.model';
import {
    Account, Application, CheckVerificationCodeRequestDTO,
    CheckVerificationCodeResponseDTO, CompleteProofResponse, ComplianceStatus,
    ComplianceStatusContext, Contact, ContactOperation,
    DEFAULT_LAST_NAME, DocUploadFile, DocUploadMetadata, EKYCDocumentUploadedData,
    EKYCRequiredDocumentsListElement, KYCDocRequest, Lead,
    RequestEmailVerificationCodeRequestDTO, RequestEmailVerificationCodeResponseDTO,
    UploadPresignedURLResponse,
    ApplicationSegment,
    UBOProvisioningChoiceType,
    EMoneyLicenseIssuer,
    UserIDVDetails,
    IDVSessionDetails
} from "../models/registration.model";
import { LeadStartData } from './lead-data-mediator.service';
import { RequestAggregatorService } from "./requestAggregator.service";
import { SoldoCookieService } from './soldo-cookie.service';
import { RegistrationApiService } from './registration-api.service';

const emptyLeadData: Lead = {
    "Id": null,
    "SoldoRegistrationExternalID__c": null,
    "Email": null,
    "MobilePhone": null,
    "MobilePhoneICC__c": null,
    "FirstName": null,
    "LastName": null,
    "Company": "anonymous",
    "RegisteredAddressCountryISO__c": null,
    "CompanyType__c": null,
    "CompanyTypeDescription__c": "",
    "CompanyReasonForUse__c": null,
    "SoldoRegistrationStatus__c": "",
    "LeadBrowserLanguage__c": null,
    // remove in place of OfferName
    "SoldoPromotionCode__c": null,
    "OfferName__c": null,
    "PlanName__c": null,
    "NumberOfEmployees": null,
    "LeadSource": null,
    "SoleTrader__c": false,
    "CustomerBusinessDescription__c": null,
    "CustomerUseCaseDescription__c": null,
    "DiscountCode__c": null,
    "FsApplicationSubmissionStatus__c": ""
};



const emptyAccountData: Account = {
    "SoldoRegistrationExternalID__c": null,
    "Id": null,
    "CompanyRegistrationNumber__c": null,
    "Name": null,
    "CompanyTradingName__c": null,
    "CompanyVATNumber__c": null,
    "CompanyCodiceFiscale__c": null,
    "Industry": null,
    "CompanyType__c": null,
    "SoleTrader__c": false,
    "CompanyTypeDescription__c": "",
    "RegisteredAddressLine1__c": null,
    "RegisteredAddressLine2__c": null,
    "RegisteredAddressPostalCode__c": null,
    "RegisteredAddressCity__c": null,
    "RegisteredAddressCountryISO__c": null,
    "RegisteredAddressState__c": null,
    "SoldoTermsAndConditions__c": null,
    "CompanyReasonForUse__c": null,
    // remove in place of OfferName
    "SoldoPromotionCode__c": null,
    "OfferName__c": null,
    "PlanName__c": null,
    "DiscountCode__c": null,
    "NumberOfEmployees": null,
    "IdentityVerificationRequested__c": false,
    "CustomerBusinessDescription__c": null,
    "CustomerUseCaseDescription__c": null,
    "EMoneyLicenseIssuer__c": null,
    "FsApplicationSubmissionStatus__c": ""
};

const emptyContactData: Contact = {
    Birthdate: null,
    ContactBirthCountryISO__c: null,
    ContactCitizenshipCountryISO__c: null,
    ContactIsAdmin__c: false,
    ContactIsDirector__c: false,
    ContactIsLegalOwner__c: false,
    ContactIsPrimaryLead__c: false,
    ContactPhoneICC__c: null,
    Email: null,
    FirstName: null,
    LastName: null,
    MobilePhone: null,
    ResidentialAddressCity__c: null,
    ResidentialAddressCountryISO__c: null,
    ResidentialAddressLine1__c: null,
    ResidentialAddressLine2__c: null,
    ResidentialAddressPostalCode__c: null,
    ResidentialAddressState__c: null,
    SoldoContactExternalID__c: null
};

const VALID_PLAN_OFFERS = [
    "Business-Premium2019",
    "Business-Premium201903",
    "Business-Premium201904",
    "Business-Premium2020",
    "Business-Premium2021",
    "Business-ZucchettiSistemi2019",
    "Business-Standard201809",
    "Business-SingleCard2018",
    "Business-SoldoNonProfit2018",
    "Business-PremiumReseller2021",
    "StartBundle-StartBundle2204",
    "StartBundle-SS-Start-2207",
    "ProGrowthBundle-ProGrowthBundle2204",
    "ProGrowthBundle-SS-Pro-2207",
    "ProGrowthBundle-SS-Pro-2303",
    "ProScaleBundle-ProScaleBundle2204",
    "PremiumBundle-PremiumBundle2204",
    "PremiumBundle-SS-Premium-2207",
    "PremiumBundle-SS-Premium-2303",
    "DirectPremiumBundle-DirectPremiumBundle2204",
    "DirectStandardBundle-DirectStandardBundle2204",
    "DirectCustomBundle-DirectCustomBundle2204",
    "ProStartup-ProStartup2206",
    "Plan_25_2402-Offer_25_2402",
    "Plan_26_2402-Offer_26_2402",
    "Plan_27_2402-Offer_27_2402",
    "Plan_28_2402-Offer_28_2402",
    "Plan_29_2402-Offer_29_2402",
    "Plan_30_2402-Offer_30_2402",
    "Plan_31_2402-Offer_31_2402",
    "Plan_32_2402-Offer_32_2402",
    "Plan_33_2402-Offer_33_2402",
    "Plan_34_2402-Offer_34_2402"
];

export type ApprovedStatus = "APPROVED" | "PENDING";
export type SoldoProductName = "GeneralSpending";

const PRODUCT_GENERAL_SPENDING: SoldoProductName = "GeneralSpending";
const PRODUCT_BY_PLAN: { [key: string]: SoldoProductName } = {
    SoldoStart: PRODUCT_GENERAL_SPENDING,
    SoldoPro: PRODUCT_GENERAL_SPENDING,
    Business: PRODUCT_GENERAL_SPENDING
};

export type SoldoProductMarket = "ITA" | "GBR" | "IRL" | "ROE" | "NLD" | "DEU" | "FRA" | "SPA";

export interface ApplicationMeta {
    planName: string;
    offerName: string;
    affiliateId?: string;
    affiliateClickTime?: number;
    affiliateMetadata?: string;
}

export interface XeroOAuthDTO {
    state: string;
    registrationExternalID: string;
    clientId: string;
    exchangeCode: string;
    redirectUri: string;
}

export interface XeroOpenIdData {
    firstName: string;
    lastName: string;
    email: string;
}

export interface IDVData {
    provider: 'HOOYU' | 'ONFIDO';
    sessionId: string;
    applicantMustIdv?: boolean;
    personExternalId?: string;
}

@Injectable()
export class RegistrationDataService {

    private static readonly SERVICE_NAME = 'soldoSalesforceRestService';
    private lead: Lead = null;
    private account: Account = null;
    private contacts: Contact[] = [];
    private validPlanOfferCombo: boolean = false;
    private soleTraderSelectionEnabled: boolean = false;
    private applicationSegment: ApplicationSegment = null;

    private selectedCompanySearchProfile: CompanyLookupResult = null;

    private complianceStatusContext: ComplianceStatusContext = null;
    private kycDocUploadData: KYCDocRequest[] = null;
    private idvData: IDVData = null;

    applicationDataSubmittedEventEmitter: EventEmitter<Lead | Account | Contact[]> = new EventEmitter();
    statusAdvancedEventEmitter: EventEmitter<string> = new EventEmitter();
    eKYCResultEventEmitter: EventEmitter<Application> = new EventEmitter<Application>();

    constructor(
        private readonly logger: NGXLogger,
        private readonly requestAggregator: RequestAggregatorService,
        private readonly registrationApiService: RegistrationApiService,
        private readonly cookieService: SoldoCookieService
    ) { }

    loadApplication(registrationId: string, appMeta: ApplicationMeta): Promise<Application> {
        const promise = this.requestAggregator.sendSingleRequest<Application>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'resumeBusinessApplication',
            args: [registrationId, appMeta]
        });
        promise.then((response: Application) => {
            if (response.complianceStatus) {
                this.initComplianceStatusVariables(response.complianceStatus, response.contacts);
            }

            if (response.companyProfile) {
                this.setCompanySearchLookupProfile(response.companyProfile);
            }

            this.applicationSegment = response.segment;

            this.logger.debug("Application segment", this.applicationSegment);
            this.logger.debug("Compliance context ", this.complianceStatusContext);
            this.logger.debug("Documents required ", this.kycDocUploadData);
        });
        return promise;
    }

    initComplianceStatusVariables(complianceStatusContext: ComplianceStatusContext, contacts: Contact[]) {
        this.complianceStatusContext = complianceStatusContext;
        const applicant = contacts.find(c => c.ContactIsPrimaryLead__c);

        if (this.complianceStatusContext.docUploadMetadataList?.length) {
            this.initDocUploadData(this.complianceStatusContext.docUploadMetadataList);
        }

        if (this.complianceStatusContext.status === ComplianceStatus.IDV_REQUIRED) {
            const applicantSession = this.complianceStatusContext.identityVerificationMetadataList.find(idvc => idvc.email == applicant.Email);
            const providerName = this.complianceStatusContext.identityVerificationMetadataList.find(idvc => idvc.providerName)?.providerName;

            let applicantIdvSessionId: string = null;
            switch (applicantSession?.providerName) {
                case 'HOOYU':
                    applicantIdvSessionId = applicantSession.hooYuRequestLink;
                    break;
                case 'ONFIDO':
                    applicantIdvSessionId = applicantSession.workflowRunId;
                    break;
            }

            this.setIdvData({
                provider: applicantSession?.providerName || providerName,
                applicantMustIdv: Boolean(applicantSession),
                sessionId: applicantIdvSessionId
            });
        }
    }

    fetchSignupWithXeroLoginParams(): Promise<Partial<XeroOAuthDTO>> {
        return this.requestAggregator.sendSingleRequest<Partial<XeroOAuthDTO>>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'initSignupWithXero',
            args: []
        });
    }

    fetchSignupWithXeroOpenIdData(data: Partial<XeroOAuthDTO>): Promise<Application> {
        return this.requestAggregator.sendSingleRequest<Application>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'getXeroUserData',
            args: [data]
        });
    }

    enableSoleTraderSelection(enable: boolean): void {
        this.soleTraderSelectionEnabled = enable;
    }

    isSoleTraderSelectionEnabled(): boolean {
        return this.soleTraderSelectionEnabled;
    }

    isSoleTrader(): boolean {
        return this.lead === null ? this.account.SoleTrader__c : this.lead.SoleTrader__c;
    }

    approvalStatus(): ApprovedStatus {
        if (this.account.AutomaticProvisioning__c && !this.account.IdentityVerificationRequested__c) {
            return "APPROVED";
        }
        return "PENDING";
    }

    isValidPlanOfferCombo(): boolean {
        return this.validPlanOfferCombo;
    }

    getProductName(): SoldoProductName {
        return PRODUCT_BY_PLAN[this.getPlanName()];
    }

    getPlanName(): string {
        return this.lead?.PlanName__c || this.account?.PlanName__c;
    }

    getOfferName(): string {
        return this.lead?.OfferName__c || this.account?.OfferName__c;
    }

    getCompanyCountry(): string {
        return this.lead?.RegisteredAddressCountryISO__c || this.account?.RegisteredAddressCountryISO__c;
    }

    isDirectSalesSegment(): boolean {
        return this.applicationSegment === "Direct";
    }

    getProductMarket(): SoldoProductMarket {
        const countryISO = this.getCompanyCountry();
        if (countryISO === null) {
            return null;
        }

        let productMarket: SoldoProductMarket;

        switch (countryISO) {
            case "ITA":
            case "IRL":
            case "NLD":
            case "DEU":
            case "FRA":
                productMarket = countryISO;
                break;
            case "GIB":
            case "GBR":
                productMarket = "GBR";
                break;
            default:
                productMarket = "ROE";
        }

        return productMarket;
    }

    getCompanyName(): string {
        const companyName = this.lead ? this.lead.Company : this.account.Name;
        return companyName === 'anonymous' ? null : companyName;
    }

    getCompanyType(): string {
        const companyType = this.lead ? this.lead.CompanyType__c : this.account.CompanyType__c;
        return companyType;
    }

    getCompanySize(): number {
        return this.lead ? this.lead.NumberOfEmployees : this.account.NumberOfEmployees;
    }

    getContactEmail(): string {
        if (this.lead) {
            return this.lead.Email;
        } else {
            const primaryLead = this.contacts.find((contact) => contact.ContactIsPrimaryLead__c);
            return primaryLead.Email;
        }
    }

    charityTypeByCountry(countryISO: string): string {
        let charityType = null;
        switch (countryISO) {
            case "GBR":
                charityType = "Charity";
                break;
            case "IRL":
                charityType = "NPO";
                break;
            case "ITA":
                charityType = "OnlusNoProfit";
                break;
        }
        return charityType;
    }

    isAutoCharityDiscountEnabled(): boolean {
        return (this.getCompanyType() === "Charity" || this.getCompanyType() === "OnlusNoProfit" || this.getCompanyType() === "NPO")
            && !this.isCharitySpecialOffer();
    }

    isCharitySpecialOffer(): boolean {
        return this.getOfferName() === "SoldoNonProfit2018";
    }

    checkPlanAndOffer(plan: string, offer: string): boolean {
        const planOfferKey = [plan, offer].join("-");
        return VALID_PLAN_OFFERS.indexOf(planOfferKey) > -1;
    }

    setPlanAndOffer(plan: string, offer: string): void {
        this.validPlanOfferCombo = this.checkPlanAndOffer(plan, offer);

        if (this.account?.SoldoTermsAndConditions__c) {
            // can't override
            return;
        }
        // Always redirect invalid values... but store them temporarily for use in segment events
        if (this.lead) {
            this.lead.PlanName__c = plan;
            this.lead.OfferName__c = offer;
        } else {
            this.account.PlanName__c = plan;
            this.account.OfferName__c = offer;
        }
    }

    initLead(data: Partial<Lead>): Lead {
        this.lead = { ...emptyLeadData, ...data };
        if (!this.lead.LeadSource) {
            this.lead.LeadSource = "Website";
        }

        this.logger.debug("##### LEAD #####", this.lead);
        return this.lead;
    }

    getLead(): Lead {
        return this.lead;
    }

    constructAccountEntity(data: Partial<Account>): Account {
        return { ...emptyAccountData, ...data };
    }

    initAccount(data: Partial<Account>): Account {
        this.account = this.constructAccountEntity(data);

        this.logger.debug("##### ACCOUNT #####", this.account);
        return this.account;
    }

    getAccount(): Account {
        return this.account;
    }

    getEmptyContact(): Contact {
        return { ...emptyContactData };
    }

    initContacts(data: any): Contact[] {
        this.contacts = data;
        this.logger.debug("##### CONTACTS #####", this.contacts);
        return this.contacts;
    }

    getContacts(): Contact[] {
        return this.contacts.map(c => {
            if (c.LastName === DEFAULT_LAST_NAME) {
                c.LastName = "";
            }
            return c;
        });
    }

    getEmoneyLicenseIssuer(): EMoneyLicenseIssuer {
        return this.account?.EMoneyLicenseIssuer__c || null;
    }

    getUBOProvisioningChoice(): UBOProvisioningChoiceType {
        return this.account?.UBOProvisioningChoice__c;
    }

    setGACID(gacid: string) {
        if (this.lead) {
            this.lead.GACID__c = gacid;
        }
        if (this.account) {
            this.account.GACID__c = gacid;
        }
    }

    saveLead(data: Partial<Lead>) {
        try {
            this.lead.ActiveWebExperimentData__c = JSON.stringify(this.cookieService.readExperimentDataCookie());
        } catch (e) {
            this.logger.warn("Couldn't serialize experiment data", e);
        }
        this.lead = { ...this.lead, ...data };
    }


    requestEmailVerificationCode(leadData: Partial<LeadStartData>): Promise<RequestEmailVerificationCodeResponseDTO> {
        const requestObject: RequestEmailVerificationCodeRequestDTO = {
            countryIso3: leadData.companyCountry,
            email: leadData.email,
            firstName: leadData.firstName,
            lastName: leadData.lastName,
            lang: leadData.language
        };
        return this.requestAggregator.sendSingleRequest<RequestEmailVerificationCodeResponseDTO>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'requestEmailVerificationCode',
            args: [requestObject]
        });
    }

    checkEmailVerificationCode(requestObject: CheckVerificationCodeRequestDTO): Promise<CheckVerificationCodeResponseDTO> {
        return this.requestAggregator.sendSingleRequest<CheckVerificationCodeResponseDTO>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'checkEmailVerificationCode',
            args: [requestObject]
        });
    }

    isEligibleForStartupOffer(): boolean {
        return this.getPlanName() === "ProStartup";
    }

    submitLead(): Promise<Lead> {
        const promise = this.requestAggregator.sendSingleRequest<Lead>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'createOrUpdateLead',
            args: [this.lead.SoldoRegistrationExternalID__c, this.lead]
        });
        promise.then(lead => {
            this.logger.debug("Lead submit response: ", lead);
            this.initLead(lead);
            this.applicationDataSubmittedEventEmitter.emit(lead);
        });
        return promise;
    }

    private clearLead(): void {
        this.lead = null;
    }

    eraseAccountRegisteredAddress(): Account {

        this.account.RegisteredAddressCountryISO__c = null;
        this.account.RegisteredAddressLine1__c = null;
        this.account.RegisteredAddressLine2__c = null;
        this.account.RegisteredAddressLine3__c = null;
        this.account.RegisteredAddressCity__c = null;
        this.account.RegisteredAddressState__c = null;
        this.account.RegisteredAddressStreet__c = null;
        this.account.RegisteredAddressSecondaryStreet__c = null;
        this.account.RegisteredAddressHouseName__c = null;
        this.account.RegisteredAddressHouseNumber__c = null;
        this.account.RegisteredAddressSubBuilding__c = null;
        this.account.RegisteredAddressPoBox__c = null;
        this.account.RegisteredAddressPostalCode__c = null;
        this.account.RegisteredAddressServiceRefCode__c = null;

        return this.account;
    }

    saveAccount(data: Partial<Account>) {
        try {
            this.account.ActiveWebExperimentData__c = JSON.stringify(this.cookieService.readExperimentDataCookie());
        } catch (e) {
            this.logger.warn("Couldn't serialize experiment data", e);
        }
        this.account = { ...this.account, ...data };
        this.logger.debug("this.account = ", this.account);
    }

    submitAccount(): Promise<Account> {
        return this.requestAggregator.sendSingleRequest<Account>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'createOrUpdateAccount',
            args: [this.account.SoldoRegistrationExternalID__c, this.account]
        }).then(account => {
            this.logger.debug("Account submit response: ", account);
            this.initAccount(account);
            this.applicationDataSubmittedEventEmitter.emit(account);
            return account;
        });
    }

    submitAccountForFSConvert(): Promise<Contact[]> {
        return this.requestAggregator.sendSingleRequest<Account>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'createOrUpdateAccount',
            args: [this.account.SoldoRegistrationExternalID__c, this.account]
        }).then(account => {
            this.logger.debug("Account submit response: ", account);
            this.initAccount(account);
            this.clearLead();
            this.applicationDataSubmittedEventEmitter.emit(account);
            return this.loadContacts().then(contacts => {
                this.applicationDataSubmittedEventEmitter.emit(contacts);
                return contacts;
            });
        });
    }

    saveContacts(contacts: ContactOperation[]) {
        this.contacts = contacts as Contact[]; // this is ugly, but necessary
    }

    loadContacts(): Promise<Contact[]> {
        const promise = this.requestAggregator.sendSingleRequest<Contact[]>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'getContacts',
            args: [this.account.SoldoRegistrationExternalID__c]
        });
        promise.then(contacts => {
            this.initContacts(contacts);
        });
        return promise;
    }

    submitContacts(): Promise<Contact[]> {
        const promise = this.requestAggregator.sendSingleRequest<Contact[]>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'createOrUpdateContacts',
            args: [this.account.SoldoRegistrationExternalID__c, { 'contacts': this.contacts }]
        });
        promise.then((contacts) => {
            this.logger.debug('Contacts submit response ', contacts);
            this.initContacts(contacts);
            this.applicationDataSubmittedEventEmitter.emit(contacts);
        });
        return promise;
    }

    requestUUID(): Promise<string> {
        return this.requestAggregator.sendSingleRequest<string>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'generateRegistrationExternalId',
            args: []
        });
    }

    completeApplication(): Promise<Application> {
        const promise = this.requestAggregator.sendSingleRequest<Application>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'completeBusinessApplication',
            args: [this.account.SoldoRegistrationExternalID__c, this.account]
        });
        promise.then((response: Application) => {
            this.logger.debug("Account submit response: ", response);
            this.initAccount(response.account);
            this.applicationDataSubmittedEventEmitter.emit(response.account);

            if (response.complianceStatus) {
                this.complianceStatusContext = response.complianceStatus;
                if (response.complianceStatus.docUploadMetadataList?.length) {
                    this.initDocUploadData(response.complianceStatus.docUploadMetadataList);
                }
            }

            this.logger.debug("Compliance context ", this.complianceStatusContext);
            this.logger.debug("Documents required ", this.kycDocUploadData);

            this.eKYCResultEventEmitter.next(response);
            this.statusAdvancedEventEmitter.next(response.status);
        });
        return promise;
    }

    loadComplianceStatusContext(registrationId: string): Promise<ComplianceStatusContext> {
        return this.requestAggregator.sendSingleRequest<ComplianceStatusContext>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'getComplianceStatus',
            args: [registrationId]
        });
    }

    setIdvData(idvData: IDVData): void {
        this.idvData = idvData;
    }

    getIdvData(): IDVData {
        return this.idvData;
    }

    loadIDVData(): Promise<IDVData> {
        const POLLING_INTERVAL_MS = 1000;
        const POLLING_TIMEOUT_MS = 5000;

        const idvData = this.getIdvData();
        if (idvData?.provider) {
            return Promise.resolve(idvData);
        }

        if (this.getAccount() === null) {
            return Promise.reject(new Error("Account object not found"));
        }

        const registrationId = this.getAccount().SoldoRegistrationExternalID__c;

        return interval(POLLING_INTERVAL_MS).pipe(
            mergeMap(() => {
                return this.loadComplianceStatusContext(registrationId);
            }, 1),
            filter(complianceStatus => {
                return complianceStatus?.identityVerificationMetadataList?.some(idvc => {
                    return idvc.providerName && (idvc.workflowRunId || idvc.hooYuRequestLink);
                });
            }),
            take(1),
            timeout(POLLING_TIMEOUT_MS)
        ).toPromise().then(complianceStatus => {
            this.initComplianceStatusVariables(complianceStatus, this.getContacts());
            return this.getIdvData();
        });
    }

    getIdvSession(personExternalId: string): Promise<IDVSessionDetails> {
        return this.registrationApiService.getIdvSession(personExternalId);
    }

    postUserIdvData(userKycSessionId: string, data: UserIDVDetails): Promise<string> {
        return this.registrationApiService.postUserIdvDetails(data, userKycSessionId);
    }

    private initDocUploadData(docMeta: EKYCRequiredDocumentsListElement[]): void {
        this.kycDocUploadData = [];

        for (const requiredDoc of docMeta) {
            let person = this.kycDocUploadData.find(p => {
                return p.businessOnboardingPersonId == requiredDoc.personId;
            });

            if (!person) {
                person = {
                    businessOnboardingPersonId: requiredDoc.personId,
                    name: [requiredDoc.firstName, requiredDoc.lastName].join(" "),
                    requiredDocuments: []
                };
                this.kycDocUploadData.push(person);
            }

            person.requiredDocuments.push({
                applicationId: requiredDoc.applicationId,
                proofId: requiredDoc.proofId,
                proofType: requiredDoc.proofType,
                personId: person.businessOnboardingPersonId,
                fileUploads: [
                    {
                        label: "front",
                        fileName: null, //Object.keys(requiredDoc.presignedUrl)[0],
                        preSignedURL: null //requiredDoc.presignedUrl[Object.keys(requiredDoc.presignedUrl)[0]]
                    },
                    {
                        label: "back",
                        fileName: null, //Object.keys(requiredDoc.presignedUrl)[1],
                        preSignedURL: null //requiredDoc.presignedUrl[Object.keys(requiredDoc.presignedUrl)[1]]
                    }
                ]
            });
        }

    }

    getComplianceContext(): ComplianceStatusContext {
        return this.complianceStatusContext;
    }

    getComplianceStatus(): ComplianceStatus {
        return this.complianceStatusContext?.status;
    }

    getKYCDocUploadData(): KYCDocRequest[] {
        return this.kycDocUploadData || [];
    }

    requestAWSS3PresignedUploadURL(document: DocUploadMetadata, file: DocUploadFile): Observable<UploadPresignedURLResponse> {

        const uploadPresignedUrlRequest = this.requestAggregator
            .createMethodRequest<UploadPresignedURLResponse>('soldoSalesforceRestService', "prepareUploadAttachment");

        const responseObservable = uploadPresignedUrlRequest.callMethod({
            fileName: file.fileName,
            contentType: file.contentType,
            applicationId: document.applicationId,
            proofId: document.proofId,
            proofType: document.proofType
        });
        this.requestAggregator.addRequest(uploadPresignedUrlRequest);

        this.requestAggregator.sendCurrentAggegatedRequest();

        return responseObservable;
    }

    completeProofUpload(document: DocUploadMetadata): Promise<CompleteProofResponse> {
        const attachmentsData = document.fileUploads
            .filter(upload => upload.httpUploadStatus.status === 200)
            .map(upload => ({
                applicationId: document.applicationId,
                proofId: document.proofId,
                proofType: document.proofType,
                fileName: upload.fileName,
                contentType: upload.contentType
            }));
        return this.requestAggregator.sendSingleRequest<CompleteProofResponse>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'completeProofUpload',
            args: [{
                applicationId: document.applicationId,
                proofId: document.proofId,
                personId: document.personId,
                proofType: document.proofType,
                proofDocumentType: document.docType,
                attachmentMetadataList: attachmentsData
            }]
        });
    }

    confirmDocumentUpload(docsMeta: EKYCDocumentUploadedData[]): Observable<boolean> {
        for (const docMeta of docsMeta) {
            const docUploadedRequest = this.requestAggregator
                .createMethodRequest<EKYCRequiredDocumentsListElement>('soldoSalesforceRestService', "completeUploadAttachment");
            docUploadedRequest.callMethod(docMeta);
            this.requestAggregator.addRequest(docUploadedRequest, docMeta.fileName);
        }

        const responseObservable = this.requestAggregator.sendCurrentAggegatedRequest();

        return responseObservable.pipe(map((response: { [key: string]: EKYCRequiredDocumentsListElement }) => {

            this.logger.log("methods response", response);

            let allConfirmed = true;

            for (const docMeta of docsMeta) {
                const docResponse = response[docMeta.fileName];
                if (docResponse?.uploadStatus) {

                    const localDoc = this.kycDocUploadData.find(person => {
                        return person.businessOnboardingPersonId == docResponse.personId;
                    }).requiredDocuments.find(docRequest => {
                        return docRequest.proofId == docMeta.proofId && docRequest.proofType == docMeta.proofType;
                    });

                    switch (docResponse.uploadStatus) {
                        case "COMPLETED":
                            localDoc.uploadStatus = "CONFIRMED";
                            break;
                        case "PENDING":
                        default:
                            allConfirmed = false;
                            localDoc.uploadStatus = "ERROR";
                            this.logger.warn("Document upload wasn't confirmed", docResponse);
                    }
                }
            }

            return allConfirmed;
        }));
    }

    setCompanySearchLookupProfile(profile: CompanyLookupResult): void {
        this.logger.debug(`Setting search profile`, profile);
        this.selectedCompanySearchProfile = profile;
    }

    getCompanySearchLookupProfile(kybProviderId: string): CompanyLookupResult {
        if (this.selectedCompanySearchProfile && this.selectedCompanySearchProfile.kybProviderId == kybProviderId) {
            this.logger.debug('retrieving search profile', kybProviderId, this.selectedCompanySearchProfile);
            return this.selectedCompanySearchProfile;
        } else {
            return null;
        }
    }

    generateOnfidoSdkToken(workflowRunId: string): Promise<string> {
        return this.requestAggregator.sendSingleRequest<string>({
            serviceName: RegistrationDataService.SERVICE_NAME,
            serviceMethod: 'generateOnfidoSdkToken',
            args: [workflowRunId]
        });
    }
}
