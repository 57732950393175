import { NgModule } from '@angular/core';
import { Route, RouterModule, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [],
            {
                useHash: true,
                enableTracing: false
            }
        )
    ],
    exports: [RouterModule],
})

export class AppRoutingModule { }
