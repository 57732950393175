<h3 class="title">{{'REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.TITLE' | translate}}</h3>
<p class="subtitle">{{'REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.SUB_TITLE' | translate}}</p>


<form [formGroup]="form" novalidate autocomplete="off">

  <soldo-field-layout
    class="chips-radio"
    [control]="form.controls['isInternational']"
    fieldName="isInternational"
    cypressSelector="Company_RadioButton_InternationalBusiness">

    <ng-container input>
      <div class="radio-item" [attr.data-cy]="'Company_RadioButton_InternationalBusiness_DOMESTIC'">
        <input type="radio" formControlName="isInternational" id="optionDomesticOnly" [value]="false">
        <label for="optionDomesticOnly">{{ "REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.OPTION_DOMESTIC_LABEL" | translate }}</label>
      </div>

      <div class="radio-item" [attr.data-cy]="'Company_RadioButton_InternationalBusiness_INTERNATIONAL'">
        <input type="radio" formControlName="isInternational" id="optionInternational" [value]="true">
        <label for="optionInternational">{{ "REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.OPTION_INTERNATIONAL_LABEL" | translate }}</label>
      </div>
    </ng-container>

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.ERROR_SELECTION_REQUIRED' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <div class="main-international-country" *ngIf="form.controls['primaryInternationalMarket']">
    <div class="left-spacer"></div>

    <div class="main-country-controls">

      <soldo-field-layout
        [control]="form.controls['primaryInternationalMarket']"
        fieldName="primaryInternationalMarket"
        [required]="true"
        cypressSelector="Company_NgSelect_PrimaryInternationalMarket"
        label="REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.MAIN_INT_COUNTRY_LABEL">

        <ng-select input
          formControlName="primaryInternationalMarket"
          [items]="internationalCountriesList" dropdownPosition="bottom"
          notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
          [clearable]=false bindLabel="name" bindValue="alpha_3"
          placeholder="{{ 'REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.MAIN_INT_COUNTRY_PLACEHOLDER' | translate }}"
          name="primaryInternationalMarket">
        </ng-select>

        <ng-container errorMessage>
          <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        </ng-container>
      </soldo-field-layout>

      <div class="help-box">
        <p>{{ 'REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.INT_COUNTRY_HELP_PARAGRAPH' | translate }}</p>
      </div>

    </div>

  </div>

</form>


<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading"
      data-cy="Signup_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.COMPANY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.COMPANY_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
