import { Component, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { Account } from '@shared/models/registration.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { CompanySubStepOutboundAction, CompanySubStepType } from '../../company-step.model';
import { SELECT_INVALID_SOLDO_FIELD, scrollToFirstResult } from '../../../../shared/formHelpers';

@Component({
    selector: 'substep-company-registered-address',
    templateUrl: './company-registered-address.component.html',
    styleUrls: ['./company-registered-address.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CompanyRegisteredAddressComponent extends CompanySubStepType {
    subStepName: string = "Company Registered Address";

    loading: boolean = true;

    form: UntypedFormGroup;
    selectedCountry: string = null;

    constructor(
        private fb: UntypedFormBuilder,
        private registrationDataService: RegistrationDataService,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService) {

        super();
        this.controlActionEventEmitter = new EventEmitter<CompanySubStepOutboundAction>();
    }

    init(d: Partial<Account>): void {
        this.selectedCountry = d.RegisteredAddressCountryISO__c;

        this.form = this.fb.group({
            idCheckAddressLookupAddressCode: [d.RegisteredAddressServiceRefCode__c ? d.RegisteredAddressServiceRefCode__c : null],
            line1: [d.RegisteredAddressLine1__c],
            line2: [d.RegisteredAddressLine2__c],
            line3: [d.RegisteredAddressLine3__c ? d.RegisteredAddressLine3__c : ''],
            city: [d.RegisteredAddressCity__c],
            buildingName: [d.RegisteredAddressHouseName__c],
            buildingNumber: [d.RegisteredAddressHouseNumber__c],
            poBoxNumber: [d.RegisteredAddressPoBox__c],
            street: [d.RegisteredAddressStreet__c],
            secondaryStreet: [d.RegisteredAddressSecondaryStreet__c],
            subBuilding: [d.RegisteredAddressSubBuilding__c],
            postcode: [d.RegisteredAddressPostalCode__c],
            country: [{ value: d.RegisteredAddressCountryISO__c, disabled: true }],
            state: [d.RegisteredAddressState__c]
        });

        this.loading = false;
    }

    isValid(): boolean {
        this.form.markAllAsTouched();
        return this.form.valid;
    }

    getData(): Partial<Account> {
        const formValues = this.form.value;

        return {
            RegisteredAddressLine1__c: formValues['line1'],
            RegisteredAddressLine2__c: formValues['line2'],
            RegisteredAddressLine3__c: formValues['line3'],
            RegisteredAddressPostalCode__c: formValues['postcode'],
            RegisteredAddressCity__c: formValues['city'],
            RegisteredAddressCountryISO__c: this.selectedCountry,
            RegisteredAddressState__c: formValues['state'],
            RegisteredAddressServiceRefCode__c: formValues['idCheckAddressLookupAddressCode'],
            RegisteredAddressHouseName__c: formValues['buildingName'],
            RegisteredAddressHouseNumber__c: formValues['buildingNumber'],
            RegisteredAddressPoBox__c: formValues['poBoxNumber'],
            RegisteredAddressStreet__c: formValues['street'],
            RegisteredAddressSecondaryStreet__c: formValues['secondaryStreet'],
            RegisteredAddressSubBuilding__c: formValues['subBuilding']
        };
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        } else {
            scrollToFirstResult(SELECT_INVALID_SOLDO_FIELD);
        }
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

}
