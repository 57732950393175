import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DocUploadComponent } from './doc-upload/doc-upload.component';

export const successRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
    {
        path: 'docupload',
        data: {
            pageName: "Document Upload"
        },
        component: DocUploadComponent
    }
]);
