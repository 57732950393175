<header>
  <h3 class="title">{{ "REGISTRATION.BENEFICIAL_OWNER_CHOICE.TITLE" | translate }}</h3>
  <p>{{ "REGISTRATION.BENEFICIAL_OWNER_CHOICE.DESCRIPTION_1" | translate }}</p>
  <p>{{ "REGISTRATION.BENEFICIAL_OWNER_CHOICE.DESCRIPTION_2" | translate }}</p>


  <button data-cy="UBO_Button_WhatIsUBO" type="button" (click)="openUBOHelpDialog()"
    class="btn btn-link what-is-ubo-button">
    {{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.WHAT_IS_UBO' | translate}}
  </button>

</header>

<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">

  <soldo-field-layout
    [control]="form.controls.uboMode"
    fieldName="uboMode"
    [required]="true"
    cypressSelector="UBO_RadioButton_Mode">

      <div input class="radio-blocks">

        <input type="radio" formControlName="uboMode" name="uboMode"
            id="EnterNowMode" value="ONLINE" #enterNowModeRadio>
        <div class="radio-block-item" data-cy="UBO_RadioButton_EnterNowMode"
          interactive
          (interact)="enterNowModeRadio.click()">

          <h5>{{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_ENTER_NOW.TITLE' | translate}}</h5>
          <p>{{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_ENTER_NOW.DESCRIPTION' | translate}}</p>

        </div>

        <input type="radio" formControlName="uboMode" name="uboMode"
            id="EmailMode" value="EMAIL" #emailModeRadio>
        <div class="radio-block-item" data-cy="UBO_RadioButton_EmailMode"
          interactive
          (interact)="emailModeRadio.click()">

          <h5>{{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_EMAIL.TITLE' | translate}}</h5>
          <p *ngIf="countryOfIncorporation !== 'ITA'">
            {{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_EMAIL.DESCRIPTION' | translate}}
          </p>
          <p *ngIf="countryOfIncorporation === 'ITA'">
            {{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_EMAIL.DESCRIPTION_ITA' | translate}}
          </p>

        </div>

        <input type="radio" formControlName="uboMode" name="uboMode"
            id="SkipMode" value="NONE" #skipModeRadio>
        <div class="radio-block-item" data-cy="UBO_RadioButton_SkipMode"
          interactive
          (interact)="skipModeRadio.click()">

          <h5>{{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_SKIP.TITLE' | translate}}</h5>
          <p>{{'REGISTRATION.BENEFICIAL_OWNER_CHOICE.MODE_SKIP.DESCRIPTION' | translate}}</p>

        </div>

      </div>

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <div class="row mt-5 page-actions">
      <div class="col-6">
          <button type="button" class="btn gray-text go-back" (click)="goToPrevious()"
            data-cy="UBO_Button_StepBackButton"><i class="md-icon">arrow_right_alt</i>
            <span>{{ 'PREVIOUS' | translate }}</span>
          </button>
      </div>
      <div class="col-6 text-right">
          <button type="submit" class="btn btn-primary" [disabled]="loading" [ngClass]="{ 'in-progress': loading}"
            data-cy="UBO_Button_StepSubmitButton">{{ 'NEXT' | translate }}</button>
      </div>
  </div>

</form>

<save-and-continue-later stepName="Ultimate Beneficial Owner"></save-and-continue-later>
