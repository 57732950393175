import { EventEmitter, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";

export type AcceptedLanguage = 'it' | 'en' | 'nl' | 'fr';

export interface LanguageSelectionControlEvent {
    selectionAllowed: boolean
};

@Injectable()
export class I18nService {
    public static COOKIE_NAME = 'registration-lang';

    public languageSelectionControlEmitter: EventEmitter<LanguageSelectionControlEvent> = new EventEmitter();

    private _lang: AcceptedLanguage = 'en';

    constructor(
        private translate: TranslateService,
        private cookieService: CookieService
    ) {

        this.translate.setDefaultLang("en");

        let lang = this._lang;
        if (this.cookieService.check(I18nService.COOKIE_NAME)) {
            lang = this.cookieService.get(I18nService.COOKIE_NAME) as AcceptedLanguage;
        } else {
            lang = this.translate.getBrowserLang().substring(0, 2) as AcceptedLanguage;
        }

        if (this.getValidLanguages().indexOf(lang) > -1) {
            this.switchTo(lang);
        } else {
            this.switchTo("en");
        }
    }

    getValidLanguages(): string[] {
        return ['en', 'it', 'nl', 'fr'];
    }

    switchTo(lang: AcceptedLanguage) {
        this._lang = lang;
        this.translate.use(lang);
        this.cookieService.set(I18nService.COOKIE_NAME, lang);
    }

    get lang(): AcceptedLanguage {
        return this._lang;
    }

    languagetToCountry(lang: AcceptedLanguage): string {
        const langToISO = {
            it: "ITA",
            en: "GBR",
            fr: "FRA",
            nl: "NLD",
        };
        const country = langToISO[lang];
        return country || "ROE";
    }

    turnOffLanguageSelection(): void {
        this.languageSelectionControlEmitter.emit({
            selectionAllowed: false
        });
    }

    turnOnLanguageSelection(): void {
        this.languageSelectionControlEmitter.emit({
            selectionAllowed: true
        });
    }
}
