import { EventEmitter } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import { LEAD_DEFAULT_FIELD_LENGTH, Lead } from "./registration.model";

export enum DefaultOutboundActions {
    NEXT = "NEXT",
    PREVIOUS = "PREVIOUS"
}
export interface OutboundAction<A, D> {
    action: A;
    data: Partial<D>;
}
export abstract class SubStepComponentType<A, D> {

    abstract subStepName: string;

    controlActionEventEmitter: EventEmitter<OutboundAction<A,D>>;
    loading: boolean;

    leadMaxlengthValidator(field: keyof Lead): ValidatorFn {
        return Validators.maxLength(LEAD_DEFAULT_FIELD_LENGTH[field] || 255);
    }

    isValid(): boolean {
        return false;
    }

    abstract init(d: Partial<D>): void;
    abstract getData(): Partial<D>;

    abstract trackSubStepViewed(): void;
    abstract trackSubStepCompleted(): void;
}
