import { Component, OnInit, Input, ElementRef, Output, EventEmitter, HostListener, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { UkAddressLookupService, AddressEntry, UkAddress } from '../../services/uk-address-lookup.service';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'soldo-uk-address-search',
    templateUrl: './soldo-uk-address-search.component.html',
    styleUrls: ['./soldo-uk-address-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SoldoUkAddressSearch implements OnInit {

    @Input()
    controlName: string = "postcode";

    @Input('group')
    public addressForm: UntypedFormGroup;

    @Output()
    queryResults: EventEmitter<AddressEntry[]> = new EventEmitter<AddressEntry[]>();

    @Output()
    resultSelected: EventEmitter<UkAddress> = new EventEmitter<UkAddress>();

    @Input("required")
    isRequired: boolean = true;

    searchControl: UntypedFormControl;

    addressEntries: Array<AddressEntry> = [];

    showDropdown: boolean = false;

    private _value = null;

    @HostListener('document:click', ['$event'])
    clickedOutside($event) {
        if (!this._elRef.nativeElement.contains($event.target)) {
            this.addressEntries = [];
        }
    }

    constructor(
        private _elRef: ElementRef,
        private logger: NGXLogger,
        private ukAddressLookupService: UkAddressLookupService) { }

    get value(): UkAddress {
        return this._value;
    }

    set value(selectedValue: UkAddress) {
        this._value = selectedValue;
    }

    onSearchButtonClicked() {
        if (this.searchControl.valid) {
            this.addressEntries = [];
            this.ukAddressLookupService.search(this.searchControl.value).then((result) => {
                if (result.result === "RESULT_FOUND") {
                    this.addressEntries = result.addressEntries;
                    this.queryResults.emit(this.addressEntries);
                } else {
                    this.queryResults.emit(null);
                }
                this.showDropdown = true;
            });
        }
    }

    selectAddress(address: AddressEntry) {
        this.showDropdown = false;
        this.ukAddressLookupService.getDetails(address.idCheckAddressLookupAddressCode)
            .then((response) => {
                this.value = response;
                this.value.idCheckAddressLookupAddressCode = address.idCheckAddressLookupAddressCode;
                this.resultSelected.emit(this.value);
            });
    }

    ngOnInit() {
        this.searchControl = <UntypedFormControl>this.addressForm.get(this.controlName);
    }
}
