<h2>{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.DEPOSIT.TITLE' | translate}}</h2>
<div class="tip-box caption">
  {{'REGISTRATION.EXPECTED_ACTIVITY_STEP.DEPOSIT.TIP_TEXT' | translate}}
</div>

<div class="layout-section">
  <form [formGroup]="form" novalidate>
    <h5>{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.DEPOSIT.LABEL' | translate}}</h5>
    <div class="chips-radio form-input" [ngClass]="{ 'ng-touched': form.touched }">
      <fieldset [attr.data-cy]="'Expected_RadioButton_ExternalDeposit'" id="externalDepositFieldset">
        <div *ngFor="let option of externalDepositOptions; let i = index" class="radio-item"
          [attr.data-cy]="'Expected_RadioButton_ExternalDeposit_'+option.value">

          <input formControlName="externalDeposit" type="radio" [id]="'externalDepositOption'+i" [value]="option.value">
          <label [for]="'externalDepositOption'+i">{{option.label | translate}}</label>

          <div class="conditional-subfield-container" *ngIf="option.requireDescription && form.controls['externalDeposit'].value === option.value">
            <soldo-field-layout
            [control]="form.controls['description']"
            label="REGISTRATION.EXPECTED_ACTIVITY_STEP.DEPOSIT.DESCRIPTION_LABEL"
            cypressSelector="Expected_RadioButton_ExternalDeposit_Description"
            fieldName="description">
            <textarea input
                name="description"
                formControlName="description"
                type="text"></textarea>
              <ng-container errorMessage>
                <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
                <soldo-field-error error errorName="maxlength">
                  {{ 'MAX_LENGHT' | translate: { value: form.controls.description.errors?.maxlength?.requiredLength } }}
                </soldo-field-error>
              </ng-container>
            </soldo-field-layout>
          </div>

        </div>
      </fieldset>
      <div class="validation-errors-container">
        <div class="caption field-validation-error" *ngIf="form?.controls.externalDeposit?.errors?.required">
          {{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.DEPOSIT.RANGE_REQUIRED' | translate }}
        </div>
      </div>
    </div>


    <div class="form-page-CTA-row">
      <button type="button" (click)="previousStep()" data-cy="Expected_Button_PreviousStepButton">
        {{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
      </button>
      <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}"
      (click)="nextStep()" data-cy="Expected_Button_NextStepButton">{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.NEXT_SUB_STEP_BUTTON' | translate }}</button>
    </div>
  </form>
</div>
