import { HttpClient, HttpContext, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, AppConfig } from "@src/app/app-config.module";
import { SoldoCookieService } from "./soldo-cookie.service";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { IDVSessionDetails, UserIDVDetails } from "../models/registration.model";

export interface HttpOptions {
    headers?: HttpHeaders;
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
}

@Injectable({
    providedIn: 'root'
})
export class RegistrationApiService {

    constructor(
        private readonly httpClient: HttpClient,
        private readonly cookieService: SoldoCookieService,
        @Inject(APP_CONFIG) private readonly appConfig: AppConfig
    ) { }


    private post<T, K>(method: string, body: K, options?: HttpOptions): Observable<T> {
        return this.httpClient.post<T>(`${this.appConfig.apiGatewayEndpoint}/${method}`, body, options);
    }

    private postWithAuth<T, K>(method: string, body: K, options?: HttpOptions): Observable<T> {
        return this.post<T, K>(method, body, this.setAuthorization(options));
    }

    private put<T, K>(method: string, body: K, options?: HttpOptions): Observable<T> {
        return this.httpClient.put<T>(`${this.appConfig.apiGatewayEndpoint}/${method}`, body, options);
    }

    private putWithAuth<T, K>(method: string, body: K, options?: HttpOptions): Observable<T> {
        return this.put<T, K>(method, body, this.setAuthorization(options));
    }

    private get<T>(method: string, options?: HttpOptions): Observable<T> {
        return this.httpClient.get<T>(`${this.appConfig.apiGatewayEndpoint}/${method}`, options);
    }

    private getWithAuth<T>(method: string, options?: HttpOptions): Observable<T> {
        return this.get<T>(method, this.setAuthorization(options));
    }

    private delete<T, K>(method: string, body?: K, options?: HttpOptions): Observable<T> {
        let opt = options;
        if (body) {
            if (opt) {
                opt.body = body;
            } else {
                opt = { body: body };
            }
        }
        return this.httpClient.delete<T>(method, opt);
    }

    private deleteWithAuth<T, K>(method: string, body?: K, options?: HttpOptions): Observable<T> {
        return this.delete<T, K>(method, body, this.setAuthorization(options));
    }

    private setAuthorization(options: HttpOptions) {
        if (options) {
            if (options.headers) {
                options.headers = options.headers
                    .set('Authorization', `Bearer ${this.cookieService.readJWT()}`);
            } else {
                options.headers = new HttpHeaders({
                    Authorization: `Bearer ${this.cookieService.readJWT()}`
                });
            }
        } else {
            return {
                headers: new HttpHeaders({
                    Authorization: `Bearer ${this.cookieService.readJWT()}`
                })
            };
        }
        return options;
    }

    getIdvSession(personExternalId: string): Promise<IDVSessionDetails> {
        return this.getWithAuth<IDVSessionDetails>(`/user/idv/info/${personExternalId}`)
            .pipe(
                take(1)
            ).toPromise();
    }

    postUserIdvDetails(data: UserIDVDetails, userKycRequestId: string): Promise<string> {
        return this.postWithAuth<string, UserIDVDetails>(`/user/idv/submit/${userKycRequestId}`, data, {
            responseType: "text" as 'json'
        })
            .pipe(
                take(1)
            ).toPromise();
    }
}
