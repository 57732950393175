<div [formGroup]="personForm">
  <div class="layout-section">

    <soldo-field-layout
      [control]="personForm.controls['firstName']"
      fieldName="firstName"
      cypressSelector="Contact_TextInput_FirstName"
      label="REGISTRATION.PERSON.FIRST_NAME_LABEL">

      <input input
        formControlName="firstName"
        type="text"
        name="firstName"
        [readOnly]="readonly"
        placeholder="{{ 'REGISTRATION.PERSON.FIRST_NAME_PLACEHOLDER' | translate }}" />

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="firstNameCfMismatch">{{ 'FIRST_NAME_CF_MISMATCH' | translate }}
        </soldo-field-error>
        <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value:
          personForm.get('firstName').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout
      [control]="personForm.controls['lastName']"
      fieldName="lastName"
      cypressSelector="Contact_TextInput_LastName"
      label="REGISTRATION.PERSON.LAST_NAME_LABEL">

      <input input
        formControlName="lastName"
        type="text" name="lastName"
        [readOnly]="readonly"
        placeholder="{{ 'REGISTRATION.PERSON.LAST_NAME_PLACEHOLDER' | translate }}" />

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="lastNameCfMismatch">{{ 'LAST_NAME_CF_MISMATCH' | translate }}
        </soldo-field-error>
        <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value:
          personForm.get('lastName').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout
      [control]="personForm.controls['citizenship']"
      fieldName="citizenship"
      cypressSelector="Contact_NgSelect_Citizenship"
      label="REGISTRATION.PERSON.CITIZENSHIP_LABEL">

      <ng-select input *ngIf="!readonly"
        formControlName="citizenship"
        [items]="completeCountryList"  dropdownposition="bottom"
        notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
        [clearable]=false bindLabel="name" bindValue="alpha_3"
        placeholder="{{ 'REGISTRATION.PERSON.CITIZENSHIP_PLACEHOLDER' | translate }}"
        name="citizenship">
      </ng-select>
      <ng-container *ngIf="readonly" input>
        <input type="text" name="citizenship_label" [value]="getCitizenshipLabel()" readonly>
        <input type="hidden" formControlName="citizenship" name="citizenship">
      </ng-container>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout *ngIf="!personForm.get('fiscalCode')"
      [control]="personForm.controls['dob']"
      fieldName="dob"
      cypressSelector="Contact_TextInput_DateOfBirth"
      label="REGISTRATION.PERSON.DOB_LABEL">

      <input input
        formControlName="dob"
        type="text"
        [readOnly]="readonly"
        mask="00/00/0000"
        [validation]="false"
        [specialCharacters]="[ '/' ]"
        [dropSpecialCharacters]="false"
        placeholder="{{ 'REGISTRATION.PERSON.DOB_PLACEHOLDER' | translate }}" />

      <ng-container errorMessage>
        <soldo-field-error errorName="required">
          {{ 'REQUIRED' | translate }}
        </soldo-field-error>
        <soldo-field-error *ngIf="!personForm.get('dob').errors?.required" errorName="invalidDateFormat">
          {{ 'DATE_FORMAT_ERROR' | translate }}
        </soldo-field-error>
        <soldo-field-error errorName="ageNotAllowed">
          {{ 'DATE_AGE_NOT_ALLOWED' | translate }}
        </soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <ng-container *ngIf="personForm.get('fiscalCode')">
      <soldo-field-layout
        [control]="personForm.controls['fiscalCode']"
        fieldName="fiscalCode"
        cypressSelector="Contact_TextInput_CodiceFiscale"
        label="REGISTRATION.PERSON.FISCAL_CODE_LABEL">

          <input input formControlName="fiscalCode"
            name="fiscalCode"
            [readOnly]="readonly"
            placeholder="{{ 'REGISTRATION.PERSON.FISCAL_CODE_PLACEHOLDER' | translate }}"
            type="text" #input (input)="input.value=$event.target.value.toUpperCase()">

          <ng-container errorMessage>
            <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
            <soldo-field-error error errorName="wrongFormat">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
            <soldo-field-error error errorName="duplicated">{{ 'DUPLICATED_CF' | translate }}</soldo-field-error>
            <soldo-field-error error errorName="ageNotAllowed">{{ 'DATE_AGE_NOT_ALLOWED' | translate }}</soldo-field-error>
          </ng-container>
      </soldo-field-layout>

      <input formControlName="dob" type="hidden" >
      <input type="hidden" formControlName="birthPlace" >
      <input type="hidden" formControlName="birthState" >
      <input type="hidden" formControlName="gender">
    </ng-container>

    <soldo-field-layout
      [control]="personForm.controls['email']"
      fieldName="email"
      cypressSelector="Contact_TextInput_Email"
      label="REGISTRATION.PERSON.WORK_EMAIL_LABEL"
      [hint]="!readonly && market === 'ITA' ? 'REGISTRATION.PERSON.EMAIL_INFO' : ''"
      [useTooltip]="true">

      <input input
        trim="all"
        formControlName="email"
        type="text"
        name="email"
        [readOnly]="readonly"
        placeholder="{{ 'REGISTRATION.PERSON.EMAIL_PLACEHOLDER' | translate }}"/>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="invalidEmailFormat">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value: personForm.get('email').errors?.maxlength?.requiredLength} }}</soldo-field-error>
        <soldo-field-error error errorName="notYourEmail">{{ 'NOT_YOUR_EMAIL' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="duplicated">{{ 'DUPLICATED_EMAIL' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="noDirectorsEmail">{{ 'NO_DIRECTOR_EMAIL_REUSE' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="noDuplicatedEmail">{{ 'DUPLICATED_EMAIL' | translate }}</soldo-field-error>

      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout
      [control]="personForm.controls['phone']"
      fieldName="phone"
      cypressSelector="Contact_TextInput_MobileNumber"
      label="REGISTRATION.PERSON.PHONE_LABEL"
      [hint]="!readonly ? 'REGISTRATION.PERSON.PHONE_INFO' : ''"
      [useTooltip]="true">

      <div input *ngIf="!readonly"
        class="phone-input" formGroupName="phone"
        [ngClass]="{'readonly': readonly}">
        <ng-select
          [items]="completeCountryList" dropdownposition="bottom"
          notFoundText="{{'NO_ITEMS_FOUND' | translate }}" class="" (keydown)="onKeyDown($event)" [clearable]="false"
          bindLabel="icc" bindValue="icc" placeholder="{{ 'REGISTRATION.PERSON.ICC_PLACEHOLDER' | translate }}"
          name="prefix" formControlName="prefix" data-cy="MobileNumber_NgSelect_ICC">
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <span class="ng-option-label">
              <span class="icc">{{item.icc}}</span><span class="border-left">{{item.name}}</span>
            </span>
          </ng-template>
        </ng-select>
        <input #mobileNumberTextField
          formControlName="number"
          type="text" name="number"
          [readOnly]="readonly"
          placeholder="{{ 'REGISTRATION.PERSON.PHONE_PLACEHOLDER' | translate }}"
          data-cy="MobileNumber_TextInput_Number" />
      </div>

      <ng-container input formGroupName="phone" *ngIf="readonly">
        <input
          [value]="getPhoneValue()"
          type="text" name="number_label"
          readOnly />
        <input type="hidden" name="prefix" formControlName="prefix">
        <input type="hidden" name="number" formControlName="number">
      </ng-container>

      <ng-container errorMessage >

        <soldo-field-error error errorName="prefix-required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="number-required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="notValidNumber">{{ 'NOT_VALID_PHONE' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="duplicated">{{ 'DUPLICATED_PHONE' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>
  </div>

  <div class="layout-section">

    <h5><strong>{{ 'REGISTRATION.PERSON.ADDRESS' | translate }}</strong></h5>

    <address2-form-group [readonly]="readonly" [group]="personForm.get('address')" data-cy="Contact_Address_ResidentialAddress">
    </address2-form-group>
  </div>

  <div class="layout-section">
    <soldo-field-layout *ngIf="personForm.get('isFatcaSubject')"
      [control]="personForm.controls['isFatcaSubject']"
      fieldName="isFatcaSubject"
      cypressSelector="Contact_RadioButton_FatcaSubject"
      label="REGISTRATION.PERSON.FATCA_FIELD_QUESTION">

      <fieldset input id="{{'isFatcaSubject-' + componentId}}">
        <legend>-- (hidden) need this to group the choices when multiple admin forms are on screen --</legend>
        <div *ngIf="!readonly || personForm.controls['isFatcaSubject'].value === true"
          data-cy="Contact_RadioButton_FatcaSubject_YES">
          <input id="{{ 'us-person-' + componentId }}" type="radio" [value]=true formControlName="isFatcaSubject">
          <label for="{{ 'us-person-' + componentId }}">{{ 'REGISTRATION.PERSON.FATCA_YES_LABEL' | translate }}</label>
        </div>
        <div *ngIf="!readonly || personForm.controls['isFatcaSubject'].value === false"
          data-cy="Contact_RadioButton_FatcaSubject_NO">
          <input id="{{ 'non-us-person-' + componentId }}" type="radio" [value]=false formControlName="isFatcaSubject">
          <label for="{{ 'non-us-person-' + componentId }}">{{ 'REGISTRATION.PERSON.FATCA_NO_LABEL' | translate }}</label>
        </div>
      </fieldset>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>
  </div>
</div>
