<div class="idv-page-content">

  <header>
    <h2>{{ 'REGISTRATION.IDV_COMPLETE.TITLE' | translate }}</h2>

    <div class="image emailimage"></div>
  </header>

  <ng-container *ngIf="applicant">
    <p>{{ 'REGISTRATION.IDV_COMPLETE.PARAGRAPH_1_APPLICANT' | translate : {email: applicant.Email} }}</p>
    <p>{{ 'REGISTRATION.IDV_COMPLETE.PARAGRAPH_2_APPLICANT' | translate }}</p>
    <p>{{ 'REGISTRATION.IDV_COMPLETE.PARAGRAPH_3_APPLICANT' | translate }}</p>
  </ng-container>

  <ng-container *ngIf="!applicant"> <!-- Copy not provided -->
    <p>{{ 'REGISTRATION.IDV_COMPLETE.PARAGRAPH_1' | translate }}</p>
    <p>{{ 'REGISTRATION.IDV_COMPLETE.PARAGRAPH_2' | translate }}</p>
  </ng-container>

  <div class="cta-section">
    <button class="primary" (click)="clickOnCTA()">{{ 'REGISTRATION.IDV_COMPLETE.CTA' | translate }}</button>
  </div>

</div>
