<h2>{{'REGISTRATION.SIGNUP_STEP.COMPANY_TYPE.TITLE' | translate}}</h2>

<div class="layout-section">
  <form [formGroup]="form">
    <soldo-field-layout *ngIf="form.get('companyType')"
      class="chips-radio"
      [control]="form.controls['companyType']"
      fieldName="companyType"
      cypressSelector="Signup_RadioButton_CompanyType"
      label="">

      <fieldset input>
        <ng-container *ngFor="let companyTypeCategoryGroup of companyTypesGroupedByCategory">
          <h5>{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_TYPE.'+ companyTypeCategoryGroup[0].category + '_LABEL' | translate}}</h5>
          <div *ngFor="let companyType of companyTypeCategoryGroup" class="radio-item">
            <input
              type="radio"
              formControlName= "companyType"
              [id]="companyType.value"
              [value]="companyType.value">
            <label [for]="companyType.value">{{companyType.label}}</label>
          </div>
        </ng-container>
      </fieldset>

      <soldo-field-error errorMessage errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error errorMessage errorName="selectionNotAllowed">{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_TYPE.SOLETRADER_NOT_ACCEPTED_MESSAGE' | translate }}</soldo-field-error>
    </soldo-field-layout>

    <soldo-field-layout *ngIf="form.get('companyTypeOther')"
      [control]="form.controls['companyTypeOther']"
      fieldName="companyTypeOther"
      cypressSelector="Signup_RadioButton_CompanyTypeOther"
      label="{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_TYPE.LABEL' }}">

      <ng-container input>
        <input
          name="companyTypeOther"
          placeholder="{{ 'COMPANY.TYPE_PLACEHOLDER' | translate }}"
          type="text"
          formControlName="companyTypeOther">
      </ng-container>

      <soldo-field-error errorMessage errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
    </soldo-field-layout>

    <!-- Sub-Step Navigation -->
    <div class="form-page-CTA-row">
      <button type="button" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
        {{ 'REGISTRATION.SIGNUP_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
      </button>
      <button type="submit" class="primary" (click)="nextStep()" [disabled]="loading" [ngClass]="{ 'loading': loading}"
        data-cy="Signup_Button_NextStepButton">
        {{ 'REGISTRATION.SIGNUP_STEP.NEXT_SUB_STEP_BUTTON' | translate }}
      </button>
    </div>
  </form>
</div>
