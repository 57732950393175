<h3 class="title">{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.MONTHLY_SPEND.TITLE' | translate}}</h3>
<p class="subtitle">{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.MONTHLY_SPEND.SUB_TITLE' | translate}}</p>

<form [formGroup]="form" novalidate>
  <soldo-field-layout *ngIf="form.get('monthlySpend')"
    [control]="form.controls['monthlySpend']"
    fieldName="monthlySpend"
    [required]="true"
    cypressSelector="Expected_RadioButton_MonthlySpend"
    label="REGISTRATION.EXPECTED_ACTIVITY_STEP.MONTHLY_SPEND.LABEL">

    <div input class="radio-main">
      <div class="radio-list radio-list-top">
        <div *ngFor="let i of [0,1,2,3]" class="radio-item"
          [attr.data-cy]="'Expected_RadioButton_MonthlySpend_'+monthlySpendOptions[i].value">
          <input type="radio" formControlName="monthlySpend" [id]="'OPT'+i" [value]="monthlySpendOptions[i].value">
          <label [for]="'OPT'+i">{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.MONTHLY_SPEND.'+monthlySpendOptions[i].label | translate }}</label>
        </div>
      </div>
    </div>

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.MONTHLY_SPEND.RANGE_REQUIRED' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>
</form>


<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading" data-cy="Expected_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Expected_Button_NextStepButton">
      {{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
