import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AbstractFormComponent } from "@shared/components/abstract-form.component";
import { CustomValidators } from "@shared/customValidators";
import { Account, Contact, ContactOperation } from "@shared/models/registration.model";
import { AmplitudeService } from '@shared/services/amplitude.service';
import { CountryService } from '@shared/services/country.service';
import { FiscalCodeService } from "@shared/services/fiscal-code.service";
import { RegistrationDataService } from "@shared/services/registrationData.service";
import { StatusService } from '@shared/services/status.service';
import { StepService } from "@shared/services/steps.service";
import * as moment from 'moment-mini';
import { NGXLogger } from 'ngx-logger';
import { SELECT_INVALID_SOLDO_FIELD, scrollToFirstResult } from '../../shared/formHelpers';

@Component({
    selector: 'director',
    templateUrl: './director.component.html',
    styleUrls: ['./director.component.scss']
})
export class DirectorComponent extends AbstractFormComponent implements OnInit {

    form: UntypedFormGroup;
    primaryLead: Contact;
    primaryLeadIsDirector: boolean = null;
    soleTrader: boolean = false;
    contacts2delete: string[] = [];
    loading: boolean = false;
    contactsData: Contact[] = [];
    countryOfResidenceDefault: string = null;
    countryOfResidenceEditable: boolean = true;
    regulatoryInstitution: string;

    constructor(
        protected logger: NGXLogger,
        private router: Router,
        private fb: UntypedFormBuilder,
        private registrationDataService: RegistrationDataService,
        private fiscalCodeService: FiscalCodeService,
        private stepService: StepService,
        private countryService: CountryService,
        private amplitudeService: AmplitudeService,
        private statusService: StatusService
    ) {
        super();
    }

    ngOnInit() {
        const fsApplicationSubmissionStatus = this.statusService.getFsSubmissionStatus();
        const account: Account = this.registrationDataService.getAccount();
        this.contactsData = this.registrationDataService.getContacts();
        this.primaryLead = this.contactsData.find(contact => {
            return contact.ContactIsPrimaryLead__c;
        });
        this.soleTrader = this.registrationDataService.isSoleTrader();
        this.countryOfResidenceDefault = account.RegisteredAddressCountryISO__c;
        this.countryOfResidenceEditable = !this.soleTrader;
        this.regulatoryInstitution = account.RegisteredAddressCountryISO__c === "GBR" ? "FCA" : "CBI";
        let directorFormGroup: UntypedFormGroup = null;
        let legalOwner: Contact = this.contactsData.find(contact => {
            return contact.ContactIsDirector__c && contact.ContactIsLegalOwner__c;
        });

        if (legalOwner) {
            this.primaryLeadIsDirector = legalOwner.ContactIsPrimaryLead__c;

            if (fsApplicationSubmissionStatus.key === "AccountCreated") { // hand over
                const leadCompatibleFields: Partial<Contact> = {
                    SoldoContactExternalID__c: this.primaryLead.SoldoContactExternalID__c,
                    FirstName: this.primaryLead.FirstName,
                    LastName: this.primaryLead.LastName,
                    Email: this.primaryLead.Email,
                    ContactIsPrimaryLead__c: true
                };
                this.primaryLead = Object.assign(this.registrationDataService.getEmptyContact(), leadCompatibleFields);

                this.primaryLeadIsDirector = null;
                legalOwner = null;
            }

        } else if (this.soleTrader) {
            this.primaryLeadIsDirector = true;
            this.primaryLead.ContactIsDirector__c = true;
            this.primaryLead.ContactIsLegalOwner__c = true;
            legalOwner = this.primaryLead;
        }

        this._enforceSingleDirector(legalOwner?.SoldoContactExternalID__c);

        directorFormGroup = this._initDirectorFormGroup(legalOwner);
        this.form = this.fb.group({
            isPrimaryLead: [this.primaryLeadIsDirector],
            director: directorFormGroup
        });
    }

    private _enforceSingleDirector(directorId?: string): void {
        this.contactsData.forEach((contact) => {
            if (contact.SoldoContactExternalID__c !== directorId) {
                contact.ContactIsLegalOwner__c = false;
                contact.ContactIsDirector__c = false;

                if (!contact.ContactIsAdmin__c && !contact.ContactIsPrimaryLead__c) {
                    this.contacts2delete.push(contact.SoldoContactExternalID__c);
                }
            }
        });
    }

    private _setDirector(contact: Contact): void {
        contact.ContactIsDirector__c = true;
        contact.ContactIsLegalOwner__c = true;
    }

    setPrimaryLeadAsDirector() {

        const account: Account = this.registrationDataService.getAccount();
        const icc = this.countryService.getMetadata(account.RegisteredAddressCountryISO__c).icc;
        this.primaryLeadIsDirector = true;
        let phoneWithoutIcc = this.primaryLead.MobilePhone;
        if (phoneWithoutIcc && phoneWithoutIcc.indexOf(this.primaryLead.ContactPhoneICC__c) > -1) {
            phoneWithoutIcc = phoneWithoutIcc.substring(this.primaryLead.ContactPhoneICC__c.length);
        }
        this._setDirector(this.primaryLead);
        this._enforceSingleDirector(this.primaryLead.SoldoContactExternalID__c);

        const directorFormGroup = <UntypedFormGroup>this.form.controls['director'];

        let citizenshipValue = account.RegisteredAddressCountryISO__c;
        if (this.primaryLead.ContactCitizenshipCountryISO__c) {
            citizenshipValue = this.primaryLead.ContactCitizenshipCountryISO__c;
        }

        let addressCountryValue = account.RegisteredAddressCountryISO__c;
        if (this.primaryLead.ResidentialAddressCountryISO__c) {
            addressCountryValue = this.primaryLead.ResidentialAddressCountryISO__c;
        }

        directorFormGroup.patchValue({
            contactExternalId: this.primaryLead.SoldoContactExternalID__c,
            personFormId: this.primaryLead.SoldoContactExternalID__c,
            firstName: this.primaryLead.FirstName,
            lastName: this.primaryLead.LastName,
            dob: moment(this.primaryLead.Birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            gender: this.primaryLead.ContactGender__c,
            citizenship: citizenshipValue,
            fiscalCode: this.primaryLead.ContactFiscalCode__c ? this.primaryLead.ContactFiscalCode__c : "",
            email: this.primaryLead.Email,
            phone: { prefix: this.primaryLead.ContactPhoneICC__c ? this.primaryLead.ContactPhoneICC__c : icc, number: phoneWithoutIcc },
            address: {
                country: addressCountryValue,
                poBoxNumber: this.primaryLead.ResidentialAddressPoBox__c,
                postcode: this.primaryLead.ResidentialAddressPostalCode__c,
                secondaryStreet: this.primaryLead.ResidentialAddressSecondaryStreet__c,
                state: this.primaryLead.ResidentialAddressState__c,
                city: this.primaryLead.ResidentialAddressCity__c,
                street: this.primaryLead.ResidentialAddressStreet__c,
                line1: this.primaryLead.ResidentialAddressLine1__c,
                line2: this.primaryLead.ResidentialAddressLine2__c,
                line3: this.primaryLead.ResidentialAddressLine3__c,
                buildingName: this.primaryLead.ResidentialAddressHouseName__c,
                buildingNumber: this.primaryLead.ResidentialAddressHouseNumber__c,
                subBuilding: this.primaryLead.ResidentialAddressSubBuilding__c
            },
            isLegalOwner: true,
            isDirector: true,
            isAdmin: this.primaryLead.ContactIsAdmin__c,
            isPrimaryLead: true
        });
        this.form.markAsUntouched();
    }

    setNewDirector() {

        const account: Account = this.registrationDataService.getAccount();
        const icc = this.countryService.getMetadata(account.RegisteredAddressCountryISO__c).icc;
        this.primaryLeadIsDirector = false;

        this.form.get('director').reset();
        this.form.get('director').markAsUntouched();

        const directorFormGroup = <UntypedFormGroup>this.form.controls['director'];

        directorFormGroup.patchValue({
            phone: { prefix: icc },
            address: { country: this.countryOfResidenceDefault },
            isLegalOwner: true,
            isDirector: true,
            isAdmin: false,
            isPrimaryLead: false
        });

        this._enforceSingleDirector();
    }

    private _initDirectorFormGroup(director?: Contact): UntypedFormGroup {
        const account: Account = this.registrationDataService.getAccount();
        const icc = this.countryService.getMetadata(account.RegisteredAddressCountryISO__c).icc;
        const directorFormId: string = [
            account.SoldoRegistrationExternalID__c,
            "director",
            this.amplitudeService.getNextFormId()].join("-");

        let directorFormGroup: UntypedFormGroup = null;

        if (director) {
            let phoneWithoutIcc = director.MobilePhone;
            if (phoneWithoutIcc && phoneWithoutIcc.indexOf(director.ContactPhoneICC__c) > -1) {
                phoneWithoutIcc = phoneWithoutIcc.substring(director.ContactPhoneICC__c.length);
            }

            if (!director.ContactCitizenshipCountryISO__c) {
                director.ContactCitizenshipCountryISO__c = account.RegisteredAddressCountryISO__c;
            }

            const usCitizenOrResident = [director.ContactCitizenshipCountryISO__c, director.ResidentialAddressCountryISO__c].includes('USA');

            directorFormGroup = this.fb.group({
                personFormId: [director.SoldoContactExternalID__c, []],
                contactExternalId: [director.SoldoContactExternalID__c],
                firstName: [director.FirstName, [Validators.required, this.validateFirstNameAgainstCF, this.contactMaxlengthValidator("FirstName")]],
                lastName: [director.LastName, [Validators.required, this.validateLastNameAgainstCF, this.contactMaxlengthValidator("LastName")]],
                dob: [moment(director.Birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY'), [Validators.required, CustomValidators.validDate(18, 120)]],
                citizenship: [director.ContactCitizenshipCountryISO__c, Validators.required],
                email: [director.Email, [
                    Validators.required,
                    this.contactMaxlengthValidator("Email"),
                    CustomValidators.validEmail(),
                    CustomValidators.cannotUseSoldoPecAddress(),
                    this.preventUseOfDuplicatedEmailValidatorFactory()]
                ],
                phone: this.fb.group({
                    prefix: [director.ContactPhoneICC__c || icc, [Validators.required]],
                    number: [phoneWithoutIcc, [Validators.required]]
                }, { validators: [CustomValidators.validPhone('prefix', 'number'), this.preventDuplicatedPhoneNumbersValidatorFactory()] }),
                address: this._setAddress(director),
                isLegalOwner: [true],
                isDirector: [true],
                isAdmin: [this.soleTrader || director.ContactIsAdmin__c],
                isPrimaryLead: [director.ContactIsPrimaryLead__c]
            });

            if (!usCitizenOrResident) {
                let isFatcaSubjectValue = null;
                switch(director.ContactIsFatcaSubject__c) {
                    case "Yes":
                        isFatcaSubjectValue = true;
                        break;
                    case "No":
                        isFatcaSubjectValue = false;
                        break;
                }
                directorFormGroup.addControl('isFatcaSubject', new UntypedFormControl(isFatcaSubjectValue, Validators.required));
            }
        } else {
            directorFormGroup = this.fb.group({
                personFormId: [directorFormId, []],
                contactExternalId: [null],
                firstName: [null, [Validators.required, this.validateFirstNameAgainstCF, this.contactMaxlengthValidator("FirstName")]],
                lastName: [null, [Validators.required, this.validateLastNameAgainstCF, this.contactMaxlengthValidator("LastName")]],
                dob: [null, [Validators.required, CustomValidators.validDate(18, 120)]],
                citizenship: [null, Validators.required],
                email: [null, [
                    Validators.required,
                    this.contactMaxlengthValidator("Email"),
                    CustomValidators.validEmail(),
                    CustomValidators.cannotUseSoldoPecAddress(),
                    this.preventUseOfDuplicatedEmailValidatorFactory()]
                ],
                phone: this.fb.group({
                    prefix: [icc, [Validators.required]],
                    number: [null, [Validators.required]]
                }, { validators: [CustomValidators.validPhone('prefix', 'number'), this.preventDuplicatedPhoneNumbersValidatorFactory()] }),
                address: this._setAddress(),
                isFatcaSubject: [null, Validators.required],
                isLegalOwner: [true],
                isDirector: [true],
                isAdmin: [this.soleTrader],
                isPrimaryLead: [false]
            });

        }

        this._onDirectorFormGroupValueChanges(directorFormGroup, director);

        directorFormGroup.valueChanges.subscribe(() => {
            this._onDirectorFormGroupValueChanges(directorFormGroup, director);
        });

        return directorFormGroup;
    }

    _onDirectorFormGroupValueChanges(directorFormGroup: UntypedFormGroup, director: Contact) {
        const account: Account = this.registrationDataService.getAccount();
        let cfField = directorFormGroup.get("fiscalCode");
        const countryOfResidence = directorFormGroup.get("address")?.get("country")?.value;
        const countryOfCitizenship = directorFormGroup.get("citizenship")?.value;


        if (!cfField && (account.RegisteredAddressCountryISO__c === "ITA" || countryOfResidence === "ITA")) {
            cfField = new UntypedFormControl(director ? director.ContactFiscalCode__c : null, [Validators.required, this.fiscalCodeService.validator]);
            cfField["id"] = Date.now();
            directorFormGroup.addControl("fiscalCode", cfField);
            directorFormGroup.addControl("gender", new UntypedFormControl(director ? director.ContactGender__c : null, Validators.required));
            directorFormGroup.addControl("birthPlace", new UntypedFormControl(director ? director.ContactBirthPlace__c : null));
            directorFormGroup.addControl("birthState", new UntypedFormControl(director ? director.ContactBirthState__c : null));

            setTimeout(() => {
                const cfFieldElement = document.getElementById(cfField["id"]);
                if (cfFieldElement) {
                    cfFieldElement.scrollIntoView();
                }
            }, 100);
        }

        if (cfField && (account.RegisteredAddressCountryISO__c !== "ITA" && countryOfResidence !== "ITA")) {
            directorFormGroup.removeControl("fiscalCode");
            directorFormGroup.removeControl("gender");
            directorFormGroup.removeControl("birthPlace");
            directorFormGroup.removeControl("birthState");
        } else if (cfField && cfField.value && cfField.valid) {
            directorFormGroup.get("firstName").updateValueAndValidity({
                onlySelf: true,
                emitEvent: true
            });
            directorFormGroup.get("lastName").updateValueAndValidity({
                onlySelf: true,
                emitEvent: true
            });
        }

        if ([countryOfCitizenship, countryOfResidence].includes("USA") && directorFormGroup.get("isFatcaSubject")) {
            directorFormGroup.removeControl("isFatcaSubject");
        } else if (![countryOfCitizenship, countryOfResidence].includes("USA") && !directorFormGroup.get("isFatcaSubject")) {
            directorFormGroup.addControl("isFatcaSubject", new UntypedFormControl(null, Validators.required));
        }
    }

    validateFirstNameAgainstCF = (firstNameControl: AbstractControl): ValidationErrors => {
        const cfField = firstNameControl.parent ? firstNameControl.parent.get("fiscalCode") : false;
        if (cfField && firstNameControl.value && cfField.value && cfField.valid) {
            if (!this.fiscalCodeService.validateAgainstFirstName(cfField.value, firstNameControl.value)) {
                return {
                    "firstNameCfMismatch": true
                };
            }
        }
        return null;
    };

    validateLastNameAgainstCF = (lastNameControl: AbstractControl): ValidationErrors => {
        const cfField = lastNameControl.parent ? lastNameControl.parent.get("fiscalCode") : false;
        if (cfField && lastNameControl.value && cfField.value && cfField.valid) {
            if (!this.fiscalCodeService.validateAgainstLastName(cfField.value, lastNameControl.value)) {
                return {
                    "lastNameCfMismatch": true
                };
            }
        }
        return null;
    };

    private preventDuplicatedPhoneNumbersValidatorFactory(): ValidatorFn {
        return (control: AbstractControl) => {
            const directorPhone = control.value.prefix + control.value.number;
            const otherPhones = this.registrationDataService.getContacts().filter(contact => {
                return contact['ContactIsAdmin__c'] && !contact['ContactIsLegalOwner__c'];
            });
            if (otherPhones.some(otherContact => {
                return otherContact['MobilePhone'] === directorPhone;
            })) {
                return {
                    duplicated: true
                };
            }
        };
    }

    private preventUseOfDuplicatedEmailValidatorFactory(): ValidatorFn {
        return (control: AbstractControl) => {
            const directorEmail = control.value;
            const otherEmails = this.registrationDataService.getContacts().filter(contact => {
                return contact['ContactIsAdmin__c'] && !contact['ContactIsLegalOwner__c'];
            });
            if (otherEmails.some(otherContact => {
                return otherContact['Email'] === directorEmail;
            })) {
                return {
                    noDuplicatedEmail: true
                };
            }
        };
    }

    private _setAddress(director?: Contact): UntypedFormGroup {
        const account: Account = this.registrationDataService.getAccount();
        let addressFormGroup: UntypedFormGroup = null;

        if (director) {
            if (!director.ResidentialAddressCountryISO__c) {
                director.ResidentialAddressCountryISO__c = account.RegisteredAddressCountryISO__c;
            }
            addressFormGroup = this.fb.group({
                idCheckAddressLookupAddressCode: [director.ResidentialAddressServiceRefCode__c ? director.ResidentialAddressServiceRefCode__c : null],
                line1: [director.ResidentialAddressLine1__c],
                line2: [director.ResidentialAddressLine2__c],
                line3: [director.ResidentialAddressLine3__c],
                city: [director.ResidentialAddressCity__c],
                postcode: [director.ResidentialAddressPostalCode__c],
                country: new UntypedFormControl({
                    value: this.countryOfResidenceEditable ? director.ResidentialAddressCountryISO__c : this.countryOfResidenceDefault,
                    disabled: !this.countryOfResidenceEditable
                }, Validators.required),
                state: [director.ResidentialAddressState__c],
                buildingName: [director.ResidentialAddressHouseName__c],
                buildingNumber: [director.ResidentialAddressHouseNumber__c],
                poBoxNumber: [director.ResidentialAddressPoBox__c],
                street: [director.ResidentialAddressStreet__c],
                secondaryStreet: [director.ResidentialAddressSecondaryStreet__c],
                subBuilding: [director.ResidentialAddressSubBuilding__c]
            });
        } else {
            addressFormGroup = this.fb.group({
                idCheckAddressLookupAddressCode: [null],
                line1: [null],
                line2: [null],
                line3: [null],
                city: [null],
                state: [null],
                postcode: [null],
                country: new UntypedFormControl({
                    value: this.countryOfResidenceDefault,
                    disabled: !this.countryOfResidenceEditable
                }, Validators.required),
                buildingName: [null],
                buildingNumber: [null],
                poBoxNumber: [null],
                street: [null],
                secondaryStreet: [null],
                subBuilding: [null]
            });
        }

        return addressFormGroup;
    }

    _saveForm() {

        const directorFormValue = this.form.value.director;

        this.logger.debug("Contact", directorFormValue);

        const contactsOperations: Array<ContactOperation> = [];

        if (directorFormValue.address.idCheckAddressLookupAddressCode) {
            this.amplitudeService.trackAddressSearchResultSubmitted(
                this.router.url,
                directorFormValue.personFormId || "none",
                this.countryOfResidenceEditable ? directorFormValue.address.country : this.countryOfResidenceDefault);
        }
        const isUsCitizenOrResident = [directorFormValue.address.country, directorFormValue.citizenship].includes("USA");

        contactsOperations.push({
            "SoldoContactExternalID__c": directorFormValue.contactExternalId,
            "FirstName": directorFormValue.firstName,
            "LastName": directorFormValue.lastName,
            "ContactFiscalCode__c": directorFormValue.fiscalCode ? directorFormValue.fiscalCode : '',
            "ContactBirthPlace__c": directorFormValue.birthPlace ? directorFormValue.birthPlace : '',
            "ContactBirthState__c": directorFormValue.birthState ? directorFormValue.birthState : '',
            "ContactGender__c": directorFormValue.gender ? directorFormValue.gender : '',
            "ContactCitizenshipCountryISO__c": directorFormValue.citizenship,
            "Birthdate": moment(directorFormValue.dob, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            "ContactPhoneICC__c": directorFormValue.phone.prefix,
            "MobilePhone": `${directorFormValue.phone.prefix}${directorFormValue.phone.number}`,
            "Email": directorFormValue.email ? directorFormValue.email : '',
            "ResidentialAddressLine1__c": directorFormValue.address.line1,
            "ResidentialAddressLine2__c": directorFormValue.address.line2,
            "ResidentialAddressLine3__c": directorFormValue.address.line3,
            "ResidentialAddressCity__c": directorFormValue.address.city,
            "ResidentialAddressPostalCode__c": directorFormValue.address.postcode,
            "ResidentialAddressCountryISO__c": this.countryOfResidenceEditable ? directorFormValue.address.country : this.countryOfResidenceDefault,
            "ResidentialAddressState__c": directorFormValue.address.state,
            "ResidentialAddressServiceRefCode__c": directorFormValue.address.idCheckAddressLookupAddressCode,
            "ResidentialAddressHouseName__c": directorFormValue.address.buildingName,
            "ResidentialAddressHouseNumber__c": directorFormValue.address.buildingNumber,
            "ResidentialAddressPoBox__c": directorFormValue.address.poBoxNumber,
            "ResidentialAddressStreet__c": directorFormValue.address.street,
            "ResidentialAddressSecondaryStreet__c": directorFormValue.address.secondaryStreet,
            "ResidentialAddressSubBuilding__c": directorFormValue.address.subBuilding,
            "ContactIsLegalOwner__c": true,
            "ContactIsDirector__c": true,
            "ContactIsAdmin__c": directorFormValue.isAdmin,
            "ContactIsPrimaryLead__c": directorFormValue.isPrimaryLead,
            "ContactIsFatcaSubject__c": directorFormValue.isFatcaSubject || isUsCitizenOrResident ? "Yes" : "No"
        });

        this.contactsData.filter(c => {
            return c.SoldoContactExternalID__c !== directorFormValue.contactExternalId;
        }).forEach(contact => {
            contactsOperations.push({
                "SoldoContactExternalID__c": contact.SoldoContactExternalID__c,
                "ContactIsLegalOwner__c": false,
                "ContactIsDirector__c": false,
                "IsDeleted": this.contacts2delete.includes(contact.SoldoContactExternalID__c)
            });
        });

        this.logger.debug("ContactsData: ", contactsOperations);

        this.registrationDataService.saveContacts(contactsOperations);
    }

    onSubmit() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.loading = true;
            this._saveForm();

            const postSubmitFsSubmissionStatus = this.statusService.getFsSubmissionStatusValidIncrement(this.soleTrader ? "AdminCreated" : "DirectorCreated");

            this.registrationDataService.saveAccount({
                FsApplicationSubmissionStatus__c: postSubmitFsSubmissionStatus
            });

            this.registrationDataService.submitContacts().then(() => {
                this.registrationDataService.submitAccount().then(() => {
                    this.statusService.setStatusIncrementAfterSubmission(postSubmitFsSubmissionStatus);
                    this.amplitudeService.trackApplicantIsLegalRepresentativeChoice(this.primaryLeadIsDirector);
                    this.stepService.navigateToNextStep();
                });
            }, (err) => this.logger.trace("FATAL: ", err));
        } else {
            this.loading = false;
            this.logger.error("form not valid: ", this.form.value, this.form.errors);
            scrollToFirstResult(SELECT_INVALID_SOLDO_FIELD, 'fiscal-code:has(input.ng-invalid)');
        }
    }

    goToPrevious() {
        this.stepService.navigateToPreviousStep();
    }
}
