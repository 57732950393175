import { Component, EventEmitter } from '@angular/core';
import { CompanySubStepOutboundAction, CompanySubStepType } from '../../company-step.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AmplitudeService } from '../../../../shared/services/amplitude.service';
import { GoogleTagManagerService } from '../../../../shared/services/google-tag-manager.service';
import { Account, AccountBusinessOperationType } from '../../../../shared/models/registration.model';
import { DefaultOutboundActions } from '../../../../shared/models/app-structure.model';
import { CountryService, ICountryMetadata } from '../../../../shared/services/country.service';
import { StatusService } from '../../../../shared/services/status.service';

@Component({
    selector: 'app-company-international',
    templateUrl: './company-international.component.html',
    styleUrls: ['./company-international.component.scss']
})
export class CompanyInternationalComponent extends CompanySubStepType {
    subStepName: string = "Company International Activity";

    loading: boolean = true;

    form: UntypedFormGroup;
    internationalCountriesList: ICountryMetadata[];

    constructor(
        private fb: UntypedFormBuilder,
        private countryService: CountryService,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService,
        private statusService: StatusService) {

        super();
        this.controlActionEventEmitter = new EventEmitter<CompanySubStepOutboundAction>();
    }

    init(d: Partial<Account>): void {

        this.countryService.countries.subscribe(allCountries => {
            this.internationalCountriesList = allCountries.sort(this.countryService.sortFunction)
                .filter(c => c.icc).map(c => {
                    if (c.alpha_3 === d.RegisteredAddressCountryISO__c) {
                        return Object.assign({ ...c }, { disabled: true });
                    }
                    return c;
                });
        });

        const isInternational = d.BusinessOperationType__c === AccountBusinessOperationType.INTERNATIONAL;

        this.form = this.fb.group({
            "isInternational": [isInternational, [Validators.required]]
        });

        if (isInternational) {
            this.showInternationalMarketsList(d.PrimaryInternationalCountryISO__c);
        }


        this.form.get("isInternational").valueChanges.subscribe((value) => {
            if (value) {
                this.showInternationalMarketsList();
            } else if (this.form.get("primaryInternationalMarket")) {
                this.hideInternationalMarketsList();
            }
        });

        this.loading = false;
    }

    showInternationalMarketsList(value: string = null) {
        if (!this.form.get("primaryInternationalMarket")) {
            this.form.addControl("primaryInternationalMarket", new UntypedFormControl(value, [
                Validators.required
            ]));
        }
    }

    hideInternationalMarketsList() {
        this.form.removeControl("primaryInternationalMarket");
    }

    isValid(): boolean {
        this.form.markAllAsTouched();
        return this.form.valid;
    }

    getData(): Partial<Account> {
        const formValues = this.form.value;
        return {
            BusinessOperationType__c: formValues.isInternational ? AccountBusinessOperationType.INTERNATIONAL : AccountBusinessOperationType.DOMESTIC,
            PrimaryInternationalCountryISO__c: formValues.primaryInternationalMarket || ""
        };
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        }
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

}

