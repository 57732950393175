import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { RegistrationDataService } from '@shared/services/registrationData.service';

@Component({
    selector: 'app-hoo-yu-verification-embedded',
    templateUrl: './hoo-yu-verification-embedded.component.html',
    styleUrls: ['./hoo-yu-verification-embedded.component.scss']
})

export class HooYuVerificationEmbeddedComponent implements OnInit {
    iframeHeight = '150px';
    link: SafeResourceUrl;
    isHooyuSelfie: boolean = false;
    isHooYuModal: boolean = false;
    constructor(
        private registrationDataService: RegistrationDataService,
        private sanitazer: DomSanitizer,
        private amplitudeService: AmplitudeService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.link = this.sanitazer.bypassSecurityTrustResourceUrl(this.registrationDataService.getHooYuVerificationLink());
        const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        const eventer = window[eventMethod];
        const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
        eventer(messageEvent, (e: any) => {
            if (e.data) {
                if (e.data.soldo?.frameEnd) {
                    this.router.navigate(['static/identity-verified']);
                }
                if (e.data.height) {
                    this.iframeHeight = `${parseInt(e.data?.height?.replace('px', '')) + 1}px`;
                }

                if (e.data.hasOwnProperty("selfie")){
                    if (e.data.selfie) {
                        if (!this.isHooyuSelfie){
                            this.isHooyuSelfie = true;
                            this.amplitudeService.trackHooYuLivenessCheckStarted();
                        }
                    } else if (this.isHooyuSelfie){
                        this.isHooyuSelfie = false;
                        this.amplitudeService.trackHooYuLivenessCheckEnded();
                    }
                }

                if (e.data.hasOwnProperty("modal")) {
                    if (e.data.modal) {
                        if (!this.isHooYuModal){
                            this.isHooYuModal = true;
                            this.amplitudeService.trackHooYuModalOpened();
                        }
                    } else if (this.isHooYuModal){
                        this.isHooYuModal = false;
                        this.amplitudeService.trackHooYuModalClosed();
                    }
                }
            }

        }, false);
    }
}
