<div [formGroup]="addressForm">

  <!-- SIMPLIFIED LINE 1, 2 ADDRESS -->
  <div class="form-input-row" *ngIf="simplified">

    <!-- LINE 1 -->
    <soldo-field-layout cypressSelector="Address_TextInput_Line1" [control]="addressForm.controls['line1']"
      fieldName="line1" label="REGISTRATION.ADDRESS.LINE_1_LABEL"
      [required]="fieldMetadata['line1'].mandatory && !readonly">
      <input input type="text" class="form-control"
        placeholder="{{ 'REGISTRATION.ADDRESS.LINE_1_PLACEHOLDER' | translate }}" formControlName="line1" name="line1"
        [readOnly]="readonly">
      <ng-container errorMessage>
        <div *ngIf="isErrorVisible(addressForm.get('line1'))" class="text-danger">
          <div *ngIf="addressForm.get('line1').errors?.required">{{ 'REQUIRED' | translate }}</div>
          <div *ngIf="addressForm.get('line1').errors?.maxlength ">
            {{ 'MAX_LENGHT' | translate:{value: addressForm.get('line1').errors?.maxlength.requiredLength} }}</div>
        </div>
      </ng-container>
    </soldo-field-layout>

    <!-- LINE 2 -->
    <soldo-field-layout cypressSelector="Address_TextInput_Line2" [control]="addressForm.controls['line2']"
      fieldName="line2" label="REGISTRATION.ADDRESS.LINE_2_LABEL"
      [required]="fieldMetadata['line2'].mandatory && !readonly">
      <input input type="text" class="form-control"
        placeholder="{{ 'REGISTRATION.ADDRESS.LINE_2_PLACEHOLDER' | translate }}" formControlName="line2" name="line2"
        [readOnly]="readonly">
      <ng-container errorMessage>
        <div *ngIf="isErrorVisible(addressForm.get('line2'))" class="text-danger">
          <div *ngIf="addressForm.get('line2').errors?.maxlength ">
            {{ 'MAX_LENGHT' | translate:{value: addressForm.get('line2').errors?.maxlength.requiredLength} }}</div>
        </div>
      </ng-container>
    </soldo-field-layout>

  </div>

  <!-- NOT SIMPLIFIED STREET AND BUILDING NUMBER -->
  <div class="form-input-row" *ngIf="!simplified">
    <soldo-field-layout cypressSelector="Address_TextInput_Street" [control]="addressForm.controls['street']"
      fieldName="street" label="REGISTRATION.ADDRESS.STREET_LABEL"
      [required]="fieldMetadata['street'].mandatory && !readonly">
      <input input type="text" class="form-control" placeholder="{{ 'REGISTRATION.ADDRESS.STREET_PLACEHOLDER' | translate }}"
        formControlName="street" name="street" [readOnly]="readonly">
      <ng-container errorMessage>
        <div *ngIf="isErrorVisible(addressForm.get('street'))" class="text-danger">
          <div *ngIf="addressForm.get('street').errors?.required">{{ 'REQUIRED' | translate }}</div>
          <div *ngIf="addressForm.get('street').errors?.maxlength ">
            {{ 'MAX_LENGHT' | translate:{value: addressForm.get('street').errors?.maxlength.requiredLength} }}</div>
        </div>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout cypressSelector="Address_TextInput_BuildingNumber"
      [control]="addressForm.controls['buildingNumber']" fieldName="buildingNumber"
      label="REGISTRATION.ADDRESS.BUILDING_NUMBER_LABEL"
      [required]="fieldMetadata['buildingNumber'].mandatory && !readonly">
      <input input type="text" class="form-control"
        placeholder="{{ 'REGISTRATION.ADDRESS.BUILDING_NUMBER_PLACEHOLDER' | translate }}"
        formControlName="buildingNumber" name="buildingNumber" [readOnly]="readonly">
      <ng-container errorMessage>
        <div *ngIf="isErrorVisible(addressForm.get('buildingNumber'))" class="text-danger">
          <div *ngIf="addressForm.get('buildingNumber').errors?.required">{{ 'REQUIRED' | translate }}</div>
          <div *ngIf="addressForm.get('buildingNumber').errors?.maxlength ">
            {{ 'MAX_LENGHT' | translate:{value: addressForm.get('buildingNumber').errors?.maxlength.requiredLength} }}
          </div>
        </div>
      </ng-container>
    </soldo-field-layout>

  </div>

  <!-- TOWN ROW - GENERIC VARIANT -->
  <div class="form-input-row">

    <!-- IRISH COUNTY SELECTOR -->
    <soldo-field-layout cypressSelector="Address_NgSelect_County" [control]="addressForm.controls['state']"
      fieldName="state" label="REGISTRATION.ADDRESS.STATE_LABEL"
      [required]="fieldMetadata['state'].mandatory && !readonly">
      <ng-select input *ngIf="!readonly" [dropdownPosition]="'bottom'" formControlName="state" name="state"
        [items]="irishCountiesFiltered" bindLabel="principal" type="search" bindValue="principal" [clearable]=false
        [typeahead]="irishCountiesTypeahead" notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
        placeholder="{{ 'REGISTRATION.ADDRESS.STATE_PLACEHOLDER' | translate }}">
      </ng-select>
      <input input *ngIf="readonly" type="text" formControlName="state" name="state" class="form-control" readonly>
      <ng-container errorMessage>
        <div *ngIf="isErrorVisible(addressForm.get('state'))" class="text-danger">
          <div *ngIf="addressForm.get('state').errors?.required">
            {{ 'REQUIRED' | translate }}
          </div>
        </div>
      </ng-container>
    </soldo-field-layout>

    <!-- CITY -->
    <div class="form-input" data-cy="Address_TextInput_City">
      <input-label forControl="city" [required]="fieldMetadata['city'].mandatory">
        {{ 'REGISTRATION.ADDRESS.CITY_LABEL' | translate }}
      </input-label>
      <input input type="text" class="form-control"
        placeholder="{{ 'REGISTRATION.ADDRESS.CITY_PLACEHOLDER' | translate }}" formControlName="city" name="city"
        [readOnly]="readonly">
      <div *ngIf="isErrorVisible(addressForm.get('city'))" class="text-danger">
        <div *ngIf="addressForm.get('city').errors?.required">{{ 'REQUIRED' | translate }}</div>
        <div *ngIf="addressForm.get('city').errors?.maxlength ">
          {{ 'MAX_LENGHT' | translate:{value: addressForm.get('city').errors?.maxlength.requiredLength} }}</div>
      </div>
    </div>

  </div>

  <!-- POSTCODE ROW -->
  <div class="form-input-row">
    <soldo-field-layout cypressSelector="Address_TextInput_Postcode" [control]="addressForm.controls['postcode']"
      fieldName="postcode" label="REGISTRATION.ADDRESS.POSTCODE_LABEL"
      [required]="fieldMetadata['postcode'].mandatory && !readonly">
      <input input type="text" class="form-control"
        placeholder="{{ 'REGISTRATION.ADDRESS.POSTCODE_PLACEHOLDER' | translate }}" formControlName="postcode"
        #postcodeInput (input)="postcodeInput.value=$event.target.value.toUpperCase()" name="postcode"
        [readOnly]="readonly">
      <ng-container errorMessage>
        <div *ngIf="isErrorVisible(addressForm.get('postcode'))" class="text-danger">
          <div *ngIf="addressForm.get('postcode').errors?.required">{{ 'REQUIRED' | translate }}</div>
          <div *ngIf="addressForm.get('postcode').errors?.maxlength ">
            {{ 'MAX_LENGHT' | translate:{value: addressForm.get('postcode').errors?.maxlength.requiredLength} }}</div>
          <div *ngIf="addressForm.get('postcode').errors?.pattern ">{{ 'WRONG_FORMAT' | translate }}</div>
        </div>
      </ng-container>
    </soldo-field-layout>

  </div>

  <input *ngIf="!simplified" type="hidden" formControlName="line1">
  <input *ngIf="!simplified" type="hidden" formControlName="line2">
  <input *ngIf="simplified" type="hidden" formControlName="street">
  <input type="hidden" formControlName="buildingName">
  <input *ngIf="simplified" type="hidden" formControlName="buildingNumber">
  <input type="hidden" formControlName="subBuilding">

</div>
