<h3 class="title">{{'REGISTRATION.COMPANY_STEP.SALES_CHANNEL.TITLE' | translate}}</h3>


<form [formGroup]="form" novalidate autocomplete="off">

  <soldo-field-layout
    [control]="form.controls['retailChannel']"
    fieldName="retailChannel"
    cypressSelector="Company_Checkbox_SalesChannel_RETAIL">

    <ng-container input>
      <input id="retailChannel" type="checkbox" (value)="true" formControlName="retailChannel">

      <label for="retailChannel">
        <span [innerHTML]="'REGISTRATION.COMPANY_STEP.SALES_CHANNEL.OPTION_RETAIL_LABEL' | translate"></span>
      </label>
    </ng-container>
  </soldo-field-layout>

  <soldo-field-layout
    [control]="form.controls['onlineChannel']"
    fieldName="onlineChannel"
    cypressSelector="Company_Checkbox_SalesChannel_ONLINE">

    <ng-container input>
      <input id="onlineChannel" type="checkbox" (value)="true" formControlName="onlineChannel">

      <label for="onlineChannel">
        <span [innerHTML]="'REGISTRATION.COMPANY_STEP.SALES_CHANNEL.OPTION_ONLINE_LABEL' | translate"></span>
      </label>
    </ng-container>
  </soldo-field-layout>

  <soldo-field-layout
    [control]="form.controls['otherChannelSelected']"
    fieldName="otherChannelSelected"
    cypressSelector="Company_Checkbox_SalesChannel_OTHER">

    <ng-container input>
      <input id="otherChannelSelected" type="checkbox" (value)="true" formControlName="otherChannelSelected">

      <label for="otherChannelSelected">
        <span [innerHTML]="'REGISTRATION.COMPANY_STEP.SALES_CHANNEL.OPTION_OTHER_LABEL' | translate"></span>
      </label>
    </ng-container>
  </soldo-field-layout>

  <div *ngIf="form.controls['otherChannel']" class="optional-freetext">
    <div class="left-spacer"></div>

    <soldo-field-layout
      [control]="form.controls['otherChannel']"
      fieldName="otherChannel"
      [required]="true"
      label="REGISTRATION.COMPANY_STEP.SALES_CHANNEL.OTHER_CHANNEL_LABEL"
      cypressSelector="Company_TextInput_OtherSalesChannel">

      <ng-container input>
        <p class="example">{{ 'REGISTRATION.COMPANY_STEP.SALES_CHANNEL.OTHER_CHANNEL_EXAMPLE'  | translate }}</p>
        <textarea
          name="otherChannel"
          formControlName="otherChannel"
          type="text" class="form-control"
          placeholder="">
        </textarea>
      </ng-container>

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value: form.get('otherChannel').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

  </div>

  <p *ngIf="form.errors?.selectOneOrMore" class="text-danger select-one-error">
    {{ 'ONE_OR_MORE_OPTIONS_REQUIRED' | translate }}
  </p>

</form>


<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.COMPANY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.COMPANY_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
