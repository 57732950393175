<header>
  <h3 class="title">{{ "REGISTRATION.BENEFICIAL_OWNERS.TITLE" | translate }}</h3>
  <p>{{ "REGISTRATION.BENEFICIAL_OWNERS.SUBTITLE" | translate }}</p>

  <button data-cy="UBO_Button_WhatIsUBO" type="button" (click)="openUBOHelpDialog()"
    class="btn btn-link what-is-ubo-button">
    {{'REGISTRATION.BENEFICIAL_OWNERS.WHAT_IS_UBO' | translate}}
  </button>

</header>


<p>{{'REGISTRATION.BENEFICIAL_OWNERS.SELECTOR_LABEL' | translate}}</p>
<div class="contact-selector" data-cy="UBO_Checkbox_ContactIsUBOArray">
  <div class="contact-selector-element" data-cy="UBO_Checkbox_ContactIsUBO" *ngFor="let contact of directorsAndAdmins; let i=index">
    <input type="checkbox" id="contact-{{i}}" [checked]="contact.ContactIsBeneficialOwner__c"
       #checkbox (change)="onDirectorOrAdminSelected(contact.SoldoContactExternalID__c, checkbox.checked)"
       [disabled]="!canAddMore() && !checkbox.checked">
    <label for="contact-{{i}}">
      {{ contact.FirstName }}
      {{ contact.LastName }}
    </label>
  </div>
</div>

<div *ngIf="beneficialOwnersForm.touched && (totalBeneficialOwners == 0)" class="at-least-one-bo-error">
  <span class="text-danger">{{ "REGISTRATION.BENEFICIAL_OWNERS.AT_LEAST_ONE" | translate }}</span>
</div>

<form [formGroup]="beneficialOwnersForm" (submit)="onSubmit()">

  <ng-container formArrayName="readOnlyContacts">
    <ng-container *ngFor="let bo of beneficialOwnersForm.controls.readOnlyContacts.controls">
      <div class="bo-form-block">
        <beneficial-owner-form  [boFormGroup]="bo" [readonly]="true"></beneficial-owner-form>
      </div>
    </ng-container>
  </ng-container>

  <ng-container formArrayName="contacts">
    <ng-container *ngFor="let bo of beneficialOwnersForm.controls.contacts.controls; let i=index">
      <div class="bo-form-block">

        <div class="bo-form-header">
          <h5>{{'REGISTRATION.BENEFICIAL_OWNERS.ADDITIONAL_BO' | translate: {number:(1+i)} }}</h5>
          <button class="btn gray-text" data-cy="UBO_Button_RemoveBeneficialOwner"
            (click)="removeBeneficialOwnerAt(i)">
            <span>{{ 'REMOVE' | translate }}</span>
          </button>
        </div>

        <beneficial-owner-form  [boFormGroup]="bo"></beneficial-owner-form>

      </div>
    </ng-container>
  </ng-container>

  <button data-cy="UBO_Button_AddBeneficialOwner" *ngIf="canAddMore()"
    class="add-button btn orange-on-white" (click)="$event.preventDefault(); addBeneficialOwner()">
    <span>{{'REGISTRATION.BENEFICIAL_OWNERS.ADD_BUTTON' | translate}}</span><i class="md-icon">add_circle</i>
  </button>

  <div class="row mt-5">
    <div class="col-6">
        <button type="button" class="btn gray-text go-back" (click)="goToPrevious()"
          data-cy="UBO_Button_StepBackButton"><i class="md-icon">arrow_right_alt</i>
          <span>{{ 'PREVIOUS' | translate }}</span>
        </button>
    </div>
    <div class="col-6 text-right">
        <button type="submit" class="btn btn-primary" data-cy="UBO_Button_StepSubmitButton"
          [disabled]="loading" [ngClass]="{ 'in-progress': loading}">
          {{ 'NEXT' | translate }}
        </button>
    </div>
  </div>
</form>
<save-and-continue-later stepName="Beneficial Owners"></save-and-continue-later>
