import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppConfig, APP_CONFIG } from '@src/app/app-config.module';
import { NGXLogger } from 'ngx-logger';
import { Application, ComplianceProofDocumentType, ComplianceProofType, ComplianceStatus } from '../models/registration.model';
import { RegistrationWebappPushApiService } from './registration-webapp-push-api.service';
import { RegistrationDataService } from './registrationData.service';
import { SoldoCookieService } from './soldo-cookie.service';
import { StatusService } from './status.service';

const GTM_EVENT_CALLBACK_TIMEOUT = 2000;

@Injectable()
export class GoogleTagManagerService {

    private clientId: string;
    public clientIdPromise: Promise<string>;
    private subscriptionProgress: number = -1;
    private eventsCategory: string = "Registration";
    private progression: string[] = [
        "Started",
        "Details",
        "AccountCreated",
        "DirectorCreated",
        "AdminCreated",
        "/success",
        "Terms"
    ];

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private logger: NGXLogger,
        private registrationDataService: RegistrationDataService,
        private soldoCookieService: SoldoCookieService,
        private statusService: StatusService,
        private pushAPI: RegistrationWebappPushApiService) {

        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            (j as HTMLScriptElement).async = true;
            (j as HTMLScriptElement).src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', config.googleTagManagerID);

        this.pushAPI.promise("ga-client-id").then(e => {
            this.clientId = e.value;
            this.registrationDataService.setGACID(e.value);
        });

        this.registrationDataService.statusAdvancedEventEmitter.subscribe((status: string) => {
            const ekycStatus = this.registrationDataService.getComplianceStatus();
            if (status == "Terms") {
                this.trackRegistrationCompleted(ekycStatus);
            }
        });

        this.registrationDataService.eKYCResultEventEmitter.subscribe((application: Application) => {
            if (application.complianceStatus
                && application.complianceStatus.status == ComplianceStatus.DOC_REQUIRED
                && (application.complianceStatus.docUploadMetadataList || []).length) {
                this.trackEKYCFail(application.complianceStatus.docUploadMetadataList.length);
            } else {
                this.trackEKYCPassed();
            }
        });
    }

    getCurrentProgressionStatus() {
        return this.progression[this.subscriptionProgress];
    }

    onNavigationEnd(routeSnapshot: ActivatedRouteSnapshot, statusName: string) {

        let progress = this.progression.indexOf(statusName);
        this.subscriptionProgress = Math.max(this.subscriptionProgress, progress);
        this.logger.debug("Setting progress to", this.subscriptionProgress, progress, "(now visiting ", statusName, ")");

        let pageEventParams: object = this.getDataLayerVariables();
        pageEventParams["url"] = location.href;
        pageEventParams["path"] = location.hash.replace('#', '');
        pageEventParams["category"] = this.eventsCategory;
        pageEventParams["title"] = routeSnapshot.data['pageName'] || "untitled";
        pageEventParams["event"] = "virtual_page_view";

        this.addToDataLayer(pageEventParams);
    }

    trackNewRegistrationStarted(planName: string, offerName: string): void {
        this.addToDataLayer({
            event: "Signup Started",
            label: planName + "-" + offerName
        });
    }

    trackSignedUp(): void {
        this.addToDataLayer({
            event: "Signup Completed",
            label: [this.registrationDataService.getPlanName(), this.registrationDataService.getOfferName()].join('-')
        });
    }

    trackRegistrationResumed(statusName: string): void {
        this.addToDataLayer({
            event: "Registration Resumed",
            label: statusName,
            resumedFromStatus: statusName
        });
    }

    trackRegistrationCompleted(eKYCPassed: ComplianceStatus): void {
        this.addToDataLayer({
            event: "Registration Completed",
            label: "Business KYB Form",
            value: "1",
            eKYCStatus: eKYCPassed == ComplianceStatus.COMPANY_PROVISIONED ? "pass" : "fail"
        });
    }

    trackStartOver(callback?: Function): void {
        this.addToDataLayer({
            event: "Start Over Next Registration"
        }, callback);
    }

    trackEKYCPassed(): void {
        this.addToDataLayer({
            event: "eKYC Passed"
        });
    }

    trackEKYCFail(numberOfRequiredDocuments: number): void {
        this.addToDataLayer({
            event: "eKYC Failed",
            label: numberOfRequiredDocuments,
            docsRequired: numberOfRequiredDocuments
        });
    }

    trackDocumentUploadConfirmed(proofType: ComplianceProofType, documentType: ComplianceProofDocumentType): void {
        this.addToDataLayer({
            event: "Document Uploaded",
            proofType: proofType,
            documentType: documentType,
            label: [proofType, documentType].join('-')
        });
    }

    trackSubStepViewed(subStepName: string) {
        this.addToDataLayer({
            event: "SubStep Viewed",
            subStepName: subStepName
        });
    }

    trackSubStepCompleted(subStepName: string) {
        this.addToDataLayer({
            event: "SubStep Completed",
            subStepName: subStepName
        });
    }

    emitExperimentInitEvent(experimentName: string): void {
        this.addToDataLayer({
            event: "init.experiment",
            experimentName: experimentName
        });
    }

    getDataLayerVariables(): object {
        const status = this.getCurrentProgressionStatus();
        const variables: object = {
            category: this.eventsCategory,
            uid: this.soldoCookieService.readRegistrationIdCookie(),
            product: this.registrationDataService.getProductName(),
            plan: this.registrationDataService.getPlanName(),
            offer: this.registrationDataService.getOfferName(),
            location: document.location.href || "undefinedValue",
            referrer: document.referrer || "undefinedValue"
        };

        if (this.clientId) {
            variables["cid"] = btoa(this.clientId);
        }

        const market = this.registrationDataService.getProductMarket();
        if (market !== null) {
            variables["market"] = market;
        }

        const companyOfIncorporation = this.registrationDataService.getCompanyCountry();
        if (companyOfIncorporation !== null) {
            variables["companyCountry"] = companyOfIncorporation;
        }

        if (status && status !== "Started") {
            variables["userType"] = this.registrationDataService.isSoleTrader() ? "soleTrader" : "nonSoleTrader";
            variables["companySize"] = this.registrationDataService.getCompanySize();
            variables["companyType"] = this.registrationDataService.getCompanyType();
        }

        return variables;
    }

    addToDataLayer(data, eventCallback?: Function) {
        if (typeof eventCallback == "function") {
            let callbackInvoked = false;
            let calledOnce = () => {
                if (!callbackInvoked) {
                    eventCallback();
                    callbackInvoked = true;
                }
            };
            data["eventCallback"] = calledOnce;
            setTimeout(calledOnce, GTM_EVENT_CALLBACK_TIMEOUT);
        }
        this.pushAPI.promise('gtm-consent-ready').then(preferences => {
            window["dataLayer"].push(Object.assign(this.getDataLayerVariables(), data));
        });
    }

    requestGoogleClientID(): Promise<string> {
        return this.pushAPI.promise("ga-client-id").then(e => {
            return e.value;
        });
    }

}
