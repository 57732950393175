
import { AfterViewChecked, Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { DynatraceHelperService } from '@shared/services/dynatraceHelper.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { I18nService } from '@shared/services/i18n.service';
import { IntercomIntegrationService } from '@shared/services/intercomIntegration.service';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { Status, StatusService } from "@shared/services/status.service";
import { StepService } from '@shared/services/steps.service';
import { NGXLogger } from 'ngx-logger';
import packageObject from 'package';
import { filter, map } from 'rxjs/operators';
import { AppConfig, APP_CONFIG } from './app-config.module';

@Component({
    selector: 'soldo-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.scss']
})

export class AppComponent implements OnInit, AfterViewChecked {
    appVersion: string;
    showVersion: boolean = true;
    title: string = 'Multi-Step Registration';
    environment: string;
    startOverButtonEnabled: boolean = false;
    isStaticRoute: boolean;

    constructor(
        private logger: NGXLogger,
        private router: Router,
        private route: ActivatedRoute,
        private stepsService: StepService,
        private registrationDataService: RegistrationDataService,
        private statusService: StatusService,
        private intercomIntegrationService: IntercomIntegrationService,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService,
        private dynatraceHelper: DynatraceHelperService,
        private i18nService: I18nService
    ) {
        this.dynatraceHelper.init();
        this.appVersion = packageObject["version"];
        this.environment = appConfig.env;
        this.showVersion = this.environment !== "prod";

        const startingHash = window.location.hash;
        const staticRouteRegexp = /^#\/static\/.+/;
        this.isStaticRoute = staticRouteRegexp.test(startingHash);
        // DISCARD CHECKS AND RESUME FOR STATIC ROUTES
        if (!this.isStaticRoute) {
            this.stepsService.init();
            // CHECK FOR PLAN & OFFER
            if (this.registrationDataService.isValidPlanOfferCombo()) {
                // NAVIGATE TO RESUME STATE
                const status: Status = this.statusService.getFsSubmissionStatus();
                const startState = status ? status.resumeFromState() : this.stepsService.getFirstStep().linkRoute();
                this.intercomIntegrationService.init();

                this.logger.debug("Initializing app at state: ", startState);
                this.router.navigate([startState], {
                    replaceUrl: true
                });
            } else {
                let baseURL = this.appConfig.invalidPlanOfferRedirectUrl[this.i18nService.languagetToCountry(this.i18nService.lang)];
                if (baseURL === undefined) {
                    baseURL = this.appConfig.invalidPlanOfferRedirectUrl.fallback;
                }
                window.location.href = baseURL;
            }
        }
    }

    ngOnInit() {

        // scroll top
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.route),
            map((route) => {
                let innerRoute = route;
                while (innerRoute.firstChild) {
                    innerRoute = innerRoute.firstChild;
                }
                return innerRoute;
            }),
            filter((route) => route.outlet === 'primary'),
            map((route) => route.snapshot))
            .subscribe((route) => {
                this.setRouteDependantCssClass(this.router.url);

                const statusByPage = this.statusService.getStatusByPage(this.router.url);
                this.gtmService.onNavigationEnd(route, statusByPage);
                this.intercomIntegrationService.onNavigationEnd(statusByPage);
                this.amplitudeService.trackPageView(route, statusByPage);
                this.dynatraceHelper.onNavigationEnd(route, statusByPage);
            });

        const currentStatus = this.statusService.getFsSubmissionStatus();
        const complianceStatus = this.registrationDataService.getComplianceStatus();
        this.startOverButtonEnabled = currentStatus ? currentStatus.key == "Terms" && complianceStatus == "KYB_PENDING" : false;
        this.logger.debug("Current status is", currentStatus);
        this.registrationDataService.statusAdvancedEventEmitter.subscribe((statusText: string) => {
            this.logger.debug("Status changed event received", statusText);
            this.startOverButtonEnabled = (statusText == "Terms" && this.registrationDataService.getComplianceStatus() == "KYB_PENDING");
        });
    }

    setRouteDependantCssClass(routeUrl): void {
        const routeClassRegx = /^route-.*$/;
        const bodyClassAttr = document.body.getAttribute("class");
        const bodyCssClasses = bodyClassAttr ? bodyClassAttr.split(" ") : [];
        const currentRouteCssClass = `route-${routeUrl.replace(/\//g, "-").replace(/^-/, "")}`;

        // Remove old
        for (const cssClass of bodyCssClasses) {
            if (routeClassRegx.test(cssClass)) {
                this.renderer.removeClass(document.body, cssClass);
            }
        }
        // Add new
        this.renderer.addClass(document.body, currentRouteCssClass);
    }

    ngAfterViewChecked(): void {
        const select = this.elementRef.nativeElement.querySelectorAll("input:not([autocomplete=nope])");
        select.forEach(element => {
            element.autocomplete = "nope"; // fake value to hack chrome behaviour
        });
    }

    startOver(e: MouseEvent): void {
        e.preventDefault();
        e.stopPropagation();

        this.gtmService.trackStartOver(() => {
            this.statusService.resetAndStartOver();
        });
    }

}
