import { Injectable } from '@angular/core';
import { RequestAggregatorService } from './requestAggregator.service';

export interface SaveAndContinueLaterDTO {
    success: boolean;
    fullName: string;
    email: string;
    soldoRegistrationResumeURL: string;
}

@Injectable({
    providedIn: 'root'
})
export class SaveAndContinueLaterService {

    // eslint-disable-next-line no-useless-constructor
    constructor(private requestAggregator: RequestAggregatorService) { }

    sendSaveAndContinueLaterEmail(registrationId: string, lang: string): Promise<SaveAndContinueLaterDTO> {
        return this.requestAggregator.sendSingleRequest<SaveAndContinueLaterDTO>({
            serviceName: 'soldoSalesforceRestService',
            serviceMethod: 'saveAndContinue',
            args: [{
                registrationExternalID: registrationId,
                lang: lang
            }]
        });
    }
}
