<h2>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.TITLE' | translate}}</h2>
<h5>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.SUB_TITLE_'+country | translate}}</h5>

<div class="layout-section" *ngIf="!isCompleteDataset">
  <div class="callout warning">
    <div class="callout-content">
      <p>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.ERROR_MESSAGE_FIRST_' + country | translate}}</p>
      <p>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.ERROR_MESSAGE_SECOND' | translate}}
        <strong>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.PLEASE_ENTER_MANUALLY' | translate}}</strong>.</p>
    </div>
  </div>
</div>

<div class="layout-section">
  <div class="company-details-container" *ngIf="searchResult">

    <h3 [attr.data-cy]="'company-name'">{{searchResult.name}}</h3>

    <div class="detail-title"><strong>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.REGISTERED_ADDRESS' | translate}}</strong></div>
    <div *ngIf="!isCompleteDataset" class="data-not-found">
      {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.NOT_FOUND' | translate}}
    </div>
    <div *ngIf="isCompleteDataset" class="company-registered-address">
      <div *ngIf="searchResult.registeredAddressLine1" [attr.data-cy]="'line-1'">{{searchResult.registeredAddressLine1}}</div>
      <div *ngIf="searchResult.registeredAddressLine2" [attr.data-cy]="'line-2'">{{searchResult.registeredAddressLine2}}</div>
      <div *ngIf="searchResult.city" [attr.data-cy]="'city'">{{searchResult.city}}</div>
      <div *ngIf="searchResult.country" [attr.data-cy]="'country'">{{searchResult.country}}</div>
      <div *ngIf="searchResult.postcode" [attr.data-cy]="'postcode'">{{searchResult.postcode}}</div>
    </div>

    <ng-container *ngIf="searchResult.status">
      <div class="detail-title"><strong>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.COMPANY_STATUS' | translate}}</strong></div>
      <div [attr.data-cy]="'status'">{{searchResult.status}}</div>
    </ng-container>

    <div class="detail-title" *ngIf="searchResult.directorProfileList?.length"><strong>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.DIRECTORS' | translate}}</strong></div>
    <ng-continer *ngFor="let director of searchResult.directorProfileList">
      <div><span class="director-last-name">{{director.lastName}}</span>, {{director.firstName}} {{director.middleName}}</div>
    </ng-continer>

    <ng-container *ngIf="searchResult.dateOfIncorporation">
      <div class="detail-title"><strong>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.INCORPORATION_DATE' | translate}}</strong></div>
      <div [attr.data-cy]="'date-of-incorporation'">{{searchResult.dateOfIncorporation}}</div>
    </ng-container>

    <ng-container *ngIf="searchResult.registrationNumber">
      <div class="detail-title"><strong>{{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.COMPANY_NUMBER' | translate}}</strong></div>
      <div [attr.data-cy]="'registration-number'">{{searchResult.registrationNumber}}</div>
    </ng-container>

  </div>
</div>

<div class="layout-section" *ngIf="isCompleteDataset">
  <p>
    {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.DATA_MISMATCH_PROMPT' | translate}}
    <br/>
    {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.DATA_MISMATCH_HELP_' + country | translate}}
  </p>

  <button data-cy="Signup_Button_EnterCompanyManually" type="button" class="link no-padding" (click)="enterManually()">
    {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.ENTER_MANUALLY' | translate}}
  </button>
</div>


<!-- Sub-Step Navigation -->

<div class="form-page-CTA-row">
  <button type="button" class="" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
    {{ 'REGISTRATION.SIGNUP_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
  </button>

  <button type="button" class="primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'loading': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH_DETAIL.ACCEPT' | translate}}
  </button>
</div>
