import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AbstractFormComponent } from '@shared/components/abstract-form.component';
import { CountryService, ICountryMetadata } from "@shared/services/country.service";
import { FiscalCodeService } from '@shared/services/fiscal-code.service';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { NGXLogger } from 'ngx-logger';
import moment from 'moment-mini';
import { distinctUntilChanged, map } from 'rxjs/operators';


@Component({
    selector: 'person-form-group',
    templateUrl: './person.component.html',
    styleUrls: ['./person.component.scss']
})
export class PersonComponent extends AbstractFormComponent implements OnInit {

    private static componentIdGlobal = 0;

    @Input('group')
    personForm: UntypedFormGroup;

    @Input()
    defaultMonth: string;

    @Input()
    readonly = false;

    @ViewChild('mobileNumberTextField')
    mobileNumberTextField: ElementRef<HTMLInputElement>;

    componentId: number;
    weekDays: { [day: string]: string };
    monthsLabels: { [index: number]: string };
    completeCountryList: ICountryMetadata[];
    market: string;

    constructor(
        private readonly countryService: CountryService,
        private readonly registrationDataService: RegistrationDataService,
        private readonly fiscalCodeService: FiscalCodeService,
        protected logger: NGXLogger
    ) {
        super();
        this.componentId = PersonComponent.componentIdGlobal++;
        this.countryService.countries.subscribe(allCountries => {
            this.completeCountryList = allCountries.filter(c => c.icc).sort(this.countryService.sortFunction);
        });

        this.market = this.registrationDataService.getCompanyCountry();
    }

    ngOnInit() {
        const phoneField = this.personForm.get("phone");
        if (phoneField) {
            const phoneNumberField = phoneField.get('number');
            const phonePrefixField = phoneField.get('prefix');

            phoneNumberField.valueChanges.subscribe((value: string) => {

                switch (phonePrefixField.value) {
                    case "+44":
                    case "+33":
                        if (value?.startsWith("0")) {
                            const cursorPosition = this.mobileNumberTextField.nativeElement.selectionStart;
                            phoneNumberField.setValue(value.substring(1), {
                                emitEvent: false
                            });
                            this.mobileNumberTextField.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
                        }
                        break;
                }

            });
        }
        this.personForm.valueChanges.pipe(
            map(formValues => {
                return formValues.fiscalCode;
            }),
            distinctUntilChanged()
        ).subscribe(() => {
            this.onCodiceFiscaleChange();
        });
    }

    onKeyDown(event: any) {
        return (this.isDigitKeyCode(event.key) && event.target.value.length < 3) || this.isManipulationKeyCode(event.keyCode);
    }

    private isDigitKeyCode(key: string) {
        return (key >= "0" && key <= "9");
    }

    private isManipulationKeyCode(keyCode: number) {
        switch (keyCode) {
            case 8: // Backspace
            case 9: // Tab
            case 11: // Enter
            case 16: // shift
            case 17: // Alt
            case 18: // ctrl
            case 35: // Home
            case 36: // End
            case 37: // arrows...
            case 38:
            case 39:
            case 40:
            case 46: // del
            case 91: // cmd
                return true;
            default:
                return false;
        }
    }

    getCitizenshipLabel(): string {
        return this.completeCountryList?.find(c => c.alpha_3 === this.personForm.get('citizenship').value).name;
    }

    getIcc(): ICountryMetadata {
        return this.completeCountryList?.find(c => c.icc === this.personForm.get('phone').value?.prefix);
    }

    getPhoneValue(): string {
        const phone = this.personForm.get('phone');
        return `${phone.get('prefix').value} ${phone.get('number').value}`;
    }

    private onCodiceFiscaleChange() {
        const fiscalCodeControl = this.personForm.get("fiscalCode");
        if (!fiscalCodeControl?.value) {
            return;
        }
        const fiscalCode = this.personForm.get("fiscalCode").value;
        this.logger.debug("fiscalCode",fiscalCode);
        if (this.personForm.get("fiscalCode").valid) {


            const placeOfBirth = this.fiscalCodeService.retrieveBirthPlace(fiscalCode);
            const dob = FiscalCodeService.retrieveBirthDate(fiscalCode);

            if (placeOfBirth) {
                this.personForm.patchValue({
                    "dob": moment(dob).format('DD/MM/YYYY'),
                    "birthPlace": placeOfBirth.town,
                    "birthState": placeOfBirth.state,
                    "gender": FiscalCodeService.refetrieveSex(fiscalCode)
                });
            } else {
                this.personForm.patchValue({
                    "dob": moment(dob).format('DD/MM/YYYY'),
                    "birthPlace": '',
                    "birthState": '',
                    "gender": FiscalCodeService.refetrieveSex(fiscalCode)
                });
            }
        }
        this.personForm.get('lastName').markAsTouched();
        this.personForm.get('firstName').markAsTouched();
    }
}
