import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";

import { APP_CONFIG, AppConfig } from '@src/app/app-config.module';
import { NGXLogger } from 'ngx-logger';
import { take } from 'rxjs/operators';


/**
 * getLead ottiene una lead o ritorna errore
 * createOrUpdateLead crea o aggiorna una lead
 * getAccount
 * createOrUpdateAccount
 * getContacts
 * createOrUpdateContacts

 */

@Injectable()
export class RequestAggregatorService {
    private currentAggregatedRequest: AggregatedRequestWrapper = null;
    private lastAggregatedRequest: AggregatedRequestWrapper = null;

    constructor(
        private logger: NGXLogger,
        private http: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {}

    sendSingleRequest<T>(body: ISingleRequestBody): Promise<T> {
        const { serviceName, serviceMethod, args } = body;
        const request = this.createMethodRequest<T>(serviceName, serviceMethod);
        this.addRequest(request);
        const promise = request.callMethod(...args).pipe(take(1)).toPromise();
        this.sendCurrentAggegatedRequest();
        return promise;
    }

    createMethodRequest<T>(serviceName: string, methodName: string) {
        const q = new Subject<T>();
        return new PlatformRequest<T>(q, serviceName, methodName);
    }

    addRequest(request: PlatformRequest<any>, requestId?: string) {
        if (request instanceof PlatformRequest) {
            if (this.currentAggregatedRequest == null) {
                this.currentAggregatedRequest = new AggregatedRequestWrapper(this.logger);
            }
            this.currentAggregatedRequest.addRequest(request, requestId);
        }
    }

    sendCurrentAggegatedRequest(): Observable<{ [key: string]: any }> {
        const arw = this.lastAggregatedRequest = this.currentAggregatedRequest;
        this.currentAggregatedRequest = null;
        return this.sendAggregatedRequest(arw);
    }

    sendAggregatedRequest(aggregateRequestWrapper: AggregatedRequestWrapper): Observable<{ [key: string]: any }> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const deferred = new Subject<{ [key: string]: any }>();
        this.http.post(this.config.aggregatorUrlEndpoint,
            {
                id: aggregateRequestWrapper.compileAggregatedRequest().id,
                aggregate: aggregateRequestWrapper.compileAggregatedRequest().aggregate
            },
            {
                headers: headers
            })
            .subscribe((response: IAggregatedResponse) => {
                deferred.next(aggregateRequestWrapper.setResponse(response));
                deferred.complete();
            });
        return deferred.asObservable();
    }
}

export class AggregatedRequestWrapper {
    private requestIdCounter: number = 0;
    id: string = "BusinessRegistration";
    aggregate: {
        [key: string]: {
            [key: string]: PlatformRequest<any>
        }
    };


    constructor(private logger: NGXLogger, context?: string) {
        this.id = context || "BusinessRegistration";
        this.aggregate = {};
    }

    private generateRequestId() {
        return "rid-" + (this.requestIdCounter++);
    }

    addRequest(request: PlatformRequest<any>, requestId?: string): void {
        const serviceName = request.serviceName;
        if (this.aggregate[serviceName] === undefined) {
            this.aggregate[serviceName] = {};
        }
        this.aggregate[serviceName][requestId || this.generateRequestId()] = request;
    }

    setResponse(response: IAggregatedResponse): { [key: string]: any } {
        const resultMap = {};

        for (const serviceName in this.aggregate) {
            for (const requestId in this.aggregate[serviceName]) {
                const request = this.aggregate[serviceName][requestId];

                resultMap[requestId] = null;

                if (!response.aggregated[serviceName] || !response.aggregated[serviceName][requestId]) {
                    // request.deferred.reject(new PlatformResponseErrorResponseMissing());
                } else if (response.aggregated[serviceName][requestId].error) {
                    const methodResponse = response.aggregated[serviceName][requestId];
                    // var error: IPlatformResponseError = {
                    //     result: response.aggregated[serviceName][requestId].error
                    // };
                    request.deferred.error({
                        error: methodResponse.error,
                        result: methodResponse.result,
                        methodName: methodResponse.methodName
                    });
                } else {
                    const result = response.aggregated[serviceName][requestId].result;
                    resultMap[requestId] = result;
                    request.deferred.next(result);
                }

                request.deferred.complete();
            }
        }

        return resultMap;
    }

    setAllRejected(result) {
        for (const serviceName in this.aggregate) {
            for (const requestId in this.aggregate[serviceName]) {
                const request = this.aggregate[serviceName][requestId];
                request.deferred.error(result);
                request.deferred.complete();
            }
        }
    }

    compileAggregatedRequest(): IAggregatedRequest {
        const agro: IAggregatedRequest = {
            id: this.id,
            aggregate: {}
        };

        for (const serviceName in this.aggregate) {
            agro.aggregate[serviceName] = {};
            for (const requestId in this.aggregate[serviceName]) {
                const request = this.aggregate[serviceName][requestId];
                if (request.params == null) {
                    throw new Error("Cannot compile aggregated request. Request " + request.serviceName + "." + request.methodName + " has no params");
                }
                agro.aggregate[serviceName][requestId] = {
                    complexParams: true,
                    methodName: request.methodName,
                    params: request.params
                };
            }
        }

        return agro;
    }
}

export class PlatformRequest<T> {

    constructor(
        public deferred: Subject<T>,
        public serviceName: string,
        public methodName: string,
        public params?: any[]
    ) { }

    callMethod(...args: any[]): Observable<T> {
        if (this.params != null) {
            throw new Error("Parameters for PlatformRequest " + this.serviceName + "." + this.methodName + " were already set");
        }
        this.params = Array.prototype.slice.apply(arguments, [0]);
        return this.deferred.asObservable();
    }
}

export class PlatformResponseErrorResponseMissing implements IPlatformResponseError {
    result: "missing_response";
}

export interface IAggregatedRequest {
    id: string;
    aggregate: {
        [key: string]: {
            [key: string]: {
                methodName: string,
                params: any[],
                complexParams: boolean
            }
        }
    };
}

export interface IPlatformResponseError {
    result: any;
}

export interface IPlatformResponse<T> {
    methodName: string;
    error?: boolean;
    result?: T;
}

export interface IAggregatedResponse {
    aggregated: {
        [key: string]: {
            [key: string]: IPlatformResponse<any>
        }
    };
}

export interface ISingleRequestBody {
    serviceName: string;
    serviceMethod: string;
    args: any[];
}
