import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { RegistrationDataService } from '../../services/registrationData.service';
import { ResumeInfo } from '../../models/resume.model';
import { TranslateService } from '@ngx-translate/core';
import { StatusService } from '../../services/status.service';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';


@Component({
    selector: 'resume-overlay',
    templateUrl: './resume-overlay.component.html',
    styleUrls: ['./resume-overlay.component.scss']
})
export class ResumeOverlayComponent implements OnInit, OnDestroy {


    isResume: boolean;
    resumeInfo: ResumeInfo;

    private _market: string;
    private _plan: string;
    private _offer: string;

    constructor(
        private registrationDataService: RegistrationDataService,
        private statusService: StatusService,
        private renderer: Renderer2,
        private translateService: TranslateService,
        private gtmService: GoogleTagManagerService
    ) { }

    ngOnInit() {
        this._market = this.registrationDataService.getCompanyCountry();
        this._plan = this.registrationDataService.getPlanName();
        this._offer = this.registrationDataService.getOfferName();

        this.isResume = this.statusService.showResumeOverlay();

        if (this.isResume) {
            this.renderer.addClass(document.body, 'modal-is-open');
            this.resumeInfo = {
                contactEmail: this.registrationDataService.getContactEmail(),
                companyName: this.registrationDataService.getCompanyName(),
                planName: this._getPlanName()
            };
        }
    }

    ngOnDestroy() {
        this.renderer.removeClass(document.body, 'modal-is-open');
    }

    dismiss() {
        this.isResume = false;
        this.renderer.removeClass(document.body, 'modal-is-open');
    }

    reset() {
        if (confirm(this.translateService.instant('RESUME.CONFIRM'))) {
            this.gtmService.trackStartOver(() => {
                this.statusService.resetAndStartOver();
            });
        }
    }

    private _getPlanName(): string | null {
        switch (this._plan) {
            case "SoldoStart":
                return 'RESUME.OFFER_PLAN.PLAN_START';
            case "SoldoPro":
                return 'RESUME.OFFER_PLAN.PLAN_PRO';
            case "Business":
                return 'RESUME.OFFER_PLAN.PLAN_PREMIUM';
            case "FuelStart":
                return 'RESUME.OFFER_PLAN.PLAN_DRIVEPAY';
            case "Fuel":
                return 'RESUME.OFFER_PLAN.PLAN_DRIVEPRO';
            case "Plan_25_2402":
                return 'RESUME.OFFER_PLAN.PLAN_STANDARD';
            case "Plan_26_2402":
                return 'RESUME.OFFER_PLAN.PLAN_STANDARD_40';
            case "Plan_27_2402":
                return 'RESUME.OFFER_PLAN.PLAN_STANDARD_80';
            case "Plan_28_2402":
                return 'RESUME.OFFER_PLAN.PLAN_PLUS';
            case "Plan_29_2402":
                return 'RESUME.OFFER_PLAN.PLAN_PLUS_40';
            case "Plan_30_2402":
                return 'RESUME.OFFER_PLAN.PLAN_PLUS_80';
            case "Plan_31_2402":
            case "Plan_32_2402":
            case "Plan_33_2402":
            case "Plan_34_2402":
                return 'RESUME.OFFER_PLAN.PLAN_ENTERPRISE';
        }
    }

}
