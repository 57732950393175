import { Component, OnInit, Type, ViewChild, ViewEncapsulation } from '@angular/core';
import { SubstepContainerDirective } from '@shared/components/substep-container.directive';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { Account, DEFAULT_COMPANY_NAME } from '@shared/models/registration.model';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { StatusService } from '@shared/services/status.service';
import { NavigationDirection, StepService } from '@shared/services/steps.service';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { CompanySubStepOutboundAction, CompanySubStepType } from './company-step.model';
import { CompanyDescriptionComponent } from './substeps/company-description/company-description.component';
import { CompanyIdentifiersComponent } from './substeps/company-identifiers/company-identifiers.component';
import { CompanyRegisteredAddressComponent } from './substeps/company-registered-address/company-registered-address.component';
import { CompanyTradingAddressComponent } from './substeps/company-trading-address/company-trading-address.component';
import { CompanySalesChannelComponent } from './substeps/company-sales-channel/company-sales-channel.component';
import { CompanyInternationalComponent } from './substeps/company-international/company-international.component';

@Component({
    selector: 'app-company-step',
    templateUrl: './company-step.component.html',
    styleUrls: ['./company-step.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CompanyStepComponent implements OnInit {
    @ViewChild(SubstepContainerDirective)
    subStepContainer: SubstepContainerDirective;
    activeSubStepInstance: CompanySubStepType;
    activeSubStepActionSubscription: Subscription;
    activeSubStep: number = 0;

    // true disables controls
    loading: boolean = false;

    subStepComponentTypes: Array<Type<CompanySubStepType>> = [
        CompanyIdentifiersComponent,
        CompanyRegisteredAddressComponent,
        CompanyTradingAddressComponent,
        CompanySalesChannelComponent,
        CompanyInternationalComponent,
        CompanyDescriptionComponent
    ];

    account: Account = null;
    isSoleTrader: boolean = false;

    absoluteProgressBySubStep: Map<Type<CompanySubStepType>, number> = new Map();

    constructor(
        protected logger: NGXLogger,
        private statusService: StatusService,
        private stepService: StepService,
        private registrationDataService: RegistrationDataService) { }

    ngOnInit() {
        this.account = this._retrieveAccountData();
        this.isSoleTrader = this.account.SoleTrader__c;

        if (this.account.kybProviderId) {
            if (this._hasIdentifiers() && this._hasRegisteredAddress()) { // complete search result, skip steps
                this.subStepComponentTypes = [
                    CompanyTradingAddressComponent,
                    CompanySalesChannelComponent,
                    CompanyInternationalComponent,
                    CompanyDescriptionComponent
                ];
            } else { // incomplete search result, treat it as a manual entry
                this.account.kybProviderId = null;
                if (this._hasIdentifiers()) { // fast forward to address
                    this.activeSubStep = this.subStepComponentTypes.indexOf(CompanyRegisteredAddressComponent);
                }
            }
        }

        const currentStatus = this.statusService.getFsSubmissionStatus();
        if (currentStatus.key != "Details" && this.stepService.getLastNavigationDirection() == NavigationDirection.BACKWARD) {
            this.activeSubStep = this.subStepComponentTypes.length - 1;
        }

        this.subStepComponentTypes.forEach((subStepType, index) => {
            this.absoluteProgressBySubStep.set(subStepType, index);
        });
    }

    _hasIdentifiers(): boolean {
        const requiredIdentifiers = [
            this.account.CompanyRegistrationNumber__c,
            this.account.Name
        ];
        return requiredIdentifiers.every((fieldValue) => {
            return fieldValue && fieldValue !== "";
        });
    }

    _hasRegisteredAddress(): boolean {
        const requiredAddressFields = [
            this.account.RegisteredAddressLine1__c,
            this.account.RegisteredAddressPostalCode__c,
            this.account.RegisteredAddressCity__c
        ];
        return requiredAddressFields.every((fieldValue) => {
            return fieldValue && fieldValue !== "";
        });
    }

    onSubstepRendered(renderedSubstepInstance: CompanySubStepType) {
        this.activeSubStepInstance = renderedSubstepInstance;
        this.activeSubStepInstance.init(this.account);
        this.activeSubStepInstance.trackSubStepViewed();

        this.activeSubStepActionSubscription = this.activeSubStepInstance.controlActionEventEmitter.subscribe((action) => {
            this.substepOutboundActionHandler(action);
        });

        const subStepProgress = this.absoluteProgressBySubStep.get(this.subStepComponentTypes[this.activeSubStep]);
        this.stepService.updateStepProgress(subStepProgress);
    }

    substepOutboundActionHandler(a: CompanySubStepOutboundAction) {
        switch (a.action) {
            case DefaultOutboundActions.NEXT:
                this.nextSubStep(a.data);
                break;
            case DefaultOutboundActions.PREVIOUS:
                this.previousSubStep();
                break;
        }
    }

    previousSubStep() {
        this.activeSubStepInstance.loading = true;
        this.activeSubStepActionSubscription.unsubscribe();

        if (this.activeSubStep > 0) {
            this.activeSubStep--;
            this._scrollTop(() => {
                this.activeSubStepInstance.loading = false;
            });
        } else {
            // Navigate to previous Step (signup)
            this.stepService.navigateToPreviousStep();
        }
    }

    nextSubStep(d: Partial<Account>) {
        this.logger.debug("Advancing to next substep");

        this.activeSubStepInstance.loading = true;

        Object.assign(this.account, d);

        this.logger.debug("Form is valid. Current data: ", this.account);

        this.activeSubStepActionSubscription.unsubscribe();

        if (this.activeSubStep == this.subStepComponentTypes.length - 1) {
            this.activeSubStepInstance.loading = true;
            this.submitAccountData();
        } else {
            this.activeSubStepInstance.trackSubStepCompleted();
            this.activeSubStep++;
            this._scrollTop(() => {});
        }
    }

    submitAccountData(): void {

        this.activeSubStepInstance.loading = true;

        const updatedFsSubmissionStatus = this.statusService.getFsSubmissionStatusValidIncrement("AccountCreated");
        this.account.FsApplicationSubmissionStatus__c = updatedFsSubmissionStatus;

        this.registrationDataService.saveAccount(this.account);

        this.registrationDataService.submitAccountForFSConvert().then(() => {
            this.statusService.setStatusIncrementAfterSubmission(updatedFsSubmissionStatus);
            this.stepService.navigateToNextStep();
        }, (err) => this.logger.trace("FATAL: ", err));

    }

    _retrieveAccountData(): Account {
        const lead = this.registrationDataService.getLead();
        let account: Account = this.registrationDataService.getAccount();
        const fsSubmisstionStatus = this.statusService.getFsSubmissionStatus();

        if (fsSubmisstionStatus.key === "Details") {
            if (account !== null) { // hand over
                account = this.registrationDataService.initAccount({
                    Name: account.Name,
                    RegisteredAddressCountryISO__c: account.RegisteredAddressCountryISO__c,
                    CompanyType__c: account.CompanyType__c,
                    CompanyTypeDescription__c: account.CompanyTypeDescription__c,
                    SoldoRegistrationExternalID__c: account.SoldoRegistrationExternalID__c,
                    SoleTrader__c: account.SoleTrader__c,
                    CompanyReasonForUse__c: account.CompanyReasonForUse__c,
                    PlanName__c: account.PlanName__c,
                    OfferName__c: account.OfferName__c,
                    DiscountCode__c: account.DiscountCode__c,
                    kybProviderId: account.kybProviderId,
                    NumberOfEmployees: account.NumberOfEmployees
                });
            } else {
                account = this.registrationDataService.constructAccountEntity({
                    Name: lead.Company == DEFAULT_COMPANY_NAME ? "" : lead.Company,
                    RegisteredAddressCountryISO__c: lead.RegisteredAddressCountryISO__c,
                    CompanyType__c: lead.CompanyType__c,
                    CompanyTypeDescription__c: lead.CompanyTypeDescription__c,
                    SoldoRegistrationExternalID__c: lead.SoldoRegistrationExternalID__c,
                    SoleTrader__c: lead.SoleTrader__c,
                    CompanyReasonForUse__c: lead.CompanyReasonForUse__c,
                    PlanName__c: lead.PlanName__c,
                    OfferName__c: lead.OfferName__c,
                    DiscountCode__c: lead.DiscountCode__c,
                    kybProviderId: lead.kybProviderId,
                    NumberOfEmployees: lead.NumberOfEmployees
                });
            }
        }

        const companyLookupResult = this.registrationDataService.getCompanySearchLookupProfile(account.kybProviderId);

        if (companyLookupResult) {
            account.RegisteredAddressCity__c = companyLookupResult.city;
            account.RegisteredAddressLine1__c = companyLookupResult.registeredAddressLine1;
            account.RegisteredAddressLine2__c = companyLookupResult.registeredAddressLine2;
            account.RegisteredAddressPostalCode__c = companyLookupResult.postcode;
            account.CompanyRegistrationNumber__c = companyLookupResult.registrationNumber;
            if (companyLookupResult.state) {
                account.RegisteredAddressState__c = companyLookupResult.state;
            }
            if (companyLookupResult.vatNumber) {
                account.CompanyVATNumber__c = companyLookupResult.vatNumber;
            }
        }


        return account;
    }

    private _scrollTop(callback: () => void) {
        setTimeout(() => {
            window.scrollTo({ top: 0/*, behavior: 'smooth'*/ });
            callback();
        }, 0);
    }
}
