<div class="registration">

  <!-- steps in the header -->
  <registration-nav *ngIf="progressBarVisible"></registration-nav>

  <div class="main">
    <!-- Routed view  -->
    <router-outlet></router-outlet>
  </div>

  <!-- For Debugging: show our valid formData -->
  <!--<pre>{{ formData | json }}</pre>-->
</div>
<!--app-footer></app-footer-->
