<h3 class="title">{{'REGISTRATION.COMPANY_STEP.REGISTERED_ADDRESS.TITLE' | translate}}</h3>
<p class="subtitle" *ngIf="selectedCountry == 'GBR'">
  {{'REGISTRATION.COMPANY_STEP.REGISTERED_ADDRESS.GBR_SEARCH_HELP' | translate}}
</p>
<p class="title_form">{{'REGISTRATION.COMPANY_STEP.REGISTERED_ADDRESS.TITLE_FORM' | translate}}</p>


<form [formGroup]="form" novalidate autocomplete="off">

  <div data-cy="Company_Address_RegisteredAddress">

    <address2-form-group
      [group]="form"
      [activeMarketsOnly]="true"
      [simplified]="true"
      data-cy="Company_Address_RegisteredAddress">
    </address2-form-group>

  </div>
</form>

<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.COMPANY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.COMPANY_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
