<div class="loading-background" *ngIf="loading">
  <div class="loading-screen"></div>
</div>

<div class="idv-landing">
  <header>
    <h2 class="v2">{{ "REGISTRATION.ONFIDO_LINK_PAGE.TITLE" | translate }}</h2>

    <div class="icon">
      <img src="../../../assets/images/doc_upload.png">
    </div>

  </header>

  <p [innerHTML]="'REGISTRATION.ONFIDO_LINK_PAGE.PARAGRAPH_1' | translate : {name: contactFirstName ?? ''}"></p>

  <p [innerHTML]="'REGISTRATION.ONFIDO_LINK_PAGE.NECESSARY_STEPS_POA_REQUIRED' | translate"></p>

  <div class="cta-container">
    <button class="btn btn-primary" (click)="callToActionOnClick()">
      {{ "REGISTRATION.ONFIDO_LINK_PAGE.CTA" | translate }}</button>
  </div>

  <p [innerHTML]="'REGISTRATION.ONFIDO_LINK_PAGE.PARAGRAPH_3' | translate"></p>

  <p class="onfido-logo">
    <img src="../../../assets/images/onfido-logo.svg">
  </p>

</div>
