import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CountryService, ICountryMetadata } from '../../../shared/services/country.service';
import { CustomValidators } from '../../../shared/customValidators';
import { Contact } from '../../../shared/models/registration.model';

export interface BeneficialOwnerFormGroup {
    contactId: FormControl<string>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    nationality: FormControl<string>;
    countryOfResidence: FormControl<string>;
    email: FormControl<string>;
}

@Component({
    selector: 'beneficial-owner-form',
    templateUrl: './beneficial-owner-form.component.html',
    styleUrls: ['./beneficial-owner-form.component.scss']
})
export class BeneficialOwnerFormComponent implements OnInit {

    @Input()
    boFormGroup: FormGroup<BeneficialOwnerFormGroup>;

    defaultValidators: {[k in keyof BeneficialOwnerFormGroup]: ValidatorFn[]} = {
        contactId: [],
        firstName: [Validators.required, CustomValidators.getContactMaxlengthValidator("FirstName")],
        lastName: [Validators.required, CustomValidators.getContactMaxlengthValidator("LastName")],
        nationality: [Validators.required],
        countryOfResidence: [Validators.required],
        email: [Validators.required, CustomValidators.validEmail, CustomValidators.getContactMaxlengthValidator("Email")]
    };

    @Input()
    readonly: boolean = false;
    completeCountryList: ICountryMetadata[];

    constructor(
        private countryService: CountryService) {

        this.countryService.countries.subscribe(allCountries => {
            this.completeCountryList = allCountries.filter(c => c.icc);
            this.completeCountryList.sort(this.countryService.sortFunction);
        });
    }

    ngOnInit(): void {
        if (this.readonly) {
            for (const control of Object.values(this.boFormGroup.controls)) {
                control.setValidators([]);
            }
        } else {
            for (const [key, control] of Object.entries(this.boFormGroup.controls)) {
                control.setValidators(this.defaultValidators[key]);
            }
        }
    }

    getNationalityLabel(): string {
        const nationality = this.boFormGroup.value.nationality;
        return this.completeCountryList?.find(c => c.alpha_3 === nationality)?.name;
    }

    getCountryOfResidenceLabel(): string {
        const countryOfResidence = this.boFormGroup.value.countryOfResidence;
        return this.completeCountryList?.find(c => c.alpha_3 === countryOfResidence)?.name;
    }

    static createBeneficialOwnerFormGroupForContact(fb: FormBuilder, contact: Contact): FormGroup<BeneficialOwnerFormGroup> {
        return fb.group({
            contactId: [contact.SoldoContactExternalID__c],
            firstName: [contact.FirstName],
            lastName: [contact.LastName],
            nationality: [contact.ContactCitizenshipCountryISO__c],
            countryOfResidence: [contact.ResidentialAddressCountryISO__c],
            email: [contact.Email]
        });
    }

    static createEmptyBeneficialOwnerFormGroup(fb: FormBuilder): FormGroup<BeneficialOwnerFormGroup> {
        return fb.group({
            contactId: [null],
            firstName: [null],
            lastName: [null],
            nationality: [null],
            countryOfResidence: [null],
            email: [null]
        });
    }
}
