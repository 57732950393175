<h2>{{ "REGISTRATION.BENEFICIAL_OWNERS.TITLE" | translate }}</h2>
<h5>{{ "REGISTRATION.BENEFICIAL_OWNERS.SUBTITLE" | translate }}</h5>

<button data-cy="UBO_Button_WhatIsUBO" type="button" (click)="openUBOHelpDialog()" class="link no-padding">
  {{'REGISTRATION.BENEFICIAL_OWNERS.WHAT_IS_UBO' | translate}}
</button>

<div class="layout-section">

  <p>{{'REGISTRATION.BENEFICIAL_OWNERS.SELECTOR_LABEL' | translate}}</p>
  <div data-cy="UBO_Checkbox_ContactIsUBOArray" class="form-input"
    [ngClass]="{ 'ng-touched': beneficialOwnersForm.touched }">
    <div data-cy="UBO_Checkbox_ContactIsUBO" *ngFor="let contact of directorsAndAdmins; let i=index">
      <input type="checkbox" id="contact-{{i}}" [checked]="contact.ContactIsBeneficialOwner__c" #checkbox
        (change)="onDirectorOrAdminSelected(contact.SoldoContactExternalID__c, checkbox.checked)"
        [disabled]="!canAddMore() && !checkbox.checked">
      <label for="contact-{{i}}">
        {{ contact.FirstName }}
        {{ contact.LastName }}
      </label>
    </div>

    <div class="validation-errors-container">
      <div class="field-validation-error caption" *ngIf="totalBeneficialOwners == 0">
        {{ 'REGISTRATION.BENEFICIAL_OWNERS.AT_LEAST_ONE' | translate }}
      </div>
    </div>
  </div>

</div>

<form [formGroup]="beneficialOwnersForm" (submit)="onSubmit()">

  <ng-container formArrayName="readOnlyContacts">
    <ng-container *ngFor="let bo of beneficialOwnersForm.controls.readOnlyContacts.controls">
      <div class="layout-section">
        <beneficial-owner-form [boFormGroup]="bo" [readonly]="true"></beneficial-owner-form>
      </div>
    </ng-container>
  </ng-container>

  <ng-container formArrayName="contacts">
    <ng-container *ngFor="let bo of beneficialOwnersForm.controls.contacts.controls; let i=index">
      <div class="layout-section">

        <div class="contact-section-header">
          <h5><strong>{{'REGISTRATION.BENEFICIAL_OWNERS.ADDITIONAL_BO' | translate: {number:(1+i)} }}</strong></h5>
          <button type="button" class="link no-padding" data-cy="UBO_Button_RemoveBeneficialOwner" (click)="removeBeneficialOwnerAt(i)">
            <span>{{ 'REMOVE' | translate }}</span>
          </button>
        </div>

        <beneficial-owner-form [boFormGroup]="bo"></beneficial-owner-form>

      </div>
    </ng-container>
  </ng-container>

  <button type="button" data-cy="UBO_Button_AddBeneficialOwner" *ngIf="canAddMore()"
    (click)="$event.preventDefault(); addBeneficialOwner()">
    <span>{{'REGISTRATION.BENEFICIAL_OWNERS.ADD_BUTTON' | translate}}</span><typeface-icon
      class="md-icon">add_circle</typeface-icon>
  </button>


  <div class="form-page-CTA-row">

    <button type="button" (click)="goToPrevious()" data-cy="UBO_Button_StepBackButton">
      {{ 'PREVIOUS' | translate }}
    </button>

    <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}"
      data-cy="UBO_Button_StepSubmitButton">
      {{ 'NEXT' | translate}}
    </button>

  </div>

</form>

<save-and-continue-later stepName="Beneficial Owners"></save-and-continue-later>
