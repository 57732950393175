
import {take} from 'rxjs/operators';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { APP_CONFIG, AppConfig } from '@src/app/app-config.module';

@Injectable()
export class DynatraceHelperService {
    private dtrumLibReadyEmitter: EventEmitter<void> = new EventEmitter();
    private dtrumLibReadyPromise: Promise<void>;
    private dtrum: any;

    constructor(
        private logger: NGXLogger,
        @Inject(APP_CONFIG) private config: AppConfig) {

        this.dtrumLibReadyPromise = this.dtrumLibReadyEmitter.pipe(take(1)).toPromise();
    }

    public init() {
        if (this.config.enableDynatraceTracking) {
            this.logger.debug("Installing Dynatrace RUM tag.");
            this.injectDynatraceTag();
        } else {
            this.logger.debug("Dynatrace monitoring is disabled on this environment.");
        }
    }
    // <script type="text/javascript"
    //    src=""
    //    crossorigin="anonymous"></script>
    private injectDynatraceTag(): void {
        let scriptTag = document.createElement('script');
        scriptTag.src = 'https://js-cdn.dynatrace.com/jstag/1623d230d17/bf56698awf/272af2de0c6a1af6_complete.js';
        scriptTag.type = 'text/javascript';
        scriptTag.async = true;
        scriptTag.crossOrigin = 'anonymous';

        document.getElementsByTagName('head')[0].appendChild(scriptTag);

        this.dtrumLibReadyPromise.then(() => {
            this.dtrum.enableManualPageDetection();
        });

        this._pingDTRUM(2000);
    }

    private _pingDTRUM(timeout) {
        if (timeout <= 0) {
            this.logger.warn("dtrum library not found - timeout exceeded");
        } else {
            const dtrumLib = window["dtrum"];

            if (!dtrumLib) {
                setTimeout(() => {
                    this._pingDTRUM(timeout - 200);
                }, 200);
            } else {
                this.logger.info("dtrum library ready");
                this.dtrum = dtrumLib;
                this.dtrumLibReadyEmitter.emit();
            }
        }
    }

    onNavigationEnd(routeSnapshot: ActivatedRouteSnapshot, statusName: string) {
        const pageObject = {
            name: location.hash.replace('#', ''),
            group: "/"
        };

        this.dtrumLibReadyPromise.then(() => {
            this.dtrum.setPage(pageObject);
        });
    }

}
