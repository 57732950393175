import { Component, Input, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { auditTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AddressEntry, UkAddress } from '../../services/uk-address-lookup.service';
import { AbstractFormComponent } from '../abstract-form.component';
import { CountryService, ICountryMetadata } from '../../services/country.service';
import { Account } from '../../models/registration.model';
import { HereAddressSearchConfiguration, HereApiaddressLookupService, HereApiAddressSearchResult } from '../../services/here-apiaddress-lookup.service';
import { regexValidators } from '../../customValidators';
import { AmplitudeService } from '../../services/amplitude.service';


export interface AddressFormControlMetadata {
    [key: string]: {
        modelFieldName: keyof Account,
        mandatory: boolean
    }
}

@Component({
    selector: 'address2-form-group',
    templateUrl: './address2.component.html',
    styleUrls: ['./address2.component.scss']
})
export class Address2Component extends AbstractFormComponent implements OnInit {

    @Input('group')
    public addressForm: UntypedFormGroup;

    @Input('simplified')
    public simplified: boolean = false;

    @Input('activeMarketsOnly')
    public activeMarketsOnly: boolean = false;

    @Input()
    readonly = false;

    public countries: ICountryMetadata[];

    public fieldMetadata: AddressFormControlMetadata = {
        "idCheckAddressLookupAddressCode": {
            modelFieldName: "RegisteredAddressServiceRefCode__c",
            mandatory: false
        },
        "line1": {
            modelFieldName: "RegisteredAddressLine1__c",
            mandatory: false
        },
        "line2": {
            modelFieldName: "RegisteredAddressLine2__c",
            mandatory: false
        },
        "line3": {
            modelFieldName: "RegisteredAddressLine3__c",
            mandatory: false
        },
        "city": {
            modelFieldName: "RegisteredAddressCity__c",
            mandatory: false
        },
        "buildingName": {
            modelFieldName: "RegisteredAddressHouseName__c",
            mandatory: false
        },
        "buildingNumber": {
            modelFieldName: "RegisteredAddressHouseNumber__c",
            mandatory: false
        },
        "poBoxNumber": {
            modelFieldName: "RegisteredAddressPoBox__c",
            mandatory: false
        },
        "street": {
            modelFieldName: "RegisteredAddressStreet__c",
            mandatory: false
        },
        "secondaryStreet": {
            modelFieldName: "RegisteredAddressSecondaryStreet__c",
            mandatory: false
        },
        "subBuilding": {
            modelFieldName: "RegisteredAddressSubBuilding__c",
            mandatory: false
        },
        "postcode": {
            modelFieldName: "RegisteredAddressPostalCode__c",
            mandatory: false
        },
        "state": {
            modelFieldName: "RegisteredAddressState__c",
            mandatory: false
        }
    };

    public hereSearchConfiguration: HereAddressSearchConfiguration = new HereAddressSearchConfiguration();
    public hereApiAddressSearchResults: HereApiAddressSearchResult[] = [];
    public hereApiSelectionEmitter: EventEmitter<HereApiAddressSearchResult> = new EventEmitter();

    public w2UkLookupSelectionEmitter: EventEmitter<UkAddress> = new EventEmitter();

    public searchResultVisible = false;
    public arrowkeyLocation = 0;
    public ukLookupLocked = false;

    public detectManualEdits: boolean = true;
    public manualChangeEmitter: EventEmitter<string> = new EventEmitter();

    public addressFieldsLayout = null;

    mainMarketCountryNames: string[] = ["FRA", "DEU", "ITA", "NLD","ESP", "GBR"];
    constructor(
        private countryService: CountryService,
        private hereLookupService: HereApiaddressLookupService,
        private router: Router,
        protected logger: NGXLogger,
        private amplitudeService: AmplitudeService) {

        super();

        this.countryService.countries.subscribe(allCountries => {
            this.countries = allCountries.filter((country) => {
                return this.mainMarketCountryNames.indexOf(country.alpha_3) != -1;
            }).sort(this.countryService.sortFunction)
                .concat(allCountries.filter((country) =>
                    ((!this.activeMarketsOnly || country.active_market) && !country.blacklist) && this.mainMarketCountryNames.indexOf(country.alpha_3) == -1
                ).sort(this.countryService.sortFunction));
        });
    }

    ngOnInit(): void {
        this.addressForm.addControl("addressSearch", new UntypedFormControl());
        this.addressForm.addControl("ukAddressSearch", new UntypedFormControl(null, [
            Validators.pattern(regexValidators.postcodesByCountry.GBR)
        ]));

        this.addressForm.get("country").valueChanges.subscribe((value) => {
            this.hereSearchConfiguration.setSearchInCountry(value, this.countryLang);
            this.handleCountryChange(value, true);
            this.manualChangeEmitter.emit("country");
        });
        this.handleCountryChange(this.country, false);

        // Init Here API lookup
        this.hereSearchConfiguration.setSearchInCountry(this.country, this.countryLang);
        this.hereSearchConfiguration.setSearchAtPosition(0.118092, 51.509865);

        this.addressForm.get("addressSearch").valueChanges.subscribe(value => {
            if(value && this.hereApiAddressSearchResults.length == 0) {
                this.searchResultVisible = false;
            }
        });

        this.addressForm.get("addressSearch").valueChanges.pipe(
            auditTime(1500),
            distinctUntilChanged()
        ).subscribe(value => {
            if (value) {
                this.hereLookupService.searchAddress(this.hereSearchConfiguration, value).subscribe(results => {
                    this.hereApiAddressSearchResults = results;
                    this.searchResultVisible = true;
                    this.amplitudeService
                        .trackAddressSearchPerformed(this.router.url, this._getFormId(), this.country, this.hereApiAddressSearchResults.length);
                });
            }
        });

        this.manualChangeEmitter.pipe(
            distinctUntilChanged((x, y) => {
                return x != "country" && x == y;
            })
        ).subscribe(e => {
            if (e) {
                this.amplitudeService.trackAddressFieldEdited(this.router.url, this._getFormId(), this.country, e);
            }
        });
    }

    private _getFormId(): string {
        let formId: string = "none";
        if (this.addressForm.parent && this.addressForm.parent.get("personFormId")) {
            formId = this.addressForm.parent.get("personFormId").value;
        }
        return formId;
    }


    selectHereApiSearchResult(selectedAddressSearch: HereApiAddressSearchResult) {
        this.hereLookupService.lookupAddress(this.hereSearchConfiguration, selectedAddressSearch.id)
            .subscribe(selectedAddress => {
                this.searchResultVisible = false;
                selectedAddress.id = selectedAddressSearch.id;
                this.hereApiSelectionEmitter.emit(selectedAddress);
                this.addressForm.get('addressSearch').setValue('', { emitEvent: false });
            });
    }

    keyDown(event: KeyboardEvent) {
        if (event.keyCode === 40 && this.arrowkeyLocation < this.hereApiAddressSearchResults.length - 1) {
            // Arrow Down
            this.arrowkeyLocation++;
        } else if (event.keyCode === 38 && this.arrowkeyLocation > 0) {
            // Arrow Up
            this.arrowkeyLocation--;
        } else if (event.keyCode === 13) {
            this.selectHereApiSearchResult(this.hereApiAddressSearchResults[this.arrowkeyLocation]);
            this.arrowkeyLocation = 0;
        }
        event.stopPropagation();
    }

    private handleCountryChange(country: string, clearValues: boolean) {
        this.detectManualEdits = false;
        this.ukLookupLocked = false;

        if (clearValues) {
            if (this.addressForm.get("idCheckAddressLookupAddressCode").value) {
                let formId: string = "none";
                if (this.addressForm.parent.get("personFormId")) {
                    formId = this.addressForm.parent.get("personFormId").value;
                }
                this.amplitudeService.trackAddressSearchResultDiscarded(this.router.url, formId, this.country, "country");
            }

            this.clearValues();
        }
        this.clearValidators();
        this.addressFieldsLayout = null;

        setTimeout(() => {
            switch (country) {
                case "GBR":
                case "IRL":
                case "ITA":
                    this.addressFieldsLayout = country;
                    break;
                default:
                    this.addressFieldsLayout = "generic";
            }
            this.addressForm.updateValueAndValidity({ emitEvent: false });
            this.detectManualEdits = false;
        },0);
    }

    private clearValues() {
        for (const formFieldName in this.fieldMetadata) {
            const control = this.addressForm.get(formFieldName);
            control.patchValue("", { emitEvent: false });
        }
    }

    private clearValidators() {
        for (const formFieldName in this.fieldMetadata) {
            const fieldData = this.fieldMetadata[formFieldName];
            const control = this.addressForm.get(formFieldName);
            control.setValidators(this.accountMaxlengthValidator(fieldData.modelFieldName));
            fieldData.mandatory = false;
        }
    }

    ukSearchResultSelected(event: UkAddress) {
        this.w2UkLookupSelectionEmitter.emit(event);
    }

    ukSearchQueryResults(results: AddressEntry[]) {
        let formId: string = "none";
        if (this.addressForm.parent.get("personFormId")) {
            formId = this.addressForm.parent.get("personFormId").value;
        }
        this.amplitudeService.trackAddressSearchPerformed(this.router.url, formId, this.country, results ? results.length : 0);
    }

    get country(): string {
        return this.addressForm.controls['country'].value;
    }

    get countryLang(): string {
        const countryMetadata = this.countries.find(c => c.alpha_3 === this.country);
        return countryMetadata ? countryMetadata.lang : null;
    }

    getCountryLabel(): string {
        return this.countries.find(c => c.alpha_3 === this.country).name;
    }

}
