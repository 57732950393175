<div class="collapsible-title" (click)="toggle()" [ngClass]="{ 'collapsed': collapsed}">
  <div class="collapsible-title-content">
    <ng-content select="[collapsible-title]"></ng-content>
  </div>
  <div class="collapsible-toggle">
    <div>
      <i class="md-icon">keyboard_arrow_up</i>
    </div>
  </div>
</div>
<div class="collapsible-content" [ngClass]="{ 'collapsed': collapsed}">
  <ng-content select="[collapsible-content]"></ng-content>
</div>
