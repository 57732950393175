<div class="idv-loading-background" *ngIf="loading">
  <div class="loading-screen"></div>
</div>

<div class="idv-page-content" *ngIf="!loading">

  <ng-container *ngIf="!tokenVerified">
    <header>
      <h2>{{ "REGISTRATION.INT_USER_IDV_PAGE.EXPIRED_TITLE" | translate }}</h2>
    </header>
    <p>
      {{ "REGISTRATION.INT_USER_IDV_PAGE.EXPIRED_PARAGRAPH" | translate }}
    </p>
  </ng-container>

  <ng-container *ngIf="tokenVerified">
    <header>
      <h2>{{ "REGISTRATION.INT_USER_IDV_PAGE.TITLE" | translate }}</h2>
      <div class="image docuploadimage"></div>
    </header>

    <p>{{ "REGISTRATION.INT_USER_IDV_PAGE.IDV_PARAGRAPH" | translate }}</p>

    <form *ngIf="idvUserForm" [formGroup]="idvUserForm" (ngSubmit)="submitDetailsForm()">

      <div class="layout-section">
        <h5><strong>{{ 'REGISTRATION.INT_USER_IDV_PAGE.PERSONAL_INFORMATION' | translate }}</strong></h5>

        <soldo-field-layout
          [control]="idvUserForm.controls.firstName"
          fieldName="firstName"
          cypressSelector="IdvUser_TextInput_FirstName"
          label="REGISTRATION.INT_USER_IDV_PAGE.FIRST_NAME_LABEL">
          <input input type="text" name="firstName" [readonly]="true" [value]="sessionData?.firstName" />
        </soldo-field-layout>

        <soldo-field-layout
          [control]="idvUserForm.controls.middleName"
          fieldName="middleName"
          cypressSelector="IdvUser_TextInput_MiddleName"
          label="REGISTRATION.INT_USER_IDV_PAGE.MIDDLE_NAME_LABEL">
          <input input type="text" name="middleName" [readonly]="true" [value]="sessionData?.middleName" />
        </soldo-field-layout>

        <soldo-field-layout
          [control]="idvUserForm.controls.lastName"
          fieldName="lastName"
          cypressSelector="IdvUser_TextInput_LastName"
          label="REGISTRATION.INT_USER_IDV_PAGE.LAST_NAME_LABEL">
          <input input type="text" name="lastName" [readonly]="true" [value]="sessionData?.lastName" />
        </soldo-field-layout>

        <soldo-field-layout
          [control]="idvUserForm.controls.dateOfBirth"
          fieldName="dateOfBirth"
          cypressSelector="IdvUser_TextInput_DateOfBirth"
          label="REGISTRATION.INT_USER_IDV_PAGE.DATE_OF_BIRTH_LABEL">

          <input input type="text" name="dateOfBirth"
            formControlName="dateOfBirth"
            mask="00/00/0000"
            [validation]="false"
            [specialCharacters]="[ '/' ]"
            [dropSpecialCharacters]="false"
            placeholder="{{ 'REGISTRATION.INT_USER_IDV_PAGE.DATE_OF_BIRTH_PLACEHOLDER' | translate }}" />

          <ng-container errorMessage>
            <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
            <soldo-field-error errorName="invalidDateFormat">{{ 'DATE_FORMAT_ERROR' | translate }}</soldo-field-error>
            <soldo-field-error errorName="ageNotAllowed">{{ 'DATE_AGE_NOT_ALLOWED' | translate }}</soldo-field-error>
          </ng-container>
        </soldo-field-layout>

        <soldo-field-layout
          [control]="idvUserForm.controls.nationality"
          fieldName="nationality"
          cypressSelector="IdvUser_NgSelect_Nationality"
          label="REGISTRATION.INT_USER_IDV_PAGE.NATIONALITY_LABEL">

          <ng-select input name="nationality"
            formControlName="nationality"
            [items]="completeCountryList"
            bindLabel="name"
            bindValue="alpha_3"
            [clearable]="false"
            notFoundText="{{'NO_ITEMS_FOUND' | translate }}">
          </ng-select>

          <ng-container errorMessage>
            <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
          </ng-container>
        </soldo-field-layout>

      </div>

      <div class="layout-section">
        <h5><strong>{{ 'REGISTRATION.INT_USER_IDV_PAGE.ADDRESS_TITLE' | translate }}</strong></h5>

        <address2-form-group [group]="idvUserForm.controls.address" data-cy="IdvUser_Address_ResidentialAddress">
        </address2-form-group>
      </div>

      <div class="layout-section">
        <h5><strong>{{ 'REGISTRATION.INT_USER_IDV_PAGE.CODICE_FISCALE_TITLE' | translate }}</strong></h5>

        <soldo-field-layout *ngIf="idvUserForm.controls.codiceFiscaleChoice"
          [control]="idvUserForm.controls.codiceFiscaleChoice"
          fieldName="codiceFiscaleChoice"
          cypressSelector="IdvUser_RadioButton_CodiceFiscaleChoice"
          label="REGISTRATION.INT_USER_IDV_PAGE.CODICE_FISCALE_QUESTION">

          <fieldset input id="codiceFiscaleChoiceRadio">
            <legend>-- (hidden) need this to group the choices when multiple admin forms are on screen --</legend>
            <div data-cy="IdvUser_RadioButton_CodiceFiscaleChoice_NO">
              <input id="codiceFiscaleChoiceRadio-NO" type="radio" formControlName="codiceFiscaleChoice" [value]="false">
              <label for="codiceFiscaleChoiceRadio-NO">{{ 'REGISTRATION.INT_USER_IDV_PAGE.CODICE_FISCALE_NO' | translate }}</label>
            </div>
            <div data-cy="IdvUser_RadioButton_CodiceFiscaleChoice_YES">
              <input id="codiceFiscaleChoiceRadio-YES" type="radio" formControlName="codiceFiscaleChoice" [value]="true">
              <label for="codiceFiscaleChoiceRadio-YES">{{ 'REGISTRATION.INT_USER_IDV_PAGE.CODICE_FISCALE_YES' | translate }}</label>
            </div>
          </fieldset>

          <ng-container errorMessage>
            <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
          </ng-container>
        </soldo-field-layout>

        <div [ngClass]="{'conditional-subfield-container': idvUserForm.value?.codiceFiscaleChoice}" *ngIf="idvUserForm.controls.codiceFiscale">
          <ng-container>
            <soldo-field-layout
              [control]="idvUserForm.controls.codiceFiscale"
              fieldName="codiceFiscale"
              cypressSelector="IdvUser_TextInput_CodiceFiscale"
              label="REGISTRATION.INT_USER_IDV_PAGE.CODICE_FISCALE_ITA_LABEL">

                <input input #codiceFiscaleInputElement type="text" name="codiceFiscale"
                  formControlName="codiceFiscale"
                  (keyup)="codiceFiscaleInputElement.value=$event.target.value.toUpperCase()">

                <ng-container errorMessage>
                  <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
                  <soldo-field-error error errorName="wrongFormat">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
                  <soldo-field-error error errorName="nameCfMismatch">
                    {{ 'REGISTRATION.INT_USER_IDV_PAGE.NAME_CF_MISMATCH' | translate }}
                  </soldo-field-error>
                  <soldo-field-error error errorName="dateCfMismatch">
                    {{ 'REGISTRATION.INT_USER_IDV_PAGE.DATE_CF_MISMATCH' | translate }}
                  </soldo-field-error>
                  <soldo-field-error error errorName="ageNotAllowed">{{ 'DATE_AGE_NOT_ALLOWED' | translate }}</soldo-field-error>
                </ng-container>
            </soldo-field-layout>
          </ng-container>
        </div>

      </div>


      <div class="layout-section cta-section">
        <button data-cy="IDV_Button_VerifyYourIdentityButton" class="primary" type="submit">
          {{ "REGISTRATION.INT_USER_IDV_PAGE.CTA" | translate }}
        </button>
      </div>

    </form>

    <ng-container *ngIf="skipForm">
      <p>{{ "REGISTRATION.INT_USER_IDV_PAGE.COMPLETE_IDV_PARAGRAPH" | translate }}</p>

      <div class="layout-section cta-section">
        <button data-cy="IDV_Button_VerifyYourIdentityButton" class="primary" (click)="proceedToIDV()">
          {{ "REGISTRATION.INT_USER_IDV_PAGE.CTA" | translate }}
        </button>
      </div>
    </ng-container>

  </ng-container>

</div>
