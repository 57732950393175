import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AppConfig, APP_CONFIG } from '@src/app/app-config.module';
import { NGXLogger } from 'ngx-logger';
import { Account, Application, Lead } from '../models/registration.model';
import { AmplitudeService } from './amplitude.service';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { MarketoIntegrationService } from './marketo-integration.service';
import { RegistrationWebappPushApiService } from './registration-webapp-push-api.service';
import { ApplicationMeta, RegistrationDataService, XeroOAuthDTO } from './registrationData.service';
import { SoldoCookieService } from "./soldo-cookie.service";
import { StatusService } from "./status.service";

@Injectable()
export class StartupService {

    // eslint-disable-next-line no-useless-constructor
    constructor(
        private readonly logger: NGXLogger,
        private readonly registrationDataService: RegistrationDataService,
        private readonly statusService: StatusService,
        private readonly soldoCookieService: SoldoCookieService,
        private readonly amplitudeService: AmplitudeService,
        private readonly marketoIntegrationService: MarketoIntegrationService,
        private readonly gtmService: GoogleTagManagerService,
        private readonly pushAPI: RegistrationWebappPushApiService,
        private readonly router: Router,
        @Inject(APP_CONFIG) private readonly config: AppConfig
    ) { }

    _getSearchParam(urlObject: URL, key: string, defaultValue: string, maxLength: number = 512): string {
        if (urlObject?.searchParams) {
            const value = urlObject.searchParams.get(key);
            return value === "" || value === null || value.length > maxLength ? defaultValue : value;
        }
        return defaultValue;
    }

    _hasSearchParam(urlObject: URL, key: string): boolean {
        let value = false;
        if (urlObject?.searchParams) {
            value = urlObject.searchParams.has(key);
        }
        return value;
    }

    // This is the method you want to call at bootstrap
    // Important: It should return a Promise
    load(): Promise<void> {

        const storedConfig = this.soldoCookieService.getStoredConfig();

        const currentUrl = new URL(location.toString());
        let routeHash = currentUrl.hash;

        const externalId: string = this._getSearchParam(currentUrl, "registrationId", null, 64);
        if (externalId) {
            this.soldoCookieService.deleteEmailVerificationToken();
        }
        const offerName: string = this._getSearchParam(currentUrl, "OfferName", null, 32);
        const planName: string = this._getSearchParam(currentUrl, "PlanName", null, 32);
        const discountCode: string = this._getSearchParam(currentUrl, "discountCode", storedConfig.discountCode, 32);
        const parentDomain: string = this._getSearchParam(currentUrl, "parentDomain", storedConfig.parentDomain, 16);
        const prepopulatedEmail: string = this._getSearchParam(currentUrl, "email", null, 64);
        const marketingDestinationId: string = this._getSearchParam(currentUrl, "destinationid", null, 64);

        const startSignupWithXeroFlow: boolean = this._hasSearchParam(currentUrl, "SignupWithXero");
        const xeroOAuthCode: string = this._getSearchParam(currentUrl, "code", null);
        const xeroOAuthState: string = this._getSearchParam(currentUrl, "state", null);
        const signupWithXeroAuthorized: boolean = Boolean(xeroOAuthCode) && Boolean(xeroOAuthState);
        const hooYuVerificationLink: string = this.hooYuOrMitekLinkCheck(this._getSearchParam(currentUrl, "link", storedConfig.hooYuLink));
        if (hooYuVerificationLink) {
            storedConfig.hooYuLink = hooYuVerificationLink;
            this.soldoCookieService.storeConfig(storedConfig);
            this.registrationDataService.setIdvData({
                provider: 'HOOYU',
                sessionId: hooYuVerificationLink.toString()
            });
            this.router.navigate(['static/verify-your-identity']);
            routeHash = '#/static/verify-your-identity';
        }
        const idvProvider: string = this._getSearchParam(currentUrl, "idvProvider", storedConfig.idvProvider, 16);
        const idvSessionId: string = this._getSearchParam(currentUrl, "idvSessionId", storedConfig.idvSessionId);
        if (idvProvider === 'Onfido' && idvSessionId) {
            storedConfig.idvSessionId = idvSessionId;
            this.soldoCookieService.storeConfig(storedConfig);
            this.registrationDataService.setIdvData({
                provider: 'ONFIDO',
                sessionId: idvSessionId
            });
            this.router.navigate(["static/verify-your-identity"]);
            routeHash = '#/static/verify-your-identity';
        }

        const staticRouteRegexp = /^#\/static\/.+/;
        const isStaticRoute = staticRouteRegexp.test(routeHash);

        this.logger.debug("Rendering a static route. Skipping Registration flow init.", routeHash);
        if (isStaticRoute) {
            return Promise.resolve();
        }


        // Handle Sole Trader Selection Override
        storedConfig.soleTraderSelectionEnabled = storedConfig.soleTraderSelectionEnabled || this._hasSearchParam(currentUrl, "est");
        this.registrationDataService.enableSoleTraderSelection(storedConfig.soleTraderSelectionEnabled);
        this.soldoCookieService.storeConfig(storedConfig);

        this.soldoCookieService.updateAffiliateData(currentUrl.searchParams);

        const marketoCookieValue: string = this.marketoIntegrationService.getMarketoCookie();


        const initData: Partial<Lead> = {
            Email: prepopulatedEmail,
            SoldoRegistrationExternalID__c: externalId,
            PlanName__c: planName,
            OfferName__c: offerName,
            DiscountCode__c: discountCode,
            ParentDomain__c: parentDomain,
            MarketoCookieId__c: marketoCookieValue,
            DestinationId__c: marketingDestinationId
        };

        const gclidData = this.soldoCookieService.readDedicatedGoogleClickIDCookie();
        if (gclidData !== null) {
            initData.GCLID__c = gclidData.gclid;
            initData.GCLIDTimestamp__c = gclidData.gclidTimestamp;
        }

        if (startSignupWithXeroFlow) {
            return this._initiateSignupWithXero(initData);
        } else if (signupWithXeroAuthorized) {
            return this._startupWithXeroOpenID(initData, xeroOAuthCode, xeroOAuthState);
        } else {
            return this._startup(initData);
        }
    }

    private _startup(initData: Partial<Lead>): Promise<void> {
        const registrationIdReplaced: boolean = Boolean(initData.SoldoRegistrationExternalID__c);
        if (!registrationIdReplaced) {
            initData.SoldoRegistrationExternalID__c = this.soldoCookieService.readRegistrationIdCookie();
        }

        const marketingParams = this.soldoCookieService.readDedicatedUTMCookie();
        if (marketingParams !== null) {
            initData.UtmCampaign__c = marketingParams.utm_campaign;
            initData.UtmSource__c = marketingParams.utm_source;
            initData.UtmContent__c = marketingParams.utm_content;
            initData.UtmMedium__c = marketingParams.utm_medium;
            initData.UtmTerm__c = marketingParams.utm_term;
            initData.DriverId__c = marketingParams.driverid;
        }

        this.logger.info('REGISTRATION ID -> ', initData.SoldoRegistrationExternalID__c);

        const applicationMetadata: ApplicationMeta = {
            planName: initData.PlanName__c,
            offerName: initData.OfferName__c
        };

        const affiliateData = this.soldoCookieService.readDedicatedAffiliateTrackingCookie();
        if (affiliateData !== null) {
            applicationMetadata.affiliateId = affiliateData.affiliateId;
            applicationMetadata.affiliateClickTime = affiliateData.affiliateClickTime;
            applicationMetadata.affiliateMetadata = affiliateData.affiliateMetadata;
        }

        if (initData.SoldoRegistrationExternalID__c && initData.SoldoRegistrationExternalID__c.length > 0) {

            return this.resumeExistingRegistration(initData, applicationMetadata, registrationIdReplaced).then(this._recordCookiePreferences);

        } else {

            return this.startNewRegistration(initData).then(this._recordCookiePreferences);
        }
    }

    _initiateSignupWithXero(initData: Partial<Lead>): Promise<void> {

        this.soldoCookieService.writeSignupWithXeroCookie(initData.PlanName__c, initData.OfferName__c);

        return this.registrationDataService.fetchSignupWithXeroLoginParams().then(xeroOAutheDTO => {

            this.soldoCookieService.updateRegistrationIdCookie(xeroOAutheDTO.registrationExternalID);

            const xeroLoginUrl = new URL(this.config.signupWithXeroLoginURLRoot);
            xeroLoginUrl.searchParams.append("response_type", "code");
            xeroLoginUrl.searchParams.append("client_id", xeroOAutheDTO.clientId);
            xeroLoginUrl.searchParams.append("state", xeroOAutheDTO.state);
            xeroLoginUrl.searchParams.append("redirect_uri", this.config.signupWithXeroLoginRedirectURI);
            xeroLoginUrl.searchParams.append("scope", this.config.signupWithXeroLoginAuthorizationScopes);

            location.replace(xeroLoginUrl.toString());
        });
    }

    _startupWithXeroOpenID(initData: Partial<Lead>, code: string, state: string): Promise<void> {
        const signupWithXeroConf = this.soldoCookieService.readSignupWithXeroCookie();

        initData.PlanName__c = signupWithXeroConf.plan;
        initData.OfferName__c = signupWithXeroConf.offer;

        this.statusService.init({
            companyProfile: null,
            status: "Started",
            fsApplicationSubmissionStatus: "Started",
            handOver: false,
            lead: null,
            account: null,
            contacts: []
        });

        if (this.registrationDataService.checkPlanAndOffer(initData.PlanName__c, initData.OfferName__c)) {

            initData.SoldoRegistrationExternalID__c = this.soldoCookieService.readRegistrationIdCookie();
            initData.LeadSource = this.config.signupWithXeroLeadSource;

            const xeroOAuthDTO: Partial<XeroOAuthDTO> = {
                exchangeCode: code,
                state: state,
                redirectUri: this.config.signupWithXeroLoginRedirectURI,
                registrationExternalID: initData.SoldoRegistrationExternalID__c
            };

            // send code and state to get OpenID data
            return this.registrationDataService.fetchSignupWithXeroOpenIdData(xeroOAuthDTO).then(application => {
                initData.FirstName = application.lead.FirstName;
                initData.LastName = application.lead.LastName;
                initData.Email = application.lead.Email;

                this.registrationDataService.initLead(initData);
                this.registrationDataService.setPlanAndOffer(initData.PlanName__c, initData.OfferName__c);

                this.gtmService.trackNewRegistrationStarted(this.registrationDataService.getPlanName(), this.registrationDataService.getOfferName());
                this.amplitudeService.trackSignupStarted();
                this.amplitudeService.trackSignupWithXeroAuthorized();
            });
        } else {
            // will be redirected as soon as app.component finds out
            // we don't have a valid plan / offer setup

            // store the query param config so it's not lost during redirect

            this.soldoCookieService.storeConfig({
                parentDomain: initData.ParentDomain__c,
                discountCode: initData.DiscountCode__c
            });

            this.registrationDataService.initLead(initData);

            return Promise.resolve(null);
        }

        // receive OK
        // proceed to 'start' of registration... that will be redirected to pricing

    }

    _recordCookiePreferences = (r) => {
        const lead = this.registrationDataService.getLead();
        const account = this.registrationDataService.getAccount();
        this.pushAPI.promise('gtm-consent-ready').then((preferencesEvent) => {
            try {
                if (lead !== null) {
                    this.registrationDataService.saveLead({
                        CookiePreferences__c: JSON.stringify(preferencesEvent.value)
                    });
                }
                if (account !== null) {
                    this.registrationDataService.saveAccount({
                        CookiePreferences__c: JSON.stringify(preferencesEvent.value)
                    });
                }
            } catch (e) {
                this.logger.warn("Can't serialize cookie preferences object", e);
            }
        });
        return r;
    };

    hooYuOrMitekLinkCheck(link: string): string {
        if (link) {
            if (/^https:\/\/www\.hooyu\.com\/.*checkid\//.test(link) || /^https:\/\/[^/?#]*miteksystems.com\//.test(link)) {
                return link;
            }
        }
        return null;
    }

    resumeExistingRegistration(initData: Partial<Lead>, metadata: ApplicationMeta, registrationIdReplaced: boolean): Promise<void> {
        return this.registrationDataService.loadApplication(initData.SoldoRegistrationExternalID__c, metadata).then(application => {

            this.statusService.init(application);

            if (!application.status) {
                return this.startNewRegistration(initData);
            }

            // Resume Success
            if(application.lead) {
                this.readLeadResumeData(initData, application);
            } else {
                this.readAccountResumeData(initData, application);
            }

            if (registrationIdReplaced) {
                this.soldoCookieService.updateRegistrationIdCookie(initData.SoldoRegistrationExternalID__c);
            }

            this.gtmService.trackRegistrationResumed(application.status);
            this.amplitudeService.trackApplicationResumed(application.status, application.segment);

        });
    }

    overrideIfDefined<T>(overrideTarget: T, overrideSource: Partial<T>, property: keyof T) {
        if (overrideSource[property]) {
            overrideTarget[property] = overrideSource[property];
        }
    }

    readLeadResumeData(initData: Partial<Lead>, application: Application) {
        const lead = this.registrationDataService.initLead(application.lead);

        const overrideProperties: Array<keyof Lead> = [
            "DiscountCode__c",
            "UtmCampaign__c",
            "UtmContent__c",
            "UtmSource__c",
            "UtmTerm__c",
            "UtmMedium__c",
            "GCLID__c",
            "GCLIDTimestamp__c",
            "DriverId__c",
            "DestinationId__c"
        ];

        for (const overrideProperty of overrideProperties) {
            this.overrideIfDefined(lead, initData, overrideProperty);
        }

        this.registrationDataService.setPlanAndOffer(
            initData.PlanName__c || lead.PlanName__c,
            initData.OfferName__c || lead.OfferName__c
        );
    }

    readAccountResumeData(initData: Partial<Lead>, application: Application) {
        const account = this.registrationDataService.initAccount(application.account);
        this.registrationDataService.initContacts(application.contacts);

        const overrideProperties: Array<keyof Account> = [
            "DiscountCode__c",
            "GCLID__c",
            "GCLIDTimestamp__c"
        ];

        for (const overrideProperty of overrideProperties) {
            this.overrideIfDefined(account, initData, overrideProperty);
        }

        this.registrationDataService.setPlanAndOffer(
            initData.PlanName__c || account.PlanName__c,
            initData.OfferName__c || account.OfferName__c
        );
    }

    startNewRegistration(initData: Partial<Lead>): Promise<void> {

        this.statusService.init({
            companyProfile: null,
            status: "Started",
            fsApplicationSubmissionStatus: "Started",
            handOver: false,
            lead: null,
            account: null,
            contacts: []
        });

        const affiliateData = this.soldoCookieService.readDedicatedAffiliateTrackingCookie();
        if (affiliateData !== null) {
            initData.AffiliateId__c = affiliateData.affiliateId;
            initData.AffiliateClickTime__c = affiliateData.affiliateClickTime;
            initData.AffiliateMetadata__c = affiliateData.affiliateMetadata;
        }

        if (this.registrationDataService.checkPlanAndOffer(initData.PlanName__c, initData.OfferName__c)) {
            // Plan and Offer selected, clean registration id and eventual verification token from a previous registration flow
            this.soldoCookieService.deleteRegistrationIdCookie();
            this.soldoCookieService.deleteEmailVerificationToken();
            // Ask for a better registration UUID and start a new registration
            return this.registrationDataService.requestUUID().then(registrationUUID => {
                initData.SoldoRegistrationExternalID__c = registrationUUID;

                this.registrationDataService.initLead(initData);
                this.registrationDataService.setPlanAndOffer(initData.PlanName__c, initData.OfferName__c);

                this.gtmService.trackNewRegistrationStarted(this.registrationDataService.getPlanName(), this.registrationDataService.getOfferName());
                this.amplitudeService.trackSignupStarted();
            });
        } else {
            // will be redirected as soon as app.component finds out
            // we don't have a valid plan / offer setup

            // store the query param config so it's not lost during redirect

            this.soldoCookieService.storeConfig({
                parentDomain: initData.ParentDomain__c,
                discountCode: initData.DiscountCode__c
            });

            this.registrationDataService.initLead(initData);

            return Promise.resolve(null);
        }
    }
}
