<h2>{{ "REGISTRATION.ADMIN.TITLE" | translate }}</h2>
<h5>{{ "REGISTRATION.ADMIN.DEFINITION"  | translate }}</h5>

<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">

  <div class="layout-section">
    <h4 *ngIf="!applicantIsLegalRep"><strong>{{ 'REGISTRATION.ADMIN.ARE_YOU_ADMIN' | translate }}</strong></h4>
    <h4 *ngIf="applicantIsLegalRep"><strong>{{ 'REGISTRATION.ADMIN.ARE_YOU_ADMIN_ALSO' | translate }}</strong></h4>

    <soldo-field-layout
      [control]="form.controls.adminChoice"
      fieldName="adminChoice"
      cypressSelector="Admin_RadioButton_AdminSelection">

      <fieldset input>
        <div data-cy="Admin_RadioButton_AdminSelection_Applicant">
          <input id="choiceApplicant" type="radio" [formControl]="form.controls.adminChoice" [value]="ADMIN_CHOICE.APPLICANT">
          <label for="choiceApplicant">{{ "REGISTRATION.ADMIN.ADMIN_CHOICE.APPLICANT" | translate: {firstName: applicantContact.FirstName, lastName: applicantContact.LastName } }}</label>
        </div>
        <div data-cy="Admin_RadioButton_AdminSelection_LegalRepresentative" *ngIf="!applicantIsLegalRep">
          <input id="choiceLegalRep" type="radio" [formControl]="form.controls.adminChoice" [value]="ADMIN_CHOICE.LEGAL_REPRESENTATIVE">
          <label for="choiceLegalRep">{{ "REGISTRATION.ADMIN.ADMIN_CHOICE.LEGAL_REPRESENTATIVE" | translate: {firstName: legalRepContact.FirstName, lastName: legalRepContact.LastName } }}</label>
        </div>
        <div data-cy="Admin_RadioButton_AdminSelection_Other">
          <input id="choiceOther" type="radio" [formControl]="form.controls.adminChoice" [value]="ADMIN_CHOICE.OTHER">
          <label for="choiceOther">{{ "REGISTRATION.ADMIN.ADMIN_CHOICE.OTHER" | translate }}</label>
        </div>
      </fieldset>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <h5>{{ 'REGISTRATION.ADMIN.ADD_MORE_LATER' | translate }}</h5>
  </div>

  <div class="callout informative">
    <div class="callout-content">
      <p>{{ "REGISTRATION.ADMIN.CALLOUT_PARAGRAPH" | translate: {regulatoryInstitution: regulatoryInstitution} }}</p>
      <a [href]="registrationProcessURL">{{ "REGISTRATION.ADMIN.CALLOUT_LINK" | translate }}</a>
    </div>
  </div>

  <ng-container *ngIf="form.value.adminChoice !== ADMIN_CHOICE.NO_CHOICE">
    <h5><strong>{{ "REGISTRATION.ADMIN.FORM_TITLE" | translate }}</strong></h5>
    <person-form
      [personFormGroup]="form.controls.admin"
      [readonly]="readonly"
      [applyUniquenessChecks]="true"
      [checkInArray]="allContacts"
      [emailIsReadonly]="form.value.adminChoice === ADMIN_CHOICE.APPLICANT"></person-form>
  </ng-container>

  <div class="form-page-CTA-row">
    <button type="button" (click)="goToPrevious()" data-cy="Button_StepBackButton">
      {{ 'PREVIOUS' | translate}}
    </button>

    <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading }" data-cy="Button_StepSubmitButton">
      {{ 'NEXT' | translate}}
    </button>
  </div>

</form>

<save-and-continue-later stepName="Account Admin"></save-and-continue-later>
