import { Component, OnInit, Type } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { Contact, Lead } from '@shared/models/registration.model';
import { LeadDataMediatorService, LeadStartData } from '@shared/services/lead-data-mediator.service';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { StatusService } from '@shared/services/status.service';
import { NavigationDirection, StepService } from '@shared/services/steps.service';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { IntercomIntegrationService } from '../../shared/services/intercomIntegration.service';
import { SignupCustomOutboundActions, SignupSubStepOutboundAction, SignupSubStepType } from './signup-step.model';
import { AboutYouSubStepComponent } from './substeps/about-you/about-you.component';
import { CompanyNameSubStepComponent } from './substeps/company-name/company-name.component';
import { CompanySearchDetailsSubStepComponent } from './substeps/company-search-details/company-search-details.component';
import { CompanySearchSubStepComponent } from './substeps/company-search/company-search.component';
import { CompanyTypeSubStepComponent } from './substeps/company-type/company-type.component';
import { CountrySubStepComponent } from './substeps/country/country.component';
import { EmailVerificationSubStepComponent } from './substeps/email-verification/email-verification.component';
import { NumberOfEmployeesSubStepComponent } from './substeps/number-of-employees/number-of-employees.component';
import { AmplitudeService } from '../../shared/services/amplitude.service';

const SOLE_TRADER_COMPANY_TYPES = ['LiberoProfessionista', 'DittaIndividuale', 'SoleTrader'];

@Component({
    selector: 'route-signup-step',
    templateUrl: './signup-step.component.html',
    styleUrls: ['./signup-step.component.scss']
})
export class SignupStepComponent implements OnInit {
    // true disables controls
    loading: boolean = false;

    subStepComponentTypes: Array<Type<SignupSubStepType>> = [
        EmailVerificationSubStepComponent,
        AboutYouSubStepComponent,
        CountrySubStepComponent,
        CompanyTypeSubStepComponent,
        CompanyNameSubStepComponent,
        NumberOfEmployeesSubStepComponent
    ];
    numberOfEmployeesSubStepIndex: number;
    activeSubStep: number = 0;
    activeSubStepInstance: SignupSubStepType;
    activeSubStepActionSubscription: Subscription;

    lead: Partial<LeadStartData> = {};
    soleTrader: boolean = false;

    userOptedOutOfCompanySearch: boolean = false;

    absoluteProgressBySubStep: Map<Type<SignupSubStepType>, number> = new Map();

    constructor(
        protected logger: NGXLogger,
        private translateService: TranslateService,
        private stepService: StepService,
        private leadDataMediatorService: LeadDataMediatorService,
        private registrationDataService: RegistrationDataService,
        private statusService: StatusService,
        private intercomIntegrationService: IntercomIntegrationService,
        private amplitudeService: AmplitudeService) {

        this.numberOfEmployeesSubStepIndex = this.subStepComponentTypes.indexOf(NumberOfEmployeesSubStepComponent);
    }

    ngOnInit() {

        this.lead = this.leadDataMediatorService.getLeadStartData();
        const currentStatus = this.statusService.getFsSubmissionStatus();

        if (currentStatus.key == "UndefinedStatus" || currentStatus.key == "Started") {
            if (this.lead.companyName?.toLowerCase() == "unknown") {
                this.lead.companyName = "";
            }
            if (!this.lead.lastName || this.lead.lastName.toLowerCase() == "unknown") {
                this.lead.lastName = "";
            }
        }

        // Handling resume (and back) scenario
        if (currentStatus.key !== "Started"
            && currentStatus.key !== "UndefinedStatus"
            && this.stepService.getLastNavigationDirection() == NavigationDirection.BACKWARD) {

            this.userOptedOutOfCompanySearch = !this.lead.kybProviderId;
            this._handleCompanyTypeChange();
            this.activeSubStep = this.subStepComponentTypes.length - 1;
        }

        this.absoluteProgressBySubStep.set(EmailVerificationSubStepComponent, 0);
        this.absoluteProgressBySubStep.set(AboutYouSubStepComponent, 1);
        this.absoluteProgressBySubStep.set(CountrySubStepComponent, 2);
        this.absoluteProgressBySubStep.set(CompanyTypeSubStepComponent, 3);
        this.absoluteProgressBySubStep.set(CompanyNameSubStepComponent, 4);
        this.absoluteProgressBySubStep.set(CompanySearchSubStepComponent, 4);
        this.absoluteProgressBySubStep.set(CompanySearchDetailsSubStepComponent, 5);
        this.absoluteProgressBySubStep.set(NumberOfEmployeesSubStepComponent, 6);
    }

    onSubstepRendered(renderedSubstepInstance: SignupSubStepType) {
        this.activeSubStepInstance = renderedSubstepInstance;
        this.activeSubStepInstance.init(this.lead);
        this.activeSubStepInstance.trackSubStepViewed();
        this.activeSubStepActionSubscription = this.activeSubStepInstance.controlActionEventEmitter.subscribe((action) => {
            this.substepOutboundActionHandler(action);
        });

        if(this.activeSubStepInstance instanceof EmailVerificationSubStepComponent) {
            this.stepService.progressBarActivation.emit(false);
        } else {
            this.stepService.progressBarActivation.emit(true);
        }

        const subStepProgress = this.absoluteProgressBySubStep.get(this.subStepComponentTypes[this.activeSubStep]);
        this.stepService.updateStepProgress(subStepProgress);
    }

    substepOutboundActionHandler(a: SignupSubStepOutboundAction) {
        if (a.data) {
            Object.assign(this.lead, a.data);
        }

        switch (a.action) {
            case DefaultOutboundActions.NEXT:
                this.nextStep();
                break;
            case DefaultOutboundActions.PREVIOUS:
                this.previousStep();
                break;
            case SignupCustomOutboundActions.SWITCH_TO_COMPANY_SEARCH:
                this.userOptedOutOfCompanySearch = false;
                this._handleSwitchToCompanySearch();
                break;
            case SignupCustomOutboundActions.SWITCH_TO_MANUAL_COMPANY_EDIT:
                this.userOptedOutOfCompanySearch = true;
                this._handleSwitchToManualCompanyEdit();
                break;
        }
    }

    previousStep() {
        this.logger.debug("Rendering previous substep");

        this.activeSubStepInstance.loading = true;

        this.activeSubStepActionSubscription.unsubscribe();
        this.activeSubStep = Math.max(0, this.activeSubStep - 1);

        this._scrollTop(() => {
            this.activeSubStepInstance.loading = false;
        });
    }

    nextStep() {
        this.logger.debug("Advancing to next substep");

        this.activeSubStepInstance.loading = true;

        if (this.activeSubStepInstance instanceof CompanyTypeSubStepComponent) {
            this._handleCompanyTypeChange();
        }

        this.activeSubStepActionSubscription.unsubscribe();

        if(this.lead?.firstName) {
            this.intercomIntegrationService.setApplicantName(this.lead.firstName, this.lead.lastName);
        }

        if (this.activeSubStepInstance instanceof EmailVerificationSubStepComponent) {
            this.activeSubStepInstance.loading = true;
            this.submitEmailData().then(() => {
                this.activeSubStepInstance.trackSubStepCompleted();
                this.activeSubStep++;
                this._scrollTop();
                this.intercomIntegrationService.init();
            });
        } else if (this.activeSubStep == this.subStepComponentTypes.length - 1) {
            this.activeSubStepInstance.loading = true;
            this.activeSubStepInstance.trackSubStepCompleted();
            this.submitLeadStartData();
        } else {
            this.activeSubStepInstance.trackSubStepCompleted();
            this.activeSubStep++;
            this._scrollTop();
        }
    }

    private _scrollTop(callback?: () => void) {
        setTimeout(() => {
            window.scrollTo({ top: 0 });
            if (callback) {
                callback();
            }
        }, 0);
    }

    private _handleSwitchToManualCompanyEdit() {
        const companySearchSubStepIndex = this.subStepComponentTypes.indexOf(CompanySearchSubStepComponent);
        if (companySearchSubStepIndex > -1) {
            this.subStepComponentTypes.splice(companySearchSubStepIndex, 2, CompanyNameSubStepComponent);
            this.activeSubStep = this.subStepComponentTypes.indexOf(CompanyNameSubStepComponent);
            this.lead.kybProviderId = null;
        }
    }

    private _handleSwitchToCompanySearch() {
        const companyNameSubStepIndex = this.subStepComponentTypes.indexOf(CompanyNameSubStepComponent);
        if (companyNameSubStepIndex > -1) {
            this.subStepComponentTypes.splice(companyNameSubStepIndex, 1, CompanySearchSubStepComponent, CompanySearchDetailsSubStepComponent);
        }
    }

    private _handleCompanyTypeChange() {

        if (SOLE_TRADER_COMPANY_TYPES.includes(this.lead.companyType) && !this.soleTrader) {
            this.logger.debug("Switching to Sole Trader Mode");
            this.soleTrader = true;
            this.lead.companySize = 1;
            this.subStepComponentTypes.splice(this.numberOfEmployeesSubStepIndex, 1);
        }

        if (!SOLE_TRADER_COMPANY_TYPES.includes(this.lead.companyType) && this.soleTrader) {
            this.logger.debug("Switching out of Sole Trader Mode");
            this.soleTrader = false;
            this.subStepComponentTypes.splice(this.numberOfEmployeesSubStepIndex, 0, NumberOfEmployeesSubStepComponent);
        }

        if (this.isCompanySearchApiAvailable() && !this.userOptedOutOfCompanySearch) {
            this._handleSwitchToCompanySearch();
        } else {
            this._handleSwitchToManualCompanyEdit();
        }
    }

    isCompanySearchApiAvailable(): boolean {
        return !this.soleTrader && (this.lead.companyCountry === "GBR" || this.lead.companyCountry === "ITA");
    }

    submitEmailData(): Promise<Lead | Contact[]> {
        return this.leadDataMediatorService.submitEmailData({
            email: this.lead.email,
            emailVerificationToken: this.lead.emailVerificationToken,
            companyName: this.lead.companyName || "unknown",
            lastName: this.lead.lastName || "unknown",
            language: this.translateService.currentLang
        });
    }

    submitLeadStartData() {
        const currentSearchProfile = this.registrationDataService.getCompanySearchLookupProfile(this.lead.kybProviderId);

        this.leadDataMediatorService.submitLeadStartData({
            companyCountry: this.lead.companyCountry,
            firstName: this.lead.firstName,
            lastName: this.lead.lastName,
            email: this.lead.email,
            language: this.translateService.currentLang,
            companyName: this.lead.companyName,
            companySize: this.lead.companySize,
            companyType: this.lead.companyType,
            companyTypeOther: this.lead.companyTypeOther,
            emailVerificationToken: this.lead.emailVerificationToken,
            kybProviderId: this.lead.kybProviderId
        }, currentSearchProfile).then(() => {
            this.amplitudeService.updateCompanySearchUsageMetric(Boolean(this.lead.kybProviderId));
            this.stepService.navigateToNextStep();
            this.activeSubStepInstance.loading = false;
        }, (err) => {
            this.logger.trace("FATAL: ", err);
            this.activeSubStepInstance.loading = false;
        });
    }

}
