import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { CustomValidators } from "../../shared/customValidators";
import { FiscalCodeService } from '../../shared/services/fiscal-code.service';
import { Contact } from '../../shared/models/registration.model';
import moment from 'moment-mini';
import { NGXLogger } from 'ngx-logger';
export interface AddressFormGroup {
    country: FormControl<string>;
    line1: FormControl<string>;
    line2: FormControl<string>;
    line3: FormControl<string>;
    city: FormControl<string>;
    buildingName: FormControl<string>;
    buildingNumber: FormControl<string>;
    poBoxNumber: FormControl<string>;
    street: FormControl<string>;
    secondaryStreet: FormControl<string>;
    subBuilding: FormControl<string>;
    postcode: FormControl<string>;
    state: FormControl<string>;
    idCheckAddressLookupAddressCode: FormControl<string>;
}

export interface PersonFormGroup {
    contactExternalId: FormControl<string>,
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    nationality: FormControl<string>;
    dateOfBirth?: FormControl<string>;
    codiceFiscale?: FormControl<string>;
    email: FormControl<string>;
    mobile: FormGroup<{
        prefix: FormControl<string>;
        number: FormControl<string>;
    }>;
    address: FormGroup<AddressFormGroup>;
    isFatcaSubject?: FormControl<"Yes" | "No">;
}

@Injectable({
    providedIn: 'root'
})
export class PersonFormHelperService {
    public defaultValidators: { [k in keyof PersonFormGroup]?: ValidatorFn[] } = {
        firstName: [
            Validators.required,
            this.codiceFiscaleFirstNameValidatorFactory(),
            CustomValidators.getContactMaxlengthValidator("FirstName")
        ],
        lastName: [
            Validators.required,
            this.codiceFiscaleLastNameValidatorFactory(),
            CustomValidators.getContactMaxlengthValidator("LastName")
        ],
        dateOfBirth: [Validators.required, CustomValidators.validDate(18, 120)],
        codiceFiscale: [Validators.required],
        nationality: [Validators.required],
        email: [
            Validators.required,
            CustomValidators.validEmail,
            CustomValidators.getContactMaxlengthValidator("Email"),
            CustomValidators.cannotUseSoldoPecAddress()
        ],
        mobile: [CustomValidators.validPhone('prefix', 'number', true)],
        isFatcaSubject: [Validators.required]
    };

    constructor(
        private readonly fb: FormBuilder,
        private readonly fiscalCodeService: FiscalCodeService,
        private readonly logger: NGXLogger) {

        this.defaultValidators.codiceFiscale.push(this.fiscalCodeService.validator);
    }

    createEmptyPersonFormGroup(countryOfIncorporation: string): FormGroup<PersonFormGroup> {
        const formGroup: FormGroup<PersonFormGroup> = this.fb.group({
            contactExternalId: [null],
            firstName: [null],
            lastName: [null],
            nationality: [countryOfIncorporation],
            email: [null],
            mobile: this.fb.group({
                prefix: [null],
                number: [null]
            }),
            address: this.fb.group({
                country: [countryOfIncorporation],
                idCheckAddressLookupAddressCode: [null],
                line1: [null],
                line2: [null],
                line3: [null],
                city: [null],
                state: [null],
                postcode: [null],
                buildingName: [null],
                buildingNumber: [null],
                poBoxNumber: [null],
                street: [null],
                secondaryStreet: [null],
                subBuilding: [null]
            })
        });

        this.addOrRemoveDateOfBirthAndCodiceFiscale(formGroup, countryOfIncorporation);
        this.addOrRemoveFatcaSubjectQuestion(formGroup);

        return formGroup;
    }

    public createPersonFormGroupForContact(person: Contact, countryOfIncorporation: string): FormGroup<PersonFormGroup> {
        const { number, icc } = this.interpretMobileNumber(person.MobilePhone, person.ContactPhoneICC__c);

        const formGroup: FormGroup<PersonFormGroup> = this.fb.group({
            contactExternalId: [person.SoldoContactExternalID__c],
            firstName: [person.FirstName],
            lastName: [person.LastName],
            nationality: [person.ContactCitizenshipCountryISO__c || countryOfIncorporation],
            email: [person.Email],
            mobile: this.fb.group({
                prefix: [icc],
                number: [number]
            }),
            address: this.fb.group({
                country: [person.ResidentialAddressCountryISO__c || countryOfIncorporation],
                idCheckAddressLookupAddressCode: [person.ResidentialAddressServiceRefCode__c],
                line1: [person.ResidentialAddressLine1__c],
                line2: [person.ResidentialAddressLine2__c],
                line3: [person.ResidentialAddressLine3__c],
                city: [person.ResidentialAddressCity__c],
                state: [person.ResidentialAddressState__c],
                postcode: [person.ResidentialAddressPostalCode__c],
                buildingName: [person.ResidentialAddressHouseName__c],
                buildingNumber: [person.ResidentialAddressHouseNumber__c],
                poBoxNumber: [person.ResidentialAddressPoBox__c],
                street: [person.ResidentialAddressStreet__c],
                secondaryStreet: [person.ResidentialAddressSecondaryStreet__c],
                subBuilding: [person.ResidentialAddressSubBuilding__c]
            })
        });

        this.addOrRemoveDateOfBirthAndCodiceFiscale(formGroup, countryOfIncorporation, person);
        this.addOrRemoveFatcaSubjectQuestion(formGroup, person);

        return formGroup;
    }

    interpretMobileNumber(fullNumber: string, icc: string): { icc: string; number: string } {
        let numberOnly = fullNumber;
        if (numberOnly?.includes(icc)) {
            numberOnly = numberOnly.substring(icc.length);
        }
        return {
            icc: icc,
            number: numberOnly
        };
    }

    applyDefaultValidators(form: FormGroup<PersonFormGroup>): void {
        for (const [key, control] of Object.entries(form.controls)) {
            const c = (control as AbstractControl);
            if (!this.defaultValidators[key]) {
                this.logger.debug("No default validators for ", key);
                continue;
            }
            for (const v of this.defaultValidators[key]) {
                if (c.enabled && !c.hasValidator(v)) {
                    c.addValidators(v);
                }
            }
        }
    }

    applyUniquenessValidators(form: FormGroup<PersonFormGroup>, contactsSet: Contact[]): void {
        const uniqueEmailValidator = this.uniqueEmailValidatorFactory(form.value.contactExternalId, contactsSet);
        const uniqueMobileValidator = this.uniqueMobileNumberValidatorFactory(form.value.contactExternalId, contactsSet);
        form.controls.email.addValidators(uniqueEmailValidator);
        form.controls.mobile.addValidators(uniqueMobileValidator);

        if (form.controls.codiceFiscale) {
            const uniqueCodiceFiscaleValidator = this.uniqueCodiceFiscaleValidatorFactory(form.value.contactExternalId, contactsSet);
            form.controls.codiceFiscale.addValidators(uniqueCodiceFiscaleValidator);
        }
    }

    addOrRemoveFatcaSubjectQuestion(personFormGroup: FormGroup<PersonFormGroup>, person: Contact = null): void {
        const formValues = personFormGroup.value;
        const usCitizenOrResident = [formValues.nationality, formValues.address.country].includes('USA');

        if (!usCitizenOrResident && !personFormGroup.controls.isFatcaSubject) {
            personFormGroup.addControl("isFatcaSubject", new FormControl(person?.ContactIsFatcaSubject__c));
            personFormGroup.controls.isFatcaSubject.updateValueAndValidity();
        }

        if (usCitizenOrResident && personFormGroup.controls.isFatcaSubject) {
            personFormGroup.removeControl("isFatcaSubject");
        }
    }

    addOrRemoveDateOfBirthAndCodiceFiscale(personFormGroup: FormGroup<PersonFormGroup>, countryOfIncorporation: string, person: Contact = null): void {
        const mustProvideCodiceFiscale = [countryOfIncorporation, personFormGroup.value.address.country].includes("ITA");

        if (mustProvideCodiceFiscale && !personFormGroup.controls.codiceFiscale) {
            personFormGroup.removeControl("dateOfBirth");
            personFormGroup.addControl("codiceFiscale", new FormControl<string>(person?.ContactFiscalCode__c));
            personFormGroup.controls.codiceFiscale.statusChanges.subscribe(() => {
                personFormGroup.controls.firstName.updateValueAndValidity();
                personFormGroup.controls.firstName.markAsTouched();
                personFormGroup.controls.lastName.updateValueAndValidity();
                personFormGroup.controls.lastName.markAsTouched();
            });
            personFormGroup.controls.codiceFiscale.updateValueAndValidity();
        }

        if (!mustProvideCodiceFiscale && !personFormGroup.controls.dateOfBirth) {
            personFormGroup.removeControl("codiceFiscale");
            const formattedBirthDate = person?.Birthdate ? moment(person.Birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY') : null;
            personFormGroup.addControl("dateOfBirth", new FormControl<string>(formattedBirthDate));
        }
    }

    mapFormValuesToContact(personFormGroup: FormGroup<PersonFormGroup>): Partial<Contact> {
        const values = personFormGroup.value;
        const isUsCitizenOrResident = [values.address.country, values.nationality].includes("USA");

        const cfValues = {
            birthPlace: null,
            dateOfBirth: ''
        };
        if (values.codiceFiscale) {
            cfValues.birthPlace = this.fiscalCodeService.retrieveBirthPlace(values.codiceFiscale);
            cfValues.dateOfBirth = moment(FiscalCodeService.retrieveBirthDate(values.codiceFiscale)).format('DD/MM/YYYY');
        }

        return {
            SoldoContactExternalID__c: values.contactExternalId,
            FirstName: values.firstName,
            LastName: values.lastName,
            ContactFiscalCode__c: values.codiceFiscale || '',
            ContactBirthPlace__c: cfValues.birthPlace?.town,
            ContactBirthState__c: cfValues.birthPlace?.state,
            ContactCitizenshipCountryISO__c: values.nationality,
            Birthdate: moment(values.dateOfBirth || cfValues.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            ContactPhoneICC__c: values.mobile.prefix,
            MobilePhone: `${values.mobile.prefix}${values.mobile.number}`,
            Email: values.email,
            ResidentialAddressLine1__c: values.address.line1,
            ResidentialAddressLine2__c: values.address.line2,
            ResidentialAddressLine3__c: values.address.line3,
            ResidentialAddressCity__c: values.address.city,
            ResidentialAddressPostalCode__c: values.address.postcode,
            ResidentialAddressCountryISO__c: values.address.country,
            ResidentialAddressState__c: values.address.state,
            ResidentialAddressServiceRefCode__c: values.address.idCheckAddressLookupAddressCode,
            ResidentialAddressHouseName__c: values.address.buildingName,
            ResidentialAddressHouseNumber__c: values.address.buildingNumber,
            ResidentialAddressPoBox__c: values.address.poBoxNumber,
            ResidentialAddressStreet__c: values.address.street,
            ResidentialAddressSecondaryStreet__c: values.address.secondaryStreet,
            ResidentialAddressSubBuilding__c: values.address.subBuilding,
            ContactIsFatcaSubject__c: isUsCitizenOrResident ? "Yes" : values.isFatcaSubject
        };
    }

    codiceFiscaleFirstNameValidatorFactory(): ValidatorFn {
        return (firstNameControl: AbstractControl): ValidationErrors => {
            const cfField = (firstNameControl.parent as FormGroup<PersonFormGroup>)?.controls.codiceFiscale;
            if (firstNameControl.value && cfField?.value && cfField?.valid) {
                if (!this.fiscalCodeService.validateAgainstFirstName(cfField.value, firstNameControl.value)) {
                    return {
                        "firstNameCfMismatch": true
                    };
                }
            }
            return null;
        };
    }

    codiceFiscaleLastNameValidatorFactory(): ValidatorFn {
        return (lastNameControl: AbstractControl): ValidationErrors => {
            const cfField = (lastNameControl.parent as FormGroup<PersonFormGroup>)?.controls.codiceFiscale;
            if (lastNameControl.value && cfField?.value && cfField?.valid) {
                if (!this.fiscalCodeService.validateAgainstLastName(cfField.value, lastNameControl.value)) {
                    return {
                        "lastNameCfMismatch": true
                    };
                }
            }
            return null;
        };
    }

    uniqueMobileNumberValidatorFactory(contactExternalId: string, allContacts: Contact[]): ValidatorFn {
        return (control: AbstractControl) => {
            const controlMobileNumber = control.value.prefix + control.value.number;
            const others = allContacts.filter(c => c.SoldoContactExternalID__c !== contactExternalId && this.hasSomeRole(c));

            if (others.some(c => c.MobilePhone === controlMobileNumber)) {
                return {
                    duplicated: true
                };
            }
            return null;
        };
    }

    uniqueEmailValidatorFactory(contactExternalId: string, allContacts: Contact[]): ValidatorFn {
        return (control: AbstractControl) => {
            const controlEmail:string = control.value;
            const others = allContacts.filter(c => c.SoldoContactExternalID__c !== contactExternalId && this.hasSomeRole(c));

            if (others.some(c => c.Email?.toLowerCase() === controlEmail?.toLowerCase())) {
                return {
                    noDuplicatedEmail: true
                };
            }
            return null;
        };
    }

    uniqueCodiceFiscaleValidatorFactory(contactExternalId: string, allContacts: Contact[]): ValidatorFn {
        return (control: AbstractControl) => {
            const controlCodiceFiscale = control.value;
            const others = allContacts.filter(c => c.SoldoContactExternalID__c !== contactExternalId && this.hasSomeRole(c));

            if (others.some(c => c.ContactFiscalCode__c === controlCodiceFiscale)) {
                return {
                    duplicated: true
                };
            }
            return null;
        };
    }

    hasSomeRole(c: Contact): boolean {
        return c.ContactIsPrimaryLead__c || c.ContactIsLegalOwner__c || c.ContactIsAdmin__c || c.ContactIsBeneficialOwner__c;
    }
}
