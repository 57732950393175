import { Component } from '@angular/core';

@Component({
  selector: 'internal-user-identity-verified',
  templateUrl: './internal-user-identity-verified.component.html',
  styleUrl: './internal-user-identity-verified.component.scss'
})
export class InternalUserIdentityVerifiedComponent {

}
