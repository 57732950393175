<div [formGroup]="addressForm" class="address-fields-gbr">

  <!-- SIMPLIFIED LINE 1, 2 ADDRESS -->
  <ng-container *ngIf="simplified">

    <!-- LINE 1 -->
    <soldo-field-layout
      cypressSelector="Address_TextInput_Line1"
      [control]="addressForm.controls['line1']"
      fieldName="line1" label="REGISTRATION.ADDRESS.LINE_1_LABEL">

      <input input type="text"
        formControlName="line1" name="line1"
        placeholder="{{ 'REGISTRATION.ADDRESS.LINE_1_PLACEHOLDER' | translate }}"
        [readOnly]="readonly">

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.controls['line1'].errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <!-- LINE 2 -->
    <soldo-field-layout
      cypressSelector="Address_TextInput_Line2"
      [control]="addressForm.controls['line2']"
      fieldName="line2" label="REGISTRATION.ADDRESS.LINE_2_LABEL">

      <input input type="text"
        formControlName="line2" name="line2"
        placeholder="{{ 'REGISTRATION.ADDRESS.LINE_2_PLACEHOLDER' | translate }}"
        [readOnly]="readonly">

      <ng-container errorMessage>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.controls['line2'].errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

  </ng-container>

  <!-- NOT SIMPLIFIED -->
  <ng-container *ngIf="!simplified">

    <!-- BUILDING NUMBER -->
    <soldo-field-layout
      cypressSelector="Address_TextInput_BuildingNumber"
      [control]="addressForm.controls['buildingNumber']" fieldName="buildingNumber"
      label="REGISTRATION.ADDRESS.BUILDING_NUMBER_LABEL">

      <input input type="text"
        placeholder="{{ 'REGISTRATION.ADDRESS.BUILDING_NUMBER_PLACEHOLDER' | translate }}"
        formControlName="buildingNumber" name="buildingNumber"
        [ngClass]="{'ng-invalid':addressForm.errors?.nameornumber}"
        [readOnly]="readonly">

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.controls['buildingNumber'].errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <!-- BUILDING NAME -->
    <soldo-field-layout
      cypressSelector="Address_TextInput_BuildingName"
      [control]="addressForm.controls['buildingName']" fieldName="buildingName"
      label="REGISTRATION.ADDRESS.BUILDING_NAME_LABEL">

      <input input type="text"
        formControlName="buildingName" name="buildingName"
        placeholder="{{ 'REGISTRATION.ADDRESS.BUILDING_NAME_PLACEHOLDER' | translate }}"
        [ngClass]="{'ng-invalid':addressForm.errors?.nameornumber}"
        [readOnly]="readonly">

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.controls['buildingName'].errors?.maxlength?.requiredLength} }}</soldo-field-error>

        <div *ngIf="addressForm.errors?.nameornumber" class="field-validation-error caption">
          {{ 'REGISTRATION.ADDRESS.BUILDING_NAME_OR_NUMBER_REQUIRED' | translate }}
        </div>
      </ng-container>
    </soldo-field-layout>

    <!-- FLAT NUMBER -->
    <soldo-field-layout
      cypressSelector="Address_TextInput_SubBuilding"
      [control]="addressForm.controls['subBuilding']"
      fieldName="subBuilding" label="REGISTRATION.ADDRESS.SUBBUILDING_LABEL">

      <input input type="text"
        placeholder="{{ 'REGISTRATION.ADDRESS.SUBBUILDING_PLACEHOLDER' | translate }}"
        formControlName="subBuilding" name="subBuilding"
        [readOnly]="readonly">

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.controls['subBuilding'].errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <!-- STREET NAME -->
    <soldo-field-layout
      cypressSelector="Address_TextInput_Street"
      [control]="addressForm.controls['street']" fieldName="street"
      label="REGISTRATION.ADDRESS.STREET_LABEL">

      <input input type="text"
        placeholder="{{ 'REGISTRATION.ADDRESS.STREET_PLACEHOLDER' | translate }}"
        formControlName="street" name="street"
        [readOnly]="readonly">

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.controls['street'].errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

  </ng-container>

  <!-- CITY -->
  <soldo-field-layout
    cypressSelector="Address_TextInput_City"
    [control]="addressForm.controls['city']" fieldName="city"
    label="REGISTRATION.ADDRESS.CITY_LABEL">

    <input input type="text"
      placeholder="{{ 'REGISTRATION.ADDRESS.CITY_PLACEHOLDER' | translate }}"
      formControlName="city" name="city"
      [readOnly]="readonly">

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.controls['city'].errors?.maxlength?.requiredLength} }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <!-- POSTCODE -->
  <soldo-field-layout
    cypressSelector="Address_TextInput_Postcode"
    [control]="addressForm.controls['postcode']" fieldName="postcode"
    label="REGISTRATION.ADDRESS.POSTCODE_LABEL">

    <input input type="text"
      placeholder="{{ 'REGISTRATION.ADDRESS.POSTCODE_PLACEHOLDER' | translate }}"
      formControlName="postcode" name="postcode"
      #postcodeInput (input)="postcodeInput.value=$event.target.value.toUpperCase()"
      [readOnly]="readonly">

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error errorName="pattern">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
      <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value: addressForm.controls['postcode'].errors?.maxlength?.requiredLength} }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <input *ngIf="!simplified" type="hidden" formControlName="line1">
  <input *ngIf="!simplified" type="hidden" formControlName="line2">
  <input type="hidden" formControlName="state">
  <input *ngIf="simplified" type="hidden" formControlName="street">
  <input *ngIf="simplified" type="hidden" formControlName="buildingName">
  <input *ngIf="simplified" type="hidden" formControlName="buildingNumber">
  <input *ngIf="simplified" type="hidden" formControlName="subBuilding">

</div>
