import { Component, OnInit } from '@angular/core';
import { IntercomIntegrationService } from '../../shared/services/intercomIntegration.service';
import { I18nService } from '../../shared/services/i18n.service';

@Component({
    selector: 'app-identity-verification-complete',
    templateUrl: './identity-verification-complete.component.html',
    styleUrls: ['./identity-verification-complete.component.scss']
})
export class IdentityVerificationCompleteComponent implements OnInit {

    constructor(
        private readonly intercomIntegrationService: IntercomIntegrationService,
        private readonly i18nService: I18nService
    ) { }

    ngOnInit() {
        this.intercomIntegrationService.init();
        this.intercomIntegrationService.onNavigationEnd("Identity Verified");
        this.i18nService.turnOnLanguageSelection();
    }

    continue() {
        window.location.assign("https://www.soldo.com/");
    }

}
