import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { AppConfig, APP_CONFIG } from '@src/app/app-config.module';


export interface IIndustry {
    label: string;
    value: string;
}

@Injectable()
export class IndustriesService {

    private industries$: Subject<IIndustry[]> = new BehaviorSubject<IIndustry[]>([]);

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
        this._loadData();
        this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
            this._loadData();
        });
    }

    private _loadData(): void {
        const industriesApiUrl = this.config.localUrlEndpoint + "/industries." + this.translate.currentLang + ".json";

        this.http.get<IIndustry[]>(industriesApiUrl).subscribe(result => {
            this.industries$.next(result);
        });
    }

    get industries(): Observable<IIndustry[]> {
        return new Observable(fn => this.industries$.subscribe(fn));
    }

}
