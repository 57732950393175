import { Component, EventEmitter } from '@angular/core';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { CompanyLookupResult } from '@shared/models/company-search.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { CompanySearchService } from '@shared/services/company-search.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { LeadStartData } from '@shared/services/lead-data-mediator.service';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { SignupCustomOutboundActions, SignupSubStepOutboundAction, SignupSubStepType } from '../../signup-step.model';

@Component({
    selector: 'substep-company-search-details',
    templateUrl: './company-search-details.component.html',
    styleUrls: ['./company-search-details.component.scss']
})
export class CompanySearchDetailsSubStepComponent extends SignupSubStepType {
    subStepName: string = "Company Search Details";

    loading: boolean = true;

    country: string;
    searchResult: CompanyLookupResult = null;
    isCompleteDataset: boolean = true;

    constructor(
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService,
        private companySearchService: CompanySearchService,
        private registrationDataService: RegistrationDataService) {

        super();
        this.controlActionEventEmitter = new EventEmitter<SignupSubStepOutboundAction>();
    }

    init(d: Partial<LeadStartData>): void {

        this.country = d.companyCountry;

        this.searchResult = this.registrationDataService.getCompanySearchLookupProfile(d.kybProviderId);


        if (this.searchResult) {
            this.loading = false;
            this.isCompleteDataset = this.checkAddressIntegrity(this.searchResult);
        } else {
            this.companySearchService.lookup(d.kybProviderId, d.companyCountry).then(r => {
                this.searchResult = r;
                this.isCompleteDataset = this.checkAddressIntegrity(r);
                if (this.searchResult.status) {
                    this.searchResult.status = this.searchResult.status.split("-").map(s => s.slice(0, 1).toUpperCase() + s.slice(1)).join(" ");
                }
                this.loading = false;
            }, () => {
                this.loading = false;
                this.isCompleteDataset = false;
            });
        }
    }

    checkAddressIntegrity(result: CompanyLookupResult): boolean {
        const requiredAddressFields = [
            result.city,
            result.postcode,
            result.registeredAddressLine1
        ];
        return requiredAddressFields.every((fieldValue) => {
            return fieldValue && fieldValue !== "";
        });
    }

    enterManually(): void {
        this.controlActionEventEmitter.emit({
            action: SignupCustomOutboundActions.SWITCH_TO_MANUAL_COMPANY_EDIT,
            data: null
        });
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        this.registrationDataService.setCompanySearchLookupProfile(this.searchResult);

        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.NEXT,
            data: this.getData()
        });
    }

    getData(): Partial<LeadStartData> {
        return {
            companyName: this.searchResult.name
        };
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

}
