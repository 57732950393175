<div class="idv-page-content">

  <header>
    <h2>{{ 'REGISTRATION.IDV_COMPLETE.TITLE' | translate }}</h2>

    <img src="../../../assets/images/email-ok.png" alt="mail"/>

  </header>
  <p>{{ 'REGISTRATION.IDV_COMPLETE.PARAGRAPH' | translate }}</p>

  <button class="primary" (click)="continue()">{{ 'REGISTRATION.IDV_COMPLETE.CTA' | translate }}</button>

</div>


