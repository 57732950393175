<h2>{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.CARDS.TITLE' | translate}}</h2>
<h5>{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.CARDS.SUB_TITLE' | translate}}</h5>

<div class="tip-box caption">
  {{'REGISTRATION.EXPECTED_ACTIVITY_STEP.CARDS.TIP_TEXT' | translate}}
</div>
<div class="layout-section">
  <form [formGroup]="form" novalidate>
    <h5>{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.CARDS.LABEL' | translate }}</h5>
    <soldo-field-layout *ngIf="form.get('numberOfCards')"
      [control]="form.controls['numberOfCards']"
      fieldName="numberOfCards"
      cypressSelector="Expected_RadioButton_NumberOfCards"
      class="chips-radio"
      >

      <fieldset input>
        <div *ngFor="let option of numberOfCardsOptions; let i = index" class="radio-item"
          [attr.data-cy]="'Expected_RadioButton_NumberOfCards_'+option.value">
          <input type="radio" formControlName="numberOfCards" [id]="'numberOfCardsOption'+i" [value]="option.value">
          <label [for]="'numberOfCardsOption'+i">{{ option.label }}</label>
        </div>
      </fieldset>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.CARDS.RANGE_REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <div class="form-page-CTA-row">
      <button type="button" (click)="previousStep()" data-cy="Expected_Button_PreviousStepButton">
        {{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
      </button>
      <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}"
      (click)="nextStep()" data-cy="Expected_Button_NextStepButton">{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.NEXT_SUB_STEP_BUTTON' | translate }}</button>
    </div>
  </form>
</div>
