<h1 class="v2">{{'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.TITLE' | translate}}</h1>
<p class="subtitle" *ngIf="!form.controls['code']">{{'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.SUBTITLE' | translate}}</p>

<form [formGroup]="form" novalidate autocomplete="off">

  <soldo-field-layout
  [control]="form.controls['email']"
  fieldName="email"
  [required]="true"
  cypressSelector="Signup_TextInput_EntityEmailAddress"
  label="REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.WORK_EMAIL_LABEL">

    <input input
      trim="all"
      type="text"
      name="email"
      formControlName="email"
      class="form-control"
      placeholder="{{ 'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.EMAIL_PLACEHOLDER' | translate }}"
      (input)="onEmailChange()">

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.EMAIL_REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error error errorName="invalidEmailFormat">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
      <soldo-field-error error errorName="maxlength">
        {{ 'MAX_LENGHT' | translate : { value: form.get('email').errors?.maxlength?.requiredLength} }}
      </soldo-field-error>
      <soldo-field-error error errorName="notYourEmail">{{ 'NOT_YOUR_EMAIL' | translate }}</soldo-field-error>
      <soldo-field-error error errorName="blacklistedDomain">{{ 'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.FREE_DOMAIN_ERROR' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <p class="subtitle verification-code-subtitle" *ngIf="form.controls['code']">{{'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.VERIFICATION_CODE_SUBTITLE' | translate}}</p>

  <div class="privacy-notice-box" *ngIf="!form.controls['code']">
    <ng-container *ngIf="!isDirectSales">
      <p>{{'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.PRIVACY_PARAGRAPH_1' | translate }}</p>
      <p [innerHTML]="'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.PRIVACY_PARAGRAPH_2' | translate:{'privacyLink': privacyLink}"></p>
    </ng-container>
    <ng-container *ngIf="isDirectSales">
      <p>{{'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.PRIVACY_PARAGRAPH_1_DS' | translate }}</p>
      <p [innerHTML]="'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.PRIVACY_PARAGRAPH_2_DS' | translate:{'privacyLink': privacyLink}"></p>
    </ng-container>
  </div>

  <!-- Verification Code -->
  <ng-container *ngIf="form.controls['code']">

    <soldo-field-layout
      [control]="form.controls['code']"
      fieldName="code"
      [required]="true"
      cypressSelector="Signup_TextInput_EntityEmailVerificationCode"
      label="REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.EMAIL_CODE_LABEL">

        <input
          trim="all"
          type="text"
          inputmode="tel"
          name="code"
          formControlName="code"
          class="form-control short-input"
          placeholder="{{ 'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.EMAIL_CODE_PLACEHOLDER' | translate }}"
          (input)="onCodeInput($event)"
          (paste)="onCodeInput($event)">

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.CODE_REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="pattern">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="codeMismatch">{{ 'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.CODE_MISMATCH' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="maxlength">
          {{ 'MAX_LENGHT' | translate : { value: codeLength } }}
        </soldo-field-error>
      </ng-container>
    </soldo-field-layout>


    <p class="subtitle">{{'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.RESEND_EMAIL_TITLE' | translate}}</p>

    <button data-cy="Signup_Button_ResendEmailVerificationCode" type="button" class="btn btn-link resend-button" (click)="requestCode()" [disabled]="!resendCodeEnabled">
      {{'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.RESEND_EMAIL_LINK' | translate}}
    </button>

  </ng-container>

</form>

<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.SIGNUP_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
