import { Injectable } from "@angular/core";

export interface ICounty {
    code: string;
    principal: string;
}

export interface ITown {
    town: string;
    county: string;
    region: string;
    tax_code: string;
}

export interface IRegion {
    region: string;
    code: string;
}

export interface ICountiesAndTownsFile {
    regions: IRegion[];
    counties: ICounty[];
    towns: ITown[];
}

@Injectable()
export class ItalianTownsService {

    private _dataFile: Promise<ICountiesAndTownsFile>;

    constructor() {
        this._loadCountiesAndTowns();
    }

    private _loadCountiesAndTowns(): void {
        this._dataFile = import(`../data/towns.it.json`);
    }

    get counties(): Promise<ICounty[]> {
        return this._dataFile.then(data => data.counties);
    }

    get towns(): Promise<ITown[]> {
        return this._dataFile.then(data => data.towns);
    }

    get data(): Promise<ICountiesAndTownsFile> {
        return this._dataFile;
    }

    resolve(): Promise<ICountiesAndTownsFile> {
        return this.data;
    }
}
