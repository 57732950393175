<div class="idv-page-content">

    <header>
      <h2>{{ 'REGISTRATION.DIRECT_THANK_YOU.TITLE' | translate }}</h2>

      <div class="image emailimage"></div>
    </header>

      <p *ngIf="companyName">{{ 'REGISTRATION.DIRECT_THANK_YOU.LINE_1' | translate : { companyName: companyName } }}</p>
      <p *ngIf="email">{{ 'REGISTRATION.DIRECT_THANK_YOU.LINE_2' | translate : { email: email } }}</p>
      <p>{{ 'REGISTRATION.DIRECT_THANK_YOU.LINE_3' | translate }}</p>
      <p>{{ 'REGISTRATION.DIRECT_THANK_YOU.LINE_4' | translate }}</p>

    <div class="cta-section">
      <button class="primary" (click)="onClickCTA()">{{ 'REGISTRATION.DIRECT_THANK_YOU.BUTTON' | translate }}</button>
    </div>

  </div>

