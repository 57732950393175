import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LoggerModule } from 'ngx-logger';

import { environment } from '@src/environments/environment';


/* App Root */
import { AppConfigModule } from './app-config.module';
import { AppComponent } from './app.component';

/* Routing Module */
import { AppRoutingModule } from './app-routing.module';

/* Shared Service */
import { RegistrationModule } from '@src/app/registration/registration.module';
import { AlertService } from "@shared/services/alert.service";
import { CountryService } from "@shared/services/country.service";
import { HereApiTokenInterceptor } from '@shared/services/here-api-token-interceptor.service';
import { HereApiTokenService } from '@shared/services/here-api-token.service';
import { I18nService } from '@shared/services/i18n.service';
import { IntercomService } from '@shared/services/intercom.service';
import { IntercomIntegrationService } from '@shared/services/intercomIntegration.service';
import { RequestAggregatorService } from "@shared/services/requestAggregator.service";
import { ResumeService } from '@shared/services/resume.service';
import { SoldoCookieService } from "@shared/services/soldo-cookie.service";
import { StartupService } from '@shared/services/startup.service';
import { StatusService } from "@shared/services/status.service";
import { UkAddressLookupService } from "@shared/services/uk-address-lookup.service";
import { SharedModule } from "@shared/shared.module";
import { SuccessModule } from "@src/app/success/success.module";
import { FileImportLoader } from './i18n/fileImportLoader.class';
import { ApiCallsInterceptorService } from './mock/api-calls-interceptor.service';
import { StaticPagesModule } from './static-pages/static-pages.module';

// AoT requires an exported function for factories
export function TranslationLoaderFactory() {
    return new FileImportLoader();
}

export function startupServiceFactory(startupService: StartupService): () => Promise<any> {
    return () => startupService.load();
}

@NgModule({
    imports: [
        LoggerModule.forRoot({ serverLogLevel: environment.logLevel, level: environment.logLevel }),
        AppConfigModule,
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslationLoaderFactory
            }
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        RegistrationModule,
        SuccessModule,
        StaticPagesModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        CountryService,
        StartupService,
        StatusService,
        ResumeService,
        // RegistrationDataService,
        RequestAggregatorService,
        UkAddressLookupService,
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true
        },
        HereApiTokenService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HereApiTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiCallsInterceptorService,
            multi: true
        },
        AlertService,
        CookieService,
        I18nService,
        SoldoCookieService,
        IntercomService,
        IntercomIntegrationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
