import { AddressFormControlMetadata } from "./address2.component";
import { UntypedFormGroup, Validators, ValidatorFn } from "@angular/forms";
import { Router } from "@angular/router";
import { Account } from "../../models/registration.model";
import { AmplitudeService } from "../../services/amplitude.service";
import { Subscription } from "rxjs";
import { NGXLogger } from "ngx-logger";
import { CustomValidators } from "../../customValidators";

export abstract class AbstractAddressFieldsComponent {

    public addressForm: UntypedFormGroup;
    public simplified: boolean = false;
    public fieldMetadata: AddressFormControlMetadata;
    protected applyingSearchResult: boolean = true;
    protected readonly localSubscriptions: Subscription[] = [];

    constructor(
        protected amplitudeService: AmplitudeService,
        protected router: Router,
        protected logger: NGXLogger
    ) { }

    protected initCompleted(): void {
        this.applyingSearchResult = false;
    }

    protected configField(
        controlName: string,
        modelFieldName: keyof Account,
        mandatory: boolean,
        setMaxLenght: boolean,
        extraValidators: ValidatorFn[],
        invalidateSearchOnEdit: boolean = false): void {

        this.fieldMetadata[controlName].mandatory = mandatory;
        const control = this.addressForm.get(controlName);
        control.enable();
        const validators = [].concat(extraValidators);
        if (setMaxLenght) {
            validators.push(CustomValidators.getAccountMaxlengthValidator(modelFieldName));
        }
        if (mandatory) {
            validators.push(Validators.required);
        }
        if (invalidateSearchOnEdit) {
            this.localSubscriptions.push(control.valueChanges.subscribe(() => {
                if (!this.applyingSearchResult && this.isDisplayingSearchResult()) {
                    this.logger.debug("INVALIDATING SEARCH RESULT", controlName);
                    this.invalidateSearchResult(controlName);
                }
            }));
        }
        control.setValidators(validators);
        control.updateValueAndValidity();
    }

    protected getFormId(): string {
        let formId: string = "none";
        if (this.addressForm?.parent?.get("personFormId")) {
            formId = this.addressForm.parent.get("personFormId").value;
        }
        return formId;
    }

    protected trackSearchResultSelected(): void {
        this.amplitudeService
            .trackAddressSearchResultSelected(this.router.url, this.getFormId(), this.addressForm.get("country").value, this.addressForm.valid);
    }

    protected trackSeachInvalidated(fieldName: string): void {
        this.amplitudeService.trackAddressSearchResultDiscarded(this.router.url, this.getFormId(), this.addressForm.get("country").value, fieldName);
    }

    protected temporarilyDisableSearchInvalidationCheck() {
        this.applyingSearchResult = true;
        setTimeout(() => {
            this.applyingSearchResult = false;
        }, 500);
    }

    abstract isDisplayingSearchResult(): boolean
    abstract invalidateSearchResult(fieldName: string): void

    clearAllSubscriptions(): void {
        for (const s of this.localSubscriptions) {
            s.unsubscribe();
        }
    }
}
