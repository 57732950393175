import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";
import { DocUploadComponent } from './doc-upload/doc-upload.component';
import { HooYuLinkPageComponent } from './hoo-yu-link-page/hoo-yu-link-page.component';
import { HooYuVerificationEmbeddedComponent } from './hoo-yu-verification-embedded/hoo-yu-verification-embedded.component';
import { HooYuVerificationLandingComponent } from './hoo-yu-verification-landing/hoo-yu-verification-landing.component';
import { IdentityVerificationCompleteComponent } from './identity-verification-complete/identity-verification-complete.component';
import { IdentityVerificationLandingComponent } from './identity-verification-landing/identity-verification-landing.component';
import { IdvLinkPageComponent } from './idv-link-page/idv-link-page.component';
import { SuccessComponent } from "./success.component";
import { successRouting } from "./success.routes";


@NgModule({
    imports: [
        CommonModule,
        successRouting,
        TranslateModule,
        FormsModule
    ],
    declarations: [
        SuccessComponent,
        DocUploadComponent,
        HooYuVerificationLandingComponent,
        HooYuLinkPageComponent,
        IdentityVerificationLandingComponent,
        IdentityVerificationCompleteComponent,
        IdvLinkPageComponent,
        HooYuVerificationEmbeddedComponent
    ]
})
export class SuccessModule { }
