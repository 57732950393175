<div class="success">
  <header>
    <h2>{{ "SUCCESS."+getSlug()+".TITLE" | translate }}</h2>

    <p innerHtml="{{ 'SUCCESS.'+getSlug()+'.LINE1' | translate }}"></p>

    <div class="pic">
      <img alt="success" class="desktop" src="assets/images/ico-success.svg">
      <img alt="success" class="mobile" src="assets/images/ico-success-mobile.svg">
    </div>

  </header>

  <div class="footer" *ngIf="approved == 'PENDING'">
    <h4>
      {{ "SUCCESS."+getSlug()+".WHAT_NEXT" | translate }}
    </h4>
    <p>{{ "SUCCESS."+getSlug()+".LINE2" | translate }}</p>

    <button (click)="onClickCTA()" class="primary">{{ "SUCCESS."+getSlug()+".BUTTON" | translate }}</button>
  </div>

  <div class="footer" *ngIf="approved == 'APPROVED'">
    <h4>
      {{ "SUCCESS."+getSlug()+".WHAT_NEXT" | translate }}
    </h4>
    <div class="footer-row">
        <div class="footer-column">
          <div class="number">1</div>
          {{ "SUCCESS."+getSlug()+".COL_1" | translate }}
        </div>
        <div class="footer-column">
          <div class="number">2</div>
          {{ "SUCCESS."+getSlug()+".COL_2" | translate }}
        </div>
        <div class="footer-column">
          <div class="number">3</div>
          {{ "SUCCESS."+getSlug()+".COL_3" | translate }}
        </div>
    </div>
  </div>
</div>
