<h2>{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.USE_CASE.TITLE' | translate}}</h2>
<h5>{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.USE_CASE.TIP' | translate}}</h5>

<div class="layout-section">
  <ng-container *ngIf="form">
    <form [formGroup]="form" novalidate class="form-input">
      <p><strong>{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.USE_CASE.LABEL' | translate}}</strong></p>

      <ng-container *ngFor="let useCase of useCases">
        <div class="form-input" [attr.data-cy]="'Expected_RadioButton_UseCases_' + useCase.label"
          [ngClass]="{ 'ng-touched': form.controls[useCase.label].touched, 'ng-invalid': form.controls[useCase.label].invalid }">
          <input #checkbox [id]="useCase.label" type="checkbox" [formControlName]="useCase.label"
          (change)="updateCheck(useCase, checkbox.checked)">
          <label [for]="useCase.label">
            {{'REGISTRATION.EXPECTED_ACTIVITY_STEP.USE_CASE.USE_CASE_'+useCase.label | translate}}
          </label>
          <div class="conditional-subfield-container" *ngIf="form.controls['description'] && useCase.value === 'Other'">
            
            <soldo-field-layout [control]="form.controls['description']" cypressSelector="Expected_RadioButton_UseCases_Description"
              label="REGISTRATION.EXPECTED_ACTIVITY_STEP.USE_CASE.OTHER_LABEL" fieldName="description">
              
              <ng-container input>
                <textarea name="description" formControlName="description" type="text" maxlength="{{this.useCaseDescriptionMaxlength}}"></textarea>
                <character-counter [maxlength]="useCaseDescriptionMaxlength" [control]="form.controls['description']"></character-counter>
              </ng-container>              

              <ng-container errorMessage>
                <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
                <soldo-field-error error errorName="maxlength">
                  {{ 'MAX_LENGHT' | translate: { value: form.controls.description.errors?.maxlength?.requiredLength } }}
                </soldo-field-error>
              </ng-container>
            
            </soldo-field-layout>

          </div>
        </div>
      </ng-container>

      <div class="validation-errors-container">
        <div class="caption field-validation-error" *ngIf="form.touched && !atLeastOneSelected()">
          {{'ONE_OR_MORE_OPTIONS_REQUIRED' | translate}}
        </div>
      </div>

      <div class="form-page-CTA-row">
        <button type="button" (click)="previousStep()" data-cy="Expected_Button_PreviousStepButton">
          {{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
        </button>
        <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}"
        (click)="nextStep()" data-cy="Expected_Button_NextStepButton">{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.NEXT_SUB_STEP_BUTTON' | translate }}</button>
      </div>
    </form>
  </ng-container>
</div>
