import { ThemeConfig } from "onfido-sdk-ui/types/shared/Types";


export const OnfidoUIConfig: ThemeConfig = {
    fontFamilyTitle: "system-ui, sans-serif",
    fontFamilySubtitle: "system-ui, sans-serif",
    fontFamilyBody: "system-ui, sans-serif",

    colorBackgroundSurfaceModal: "#FFFFFF",
    colorBorderSurfaceModal: "#D2D2D6",
    borderRadiusSurfaceModal: "8px",

    // colorBackgroundIcon: "#FFF0D2", KEEP IT DEFAULT GREY

    borderRadiusButton: "6px",

    colorContentButtonPrimaryText: "#FFFFFF",

    colorBackgroundButtonPrimary: "#242B30",
    colorBorderButtonPrimary: "#242B30",

    colorBackgroundButtonPrimaryHover: "#0B1412",
    colorBorderButtonPrimaryHover: "#0B1412",

    colorBackgroundButtonPrimaryActive: "#242B30", // stands for active focus
    colorBorderButtonPrimaryActive: "#047CC3", // stands for active focus

    colorContentButtonSecondaryText: "#383D44",

    colorBackgroundButtonSecondary: "#FFFFFF",
    colorBorderButtonSecondary: "#242B30",

    colorBackgroundButtonSecondaryHover: "#FFFFFF",
    colorBorderButtonSecondaryHover: "#0B1412",

    colorBackgroundButtonSecondaryActive: "#FFFFFF",
    colorBorderButtonSecondaryActive: "#047CC3",

    colorInputOutline: "#047CC3",

    colorBorderDocTypeButtonHover: "#000000",
    colorBorderDocTypeButtonActive: "#047CC3",

    colorBorderLinkUnderline: "#2b2d33",
    colorContentLinkTextHover: "#2b2d33",
    colorBackgroundLinkHover: "transparent",
    colorBackgroundLinkActive: "transparent"

    // NEW borderStyleSurfaceModal
    // NEW fontWeightBody
    // NEW fontSizeBody
    // NEW fontSizeSubtitle
    // NEW fontSizeTitle
    // NEW colorBackgroundDocTypeButton
    // NEW colorContentBody
    // NEW colorContentTitle
    // NEW colorContentSubtitle
    // NEW colorBorderDocTypeButton
    // NEW colorBackgroundDocTypeButtonHover
    // NEW colorBackgroundDocTypeButtonActive
    // NEW colorContentDocTypeButton
    // NEW colorIcon
    // NEW colorContentInfoPill
    // NEW colorBackgroundInfoPill
    // NEW colorBackgroundSelector
    // NEW colorBackgroundDropdownItemHover
    // NEW colorContentButtonTertiaryText
    // NEW colorContentInput
    // NEW colorBackgroundInput
    // NEW colorBorderInput
};
