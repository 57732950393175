
import {take} from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface PushAPIEvent {
    name: string;
    value: any;
}

interface PushAPIEventEmitter {
    name: string,
    query?: (event: PushAPIEvent) => boolean,
    emitter: EventEmitter<PushAPIEvent>,
    promise: Promise<PushAPIEvent>
}

@Injectable()
export class RegistrationWebappPushApiService {

    private eventQueue: Array<PushAPIEvent> = [];
    private emitters: Array<PushAPIEventEmitter> = [];

    constructor() {
        window["registrationWebappPushAPI"] = {
            push: (event: PushAPIEvent) => {
                return this.push(event);
            },
            get: (name: string) => {
                return this.get(name);
            }
        };
    }

    subscribe(name: string, query?: (event: PushAPIEvent) => boolean): Observable<PushAPIEvent> {
        return this.getEmitterForEvent(name, query).emitter.asObservable();
    }

    promise(name: string, query?: (event: PushAPIEvent) => boolean): Promise<PushAPIEvent> {
        return this.getEmitterForEvent(name, query).promise;
    }

    private getEmitterForEvent(name: string, query?: (event: PushAPIEvent) => boolean): PushAPIEventEmitter {
        let emitterEntry: PushAPIEventEmitter = this.emitters.find(em => em.query == undefined && em.name == name);
        if (typeof query == 'function' || emitterEntry == undefined) {
            let eventEmitter = new EventEmitter<PushAPIEvent>();
            emitterEntry = {
                name: name,
                query: query,
                emitter: eventEmitter,
                promise: eventEmitter.pipe(take(1)).toPromise()
            };
            this.emitters.push(emitterEntry);
            setTimeout(() => {
                this.eventQueue.forEach(event => {
                    this.emitIfMatches(event, emitterEntry);
                });
            }, 0);
        } else {
            //console.log("emitter reused");
        }
        return emitterEntry;
    }

    push(event: PushAPIEvent) {
        this.eventQueue.push(event);
        this.emitters.forEach(emitter => {
            this.emitIfMatches(event, emitter);
        });
    }

    private emitIfMatches(event: PushAPIEvent, emitter: PushAPIEventEmitter): boolean {
        if (typeof emitter.query == 'function') {
            if (emitter.query(event)) {
                emitter.emitter.emit(event);
                return true;
            }
        } else if (emitter.name == event.name) {
            emitter.emitter.emit(event);
            return true;
        }
        return false;
    }

    get(name: string): PushAPIEvent {
        let res = this.find(e => e.name == name);
        return res[0] || null;
    }

    find(searchFunction: (event: PushAPIEvent) => boolean): Array<PushAPIEvent> {
        return this.eventQueue.filter(searchFunction);
    }
}
