import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export enum HereApiService {
    GEOCODE = "geocode",
    DISCOVER = "discover",
    AUTOSUGGEST = "autosuggest",
    BROWSE = "browse",
    LOOKUP = "lookup"
}

@Injectable()
export class HereApiaddressLookupService {
    private baseURL = "https://{{service}}.search.hereapi.com/v1/{{service}}";

    constructor(
        private httpClient: HttpClient
    ) { }

    searchAddress(searchConf: HereAddressSearchConfiguration, term: string): Observable<HereApiAddressSearchResult[]> {
        const requestURL = new URL(this.baseURL.replace(/\{\{service\}\}/g, HereApiService.AUTOSUGGEST.toString()));
        const requestParams = searchConf.getSearchParams("houseNumber,street", term) as { [param: string]: string };
        return this.httpClient.get<any>(requestURL.toString(), {
            params: requestParams
        }).pipe(map(res => res.items));
    }

    lookupAddress(searchConf: HereAddressSearchConfiguration, id: string): Observable<HereApiAddressSearchResult> {
        const requestURL = new URL(this.baseURL.replace(/\{\{service\}\}/g, HereApiService.LOOKUP));
        const requestParams = searchConf.getLookupParams(id) as { [param: string]: string };
        return this.httpClient.get<any>(requestURL.toString(), {
            params: requestParams
        });
    }
}

export interface HereApiPosition {
    lat: number;
    lng: number;
}

export class HereAddressSearchConfiguration {
    //public service: HereApiService;
    public language: string;
    private searchIn: string;
    private searchAt: HereApiPosition;

    setSearchInCountry(countryISO: string, countryLang?: string) {
        this.searchIn = 'countryCode:' + countryISO;
        this.language = countryLang ? countryLang : 'en';
    }

    setSearchAtPosition(lng: number, lat: number) {
        this.searchAt = {
            lng: lng,
            lat: lat
        };
    }

    getLatLonString(): string {
        return [this.searchAt.lat, this.searchAt.lng].join(',');
    }

    getSearchParams(resultTypes: string, searchTerm: string): HereApiSearchParams {
        const sp: HereApiSearchParams = {};
        sp.q = searchTerm;
        sp.lang = this.language;
        if (resultTypes) {
            sp.resultTypes = resultTypes;
        }
        if (this.searchIn) {
            sp.in = this.searchIn;
        }
        if (this.searchAt) {
            sp.at = this.getLatLonString();
        }
        return sp;
    }

    getLookupParams(id: string): HereApiSearchParams {
        const sp: HereApiSearchParams = {};
        sp.id = id;
        sp.lang = this.language;
        return sp;
    }
}

export interface HereApiSearchParams {
    "resultTypes"?: string;
    "id"?: string;
    "q"?: string;
    "qq"?: string;
    "lang"?: string;
    "in"?: string;
    "at"?: string;
}

export interface HereApiAddressSearchResult {
    id: string;
    resultType: string;
    title: string;
    position: HereApiPosition;
    distance: number;
    address: HereApiAddress;
}

export interface HereApiAddress {
    label: string;
    countryCode: string;
    countryName: string;
    state?: string;
    county?: string;
    city: string;
    district?: string;
    street?: string;
    houseNumber?: string;
    postalCode?: string;
}
