import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '@src/app/app-config.module';
import { NGXLogger } from 'ngx-logger';

interface IntercomTransient {
    q?: Array<any>;
    c?: (any) => any;
    (): void;
}

declare type IntercomOperation = 'boot' | 'shutdown' | 'update' | 'hide' | 'show' | 'trackEvent' | 'reattach_activator';

interface IntercomObject {
    (op: IntercomOperation, ...args: Array<any>): void;
}

@Injectable()
export class IntercomService {

    private testMode: boolean = true;
    private intercomAppId: string;
    // private alignment: string;
    private window: any;
    private _isLoggedIn = false;

    /*@ngInject*/
    constructor(
        private logger: NGXLogger,
        @Inject(APP_CONFIG) private appConfig: AppConfig) {

        this.testMode = this.appConfig.intercomTestMode;
        this.intercomAppId = appConfig.intercomAppId;
        this.window = window;
    }

    initializeIntercom(): void {
        const APP_ID = this.intercomAppId;
        const w: any = this.window;
        const d = w.document;
        const ic: IntercomObject = w.Intercom;
        const intercomSettings = w.intercomSettings;

        this.logger.debug(`Initializing intercom script with app id: ${APP_ID}`);

        if (this.testMode) {
            w.Intercom = (op: string, ...args: Array<any>) => {
                this.logger.debug(`IntercomChat called with op: '${op}', `, args);
            };
        } else if (typeof ic === "function") {
            ic('reattach_activator');
            ic('update', intercomSettings);
        } else {

            const i: IntercomTransient = function (...args) {
                i.c(args);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;

            const l = function () {
                const s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = `https://widget.intercom.io/widget/${APP_ID}`;
                const x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };

            l();
        }
    }

    private getIntercom(): IntercomObject {
        return this.window["Intercom"];
    }

    private _boot(): void {
        const i = this.getIntercom();
        i('boot', {
            app_id: this.intercomAppId
        });
    }
    private _shutdown(): void {
        const i = this.getIntercom();
        i('shutdown');
    }

    hide(): void {
        const i = this.getIntercom();
        i('hide');
    }

    show(): void {
        const i = this.getIntercom();
        i('show');
    }

    terminateIntercom(): void {
        this._shutdown();
        this._isLoggedIn = false;
    }

    logIn(userData: { [key: string]: any }): void {
        this.logger.debug(`Performing signin with`, userData);
        userData.app_id = this.intercomAppId;
        const i = this.getIntercom();
        i('update', userData);
        this._isLoggedIn = true;
    }

    trackEvent(eventName: string, eventMetadata: { [key: string]: any }): void {
        this.logger.debug(`Tracking event '${eventName}' with:`, eventMetadata);
        const i = this.getIntercom();
        i('trackEvent', eventName, eventMetadata);
    }

    logOut() {
        this._shutdown();
        this._boot();
        this._isLoggedIn = false;
    }

    isLoggedIn(): boolean {
        return this._isLoggedIn;
    }
}
