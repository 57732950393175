import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";
import { DocUploadComponent } from './doc-upload/doc-upload.component';
import { successRouting } from "./success.routes";


@NgModule({
    imports: [
        CommonModule,
        successRouting,
        TranslateModule,
        FormsModule
    ],
    declarations: [
        DocUploadComponent
    ]
})
export class SuccessModule { }
