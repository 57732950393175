import { Component, OnInit, Renderer2, Type } from '@angular/core';
import { ModalDialogService } from '@shared/services/modal-dialog.service';

@Component({
    selector: 'modal-dialog',
    templateUrl: './modal-dialog.component.html',
    styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit {

    currentContentComponent: Type<Component>;

    constructor(
        private modalDialogService: ModalDialogService,
        private renderer: Renderer2
    ) { }

    ngOnInit() {
        this.modalDialogService.modalDialogEventEmitter.subscribe((content: Type<Component>) => {
            this.currentContentComponent = content;
            if (content) {
                this.renderer.addClass(document.body, 'modal-is-open');
            }
        });
    }
    closeFromOutsideDialog(e: Event) {
        if ((e.target as Element).id == "overlay") {
            this.dismiss();
        }
    }

    dismiss() {
        this.modalDialogService.closeModalDialog();
        this.renderer.removeClass(document.body, 'modal-is-open');
    }

}
