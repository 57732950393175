<h3 class="title">{{'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.TITLE' | translate}}</h3>

<div class="registered-address-box">

  <p><b>{{'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.REGISTERED_ADDRESS_TITLE' | translate}}</b></p>

  <div *ngIf="registeredCountry == 'ITA'">
    <span class="line-1" *ngIf="registeredAddress.line1">{{registeredAddress.line1}}</span>
    <span class="line-2" *ngIf="registeredAddress.line2">{{registeredAddress.line2}}</span>
    <span class="postcode" *ngIf="registeredAddress.postalCode">{{registeredAddress.postalCode}}</span>
    <span class="city" *ngIf="registeredAddress.city">{{registeredAddress.city}}</span>
    <span class="state" *ngIf="registeredAddress.state">{{registeredAddress.state}}</span>
  </div>

  <div *ngIf="registeredCountry !== 'ITA'">
    <span class="line-1" *ngIf="registeredAddress.line1">{{registeredAddress.line1}}</span>
    <span class="line-2" *ngIf="registeredAddress.line2">{{registeredAddress.line2}}</span>
    <span class="city" *ngIf="registeredAddress.city">{{registeredAddress.city}}</span>
    <span class="postcode" *ngIf="registeredAddress.postalCode">{{registeredAddress.postalCode}}</span>
  </div>

</div>

<p class="subtitle"><b>{{'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.QUESTION_SAME_ADDRESS' | translate}}</b></p>

<form [formGroup]="form" novalidate autocomplete="off">

  <div data-cy="Company_RadioButton_SeparateTradingAddress" class="d-flex radio-group">

    <input id="YES-trading" type="radio" [value]="false"
      formControlName="tradingAddressDifferent"
      data-cy="Company_RadioButton_SeparateTradingAddress_TRUE">
    <label for="YES-trading" class="flex-grow-1">{{ 'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.YES_ANSWER' | translate }}</label>

    <input id="NO-trading" type="radio" [value]="true"
      formControlName="tradingAddressDifferent"
      data-cy="Company_RadioButton_SeparateTradingAddress_FALSE">
    <label for="NO-trading" class="flex-grow-1">{{ 'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.NO_ANSWER' | translate }}</label>

  </div>

  <ng-container *ngIf="form.get('tradingAddress')">

      <p class="mt-3" *ngIf="tradingCountry == 'GBR'">
        {{'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.GBR_SEARCH_HELP' | translate}}
      </p>

      <p class="trading-address-title">
        {{'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.TRADING_ADDRESS_TITLE' | translate}}
      </p>

      <div class="mt-4">
        <address2-form-group
          [group]="form.get('tradingAddress')"
          [activeMarketsOnly]="true" [simplified]="true"
          data-cy="Company_Address_TradingAddress">
        </address2-form-group>
      </div>

  </ng-container>


</form>

<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.COMPANY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.COMPANY_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
