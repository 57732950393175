import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PersonFormGroup, PersonFormHelperService } from '../person-form/person-form-helper.service';
import { Account, Contact, ContactOperation } from '../../shared/models/registration.model';
import { APP_CONFIG, AppConfig } from '../../app-config.module';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { RegistrationDataService } from '../../shared/services/registrationData.service';
import { StepService } from '../../shared/services/steps.service';
import { AmplitudeService } from '../../shared/services/amplitude.service';
import { StatusService } from '../../shared/services/status.service';
import { scrollToFirstResult, SELECT_INVALID_SOLDO_FIELD } from '../../shared/formHelpers';

export enum ADMIN_CHOICE {
    NO_CHOICE,
    APPLICANT,
    LEGAL_REPRESENTATIVE,
    OTHER
}

interface AdminChoiceFormGroup {
    adminChoice: FormControl<ADMIN_CHOICE>;
    admin: FormGroup<PersonFormGroup>;
}

@Component({
    selector: 'admin',
    templateUrl: './admin.component.html',
    styleUrl: './admin.component.scss'
})
export class AdminComponent {

    form: FormGroup<AdminChoiceFormGroup>;
    loading: boolean = false;
    allContacts: Contact[] = [];
    applicantIsLegalRep: boolean;
    countryOfIncorporation: string;
    readonly: boolean = false;
    regulatoryInstitution: string;
    registrationProcessURL: string;

    constructor(
        @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
        protected logger: NGXLogger,
        private readonly router: Router,
        private readonly fb: FormBuilder,
        private readonly personFormHelper: PersonFormHelperService,
        private readonly registrationDataService: RegistrationDataService,
        private readonly stepService: StepService,
        private readonly amplitudeService: AmplitudeService,
        private readonly statusService: StatusService
    ) {

        this.countryOfIncorporation = this.registrationDataService.getCompanyCountry();
        this.allContacts = this.registrationDataService.getContacts();
        const account: Account = this.registrationDataService.getAccount();
        this.regulatoryInstitution = this.appConfig.regulatoryInstitutionShorthand[account.EMoneyLicenseIssuer__c];

        this.registrationProcessURL = this.appConfig.registrationProcessHelpURLs[this.countryOfIncorporation]
            || this.appConfig.registrationProcessHelpURLs.fallback;
    }

    public get applicantContact(): Contact {
        return this.allContacts.find(c => c.ContactIsPrimaryLead__c);
    }

    public get legalRepContact(): Contact {
        return this.allContacts.find(c => c.ContactIsLegalOwner__c);
    }

    ngOnInit() {
        this.applicantIsLegalRep = this.applicantContact.ContactIsLegalOwner__c;

        const adminContact = this.allContacts.find(c => c.ContactIsAdmin__c);

        if (adminContact) {
            this.initAsExistingAdmin(adminContact);
        } else {
            this.initAsNew();
        }

        this.form.controls.adminChoice.valueChanges.subscribe((choice) => {
            if (choice == ADMIN_CHOICE.APPLICANT) {
                this.selectApplicant();
            } else if (choice === ADMIN_CHOICE.LEGAL_REPRESENTATIVE) {
                this.selectLegalRepresentative();
            } else if (choice === ADMIN_CHOICE.OTHER) {
                this.selectNewContact();
            }
        });
    }

    private initAsNew() {
        this.form = this.fb.group({
            adminChoice: [ADMIN_CHOICE.NO_CHOICE, (control: AbstractControl) => {
                return (control.value === ADMIN_CHOICE.NO_CHOICE) ? { required: true } : null;
            }],
            admin: this.personFormHelper.createEmptyPersonFormGroup(this.countryOfIncorporation)
        });
    }

    private initAsExistingAdmin(adminContact: Contact) {
        let previousChoice: ADMIN_CHOICE = ADMIN_CHOICE.OTHER;
        if (adminContact.ContactIsLegalOwner__c) {
            previousChoice = ADMIN_CHOICE.LEGAL_REPRESENTATIVE;
            this.readonly = true;
        }
        if (adminContact.ContactIsPrimaryLead__c) {
            previousChoice = ADMIN_CHOICE.APPLICANT;
        }

        this.form = this.fb.group({
            adminChoice: [previousChoice as ADMIN_CHOICE],
            admin: this.personFormHelper.createPersonFormGroupForContact(adminContact, this.countryOfIncorporation)
        });
    }

    selectApplicant(): void {
        this.allContacts.forEach(c => {
            c.ContactIsAdmin__c = false;
        });
        this.applicantContact.ContactIsAdmin__c = true;
        this.readonly = this.applicantContact.ContactIsLegalOwner__c;

        const adminFormGroup = this.personFormHelper.createPersonFormGroupForContact(this.applicantContact, this.countryOfIncorporation);
        this.form.setControl("admin", adminFormGroup);
    }

    selectLegalRepresentative(): void {
        this.allContacts.forEach(c => {
            c.ContactIsAdmin__c = false;
        });
        this.legalRepContact.ContactIsAdmin__c = true;
        this.readonly = true;

        const adminFormGroup = this.personFormHelper.createPersonFormGroupForContact(this.legalRepContact, this.countryOfIncorporation);
        this.form.setControl("admin", adminFormGroup);
    }

    selectNewContact(): void {
        this.allContacts.forEach(c => {
            c.ContactIsAdmin__c = false;
        });
        this.readonly = false;

        const adminFormGroup = this.personFormHelper.createEmptyPersonFormGroup(this.countryOfIncorporation);
        this.form.setControl("admin", adminFormGroup);
    }

    private saveForm() {

        const adminValues = this.form.value.admin;
        this.logger.debug("Admin Values: ", adminValues);

        const contactsOperations: Array<ContactOperation> = [];

        if (adminValues.address.idCheckAddressLookupAddressCode) {
            this.amplitudeService.trackAddressSearchResultSubmitted(
                this.router.url,
                "none",
                adminValues.address.country
            );
        }

        const adminContact = this.personFormHelper.mapFormValuesToContact(this.form.controls.admin);

        contactsOperations.push({
            ...adminContact as ContactOperation,
            ContactIsAdmin__c: true
        });

        const others = this.allContacts.filter(c => {
            return c.SoldoContactExternalID__c !== adminValues.contactExternalId;
        });

        others.forEach((c) => {
            contactsOperations.push({
                SoldoContactExternalID__c: c.SoldoContactExternalID__c,
                ContactIsAdmin__c: false,
                IsDeleted: !this.personFormHelper.hasSomeRole(c)
            });
        });

        this.registrationDataService.saveContacts(contactsOperations);
    }

    onSubmit() {
        if (this.form.value.adminChoice === ADMIN_CHOICE.NO_CHOICE) {
            this.form.controls.adminChoice.markAsTouched();
            scrollToFirstResult(SELECT_INVALID_SOLDO_FIELD);
            return;
        }

        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.loading = true;
            this.saveForm();

            const postSubmitFsSubmissionStatus = this.statusService.getFsSubmissionStatusValidIncrement("AdminCreated");

            this.registrationDataService.saveAccount({
                FsApplicationSubmissionStatus__c: postSubmitFsSubmissionStatus
            });

            this.registrationDataService.submitContacts().then(() => {
                this.registrationDataService.submitAccount().then(() => {
                    this.statusService.setStatusIncrementAfterSubmission(postSubmitFsSubmissionStatus);
                    this.amplitudeService.trackApplicantIsLegalRepresentativeChoice(this.applicantContact.ContactIsDirector__c);
                    this.stepService.navigateToNextStep();
                });
            }, (err) => this.logger.trace("FATAL: ", err));
        } else {
            this.loading = false;
            this.logger.warn("form not valid: ", this.form.value, this.form.errors);
            scrollToFirstResult(SELECT_INVALID_SOLDO_FIELD, 'fiscal-code:has(input.ng-invalid)');
        }
    }

    goToPrevious() {
        this.stepService.navigateToPreviousStep();
    }

    public get ADMIN_CHOICE() {
        return ADMIN_CHOICE;
    }
}
