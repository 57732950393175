import { Component, OnInit, Type, ViewChild } from '@angular/core';
import { SubstepContainerDirective } from '@shared/components/substep-container.directive';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { Account } from '@shared/models/registration.model';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { StatusService } from '@shared/services/status.service';
import { NavigationDirection, StepService } from '@shared/services/steps.service';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ExpectedActivitySubStepOutboundAction, ExpectedActivitySubStepType } from './expected-activity-step.model';
import { CashWithdrawalComponent } from './substeps/cash-withdrawal/cash-withdrawal.component';
import { ExternalDepositComponent } from './substeps/external-deposit/external-deposit.component';
import { MonthlySpendComponent } from './substeps/monthly-spend/monthly-spend.component';
import { NumberOfCardsComponent } from './substeps/number-of-cards/number-of-cards.component';
import { UseCaseComponent } from './substeps/use-case/use-case.component';

@Component({
    selector: 'app-expected-activity-step',
    templateUrl: './expected-activity-step.component.html',
    styleUrls: ['./expected-activity-step.component.scss']
})
export class ExpectedActivityStepComponent implements OnInit {
    @ViewChild(SubstepContainerDirective)
    subStepContainer: SubstepContainerDirective;
    activeSubStepInstance: ExpectedActivitySubStepType;
    activeSubStepActionSubscription: Subscription;
    activeSubStep: number = 0;

    // true disables controls
    loading: boolean = false;

    subStepComponentTypes: Array<Type<ExpectedActivitySubStepType>> = [
        UseCaseComponent,
        NumberOfCardsComponent,
        MonthlySpendComponent,
        CashWithdrawalComponent,
        ExternalDepositComponent
    ];

    account: Account = null;
    isSoleTrader: boolean = false;

    absoluteProgressBySubStep: Map<Type<ExpectedActivitySubStepType>, number> = new Map();

    constructor(
        private stepService: StepService,
        private logger: NGXLogger,
        private statusService: StatusService,
        private registrationDataService: RegistrationDataService
    ) { }

    ngOnInit(): void {
        this.account = this.retrieveAccountData();
        this.isSoleTrader = this.account.SoleTrader__c;

        const currentStatus = this.statusService.getFsSubmissionStatus();
        if (currentStatus.key != "Details" && this.stepService.getLastNavigationDirection() == NavigationDirection.BACKWARD) {
            this.activeSubStep = this.subStepComponentTypes.length - 1;
        }

        this.subStepComponentTypes.forEach((subStepType, index) => {
            this.absoluteProgressBySubStep.set(subStepType, index);
        });
    }

    onSubstepRendered(renderedSubstepInstance: ExpectedActivitySubStepType) {
        this.activeSubStepInstance = renderedSubstepInstance;
        this.activeSubStepInstance.init(this.account);
        this.activeSubStepInstance.trackSubStepViewed();

        this.activeSubStepActionSubscription = this.activeSubStepInstance.controlActionEventEmitter.subscribe((action) => {
            this.substepOutboundActionHandler(action);
        });

        const subStepProgress = this.absoluteProgressBySubStep.get(this.subStepComponentTypes[this.activeSubStep]);
        this.stepService.updateStepProgress(subStepProgress);
    }

    substepOutboundActionHandler(a: ExpectedActivitySubStepOutboundAction) {
        switch (a.action) {
            case DefaultOutboundActions.NEXT:
                this.nextSubStep(a.data);
                break;
            case DefaultOutboundActions.PREVIOUS:
                this.previousSubStep();
                break;
        }
    }

    private previousSubStep() {
        this.activeSubStepInstance.loading = true;
        this.activeSubStepActionSubscription.unsubscribe();

        if (this.activeSubStep > 0) {
            this.activeSubStep--;
            this.scrollTop(() => {
                this.activeSubStepInstance.loading = false;
            });
        } else {
            this.stepService.navigateToPreviousStep();
        }
    }

    private nextSubStep(d: Partial<Account>) {
        this.logger.debug("Advancing to next substep");

        this.activeSubStepInstance.loading = true;

        Object.assign(this.account, d);

        this.logger.debug("Form is valid. Current data: ", this.account);

        this.activeSubStepActionSubscription.unsubscribe();

        if (this.activeSubStep == this.subStepComponentTypes.length - 1) {
            this.activeSubStepInstance.loading = true;
            this.submitAccountData();
        } else {
            this.activeSubStepInstance.trackSubStepCompleted();
            this.activeSubStep++;
            this.scrollTop(() => {
                // this.activeSubStepInstance.loading = false;
            });

        }
    }

    private submitAccountData(): void {

        this.activeSubStepInstance.loading = true;

        const updatedFsSubmissionStatus = this.statusService.getFsSubmissionStatusValidIncrement("AccountCreated");
        this.account.FsApplicationSubmissionStatus__c = updatedFsSubmissionStatus;

        this.registrationDataService.saveAccount(this.account);

        this.registrationDataService.submitAccount().then(() => {
            this.statusService.setStatusIncrementAfterSubmission(updatedFsSubmissionStatus);
            this.stepService.navigateToNextStep();
        }, (err) => this.logger.trace("FATAL: ", err));

    }

    private retrieveAccountData(): Account {
        return this.registrationDataService.getAccount();
    }

    private scrollTop(callback: () => void) {
        setTimeout(() => {
            window.scrollTo({ top: 0 });
            callback();
        }, 0);
    }

}
