import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'character-counter',
    templateUrl: './character-counter.component.html',
    styleUrl: './character-counter.component.scss'
})
export class CharacterCounterComponent {
    @Input() maxlength: number;
    @Input() control: AbstractControl;
}
