import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { ExpectedActivityStepComponent } from './expected-activity-step.component';
import { UseCaseComponent } from './substeps/use-case/use-case.component';
import { NumberOfCardsComponent } from './substeps/number-of-cards/number-of-cards.component';
import { MonthlySpendComponent } from './substeps/monthly-spend/monthly-spend.component';
import { CashWithdrawalComponent } from './substeps/cash-withdrawal/cash-withdrawal.component';
import { ExternalDepositComponent } from './substeps/external-deposit/external-deposit.component';

@NgModule({
    declarations: [
        ExpectedActivityStepComponent,
        UseCaseComponent,
        NumberOfCardsComponent,
        MonthlySpendComponent,
        CashWithdrawalComponent,
        ExternalDepositComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        TranslateModule,
        ReactiveFormsModule,
        NgSelectModule
    ]
})
export class ExpectedActivityStepModule { }
