import { Component, OnInit, Input, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AddressFormControlMetadata } from '../address2.component';
import { AbstractAddressFieldsComponent } from '../abstract-address-fields.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UkAddress } from '../../../services/uk-address-lookup.service';
import { AmplitudeService } from '../../../services/amplitude.service';
import { regexValidators } from '../../../customValidators';

@Component({
    selector: 'address-fields-gbr',
    templateUrl: './address-fields-gbr.component.html',
    styleUrls: ['./address-fields-gbr.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddressFieldsGbrComponent extends AbstractAddressFieldsComponent implements OnInit {

    @Input("detectManualEdits")
    public detectManualEdits: boolean = true;

    @Input("manualChangeEmitter")
    public manualChangeEmitter: EventEmitter<string>;

    @Input('formGroup')
    public addressForm: UntypedFormGroup;

    @Input('simplified')
    public simplified: boolean = false;

    @Input('fieldMetadata')
    public fieldMetadata: AddressFormControlMetadata;

    @Input('searchResultEmitter')
    public searchResultEmitter: EventEmitter<UkAddress>;

    @Input()
    readonly = false;

    private localSubscriptions: Subscription[] = [];

    constructor(
        protected amplitudeService: AmplitudeService,
        protected router: Router) {
        super(amplitudeService, router);
    }

    ngOnInit() {

        this.localSubscriptions.push(this.searchResultEmitter.subscribe((event: UkAddress) => {
            this.addressForm.patchValue({
                idCheckAddressLookupAddressCode: event.idCheckAddressLookupAddressCode,
                line1: event.line1,
                line2: event.line2,
                line3: event.line3,
                city: event.city,
                buildingName: event.buildingName,
                buildingNumber: event.buildingNumber,
                poBoxNumber: event.poBoxNumber,
                street: event.street,
                secondaryStreet: event.secondaryStreet,
                subBuilding: event.subBuilding,
                postcode: event.postalCode
            }, { emitEvent: false });

            this.trackSearchResultSelected();
        }));

        setTimeout(() => {
            if (this.simplified) {
                this.localSubscriptions.push(this.configField("line1", "RegisteredAddressLine1__c", true, true, true, []));
                this.localSubscriptions.push(this.configField("line2", "RegisteredAddressLine2__c", false, true, true, []));
            } else {
                this.localSubscriptions.push(this.configField("street", "RegisteredAddressStreet__c", true, true, true, []));
                this.localSubscriptions.push(this.configField("buildingName", "RegisteredAddressHouseName__c", false, true, true, []));
                this.localSubscriptions.push(this.configField("buildingNumber", "RegisteredAddressHouseNumber__c", false, true, true, []));
                this.localSubscriptions.push(this.configField("subBuilding", "RegisteredAddressSubBuilding__c", false, true, true, []));
                this.addressForm.setValidators([this.nameOrNumberValidator]);
            }
            this.localSubscriptions.push(this.configField("city", "RegisteredAddressCity__c", true, true, true, []));
            this.localSubscriptions.push(this.configField("postcode", "RegisteredAddressPostalCode__c", true, true, true, [
                Validators.pattern(regexValidators.postcodesByCountry.GBR)
            ]));
        }, 0);
    }

    ngOnDestroy() {
        this.addressForm.setValidators([]);
        for (const s of this.localSubscriptions) {
            s.unsubscribe();
        }
    }

    private nameOrNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const addressFormGroup = control as UntypedFormGroup;
        if (addressFormGroup.get("buildingName").value || addressFormGroup.get("buildingNumber").value) {
            return null;
        }
        return {
            "nameornumber": true
        };
    };

    invalidateSearchResult(fieldName: string) {
        const searchResultIdControl = this.addressForm.get("idCheckAddressLookupAddressCode");
        if (searchResultIdControl.value) {
            this.addressForm.patchValue({
                idCheckAddressLookupAddressCode: "",
                line1: "",
                line2: "",
                line3: "",
                poBoxNumber: "",
                secondaryStreet: ""
            }, { emitEvent: false });
            this.trackSeachInvalidated(fieldName);
        }
    }

}
