import { Component, ElementRef, OnInit, Type, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CountryService, ICountryMetadata } from '@shared/services/country.service';
import { AbstractFormComponent } from '../../abstract-form.component';
import { ExpectedActivitySubStepType } from '@src/app/registration/expected-activity-step/expected-activity-step.model';
import { UseCaseComponent } from '@src/app/registration/expected-activity-step/substeps/use-case/use-case.component';
import { CashWithdrawalComponent } from '@src/app/registration/expected-activity-step/substeps/cash-withdrawal/cash-withdrawal.component';
import { ExternalDepositComponent } from '@src/app/registration/expected-activity-step/substeps/external-deposit/external-deposit.component';
import { MonthlySpendComponent } from '@src/app/registration/expected-activity-step/substeps/monthly-spend/monthly-spend.component';
import { NumberOfCardsComponent } from '@src/app/registration/expected-activity-step/substeps/number-of-cards/number-of-cards.component';
import { StepService } from '@shared/services/steps.service';

enum UseCase {
    pettyCash = 'Petty Cash',
    travel = 'Travel and entertainment',
    team = 'Team Spending',
    company = 'Company Expenses',
    advertising = 'Online Advertising',
    other = 'Other'
}

interface UseCaseData {
    value: UseCase;
    active: boolean;
    showDescription: boolean;
    label: string;
}

@Component({
    selector: 'textfield-test',
    templateUrl: './textfield-test.component.html',
    styleUrls: ['./textfield-test.component.scss']
})


export class TextfieldTestComponent extends AbstractFormComponent implements OnInit{

    loading: boolean = true;
    form: UntypedFormGroup;
    searchMode: boolean = false;
    visibleCountries: ICountryMetadata[];

    defaultMonth: string;

    readonly = false;
    buttonSearchDisable = false;

    @ViewChild('mobileNumberTextField')
    mobileNumberTextField: ElementRef<HTMLInputElement>;

    disableInputLabel = "Disable";
    setErrorInputLabel = "SET";
    completeCountryList: ICountryMetadata[];
    market: string;
    selectedCountry: ICountryMetadata;
    showSelectedSeparately: boolean;

    numberOfCardsOptions = [
        { label: '1-9', value: 5 },
        { label: '10-49', value: 30 },
        { label: '50-99', value: 75 },
        { label: '100-249', value: 175 },
        { label: '250+', value: 251 }
    ];

    useCases: UseCaseData[] = [
        { value: UseCase.pettyCash, active: false, showDescription: false, label: 'PETTY_CASH' },
        { value: UseCase.travel, active: false, showDescription: false, label: 'TRAVEL' },
        { value: UseCase.team, active: false, showDescription: false, label: 'TEAM' },
        { value: UseCase.company, active: false, showDescription: false, label: 'COMPANY' },
        { value: UseCase.advertising, active: false, showDescription: false, label: 'ADVERTISING' },
        { value: UseCase.other, active: false, showDescription: true, label: 'OTHER' }
    ];
    subStepComponentTypes: Array<Type<ExpectedActivitySubStepType>> = [
        UseCaseComponent,
        NumberOfCardsComponent,
        MonthlySpendComponent,
        CashWithdrawalComponent,
        ExternalDepositComponent
    ];

    constructor(
        private stepService: StepService,
        private fb: UntypedFormBuilder,
        private countryService: CountryService,
        private translateService: TranslateService){
        super();


    }
    private toggleDescription(showDescription: boolean, description: string = null) {
        if (showDescription && !this.form.controls['description']) {
            this.form.addControl('description', this.fb.control(description, Validators.required));
        } else if (!showDescription && this.form.controls['description']) {
            this.form.removeControl('description');
        }
    }

    ngOnInit(): void {

        this.form = this.fb.group({
            test: ["", [
                Validators.required]],
            textarea: ["", Validators.required],
            companySearch: ["", Validators.maxLength],
            phone: this.fb.group({
                prefix: ["+39", [
                    Validators.required]],
                number: ["", [
                    Validators.required]]
            }),
            citizenship: ["",Validators.required],
            isFatcaSubject: ["",Validators.required],
            country: ["ITA", Validators.required],
            chip: ["",Validators.required],
            numberOfCards: ["",Validators.required],
            searchTerm: ["", Validators.maxLength],
            retailChannel: [false,Validators.required],
            onlineChannel: [false,Validators.required],
            otherChannelSelected: [false,Validators.required]
        });
        for (const useCase of this.useCases) {
            useCase.active = false;
            this.form.addControl(useCase.label, this.fb.control(useCase.active));
        }
        this.toggleDescription(this.useCases.find(useCase => useCase.showDescription).active, "Other");

        const phoneField = this.form.get("phone");
        if (phoneField) {
            const phoneNumberField = phoneField.get('number');
            const phonePrefixField = phoneField.get('prefix');
            phoneNumberField.valueChanges.subscribe((value: string) => {

                switch (phonePrefixField.value) {
                    case "+44":
                    case "+33":
                        if (value && value.charAt(0) == "0") {
                            const cursorPosition = this.mobileNumberTextField.nativeElement.selectionStart;
                            phoneNumberField.setValue(value.substring(1), {
                                emitEvent: false
                            });
                            this.mobileNumberTextField.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
                        }
                        break;
                }
            });
        }

        this.countryService.countries.subscribe(allCountries => {
            const popularCountriesList: ICountryMetadata[] = allCountries.filter(country => {
                return this.countryService.getMainMarkets().includes(country.alpha_3);
            }).sort(this.countryService.sortFunction);
            this.completeCountryList = allCountries;
            this.visibleCountries = popularCountriesList.sort(this.countryService.sortFunction);
        });



        this.loading = false;
    }
    disableInput(): void {
        if (this.disableInputLabel == "Disable"){
            this.form.disable();
            this.buttonSearchDisable = true;
            this.disableInputLabel = "Enable";
        } else {
            this.form.enable();
            this.buttonSearchDisable = false;
            this.disableInputLabel = "Disable";
        }

    }
    setReadonlyInput(): void {

        this.readonly = !this.readonly;

    }

    setErrorsInput(): void {
        if (!this.readonly){
            if (this.setErrorInputLabel == "SET"){
                this.form.markAllAsTouched();
                this.setErrorInputLabel = "CLEAR";
            } else {
                this.form.reset(this.form.value);
                this.setErrorInputLabel = "SET";
            }
        }
    }

    getPhoneValue(): string {

        return "+39 34634821";
    }


    onKeyDown(event: any) {
        return (this.isDigitKeyCode(event.key) && event.target.value.length < 3) || this.isManipulationKeyCode(event.keyCode);
    }


    updateCheck(useCase: UseCaseData, checked: boolean) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        for (const useCase of this.useCases) {
            this.form.get(useCase.label)?.markAsTouched();
        }
        // this.form.markAllAsTouched();
        if (useCase.showDescription) {
            this.toggleDescription(checked);
        }
    }

    atLeastOneSelected(): boolean {

        // return Object.keys(this.form.controls).some(key => {
        //     if (key === 'description') {
        //         return false;
        //     }
        //     return this.form.get(key).value;
        // });


        console.log("atLeastOneSelected");
        let atLeastOne = false;
        for (const useCase of this.useCases) {
            if ( this.form.get(useCase.label).value){
                console.log(" this.form.get(useCase.label).value ",this.form.get(useCase.label));
                atLeastOne = true;
            }
        }
        console.log("atLeastOne :",atLeastOne);
        return atLeastOne;
    }

    private isDigitKeyCode(key: string) {
        return (key >= "0" && key <= "9");
    }

    private isManipulationKeyCode(keyCode: number) {
        switch (keyCode) {
            case 8: // Backspace
            case 9: // Tab
            case 11: // Enter
            case 16: // shift
            case 17: // Alt
            case 18: // ctrl
            case 35: // Home
            case 36: // End
            case 37: // arrows...
            case 38:
            case 39:
            case 40:
            case 46: // del
            case 91: // cmd
                return true;
            default:
                return false;
        }
    }

    onRadioChoiceLabel(event: any, radioChoice:boolean) {
        if (event.code === "Enter") {
            this.form.get('isFatcaSubject').setValue(radioChoice);
        }
    }

    getCitizenshipLabel(): string {
        return this.completeCountryList?.find(c => c.alpha_3 === this.form.get('citizenship').value)?.name;
    }

    getIcc(): ICountryMetadata {
        return this.completeCountryList?.find(c => c.icc === this.form.get('phone').value.prefix);
    }

    onBlur(): void {
        // eslint-disable-next-line no-console

    }

    currentLanguage(): string {
        return this.translateService.currentLang;
    }

}
