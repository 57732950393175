import { InjectionToken, NgModule } from '@angular/core';
import { environment } from '@src/environments/environment';
import { NgxLoggerLevel } from 'ngx-logger';

export interface LanguageBasedConfig<T> {
    fallback: T;
    en?: T;
    it?: T;
    nl?: T;
    fr?: T;
}

export interface CountryBasedConfig<T> {
    fallback: T;
    GBR?: T;
    ITA?: T;
    FRA?: T;
    NLD?: T;
}
export class ApiCallsMockConfig {
    GLOBAL: boolean;
    forceMitekAsIdvProvider: boolean;
    forceApplicantOffIdv: boolean;
}

export class AppConfig {
    env: string;
    logLevel: NgxLoggerLevel;
    localUrlEndpoint: string;
    aggregatorUrlEndpoint: string;
    googleTagManagerID: string;
    enableDynatraceTracking: boolean;
    amplitudeProjectId: string;
    intercomTestMode: boolean;
    intercomAppId: string;
    invalidPlanOfferRedirectUrl: CountryBasedConfig<string>;
    termsAndConditionsLink: CountryBasedConfig<string>;
    privacyPolicyLink: LanguageBasedConfig<string>;
    embedHooYuVerificationSession: boolean;
    registrationProcessHelpURLs: CountryBasedConfig<string>;
    postRegistrationInfoURLs: LanguageBasedConfig<string>;
    websiteHomePage: LanguageBasedConfig<string>;
    signupWithXeroLoginURLRoot: string;
    signupWithXeroLoginRedirectURI: string;
    signupWithXeroLoginAuthorizationScopes: string;
    signupWithXeroLeadSource: string;
    regulatoryInstitutionShorthand: {
        "SFS-UK": string;
        "SFS-IRL": string
    };

    apiCallsMockConfig: ApiCallsMockConfig;
}

export const APP_CONFIG_VALUE: AppConfig = {
    env: environment.env,
    logLevel: environment.logLevel,
    localUrlEndpoint: environment.localUrlEndpoint,
    aggregatorUrlEndpoint: environment.aggregatorUrlEndpoint,
    googleTagManagerID: environment.googleTagManagerID,
    enableDynatraceTracking: environment.enableDynatraceTracking,
    amplitudeProjectId: environment.amplitudeProjectId,
    intercomAppId: environment.intercomAppId,
    intercomTestMode: environment.intercomTestMode,
    invalidPlanOfferRedirectUrl: environment.invalidPlanOfferRedirectUrl,
    termsAndConditionsLink: {
        ITA: 'https://www.soldo.com/it-it/condizioni-generali/',
        GBR: 'https://www.soldo.com/en-gb/terms-conditions/',
        fallback: 'https://www.soldo.com/en-eu/terms-conditions/'
    },
    privacyPolicyLink: {
        it: 'https://www.soldo.com/it-it/informative-sulla-privacy/',
        en: 'https://www.soldo.com/en-gb/privacy-policies/',
        fallback: 'https://www.soldo.com/en-eu/privacy-policies/'
    },
    registrationProcessHelpURLs: {
        fallback: 'https://support.soldo.com/hc/en-gb/articles/24409698732306-Completing-the-online-registration-form',
        ITA: 'https://support.soldo.com/hc/it/articles/24409698732306-Completare-il-modulo-di-registrazione',
        FRA: 'https://support.soldo.com/hc/fr/articles/24409698732306-Remplir-le-formulaire-d-inscription-en-ligne',
        NLD: 'https://support.soldo.com/hc/nl-nl/articles/24409698732306-Het-online-registratieformulier-invullen'
    },
    postRegistrationInfoURLs: {
        fallback: 'https://www.soldo.com/en-gb/progressivefinance/',
        it: 'https://www.soldo.com/it-it/finanzaprogressiva/'
    },
    embedHooYuVerificationSession: environment.embedHooYuVerificationSession,
    websiteHomePage: environment.websiteHomePage,
    signupWithXeroLoginURLRoot: environment.signupWithXeroLoginURLRoot,
    signupWithXeroLoginRedirectURI: environment.signupWithXeroLoginRedirectURI,
    signupWithXeroLoginAuthorizationScopes: environment.signupWithXeroLoginAuthorizationScopes,
    signupWithXeroLeadSource: environment.signupWithXeroLeadSource,
    regulatoryInstitutionShorthand: {
        "SFS-UK": "FCA",
        "SFS-IRL": "CBI"
    },
    apiCallsMockConfig: environment.apiCallsMockConfig
};

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');

@NgModule({
    providers: [{
        provide: APP_CONFIG,
        useValue: APP_CONFIG_VALUE
    }]
})
export class AppConfigModule { }
