
<typeface-icon class="help-ico">help</typeface-icon>
<div class="soldo-tooltip-dropdown {{verticalPosition}} {{horizontalPosition}}">
  <div class="soldo-tooltip-content">
    {{ text | translate }}
  </div>
</div>
<div class="soldo-tooltip-mobile">
  {{ text | translate }}
</div>
