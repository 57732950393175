<div class="uk-address-lookup-container" [formGroup]="addressForm" data-cy="Address_UkAddressSearch_SearchAddress">
  <input-label forControl="searchControl" [required]="isRequired">
    {{ 'COMPONENTS.UK_ADDRESS_SEARCH.LABEL' | translate }}
  </input-label>
  <div class="search-field input-group">
    <input (input)="searchControl.setValue($event.target.value.toUpperCase())" class="form-control" type="search"
      [formControl]="searchControl" name="searchControl"
      placeholder="{{ 'COMPONENTS.UK_ADDRESS_SEARCH.PLACEHOLDER' | translate }}" data-cy="Address_UkAddressSearch_SearchInput"/>
    <button class="btn md-text" (click)="onSearchButtonClicked();" type="button"
      value="{{ 'COMPONENTS.UK_ADDRESS_SEARCH.SEARCH_BUTTON' | translate }}" data-cy="Address_UkAddressSearch_SearchButton">
      {{ 'COMPONENTS.UK_ADDRESS_SEARCH.SEARCH_BUTTON' | translate }}
    </button>
  </div>
  <div *ngIf=" searchControl.touched && searchControl.errors?.pattern" class="text-danger">
    {{ 'WRONG_FORMAT' | translate }}
  </div>
  <div class=" uk-address-lookup-results" *ngIf="showDropdown" (clickOutside)="showDropdown = false">
    <ul>
      <li class="single-line result" *ngFor="let address of addressEntries; let i = index;" (click)="selectAddress(address);" data-cy="Address_UkAddressSearch_SearchResult">
        {{ address.text }}
      </li>
      <li class="single-line no-result" *ngIf="addressEntries.length == 0" (click)="showDropdown = false" data-cy="Address_UkAddressSearch_NoResult">
        {{ "COMPONENTS.UK_ADDRESS_SEARCH.NO_RESULT_FOUND" | translate : {postcode: searchControl.value} }}
      </li>
    </ul>
  </div>
</div>
