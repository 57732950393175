<header>
  <h3 class="title">{{ "REGISTRATION.DIRECTOR.TITLE" + (soleTrader ? "_SOLETRADER" : "") | translate }}</h3>
  <div class="important-notice">
      <img src="../assets/images/regulated-icon.svg">
      <div class="description">{{ "REGISTRATION.DIRECTOR.DESCRIPTION" + (soleTrader ? "_SOLETRADER" : "") | translate: {regulatoryInstitution: regulatoryInstitution} }}</div>
  </div>
</header>

<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">


  <div class="info-radio" *ngIf="!soleTrader" data-cy="Director_RadioButton_IsLeadDirector">
    <h5>{{ 'REGISTRATION.DIRECTOR.IS_DIRECTOR' | translate }}</h5>
    <p>{{ "REGISTRATION.DIRECTOR.LEGAL_REPRESENTATIVE_DEFINITION"  | translate }}</p>
    <div class="radio-choice" data-cy="Director_RadioButton_IsLeadDirector_TRUE">
      <input id="YES-lead" type="radio"  formControlName="isPrimaryLead" (change)="setPrimaryLeadAsDirector()" [value]="true">
      <label for="YES-lead">{{ "REGISTRATION.DIRECTOR.USER_IS_LEGAL_REP" | translate }}</label>
    </div>
    <div class="radio-choice" data-cy="Director_RadioButton_IsLeadDirector_FALSE">
      <input id="NO-lead" type="radio"  formControlName="isPrimaryLead" (change)="setNewDirector()" [value]="false">
      <label for="NO-lead">{{ "REGISTRATION.DIRECTOR.USER_IS_NOT_LEGAL_REP" | translate }}</label>
    </div>
  </div>

  <ng-container *ngIf="primaryLeadIsDirector !== null">
    <h5 class="my-4" [style.visibility]="soleTrader ? 'hidden' : 'visible'">{{ "REGISTRATION.DIRECTOR.SUBTITLE" | translate }}</h5>
    <person-form-group [group]="form.get('director')" formGroupName="director"></person-form-group>
  </ng-container>

  <div class="row mt-5">
      <div class="col-6">
          <button type="button" class="btn gray-text go-back" (click)="goToPrevious()"
          data-cy="Director_Button_StepBackButton"><i class="md-icon">arrow_right_alt</i>
          <span>{{ 'PREVIOUS' | translate }}</span>
        </button>
      </div>
      <div class="col-6 text-right">
          <button type="submit" class="btn btn-primary" [disabled]="loading || primaryLeadIsDirector === null" [ngClass]="{ 'in-progress': loading}"
          data-cy="Director_Button_StepSubmitButton">{{ 'NEXT' | translate }}</button>
      </div>
  </div>
</form>

<save-and-continue-later stepName="Director Details"></save-and-continue-later>
