import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { StepService } from '../../shared/services/steps.service';
import { ModalDialogService } from '../../shared/services/modal-dialog.service';
import { UboHelpComponent } from '../../shared/dialog-contents/ubo-help/ubo-help.component';
import { RegistrationDataService } from '../../shared/services/registrationData.service';
import { UBOProvisioningChoiceType } from '../../shared/models/registration.model';
import { AmplitudeService } from '../../shared/services/amplitude.service';

@Component({
    selector: 'app-beneficial-owner-choice',
    templateUrl: './beneficial-owner-choice.component.html',
    styleUrls: ['./beneficial-owner-choice.component.scss']
})
export class BeneficialOwnerChoiceComponent implements OnInit {
    form: FormGroup<{ uboMode: FormControl<UBOProvisioningChoiceType> }>;
    loading: boolean = false;
    countryOfIncorporation: string;

    constructor(
        protected logger: NGXLogger,
        private stepService: StepService,
        private fb: UntypedFormBuilder,
        private modalDialogService: ModalDialogService,
        private registrationDataService: RegistrationDataService,
        private amplitudeService: AmplitudeService) { }

    ngOnInit(): void {
        const account = this.registrationDataService.getAccount();
        this.countryOfIncorporation = account.RegisteredAddressCountryISO__c;
        this.form = this.fb.group({
            "uboMode": new FormControl<UBOProvisioningChoiceType>(account.UBOProvisioningChoice__c, [Validators.required])
        });
    }

    openUBOHelpDialog(): void {
        this.modalDialogService.showModalDialog(UboHelpComponent);
    }

    onSubmit(): boolean {

        this.form.controls.uboMode.markAsTouched();

        if(this.form.valid) {
            this.loading = true;

            const chosenInputMode = this.form.value.uboMode;

            this.registrationDataService.saveAccount({
                UBOProvisioningChoice__c: chosenInputMode
            });

            this.registrationDataService.submitAccount().then(() => {
                this.amplitudeService.trackBeneficialOwnerInputModeChoice(chosenInputMode);
                this.stepService.navigateToNextStep();
            }, (err) => this.logger.trace("FATAL: ", err));
        }

        return true;
    }

    goToPrevious() {
        this.stepService.navigateToPreviousStep();
    }

}
