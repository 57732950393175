<header>
  <div class="header-content">
    <img src="assets/images/soldo-logo-v2.png" alt="Soldo logo">

    <form *ngIf="languageSelectorEnabled" [formGroup]="form">
      <ng-select
        data-cy="Header_LanguageSelector_LanguageMenu"
        [items]="availableLanguages" dropdownPosition="bottom"
        [clearable]="false" [searchable]="false"
        formControlName="language">
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="flag-icon" style="background-image: url(/assets/round-flag-icons/{{item.flag}}.svg)"></div>
          <span>{{ item.label }}</span>
          <typeface-icon *ngIf="item.label === this.i18nService.lang.toUpperCase()">checkmark</typeface-icon>
        </ng-template>
      </ng-select>
    </form>
  </div>
</header>
