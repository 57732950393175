import { Component, Inject, OnInit } from '@angular/core';
import { IntercomIntegrationService } from '../../shared/services/intercomIntegration.service';
import { I18nService } from '../../shared/services/i18n.service';
import { RegistrationDataService } from '../../shared/services/registrationData.service';
import { Contact } from '../../shared/models/registration.model';
import { APP_CONFIG, AppConfig } from '@src/app/app-config.module';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'identity-verified',
    templateUrl: './identity-verified.component.html',
    styleUrl: './identity-verified.component.scss'
})
export class IdentityVerifiedComponent implements OnInit {

    applicant: Contact = null;
    postRegistrationInfoUrl: string = null;
    constructor(
        @Inject(APP_CONFIG) private readonly config: AppConfig,
        private readonly registrationDataService: RegistrationDataService,
        private readonly intercomIntegrationService: IntercomIntegrationService,
        private readonly i18nService: I18nService,
        private readonly translateService: TranslateService) { }

    ngOnInit() {

        this.translateService.onLangChange.subscribe(e => {
            this.handleLanguageChange(e.lang);
        });
        this.handleLanguageChange(this.translateService.currentLang);

        this.intercomIntegrationService.init();
        this.intercomIntegrationService.onNavigationEnd("Identity Verified");
        this.i18nService.turnOnLanguageSelection();

        const applicant = this.registrationDataService.getContacts().find(c => c.ContactIsPrimaryLead__c);
        const complianceStatus = this.registrationDataService.getComplianceContext();
        if (complianceStatus?.identityVerificationMetadataList?.some(idvc => idvc.email === applicant?.Email)) {
            this.applicant = applicant;
        }
    }

    handleLanguageChange(lang) {
        this.postRegistrationInfoUrl = this.config.postRegistrationInfoURLs[lang] || this.config.postRegistrationInfoURLs.fallback;
    }

    clickOnCTA() {
        window.location.assign(this.postRegistrationInfoUrl);
    }
}
