import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'collapsible-container',
    templateUrl: './collapsible-container.component.html',
    styleUrls: ['./collapsible-container.component.scss']
})
export class CollapsibleContainerComponent implements OnInit {

    @Input("collapsed")
    collapsed: boolean = true;

    constructor() { }

    ngOnInit() {
    }

    toggle():void {
        this.collapsed = !this.collapsed;
    }

}
