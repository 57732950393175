import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, AbstractControl, Validators, ValidatorFn } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { Lead, LEAD_DEFAULT_FIELD_LENGTH, ACCOUNT_DEFAULT_FIELD_LENGTH, Contact, CONTACT_DEFAULT_FIELD_LENGTH, Account } from "../models/registration.model";

export abstract class AbstractFormComponent {

    protected logger: NGXLogger;
    form: UntypedFormGroup;

    leadMaxlengthValidator(field: keyof Lead): ValidatorFn {
        return Validators.maxLength(LEAD_DEFAULT_FIELD_LENGTH[field] || 255);
    }

    accountMaxlengthValidator(field: keyof Account): ValidatorFn {
        return Validators.maxLength(ACCOUNT_DEFAULT_FIELD_LENGTH[field] || 255);
    }

    contactMaxlengthValidator(field: keyof Contact): ValidatorFn {
        return Validators.maxLength(CONTACT_DEFAULT_FIELD_LENGTH[field] || 255);
    }

    printAllErrors(name?: string, control?: AbstractControl) {
        if (!control) {
            this.printAllErrors("global", this.form);
        } else if (control instanceof UntypedFormControl) {
            if (control.errors) {
                this.logger.warn(name, control.value, control.errors);
            }
        } else if (control instanceof UntypedFormArray) {
            control.controls.forEach((subControl, index) => {
                this.printAllErrors(`${name}[${index}]`, subControl);
            });
        } else if (control instanceof UntypedFormGroup) {
            for (const controlName in control.controls) {
                this.printAllErrors(`${name} - ${controlName}`, control.controls[controlName]);
            }
        }
    }

    isErrorVisible(formControl: AbstractControl): boolean {
        return formControl.touched;
    }
}
