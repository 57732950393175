import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { NgSelectModule } from '@ng-select/ng-select';

import { registrationRouting } from './registration.routes';

import { AccountComponent } from './account/account.component';
import { DirectorComponent } from './director/director.component';
import { RegistrationComponent } from './registration.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';

/* Shared Components */
import { PersonComponent } from './person/person.component';

import { ClickOutsideDirective } from '@shared/click-outside/click-outside.directive';
import { FiscalCodeService } from '@shared/services/fiscal-code.service';
import { IndustriesService } from "@shared/services/industries.service";
import { SharedModule } from "@shared/shared.module";
import { CompanyStepModule } from './company-step/company-step.module';
import { ExpectedActivityStepModule } from './expected-activity-step/expected-activity-step.module';
import { NavigationComponent } from './navigation/navigation.component';
import { SignupStepModule } from './signup-step/signup-step.module';
import { BeneficialOwnersComponent } from './beneficial-owners/beneficial-owners.component';
import { BeneficialOwnerChoiceComponent } from './beneficial-owner-choice/beneficial-owner-choice.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { BeneficialOwnerFormComponent } from './beneficial-owners/beneficial-owner-form/beneficial-owner-form.component';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        NgSelectModule,
        SharedModule,
        SignupStepModule,
        CompanyStepModule,
        ExpectedActivityStepModule,
        registrationRouting,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    declarations: [
        RegistrationComponent,
        NavigationComponent,
        DirectorComponent,
        AccountComponent,
        TermsConditionComponent,
        PersonComponent,
        ClickOutsideDirective,
        BeneficialOwnersComponent,
        BeneficialOwnerChoiceComponent,
        BeneficialOwnerFormComponent
    ],
    providers: [
        IndustriesService,
        FiscalCodeService,
        provideNgxMask()
    ]
})
export class RegistrationModule { }
