import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AddressFormControlMetadata } from '../address2.component';
import { AbstractAddressFieldsComponent } from '../abstract-address-fields.component';
import { Router } from '@angular/router';
import { HereApiAddressSearchResult } from '../../../services/here-apiaddress-lookup.service';
import { AmplitudeService } from '../../../services/amplitude.service';
import { regexValidators } from '../../../customValidators';
import { NGXLogger } from 'ngx-logger';

export interface IIrishCounty {
    principal: string;
}

@Component({
    selector: 'address-fields-irl',
    templateUrl: './address-fields-irl.component.html',
    styleUrls: ['./address-fields-irl.component.scss']
})
export class AddressFieldsIrlComponent extends AbstractAddressFieldsComponent implements OnInit, OnDestroy {

    @Input('formGroup')
    public addressForm: UntypedFormGroup;

    @Input('simplified')
    public simplified: boolean = false;

    @Input('fieldMetadata')
    public fieldMetadata: AddressFormControlMetadata;

    @Input('searchResultEmitter')
    public searchResultEmitter: EventEmitter<HereApiAddressSearchResult>;

    @Input()
    readonly = false;

    irishCountiesTypeahead = new EventEmitter<string>();

    public irishCounties: IIrishCounty[];
    public irishCountiesFiltered: IIrishCounty[];

    constructor(
        protected amplitudeService: AmplitudeService,
        protected router: Router,
        protected logger: NGXLogger) {

        super(amplitudeService, router, logger);

        this.irishCountiesFiltered = [
            { "principal": "Cork" },
            { "principal": "Galway" },
            { "principal": "Mayo" },
            { "principal": "Donegal" },
            { "principal": "Kerry" },
            { "principal": "Tipperary" },
            { "principal": "Clare" },
            { "principal": "Limerick" },
            { "principal": "Roscommon" },
            { "principal": "Wexford" },
            { "principal": "Meath" },
            { "principal": "Kilkenny" },
            { "principal": "Wicklow" },
            { "principal": "Offaly" },
            { "principal": "Cavan" },
            { "principal": "Waterford" },
            { "principal": "Westmeath" },
            { "principal": "Sligo" },
            { "principal": "Laois" },
            { "principal": "Kildare" },
            { "principal": "Leitrim" },
            { "principal": "Monaghan" },
            { "principal": "Longford" },
            { "principal": "Dublin" },
            { "principal": "Carlow" },
            { "principal": "Louth" }
        ].sort((a, b) => {
            return a.principal > b.principal ? 1 : -1;
        });
        this.irishCounties = this.irishCountiesFiltered;
    }

    ngOnInit() {
        this.localSubscriptions.push(this.searchResultEmitter.subscribe((selectedAddress: HereApiAddressSearchResult) => {
            const sanitizedCounty = selectedAddress.address.county.replace(/^County\s/, "");
            const postcode = selectedAddress.address.postalCode;
            const sanitizedPostcode = regexValidators.postcodesByCountry.IRL.test(postcode) ? postcode : null;

            // Do we have a matching county?
            const county = this.irishCounties.find(c =>
                c.principal.toLowerCase().startsWith(sanitizedCounty.toLowerCase()));
            let countyValue = null;

            if (county) {
                countyValue = county.principal;
            } else {
                // Go in manual mode!
                this.logger.debug(`Can't match county: '${sanitizedCounty}'`);
            }

            this.temporarilyDisableSearchInvalidationCheck();
            this.addressForm.patchValue({
                idCheckAddressLookupAddressCode: selectedAddress.id,
                postcode: sanitizedPostcode,
                street: selectedAddress.address.street,
                buildingNumber: selectedAddress.address.houseNumber,
                city: selectedAddress.address.city,
                state: countyValue,
                line1: [selectedAddress.address.street, selectedAddress.address.houseNumber].join(" "),
                line2: "",
                line3: ""
            });

            this.trackSearchResultSelected();
        }));

        this.localSubscriptions.push(this.irishCountiesTypeahead.subscribe(term => {
            this.filterIrishCounties(term);
        }));

        if (this.simplified) {
            this.configField("line1", "RegisteredAddressLine1__c", true, true, []);
            this.configField("line2", "RegisteredAddressLine2__c", false, true, []);
        } else {
            this.configField("street", "RegisteredAddressStreet__c", true, true, [], true);
            this.configField("buildingNumber", "RegisteredAddressHouseNumber__c", true, true, [], true);
        }
        this.configField("city", "RegisteredAddressCity__c", true, true, [], true);
        this.configField("state", "RegisteredAddressState__c", true, true, [], true);
        this.configField("postcode", "RegisteredAddressPostalCode__c", false, true, [
            Validators.pattern(regexValidators.postcodesByCountry.IRL)
        ], true);

        this.initCompleted();
    }

    isDisplayingSearchResult(): boolean {
        return this.addressForm.get("idCheckAddressLookupAddressCode").value !== "";
    }

    invalidateSearchResult(fieldName: string) {
        const formValue = this.addressForm.value;
        if (formValue.idCheckAddressLookupAddressCode) {
            this.addressForm.patchValue({
                idCheckAddressLookupAddressCode: ""
            });
            this.trackSeachInvalidated(fieldName);
        }
    }

    private filterIrishCounties(term: string = "") {
        const searchterm = term.toUpperCase();

        this.irishCountiesFiltered = this.irishCounties.filter(state => {
            return state.principal.toUpperCase().startsWith(searchterm.toUpperCase());
        }).concat(this.irishCounties.filter(state => {
            return state.principal.toUpperCase().search(searchterm.toUpperCase()) > 0;
        }));
    }

    ngOnDestroy(): void {
        this.clearAllSubscriptions();
    }

}
