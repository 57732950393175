<h2>{{'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.TITLE' | translate}}</h2>

<div class="registered-address-box">

  <strong>{{'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.REGISTERED_ADDRESS_TITLE' | translate}}</strong>

  <div *ngIf="registeredCountry == 'ITA'" class="registered-address-text">
    <div data-cy="line-1" *ngIf="registeredAddress.line1">{{registeredAddress.line1}}</div>
    <div data-cy="line-2" *ngIf="registeredAddress.line2">{{registeredAddress.line2}}</div>
    <div>
      <span data-cy="postcode" *ngIf="registeredAddress.postalCode">{{registeredAddress.postalCode}}</span>
      <span data-cy="city" *ngIf="registeredAddress.city">{{registeredAddress.city}}</span>
      <span data-cy="state" *ngIf="registeredAddress.state">{{registeredAddress.state}}</span>
    </div>
  </div>

  <div *ngIf="registeredCountry !== 'ITA'" class="registered-address-text">
    <div data-cy="line-1" *ngIf="registeredAddress.line1">{{registeredAddress.line1}}</div>
    <div data-cy="line-2" *ngIf="registeredAddress.line2">{{registeredAddress.line2}}</div>
    <div>
      <span data-cy="city" *ngIf="registeredAddress.city">{{registeredAddress.city}}</span>
      <span data-cy="postcode" *ngIf="registeredAddress.postalCode">{{registeredAddress.postalCode}}</span>
    </div>
  </div>

</div>

<div class="layout-section">
  <h5><strong>{{'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.QUESTION_SAME_ADDRESS' | translate}}</strong></h5>

  <form [formGroup]="form" novalidate autocomplete="off">

    <soldo-field-layout
    [control]="form.controls['tradingAddressDifferent']"
    fieldName="tradingAddressDifferent"
    cypressSelector="Company_RadioButton_SeparateTradingAddress">

      <fieldset input class="horizontal-radio">

        <input id="YES-trading" type="radio" [value]="false"
          formControlName="tradingAddressDifferent"
          data-cy="Company_RadioButton_SeparateTradingAddress_TRUE">
        <label for="YES-trading">{{ 'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.YES_ANSWER' | translate }}</label>

        <input id="NO-trading" type="radio" [value]="true"
          formControlName="tradingAddressDifferent"
          data-cy="Company_RadioButton_SeparateTradingAddress_FALSE">
        <label for="NO-trading">{{ 'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.NO_ANSWER' | translate }}</label>

      </fieldset>
  </soldo-field-layout>

    <ng-container *ngIf="form.get('tradingAddress')">

        <h5 *ngIf="tradingCountry == 'GBR'">
          {{'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.GBR_SEARCH_HELP' | translate}}
        </h5>

        <h5 class="trading-address-title">
          <strong>{{'REGISTRATION.COMPANY_STEP.TRADING_ADDRESS.TRADING_ADDRESS_TITLE' | translate}}</strong>
        </h5>

        <div>
          <address2-form-group
            [group]="form.get('tradingAddress')"
            [activeMarketsOnly]="true" [simplified]="true"
            data-cy="Company_Address_TradingAddress">
          </address2-form-group>
        </div>

    </ng-container>

    <!-- Sub-Step Navigation -->
    <div class="form-page-CTA-row">

      <button type="button" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
        {{ 'REGISTRATION.COMPANY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
      </button>

      <button type="submit" class="primary" (click)="nextStep()" [disabled]="loading"
        [ngClass]="{ 'loading': loading}" data-cy="Signup_Button_NextStepButton">
        {{ 'REGISTRATION.COMPANY_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
      </button>

    </div>
  </form>
</div>
