import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { AlertsComponent } from "./components/alerts/alerts.component";
import { HeaderComponent } from './components/header/header.component';
import { ItalianTownsService } from './services/italian-towns.service';
import { StepService } from './services/steps.service';
import { RegistrationDataService } from './services/registrationData.service';
import { ResumeOverlayComponent } from './components/resume-overlay/resume-overlay.component';
import { FieldInfoComponent } from './components/field-info/field-info.component';
import { LeadDataMediatorService } from './services/lead-data-mediator.service';
import { HereApiaddressLookupService as HereApiAddressLookupService } from './services/here-apiaddress-lookup.service';
import { ExperimentBridgeService } from './services/experiment-bridge.service';
import { AmplitudeService } from './services/amplitude.service';
import { MarketoIntegrationService } from './services/marketo-integration.service';
import { CollapsibleContainerComponent } from './components/collapsible-container/collapsible-container.component';
import { GoogleTagManagerService } from './services/google-tag-manager.service';
import { RegistrationWebappPushApiService } from './services/registration-webapp-push-api.service';
import { DynatraceHelperService } from './services/dynatraceHelper.service';
import { SoldoFieldLayoutComponent } from './components/soldo-field-layout/soldo-field-layout.component';
import { SoldoFieldErrorComponent } from './components/soldo-field-error/soldo-field-error.component';
import { SubstepContainerDirective } from './components/substep-container.directive';
import { SoldoUkAddressSearch } from './components/soldo-uk-address-search/soldo-uk-address-search.component';
import { Address2Component } from './components/address2/address2.component';
import { AddressFieldsGbrComponent } from './components/address2/address-fields-gbr/address-fields-gbr.component';
import { AddressFieldsIrlComponent } from './components/address2/address-fields-irl/address-fields-irl.component';
import { AddressFieldsItaComponent } from './components/address2/address-fields-ita/address-fields-ita.component';
import { AddressFieldsGenericComponent } from './components/address2/address-fields-generic/address-fields-generic.component';
import { SaveAndContinueLaterComponent } from './components/save-and-continue-later/save-and-continue-later.component';
import { TrimWhitespacesDirective } from './components/trim-whitespaces.directive';
import { ModalDialogService } from './services/modal-dialog.service';
import { UboHelpComponent } from './dialog-contents/ubo-help/ubo-help.component';
import { SaveAndContinueComponent } from './dialog-contents/save-and-continue/save-and-continue.component';
import { InteractiveElementDirective } from './components/interactive-element.directive';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { TypefaceIconComponent } from './components/typeface-icon/typeface-icon.component';
import { SoldoTooltipComponent } from './components/soldo-tooltip/soldo-tooltip.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        NgSelectModule
    ],
    declarations: [
        AlertsComponent,
        HeaderComponent,
        ResumeOverlayComponent,
        FieldInfoComponent,
        CollapsibleContainerComponent,
        SoldoFieldLayoutComponent,
        SoldoFieldErrorComponent,
        TypefaceIconComponent,
        SubstepContainerDirective,
        Address2Component,
        SoldoUkAddressSearch,
        AddressFieldsGbrComponent,
        AddressFieldsIrlComponent,
        AddressFieldsItaComponent,
        AddressFieldsGenericComponent,
        SaveAndContinueLaterComponent,
        ModalDialogComponent,
        UboHelpComponent,
        SaveAndContinueComponent,
        TrimWhitespacesDirective,
        InteractiveElementDirective,
        SoldoTooltipComponent
    ],
    providers: [
        ItalianTownsService,
        StepService,
        ModalDialogService,
        RegistrationDataService,
        LeadDataMediatorService,
        HereApiAddressLookupService,
        ExperimentBridgeService,
        AmplitudeService,
        MarketoIntegrationService,
        GoogleTagManagerService,
        RegistrationWebappPushApiService,
        DynatraceHelperService
    ],
    exports: [
        AlertsComponent,
        HeaderComponent,
        ResumeOverlayComponent,
        FieldInfoComponent,
        CollapsibleContainerComponent,
        SoldoFieldLayoutComponent,
        SoldoFieldErrorComponent,
        TypefaceIconComponent,
        SubstepContainerDirective,
        Address2Component,
        SoldoUkAddressSearch,
        AddressFieldsGbrComponent,
        AddressFieldsIrlComponent,
        AddressFieldsItaComponent,
        AddressFieldsGenericComponent,
        SaveAndContinueLaterComponent,
        ModalDialogComponent,
        UboHelpComponent,
        SaveAndContinueComponent,
        TrimWhitespacesDirective,
        InteractiveElementDirective,
        SoldoTooltipComponent
    ]
})
export class SharedModule {}
