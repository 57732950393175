import { Injectable } from "@angular/core";
import { RequestAggregatorService } from "./requestAggregator.service";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class HereApiTokenService {

    private token = '';

    constructor(
        private logger: NGXLogger,
        private requestAggregator: RequestAggregatorService
    ) { }

    getToken(): Promise<string> {
        if (!this.token) {
            const promise = this.requestAggregator.sendSingleRequest<string>({
                serviceName: 'soldoHereApiRestService',
                serviceMethod: 'getToken',
                args: []
            });
            promise.then(response => {
                this.token = response;
                this.logger.debug("get new token");
            })
            return promise;
        }
        return Promise.resolve(this.token);
    }
}
