import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPagesRoutingModule } from './static-pages-routing.module';
import { SharedModule } from '../shared/shared.module';
import { StaticPreIdvPageComponent } from './static-pre-idv-page/static-pre-idv-page.component';
import { EmbeddedIdvWithMitekComponent } from './embedded-idv-with-mitek/embedded-idv-with-mitek.component';
import { EmbeddedIdvWithOnfidoComponent } from './embedded-idv-with-onfido/embedded-idv-with-onfido.component';
import { IdentityVerifiedComponent } from './identity-verified/identity-verified.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectSalesThankYouPageComponent } from './direct-sales-thank-you-page/direct-sales-thank-you-page.component';


@NgModule({
    declarations: [
        StaticPreIdvPageComponent,
        EmbeddedIdvWithMitekComponent,
        EmbeddedIdvWithOnfidoComponent,
        IdentityVerifiedComponent,
        DirectSalesThankYouPageComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        StaticPagesRoutingModule,
        TranslateModule
    ]
})
export class StaticPagesModule { }
