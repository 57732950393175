<h2>{{'REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.TITLE' | translate}}</h2>
<h5>{{'REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.SUB_TITLE' | translate}}</h5>


<form [formGroup]="form" novalidate autocomplete="off">
  <fieldset class="chips-radio" cypressSelector="Company_RadioButton_InternationalBusiness">
    <div class="radio-item" [attr.data-cy]="'Company_RadioButton_InternationalBusiness_DOMESTIC'">
      <input type="radio" formControlName="isInternational" id="optionDomesticOnly" [value]="false">
      <label for="optionDomesticOnly">{{ "REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.OPTION_DOMESTIC_LABEL" | translate }}</label>
    </div>

    <div class="radio-item" [attr.data-cy]="'Company_RadioButton_InternationalBusiness_INTERNATIONAL'">
      <input type="radio" formControlName="isInternational" id="optionInternational" [value]="true">
      <label for="optionInternational">{{ "REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.OPTION_INTERNATIONAL_LABEL" | translate }}</label>
    </div>
  </fieldset>

  <div class="conditional-subfield-container" *ngIf="form.controls['primaryInternationalMarket']">
    <soldo-field-layout
      [control]="form.controls['primaryInternationalMarket']"
      fieldName="primaryInternationalMarket"
      cypressSelector="Company_NgSelect_PrimaryInternationalMarket"
      label="REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.MAIN_INT_COUNTRY_LABEL">

      <ng-select input
        formControlName="primaryInternationalMarket"
        [items]="internationalCountriesList" dropdownPosition="bottom"
        notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
        [clearable]=false bindLabel="name" bindValue="alpha_3"
        placeholder="{{ 'REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.MAIN_INT_COUNTRY_PLACEHOLDER' | translate }}"
        name="primaryInternationalMarket">
      </ng-select>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <div class="help-box caption">
      {{ 'REGISTRATION.COMPANY_STEP.INTERNATIONAL_ACTIVITY.INT_COUNTRY_HELP_PARAGRAPH' | translate }}
    </div>
  </div>

  <div class="form-page-CTA-row">
    <button type="button" (click)="previousStep()"
      data-cy="Signup_Button_PreviousStepButton">
      {{ 'REGISTRATION.COMPANY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
    </button>
    <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}" (click)="nextStep()"
      data-cy="Signup_Button_NextStepButton">{{ 'REGISTRATION.COMPANY_STEP.NEXT_SUB_STEP_BUTTON' | translate }}</button>
  </div>

</form>
