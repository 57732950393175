import { Component, OnInit, Input, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AddressFormControlMetadata } from '../address2.component';
import { AbstractAddressFieldsComponent } from '../abstract-address-fields.component';
import { Router } from '@angular/router';
import { HereApiAddressSearchResult } from '../../../services/here-apiaddress-lookup.service';
import { AmplitudeService } from '../../../services/amplitude.service';
import { regexValidators } from '../../../customValidators';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'address-fields-generic',
    templateUrl: './address-fields-generic.component.html',
    styleUrls: ['./address-fields-generic.component.scss']
})
export class AddressFieldsGenericComponent extends AbstractAddressFieldsComponent implements OnInit, OnChanges, OnDestroy {

    @Input('formGroup')
    public addressForm: UntypedFormGroup;

    @Input('simplified')
    public simplified: boolean = false;

    @Input('fieldMetadata')
    public fieldMetadata: AddressFormControlMetadata;

    @Input('searchResultEmitter')
    public searchResultEmitter: EventEmitter<HereApiAddressSearchResult>;

    @Input()
    readonly = false;

    constructor(
        protected amplitudeService: AmplitudeService,
        protected router: Router,
        protected logger: NGXLogger) {

        super(amplitudeService, router, logger);
    }

    ngOnInit() {
        this.localSubscriptions.push(this.searchResultEmitter.subscribe((selectedAddress: HereApiAddressSearchResult) => {
            this.temporarilyDisableSearchInvalidationCheck();
            this.addressForm.patchValue({
                idCheckAddressLookupAddressCode: selectedAddress.id,
                postcode: selectedAddress.address.postalCode,
                street: selectedAddress.address.street,
                buildingNumber: selectedAddress.address.houseNumber,
                state: selectedAddress.address.county,
                city: selectedAddress.address.city,
                line1: [selectedAddress.address.street, selectedAddress.address.houseNumber].join(" "),
                line2: "",
                line3: ""
            });
            this.trackSearchResultSelected();
        }));

        this.initCompleted();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes["addressForm"]) {
            const country = this.addressForm.get("country").value;
            const postcodeRegexPattern = regexValidators.postcodesByCountry[country] || /.*/;

            if (this.simplified) {
                this.configField("line1", "RegisteredAddressLine1__c", true, true, []);
                this.configField("line2", "RegisteredAddressLine2__c", false, true, []);
            } else {
                this.configField("street", "RegisteredAddressStreet__c", true, true, [], true);
                this.configField("buildingNumber", "RegisteredAddressHouseNumber__c", true, true, [], true);
            }
            this.configField("city", "RegisteredAddressCity__c", true, true, [], true);
            this.configField("postcode", "RegisteredAddressPostalCode__c", true, true, [
                Validators.pattern(postcodeRegexPattern), Validators.maxLength(8)
            ], true);
        }
    }

    isDisplayingSearchResult(): boolean {
        return this.addressForm.get("idCheckAddressLookupAddressCode").value !== "";
    }

    invalidateSearchResult(fieldName: string) {
        const formValue = this.addressForm.value;
        if (formValue.idCheckAddressLookupAddressCode) {
            this.addressForm.patchValue({
                idCheckAddressLookupAddressCode: ""
            });
            this.trackSeachInvalidated(fieldName);
        }
    }

    ngOnDestroy(): void {
        this.clearAllSubscriptions();
    }

}
