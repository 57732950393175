<soldo-field-layout
  cypressSelector="Address_UkAddressSearch_SearchAddress"
  [control]="searchControl"
  [fieldName]="controlName"
  label="COMPONENTS.UK_ADDRESS_SEARCH.LABEL">

  <div class="autocomplete-field" input>
    <typeface-icon>search</typeface-icon>
    <ng-select
      name="hereAPIAddressSearch"
      [loading]="searchLoading"
      [items]="addressEntries"
      dropdownposition="bottom"
      [notFoundText]="'COMPONENTS.UK_ADDRESS_SEARCH.NO_RESULT_FOUND' | translate : {postcode: searchTerm}"
      type="search"
      [typeahead]="searchTypeahead"
      [clearable]="false"
      bindLabel="text"
      bindValue="idCheckAddressLookupAddressCode"
      placeholder="{{ 'COMPONENTS.UK_ADDRESS_SEARCH.PLACEHOLDER' | translate }}"
      [formControl]="searchControl">
    </ng-select>
  </div>

  <ng-container errorMessage>
    <soldo-field-error errorName="pattern">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
  </ng-container>

</soldo-field-layout>
