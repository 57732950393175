import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationDataService } from '../../shared/services/registrationData.service';
import { AmplitudeService } from '../../shared/services/amplitude.service';
import { APP_CONFIG, AppConfig } from '../../app-config.module';
import { StepService } from '../../shared/services/steps.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'applicant-pre-idv-page',
    templateUrl: './applicant-pre-idv-page.component.html',
    styleUrl: './applicant-pre-idv-page.component.scss'
})
export class ApplicantPreIdvPageComponent implements OnInit {

    loading: boolean = true;
    applicantFirstName: string = '';
    necessaryIdvSteps: string[] = [
        "STEP_OPEN_LINK",
        "STEP_UPLOAD_ID",
        "STEP_RECORD_VIDEO"
    ];
    idvHelpPageUrl: string = null;
    privacyPolicyLink: string = null;
    postRegistrationInfoUrl: string = null;
    applicantMustIdv: boolean = false;
    idvProvider: 'Onfido' | 'Mitek' = 'Onfido';

    constructor(
        @Inject(APP_CONFIG) private readonly config: AppConfig,
        private readonly router: Router,
        private readonly registrationDataService: RegistrationDataService,
        private readonly amplitudeService: AmplitudeService,
        private readonly stepsService: StepService,
        private readonly translateService: TranslateService) { }

    ngOnInit(): void {
        this.stepsService.deactivateProgressBar();

        this.translateService.onLangChange.subscribe(e => {
            this.handleLanguageChange(e.lang);
        });
        this.handleLanguageChange(this.translateService.currentLang);

        switch (this.registrationDataService.getCompanyCountry()) {
            case "GBR":
                this.privacyPolicyLink = this.config.privacyPolicyLink.en;
                this.idvHelpPageUrl = this.config.registrationProcessHelpURLs.fallback;
                break;
            case "ITA":
                this.privacyPolicyLink = this.config.privacyPolicyLink.it;
                this.idvHelpPageUrl = this.config.registrationProcessHelpURLs.ITA;
                break;
            default:
                this.privacyPolicyLink = this.config.privacyPolicyLink.fallback;
                this.idvHelpPageUrl = this.config.registrationProcessHelpURLs.fallback;
                break;
        }

        const applicant = this.registrationDataService.getContacts().find(c => c.ContactIsPrimaryLead__c);
        this.applicantFirstName = applicant.FirstName;

        // Should this fail or timeout, this.applicantMustIdv remains false, they can still IDV when they get the email.
        this.registrationDataService.loadIDVData().then((idvData) => {

            this.applicantMustIdv = idvData.applicantMustIdv;

            if (this.applicantMustIdv) {
                switch (idvData.provider) {
                    case 'HOOYU':
                        this.idvProvider = 'Mitek';
                        break;
                    case 'ONFIDO':
                        this.idvProvider = 'Onfido';
                        break;
                }

                const poaRequired = Boolean(this.registrationDataService
                    .getComplianceContext()
                    .identityVerificationMetadataList
                    .find(idvc => idvc.identityVerificationSteps?.split(',').includes('POA')));

                if (poaRequired) {
                    this.necessaryIdvSteps.splice(2, 0, "STEP_UPLOAD_POA");
                }
            }

            this.loading = false;
        });
    }

    handleLanguageChange(lang) {
        this.postRegistrationInfoUrl = this.config.postRegistrationInfoURLs[lang] || this.config.postRegistrationInfoURLs.fallback;
    }

    proceedToIDV(): void {
        this.amplitudeService.trackIDVLinkClicked(this.idvProvider, () => {
            this.router.navigate([`static/verify-with-${this.idvProvider.toLowerCase()}`]);
        });
    }

    onClickCTA(): void {
        if (this.applicantMustIdv) {
            window.open(this.postRegistrationInfoUrl);
        } else {
            window.location.assign(this.postRegistrationInfoUrl);
        }
    }
}
