<div class="doc-upload">
  <header>
    <h1>{{ "REGISTRATION.DOCUPLOAD.TITLE" | translate }}</h1>
    <p>{{ "REGISTRATION.DOCUPLOAD.SUBTITLE" | translate }}
      <span *ngFor="let n of nameList"><b *ngIf="n != ', '">{{n}}</b><span *ngIf="n == ', '">{{n}}</span></span>
    </p>
    <div class="icon">
      <img src="../../../assets/images/doc_upload.png">
    </div>
  </header>

  <!-- FOR EACH CONTACT -->
  <div class="person-documents" *ngFor="let person of people; let i=index" [class.complete]="person.completion == 100"
    data-cy="DocumentUpload_ContactDocuments_ContactDocumentsGroup">
    <h4>{{ "REGISTRATION.DOCUPLOAD.PERSON" | translate : { name: person.name } }}</h4>

    <!-- FOR EACH PROOF -->
    <div class="person-required-document" *ngFor="let document of person.requiredDocuments"
      [class.locked]="document.uploadStatus != 'NEW'" [class.open]="document.open"
      [class.selected]="document.docType != null" [class.confirming]="document.uploadStatus == 'CONFIRMING'"
      [class.confirmed]="document.uploadStatus == 'CONFIRMED'" (click)="open($event, document)"
      [attr.data-cy]="'DocumentUpload_UploadProof_'+document.proofType">
      <label
        class="required-doc-title">{{ "REGISTRATION.DOCUPLOAD.DOC_REQUIRED."+document.proofType | translate }}</label>
      <label
        class="required-doc-uploaded">{{ "REGISTRATION.DOCUPLOAD.DOC_UPLOADED."+document.proofType | translate }}</label>
      <label class="file-size-help">{{ "REGISTRATION.DOCUPLOAD.FILE_HELP" | translate }}</label>

      <div class="toggle" (click)="toggle($event, document)" data-cy="DocumentUpload_Button_ToggleContact"></div>
      <div class="completion-tick">done</div>

      <div class="person-required-document-body">
        <label class="select-type-message">{{ "REGISTRATION.DOCUPLOAD.SELECT_TYPE" | translate }}</label>
        <div class="type-radio-list" data-cy="DocumentUpload_RadioButton_DocumentType">
          <!-- DOC TYPE SELECTOR -->
          <div *ngFor="let opt of this._docTypesByCountry(this.docCountry)[document.proofType]; index as i;" class="radio-item">
            <input type="radio" name="doc-{{document.proofId}}" [(ngModel)]="document.docType" [value]="opt.docType"
              [id]="document.proofId+'-'+i" (change)="onDocTypeChange(document, opt)"
              [disabled]="document.uploadStatus != 'NEW'"  [attr.data-cy]="'DocumentUpload_RadioButton_DocumentType_'+opt.docType">
            <label [for]="document.proofId+'-'+i">{{ docTypeLabel(opt.docType) }}</label>
          </div>
        </div>

        <div class="file-help">
          <p>{{ docTypeHelp(document.proofType, document.docType) }}</p>
        </div>

        <div class="uploads-row">

          <!-- COLUMN 1 ### FRONT -->

          <div class="upload-column front" data-cy="DocumentUpload_FileUpload_Front">

            <div class="document-upload" [class.complete]="getFileUploadStatus(document.fileUploads[0]) == 'uploaded'"
              [class.uploading]="getFileUploadStatus(document.fileUploads[0]) == 'uploading'"
              [class.error]="getFileUploadStatus(document.fileUploads[0]) == 'error'">

              <div class="upload-input" (dragenter)="onDragOver($event, person, document, document.fileUploads[0])"
                (dragover)="onDragOver($event, person, document, document.fileUploads[0])"
                (dragleave)="onDragLeave($event, person, document, document.fileUploads[0])"
                (drop)="onDrop($event, person, document, document.fileUploads[0])"
                (click)="fileInputFront.click();$event.stopPropagation();">

                <input type="file" #fileInputFront name="{{person.personId}}-{{document.proofId}}-front"
                  [disabled]="getFileUploadStatus(document.fileUploads[0]) == 'uploading'" accept="image/png,image/jpeg,application/pdf"
                  (change)="onFileSelected(person, document, $event.target.files, document.fileUploads[0])" />

                  <label for="{{person.personId}}-{{document.proofId}}-front" class="desktop"
                    innerHTML="{{ 'REGISTRATION.DOCUPLOAD.DROP_HERE' | translate }}"></label>
                  <label for="{{person.personId}}-{{document.proofId}}-front" class="mobile"
                    innerHTML="{{ 'REGISTRATION.DOCUPLOAD.SELECT_FILE_MOBILE' | translate }}"></label>

                <button
                  [disabled]="getFileUploadStatus(document.fileUploads[0]) == 'uploading' || getFileUploadStatus(document.fileUploads[0]) == 'uploaded'"
                  (click)="fileInputFront.click();$event.stopPropagation();">{{ 'REGISTRATION.DOCUPLOAD.BROWSE_FILES' | translate }}</button>

              </div>

              <div class="upload-progress">
                {{ "REGISTRATION.DOCUPLOAD.UPLOADING" | translate }} {{ document.fileUploads[0].httpUploadStatus.percentageUploaded }}%
              </div>
              <div class="upload-success" *ngIf="document.filesRequired == 1">
                {{ "REGISTRATION.DOCUPLOAD.DONE."+document.proofType | translate : { fileName : document.fileUploads[0].fileName } }}
                <button class="remove-file" (click)="removeFile(document, document.fileUploads[0])">X</button>
              </div>
              <div class="upload-success" *ngIf="document.filesRequired > 1">
                {{ "REGISTRATION.DOCUPLOAD.DONE."+document.proofType+"_FRONT" | translate : { fileName : document.fileUploads[0].fileName } }}
                <button class="remove-file" (click)="removeFile(document, document.fileUploads[0])">X</button>
              </div>
              <div class="upload-error">
                {{ "REGISTRATION.DOCUPLOAD.ERROR" | translate }}
              </div>
              <div class="file-selection-error file_too_large"
                *ngIf="document.fileUploads[0].httpUploadStatus.error == 'too_large'">
                {{ "REGISTRATION.DOCUPLOAD.FILE_ERROR_LARGE" | translate }}
              </div>
              <div class="file-selection-error wrong_type"
                *ngIf="document.fileUploads[0].httpUploadStatus.error == 'wrong_type'">
                {{ "REGISTRATION.DOCUPLOAD.FILE_ERROR_TYPE" | translate }}
              </div>
            </div>

          </div>

          <!-- COLUMN 2 ### BACK -->

          <div class="upload-column back" *ngIf="document.filesRequired > 1" data-cy="DocumentUpload_FileUpload_Back">

            <div class="document-upload" [class.complete]="getFileUploadStatus(document.fileUploads[1]) == 'uploaded'"
              [class.uploading]="getFileUploadStatus(document.fileUploads[1]) == 'uploading'"
              [class.error]="getFileUploadStatus(document.fileUploads[1]) == 'error'">

              <div class="upload-input" (dragenter)="onDragOver($event, person, document, document.fileUploads[1])"
                (dragover)="onDragOver($event, person, document, document.fileUploads[1])"
                (dragleave)="onDragLeave($event, person, document, document.fileUploads[1])"
                (drop)="onDrop($event, person, document, document.fileUploads[1])"
                (click)="fileInputBack.click();$event.stopPropagation();">

                <input type="file" name="{{person.personId}}-{{document.proofId}}-back" #fileInputBack
                  [disabled]="getFileUploadStatus(document.fileUploads[1]) == 'uploading'" accept="image/png,image/jpeg,application/pdf"
                  (change)="onFileSelected(person, document, $event.target.files, document.fileUploads[1])" />

                <label for="{{person.personId}}-{{document.proofId}}-back" class="desktop"
                  innerHTML="{{ 'REGISTRATION.DOCUPLOAD.DROP_HERE' | translate }}"></label>
                <label for="{{person.personId}}-{{document.proofId}}-back" class="mobile"
                  innerHTML="{{ 'REGISTRATION.DOCUPLOAD.SELECT_FILE_MOBILE' | translate }}"></label>

                <button
                  [disabled]="getFileUploadStatus(document.fileUploads[1]) == 'uploading' || getFileUploadStatus(document.fileUploads[1]) == 'uploaded'"
                  (click)="fileInputBack.click();$event.stopPropagation();">{{ 'REGISTRATION.DOCUPLOAD.BROWSE_FILES' | translate }}</button>

              </div>

              <div class="upload-progress">
                {{ "REGISTRATION.DOCUPLOAD.UPLOADING" | translate }} {{ document.fileUploads[1].httpUploadStatus.percentageUploaded }}%
              </div>
              <div class="upload-success">
                {{ "REGISTRATION.DOCUPLOAD.DONE."+document.proofType+"_BACK" | translate : { fileName : document.fileUploads[1].fileName }  }}
                <button class="remove-file" (click)="removeFile(document, document.fileUploads[1])">X</button>
              </div>
              <div class="upload-error">
                {{ "REGISTRATION.DOCUPLOAD.ERROR" | translate }}
              </div>
              <div class="file-selection-error file_too_large"
                *ngIf="document.fileUploads[1].httpUploadStatus.error == 'too_large'">
                {{ "REGISTRATION.DOCUPLOAD.FILE_ERROR_LARGE" | translate }}
              </div>
              <div class="file-selection-error wrong_type"
                *ngIf="document.fileUploads[1].httpUploadStatus.error == 'wrong_type'">
                {{ "REGISTRATION.DOCUPLOAD.FILE_ERROR_TYPE" | translate }}
              </div>
            </div>

          </div>


        </div>

        <div class="confirm-button">
          <button type="submit" class="btn btn-primary" (click)="confirmDocument(person, document)"
            [disabled]="!canConfirmDocument(document)"
            [ngClass]="{ 'in-progress': document.uploadStatus == 'CONFIRMING' }"
             data-cy="DocumentUpload_Button_ConfirmDocument">
            {{ 'REGISTRATION.DOCUPLOAD.DOC_CONFIRM' | translate}}
          </button>
        </div>
      </div>

    </div>

  </div>

  <div class="end-button">
    <button type="submit" class="btn btn-primary" (click)="endUploadPhase()" [disabled]="endUploadPhaseButtonDisabled()"
      [ngClass]="{ 'in-progress': loading }" data-cy="DocumentUpload_Button_DoneUploading">
      {{ 'REGISTRATION.DOCUPLOAD.END_PROCESS' | translate}}
    </button>
  </div>
</div>
