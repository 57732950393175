<h3 class="title">{{'REGISTRATION.SIGNUP_STEP.ENTITY_NAME.TITLE' | translate}}</h3>

<form [formGroup]="form" novalidate autocomplete="off">

  <soldo-field-layout
    [control]="form.controls['companyName']"
    fieldName="companyName"
    [required]="true"
    cypressSelector="Signup_TextInput_EntityName"
    [label]="'REGISTRATION.SIGNUP_STEP.ENTITY_NAME.' + (soleTrader ? 'TRADING_NAME' : 'COMPANY_NAME') + '_LABEL'">

    <input input
      name="companyName"
      type="text"
      formControlName="companyName"
      class="form-control"
      placeholder="{{ 'REGISTRATION.SIGNUP_STEP.ENTITY_NAME.' + (soleTrader ? 'TRADING_NAME' : 'COMPANY_NAME') + '_PLACEHOLDER' | translate }}">

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REGISTRATION.SIGNUP_STEP.ENTITY_NAME.COMPANY_NAME_REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error error errorName="maxlength">
        {{ 'MAX_LENGHT' | translate : { value: form.get('companyName').errors?.maxlength?.requiredLength} }}
      </soldo-field-error>
    </ng-container>
  </soldo-field-layout>

</form>

<button *ngIf="isCompanySearchAvailable()"
        data-cy="Signup_Button_Switch_To_Company_Search" type="button"
        (click)="switchToCompanySearch()"
        class="btn btn-link switch-to-company-search-button">
  {{'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SWITCH_COMPANY_SEARCH_LINK' | translate}}
</button>

<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.SIGNUP_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.SIGNUP_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
