import { Component } from '@angular/core';
import { StepService } from '../shared/services/steps.service';
@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {

    progressBarVisible: boolean = true;

    constructor(private stepsService: StepService) {
        this.stepsService.progressBarActivation.subscribe(activate => {
            this.progressBarVisible = activate;
        });
    }

}
