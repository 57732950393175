import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive({
    selector: '[interactive]',
    host: {
        '(click)': '$any(this)._activated($event)',
        '(keydown.enter)': '$any(this)._activated($event)',
        '(keydown.space)': '$any(this)._activated($event)'
    }
})
export class InteractiveElementDirective{

    @Input()
    interactive: number;

    @Output()
    interact: EventEmitter<MouseEvent | KeyboardEvent> = new EventEmitter<MouseEvent | KeyboardEvent>();

    _activated(event: MouseEvent | KeyboardEvent) {
        this.interact.emit(event);
    }

}
