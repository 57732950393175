<div [formGroup]="addressForm">

  <!--
    The form works in 2 modes: normal and simplified

    Simplified has no search service and only asks for City, Postcode (if applicable),
    and 2 lines of text.

    Normal mode has search (W2 for UK and Here for the others) and asks for the full
    complement of fields.
  -->


  <!-- COUNTRY ROW -->
  <soldo-field-layout
    cypressSelector="Address_NgSelect_Country"
    [control]="addressForm.controls['country']"
    fieldName="country"
    label="REGISTRATION.ADDRESS.COUNTRY_LABEL">

      <ng-select input
        *ngIf="!readonly && !disableCountrySelection"
        name="country"
        [items]="countries"
        dropdownposition="bottom"
        notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
        type="search"
        [clearable]=false
        bindLabel="name"
        bindValue="alpha_3"
        formControlName="country">
      </ng-select>

      <div *ngIf="readonly || disableCountrySelection" input>
        <input type="text" name="country_label" [value]="getCountryLabel()" readonly>
        <input type="hidden" formControlName="country" name="country">
      </div>

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>

  </soldo-field-layout>

  <!-- HERE API SEARCH ROW -->
  <ng-container *ngIf="country !== 'GBR' && !simplified && !readonly">

    <soldo-field-layout
      cypressSelector="Address_HereAPIAddressSearch_SearchAddress"
      [control]="addressForm.controls['hereAPIAddressSearch']"
      fieldName="hereAPIAddressSearch"
      label="REGISTRATION.ADDRESS.SEARCH">

      <div class="autocomplete-field" input>
        <typeface-icon>search</typeface-icon>
        <ng-select
          name="hereAPIAddressSearch"
          [typeahead]="searchHereApi"
          [loading]="hereApiSearchLoading"
          [items]="hereApiAddressSearchResults"
          dropdownposition="bottom"
          notFoundText="{{'REGISTRATION.ADDRESS.SEARCH_NO_RESULT' | translate }}"
          type="search"
          [clearable]=false
          bindLabel="title"
          bindValue="id"
          placeholder="{{ 'REGISTRATION.ADDRESS.SEARCH_PLACEHOLDER' | translate }}"
          formControlName="hereAPIAddressSearch">
        </ng-select>
      </div>

    </soldo-field-layout>

  </ng-container>

  <!-- W2 UK SEARCH BY POSTCODE ROW -->
  <ng-container *ngIf="country === 'GBR' && !readonly">

    <div class="form-input child-position">
      <soldo-uk-address-search
        (resultSelected)="ukSearchResultSelected($event)"
        (queryResults)="ukSearchQueryResults($event)"
        [group]="addressForm"
        controlName="ukAddressSearch">
      </soldo-uk-address-search>
    </div>

  </ng-container>

  <address-fields-gbr *ngIf="addressFieldsLayout === 'GBR'"
    [readonly]="readonly"
    [formGroup]="addressForm"
    [simplified]="simplified"
    [fieldMetadata]="fieldMetadata"
    [searchResultEmitter]="w2UkLookupSelectionEmitter"
    [manualChangeEmitter]="manualChangeEmitter"
    [detectManualEdits]="detectManualEdits">
  </address-fields-gbr>

  <address-fields-ita *ngIf="addressFieldsLayout === 'ITA'"
    [readonly]="readonly"
    [formGroup]="addressForm"
    [simplified]="simplified"
    [fieldMetadata]="fieldMetadata"
    [searchResultEmitter]="hereApiSelectionEmitter"
    [manualChangeEmitter]="manualChangeEmitter"
    [detectManualEdits]="detectManualEdits">
  </address-fields-ita>

  <address-fields-irl *ngIf="addressFieldsLayout === 'IRL'"
    [readonly]="readonly"
    [formGroup]="addressForm"
    [simplified]="simplified"
    [fieldMetadata]="fieldMetadata"
    [searchResultEmitter]="hereApiSelectionEmitter"
    [manualChangeEmitter]="manualChangeEmitter"
    [detectManualEdits]="detectManualEdits">
  </address-fields-irl>

  <address-fields-generic *ngIf="addressFieldsLayout === 'generic'"
    [readonly]="readonly"
    [formGroup]="addressForm"
    [simplified]="simplified"
    [fieldMetadata]="fieldMetadata"
    [searchResultEmitter]="hereApiSelectionEmitter"
    [manualChangeEmitter]="manualChangeEmitter"
    [detectManualEdits]="detectManualEdits"></address-fields-generic>

  <input type="hidden" formControlName="state" name="state">
  <input type="hidden" formControlName="line1" name="line1">
  <input type="hidden" formControlName="line2" name="line2">
  <input type="hidden" formControlName="line3" name="line3">
  <input type="hidden" formControlName="idCheckAddressLookupAddressCode" name="idCheckAddressLookupAddressCode">
  <input type="hidden" formControlName="poBoxNumber" name="poBoxNumber">
  <input type="hidden" formControlName="secondaryStreet" name="secondaryStreet">
</div>
