import { Component, ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DefaultOutboundActions } from '@shared/models/app-structure.model';
import { AmplitudeService } from '@shared/services/amplitude.service';
import { CountryService, ICountryMetadata } from '@shared/services/country.service';
import { GoogleTagManagerService } from '@shared/services/google-tag-manager.service';
import { LeadStartData } from '@shared/services/lead-data-mediator.service';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { SignupSubStepOutboundAction, SignupSubStepType } from '@src/app/registration/signup-step/signup-step.model';
import { take } from 'rxjs/operators';

@Component({
    selector: 'substep-country',
    templateUrl: './country.component.html',
    styleUrls: ['./country.component.scss']
})
export class CountrySubStepComponent extends SignupSubStepType {
    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
    subStepName: string = "Country of Incorporation";

    loading: boolean = true;
    form: UntypedFormGroup;
    searchMode: boolean = false;
    visibleCountries: ICountryMetadata[];
    selectedCountry: ICountryMetadata;
    previousSelectedCountryCode: string;
    showSelectedSeparately: boolean = false;
    clearButtonVisibility: boolean = false;

    isPopularCountry: (countryMetadata: ICountryMetadata) => boolean = (country: ICountryMetadata) => {
        return this.countryService.getMainMarkets().includes(country.alpha_3);
    };

    isAllowedCountry: (countryMetadata: ICountryMetadata) => boolean = (country: ICountryMetadata) => {
        return country.active_market;
    };

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly countryService: CountryService,
        private readonly translateService: TranslateService,
        private readonly amplitudeService: AmplitudeService,
        private readonly gtmService: GoogleTagManagerService,
        private readonly registrationDataService: RegistrationDataService) {
        super();
        this.controlActionEventEmitter = new EventEmitter<SignupSubStepOutboundAction>();
    }

    init(d: Partial<LeadStartData>): void {

        this.translateService.onLangChange.subscribe(() => {
            setTimeout(() => {
                this.performSearch(this.form.get('searchTerm').value);
            }, 10);
        });

        this.form = this.fb.group({
            "searchTerm": ["", this.leadMaxlengthValidator("RegisteredAddressCountryISO__c")],
            "country": [d.companyCountry, Validators.required]
        });

        this.form.get("country").valueChanges.subscribe((value) => {
            this.showSelectedSeparately = false;
            if (value) {
                this.previousSelectedCountryCode = value;
            }
        });

        if (this.registrationDataService.isDirectSalesSegment()) {
            this.isAllowedCountry = (country: ICountryMetadata) => {
                return !country.blacklist;
            };
        }

        this.countryService.countries.pipe(take(1)).subscribe(listOfAllCountries => {

            const popularCountriesList: ICountryMetadata[] = listOfAllCountries
                .filter(this.isPopularCountry)
                .sort(this.countryService.sortFunction);

            if (d.companyCountry) {
                this.previousSelectedCountryCode = d.companyCountry;
                this.setSelectedWithPreviousCountryCode(listOfAllCountries);

                if (!this.isPopularCountry(this.selectedCountry)) {
                    this.showSelectedSeparately = true;
                }
                this.visibleCountries = popularCountriesList;
            } else {
                this.countryService.locationPromise.then((countryCode: string) => {

                    this.selectedCountry = listOfAllCountries.filter(this.isAllowedCountry).find(country => country.alpha_3 === countryCode);
                    this.visibleCountries = popularCountriesList;

                    if (this.selectedCountry !== undefined) {
                        this.form.get("country").setValue(countryCode);
                    } else if (!this.isPopularCountry(this.selectedCountry)) {
                        this.showSelectedSeparately = true;
                    }
                }, () => {
                    this.visibleCountries = popularCountriesList;
                    this.loading = false;
                });
            }
        });

        this.form.get("searchTerm").valueChanges.subscribe(newSearchTerm => {
            this.performSearch(newSearchTerm);
        });
        this.loading = false;
    }

    private performSearch(newSearchTerm: string): void {
        this.loading = true;

        this.countryService.countries.subscribe(listOfAllCountries => {

            if (newSearchTerm === "") {
                this.clearButtonVisibility = false;
                this.searchMode = false;
                const popularCountriesList: ICountryMetadata[] = listOfAllCountries
                    .filter(this.isPopularCountry)
                    .sort(this.countryService.sortFunction);
                this.visibleCountries = popularCountriesList;

                this.setSelectedWithPreviousCountryCode(listOfAllCountries);

                this.form.get("country").setValue(this.previousSelectedCountryCode);

                if (!this.isPopularCountry(this.selectedCountry)) {
                    this.showSelectedSeparately = true;
                }

            } else {
                this.clearButtonVisibility = true;
                this.searchMode = true;
                this.visibleCountries = listOfAllCountries.filter(this.isAllowedCountry).filter((country) => {
                    return country.name.toLowerCase().startsWith(newSearchTerm.toLowerCase());
                }).sort(this.countryService.sortFunction);

                if (this.visibleCountries.find(c => c.alpha_3 === this.previousSelectedCountryCode)) {
                    this.form.get("country").setValue(this.previousSelectedCountryCode);

                } else {
                    this.selectedCountry = null;
                    this.form.get("country").setValue(null);
                    this.form.get("country").markAsUntouched();
                }

            }
            this.loading = false;
        });
    }

    setSelectedWithPreviousCountryCode(listOfAllCountries: ICountryMetadata[]): void {
        this.selectedCountry = listOfAllCountries.find((country) => {
            return country.alpha_3 == this.previousSelectedCountryCode;
        });
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        }
    }

    isValid(): boolean {
        this.form.get("country").markAsTouched();
        return this.form.valid;
    }

    getData(): Partial<LeadStartData> {
        const formValues = this.form.value;
        return {
            companyCountry: formValues["country"]
        };
    }

    clearButtonOnClick(): void {
        this.form.get('searchTerm').setValue("");
        this.searchMode = false;
        this.clearButtonVisibility = false;
        this.form.get("country").setValue(this.previousSelectedCountryCode);
        if (!this.isPopularCountry(this.selectedCountry)) {
            this.showSelectedSeparately = true;
        }
        this.searchInput.nativeElement.focus();
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
        this.amplitudeService.trackSignupCountryAccepted(this.getData().companyCountry);
    }
}
