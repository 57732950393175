<div class="message-alerts">
  <div *ngFor="let alert of alerts">
    <div [class]="'alert alert-' + alert.type + ' alert-dismissible'" role="alert">
      <div class="notice"><img src="/assets/images/notification.png" height="24px"></div>
      <button  type="button" class="close" aria-label="Close" (click)="closeAlert(alert)">
        <span aria-hidden="true">&times;</span>
      </button>
      <div [innerHTML]="alert.message | translate"></div>
    </div>
  </div>
</div>
