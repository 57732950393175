<h3 class="title">{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.USE_CASE.TITLE' | translate}}</h3>
<p>{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.USE_CASE.TIP' | translate}}</p>
<div class="row my-4">
  <div class="col-md-7 form" *ngIf="form">
    <form [formGroup]="form" novalidate>
      <p><strong>{{'REGISTRATION.EXPECTED_ACTIVITY_STEP.USE_CASE.LABEL' | translate}}</strong></p>
      <ng-container *ngFor="let useCase of useCases">
        <soldo-field-layout
          [control]="form.controls[useCase.label]"
          [fieldName]="useCase.label"
          [cypressSelector]="'Expected_RadioButton_UseCases_' + useCase.label">

          <ng-container input>
              <input #input [id]="useCase.label" type="checkbox" [formControlName]="useCase.label"
                (change)="updateCheck(useCase, input.checked)">
              <label class="full-length-line" [for]="useCase.label">
                {{'REGISTRATION.EXPECTED_ACTIVITY_STEP.USE_CASE.USE_CASE_'+useCase.label | translate}}
              </label>
          </ng-container>
        </soldo-field-layout>
      </ng-container>
      <ng-container *ngIf="form.controls['description']">
        <div class="description_container">
          <div class="spacer"></div>
          <soldo-field-layout [required]="true" [control]="form.controls['description']" cypressSelector="Expected_RadioButton_UseCases_Description"
            label="REGISTRATION.EXPECTED_ACTIVITY_STEP.USE_CASE.OTHER_LABEL" fieldName="description">
            <textarea input name="description" formControlName="description" type="text" class="form-control"
              rows="5"></textarea>
            <ng-container errorMessage>
              <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
            </ng-container>
          </soldo-field-layout>
        </div>
      </ng-container>
      <ng-container *ngIf="form.touched && !atLeastOneSelected()">
        <span class="text-danger mt-3">
          {{'ONE_OR_MORE_OPTIONS_REQUIRED' | translate}}
        </span>
      </ng-container>
    </form>
  </div>
</div>

<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading"
      data-cy="Signup_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
