import { Injectable, Inject } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { NGXLogger } from 'ngx-logger';
import { APP_CONFIG, AppConfig } from "@src/app/app-config.module";
import { HttpParams } from "@angular/common/http";

@Injectable()
export class MentionMeService {
    public static COOKIE_NAME = 'soldo-mentionme';

    private _mentionme: boolean = false;

    constructor(
        protected logger: NGXLogger,
        private cookieService: CookieService,
        @Inject(APP_CONFIG) private appConfig: AppConfig
    ) {
        if (this.cookieService.check(MentionMeService.COOKIE_NAME)) {
            this._mentionme = this.cookieService.get(MentionMeService.COOKIE_NAME) === "true";
        } else {
            this._mentionme = false;
        }
    }

    init(urlParam: string) {
        if (urlParam === "true") {
            this._mentionme = true;
            this.cookieService.set(MentionMeService.COOKIE_NAME, "true");
            this.logger.debug("mentionme init to: ", this._mentionme);
        }
    }

    get isEnabled() {
        return this._mentionme;
    }

    private loadMentionMeTag(tagSource: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = tagSource;

            script.onload = () => {
                resolve({ script: "MentionMe", loaded: true, status: 'Loaded' });
            };
            script.onerror = (error: any) => resolve({ script: "MentionMe", loaded: false, status: 'Loaded' });
            document.getElementsByTagName('head')[0].appendChild(script);
        });
    }

    private getBaseTagURL(method: string): string {
        return [
            this.appConfig.mentionMeBaseUrl,
            method, "/",
            this.appConfig.mentionMeCustomerCode
        ].join("");
    }

    getRefereeTagBaseURL(params?: HttpParams): string {
        return this.getBaseTagURL("refereefind") + (params ? "?" + params.toString() : "");
    }

    getOrderTagBaseURL(params?: HttpParams): string {
        return this.getBaseTagURL("order") + (params ? "?" + params.toString() : "");;
    }

    loadRefereeTag(params?: HttpParams): Promise<any> {
        return this.loadMentionMeTag(this.getRefereeTagBaseURL(params));
    }

    loadOrderTag(params?: HttpParams): Promise<any> {
        return this.loadMentionMeTag(this.getOrderTagBaseURL(params));
    }
}
