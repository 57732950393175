import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SignupStepComponent } from './signup-step.component';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AboutYouSubStepComponent } from './substeps/about-you/about-you.component';
import { CompanyNameSubStepComponent } from './substeps/company-name/company-name.component';
import { CompanySearchDetailsSubStepComponent } from './substeps/company-search-details/company-search-details.component';
import { CompanySearchSubStepComponent } from './substeps/company-search/company-search.component';
import { CompanyTypeSubStepComponent } from './substeps/company-type/company-type.component';
import { CountrySubStepComponent } from './substeps/country/country.component';
import { EmailVerificationSubStepComponent } from './substeps/email-verification/email-verification.component';
import { NumberOfEmployeesSubStepComponent } from './substeps/number-of-employees/number-of-employees.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        TranslateModule,
        ReactiveFormsModule,
        NgSelectModule
    ],
    declarations: [
        SignupStepComponent,
        CountrySubStepComponent,
        CompanyTypeSubStepComponent,
        NumberOfEmployeesSubStepComponent,
        AboutYouSubStepComponent,
        EmailVerificationSubStepComponent,
        CompanyNameSubStepComponent,
        CompanySearchSubStepComponent,
        CompanySearchDetailsSubStepComponent
    ],
    exports: [
        SignupStepComponent
    ]
})
export class SignupStepModule { }
