<h2>{{'REGISTRATION.COMPANY_STEP.DESCRIPTION.TITLE' | translate}}</h2>
<h5>{{'REGISTRATION.COMPANY_STEP.DESCRIPTION.SUB_TITLE' | translate}}</h5>

<div class="layout-section">
  <form [formGroup]="form" novalidate autocomplete="off">

    <p *ngIf="isEligibleForStartupOffer">{{'REGISTRATION.COMPANY_STEP.DESCRIPTION.BOX_PLAN_PROSTARTUP_FIRST' | translate}}</p>

    <!-- Generic Registration Number -->
    <soldo-field-layout
      [control]="form.controls['description']"
      fieldName="description"
      cypressSelector="Company_TextInput_BusinessDescription"
      label="REGISTRATION.COMPANY_STEP.DESCRIPTION.DESC_LABEL"
      [hint]="!isEligibleForStartupOffer ? 'REGISTRATION.COMPANY_STEP.DESCRIPTION.DESC_HELP' : ''">

      <ng-container input>
        <textarea input
          name="description"
          formControlName="description"
          type="text"
          maxlength="{{companyDescriptionMaxlength}}"
          placeholder=""></textarea>
          <character-counter [maxlength]="companyDescriptionMaxlength" [control]="form.controls['description']"></character-counter>
      </ng-container>

      <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error errorName="minlength">
          {{ 'REGISTRATION.COMPANY_STEP.DESCRIPTION.MIN_LENGTH' | translate : { value: form.get('description').errors?.minlength?.requiredLength} }}
        </soldo-field-error>
        <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value: form.get('description').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

  </form>
</div>

<div class="form-page-CTA-row">
  <button type="button" (click)="previousStep()"
    data-cy="Signup_Button_PreviousStepButton">
    {{ 'REGISTRATION.COMPANY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
  </button>
  <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}" (click)="nextStep()"
    data-cy="Signup_Button_NextStepButton">{{ 'REGISTRATION.COMPANY_STEP.NEXT_SUB_STEP_BUTTON' | translate }}</button>
</div>
