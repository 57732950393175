<h3  class="title">{{'REGISTRATION.COMPANY_STEP.DESCRIPTION.TITLE' | translate}}</h3>
<p class="subtitle">{{'REGISTRATION.COMPANY_STEP.DESCRIPTION.SUB_TITLE' | translate}}</p>

<form [formGroup]="form" novalidate autocomplete="off">

  <div  *ngIf="isEligibleForStartupOffer" class="description-box">
    <p>{{'REGISTRATION.COMPANY_STEP.DESCRIPTION.BOX_PLAN_PROSTARTUP_FIRST' | translate}}</p>
  </div>

  <!-- Generic Registration Number -->
  <soldo-field-layout class="description-field"
    [control]="form.controls['description']"
    fieldName="description"
    [required]="true"
    cypressSelector="Company_TextInput_BusinessDescription"
    label="REGISTRATION.COMPANY_STEP.DESCRIPTION.DESC_LABEL">

    <ng-container input>
      <p class="help" *ngIf="!isEligibleForStartupOffer">
        {{ 'REGISTRATION.COMPANY_STEP.DESCRIPTION.DESC_HELP'  | translate }}
      </p>
      <textarea input
        name="description"
        formControlName="description"
        type="text" class="form-control"
        placeholder=""></textarea>
    </ng-container>

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error errorName="minlength">
        {{ 'REGISTRATION.COMPANY_STEP.DESCRIPTION.MIN_LENGTH' | translate : { value: form.get('description').errors?.minlength?.requiredLength} }}
      </soldo-field-error>
      <soldo-field-error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value: form.get('description').errors?.maxlength?.requiredLength} }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

</form>

<!-- Sub-Step Navigation -->
<div class="row mt-2 signUpButtons">

  <div class="col-6">
    <button type="button" class="btn gray-text go-back" (click)="previousStep()" [disabled]="loading" data-cy="Signup_Button_PreviousStepButton">
      <i class="md-icon">arrow_right_alt</i>
      <span>{{ 'REGISTRATION.COMPANY_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}</span>
    </button>
  </div>

  <div class="col text-right">
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="loading"
      [ngClass]="{ 'in-progress': loading}" data-cy="Signup_Button_NextStepButton">
      {{ 'REGISTRATION.COMPANY_STEP.NEXT_SUB_STEP_BUTTON' | translate}}
    </button>
  </div>

</div>
