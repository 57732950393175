<h2>{{'REGISTRATION.SIGNUP_STEP.ABOUT_YOU.TITLE' | translate}}</h2>

<form [formGroup]="form" novalidate autocomplete="off">

  <soldo-field-layout
  [control]="form.controls['firstName']"
  fieldName="firstName"
  cypressSelector="Signup_TextInput_LeadFirstName"
  label="REGISTRATION.SIGNUP_STEP.ABOUT_YOU.FIRST_NAME_LABEL">

    <input input
    type="text"
    name="firstName"
    formControlName="firstName"
    placeholder="{{ 'REGISTRATION.SIGNUP_STEP.ABOUT_YOU.FIRST_NAME_PLACEHOLDER' | translate }}" />

    <ng-container errorMessage>
      <soldo-field-error errorName="required">{{ 'REGISTRATION.SIGNUP_STEP.ABOUT_YOU.FIRST_NAME_REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error errorName="maxlength">
        {{ 'MAX_LENGHT' | translate : { value: form.get('firstName').errors?.maxlength?.requiredLength} }}
      </soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <soldo-field-layout
  [control]="form.controls['lastName']"
  fieldName="lastName"
  cypressSelector="Signup_TextInput_LeadLastName"
  label="REGISTRATION.SIGNUP_STEP.ABOUT_YOU.LAST_NAME_LABEL">

    <input input
    type="text"
    name="lastName"
    formControlName="lastName"
    placeholder="{{ 'REGISTRATION.SIGNUP_STEP.ABOUT_YOU.LAST_NAME_PLACEHOLDER' | translate }}" />

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REGISTRATION.SIGNUP_STEP.ABOUT_YOU.LAST_NAME_REQUIRED' | translate }}</soldo-field-error>
      <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value:
        form.get('lastName').errors?.maxlength?.requiredLength} }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

  <div class="form-page-CTA-row">
    <button type="button" (click)="previousStep()" data-cy="Signup_Button_PreviousStepButton">
      {{ 'REGISTRATION.SIGNUP_STEP.PREVIOUS_SUB_STEP_BUTTON' | translate }}
    </button>
    <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}" (click)="nextStep()"
      data-cy="Signup_Button_NextStepButton">{{ 'REGISTRATION.SIGNUP_STEP.NEXT_SUB_STEP_BUTTON' | translate }}</button>
  </div>
</form>
