
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { RegistrationDataService } from './registrationData.service';
import { UBOProvisioningChoiceType } from '../models/registration.model';

export enum REGISTRATION_STEP_ID {
    WELCOME = "WELCOME",
    COMPANY = "COMPANY INFO",
    DIRECTOR = "LEGAL REPRESENTATIVE",
    ADMIN = "ACCOUNT ADMIN",
    UBO_CHOICE = "BENEFICIAL OWNER CHOICE",
    UBO_DETAILS = "BENEFICIAL OWNER DETAILS",
    EXPECTED_ACTIVITY = "EXPECTED ACTIVITY",
    TERMS = "TERMS AND CONDITIONS"
}
export interface RegistrationStep {
    id: REGISTRATION_STEP_ID;
    label: string;
    linkRoute: () => string;
    active: boolean;
    maximumSubsteps: number;
    isEnabled: () => boolean;
}

// eslint-disable-next-line no-shadow
export enum NavigationDirection {
    BACKWARD = 0,
    FORWARD = 1
}

@Injectable()
export class StepService {

    private lastNavigationDirection: NavigationDirection = NavigationDirection.FORWARD;
    private registrationStepsOrder: RegistrationStep[] = [];
    private registrationFlow: RegistrationStep[] = [];

    stepChanged: EventEmitter<RegistrationStep> = new EventEmitter();
    registrationSteps: EventEmitter<RegistrationStep[]> = new EventEmitter();
    progressBarActivation: EventEmitter<boolean> = new EventEmitter();
    stepProgressChanged: EventEmitter<number> = new EventEmitter();

    welcomeStep: RegistrationStep = {
        id: REGISTRATION_STEP_ID.WELCOME,
        label: 'NAV.WELCOME',
        linkRoute: () => '/registration/welcome',
        active: false,
        maximumSubsteps: 7,
        isEnabled: () => true
    };
    companyStep: RegistrationStep = {
        id: REGISTRATION_STEP_ID.COMPANY,
        label: 'NAV.COMPANY',
        linkRoute: () => '/registration/company',
        active: false,
        maximumSubsteps: 6,
        isEnabled: () => true
    };
    directorStep: RegistrationStep = {
        id: REGISTRATION_STEP_ID.DIRECTOR,
        label: 'NAV.LEGAL_REP',
        linkRoute: () => '/registration/director',
        active: false,
        maximumSubsteps: 1,
        isEnabled: () => true
    };
    adminStep: RegistrationStep = {
        id: REGISTRATION_STEP_ID.ADMIN,
        label: 'NAV.ACCOUNT',
        linkRoute: () => '/registration/account',
        active: false,
        maximumSubsteps: 1,
        isEnabled: () => {
            return !this.registrationDataService.isSoleTrader();
        }
    };
    uboChoiceStep: RegistrationStep = {
        id: REGISTRATION_STEP_ID.UBO_CHOICE,
        label: 'NAV.UBOCHOICE',
        linkRoute: () => '/registration/beneficial-owners',
        active: false,
        maximumSubsteps: 1,
        isEnabled: () => {
            return this.registrationDataService.getEmoneyLicenseIssuer() !== 'SFS-UK';
        }
    };
    uboDetailsStep: RegistrationStep = {
        id: REGISTRATION_STEP_ID.UBO_DETAILS,
        label: 'NAV.UBODETAILS',
        linkRoute: () => '/registration/beneficial-owners-details',
        active: false,
        maximumSubsteps: 1,
        isEnabled: () => {
            const isChoiceEnabled = this.uboChoiceStep.isEnabled();
            const uboProvisioninChoice = this.registrationDataService.getUBOProvisioningChoice();
            return isChoiceEnabled && !([UBOProvisioningChoiceType.EMAIL, UBOProvisioningChoiceType.NONE].includes(uboProvisioninChoice));
        }
    };
    expectedActivityStep: RegistrationStep = {
        id: REGISTRATION_STEP_ID.EXPECTED_ACTIVITY,
        label: 'NAV.EXPECTED',
        linkRoute: () => '/registration/expected-activity',
        active: false,
        maximumSubsteps: 5,
        isEnabled: () => true
    };
    termsStep: RegistrationStep = {
        id: REGISTRATION_STEP_ID.TERMS,
        label: 'NAV.TERMS',
        linkRoute: () => '/registration/terms-conditions',
        active: false,
        maximumSubsteps: 1,
        isEnabled: () => true
    };

    constructor(
        private router: Router,
        private registrationDataService: RegistrationDataService,
        private logger: NGXLogger) {

        // All possible steps in order
        this.registrationStepsOrder = [
            this.welcomeStep,
            this.companyStep,
            this.directorStep,
            this.adminStep,
            this.uboChoiceStep,
            this.uboDetailsStep,
            this.expectedActivityStep,
            this.termsStep
        ];

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            const step = this.registrationFlow.find(s => s.linkRoute() == event.url);
            if (step) {
                this.setActiveStep(step);
            }
        });
    }

    init(): void {
        this.recalculateRegistrationFlow();
        this.registrationDataService.applicationDataSubmittedEventEmitter.subscribe(() => {
            this.recalculateRegistrationFlow();
        });
    }

    recalculateRegistrationFlow() {
        this.registrationFlow = this.registrationStepsOrder.filter(s => s.isEnabled());
        this.logger.debug("Recalculating Registration Flow:", this.registrationFlow.map(s => s.id));
        this.registrationSteps.emit(this.registrationFlow);
    }

    getRegistrationFlowSnapshot(): RegistrationStep[] {
        return [...this.registrationFlow];
    }

    getFirstStep(): RegistrationStep {
        return this.registrationFlow[0];
    }

    getActiveStep(): RegistrationStep {
        return this.registrationFlow.find(s => s.active);
    }

    getNextStep(): RegistrationStep {
        const activeIndex = this.registrationFlow.findIndex(s => s.active);
        if(activeIndex >= this.registrationFlow.length - 1) {
            return null;
        }
        return this.registrationFlow[activeIndex + 1];
    }

    getPreviousStep(): RegistrationStep {
        const activeIndex = this.registrationFlow.findIndex(s => s.active);
        if(activeIndex <= 0) {
            return this.getFirstStep();
        }
        return this.registrationFlow[activeIndex - 1];
    }

    navigateToNextStep(): Promise<boolean> {
        this.lastNavigationDirection = NavigationDirection.FORWARD;
        return this.router.navigate([this.getNextStep().linkRoute()]);
    }

    navigateToPreviousStep(): Promise<boolean> {
        this.lastNavigationDirection = NavigationDirection.BACKWARD;
        return this.router.navigate([this.getPreviousStep().linkRoute()]);
    }

    getLastNavigationDirection(): NavigationDirection {
        return this.lastNavigationDirection;
    }

    setActiveStep(step: RegistrationStep) {
        this.registrationFlow.forEach(s => {
            if (s !== step) {
                s.active = false;
            }
        });
        step.active = true;
        this.registrationSteps.emit(this.registrationFlow);
        this.stepChanged.emit(step);
    }

    updateStepProgress(progress: number) {
        this.stepProgressChanged.emit(progress);
    }
}
