import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'soldo-tooltip',
    templateUrl: './soldo-tooltip.component.html',
    styleUrl: './soldo-tooltip.component.scss'
})
export class SoldoTooltipComponent implements OnInit {
    @Input() text: string;
    @Input() position: string;

    verticalPosition: string = "bottom";
    horizontalPosition: string = "right";

    ngOnInit(): void {
        if(this.position){
            this.verticalPosition = ["top", "bottom"].find(p => this.position.includes(p)) || this.verticalPosition;
            this.horizontalPosition = ["left", "right"].find(p => this.position.includes(p)) || this.horizontalPosition;
        }
    }
}
