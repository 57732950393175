import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { NGXLogger } from 'ngx-logger';
import { filter, map } from 'rxjs/operators';
import { IAggregatedRequest } from '../shared/services/requestAggregator.service';
import { ComplianceStatusContext, IdentityVerificationMetadata } from '../shared/models/registration.model';
import { RegistrationDataService } from '../shared/services/registrationData.service';

@Injectable({
    providedIn: 'root'
})
export class ApiCallsInterceptorService implements HttpInterceptor {

    constructor(
        @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
        private readonly logger: NGXLogger,
        private readonly registrationData: RegistrationDataService) {

        this.logger.debug("Api Calls Mock Interceptor starting up...");
        this.logger.debug("Global switch is", this.appConfig.apiCallsMockConfig.GLOBAL ? "ON" : "OFF");
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const switchPanel = this.appConfig.apiCallsMockConfig;
        const env = this.appConfig.env;

        if (["dev", "stag"].includes(env) && switchPanel.GLOBAL) {
            this.logger.debug("Intercepting...");

            // API calls bypass handlers

            // ...

            // API calls response override handlers

            let handlingObservable: Observable<HttpEvent<any>> = next.handle(request);

            if (switchPanel.forceMitekAsIdvProvider) {
                handlingObservable = this.forceMitekAsIdvProvider(request, handlingObservable);
            }

            if (switchPanel.forceApplicantOffIdv) {
                handlingObservable = this.forceApplicantOffIdv(request, handlingObservable);
            }

            return handlingObservable;
        }

        return next.handle(request);
    }

    private forceApplicantOffIdv(request: HttpRequest<any>, prev: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> {
        if (!request.url.includes(this.appConfig.aggregatorUrlEndpoint)) {
            return prev;
        }

        const aggregatedReqBody = (request.body as IAggregatedRequest)?.aggregate;
        const apiServiceName = Object.keys(aggregatedReqBody)?.[0];
        const methodReqBody = aggregatedReqBody[apiServiceName]?.['rid-0'];
        const applicant = this.registrationData.getContacts().find(c => c.ContactIsPrimaryLead__c);

        if (apiServiceName !== "soldoSalesforceRestService" || methodReqBody.methodName !== "getComplianceStatus" || !applicant) {
            return prev;
        }

        this.logger.debug('Call intercepted for forceApplicantOutOfIdv', request);

        return prev.pipe(
            filter((event: HttpEvent<any>) => (event instanceof HttpResponse)),
            map((e: HttpResponse<any>) => {

                const originalResponseBody = e.body;
                const originalMethodResponse = originalResponseBody.aggregated?.soldoSalesforceRestService?.['rid-0'];
                const originalComplianceStatus = (originalMethodResponse?.result as ComplianceStatusContext);

                const overrides = {
                    body: {
                        aggregated: {
                            soldoSalesforceRestService: {
                                "rid-0": {
                                    ...originalMethodResponse,
                                    result: {
                                        ...originalComplianceStatus,
                                        identityVerificationMetadataList: []
                                    }
                                }
                            }
                        }
                    }
                };

                for (const idvPerson of originalComplianceStatus.identityVerificationMetadataList) {
                    if (idvPerson.email !== applicant.Email) {
                        overrides.body.aggregated
                            .soldoSalesforceRestService['rid-0']
                            .result.identityVerificationMetadataList.push(idvPerson);
                    }
                }

                if (overrides.body.aggregated
                    .soldoSalesforceRestService['rid-0']
                    .result.identityVerificationMetadataList.length === 0) {
                    const fakeperson: IdentityVerificationMetadata = {
                        personId: 0,
                        name: "Developer",
                        surname: "Devsson",
                        email: "developer@soldo.com",
                        phone: "700000000",
                        superAdmin: true,
                        legalOwner: false,
                        kycPass: false,
                        hooYuRequestLink: undefined,
                        identityVerificationSteps: "POA",
                        providerName: 'ONFIDO',
                        workflowRunId: "#"
                    };
                    overrides.body.aggregated
                        .soldoSalesforceRestService['rid-0']
                        .result.identityVerificationMetadataList.push(fakeperson);
                }

                const overridden = e.clone(overrides);

                this.logger.debug("Response overridden by ", overridden);

                return overridden;
            })
        );
    }

    private forceMitekAsIdvProvider(request: HttpRequest<any>, prev: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> {

        if (!request.url.includes(this.appConfig.aggregatorUrlEndpoint)) {
            return prev;
        }

        const aggregatedReqBody = (request.body as IAggregatedRequest)?.aggregate;
        const apiServiceName = Object.keys(aggregatedReqBody)?.[0];
        const methodReqBody = aggregatedReqBody[apiServiceName]?.['rid-0'];

        const methodsToIntercept = ["resumeBusinessApplication", "getComplianceStatus"];

        if (apiServiceName !== "soldoSalesforceRestService" || !methodsToIntercept.includes(methodReqBody.methodName)) {
            return prev;
        }

        this.logger.debug('Call intercepted for forceMitekAsIdvProvider', request);

        return prev.pipe(
            filter((event: HttpEvent<any>) => (event instanceof HttpResponse)),
            map((e: HttpResponse<any>) => {

                if (methodReqBody.methodName === "resumeBusinessApplication") {
                    return this.forceMitekAsProviderInResume(e);
                }

                if (methodReqBody.methodName === "getComplianceStatus") {
                    return this.forceMitekAsProviderInGetComplianceStatus(e);
                }

                return e;

            })
        );
    }

    forceMitekAsProviderInGetComplianceStatus(e: HttpResponse<any>): HttpResponse<any> {
        const originalResponseBody = e.body;
        const originalResult = originalResponseBody.aggregated?.soldoSalesforceRestService?.['rid-0']?.result;
        const originalIdvMetaList = (originalResult as ComplianceStatusContext)?.identityVerificationMetadataList;

        const overrides = {
            body: {
                aggregated: {
                    soldoSalesforceRestService: {
                        "rid-0": {
                            ...originalResponseBody.aggregated?.soldoSalesforceRestService?.['rid-0'],
                            result: {
                                ...originalResult,
                                identityVerificationMetadataList: []
                            }
                        }
                    }
                }
            }
        };

        for (const idvPerson of originalIdvMetaList) {
            overrides.body.aggregated
                .soldoSalesforceRestService['rid-0']
                .result
                .identityVerificationMetadataList.push({
                    ...idvPerson,
                    providerName: 'HOOYU',
                    hooYuRequestLink: 'https://google.com',
                    identityVerificationSteps: "POA"
                });
        }

        const overridden = e.clone(overrides);

        this.logger.debug("Response overridden by ", overridden);

        return overridden;

    }

    forceMitekAsProviderInResume(e: HttpResponse<any>): HttpResponse<any> {
        const originalResponseBody = e.body;
        const originalResult = originalResponseBody.aggregated?.soldoSalesforceRestService?.['rid-0']?.result;
        const originalIdvMetaList = (originalResult?.complianceStatus as ComplianceStatusContext)?.identityVerificationMetadataList;

        const overrides = {
            body: {
                aggregated: {
                    soldoSalesforceRestService: {
                        "rid-0": {
                            ...originalResponseBody.aggregated?.soldoSalesforceRestService?.['rid-0'],
                            result: {
                                ...originalResult,
                                complianceStatus: {
                                    ...originalResult.complianceStatus,
                                    identityVerificationMetadataList: []
                                }
                            }
                        }
                    }
                }
            }
        };

        for (const idvPerson of originalIdvMetaList) {
            overrides.body.aggregated
                .soldoSalesforceRestService['rid-0']
                .result.complianceStatus
                .identityVerificationMetadataList.push({
                    ...idvPerson,
                    providerName: 'HOOYU',
                    hooYuRequestLink: 'https://google.com',
                    identityVerificationSteps: "POA"
                });
        }

        const overridden = e.clone(overrides);

        this.logger.debug("Response overridden by ", overridden);

        return overridden;
    }
}


