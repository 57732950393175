import { Inject, Injectable } from "@angular/core";
import { AppConfig, APP_CONFIG } from "@src/app/app-config.module";
import { NGXLogger } from "ngx-logger";
import { Application, ComplianceStatus } from "../models/registration.model";
import { RegistrationDataService } from "./registrationData.service";
import { SoldoCookieService } from "./soldo-cookie.service";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

export interface Status {
    key: RegistrationStatusKey;
    resumeFromState: () => string;
}

export class RegistrationStatusMap {

    constructor(
        private readonly registrationDataService: RegistrationDataService,
        private readonly config: AppConfig) { }

    UndefinedStatus: Status = {
        key: "UndefinedStatus",
        resumeFromState: () => "registration/welcome"
    };
    Started: Status = {
        key: "Started",
        resumeFromState: () => "registration/welcome"
    };
    Details: Status = {
        key: "Details",
        resumeFromState: () => "registration/company"
    };
    AccountCreated: Status = {
        key: "AccountCreated",
        resumeFromState: () => "registration/director"
    };
    DirectorCreated: Status = {
        key: "DirectorCreated",
        resumeFromState: () => "registration/account"
    };
    AdminCreated: Status = {
        key: "AdminCreated",
        resumeFromState: () => {
            if(this.registrationDataService.getEmoneyLicenseIssuer() == "SFS-IRL") {
                return "registration/beneficial-owners";
            } else {
                return "registration/expected-activity";
            }
        }
    };
    BeneficialOwnerCreated: Status = {
        key: "BeneficialOwnerCreated",
        resumeFromState: () => {
            const account = this.registrationDataService.getAccount();
            if(account?.ThirdPartyLoadSource__c) {
                return "registration/terms-conditions";
            } else {
                return "registration/expected-activity";
            }
        }
    };
    Terms: Status = {
        key: "Terms",
        resumeFromState: () => {
            const complianceStatus = this.registrationDataService.getComplianceStatus();
            let route = "static/thank-you";

            switch (complianceStatus) {
                // Applicant's Pre-IDV Page
                case (ComplianceStatus.IDV_REQUIRED): {
                    const complianceContext = this.registrationDataService.getComplianceContext();
                    route = complianceContext.identityVerificationMetadataList?.length > 0 ? "registration/verify-your-identity" : "static/thank-you";
                    break;
                }
                // Document upload page
                case ComplianceStatus.DOC_REQUIRED: {
                    const compliaceDocsRequired = this.registrationDataService.getKYCDocUploadData();
                    route = compliaceDocsRequired.length > 0 ? "docupload" : "static/thank-you";
                    break;
                }
            }

            return route;
        }
    };
}

export type RegistrationStatusKey = keyof RegistrationStatusMap;

const STATUS_ORDER:RegistrationStatusKey[] = [
    "Started",
    "Details",
    "AccountCreated",
    "DirectorCreated",
    "AdminCreated",
    "BeneficialOwnerCreated",
    "Terms"
];

@Injectable()
export class StatusService  {
    private readonly statusMap: RegistrationStatusMap;
    private currentFsSubmissionStatus: Status = null;
    private readonly statusArray: RegistrationStatusKey[] = [
        "Started",
        "Details",
        "AccountCreated",
        "DirectorCreated",
        "AdminCreated",
        "BeneficialOwnerCreated",
        "Terms"
    ];

    constructor(
        private readonly logger: NGXLogger,
        private readonly registrationDataService: RegistrationDataService,
        private readonly cookieService: SoldoCookieService,
        @Inject(APP_CONFIG) private readonly config: AppConfig) {

        this.statusMap = new RegistrationStatusMap(this.registrationDataService, this.config);

        this.registrationDataService.statusAdvancedEventEmitter.subscribe((message: string) => {
            this.logger.debug("Registration Status Advanced Event", message);
            if (this.statusMap[message]) {
                this.currentFsSubmissionStatus = this.statusMap[message];
                this.logger.debug("Status advanced to", this.currentFsSubmissionStatus);
            }
        });
    }

    init(application: Application) {
        const resumeStatusKey: string = application.account?.FsApplicationSubmissionStatus__c || application.lead?.FsApplicationSubmissionStatus__c;
        this.currentFsSubmissionStatus = this.statusMap[resumeStatusKey] || this.statusMap.UndefinedStatus;
    }

    getStatusByPage(uri: string): string {
        let statusName = uri;
        for (const i in this.statusArray) {
            const status: Status = this.statusMap[this.statusArray[i]];
            if (uri.indexOf(status.resumeFromState()) > -1) {
                statusName = status.key;
                break;
            }
        }
        return statusName;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let allow: boolean = true;

        if (this.currentFsSubmissionStatus
            && this.currentFsSubmissionStatus.key === "Terms"
            && !childRoute.data?.canAccessAfterTerms) {
            this.logger.warn("Cannot access forms when status is Terms!");
            allow = false;
        }

        return allow;
    }

    isResumable(): boolean {
        return [
            this.statusMap.Details.key,
            this.statusMap.AccountCreated.key,
            this.statusMap.DirectorCreated.key,
            this.statusMap.AdminCreated.key
        ].includes(this.currentFsSubmissionStatus?.key);
    }

    _getStatusIndex(statusKey: string): number {
        return STATUS_ORDER.findIndex(s => s == statusKey);
    }

    compareStatusKey(statusAKey: keyof RegistrationStatusMap, statusBKey: keyof RegistrationStatusMap): number {
        return this._getStatusIndex(statusAKey) - this._getStatusIndex(statusBKey);
    }

    showResumeOverlay(): boolean {
        return [
            this.statusMap.Details.key,
            this.statusMap.AccountCreated.key,
            this.statusMap.DirectorCreated.key,
            this.statusMap.AdminCreated.key,
            this.statusMap.Terms.key
        ].includes(this.currentFsSubmissionStatus?.key)
        && this.currentFsSubmissionStatus.resumeFromState() != "success";
    }

    getFsSubmissionStatus(): Status {
        return this.currentFsSubmissionStatus;
    }

    getFsSubmissionStatusValidIncrement(newStatus: RegistrationStatusKey): RegistrationStatusKey {
        const fsSubmissionStatusIndex = this._getStatusIndex(this.currentFsSubmissionStatus.key);
        const newFsSubmissionStatusIndex = this._getStatusIndex(newStatus);
        return STATUS_ORDER[Math.max(fsSubmissionStatusIndex, newFsSubmissionStatusIndex)];
    }

    setStatusIncrementAfterSubmission(submittedStatus: RegistrationStatusKey) {
        this.currentFsSubmissionStatus = this.statusMap[this.getFsSubmissionStatusValidIncrement(submittedStatus)];
    }

    resetAndStartOver() {
        const country = this.registrationDataService.getCompanyCountry();
        let startOverUrl = this.config.invalidPlanOfferRedirectUrl[country];
        if (!startOverUrl) {
            startOverUrl = this.config.invalidPlanOfferRedirectUrl.fallback;
        }
        const redirectURL = new URL(startOverUrl);
        redirectURL.searchParams.append("startover", "true");

        this.cookieService.deleteRegistrationIdCookie();
        this.cookieService.deleteEmailVerificationToken();

        window.location.assign(redirectURL.toString());
    }
}
