import { Component, EventEmitter } from '@angular/core';
import { CompanySubStepOutboundAction, CompanySubStepType } from '../../company-step.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AmplitudeService } from '../../../../shared/services/amplitude.service';
import { GoogleTagManagerService } from '../../../../shared/services/google-tag-manager.service';
import { Account, AccountSalesChannelOption } from '../../../../shared/models/registration.model';
import { DefaultOutboundActions } from '../../../../shared/models/app-structure.model';

@Component({
    selector: 'app-company-sales-channel',
    templateUrl: './company-sales-channel.component.html',
    styleUrls: ['./company-sales-channel.component.scss']
})
export class CompanySalesChannelComponent extends CompanySubStepType {
    subStepName: string = "Company Sales Channel";

    loading: boolean = true;

    form: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private amplitudeService: AmplitudeService,
        private gtmService: GoogleTagManagerService) {
        super();
        this.controlActionEventEmitter = new EventEmitter<CompanySubStepOutboundAction>();
    }

    init(d: Partial<Account>): void {

        this.form = this.fb.group({
            "retailChannel": [d.SalesChannels__c?.includes(AccountSalesChannelOption.RETAIL)],
            "onlineChannel": [d.SalesChannels__c?.includes(AccountSalesChannelOption.ONLINE)],
            "otherChannelSelected": [d.SalesChannels__c?.includes(AccountSalesChannelOption.OTHER)]
        });

        if (d.SalesChannels__c?.includes(AccountSalesChannelOption.OTHER)) {
            this.showFreeTextOption(d.SalesChannelsDescription__c);
        }

        this.form.get("otherChannelSelected").valueChanges.subscribe((value) => {
            if (value) {
                this.showFreeTextOption();
                this.amplitudeService.trackFreeTextSurveyOptionSelected(this.subStepName);
            } else if (this.form.get("otherChannel")) {
                this.hideFreeTextOption();
            }
        });

        this.loading = false;
    }

    showFreeTextOption(value: string = null) {
        if (!this.form.get("otherChannel")) {
            this.form.addControl("otherChannel", new UntypedFormControl(value, [
                Validators.required,
                Validators.maxLength(255)
            ]));
        }
    }

    hideFreeTextOption() {
        this.form.removeControl("otherChannel");
    }

    isValid(): boolean {
        this.form.markAllAsTouched();

        const options = [
            this.form.value['retailChannel'],
            this.form.value['onlineChannel'],
            this.form.value['otherChannelSelected']
        ];

        if (options.every(v => v !== true)) {
            this.form.setErrors({
                selectOneOrMore: true
            });
            return false;
        }

        return this.form.valid;
    }

    getData(): Partial<Account> {
        const formValues = this.form.value;

        const selection: string[] = [];
        if (formValues.retailChannel) {
            selection.push(AccountSalesChannelOption.RETAIL);
        }
        if (formValues.onlineChannel) {
            selection.push(AccountSalesChannelOption.ONLINE);
        }
        if (formValues.otherChannelSelected) {
            selection.push(AccountSalesChannelOption.OTHER);
        }

        return {
            SalesChannels__c: selection.join(";"),
            SalesChannelsDescription__c: formValues.otherChannelSelected ? formValues.otherChannel : null
        };
    }

    previousStep(): void {
        this.controlActionEventEmitter.emit({
            action: DefaultOutboundActions.PREVIOUS,
            data: null
        });
    }

    nextStep(): void {
        if (this.isValid()) {
            if (this.form.controls['otherChannel']) {
                this.amplitudeService.trackFreeTextSurveyOptionSubmitted(this.subStepName);
            }
            this.controlActionEventEmitter.emit({
                action: DefaultOutboundActions.NEXT,
                data: this.getData()
            });
        }
    }

    trackSubStepViewed(): void {
        this.amplitudeService.trackSubStepViewed(this.subStepName);
        this.gtmService.trackSubStepViewed(this.subStepName);
    }

    trackSubStepCompleted(): void {
        this.amplitudeService.trackSubStepCompleted(this.subStepName);
        this.gtmService.trackSubStepCompleted(this.subStepName);
    }

}
