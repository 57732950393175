import { ComponentFactoryResolver, Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Type, ViewContainerRef } from '@angular/core';
import { SubStepComponentType } from '../models/app-structure.model';

@Directive({
  selector: '[substep-container]'
})
export class SubstepContainerDirective implements OnChanges, OnInit {

    @Input('substep-container')
    substepComponentType: Type<SubStepComponentType<any, any>>;

    @Output('component-created')
    componentCreatedEmitter: EventEmitter<SubStepComponentType<any, any>> = new EventEmitter<SubStepComponentType<any, any>>();

    constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this._renderSubStep(this.substepComponentType);
    }

    private _renderSubStep(subStepType: Type<SubStepComponentType<any, any>>) {
        this.viewContainerRef.clear();
        let componentInstace;
        if(subStepType) {
            let componentFactory = this.componentFactoryResolver.resolveComponentFactory(subStepType);
            componentInstace = this.viewContainerRef.createComponent(componentFactory).instance;
        }
        this.componentCreatedEmitter.emit(componentInstace);
    }

}
