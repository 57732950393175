
import {from as observableFrom, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import { HereApiTokenService } from "./here-api-token.service";

import { NGXLogger } from "ngx-logger";

@Injectable()
export class HereApiTokenInterceptor implements HttpInterceptor {

    constructor(
        private logger: NGXLogger,
        private auth: HereApiTokenService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes('hereapi.com')) {
            return observableFrom(this.handleAccess(request, next));
        } else {
            return next.handle(request);
        }
    }

    private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
        const token = await this.auth.getToken();
        const changedRequest = request.clone({
            setHeaders: {
                Authorization: "Bearer " + token
            }
        });
        return next.handle(changedRequest).toPromise();
    }
}
