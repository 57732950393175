import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppConfig, APP_CONFIG } from '@src/app/app-config.module';
import { TranslateService } from '@ngx-translate/core';
import * as amplitude from '@amplitude/analytics-browser';
import { NGXLogger } from 'ngx-logger';
import { Application, ApplicationSegment, ComplianceProofDocumentType, ComplianceProofType, ComplianceStatus, UBOProvisioningChoiceType } from '../models/registration.model';
import { RegistrationDataService } from './registrationData.service';
import { SoldoAffiliateData, SoldoCookieService, SoldoMarketingParameters } from './soldo-cookie.service';

export type AmplitudeEventCategory = "Navigation" | "Milestone" | "Interaction" | "Integration" | "Error";

export interface AmplitudeEventProperties {
    app?: "Registration Webapp";
    category: AmplitudeEventCategory,
    subcategory?: string,
    onPage?: string,
    browserLanguage?: string,
    appLanguage?: string,
    selectedPlan?: string,
    selectedOffer?: string,
    [key: string]: any
}

@Injectable()
export class AmplitudeService {

    private staticMode: boolean = false;

    private nextFormId: number = 0;

    private uniquenessHelper: object = {};

    private affiliateParams: SoldoAffiliateData;
    private utmParams: SoldoMarketingParameters;

    private readonly CATEGORY_DOCUPLOAD = "Document Upload";
    private readonly CATEGORY_ADDRESS_FORM = "Address Form";
    private readonly CATEGORY_COMPANY_SEARCH = "Company Search";

    constructor(
        private cookieSerivice: SoldoCookieService,
        private dataService: RegistrationDataService,
        private translate: TranslateService,
        private logger: NGXLogger,
        @Inject(APP_CONFIG) private appConfig: AppConfig) {

    }

    initAmplitude(appVersion: string) {
        try {
            this.logger.debug("Initializing Amplitude with", this.appConfig.amplitudeProjectId);
            const userId = this.cookieSerivice.readRegistrationIdCookie();
            this.staticMode = !userId;

            amplitude.init(this.appConfig.amplitudeProjectId, {
                userId: userId || undefined,
                autocapture: false,
                appVersion: appVersion,
                logLevel: this.appConfig.env === 'prod' ? amplitude.Types.LogLevel.Error : amplitude.Types.LogLevel.Warn
            }).promise.then(() => {
                this.logger.debug("Amplitude initialized, running post init setup");
                this.postInit();
            });

        } catch (e) {
            this.logger.debug("Couldn't initialize Amplitude", e);
        }
    }


    private postInit() {
        this.cookieSerivice.registrationIdEventEmitter.on("registration-id-replaced", newId => {
            amplitude.setUserId(newId);
        });

        this.dataService.eKYCResultEventEmitter.subscribe((application: Application) => {
            if (application.complianceStatus
                && application.complianceStatus.status == ComplianceStatus.DOC_REQUIRED
                && (application.complianceStatus.docUploadMetadataList || []).length) {
                this.trackEKYCFail(application.complianceStatus.docUploadMetadataList.length);
            } else {
                this.trackEKYCPass();
            }
        });

        this.dataService.statusAdvancedEventEmitter.subscribe((status: string) => {
            if (status === "Terms") {
                this.trackApplicationCompleted(this.dataService.getComplianceStatus() == ComplianceStatus.COMPANY_PROVISIONED);
            }
        });

        this.affiliateParams = this.cookieSerivice.readDedicatedAffiliateTrackingCookie();
        this.utmParams = this.cookieSerivice.readDedicatedUTMCookie();
    }

    getNextFormId(): number {
        return this.nextFormId++;
    }

    public setUserMetrics(metrics: { [key: string]: any }): void {
        const identifyEvent = new amplitude.Identify();
        for (const k in metrics) {
            identifyEvent.set(k, metrics[k]);
        }
        amplitude.identify(identifyEvent);
    }

    public trackEvent(eventName: string, data: { [key: string]: any }, callback: amplitude.Types.EventCallback) {
        amplitude.track(eventName, data).promise.then(callback);
    }

    private eventProperties(customProperties: AmplitudeEventProperties): AmplitudeEventProperties {
        const defaultProperties: AmplitudeEventProperties = {
            app: "Registration Webapp",
            category: "Navigation",
            browserLanguage: this.translate.getBrowserCultureLang(),
            appLanguage: this.translate.currentLang,
            previousPages: document.referrer
        };

        if(!this.staticMode) {
            defaultProperties["selectedPlan"] = this.dataService.getPlanName();
            defaultProperties["selectedOffer"] = this.dataService.getOfferName();
        }

        if (this.utmParams) {
            Object.keys(this.utmParams).forEach(param => {
                defaultProperties[param] = this.utmParams[param];
            });
        }
        if (this.affiliateParams) {
            defaultProperties.affiliateId = this.affiliateParams.affiliateId;
        }
        return {...defaultProperties, ...customProperties};
    }

    public trackPageView(routeSnapshot: ActivatedRouteSnapshot, status: string): void {
        this.logger.debug("Amplitude - tracking page view", routeSnapshot.data['pageName'], status);
        const title = routeSnapshot.data['pageName'] || location.hash.replace('#', '');

        const path = location.hash.replace('#', '');

        if (path != "/") {
            amplitude.track(`Viewed ${title}`, this.eventProperties({
                category: "Navigation",
                registrationStatus: status
            }));
        }
    }

    public trackSignupStarted(): void {
        this.setUserMetrics({
            segment: "Self Serve"
        });
        amplitude.track("Signup Started", this.eventProperties({
            category: "Milestone"
        }));
    }

    public trackSignupCountryAccepted(countryOfIncorporation: string) {
        const eventName = "Selected Country of Incorporation";
        if (!this.uniquenessHelper[eventName]) {
            this.setUserMetrics({
                companyCountry: countryOfIncorporation
            });
            amplitude.track(eventName, this.eventProperties({
                category: "Navigation"
            }));
            this.uniquenessHelper[eventName] = true;
        }
    }

    public trackSignupPrivacyAccepted() {
        const eventName = "Accepted Privacy Policy";
        if (!this.uniquenessHelper[eventName]) {
            amplitude.track(eventName, this.eventProperties({
                category: "Navigation"
            }));
            this.uniquenessHelper[eventName] = true;
        }
    }

    public trackSignupCompleted(numberOfEmployees: number, companyCountry: string): void {

        this.setUserMetrics({
            companyCountry: companyCountry,
            soleTrader: this.dataService.isSoleTrader(),
            numberOfEmployees: this.dataService.getCompanySize(),
            companyType: this.dataService.getCompanyType()
        });

        amplitude.track("Signup Completed", this.eventProperties({
            category: "Milestone",
            numberOfEmployees: numberOfEmployees,
            market: companyCountry
        }));
    }

    public trackApplicationResumed(status: string, segment: ApplicationSegment = "Self Serve"): void {
        this.setUserMetrics({
            segment: segment
        });
        amplitude.track("Application Resumed", this.eventProperties({
            category: "Milestone",
            registrationStatus: status
        }));
    }

    public trackApplicationCompleted(eKycPass: boolean): void {
        amplitude.track("Application Completed", this.eventProperties({
            category: "Milestone",
            eKYCPass: eKycPass
        }));
    }

    public trackEKYCPass(): void {
        amplitude.track("eKYC Check Passed", this.eventProperties({
            category: "Milestone",
            subcategory: "eKYC"
        }));
    }

    public trackEKYCFail(numberOfRequiredDocuments: number): void {
        amplitude.track("eKYC Check Failed", this.eventProperties({
            category: "Milestone",
            subcategory: "eKYC",
            numberOfRequiredDocuments: numberOfRequiredDocuments
        }));
    }

    public trackDocumentUploaded(proofType: ComplianceProofType, documentType: ComplianceProofDocumentType, numFiles: number): void {
        amplitude.track("Document Uploaded", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_DOCUPLOAD,
            documentType: documentType,
            proofType: proofType,
            filesUploaded: numFiles
        }));
    }

    public trackSubStepViewed(subStepName: string) {
        amplitude.track("SubStep Viewed", this.eventProperties({
            category: "Navigation",
            subStepName: subStepName
        }));
    }

    public trackSubStepCompleted(subStepName: string) {
        amplitude.track("SubStep Completed", this.eventProperties({
            category: "Navigation",
            subStepName: subStepName
        }));
    }

    public trackEmailVerificationCodeRequested() {
        amplitude.track("Email Verification Code Requested", this.eventProperties({
            category: "Interaction"
        }));
    }

    public trackFreeEmailDomainErrorTriggered(emailProvider: string) {
        amplitude.track("Free Email Domain Error Triggered", this.eventProperties({
            category: "Interaction",
            domain: emailProvider
        }));
    }

    public trackExperimentActivation(experimentName: string, page: string): void {
        amplitude.track(`${experimentName}ExperimentActivate`, this.eventProperties({
            category: "Integration",
            onPage: page
        }));
    }

    public trackExperimentVariantAssigned(experimentName: string, page: string, variant: number): void {
        amplitude.track(`${experimentName}ExperimentVariantAssigned`, this.eventProperties({
            category: "Integration",
            onPage: page,
            variant: variant
        }));
    }

    public trackAddressSearchPerformed(page: string, formId: string, country: string, numResults: number): void {
        amplitude.track("Address Search Query", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_ADDRESS_FORM,
            onPage: page,
            formId: formId,
            addressCountry: country,
            numResults: numResults
        }));
    }

    public trackAddressSearchResultSelected(page: string, formId: string, country: string, isFormValid: boolean): void {
        amplitude.track("Address Search Result Selected", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_ADDRESS_FORM,
            onPage: page,
            formId: formId,
            addressCountry: country,
            isFormValid: isFormValid
        }));
    }

    public trackAddressFieldEdited(page: string, formId: string, country: string, field: string): void {
        amplitude.track("Address Field Manually Edited", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_ADDRESS_FORM,
            onPage: page,
            formId: formId,
            addressCountry: country,
            field: field
        }));
    }

    public trackAddressSearchResultDiscarded(page: string, formId: string, country: string, field: string): void {
        amplitude.track("Address Search Result Discarded", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_ADDRESS_FORM,
            onPage: page,
            formId: formId,
            addressCountry: country,
            field: field
        }));
    }

    public trackAddressSearchResultSubmitted(page: string, formId: string, country: string): void {
        amplitude.track("Address Search Result Submitted", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_ADDRESS_FORM,
            onPage: page,
            formId: formId,
            addressCountry: country
        }));
    }

    public updateCompanySearchUsageMetric(companySearchResultSubmitted: boolean) {
        this.setUserMetrics({
            usedCompanySearch: companySearchResultSubmitted
        });
    }

    public trackCompanySearchPerformed(searchTerm: string, numResults: number, searchByNumber: boolean = false): void {
        amplitude.track("Company Search Query", this.eventProperties({
            category: "Interaction",
            subcategory: this.CATEGORY_COMPANY_SEARCH,
            searchTerm: searchTerm,
            numResults: numResults,
            searchByNumber: searchByNumber,
            searchTermLenth: searchTerm ? searchTerm.trim().length : 0
        }));
    }

    public trackSoleTraderCategoryErrorVisualized(selectedCompanyType: string): void {
        amplitude.track("Sole Trader Disabled Message Visualized", this.eventProperties({
            category: "Interaction",
            selectedType: selectedCompanyType
        }));
    }

    public trackSignupWithXeroAuthorized(): void {
        amplitude.track("Signup With Xero Authorized", this.eventProperties({
            category: "Integration",
            subcategory: "Xero"
        }));
    }

    public trackIDVLinkClicked(provider: string, callback: amplitude.Types.EventCallback): void {
        amplitude.track(`Clicked on ${provider} Verification Link`, this.eventProperties({
            category: "Interaction"
        })).promise.then(callback);
    }

    public trackBeneficialOwnerInputModeChoice(mode: UBOProvisioningChoiceType): void {
        amplitude.track("Beneficial Owner Input Mode Selected", this.eventProperties({
            category: "Interaction",
            subcategory: "Beneficial Owner",
            chosenInputMode: mode
        }));
    }

    public trackBeneficialOwnersCreated(total: number, isDirector: boolean, totAdmin: number, totAdditional: number): void {
        amplitude.track("Beneficial Owners Saved", this.eventProperties({
            category: "Interaction",
            subcategory: "Beneficial Owner",
            totBeneficialOwners: total,
            totDirectorBO: isDirector ? 1 : 0,
            totAdminBO: totAdmin,
            totAdditional: totAdditional
        }));
    }

    public trackHooYuLivenessCheckStarted(): void {
        amplitude.track("HooYu Liveness Check Started", this.eventProperties({
            category: "Interaction",
            subcategory: "HooYu"
        }));
    }

    public trackHooYuLivenessCheckEnded(): void {
        amplitude.track("HooYu Liveness Check Ended", this.eventProperties({
            category: "Interaction",
            subcategory: "HooYu"
        }));
    }

    public trackHooYuModalOpened(): void {
        amplitude.track("HooYu Modal Opened", this.eventProperties({
            category: "Interaction",
            subcategory: "HooYu"
        }));
    }

    public trackHooYuModalClosed(): void {
        amplitude.track("HooYu Modal Closed", this.eventProperties({
            category: "Interaction",
            subcategory: "HooYu"
        }));
    }

    public trackApplicantIsLegalRepresentativeChoice(isLegalRepresentative: boolean): void {
        amplitude.track("Legal Representative Choice", this.eventProperties({
            category: "Interaction",
            isLegalRepresentative: isLegalRepresentative
        }));

        this.setUserMetrics({ applicantIsLegalRepresentative: isLegalRepresentative });
    }

    public trackFreeTextSurveyOptionSubmitted(subStepName: string): void {
        amplitude.track("Free Text Survey Option Submitted", this.eventProperties({
            category: "Interaction",
            subStepName: subStepName
        }));
    }

    public trackFreeTextSurveyOptionSelected(subStepName: string): void {
        amplitude.track("Free Text Survey Option Selected", this.eventProperties({
            category: "Interaction",
            subStepName: subStepName
        }));
    }

    public trackSaveAndContinueClicked(stepName: string, substepName: string): void {
        amplitude.track("Clicked Save and Continue", this.eventProperties({
            category: "Interaction",
            step: stepName,
            substep: substepName
        }));
    }

    public trackOnfidoWebSdkTraversal(idvStepName: string, isCrossDevice: boolean): void {
        amplitude.track("Onfido Web IDV Traversal", this.eventProperties({
            category: "Interaction",
            subcategory: "Identity Verification",
            idvStepName: idvStepName,
            isCrossDevice: isCrossDevice
        }));
    }

    public trackOnfidoWebSdkError(errorType: string): void {
        amplitude.track("Onfido Web IDV Error", this.eventProperties({
            category: "Error",
            subcategory: "Identity Verification",
            errorType: errorType
        }));
    }

    public trackOnfidoWebSdkIdvUserExit(): void {
        amplitude.track("Onfido Web IDV User Exit", this.eventProperties({
            category: "Interaction",
            subcategory: "Identity Verification"
        }));
    }

    public trackOnfidoWebSdkIdvComplete(): void {
        amplitude.track("Onfido Web IDV Completed", this.eventProperties({
            category: "Interaction",
            subcategory: "Identity Verification"
        }));
    }

}
