import { Component, OnInit, Input, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@src/app/app-config.module';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Contact } from '../../models/registration.model';
import { MentionMeService } from './mention-me.service';

@Component({
    selector: 'mention-me-widget',
    template: '<div *ngIf="enabled" id="mmWrapper"></div>',
    styles: ['#mmWrapper { text-align: center; margin: 50px 0 30px 0;}']
})
export class MentionMeComponent implements OnInit {

    @Input()
    registrationId: string;
    @Input()
    companyName: string;
    @Input()
    primaryLead: Contact;

    enabled: boolean = false;

    constructor(
        private translateService: TranslateService,
        private mentionMeService: MentionMeService,
        @Inject(APP_CONFIG) private appConfig: AppConfig
    ) { }

    ngOnInit() {

        this.enabled = this.mentionMeService.isEnabled;
        if (this.enabled) {
            const params = new HttpParams()
                .set("situation", "WebRegistration")
                .set("email", this.primaryLead.Email)
                .set("firstname", this.primaryLead.FirstName)
                .set("surname", this.primaryLead.LastName)
                .set("fullname", this.companyName)
                .set("customer_id", this.registrationId)
                .set("locale", this.translateService.currentLang === 'en' ? 'en_GB' : 'it_IT');

            this.loadScript(params).then((response) => {
                // loaded.
             });
        }
    }

    private loadScript(params: HttpParams): Promise<any> {
        return this.mentionMeService.loadRefereeTag(params);
    }
}
