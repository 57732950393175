
import {filter} from 'rxjs/operators';
import { Injectable, EventEmitter, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { GoogleTagManagerService } from './google-tag-manager.service';


export interface ExperimentProperty {
    key: string;
    value: any;
}

export const EXP_VAR_COUNTRY_OF_INCORPORATION_KEY = "countryOfIncorporation";

export const DOCS_HEADSUP_EXPERIMENT_KEY = "docs-headsup";
export const DOCS_HEADSUP_EXPERIMENT_VARIANT = "headsup";

@Injectable()
export class ExperimentBridgeService {
    properties: { [key: string]: any } = {};
    propertyInjectedEmitter = new EventEmitter<ExperimentProperty>();

    constructor(
        private zone: NgZone,
        private translate: TranslateService,
        private gtmService: GoogleTagManagerService) {
        window["experimentBridge"] = {
            pushProperty: (key: string, value: any) => {
                return this.pushProperty(key, value);
            },
            getProperty: (key: string) => {
                return this.getProperty(key);
            }
        };
        this.propertyInjectedEmitter.subscribe((e: any) => {
            if (e.key == "i18n") {
                this.translate.setTranslation(e.value.lang, e.value.tx, true);
            }
        });
    }

    public getProperty(key: string): any {
        return this.properties[key];
    }

    public getAllProperties(): { [key: string]: any } {
        return this.properties;
    }

    public pushProperty(key: string, value: any): boolean {
        this.zone.run(() => {
            this.properties[key] = value;
            this.propertyInjectedEmitter.next({
                key: key,
                value: value
            });
        });
        return true;
    }

    public initializeDocuploadHeadsupExperiment(market: string) {
        this.pushProperty(EXP_VAR_COUNTRY_OF_INCORPORATION_KEY, market);
        this.gtmService.emitExperimentInitEvent("DocumentHeadsUpExperiment");
    }

    public subscribeToHeadsUpExperiment(): Observable<ExperimentProperty> {
        return this.propertyInjectedEmitter.pipe(filter(property =>
            property.key == DOCS_HEADSUP_EXPERIMENT_KEY));
    }

    public isDocHeadsUpExperimentActive(): boolean {
        return this.getProperty(DOCS_HEADSUP_EXPERIMENT_KEY) == DOCS_HEADSUP_EXPERIMENT_VARIANT;
    }
}
