import { Component, OnDestroy, OnInit } from '@angular/core';
import { RegistrationDataService } from '@shared/services/registrationData.service';
import { NGXLogger } from 'ngx-logger';
import { OnfidoUIConfig } from './onfido.config';
import { Router } from '@angular/router';
import { AmplitudeService } from '../../shared/services/amplitude.service';
import { I18nService } from '../../shared/services/i18n.service';
import { Handle, Onfido, SdkParameters } from 'onfido-sdk-ui';
import { CompleteData } from 'onfido-sdk-ui/types/shared/SdkParameters';

@Component({
    selector: 'embedded-idv-with-onfido',
    templateUrl: './embedded-idv-with-onfido.component.html',
    styleUrl: './embedded-idv-with-onfido.component.scss'
})
export class EmbeddedIdvWithOnfidoComponent implements OnInit, OnDestroy {

    private onfidoSdkHandle: Handle;

    private readonly userAnalyticsEventListener: (event) => void;

    constructor(protected logger: NGXLogger,
        private readonly registrationDataService: RegistrationDataService,
        private readonly amplitudeService: AmplitudeService,
        private readonly router: Router,
        private readonly i18nService: I18nService
    ) {
        this.userAnalyticsEventListener = (event) => {
            this.logger.debug("This is an ONFIDO event", event);
            this.amplitudeService.trackOnfidoWebSdkTraversal(event.detail?.eventName, event.detail?.isCrossDevice);
        };
    }


    ngOnInit(): void {
        this.i18nService.turnOffLanguageSelection();

        window.addEventListener('userAnalyticsEvent', this.userAnalyticsEventListener);

        const idvData = this.registrationDataService.getIdvData();

        this.registrationDataService.generateOnfidoSdkToken(idvData.sessionId)
            .then((sdkToken: string) => {
                this.onfidoSdkHandle = this.initOnfido(sdkToken, idvData.sessionId);
            });
    }

    ngOnDestroy(): void {
        this.onfidoSdkHandle.tearDown();
        window.removeEventListener('userAnalyticsEvent', this.userAnalyticsEventListener);
    }

    private initOnfido(sdkToken: string, workflowRunId: string): Handle {
        const params: SdkParameters = {
            token: sdkToken,
            workflowRunId: workflowRunId,
            containerId: "onfidoContainer",
            theme: {
                name: "light",
                config: OnfidoUIConfig
            },
            onComplete: (data: CompleteData) => {
                this.logger.debug("IDV process completed", data);
                this.amplitudeService.trackOnfidoWebSdkIdvComplete();
                this.router.navigate(['static/identity-verified']);
            },
            onError: (error) => {
                this.logger.debug("An error occurred", error);
                this.amplitudeService.trackOnfidoWebSdkError(error.type);
            }
        };
        return Onfido.init(params);
    }
}
