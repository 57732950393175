<div [formGroup]="personForm">

  <div class="form-input-row">
    <soldo-field-layout
      [control]="personForm.controls['firstName']"
      fieldName="firstName"
      [required]="!readonly"
      cypressSelector="Contact_TextInput_FirstName"
      label="REGISTRATION.PERSON.FIRST_NAME_LABEL">

      <input input
        formControlName="firstName"
        type="text"
        name="firstName"
        [readOnly]="readonly"
        class="form-control"
        placeholder="{{ 'REGISTRATION.PERSON.FIRST_NAME_PLACEHOLDER' | translate }}" />

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="firstNameCfMismatch">{{ 'FIRST_NAME_CF_MISMATCH' | translate }}
        </soldo-field-error>
        <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value:
          personForm.get('firstName').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout
      [control]="personForm.controls['lastName']"
      fieldName="lastName"
      [required]="!readonly"
      cypressSelector="Contact_TextInput_LastName"
      label="REGISTRATION.PERSON.LAST_NAME_LABEL">

      <input input
        formControlName="lastName"
        type="text" name="lastName"
        [readOnly]="readonly"
        class="form-control"
        placeholder="{{ 'REGISTRATION.PERSON.LAST_NAME_PLACEHOLDER' | translate }}" />

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="lastNameCfMismatch">{{ 'LAST_NAME_CF_MISMATCH' | translate }}
        </soldo-field-error>
        <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate:{value:
          personForm.get('lastName').errors?.maxlength?.requiredLength} }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout
      [control]="personForm.controls['citizenship']"
      fieldName="citizenship"
      [required]="!readonly"
      cypressSelector="Contact_NgSelect_Citizenship"
      label="REGISTRATION.PERSON.CITIZENSHIP_LABEL">

      <ng-select input *ngIf="!readonly"
        formControlName="citizenship"
        [items]="completeCountryList" [dropdownPosition]="'bottom'"
        notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
        [clearable]=false bindLabel="name" bindValue="alpha_3"
        placeholder="{{ 'REGISTRATION.PERSON.CITIZENSHIP_PLACEHOLDER' | translate }}"
        name="citizenship">
      </ng-select>
      <ng-container *ngIf="readonly" input>
        <input class="form-control" type="text" name="citizenship_label" [value]="getCitizenshipLabel()" readonly>
        <input type="hidden" formControlName="citizenship" name="citizenship">
      </ng-container>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <soldo-field-layout *ngIf="!personForm.get('fiscalCode')"
      [control]="personForm.controls['dob']"
      fieldName="dob"
      [required]="!readonly"
      cypressSelector="Contact_TextInput_DateOfBirth"
      label="REGISTRATION.PERSON.DOB_LABEL">

      <input input
        formControlName="dob"
        type="text"
        class="form-control"
        [readOnly]="readonly"
        mask="00/00/0000"
        [validation]="false"
        [specialCharacters]="[ '/' ]"
        [dropSpecialCharacters]="false"
        placeholder="{{ 'REGISTRATION.PERSON.DOB_PLACEHOLDER' | translate }}" />

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error *ngIf="!personForm.get('dob').errors?.required"
          errorName="invalidDateFormat">{{ 'DATE_FORMAT_ERROR' | translate }}
        </soldo-field-error>
        <soldo-field-error error
          errorName="ageNotAllowed">{{ 'DATE_AGE_NOT_ALLOWED' | translate }}
        </soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <div class="form-input" *ngIf="personForm.get('fiscalCode')" id="{{personForm.get('fiscalCode').id}}"
      data-cy="Contact_TextInput_CodiceFiscale">
      <fiscal-code [group]="personForm" [readonly]="readonly"></fiscal-code>
      <div *ngIf="isErrorVisible(personForm.get('fiscalCode'))" class="text-danger">
        <div *ngIf="personForm.get('fiscalCode').errors?.required">
          {{ 'REQUIRED' | translate }}
        </div>
        <div *ngIf="personForm.get('fiscalCode').errors?.wrongFormat">
          {{ 'WRONG_FORMAT' | translate }}
        </div>
      </div>
      <div class="text-danger" *ngIf="personForm.get('fiscalCode').errors?.duplicated">
        {{ 'DUPLICATED_CF' | translate }}
      </div>
      <div *ngIf="isErrorVisible(personForm.get('dob'))" class="text-danger">
        <div *ngIf="personForm.get('dob').errors?.ageNotAllowed">
          {{ 'DATE_AGE_NOT_ALLOWED' | translate }}
        </div>
      </div>
    </div>

    <soldo-field-layout
      [control]="personForm.controls['email']"
      fieldName="email"
      [required]="!readonly"
      cypressSelector="Contact_TextInput_Email"
      label="REGISTRATION.PERSON.WORK_EMAIL_LABEL">

      <input input
        trim="all"
        formControlName="email"
        type="text"
        name="email"
        [readOnly]="readonly"
        class ="form-control"
        placeholder="{{ 'REGISTRATION.PERSON.EMAIL_PLACEHOLDER' | translate }}" />

      <soldo-field-info *ngIf="market === 'ITA'" infoMessage>{{ 'REGISTRATION.PERSON.EMAIL_INFO' | translate }}</soldo-field-info>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="invalidEmailFormat">{{ 'WRONG_FORMAT' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value: personForm.get('email').errors?.maxlength?.requiredLength} }}</soldo-field-error>
        <soldo-field-error error errorName="notYourEmail">{{ 'NOT_YOUR_EMAIL' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="duplicated">{{ 'DUPLICATED_EMAIL' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="noDirectorsEmail">{{ 'NO_DIRECTOR_EMAIL_REUSE' | translate }}</soldo-field-error>
        <soldo-field-error error errorName="noDuplicatedEmail">{{ 'DUPLICATED_EMAIL' | translate }}</soldo-field-error>

      </ng-container>
    </soldo-field-layout>

    <div class="phone-input-field">
      <soldo-field-layout
        [control]="personForm.controls['phone']"
        fieldName="phone"
        [required]="!readonly"
        cypressSelector="Contact_TextInput_MobileNumber"
        label="REGISTRATION.PERSON.PHONE_LABEL">

        <div input *ngIf="!readonly"
          class="input-group phone-input" formGroupName="phone"
          [ngClass]="{'ng-touched': (isErrorVisible(personForm.get('phone').get('number')) || isErrorVisible(personForm.get('phone').get('prefix'))), 'readonly': readonly}">
          <ng-select
            [items]="completeCountryList" [dropdownPosition]="'bottom'"
            notFoundText="{{'NO_ITEMS_FOUND' | translate }}" class="" (keydown)="onKeyDown($event)" [clearable]="false"
            bindLabel="icc" bindValue="icc" placeholder="{{ 'REGISTRATION.PERSON.ICC_PLACEHOLDER' | translate }}"
            name="prefix" formControlName="prefix" data-cy="MobileNumber_NgSelect_ICC">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <span class="icc">{{item.icc}}</span><span class="border-left">{{item.name}}</span>
            </ng-template>
          </ng-select>
          <input #mobileNumberTextField
            formControlName="number"
            type="text" class="form-control has-field-info"  name="number"
            [readOnly]="readonly"
            placeholder="{{ 'REGISTRATION.PERSON.PHONE_PLACEHOLDER' | translate }}"
            data-cy="MobileNumber_TextInput_Number" />
        </div>

        <ng-container input formGroupName="phone" *ngIf="readonly">
          <input
            [value]="getPhoneValue()"
            type="text" class="form-control has-field-info"  name="number_label"
            readOnly />
          <input type="hidden" name="prefix" formControlName="prefix">
          <input type="hidden" name="number" formControlName="number">
        </ng-container>

        <soldo-field-info *ngIf="!readonly" infoMessage>{{ 'REGISTRATION.PERSON.PHONE_INFO' | translate }}</soldo-field-info>

        <ng-container errorMessage >

          <soldo-field-error error errorName="prefix-required">{{ 'REQUIRED' | translate }}</soldo-field-error>
          <soldo-field-error error errorName="number-required">{{ 'REQUIRED' | translate }}</soldo-field-error>
          <soldo-field-error error errorName="notValidNumber">{{ 'NOT_VALID_PHONE' | translate }}</soldo-field-error>
          <soldo-field-error error errorName="duplicated">{{ 'DUPLICATED_PHONE' | translate }}</soldo-field-error>
        </ng-container>
      </soldo-field-layout>


    </div>
  </div>

  <h5 class="my-4">{{ 'REGISTRATION.PERSON.ADDRESS' | translate }}</h5>

  <address2-form-group [readonly]="readonly" [group]="personForm.get('address')" data-cy="Contact_Address_ResidentialAddress">
  </address2-form-group>

  <soldo-field-layout *ngIf="personForm.get('isFatcaSubject')"
    [control]="personForm.controls['isFatcaSubject']"
    fieldName="isFatcaSubject"
    [required]="true"
    cypressSelector="Contact_RadioButton_FatcaSubject"
    label="REGISTRATION.PERSON.FATCA_FIELD_QUESTION">

    <div input class="fatca-field-options">
        <fieldset id="{{'isFatcaSubject-' + componentId}}">
            <legend>-- (hidden) need this to group the choices when multiple admin forms are on screen --</legend>
            <div *ngIf="!readonly || personForm.controls['isFatcaSubject'].value === true" data-cy="Contact_RadioButton_FatcaSubject_YES">
                <input id="{{ 'us-person-' + componentId }}" type="radio" [value]=true
                formControlName="isFatcaSubject">
                <label for="{{ 'us-person-' + componentId }}">{{ 'REGISTRATION.PERSON.FATCA_YES_LABEL' | translate }}</label>
            </div>
            <div *ngIf="!readonly || personForm.controls['isFatcaSubject'].value === false" data-cy="Contact_RadioButton_FatcaSubject_NO">
                <input id="{{ 'non-us-person-' + componentId }}" type="radio" [value]=false
                formControlName="isFatcaSubject">
                <label for="{{ 'non-us-person-' + componentId }}">{{ 'REGISTRATION.PERSON.FATCA_NO_LABEL' | translate }}</label>
            </div>
        </fieldset>
    </div>

    <ng-container errorMessage>
      <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
    </ng-container>
  </soldo-field-layout>

</div>
