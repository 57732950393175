<div id="overlay" *ngIf="currentContentComponent != null" data-cy="ModalDialog_Overlay"
  interactive="-1" (interact)="closeFromOutsideDialog($event)">

  <div class="overlay-content">
    <div class="main">
      <button class="icon" data-cy="ModalDialog_Button_X" (click)="dismiss()">
        <typeface-icon>close</typeface-icon>
      </button>
      <ng-container *ngComponentOutlet="currentContentComponent"></ng-container>
      <div class="footer">
          <button class="primary" data-cy="ModalDialog_Button_Close" (click)="dismiss()">
            {{ 'REGISTRATION.SAVE_AND_CONTINUE.DISMISS' | translate }}
          </button>
      </div>
    </div>
  </div>

</div>
