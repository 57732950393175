import { Directive, Input, forwardRef } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: 'input[trim],textarea[trim]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TrimWhitespacesDirective),
        multi: true
    }],
    host: {
        '(input)': '$any(this)._handleInput($event.target)',
        '(blur)': '$any(this)._handleBlur($event.target)',
        '(paste)': '$any(this)._handlePaste($event)',
        '(compositionstart)': '$any(this)._compositionStart()',
        '(compositionend)': '$any(this)._compositionEnd($event.target.value)'
    }
})
export class TrimWhitespacesDirective extends DefaultValueAccessor {
    @Input('trim')
    mode: string;

    isRemoveLeadingSpacesOn(): boolean {
        return this.mode.indexOf("leading") > -1;
    }

    isRemoveTrailingSpacesOn(): boolean {
        return this.mode.indexOf("trailing") > -1;
    }

    isRemoveAllSpacesOn(): boolean {
        return this.mode.indexOf("all") > -1;
    }

    _handleInput(eventTarget) {
        if (eventTarget?.value) {
            const uiValue = eventTarget.value;
            let trimmedValue = uiValue;

            if (this.isRemoveAllSpacesOn()) {
                trimmedValue = uiValue.replace(/\s+/, '');
            } else if (this.isRemoveLeadingSpacesOn()) {
                trimmedValue = uiValue.replace(/^\s+/, '');
            } // Cannot remove trailing spaces while the value is being typed

            if (uiValue != trimmedValue) {
                const caretPosition = this._getCaretPosition(eventTarget);
                eventTarget.value = trimmedValue;
                this._setCaretPosition(eventTarget, Math.max(caretPosition - 1, 0));
            }
            this.onChange(trimmedValue);
        }
        this.onChange(eventTarget?.value);
    }

    _handlePaste(event) {
        event.preventDefault();
        const eventTarget = event.target;
        const clipboardValue = event.clipboardData.getData("text");

        if (eventTarget && clipboardValue) {
            let trimmedValue = clipboardValue;

            if (this.isRemoveAllSpacesOn()) {
                trimmedValue = trimmedValue.replaceAll(/\s+/g, '');
            } else {
                if (this.isRemoveLeadingSpacesOn()) {
                    trimmedValue = trimmedValue.replace(/^\s+/, '');
                }
                if (this.isRemoveTrailingSpacesOn()) {
                    trimmedValue = trimmedValue.replace(/\s+$/, '');
                }
            }

            eventTarget.value = trimmedValue;
            this.onChange(trimmedValue);
        }
    }

    _handleBlur(eventTarget) {
        if (eventTarget?.value) {
            const uiValue = eventTarget.value;
            let trimmedValue = uiValue;

            // They stopped typing, now we can look for trailing spaces
            if (this.isRemoveTrailingSpacesOn()) {
                trimmedValue = uiValue.replace(/\s+$/, '');
                eventTarget.value = trimmedValue;
                this.onChange(trimmedValue);
            }
        }
        this.onTouched();
    }

    _getCaretPosition(el) {
        return Number.parseInt(el.selectionStart) > -1 ? el.selectionStart : 0;
    }

    _setCaretPosition(el, caretPos) {
        if (el !== null) {
            if (el.createTextRange) {
                const range = el.createTextRange();
                range.move('character', caretPos);
                range.select();
                return true;
            } else if (el.selectionStart || el.selectionStart === 0) {
                el.focus();
                el.setSelectionRange(caretPos, caretPos);
                return true;
            } else {
                el.focus();
                return false;
            }
        }
    }

}
