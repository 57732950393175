<h2>{{ "REGISTRATION.DIRECTOR.TITLE" + (soleTrader ? "_SOLETRADER" : "") | translate }}</h2>
<h5>{{ "REGISTRATION.DIRECTOR.LEGAL_REPRESENTATIVE_DEFINITION"  | translate }}</h5>

<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">

  <div class="layout-section" *ngIf="!soleTrader">
    <h4><strong>{{ 'REGISTRATION.DIRECTOR.ARE_YOU_REPRESENTATIVE' | translate }}</strong></h4>

    <soldo-field-layout
      [control]="form.controls.applicantIsLegalRep"
      fieldName="applicantIsLegalRep"
      cypressSelector="Director_RadioButton_IsLeadDirector">

      <fieldset input>
        <div data-cy="Director_RadioButton_IsLeadDirector_TRUE">
          <input id="YES-lead" type="radio" [formControl]="form.controls.applicantIsLegalRep" [value]="LEGAL_REP_CHOICE.APPLICANT">
          <label for="YES-lead">{{ "REGISTRATION.DIRECTOR.APPLICANT_IS_LEGAL_REP" | translate: {firstName: applicantContact.FirstName, lastName: applicantContact.LastName } }}</label>
        </div>
        <div data-cy="Director_RadioButton_IsLeadDirector_FALSE">
          <input id="NO-lead" type="radio" [formControl]="form.controls.applicantIsLegalRep" [value]="LEGAL_REP_CHOICE.OTHER">
          <label for="NO-lead">{{ "REGISTRATION.DIRECTOR.APPLICANT_IS_NOT_LEGAL_REP" | translate }}</label>
        </div>
      </fieldset>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

  </div>

  <div class="callout informative">
    <div class="callout-content">
      <p>{{ "REGISTRATION.DIRECTOR.CALLOUT_PARAGRAPH" | translate: {regulatoryInstitution: regulatoryInstitution} }}</p>
      <a [href]="registrationProcessURL">{{ "REGISTRATION.DIRECTOR.CALLOUT_LINK" | translate }}</a>
    </div>
  </div>

  <ng-container *ngIf="form.value.applicantIsLegalRep !== LEGAL_REP_CHOICE.NO_CHOICE">
    <h5 *ngIf="!soleTrader"><strong>{{ "REGISTRATION.DIRECTOR.FORM_TITLE" | translate }}</strong></h5>
    <person-form
      [personFormGroup]="form.controls.legalRep"
      [applyUniquenessChecks]="true"
      [checkInArray]="allContacts"
      [emailIsReadonly]="form.value.applicantIsLegalRep === LEGAL_REP_CHOICE.APPLICANT"></person-form>
  </ng-container>

  <div class="form-page-CTA-row">
    <button type="button" (click)="goToPrevious()" data-cy="Button_StepBackButton">
      {{ 'PREVIOUS' | translate}}
    </button>

    <button type="submit" class="primary" [disabled]="loading" [ngClass]="{ 'loading': loading}" data-cy="Button_StepSubmitButton">
      {{ 'NEXT' | translate}}
    </button>
  </div>

</form>

<save-and-continue-later stepName="Director Details"></save-and-continue-later>
